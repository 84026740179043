import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Row, Select } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { getVar } from '@System/support/helpers';

import { t, trans } from '../../../../../system/i18n';
import { updateCollectionItem } from '../../../../App/services/helpers';

const { Option } = Select;

export default function StackRow({
    index,
    removeRow,
    stack,
    sku,
    skus,
    setSkus,
    lineErrors,
    setLineErrors,
    canInspect,
    setCanInspect,
}) {
    const [warehouse, setWarehouse] = useState(null);
    const [isSelectedWarehouse, setIsSelectedWarehouse] = useState(false);
    const [warehouseArea, setWarehouseArea] = useState(null);
    const [stock, setStock] = useState(null);
    const [lineError, setLineError] = useState(null);
    const { product } = sku;

    useEffect(() => {
        
        if (stack.warehouse_id) {
            setIsSelectedWarehouse(true);
            let tmpWarehouse = sku.warehouses.find(row => _.get(row, 'warehouse.id', '') === stack.warehouse_id);
            setWarehouse(tmpWarehouse);
        } else {
            setIsSelectedWarehouse(false);
        }
    }, []);

    useEffect(() => {
        let tmpLineError = lineErrors.find(error => sku.sku_id === error.sku_id);
        setLineError(tmpLineError);
    }, [lineErrors]);

    /**
     * Nếu tất cả các stack đều có dữ liệu và tổng số sp trong tất cả các stack === tổng sp trong đơn thì pass
     * Nếu số lượng sp tạm tồn nhỏ hơn sp nhập vào thì báo lỗi
     */
    useEffect(() => {
        let noErrors = true;
        // Nếu số lượng sp kiểm hàng lớn hơn số lượng sp trong đơn thì báo lỗi
        let total_quantity = _.get(sku, 'stacks', []).reduce((total, opt) => {
            if (opt.quantity === '') noErrors = false;

            return total + (opt.quantity === '' ? 0 : opt.quantity);
        }, 0);
        if (total_quantity > sku.quantity) {
            noErrors = false;
            addError('greater_order');
        }

        // Nếu số lượng thực tồn nhỏ hơn số lượng kiểm hàng và sp ko phải dropship thì báo lỗi
        if (stock && Number.isInteger(stack.quantity) && stock.quantity < stack.quantity && product?.dropship === 0) {
            noErrors = false;
            addError('greater');
        }

        // Nếu không còn lỗi nào thì remove lỗi cho sku này
        if (noErrors) {
            let new_lineErrors = lineErrors.filter(row => row.sku_id !== sku.sku_id);
            setLineErrors(new_lineErrors);
        }

        setCanInspect(noErrors);
    }, [sku]);

    function addError(error_code) {
        if (lineError)
            setLineErrors([...lineErrors, { ...lineError, error_code: [...lineError.error_code, error_code] }]);
        else setLineErrors([...lineErrors, { sku_id: sku.sku_id, error_code: [error_code] }]);
    }

    useEffect(() => {
        if (warehouseArea) {
            let { stock } = warehouseArea;
            setStock(stock);
        }
    }, [warehouseArea]);

    function _onUpdate(data) {
        let stacks = updateCollectionItem(sku.stacks, stack.id, data);
        setSkus(updateCollectionItem(skus, sku.id, { stacks }));
    }

    function _onChange(value) {
        _onUpdate({ quantity: value });
    }

    function _onSelectWarehouse(value) {
        let tmpWarehouse = sku.warehouses.find(row => _.get(row, 'warehouse.id', '') === value);
        setWarehouse(tmpWarehouse);
        _onUpdate({ warehouse_id: value, warehouse_area_id: '' });
    }

    function _onSelectWarehouseArea(value) {
        let { warehouseAreas } = warehouse;
        let tmpWarehouseArea = warehouseAreas.find(row => _.get(row, 'warehouseArea.id', '') === value);
        let stock_quantity = _.get(tmpWarehouseArea, 'stock.quantity', '');
        setWarehouseArea(tmpWarehouseArea);
        _onUpdate({ warehouse_area_id: value, stock_quantity });
    }

    if (!sku) return null;
    return (
        <Row
            gutter={20}
            className={`d-flex align-items-center _choose-export-warehouse ${index > 0 ? 'mt-3 pt-3 border-top' : ''}`}
        >
            <Col span={3} offset={1} className="d-flex align-items-center text-nowrap">
                {t('order:label.warehouse_number')} {index + 1}
            </Col>
            <Col span={6}>
                {isSelectedWarehouse ? (
                    <p className="_warehouse-info">
                        {getVar(stack, 'warehouse.code', '') + ' - ' + getVar(stack, 'warehouse.name', '')}
                    </p>
                ) : (
                    <Select
                        style={{ width: '100%' }}
                        placeholder={t('order:placeholder.warehouse_code')}
                        onSelect={_onSelectWarehouse}
                        value={stack.warehouse_id || undefined}
                        className="bg-white _select-warehouse"
                    >
                        {sku.warehouses.length > 0 &&
                            sku.warehouses.map((row, key) => {
                                let { warehouse } = row;
                                return (
                                    <Option key={key} value={warehouse.id}>
                                        {warehouse.code + ' - ' + warehouse.name}
                                    </Option>
                                );
                            })}
                    </Select>
                )}
            </Col>
            <Col span={6}>
                <Select
                    style={{ width: '100%' }}
                    placeholder={t('order:placeholder.warehouse_area_code')}
                    onSelect={_onSelectWarehouseArea}
                    value={stack.warehouse_area_id || undefined}
                    className="bg-white _select-warehouse-area"
                >
                    {warehouse &&
                        warehouse.warehouseAreas.length > 0 &&
                        warehouse.warehouseAreas.map((row, key) => {
                            let { warehouseArea } = row;
                            let existedWarehouseAreas = sku.stacks.map(opt => opt.warehouse_area_id);
                            if (
                                existedWarehouseAreas.includes(warehouseArea.id) &&
                                stack.warehouse_area_id !== warehouseArea.id
                            )
                                return null;

                            let warehouseArea_code =
                                warehouseArea.code === 'default' ? t('order:label.default') : warehouseArea.code;
                            let warehouseArea_name =
                                warehouseArea.name === 'default' ? t('order:label.default') : warehouseArea.name;

                            return (
                                <Option key={key} value={warehouseArea.id}>
                                    {warehouseArea_code + ' - ' + warehouseArea_name}
                                </Option>
                            );
                        })}
                </Select>
            </Col>
            <Col span={4}>
                <InputNumber
                    placeholder={t('order:placeholder.quantity')}
                    style={{ width: '100%' }}
                    onChange={_onChange}
                    value={stack.quantity}
                    className="bg-white _quantity-stock"
                    min={1}
                />
            </Col>
            {index > 0 && (
                <Col span={1}>
                    <Button onClick={() => removeRow(sku.id, stack.id)} className="text-red _btn-close">
                        <CloseOutlined />
                    </Button>
                </Col>
            )}
            {stock && (
                <Col span={4} className="d-flex align-items-center">
                    <span className="text-fz-12 text-primary _existed-quantity">
                        {trans('order:message.existed_quantity', {
                            quantity: <strong className="text-primary">{stock.quantity}</strong>,
                        })}
                    </span>
                </Col>
            )}
            {lineError && index === sku.stacks.length - 1 && (
                <Col span={23} offset={1}>
                    {_.get(lineError, 'error_code', []).map(key => (
                        <p className="text-red mt-3 text-fz-12 _message-error">{t(`order:message.${key}`)}</p>
                    ))}
                </Col>
            )}
        </Row>
    );
}
