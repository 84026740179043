import { Select } from 'antd';
import map from 'lodash/map';
import React from 'react';

import usePurchasingServicesQuery from '../Hooks/usePurchasingServicesQuery';

const { Option } = Select;

const ServicesSelect = props => {
    const { data: purchasingServices } = usePurchasingServicesQuery();

    return (
        <Select
            allowClear
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            optionFilterProp="label"
            showSearch
            {...props}
        >
            {map(purchasingServices?.data?.purchasing_services, purchasingService => (
                <Option key={purchasingService.id} value={purchasingService.id}>
                    {purchasingService.name}
                </Option>
            ))}
        </Select>
    );
};

export default ServicesSelect;
