import clsx from 'clsx';
import React from 'react';

import styles from './box.module.scss';

const Box = ({ className, ...otherProps }) => {
    return <div className={clsx(styles.box, className)} {...otherProps} />;
};

export default Box;
