import { PlusOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Card, Typography } from 'antd';
import React, { useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import Modal from '@Modules/App/Modal';
import useDeletePurchasingAccountMutation from '@Modules/PurchasingAccount/Hooks/useDeletePurchasingAccountMutation';
import AddPurchasingAccountModal from '@Modules/PurchasingAccount/components/AddPurchasingAccountModal';
import EditPurchasingAccountModal from '@Modules/PurchasingAccount/components/EditPurchasingAccountModal';
import PurchasingAccountsTable from '@Modules/PurchasingAccount/components/PurchasingAccountsTable';
import { STATUSES } from '@Modules/PurchasingAccount/services/constants';

import { t } from '@System/i18n';

const { Paragraph, Title } = Typography;

const Body = ({ loading, pagination, purchasingAccounts, onChangePage, onRefetch }) => {
    const [selectedPurchasingAccount, setSelectedPurchasingAccount] = useState();
    const [visibles, setVisibles] = useState({
        add: false,
        edit: false,
    });
    const { mutateAsync: deletePurchasingAccount } = useDeletePurchasingAccountMutation({
        onSuccess: () => onRefetch(),
    });

    const handleOpenModal = usePersistFn(type => {
        setVisibles({
            ...visibles,
            [type]: true,
        });
    });

    const handleCloseModal = usePersistFn(type => {
        setVisibles({
            ...visibles,
            [type]: false,
        });

        if (type === 'edit') {
            setSelectedPurchasingAccount();
        }
    });

    const handleRowClick = usePersistFn(index => {
        setSelectedPurchasingAccount(purchasingAccounts[index]);
        handleOpenModal('edit');
    });

    const handleSuccessAddPurchasingAccount = usePersistFn(() => {
        onRefetch();
        handleCloseModal('add');
    });

    const handleSuccessUpdatePurchasingAccount = usePersistFn(() => {
        onRefetch();
        handleCloseModal('edit');
    });

    const handleDelete = usePersistFn(index => {
        Modal.confirm({
            cancelText: t('btn.cancel'),
            className: '_confirm-delete-purchasing-account-modal',
            okText: t('btn.confirm'),
            title: t('purchasingAccount:delete.title'),
            content: (
                <div>
                    <Paragraph className="mb-3">{t('purchasingAccount:delete.confirm')}</Paragraph>

                    <div className="d-flex align-items-center mb-3">
                        <Avatar className="mr-2" size={36} />
                        <div className="d-flex flex-column">
                            {purchasingAccounts[index]?.username}
                            <Badge
                                color={STATUSES[purchasingAccounts[index]?.status?.toUpperCase()]?.color}
                                text={t(`purchasingAccount:statuses.${purchasingAccounts[index]?.status}`)}
                            />
                        </div>
                    </div>
                </div>
            ),
            onOk: () => deletePurchasingAccount(purchasingAccounts[index]?.id),
        });
    });

    return (
        <Card className="rounded-12">
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mb-4">
                <Title className="font-weight-bold text-fz-16 mb-0" level={3}>
                    {t('account_list')}
                </Title>

                <Button className="_add-btn" type="primary" onClick={handleOpenModal.bind(this, 'add')}>
                    <PlusOutlined />
                    {t('add_account')}
                </Button>
            </div>

            <PurchasingAccountsTable
                loading={loading}
                pagination={pagination}
                purchasingAccounts={purchasingAccounts}
                onChange={onChangePage}
                onDelete={handleDelete}
                onRowClick={handleRowClick}
            />

            <AddPurchasingAccountModal
                visible={visibles.add}
                onCancel={handleCloseModal.bind(this, 'add')}
                onOk={handleSuccessAddPurchasingAccount}
            />

            <EditPurchasingAccountModal
                purchasingAccount={selectedPurchasingAccount}
                visible={visibles.edit}
                onCancel={handleCloseModal.bind(this, 'edit')}
                onOk={handleSuccessUpdatePurchasingAccount}
            />
        </Card>
    );
};

export default Body;
