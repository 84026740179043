import {formatNumber} from "@System/support/numberFormat";

export const vnCurrency = {
    precision: 0,
    format: "{amount}₫",
    thousands_separator: ".",
    decimal_separator: ","
};

export const cnCurrency = {
    precision: 2,
    format: "¥{amount}",
    thousands_separator: ".",
    decimal_separator: ","
};

export const formatCurrency = (currency, currentCurrency = vnCurrency) => {
    let {format} = currentCurrency;
    currency = formatNumber(parseFloat(currency), currentCurrency);
    return format.replace("{amount}", currency);
}

export const EVENTS = {
    FETCH_PURCHASING_ORDER_AGAIN: "EVENTS.FETCH_PURCHASING_ORDER_AGAIN"
}

export const PURCHASING_ORDER_STATUS_COLORS = {
    DEPOSITED: '#2e70ff',
    ACCEPTED: '#008df9',
    PENDING: '#ed6e52',
    PROCESSING: '#ed6e52',
    PURCHASED: '#ffa634',
    MERCHANT_DELIVERING: '#09b8ad',
    PUTAWAY: '#06d087',
    TRANSPORTING: '#03aed3',
    READY_FOR_DELIVERY: '#bdbdbd',
    DELIVERING: '#4fff37',
    DELIVERED: '#1ef8ff',
    CANCELLED: '#ff4559',
    OUT_OF_STOCK: '#ff4559',
    MIA: '#ff4559',
    DELIVERY_CANCELLED: '#ff4559'
};
