import { Modal, Input, Typography } from 'antd';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useState } from 'react';

import { t } from '@System/i18n';

const { TextArea } = Input;
const { Paragraph } = Typography;

const InputMultipleBarcodesModal = ({ visible, onCancel, onOk }) => {
    const [value, setValue] = useState();

    const handleChange = useCallback(event => {
        setValue(event.target.value);
    }, []);

    const handleCancel = useCallback(() => {
        if (isFunction(onCancel)) {
            onCancel();
        }

        setValue();
    }, [onCancel]);

    const handleOk = useCallback(() => {
        if (isFunction(onOk)) {
            onOk(value ? value.split('\n') : []);
        }

        setValue();
    }, [value, onOk]);

    return (
        <Modal
            cancelText={t('btn.close')}
            className="_input-multiple-barcodes-modal"
            maskClosable
            okText={t('import_list')}
            title={t('input_code_from_text')}
            visible={visible}
            onCancel={handleCancel}
            onOk={handleOk}
        >
            <Paragraph>{t('document:input_multiples_barcode_hint')}</Paragraph>
            <TextArea rows={4} value={value} onChange={handleChange} />
        </Modal>
    );
};

export default InputMultipleBarcodesModal;
