import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { t } from '@System/i18n';
import { classes } from '@System/support/helpers';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';
import { useEffect } from 'react';

import Configuration from './Configuration';
import UploadBanner from './UploadBanner';

const OperationConfig = () => {
    const { xs } = useBreakpoint();

    useEffect(() => {
        setNavigator(t('config'), [
            {
                name: t('config'),
            },
        ]);
        setDocumentTitle(t('config'));
    }, []);

    return (
        <div
            className={classes('site-content d-flex p-3', {
                'flex-wrap': xs,
            })}
        >
            <UploadBanner />
            <Configuration />
        </div>
    );
};

export default OperationConfig;
