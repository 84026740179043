import { Card, Form, Modal, Spin } from 'antd'
import concat from 'lodash/concat'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import values from 'lodash/values'
import React, { useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { updateCollectionItem } from '@Modules/App/services/helpers'
import useImportingDocumentSkusQuery from '@Modules/Document/Hooks/useImportingDocumentSkusQuery'
import useUpdateSkuImportDocumentMutation from '@Modules/Document/Hooks/useUpdateSkuImportingDocumentMutation'
import ServiceList from '@Modules/Document/components/ServiceList'

import useGeneratorKey, { IdempotencyStorage } from '@System/hooks/useGeneratorKey'
import { getVar } from '@System/support/helpers'

import { t, trans } from '../../../../../system/i18n'
import Error403 from '../../../../App/Error/403'
import Error404 from '../../../../App/Error/404'
import { setDocumentTitle, setNavigator } from '../../../../App/services'
import useDeleteImportingDocumentMutation from '../../../Hooks/useDeleteImportingDocumentMutation'
import useImportMutation from '../../../Hooks/useImportMutation'
import useImportingDocumentQuery from '../../../Hooks/useImportingDocumentQuery'
import LeavePageBlocker from '../components/LeavePageBlocker'
import ProductList from '../components/ProductList'
import Header from './Header'
import notification from '@System/notification'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const DocumentImportingDetail = props => {
    const { uniqueKey, generatorKey } = useGeneratorKey()
    const { match } = props
    const documentId = match.params.id
    const [form] = Form.useForm()
    const [formProduct] = Form.useForm()
    const [importingDocument, setImportingDocument] = useState({})
    const [skusImporting, setSkusImporting] = useState([])
    const [documentSkusDelete, setDocumentSkusDelete] = useState([])
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [filters, setFilters] = useState({})
    const [services, setServices] = useState([])
    const [loadingCancel, setLoadingCancel] = useState(false)
    const [dirty, setDirty] = useState(false)

    const { isLoading: documentSkusLoading, data: skusImportingInfo, refetch } = useImportingDocumentSkusQuery(documentId, filters)
    const { error, isError, isFetching, data, refetch: refetchImportingDocument } = useImportingDocumentQuery(documentId)
    const { mutateAsync: deleteImportingDocument } = useDeleteImportingDocumentMutation({
        id: documentId,
        onSuccess: data => {
            setImportingDocument(data)
            setConfirmDelete(false)
            setLoadingCancel(false)
        },
        onMutate: () => {
            setLoadingCancel(true)
        },
    })
    const { mutateAsync: importDocument, isLoading: isImporting } = useImportMutation({
        id: match.params.id,
        onSuccess: data => {
            setDirty(false)
            IdempotencyStorage.reset()
            setImportingDocument(data)
            setConfirm(false)
        },
        onError: error => {
            const { response } = error
            const code = get(response, 'data.code')

            if (code === 'REQUEST_PROCESSED') {
                setDirty(true)
                return notification.success(t('message.request_process'))
            }else{
                generatorKey()
                setDirty(false)
            }
        },
    })

    const { isLoading: updating, mutate: updateSkuImportingDocument } = useUpdateSkuImportDocumentMutation({
        onSuccess: () => {
            form.resetFields()
            refetch()
            refetchImportingDocument()
            setIsChanged(false)
        },
    })

    useEffect(() => {
        setNavigator(trans('document:types.importing'), [
            {
                name: trans('document:list.importing'),
                route: 'documents.importing.list',
            },
            {
                name: trans('document:detail.importing'),
            },
        ])
    }, [])

    useEffect(() => {
        const importingDocument = getVar(data, 'data', {})
        const purchasing_packages = getVar(importingDocument, 'purchasing_packages', [])
        setImportingDocument(importingDocument)
        if (purchasing_packages.length > 0) {
            const services = getVar(purchasing_packages, '0.services', [])
            setServices(refactorService(services))
        }
    }, [data])

    useEffect(() => {
        form.setFieldsValue({
            sender_name: importingDocument?.info?.sender_name,
            sender_phone: importingDocument?.info?.sender_phone,
            sender_license: importingDocument?.info?.sender_license,
            sender_partner: importingDocument?.info?.sender_partner,
        })
        setDocumentTitle(t('title_page.document_importing', { document_code: get(importingDocument, 'code', '') }))
    }, [importingDocument])

    function refactorService(data) {
        const services = []
        data.map(item => {
            const service_prices = getVar(item, 'service_prices', [])
            service_prices.map(service_price => {
                services.push({
                    key: v4(),
                    name: getVar(item, 'service.name', ''),
                    quantity: getVar(service_price, 'quantity', ''),
                    price: getVar(service_price, 'price', 0),
                    amount: getVar(service_price, 'amount', 0),
                    service_price_name: getVar(service_price, 'label', ''),
                    skus: getVar(service_price, 'skus', []),
                })
            })
        })
        return services
    }

    function handleImport() {
        if(importingDocument?.document_confirm){
            warningNotSize()
        }else{
           setConfirm(true)
        }  
    }

    const warningNotSize =()=>{
        Modal.confirm({
            title: t('document:import_confirm.measure_size_before_accept'),
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: t("btn.ok"),
            cancelText: t("btn.close"),
            okButtonProps:{className:"d-none"}
          });
    }

    function handleDelete() {
        setConfirmDelete(true)
    }

    const onSuccessUpdate = useCallback(data => {
        setImportingDocument(data)
    }, [])

    function handleSave() {
        formProduct.validateFields().then(data => {
            const skus = concat(values(data), documentSkusDelete)
            updateSkuImportingDocument({
                documentId,
                data: { skus },
            })
        })
    }

    function handleSaveAndConfirm() {
        formProduct.validateFields().then(data => {
            const skus = concat(values(data), documentSkusDelete)
            updateSkuImportingDocument(
                {
                    documentId,
                    data: { skus },
                },
                {
                    onSuccess: () => {
                        setDirty(true)
                        importDocument()
                        form.resetFields()
                        refetch()
                    },
                }
            )
        })
    }

    function handleImportDocument() {
        try {
            if (isChanged) {
                handleSaveAndConfirm()
            } else {
                setDirty(true)
                importDocument()
            }
        } catch (e) {
            notification.error({ message: t('message.update_failed_by_attribute'), duration: 6 })
        }
    }

    const refactorDataSkusImporting = data => {
        let documentSkusImporting = []

        if (!isEmpty(data)) {
            data.map(item => {
                const sku_id = get(item, 'sku.id', '')
                const existSkuParent = find(documentSkusImporting, { sku_id: sku_id, isParent: true })
                const info = {
                    product_id: get(item, 'sku.product_id', ''),
                    sku_id: get(item, 'sku.id', ''),
                    sku_code: get(item, 'sku.code', ''),
                    sku_name: get(item, 'sku.name', ''),
                    width: get(item, 'sku.width', '') * 1000,
                    length: get(item, 'sku.length', '') * 1000,
                    height: get(item, 'sku.height', '') * 1000,
                    weight: get(item, 'sku.weight', '') * 1000,
                    lack_of_information: get(item, 'index', ''), // Nếu index === false thì sản phẩm thiếu thông tin cân nặng hoặc kích thước
                    real_quantity: get(item, 'document_sku_importing.real_quantity', 0),
                    quantity: get(item, 'document_sku_importing.quantity', 0),
                    warehouse_area_id: get(item, 'document_sku_importing.warehouse_area_id', undefined),
                    documentSkuId: get(item, 'document_sku_importing.id', undefined),
                    warehouse_area: getVar(item, 'warehouse_area', {}),
                }
                if (isEmpty(existSkuParent)) {
                    return documentSkusImporting.push({
                        ...info,
                        key: get(item, 'sku.id', ''),
                        isParent: true,
                        rowSpan: 1,
                    })
                } else {
                    documentSkusImporting = updateCollectionItem(documentSkusImporting, existSkuParent.key, { rowSpan: existSkuParent.rowSpan + 1 }, 'key')
                    return documentSkusImporting.push({
                        ...info,
                        key: v4(),
                        isParent: false,
                        rowSpan: 0,
                    })
                }
            })
        }
        return documentSkusImporting
    }

    useEffect(() => {
        refetch()
    }, [filters])

    if (isError) {
        const status = error.response.status
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    return (
        <>
            {dirty && <LeavePageBlocker when={dirty} />}

            <Spin spinning={isFetching}>
                <Card
                    className="flex-grow-1 bg-white m-4"
                    title={
                        <Header
                            form={form}
                            importingDocument={importingDocument}
                            handleDelete={handleDelete}
                            handleImport={handleImport}
                            onSuccessUpdate={onSuccessUpdate}
                            handleSave={handleSave}
                            isChanged={isChanged}
                            updatingSku={updating}
                            isImporting={isImporting}
                        />
                    }
                >
                    {documentId && (
                        <ProductList
                            skusImportingInfo={skusImportingInfo}
                            form={formProduct}
                            skusImporting={skusImporting}
                            setSkusImporting={setSkusImporting}
                            importingDocument={importingDocument}
                            isChanged={isChanged}
                            setIsChanged={setIsChanged}
                            handleSave={handleSave}
                            setDocumentSkusDelete={setDocumentSkusDelete}
                            documentSkusDelete={documentSkusDelete}
                            refactorDataSkusImporting={refactorDataSkusImporting}
                            isLoading={documentSkusLoading}
                            filters={filters}
                            setFilters={setFilters}
                            refetchImportingDocument={refetchImportingDocument}
                            refetchSkusImporting={refetch}
                        />
                    )}
                    {!isEmpty(services) && (
                        <div className="_detail-document-importing-service-list p-2 pt-3 pb-3">
                            <div className="d-flex">
                                <h3 className="">{t('title.service_list')}</h3>
                            </div>
                            <ServiceList services={services} />
                        </div>
                    )}
                </Card>
                {confirmDelete && (
                    <Modal
                        title={trans('document:delete.title')}
                        visible={confirmDelete}
                        okText={trans('btn.confirm')}
                        cancelText={trans('btn.close')}
                        onOk={deleteImportingDocument}
                        onCancel={() => setConfirmDelete(false)}
                        cancelButtonProps={{ className: '_modal-confirm-delete-importing-document-btn-cancel' }}
                        okButtonProps={{
                            className: '_modal-confirm-delete-importing-document-btn-ok',
                            loading: loadingCancel,
                        }}
                    >
                        <p>{trans('document:delete.confirm', { code: <b>#{importingDocument?.code}</b> })}</p>
                    </Modal>
                )}
                {confirm && (
                    <Modal
                        title={trans('document:import_confirm.title')}
                        visible={confirm}
                        okText={trans('btn.confirm')}
                        cancelText={trans('btn.close')}
                        onOk={handleImportDocument}
                        onCancel={() => setConfirm(false)}
                        cancelButtonProps={{ className: '_modal-confirm-importing-btn-cancel' }}
                        okButtonProps={{ className: '_modal-confirm-importing-btn-ok', loading: isImporting }}
                    >
                        <p>{trans('document:import_confirm.confirm', { code: <b>#{importingDocument?.code}</b> })}</p>
                    </Modal>
                )}
            </Spin>
        </>
    )
}

export default DocumentImportingDetail
