import { useQuery } from 'react-query';

import apiService from './../services/api';
import { t } from './../../../system/i18n';
import notification from './../../../system/notification';
import { convertBlobDataToExcel } from './../../../system/support/helpers';
import {useEffect, useState} from "react";
import ServiceWarehouses from "@Modules/Warehouse/services/ServiceWarehouses";

const useDownloadItemListQuery = (params) => {
    const [warehouses, setWarehouses] = useState([]);

    useEffect(() => {
        ServiceWarehouses.list()
            .then(res => {
                setWarehouses(res);
            });
    }, []);

    return useQuery(['orders-packings.download-item-list', params], () => apiService.downloadItemList(params), {
        enabled: false,
        retry: false,
        onSuccess: response => {
            const warehouse = warehouses.find(warehouse => warehouse.id === Number(params.warehouse_id));

            if (warehouse) {
                convertBlobDataToExcel(response.data, `${warehouse.code}-product-list`);
            }

            notification.success(t('order:export_product_list.success'));
        },
        onError: error => {
            notification.error(t('order:export_product_list.failed'));
        }
    });
};

export default useDownloadItemListQuery;
