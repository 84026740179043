import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import apiService from './../services/api';

const useSkuComboHistoryQuery = comboId =>
    useQuery(['sku-combo-history', comboId], () => apiService.getSkuComboHistory(comboId), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });

export default useSkuComboHistoryQuery;
