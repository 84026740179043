import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, DatePicker, Col, Button, Input, Select } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import ChoseWarehouseModal from '@Modules/Document/screens/Inventory/components/ChoseWarehouseModal';

import { t } from '../../../system/i18n';
import SelectAccounts from '../../Account/components/SelectAccounts';
import useSize from '../../App/Hooks/useSize';
import { warehouseStorage } from '../../App/services';
import SelectWarehouse from '../../Warehouse/components/SelectWarehouse';
import { LIST_TYPE_IMPORTING_DOCUMENT, TYPES } from '../services/constants';
import SelectDocumentStatus from './SelectDocumentStatus';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

const expandSearch = [TYPES.TYPE_IMPORTING, TYPES.TYPE_EXPORTING];

const infoCreateDocument = {
    [TYPES.TYPE_SKU_INVENTORY]: {
        route_name: 'documents.inventory.create',
        btn_name: t('document:create_inventory_document'),
    },
};

function FormSearch({ onSearch, filters, initFilters, type, loading }) {
    const { isXSmall, isSmall } = useSize();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const {
        code,
        warehouse_id,
        verifier_id,
        creator_id,
        status,
        package_code,
        package_freight_bill_code,
        freight_bill_code,
    } = input || {};
    const verified_at_from = get(input, 'verified_at[from]', '');
    const verified_at_to = get(input, 'verified_at[to]', '');
    const created_at_from = get(input, 'created_at[from]', '');
    const created_at_to = get(input, 'created_at[to]', '');

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({ ...input, [name]: value });
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;
        setInput({
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        });
    }

    function _onSubmit() {
        onSearch(input);
    }

    function _onReset() {
        warehouseStorage.reset();
        onSearch(initFilters);
    }

    return (
        <div className="bg-white rounded-12 p-4">
            <div className="search-box">
                <div className="search-box--title d-lg-flex justify-content-between pb-4">
                    <h3 className="text-fz-18">{t(`common:heading.document_search.${type}`)}</h3>
                    {!isEmpty(infoCreateDocument[type]) && auth.can(permissions.OPERATION_HISTORY_AUDIT_EDIT) && (
                        <ChoseWarehouseModal />
                    )}
                </div>
                <Form form={form} layout="vertical">
                    <Row gutter={20}>
                        <Col
                            xs={{ span: 12 }}
                            lg={expandSearch.includes(type) ? { span: 4 } : { span: 5 }}
                            xxl={{ span: type === TYPES.TYPE_SKU_INVENTORY ? 6 : 4 }}
                        >
                            <Form.Item className="mb-3" label={t(`document:label.document_code.${type}`)}>
                                <Input
                                    className="_document-search-code"
                                    name="code"
                                    value={code}
                                    placeholder={t(`document:placeholder.document_code.${type}`)}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 12 }}
                            lg={
                                expandSearch.includes(type)
                                    ? { span: 4 }
                                    : { span: type === TYPES.TYPE_PACKING ? 5 : 6 }
                            }
                            xxl={{ span: type === TYPES.TYPE_SKU_INVENTORY ? 6 : 4 }}
                        >
                            <Form.Item className="mb-3" label={t('product:label.warehouse_name')}>
                                <SelectWarehouse
                                    className="_document-search-warehouse-id"
                                    allowClear={true}
                                    placeholder={t('document:placeholder.warehouse_name')}
                                    value={warehouse_id ? parseInt(warehouse_id) : undefined}
                                    onChange={value => _onChange('warehouse_id', value)}
                                    noSuggest={false}
                                />
                            </Form.Item>
                        </Col>
                        {type !== TYPES.TYPE_SKU_INVENTORY && (
                            <>
                                <Col
                                    xs={{ span: 12 }}
                                    lg={expandSearch.includes(type) ? { span: 8 } : { span: 9 }}
                                    xxl={expandSearch.includes(type) ? { span: 8 } : { span: 7 }}
                                >
                                    <Form.Item className="mb-3" label={t('document:label.verified_at')}>
                                        <RangePicker
                                            className="_document-search-verified-at"
                                            value={
                                                verified_at_from && verified_at_to
                                                    ? [moment(verified_at_from), moment(verified_at_to)]
                                                    : undefined
                                            }
                                            format={dateFormat}
                                            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                            onChange={date => _onChangeDate('verified_at', date)}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col
                                    xs={{ span: 12 }}
                                    lg={expandSearch.includes(type) ? { span: 4 } : { span: 5 }}
                                    xxl={{ span: 4 }}
                                >
                                    <Form.Item className="mb-3" label={t('document:label.verifier_username')}>
                                        <SelectAccounts
                                            className="_document-search-verifier-id"
                                            allowClear={true}
                                            placeholder={t('document:placeholder.verifier')}
                                            value={verifier_id ? parseInt(verifier_id) : undefined}
                                            onChange={value => _onChange('verifier_id', value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                        {(expandSearch.includes(type) || type === TYPES.TYPE_SKU_INVENTORY) && (
                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('document:label.status')}>
                                    <SelectDocumentStatus
                                        statustype={type}
                                        allowClear={true}
                                        placeholder={t('document:placeholder.status')}
                                        value={status ? status : undefined}
                                        onChange={value => _onChange('status', value)}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                        {expandSearch.includes(type) && (
                            <>
                                <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                                    <Form.Item className="mb-3" label={t('document:label.created_at')}>
                                        <RangePicker
                                            value={
                                                created_at_from && created_at_to
                                                    ? [moment(created_at_from), moment(created_at_to)]
                                                    : undefined
                                            }
                                            format={dateFormat}
                                            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                            onChange={date => _onChangeDate('created_at', date)}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                    <Form.Item className="mb-3" label={t('document:label.creator_username')}>
                                        <SelectAccounts
                                            className="_document-search-creator-id"
                                            allowClear={true}
                                            placeholder={t('document:placeholder.creator')}
                                            value={creator_id ? parseInt(creator_id) : undefined}
                                            onChange={value => _onChange('creator_id', value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                        {type === TYPES.TYPE_IMPORTING && (
                            <>
                                <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                    <Form.Item className="mb-3" label={t('document:label.type_importing_document')}>
                                        <Select
                                            mode="multiple"
                                            showSearch
                                            placeholder={t('document:placeholder.type_importing_document')}
                                            onChange={value => _onChange('type', value)}
                                            value={input.type ? input.type : undefined}
                                        >
                                            {Object.values(LIST_TYPE_IMPORTING_DOCUMENT).map(item => (
                                                <Option value={item} key={item}>
                                                    {t(`document:LIST_TYPE_IMPORTING.${item}`)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                    <Form.Item className="mb-3" label={t('label.import_package_code')}>
                                        <Input
                                            onChange={_onChangeInput}
                                            value={package_code}
                                            onPressEnter={_onSubmit}
                                            placeholder={t('placeholder.import_package_code')}
                                            name="package_code"
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                    <Form.Item className="mb-3" label={t('label.freight_bill_code')}>
                                        <Input
                                            onChange={_onChangeInput}
                                            onPressEnter={_onSubmit}
                                            value={package_freight_bill_code}
                                            placeholder={t('placeholder.freight_bill_code')}
                                            name="package_freight_bill_code"
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        )}

                        {type === TYPES.TYPE_EXPORTING && (
                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.freight_bill_code')}>
                                    <Input
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                        value={freight_bill_code}
                                        placeholder={t('placeholder.freight_bill_code')}
                                        name="freight_bill_code"
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        {type === TYPES.TYPE_EXPORTING ? (
                            <Col
                                xs={{ span: 24 }}
                                md={{ span: 24 }}
                                lg={{ span: 8, offset: 4 }}
                                xl={{ span: 8, offset: 16 }}
                                xxl={{ span: 8, offset: 16 }}
                                className="text-right"
                            >
                                <Form.Item className="mb-3" label={isXSmall || isSmall ? '' : ' '}>
                                    <Button className="search-reset-btn _document-search-btn-reset" onClick={_onReset}>
                                        <UndoOutlined /> {t('common:btn.reset')}
                                    </Button>
                                    <Button
                                        type="primary"
                                        className="search-submit-btn _document-search-btn-submit"
                                        onClick={_onSubmit}
                                        loading={loading}
                                    >
                                        <SearchOutlined /> {t('common:btn.search')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        ) : (
                            <Col span={24} className="text-right">
                                <Button className="search-reset-btn _document-search-btn-reset" onClick={_onReset}>
                                    <UndoOutlined /> {t('common:btn.reset')}
                                </Button>
                                <Button
                                    type="primary"
                                    className="search-submit-btn _document-search-btn-submit"
                                    onClick={_onSubmit}
                                    loading={loading}
                                >
                                    <SearchOutlined /> {t('common:btn.search')}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default FormSearch;
