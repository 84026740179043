import { DownOutlined, SearchOutlined, UndoOutlined, UpOutlined } from "@ant-design/icons";
import useSize from "@Modules/App/Hooks/useSize";
import SelectAllMerchants from "@Modules/Merchant/components/SelectAllMerchants";
import SelectSite from "@Modules/Order/components/SelectSite";
import SelectAssignedPurchasingAccount from "@Modules/PurchasingManager/components/SelectAssignedPurchasingAccount";
import SelectPurchasingAccount from "@Modules/PurchasingManager/components/SelectPurchasingAccount";
import SelectPurchasingOrderStatus from "@Modules/PurchasingManager/components/SelectPurchasingOrderStatus";
import { t } from "@System/i18n";
import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import _ from "lodash";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SelectMerchants from "../../../Merchant/components/SelectMerchants";

const {RangePicker} = DatePicker;
const dateFormat = "DD/MM/YYYY";

function FormSearch({onSearch, filters, initFilters, fetchOrders}) {
    const {isMobile, isDesktop} = useSize();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const [showOnMobile, setShowOnMobile] = useState(false);
    const {
        code,
        purchasing_account_id,
        marketplace,
        supplier,
        status,
        sku_code,
        merchant_id
    } = input || {};

    const total_value_from = _.get(input, "total_value[from]", "");
    const total_value_to = _.get(input, "total_value[to]", "");
    const ordered_at_from = _.get(input, "ordered_at[from]", "");
    const ordered_at_to = _.get(input, "ordered_at[to]", "");

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({...input, [name]: value});
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onChangeInputGroup(e) {
        const name = e.target.name;
        const value = e.target.value;
        let newValue = "";
        if (value) {
            newValue = parseInt(value.replace(/\./gi, ""));
            if (Number.isNaN(newValue)) {
                newValue = "";
            }
        }
        _onChange(name, newValue);
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;
        const filters = {
            ...input,
            [name_from]: dates ? dates[0].format("YYYY-MM-DD") : "",
            [name_to]: dates ? dates[1].format("YYYY-MM-DD") : ""
        };
        setInput(filters);
    }

    function _onSubmit() {
        onSearch(input);
    }

    function _onReset() {
        onSearch(initFilters);
    }

    function _onToggleMobile() {
        setShowOnMobile(!showOnMobile);
    }

    return (
        <Form
            form={form}
            layout="vertical"
        >
            <div className="bg-white rounded-12 p-4">
                <div className="search-box">
                    <div className="search-box--title d-lg-flex justify-content-between pb-4">
                        <h3 className="text-fz-18">{t("common:heading.list-orders")}</h3>
                    </div>

                    <Row gutter={20}>
                        <Col xs={{span: 12}} lg={{span: 4}}>
                            <Form.Item
                                className="mb-3"
                                label={t("purchasingOrder:label.code")}
                            >
                                <Input
                                    className="_purchasing-orders-search-code"
                                    name="code"
                                    value={code}
                                    placeholder={t("purchasingOrder:placeholder.code")}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{span: 12}} lg={{span: 4}}>
                            <Form.Item
                                className="mb-3"
                                label={t("purchasingOrder:label.purchasing_account_id")}
                            >
                                {
                                    filters.tab_vendor
                                        ? <SelectAssignedPurchasingAccount
                                            className="_purchasing-orders-search-purchasing_account_id"
                                            placeholder={t("purchasingOrder:placeholder.purchasing_account_id")}
                                            allowClear={true}
                                            value={purchasing_account_id ? parseInt(purchasing_account_id) : undefined}
                                            onChange={value => _onChange("purchasing_account_id", value)}
                                        />
                                        : <SelectPurchasingAccount
                                            className="_purchasing-orders-search-purchasing_account_id"
                                            placeholder={t("purchasingOrder:placeholder.purchasing_account_id")}
                                            allowClear={true}
                                            value={purchasing_account_id ? parseInt(purchasing_account_id) : undefined}
                                            onChange={value => _onChange("purchasing_account_id", value)}
                                        />
                                }
                            </Form.Item>
                        </Col>

                        <Col xs={{span: 12}} lg={{span: 4}}>
                            <Form.Item
                                className="mb-3"
                                label={t("purchasingOrder:label.vendor_id")}
                            >
                                {
                                    filters.tab_vendor
                                        ? <SelectMerchants
                                            className="_purchasing-orders-search-vendor_id"
                                            placeholder={t("purchasingOrder:placeholder.vendor_id")}
                                            allowClear={true}
                                            value={merchant_id ? parseInt(merchant_id) : undefined}
                                            onChange={value => _onChange("merchant_id", value)}
                                        />
                                        : <SelectAllMerchants
                                            className="_purchasing-orders-search-vendor_id"
                                            placeholder={t("purchasingOrder:placeholder.vendor_id")}
                                            allowClear={true}
                                            value={merchant_id ? parseInt(merchant_id) : undefined}
                                            onChange={value => _onChange("merchant_id", value)}
                                        />
                                }
                            </Form.Item>
                        </Col>

                        <Col xs={{span: 12}} lg={{span: 4}}>
                            <Form.Item
                                className="mb-3"
                                label={t("purchasingOrder:label.marketplace")}
                            >
                                <SelectSite
                                    className="_purchasing-orders-search-marketplace"
                                    placeholder={t("purchasingOrder:placeholder.purchasing_account_id")}
                                    allowClear={true}
                                    value={marketplace ? marketplace : undefined}
                                    onChange={value => _onChange("marketplace", value)}
                                />
                            </Form.Item>
                        </Col>

                        {
                            (isDesktop || (isMobile && showOnMobile)) &&
                            <>
                                <Col xs={{span: 12}} lg={{span: 4}}>
                                    <Form.Item
                                        className="mb-3"
                                        label={t("purchasingOrder:label.supplier")}
                                    >
                                        <Input
                                            className="_purchasing-orders-search-supplier"
                                            name="supplier"
                                            value={supplier}
                                            placeholder={t("purchasingOrder:placeholder.supplier")}
                                            onChange={_onChangeInput}
                                            onPressEnter={_onSubmit}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={{span: 12}} lg={{span: 4}}>
                                    <Form.Item
                                        label={t("purchasingOrder:label.total_value")}
                                        className="mb-3"
                                    >
                                        <Input.Group compact>
                                            <Input
                                                className="_purchasing-orders-search-total_value_from"
                                                style={{ width: "calc(50% - 20px)", textAlign: 'center', borderRight: "none" }}
                                                placeholder={t("purchasingOrder:placeholder.total_value_from")}
                                                name="total_value[from]"
                                                value={total_value_from}
                                                onChange={_onChangeInputGroup}
                                                onPressEnter={_onSubmit}
                                            />
                                            <Input style={{ width: 40, textAlign: 'center', borderRight: "none", borderLeft: "none" }} disabled={true} placeholder="→"/>
                                            <Input
                                                className="_purchasing-orders-search-total_value_to site-input-right"
                                                style={{width: "calc(50% - 20px)", textAlign: 'center', borderLeft: "none"}}
                                                placeholder={t("purchasingOrder:placeholder.total_value_to")}
                                                name="total_value[to]"
                                                value={total_value_to}
                                                onChange={_onChangeInputGroup}
                                                onPressEnter={_onSubmit}
                                            />
                                        </Input.Group>
                                    </Form.Item>
                                </Col>

                                <Col xs={{span: 24}} lg={{span: 6}}>
                                    <Form.Item
                                        className={isMobile ? "mb-3" : "mb-0"}
                                        label={t("purchasingOrder:label.ordered_at")}
                                    >
                                        <RangePicker
                                            className="_purchasing-orders-search-ordered_at"
                                            value={(ordered_at_from && ordered_at_to) ? [moment(ordered_at_from), moment(ordered_at_to)] : undefined}
                                            format={dateFormat}
                                            placeholder={[t("label.time_start"), t("label.time_end")]}
                                            onChange={(date) => _onChangeDate("ordered_at", date)}
                                            style={{width: "100%"}}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={{span: 12}} lg={{span: 4}}>
                                    <Form.Item
                                        className="mb-3"
                                        label={t("purchasingOrder:label.status")}
                                    >
                                        <SelectPurchasingOrderStatus
                                            className="_purchasing-orders-search-status"
                                            placeholder={t("purchasingOrder:placeholder.status")}
                                            allowClear={true}
                                            value={status}
                                            onChange={value => _onChange("status", value)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col xs={{span: 12}} lg={{span: 4}}>
                                    <Form.Item
                                        className="mb-3"
                                        label={t("purchasingOrder:label.sku_code")}
                                    >
                                        <Input
                                            className="_purchasing-orders-search-sku_code"
                                            name="sku_code"
                                            value={sku_code}
                                            placeholder={t("purchasingOrder:placeholder.sku_code")}
                                            onChange={_onChangeInput}
                                            onPressEnter={_onSubmit}
                                        />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        <Col xs={{span: 24}} lg={{span: 10}} className="text-right">
                            <Form.Item
                                className="mb-0"
                                label={isMobile ? "" : " "}
                            >
                                <Button className="mr-2 _orders-search-btn-reset" onClick={_onReset} ><UndoOutlined/> {t("common:btn.reset")}</Button>
                                <Button type="primary" onClick={_onSubmit} className="_orders-search-btn-search"><SearchOutlined/> {t("common:btn.search")}</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    {
                        isMobile &&
                        <p className="mt-4 text-fz-13 text-center text-primary cursor-pointer" onClick={_onToggleMobile}>
                            {
                                showOnMobile
                                    ? <>Thu gọn tìm kiếm <UpOutlined /></>
                                    : <>Mở rộng tìm kiếm <DownOutlined /></>
                            }
                        </p>
                    }
                </div>
            </div>
        </Form>
    );
}

export default FormSearch;
