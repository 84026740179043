import { Select } from 'antd';
import React from 'react';

import { t } from '@System/i18n';
import { DROPSHIPPING_STATUS } from '../services/constants';



const { Option } = Select;

export default function SelectStatusDropship(props) {
    return (
        <Select
            {...props}
            value={props.value || undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {Object.keys(DROPSHIPPING_STATUS).map(opt => {
                return (
                    <Option key={DROPSHIPPING_STATUS[opt]} value={DROPSHIPPING_STATUS[opt]}>
                        {t(`product:DROPSHIP_STATUS.${DROPSHIPPING_STATUS[opt]}`)}
                    </Option>
                );
            })}
        </Select>
    );
}
