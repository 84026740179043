import React from "react";
import {useDispatch} from "react-redux";
import {addError, deleteErrorByKey, clearErrors, deleteError} from "@State/scanErrors/actions";
import {useScanErrorsOfType} from "@State/scanErrors/hooks";

export default function withScanErrors(WrappedComponent, ScanType) {
    return function (props) {
        const dispatch = useDispatch();
        const scanErrors = useScanErrorsOfType(ScanType);

        function add(payload) {
            dispatch(addError({
                type: ScanType,
                ...payload
            }));
        }

        function remove(index) {
            dispatch(deleteError({
                type: ScanType,
                index
            }));
        }

        function removeAll(key) {
            dispatch(deleteErrorByKey({
                type: ScanType,
                key
            }));
        }

        function clear() {
            dispatch(clearErrors(ScanType));
        }

        return (
            <WrappedComponent
                {...props}
                storeErrors={{add, remove, removeAll, clear}}
                scanType={ScanType}
                hasError={scanErrors?.length ?? 0}
                errors={scanErrors ?? []}
            />
        );
    }
}
