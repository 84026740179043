import { useQuery } from 'react-query';

import apiService from './../services/api';

const useMerchantsQuery = params =>
    useQuery(['merchants', params], () => apiService.getMerchants(params), {
        retry: false,
    });

export default useMerchantsQuery;
