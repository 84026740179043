import { Checkbox, Form, Select, Spin, Switch } from 'antd'
import { get, mapKeys } from 'lodash'
import React, { useState } from 'react'

import api from '@Modules/Product/services/api'
import { LOGIC_BATCH } from '@Modules/Product/services/constants'
import { INPUT_INVALID } from '@Modules/PurchasingPackage/services/constants'

import { t } from '@System/i18n'
import notification from '@System/notification'

const { Option } = Select

const SwitchLogic = ({ onChangeInfo, fetchData, logicBatch, isBatch, variation, index }) => {
    const [openLogic, setOpenLogic] = useState(false)
    const [loadingLogic, setLoadingLogic] = useState(false)
    const [checkOpen, setCheckOpen] = useState(false)

    const onChangeLogic = (name, item, value, index) => {
        if (!value) {
            postChangeBatchLogic(item.id, {
                is_batch: false,
            })

            return
        }

        setCheckOpen(item.id)
        onOpenLogic(item.id)
        onChangeInfo(name, { ...item, [name]: value }, index)
    }

    const onOpenLogic = id => {
        setOpenLogic(id)
    }

    const onHideLogic = (name, item, index) => {
        setOpenLogic(false)
        if (item.id === checkOpen) {
            onChangeInfo(name, { ...item, [name]: false }, index)
        }
    }

    const handleChangeLogicBatch = (value, id) => {
        postChangeBatchLogic(id, {
            is_batch: true,
            logic_batch: value,
        })
    }

    const postChangeBatchLogic = (id, data) => {
        setLoadingLogic(true)
        api.changeLogicBatch(id, data)
            .then(result => {
                setCheckOpen(false)
                notification.success(t('product:message.update_batch_logic_success'))
                fetchData()
            })
            .catch(err => {
                const code = get(err, 'response.data.code')
                const data = get(err, 'response.data.data')

                if (code === INPUT_INVALID) {
                    mapKeys(data, (value, key) => {
                        notification.error(t(`packing:message.${key}.${Object.keys(value)[0]}`))
                    })
                } else {
                    notification.error(t('product:message.update_batch_logic_fail'))
                }
            })
            .finally(() => {
                setLoadingLogic(false)
            })
    }

    return (
        <div>
            <Spin spinning={loadingLogic}>
                <div className="">
                    <div className='text-right mb-1 font-weight-500'>{t('sku:label.batch_manage')}</div>
                    <div className='d-flex align-items-center justify-content-end'>
                        {isBatch ? (
                            <Select
                                value={checkOpen !== variation.id ? logicBatch : undefined}
                                className="mr-2"
                                open={openLogic === variation.id}
                                loading={loadingLogic}
                                onDropdownVisibleChange={value => {
                                    if (!value) {
                                        onHideLogic('is_batch', variation, index)
                                    } else {
                                        onOpenLogic(variation.id)
                                    }
                                }}
                                style={{ minWidth: 272 }}
                                onChange={value => handleChangeLogicBatch(value, variation.id)}
                            >
                                {Object.keys(LOGIC_BATCH).map(item => (
                                    <Option
                                        value={item}
                                        key={item}
                                    >
                                        {t(`sku:LOGIC_BATCH.${item}`)}
                                    </Option>
                                ))}
                            </Select>
                        ) : null}
                        <Form.Item
                            name={['is_batch', isBatch]}
                            className="mb-0"
                            // valuePropName="checked"
                        >
                            <Checkbox
                                checked={isBatch}
                                loading={loadingLogic}
                                onChange={e => onChangeLogic('is_batch', variation, e.target.checked, index)}
                            />
                        </Form.Item>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default SwitchLogic
