import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Row } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import SelectAccounts from '@Modules/Account/components/SelectAccounts'
import SelectFinanceControlStatus from '@Modules/Finance/components/SelectFinanceControlStatus'
import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner'

import { t } from '@System/i18n'
import { classes } from '@System/support/helpers'

const dateFormat = 'DD/MM/YYYY'

const { RangePicker } = DatePicker

const FormSearch = ({ onSearch, filters, loading }) => {
    const { xs, md } = useBreakpoint()
    const [form] = useForm()
    const [input, setInput] = useState(filters)

    const { creator_id, code, shipping_partner_id, status } = input || {}

    useEffect(() => {
        setInput(filters)
    }, [filters])

    const handleChangeRangePicker = (name, dates) => {
        const name_from = `${name}[from]`
        const name_to = `${name}[to]`

        const filters = {
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        }

        setInput(filters)
        onFinish(filters)
    }

    const handleChangeInput = e => {
        const name = e.target.name
        const value = e.target.value
        setInput(prevState => {
            return { ...prevState, ...input, [name]: value }
        })
    }

    const handleChangeSelect = (value, field) => {
        const filters = { ...input, [field]: value }
        setInput(filters)
        onFinish(filters)
    }

    const onFinish = filters => {
        onSearch({ ...input, ...filters })
    }

    const clearFilter = () => {
        onSearch({})
    }

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}>
                <div className="bg-white rounded-12 p-4 mt-3">
                    <div className="search-box">
                        <Row gutter={20}>
                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 6 }}>
                                <Form.Item
                                    className={xs || md ? 'mb-3' : 'mb-0'}
                                    label={t('document:label.code')}>
                                    <Input
                                        name="code"
                                        value={code}
                                        placeholder={t('document:label.code')}
                                        onChange={handleChangeInput}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 6 }}>
                                <Form.Item
                                    className="mb-3"
                                    label={t('document:label.creator_username')}>
                                    <SelectAccounts
                                        className="_document-search-creator-id"
                                        allowClear={true}
                                        placeholder={t('document:placeholder.creator')}
                                        value={creator_id ? parseInt(creator_id) : undefined}
                                        onChange={value => handleChangeSelect(value, 'creator_id')}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 6 }}>
                                <Form.Item
                                    className="mb-3"
                                    label={t('order:label.order_shipping_partner')}>
                                    <SelectShippingPartner
                                        className="_purchasing-package-search-shipping-partner-id"
                                        allowClear={true}
                                        placeholder={t('placeholder.shipping_partner')}
                                        onChange={value => handleChangeSelect(value, 'shipping_partner_id')}
                                        value={shipping_partner_id ? parseInt(shipping_partner_id) : undefined}
                                        locationByUser
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 6 }}>
                                <Form.Item
                                    className="mb-3"
                                    label={t('label.status')}>
                                    <SelectFinanceControlStatus
                                        allowClear={true}
                                        placeholder={t('document:placeholder.status')}
                                        value={status ? status : undefined}
                                        onChange={value => handleChangeSelect(value, 'status')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}>
                                <Form.Item
                                    className={xs || md ? 'mb-3' : 'mb-0'}
                                    label={t('document:label.verified_at')}>
                                    <RangePicker
                                        className="_finance-search-created-at"
                                        value={
                                            input['verified_at[from]'] && input['verified_at[to]']
                                                ? [moment(input['verified_at[from]']), moment(input['verified_at[to]'])]
                                                : undefined
                                        }
                                        onChange={value => handleChangeRangePicker('verified_at', value)}
                                        placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                        format={dateFormat}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}>
                                <Form.Item
                                    className={xs || md ? 'mb-3' : 'mb-0'}
                                    label={t('finance:label.date_receipt_documents')}>
                                    <RangePicker
                                        className="_finance-search-created-at"
                                        value={
                                            input['received_date[from]'] && input['received_date[to]']
                                                ? [moment(input['received_date[from]']), moment(input['received_date[to]'])]
                                                : undefined
                                        }
                                        onChange={value => handleChangeRangePicker('received_date', value)}
                                        placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                        format={dateFormat}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col
                                span={24}
                                className={classes('text-right', {
                                    'mt-3': !md,
                                })}>
                                <Button
                                    className="mr-2 _finance-search-btn-reset"
                                    onClick={clearFilter}>
                                    <UndoOutlined /> {t('common:btn.set_again')}
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="_finance-search-btn-search"
                                    loading={loading}>
                                    <SearchOutlined /> {t('common:btn.search')}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default FormSearch
