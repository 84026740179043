import { List, Typography } from 'antd';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React from 'react';

import { t } from './../../../../../../system/i18n';
import { Link } from '../../../../../../system/routing';

import Badge from './../../../../../App/Badge';
import Space from './../../../../../App/Space';
import {getVar} from "@System/support/helpers";

const { Paragraph, Title } = Typography;

const ProductList = ({ products, loading }) => {
    return (
        <div>
            <Space className="mb-3" type="flex">
                <Title className="mb-0" level={5}>
                    {t('product_list')}
                </Title>
                <span className="number-circle">{products.length}</span>
            </Space>

            <List
                loading={loading}
                dataSource={products}
                footer={(
                    <Space justifyContent="between" type="flex">
                        <strong>{t('sum')}</strong>
                        <strong>{sumBy(products, item => {
                            return Number(getVar(item, 'order_packing_quantity', 0));
                        })}</strong>
                    </Space>
                )}
                header={(
                    <Space justifyContent="between" type="flex">
                        <Paragraph className="mb-0">SKU</Paragraph>
                        <Paragraph className="mb-0">{t('quantity')}</Paragraph>
                    </Space>
                )}
                renderItem={product => (
                    <List.Item key={product.id}>
                        <List.Item.Meta
                            description={product?.sku?.name}
                            title={(
                                <Link
                                    className="text-primary"
                                    isNewTab
                                    params={{
                                        id: product?.sku?.product_id
                                    }}
                                    to="products.detail"
                                >
                                    {product?.sku?.code}
                                </Link>
                            )}
                        />
                        <div>{product.order_packing_quantity}</div>
                    </List.Item>
                )}
            />
        </div>
    );
};

ProductList.propTypes = {
    products: PropTypes.array
};

ProductList.defaultProps = {
    products: []
};

export default ProductList;
