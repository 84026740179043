import { Button, DatePicker, Form, Input, InputNumber, Modal } from 'antd'
import { get, mapKeys } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput'

import { t } from '@System/i18n'
import notification from '@System/notification'

import api from '../services/api'
import { INPUT_INVALID } from '../services/constants'

const CreateBathOfGoods = ({ skuId }) => {
    const [form] = Form.useForm()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        form.validateFields().then(result => {
            const data = {
               
                ...result,
                expiration_at: moment(result.expiration_at).format('YYYY-MM-DD'),
                production_at: moment(result.production_at).format('YYYY-MM-DD'),
            }
            setLoading(true)

            api.createBatchOfGoods(skuId, data)
                .then(result => {
                    handleCancel()
                    notification.success(t('packing:message.create_batch_success'))
                })
                .catch(err => {
                    const code = get(err, 'response.data.code')
                    const data = get(err, 'response.data.data')

                    if (code === INPUT_INVALID) {
                        mapKeys(data, (value, key) => {
                            notification.error(t(`packing:message.${Object.keys(value)[0]}`,{
                                attribute :t(`packing:label.${key}`)
                            }))
                        })
                    } else {
                        notification.error(t('packing:message.create_batch_fail'))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }
    const handleCancel = () => {
        form.resetFields()
        setIsModalOpen(false)
    }

    return (
        <>
            <Button
                className="mr-2"
                onClick={showModal}
                type="primary"
            >
                {t('packing:btn.create_bath')}
            </Button>

            {isModalOpen && (
                <Modal
                    title={t('packing:btn.create_bath')}
                    visible={true}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    cancelText={t('btn.cancel')}
                    okText={t('btn.ok')}
                    okButtonProps={{
                        loading,
                        disabled: loading,
                    }}
                >
                    <Form form={form}>
                        <Form.Item
                            labelAlign={'left'}
                            labelCol={{ span: 7 }}
                            label={t('packing:label.batch_number')}
                            rules={[
                                {
                                    required: true,
                                    message: t('order:message.required', { attribute: t('packing:label.batch_number') }),
                                },
                                {
                                    pattern: new RegExp(/^[^!@#\$%\^&*()\=\[\]{};':"\\|,.<>\/?]*$/),
                                    message: t('packing:message.no_special', {
                                        attribute: t('packing:label.batch_number'),
                                    }),
                                },
                            ]}
                            name="code"
                        >
                            <Input
                                placeholder={t('packing:placeholder.batch_number')}
                                min={0}
                            />
                        </Form.Item>
                        <Form.Item
                            labelAlign={'left'}
                            labelCol={{ span: 7 }}
                            label={t('packing:label.production_at')}
                            rules={[
                                {
                                    required: true,
                                    message: t('order:message.required', { attribute: t('packing:label.production_at') }),
                                },
                            ]}
                            name="production_at"
                        >
                            <DatePicker
                                placeholder={t('packing:placeholder.production_at')}
                                style={{ width: '100%' }}
                                format={'DD/MM/YYYY'}
                                allowClear={false}
                                disabledDate={(current) => {
                                    // Can not select days before today and today
                                    return current && current > moment().endOf('day');
                                  }} 
                            />
                        </Form.Item>
                        <Form.Item
                            labelAlign={'left'}
                            labelCol={{ span: 7 }}
                            label={t('packing:label.expiration_at')}
                            rules={[
                                {
                                    required: true,
                                    message: t('order:message.required', { attribute: t('packing:label.expiration_at') }),
                                },
                            ]}
                            name="expiration_at"
                        >
                            <DatePicker
                                placeholder={t('packing:placeholder.expiration_at')}
                                style={{ width: '100%' }}
                                format={'DD/MM/YYYY'}
                                allowClear={false}
                                disabledDate={(current) => {
                                    // Can not select days before today and today
                                    return current && current <  moment().endOf('day') ;
                                  }} 
                            />
                        </Form.Item>

                        <Form.Item
                            labelAlign={'left'}
                            labelCol={{ span: 7 }}
                            label={t('packing:label.cost_of_goods')}
                            name="cost_of_goods"
                        >
                            <CurrencyInput
                                style={{ width: '100%' }}
                                className="text-left"
                                placeholder={t('packing:placeholder.cost_of_goods')}
                                onBlur={value => {
                                    form.setFields([{ name: 'cost_of_goods', value: value }])
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default CreateBathOfGoods
