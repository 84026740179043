import { EditOutlined, CloseSquareOutlined } from '@ant-design/icons';
import { Button, notification, Table, Tooltip, Modal } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { events } from '../../../../system/events';
import { t } from '../../../../system/i18n';
import { trans } from '../../../../system/i18n';
import TableEmpty from '../../../App/Utilities/TableEmpty';
import { auth } from '../../../Auth/services';
import PERMISSIONS from '../../../Auth/services/permissions';
import { EVENTS } from '../../../WarehouseArea/services/constants';
import warehouseAreaApi from '../../services/api';
import UpdateWarehouseArea from '../UpdateWarehouseArea';
import FormSearch from './FormSearch';
import useTableSelection from "@Modules/App/Hooks/useTableSelection";
import PrintWarehouseAreaBarcode from "@Modules/WarehouseArea/components/PrintWarehouseAreaBarcode";

function ListWarehouseAreaByWarehouse({ warehouseId }) {
    const [filters, setFilters] = useState({});
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [warehouseAreas, setWarehouseAreas] = useState([]);
    const [warehouseAreaInfo, setWarehouseAreaInfo] = useState({});
    const { rowSelection, selectedRowKeys } = useTableSelection({});

    function fetchWarehouseAreas() {
        return warehouseAreaApi
            .getWarehouseAreas(warehouseId, filters)
            .then(res => {
                setWarehouseAreas(_.get(res, 'data.areas', []));
            })
            .catch(err => {
                const status = _.get(err, 'response.status', null);
                if (status === 403) notification.error({ message: t('common:message.403') });
                else if (status === 404) notification.error({ message: t('common:message.404') });
                else
                    notification.error({
                        message: t('common:message.fetch_fail', { attribute: t('common:label.warehouse_area') }),
                    });
            });
    }

    useEffect(() => {
        setLoading(true);
        fetchWarehouseAreas().finally(() => setLoading(false));

        // eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        return events.listen(EVENTS.FETCH_WAREHOUSE_AREAS_AGAIN, (event, payload) => {
            fetchWarehouseAreas();
            setFilters({});
        });

        // eslint-disable-next-line
    }, []);

    function handleEditWarehouseArea(warehouseAreaInfo) {
        setVisible(true);
        setWarehouseAreaInfo(warehouseAreaInfo);
    }

    function handleDeleteWarehouseArea(warehouseAreaId) {
        warehouseAreaApi
            .deleteWarehouseArea(warehouseAreaId)
            .then(res => {
                fetchWarehouseAreas();
            })
            .catch(err => {
                const status = _.get(err, 'response.status', null);
                const data = _.get(err, 'response.data.data', null);
                if (data.hasOwnProperty('stock.quantity')) {
                    notification.error({ message: t('warehouseArea:message.stock_quantity_invalid') });
                } else {
                    if (status === 403) notification.error({ message: t('common:message.403') });
                    else if (status === 404) notification.error({ message: t('common:message.404') });
                    else notification.error({ message: t('warehouseArea:message.delete_fail') });
                }
            });
    }

    function createWarehouseArea() {
        setWarehouseAreaInfo({});
        setVisible(true);
    }

    function handleConfirmDeleteWarehouseArea(warehouseAreaInfo) {
        const info = '[' + warehouseAreaInfo.code + ' - ' + warehouseAreaInfo.name + ']';
        Modal.confirm({
            title: t('warehouseArea:title.content_confirm_delete_warehouse_area'),
            okText: t('common:btn.confirm'),
            okButtonProps: { loading },
            cancelText: t('common:btn.cancel'),
            content: trans('warehouseArea:message.content_confirm_delete_warehouse_area', {
                attribute: <strong>{info.replace(/(<([^>]+)>)/gi, ' ')}</strong>,
            }),
            onOk: () => handleDeleteWarehouseArea(warehouseAreaInfo.id),
        });
    }

    const columns = [
        {
            title: t('warehouseArea:label.code'),
            dataIndex: 'warehouseArea.code',
            key: 'warehouseArea.code',
            width: '25%',
            render: (text, record) => {
                const warehouseArea = _.get(record, 'warehouseArea', {});
                return <p>{warehouseArea.code === 'default' ? t('order:label.default') : warehouseArea.code}</p>;
            },
        },
        {
            title: t('warehouseArea:label.name'),
            dataIndex: 'warehouseArea.name',
            key: 'warehouseArea.name',
            width: '25%',
            render: (text, record) => {
                const warehouseArea = _.get(record, 'warehouseArea', {});
                return <p>{warehouseArea.name === 'default' ? t('order:label.default') : warehouseArea.name}</p>;
            },
        },
        {
            title: t('warehouseArea:label.note'),
            dataIndex: 'warehouseArea.description',
            key: 'warehouseArea.description',
            width: '30%',
            render: (text, record) => {
                const warehouseArea = _.get(record, 'warehouseArea', {});
                return <p>{warehouseArea.description}</p>;
            },
        },
        {
            title: '',
            render: (text, record) => {
                const code = _.get(record, 'warehouseArea.code', '');
                if (code === 'default') return null;
                return (
                    <>
                        {auth.canAny([PERMISSIONS.WAREHOUSE_CREATE_AREA]) && (
                            <div className="d-flex justify-content-end">
                                <Button type="link" onClick={() => handleEditWarehouseArea(record)}>
                                    <Tooltip title={t('warehouseArea:title.edit')}>
                                        <EditOutlined style={{ fontSize: '16px', color: '#08c' }} />
                                    </Tooltip>
                                </Button>
                                <Button
                                    type="link"
                                    onClick={() => handleConfirmDeleteWarehouseArea(_.get(record, 'warehouseArea', {}))}
                                >
                                    <Tooltip title={t('warehouseArea:title.delete')}>
                                        <CloseSquareOutlined style={{ fontSize: '16px', color: '#cc2e53' }} />
                                    </Tooltip>
                                </Button>
                            </div>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <>
            <div className="pr-3">
                <div className="mb-2 d-flex justify-content-end">
                    {auth.can([PERMISSIONS.WAREHOUSE_CREATE_AREA]) && (
                        <Button
                            className="float-right text-uppercase mt-2 ml-2"
                            style={{ backgroundColor: '#407a13' }}
                            type="primary"
                            onClick={() => createWarehouseArea()}
                        >
                            {t('warehouseArea:btn.create')}
                        </Button>
                    )}
                </div>
            </div>

            <div className="m-3">
                <FormSearch addFilter={setFilters} filters={filters} loading={loading} />
                <div className={"p-3 text-right"}>
                    <PrintWarehouseAreaBarcode className="mr-3" warehouseAreaIds={selectedRowKeys} data={warehouseAreas} />
                </div>

                <Table
                    className="mt-2"
                    rowSelection={rowSelection}
                    rowKey={record => record.warehouseArea.id}
                    loading={loading}
                    dataSource={warehouseAreas}
                    columns={columns}
                    pagination={false}
                    locale={{ emptyText: <TableEmpty /> }}
                />
            </div>
            {visible && (
                <UpdateWarehouseArea
                    visible={visible}
                    setVisible={setVisible}
                    warehouseAreaInfo={warehouseAreaInfo}
                    warehouseId={warehouseId}
                />
            )}
        </>
    );
}

export default ListWarehouseAreaByWarehouse;
