import { useQuery } from 'react-query';

import apiService from './../services/api';

const useOrderPackingsQuery = skuId =>
    useQuery(['sku-order-packings', skuId], () => apiService.orderPackings(skuId), {
        enabled: !!skuId,
        retry: false,
    });

export default useOrderPackingsQuery;
