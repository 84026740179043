import React, {useState} from 'react';
import {Layout} from 'antd';
import PageHeader from "../modules/App/Header";
import MenuLeft from "../modules/App/MenuLeft";
import {QueryClient, QueryClientProvider} from 'react-query';
import ProfileMobile from "../modules/App/ProfileMobile";
import {auth} from "@Modules/Auth/services";
import {tracker} from "@Config/tracker";

const {Content} = Layout;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false
        }
    }
});

export default function DefaultLayout({children}) {
    const [isShown, setIsShown] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [status, setStatus] = useState("");

    function _onExpand() {
        setStatus(expanded ? 'collapsing' : 'expanding');
        setExpanded(!expanded);
        resetStatus();
    }

    function _onClick() {
        if (expanded)
            _onExpand();
    }

    const resetStatus = () => setTimeout(() => setStatus(""), 200);
    const user = auth.user();
    if (user && window?.APP_CONFIG?.TRACKING) {
       tracker.setUserID(user.email);
    }

    return (
        <QueryClientProvider client={queryClient}>
            <div id="page-body">
                <div id="page-menu">
                    <MenuLeft
                        expanded={expanded}
                        _onExpand={_onExpand}
                        _onClick={_onClick}
                        status={status}
                    />
                </div>
                <div id="page-content">
                    <Layout className="min-h-100">
                        <PageHeader
                            _onExpand={_onExpand}
                            isShown={isShown}
                            setIsShown={setIsShown}
                        />
                        <Content className="d-flex flex-column">{children}</Content>
                    </Layout>
                </div>
                <ProfileMobile
                    isShown={isShown}
                    setIsShown={setIsShown}
                />
            </div>
        </QueryClientProvider>
    );
}
