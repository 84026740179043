import { Card, Form, Modal } from 'antd';
import { every, flatMapDeep, map } from 'lodash';
import concat from 'lodash/concat';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import values from 'lodash/values';
import { async } from 'q';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import Loading from '@Modules/App/Loading';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { FREIGHT_BILL_STATUS } from '@Modules/App/services/constants';
import { updateCollectionItem } from '@Modules/App/services/helpers';
import useDeleteImportingDocumentMutation from '@Modules/Document/Hooks/useDeleteImportingDocumentMutation';
import useImportMutation from '@Modules/Document/Hooks/useImportMutation';
import useImportingReturnOrderDocumentQuery from '@Modules/Document/Hooks/useImportingReturnOrderDocumentQuery';
import useUpdateSkuImportDocumentMutation from '@Modules/Document/Hooks/useUpdateSkuImportingDocumentMutation';
import BodyInfo from '@Modules/Document/screens/Importing/ReturnOrder/Detail/BodyInfo';
import api from '@Modules/Document/services/api';

import { t, trans } from '@System/i18n';
import notification from '@System/notification';
import { getVar } from '@System/support/helpers';

import Header from './Header';
import LeavePageBlocker from '../../components/LeavePageBlocker';

const ImportingReturnOrderDetail = props => {

    const { match } = props;
    const documentId = match.params.id;
    const [form] = Form.useForm();
    const [importingDocument, setImportingDocument] = useState({});
    const [skusImporting, setSkusImporting] = useState([]);
    const [importingListBarcode, setImportingListBarcode] = useState([]);
    const [documentSkusDelete, setDocumentSkusDelete] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [freightBills, setFreightBills] = useState([]);
    const [isChanged, setIsChanged] = useState(false);
    const [loadingCancel, setLoadingCancel] = useState(false);
    const [dataImportingBarcode, setDataImportingBarcode] = useState([]);
    const [errorAlert, setErrorAlert] = useState([]);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const childRef = React.useRef();

    const { error, isError, isLoading, data, refetch } = useImportingReturnOrderDocumentQuery(documentId);
    const { mutateAsync: deleteImportingDocument } = useDeleteImportingDocumentMutation({
        id: documentId,
        type: importingDocument?.type,
        onSuccess: data => {
            setImportingDocument(data);
            setConfirmDelete(false);
            setLoadingCancel(false);
        },
        onMutate: () => {
            setLoadingCancel(true);
        },
    });
    const { mutateAsync: importDocument, isLoading: isImporting } = useImportMutation({
        id: match.params.id,
        type: importingDocument?.type,
        onSuccess: data => {
            setImportingDocument(data);
            setConfirm(false);
        },
    });

    const { isLoading: updating, mutate: updateSkuImportingDocument } = useUpdateSkuImportDocumentMutation({
        onSuccess: data => {
            setSkusImporting(refactorDataSkusImporting(data?.document_sku_importings));
            setIsChanged(false);
        },
    });

    useEffect(() => {
        setNavigator(trans('document:types.importing_return_order'), [
            {
                name: trans('document:list.importing_return_order'),
                route: 'documents.importing.list',
            },
            {
                name: trans('document:detail.importing_return_order'),
            },
        ]);
    }, []);

    useEffect(() => {
        setImportingDocument(get(data?.data, 'document', {}));
        const importing_barcodes = get(data?.data, 'importing_barcodes', []);
        setImportingListBarcode(importing_barcodes);
        handleContentConfirm(importing_barcodes);
    }, [data]);

    useEffect(() => {
        setDocumentTitle(
            t('title_page.document_importing_return_order', { document_code: get(importingDocument, 'code', '') })
        );
    }, [importingDocument]);

    useEffect(() => {
        function convertData() {
            const waybills = [];
            importingListBarcode.map(item => {
                const skus = map(get(item, 'importing_barcode.snapshot_skus.skus', []), snapSku => {
                    map(get(item, 'order_skus', []), orderSkus => {
                        if (snapSku.id === orderSkus.sku_id) {
                            snapSku['current_sku'] = orderSkus.quantity;
                        }
                    });

                    return {
                        ...snapSku,
                        real_quantity: snapSku.quantity,
                    };
                });

                waybills.push({
                    freight_bill: get(item, 'freight_bill', {}),
                    order: get(item, 'order', {}),
                    skus: skus,
                    scanner: get(item, 'importing_barcode.snapshot_skus.scanner', ''),
                });
                return true;
            });
            setDataImportingBarcode(waybills);
        }
        convertData();
    }, [importingListBarcode]);

    function handleContentConfirm(data) {
        const freight_bills = [];
        data.map(item => {
            const status = get(item, 'freight_bill.status', '');
            const freight_bill = get(item, 'freight_bill.freight_bill_code', '');
            if (status !== FREIGHT_BILL_STATUS.RETURN) {
                freight_bills.push(freight_bill);
            }
            return true;
        });
        setFreightBills(freight_bills);
    }

    function handleImport() {
        if (!checkError()) {
            return;
        }

        setConfirm(true);
    }

    function handleDelete() {
        setConfirmDelete(true);
    }

    const onSuccessUpdate = useCallback(data => {
        setImportingDocument(data);
    }, []);

    async function handleSave() {
        if (!checkError()) {
            return;
        }

        await form.validateFields().then(data => {
            const skus = concat(values(data), documentSkusDelete);

            updateSkuImportingDocument({
                documentId,
                data: { skus },
            });

            onUpdateImportingReturnGoods();
        });
    }

    const checkError = () => {
        const newErrors = map(dataImportingBarcode, item => {
            const skuElert = item?.skus.map(sku => ({ alert: sku?.real_quantity > sku?.current_sku }));
            return skuElert;
        });

        const isError = every(flatMapDeep(newErrors), ['alert', false]);
        setErrorAlert(newErrors);

        return isError;
    };

    function handleSaveAndConfirm() {
        form.validateFields().then(data => {
            const skus = concat(values(data), documentSkusDelete);

            updateSkuImportingDocument(
                {
                    documentId,
                    data: { skus },
                },
                {
                    onSuccess: data => {
                        importDocument();
                        form.resetFields();
                    },
                }
            );
        });
    }

    const onUpdateImportingReturnGoods = async () => {
        const newDataImportingBarcode = {
            order_items: map(dataImportingBarcode, item => ({
                id: item.order.id,
                skus: item.skus.map(item => ({
                    id: item.id,
                    quantity: item.real_quantity,
                })),
            })),
        };

        let checkError = false;
        setLoadingUpdate(true);
        await api
            .updateImportingReturnGoods(documentId, newDataImportingBarcode)
            .then(res => {
                notification.success(t('message.update_success'));
                checkError = true;
            })
            .catch(res => {
                notification.error(t('message.update_failed_by_attribute'));
                checkError = false;
            })
            .finally(() => {
                setLoadingUpdate(false);
            });

        return checkError;
    };

    async function handleImportDocument() {
        try {
            const res = await onUpdateImportingReturnGoods();
            if (isChanged) {
                if (res) {
                    await handleSaveAndConfirm();
                }
            } else {
                if (res) {
                    await importDocument();
                }
            }
            childRef.current?.refetchImportingDocument();
            refetch();
        } catch (e) {
            notification.error(t('message.update_failed_by_attribute'));
        }
    }

    const refactorDataSkusImporting = data => {
        let skus = [];
        if (!isEmpty(data)) {
            data.map(item => {
                const documentSkuId = get(item, 'document_sku_importing.id', undefined);
                const sku_id = get(item, 'sku.id', '');
                const existSkuParent = find(skus, { id: sku_id, isParent: true });
                const info = {
                    documentSkuId,
                    code: get(item, 'sku.code', ''),
                    id: get(item, 'sku.id', ''),
                    name: get(item, 'sku.name', ''),
                    product_id: get(item, 'sku.product_id', ''),
                    width: get(item, 'sku.width', '') * 1000,
                    length: get(item, 'sku.length', '') * 1000,
                    height: get(item, 'sku.height', '') * 1000,
                    weight: get(item, 'sku.weight', '') * 1000,
                    lack_of_information: get(item, 'index', undefined), // Nếu index === false thì sản phẩm thiếu thông tin cân nặng hoặc kích thước
                    real_quantity: get(item, 'document_sku_importing.real_quantity', 0),
                    quantity: get(item, 'document_sku_importing.quantity', 0),
                    warehouse_area_id: get(item, 'document_sku_importing.warehouse_area_id', undefined),
                    warehouse_area: getVar(item, 'warehouse_area', {}),
                };

                if (isEmpty(existSkuParent)) {
                    return skus.push({
                        ...info,
                        key: get(item, 'sku.id', ''),
                        isParent: true,
                        rowSpan: 1,
                    });
                } else {
                    skus = updateCollectionItem(
                        skus,
                        existSkuParent.key,
                        { rowSpan: existSkuParent.rowSpan + 1 },
                        'key'
                    );
                    return skus.push({
                        ...info,
                        key: v4(),
                        isParent: false,
                        rowSpan: 0,
                    });
                }
            });
        }
        return skus;
    };

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        const status = get(error, 'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    return (
        <>

            <Card
                className="flex-grow-1 bg-white m-4"
                title={
                    <Header
                        documentId={documentId}
                        importingDocument={importingDocument}
                        handleDelete={handleDelete}
                        handleImport={handleImport}
                        handleSave={handleSave}
                        updating={updating}
                        isChanged={isChanged}
                        onSuccessUpdate={onSuccessUpdate}
                        isImporting={isImporting}
                        errorAlert={errorAlert}
                    />
                }
            >
                {documentId && (
                    <BodyInfo
                        form={form}
                        skusImporting={skusImporting}
                        setSkusImporting={setSkusImporting}
                        importingListBarcode={importingListBarcode}
                        documentId={documentId}
                        importingDocument={importingDocument}
                        onSuccessUpdate={onSuccessUpdate}
                        isChanged={isChanged}
                        setIsChanged={setIsChanged}
                        handleSave={handleSave}
                        setDocumentSkusDelete={setDocumentSkusDelete}
                        documentSkusDelete={documentSkusDelete}
                        refactorDataSkusImporting={refactorDataSkusImporting}
                        setDataImportingBarcode={setDataImportingBarcode}
                        dataImportingBarcode={dataImportingBarcode}
                        errorAlert={errorAlert}
                        setErrorAlert={setErrorAlert}
                        ref={childRef}
                    />
                )}
            </Card>
            {confirmDelete && (
                <Modal
                    title={trans('document:delete.title')}
                    visible={confirmDelete}
                    okText={trans('btn.confirm')}
                    cancelText={trans('btn.close')}
                    onOk={deleteImportingDocument}
                    onCancel={() => setConfirmDelete(false)}
                    cancelButtonProps={{ className: '_modal-confirm-delete-importing-document-btn-cancel' }}
                    okButtonProps={{
                        className: '_modal-confirm-delete-importing-document-btn-ok',
                        loading: loadingCancel,
                    }}
                >
                    <p>{trans('document:delete.confirm', { code: <b>#{importingDocument?.code}</b> })}</p>
                </Modal>
            )}
            {confirm && (
                <Modal
                    title={trans('document:import_confirm.title')}
                    visible={confirm}
                    okText={trans('btn.confirm')}
                    cancelText={trans('btn.close')}
                    onOk={handleImportDocument}
                    onCancel={() => setConfirm(false)}
                    cancelButtonProps={{ className: '_modal-confirm-importing-btn-cancel' }}
                    okButtonProps={{
                        className: '_modal-confirm-importing-btn-ok',
                        loading: isImporting || loadingUpdate,
                    }}
                >
                    {freightBills.length > 0 ? (
                        <>
                            <p>
                                {trans('document:import_confirm.confirm_return_order', {
                                    status: <b>{trans('order:FREIGHT_BILL_STATUS.RETURN')}</b>,
                                })}
                            </p>
                            <br />
                            {freightBills.join(', ')}
                        </>
                    ) : (
                        <p>{trans('document:import_confirm.confirm', { code: <b>#{importingDocument?.code}</b> })}</p>
                    )}
                </Modal>
            )}
        </>
    );
};

export default ImportingReturnOrderDetail;
