import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch, Tooltip } from 'antd'
import React, { useState } from 'react'

import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'

import { t } from '@System/i18n'
import notification from '@System/notification'

import { ISettings } from '../interfaces'
import api from '../services/api'

interface ICompulsorySizePurchasePackage {
    getSettings: () => void
    setting: Pick<ISettings, 'document_importing'>
}

const CompulsorySizePurchasePackage: React.FC<ICompulsorySizePurchasePackage> = ({ getSettings, setting }) => {
    const [loadingSwitch, setLoadingSwitch] = useState(false)

    const handleChangeLogicBillLading = async (checked: boolean) => {
        setLoadingSwitch(true)
        try {
            await api.changeCompulsorySizePurchasePackage({
                document_importing: checked,
            })
            getSettings()
            notification.success(t('message.config_success'))
        } catch (error) {
            notification.error(t('message.config_failed'))
        } finally {
            setLoadingSwitch(false)
        }
    }

    return (
        <Tooltip
            title={!auth.can(permissions.ADMIN_SYSTEM_CONFIG) && t('message.unauthorized')}
            className="mb-2"
        >
            <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={setting?.document_importing}
                onChange={handleChangeLogicBillLading}
                loading={loadingSwitch}
                disabled={!auth.can(permissions.ADMIN_SYSTEM_CONFIG)}
                className="__setting_document_importing"
            />
        </Tooltip>
    )
}

export default CompulsorySizePurchasePackage
