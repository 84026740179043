import { Select } from 'antd'
import { get, map } from 'lodash'
import React, { useEffect, useState } from 'react'

import api from '@Modules/Merchant/services/api'

const { Option } = Select

export default function SelectMerchantSuggest({ loading, countryId, sellers, ...props }) {
    let [data, setData] = useState([])
    let [isLoading, setIsLoading] = useState(false)
    const idSellers = map(sellers, 'merchant.id')
    useEffect(() => {
        const getMerchantSuggest = () => {
            setIsLoading(true)
            api.getAllMerchants({ assigned: true, country_id: countryId, 'requires[]': 'service_pack' })
                .then(res => {
                    setData(get(res, 'data.merchants'))
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
        getMerchantSuggest()
    }, [countryId])

    return (
        <Select
            {...props}
            filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            loading={isLoading || loading}
            optionFilterProp="label"
            showSearch
        >
            {data?.map((item: any) => {
                return (
                    !idSellers.includes(get(item, 'id')) && (
                        <Option
                            key={get(item, 'id')}
                            value={get(item, 'id')}
                            data={item}
                        >
                            {`${item?.code}${item?.name ? ` - ${item?.name}` : ''}`}
                        </Option>
                    )
                )
            })}
        </Select>
    )
}
