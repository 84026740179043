export default {
    NOT_EXIST: "not_exist",
    ALREADY_EXIST: "already_exist",
    REQUIRED: "required",
    GTE: "gte",
    NUMERIC: "numeric",
    INVALID: "invalid",
    GREATER: "greater",
    EMPTY: "empty",
    EXISTS: "exists",
    DUPLICATED: "duplicated"
}
