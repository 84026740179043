import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useScanBarcodeMutation = createUseMutation({
    namespace: 'document:sku_inventories_document.scan',
    mutation: data => apiService.scan(data),
});

export default useScanBarcodeMutation;
