import React, {useEffect, useState} from "react";
import {Select} from "antd";
import ServiceAccounts from "../services/ServiceAccounts";

const {Option} = Select;

export default function SelectAccounts(props) {
    let [accounts, setAccounts] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceAccounts.list()
            .then(res => {
                setAccounts(res);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    return (
        <Select
            loading={isLoading}
            {...props}
            value={(accounts.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            // filterOption={(input, option) =>
            //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
        >
            {
                accounts.length > 0 &&
                accounts.map(opt => {
                    return <Option
                        key={opt.id}
                        value={opt.id}
                        label={opt.name + (opt.username ? " - " + opt.username : "")}
                    >
                        {opt.name}
                    </Option>;
                })
            }
        </Select>
    );
}
