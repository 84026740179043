import { Table } from 'antd';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import React from 'react';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

function ServiceList({ services }) {
    const columns = [
        {
            className: '_service-list-name',
            title: t('label.service'),
            dataIndex: 'name',
        },
        {
            className: '_service-list-price-name',
            title: t('label.price_level'),
            dataIndex: 'service_price_name',
        },
        {
            className: 'text-nowrap _service-list-price',
            title: t('label.unit_price'),
            dataIndex: 'price',
            render: text => {
                const price = text ? text : t('label.free');
                return <p>{price}</p>;
            },
        },
        {
            className: '_service-list-sku-apply text-wrap',
            title: t('label.sku_apply'),
            dataIndex: 'skus',
            render: text => {
                return text.length > 0 ? map(text, 'sku_code').join(', ') : '';
            },
        },
        {
            className: 'text-center _service-list-quantity',
            title: t('label.quantity'),
            dataIndex: 'quantity',
        },
        {
            className: 'text-right _service-list-amount',
            title: t('label.sum_total_money'),
            dataIndex: 'amount',
            render: text => {
                return <p>{ text ? text : t('label.free')}</p>;
            },
        },
    ];

    return (
        <Table
            className="_service-list"
            dataSource={services}
            columns={columns}
            pagination={false}
            scroll={{ x: 576 }}
            footer={() => {
                return (
                    <div className="d-flex justify-content-between">
                        <strong>{t('label.total_cost')}</strong>
                        <strong className="_service-list-total">{sumBy(services, 'amount')}</strong>
                    </div>
                );
            }}
            locale={{
                emptyText: <p>{t(`message.no_data_service`)}</p>,
            }}
        />
    );
}
export default ServiceList;
