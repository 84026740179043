import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";
import axios from "axios";

class ServicePurchasingAccount extends BaseServiceData {

    constructor() {
        super();
    }

    runApi() {
        return api.getPurchasingAccount()
            .then(res => _.get(res, "data.purchasing_accounts", []))
            .catch(err => {
                if (!axios.isCancel(err)) {
                    notification.error({message: t("common:message.fetch_fail", {attribute: t("label.purchasing_accounts").toLowerCase()})});
                    return Promise.reject(err);
                }
            });
    }
}

export default new ServicePurchasingAccount();
