  
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import { useState, useCallback } from 'react';

import useUpdateEffect from './useUpdateEffect';

const createUseStorageState = nullishStorage => {
    const useStorageState = (key, defaultValue) => {
        const storage = nullishStorage;
        const [state, setState] = useState(() => getStoredValue());

        function getStoredValue() {
            const raw = storage.getItem(key);
    
            if (raw) {
                try {
                    return JSON.parse(raw);
                } catch (e) {
                }
            }

            if (isFunction(defaultValue)) {
                return defaultValue();
            }

            return defaultValue;
        }

        const updateState = useCallback(value => {
            if (isUndefined(value)) {
                storage.removeItem(key);
                setState(undefined);
            } else if (isFunction(value)) {
                const previousState = getStoredValue();
                const currentState = value(previousState);
                storage.setItem(key, JSON.stringify(currentState));
                setState(currentState);
            } else {
                storage.setItem(key, JSON.stringify(value));
                setState(value);
            }
            // eslint-disable-next-line
        }, [key]);

        useUpdateEffect(() => {
            setState(getStoredValue());
        }, [key]);

        return [state, updateState, getStoredValue];
    };

    if (!nullishStorage) {
        return (_, defaultValue) => [
            isFunction(defaultValue) ? defaultValue() : defaultValue,
            () => {}
        ];
    }

    return useStorageState;
};

export default createUseStorageState;
