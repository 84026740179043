import { useQuery } from 'react-query'

import processResponseError from '@System/api/processResponseError'

import apiService from '../services/api'

const useTransactionHistory = (id, params) => {
    return useQuery(['supplier-transaction-history', params], () => apiService.getSuppliersTransactionHistory(id, params), {
        retry: false,
        onError: err => {
            processResponseError(err)
        },
    })
}

export default useTransactionHistory
