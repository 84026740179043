import { DownloadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import React, { useState } from 'react';

import processResponseError from '@System/api/processResponseError';
import { convertBlobDataToExcel } from '@System/support/helpers';

import { t } from '../../../system/i18n';
import api from '../services/api';

export default function ExportList({ filters }) {
    const [loading, setLoading] = useState(false);

    function onDownloadExcel() {
        setLoading(true);
        api.exportPurchasingPackages(filters)
            .then(response => {
                convertBlobDataToExcel(response.data, 'purchasing-package-list');
                notification.success({ message: t('order:export_excel.success') });
            })
            .catch(err => {processResponseError(err)})
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Button className="_order-export-excel" loading={loading} type="primary" onClick={onDownloadExcel}>
            <DownloadOutlined /> {t('common:btn.export_costs')}
        </Button>
    );
}
