import { useMutation } from 'react-query';

import api from '@Modules/Product/services/api';

const useConfirmWeightVolumeMutation = config => {
    return useMutation(({productId, sku_ids}) => {
        return api.confirmWeightVolume(productId, { sku_ids });
    }, config);
};

export default useConfirmWeightVolumeMutation;
