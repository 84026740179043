import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber as InputNumeric, Select, Switch, Tooltip } from 'antd';
import { get, map } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import TableCustomize from '@Modules/App/Table/TableCustomize';
import InputNumber from '@Modules/App/Utilities/InputNumber';
import { dateFormatter } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';

import processResponseError from '@System/api/processResponseError';
import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';

import api from '../services/api';
import { SERVICE_TYPE } from '../services/constants';
import ModalAddPrice from './ModalAddPrice';
import SelectAllMerchants from './SelectAllMerchants';
import { formatCurrency } from '@System/support/numberFormat';

const ModalPriceList = ({ serviceType, idService, currency, autoPriceBy, ruleLogic }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [rule, setRule] = useState('');
    const [visibleAddPrice, setVisibleAddPrice] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSelectRule, setLoadingSelectRule] = useState(false);
    const [loadingSwitch, setLoadingSwitch] = useState(false);
    const [loadingSaveRow, setLoadingSaveRow] = useState('');

    const colRender = () => {
        switch (rule) {
            case 'SIZE':
                return [
                    {
                        key: 'length',
                        title: t('label.length'),
                        className: '_length-column',
                        dataIndex: 'length',
                        render: (data, record, index) => {
                            return (
                                <Form.Item
                                    name={['data', index, 'length']}
                                    style={{ margin: '0' }}
                                    initialValue={data}
                                    rules={[
                                        { required: true, message: t('product:message.required') },
                                        {
                                            pattern: /^\+?([1-9]\d*)$/,
                                            message: t('validation:min.numeric', { min: 1 }),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className={`text-right _product-variants-length-input`}
                                        style={{ width: '130px' }}
                                        onChange={e => onChangeInput(e, index, 'length')}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                    {
                        key: 'width',
                        title: t('label.width'),
                        className: '_width-column',
                        dataIndex: 'width',
                        render: (data, record, index) => {
                            return (
                                <Form.Item
                                    name={['data', index, 'width']}
                                    style={{ margin: '0' }}
                                    initialValue={data}
                                    rules={[
                                        { required: true, message: t('product:message.required') },
                                        {
                                            pattern: /^\+?([1-9]\d*)$/,
                                            message: t('validation:min.numeric', { min: 1 }),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className={`text-right _product-variants-width-input`}
                                        style={{ width: '130px' }}
                                        value={data}
                                        onChange={e => onChangeInput(e, index, 'width')}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                    {
                        key: 'height',
                        title: t('label.height'),
                        className: '_height-column',
                        dataIndex: 'height',
                        render: (data, record, index) => {
                            return (
                                <Form.Item
                                    style={{ margin: '0' }}
                                    name={['data', index, 'height']}
                                    initialValue={data}
                                    rules={[
                                        { required: true, message: t('product:message.required') },
                                        {
                                            pattern: /^\+?([1-9]\d*)$/,
                                            message: t('validation:min.numeric', { min: 1 }),
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        className={`text-right _product-variants-height-input`}
                                        style={{ width: '130px' }}
                                        value={data}
                                        onChange={e => onChangeInput(e, index, 'height')}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                ];

            case 'VOLUME':
                return [
                    {
                        key: 'volume',
                        title: t('label.cbm'),
                        className: '_volume-column',
                        dataIndex: 'volume',
                        render: (data, record, index) => {
                            return (
                                <Form.Item
                                    name={['data', index, 'volume']}
                                    style={{ margin: '0' }}
                                    rules={[{ required: true, message: t('product:message.required') }]}
                                    initialValue={data}
                                >
                                    <InputNumeric
                                        min={0}
                                        onChange={value => onChangeInput(value, index, 'volume')}
                                        style={{ width: '200px' }}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                ];

            case 'SELLER':
                return [
                    {
                        key: 'seller_refs',
                        title: t('merchant:label.ref'),
                        className: '_seller_refs-column',
                        dataIndex: 'seller_refs',
                        render: (data, record, index) => {
                            return (
                                <Form.Item
                                    name={['data', index, 'seller_refs']}
                                    style={{ margin: '0' }}
                                    initialValue={data}
                                >
                                    <Select
                                        mode="tags"
                                        value={data}
                                        style={{ width: '200px' }}
                                        onChange={value => onChangeSelect(value, index, 'seller_refs')}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                    {
                        key: 'seller_codes',
                        title: t('merchant:label.code'),
                        className: '_seller_codes-column',
                        dataIndex: 'seller_codes',
                        render: (data, record, index) => {
                            return (
                                <Form.Item
                                    name={['data', index, 'seller_codes']}
                                    style={{ margin: '0' }}
                                    initialValue={data}
                                >
                                    <SelectAllMerchants
                                        className="_search-merchant"
                                        allowClear
                                        mode="tags"
                                        value={data}
                                        style={{ width: '200px' }}
                                        onChange={value => onChangeSelect(value, index, 'seller_codes')}
                                    />
                                </Form.Item>
                            );
                        },
                    },
                ];
            default:
                return [];
        }
    };

    const columns = [
        {
            key: 'label',
            title: t('label.price_level'),
            className: 'ant-table-label-column',
            dataIndex: 'label',
        },
        {
            key: 'created_at',
            title: t('label.created_at'),
            className: 'created_at-column',
            dataIndex: 'created_at',
            render: (data, record) => {
                return data ? dateFormatter.full(data) : '---';
            },
        },
        {
            key: 'price',
            title: t('label.price'),
            className: '_price-column',
            dataIndex: 'price',
            render: (data, record) => {
               return <p>{formatCurrency(data || 0, currency ? currency : null)}</p>;
            },
        },
        serviceType === SERVICE_TYPE.EXPORT
            ? {
                  key: 'yield_price',
                  title: t('label.additional_costs'),
                  className: '_yield-price-column',
                  dataIndex: 'yield_price',
              }
            : {},
        serviceType === SERVICE_TYPE.EXTENT
            ? {
                  key: 'deduct',
                  title: t('label.deduct_percent'),
                  className: '_deduct-column',
                  dataIndex: 'deduct',
              }
            : {},
        {
            key: 'note',
            title: t('note'),
            className: '_note-column',
            dataIndex: 'note',
            render: (data, record) => {
                return <p>{data ? data : '---'}</p>;
            },
        },
        {
            key: 'is_default',
            title: t('label.default'),
            className: '_is_default-column',
            dataIndex: 'is_default',
            render: (data, record) => {
                return auth.can(permissions.SERVICE_ADD) ? (
                    <Switch
                        checked={data}
                        onChange={checked => handleChangeDefaultPrice(record.id, checked)}
                        loading={loadingSwitch}
                    />
                ) : (
                    <Switch checked={data} disabled loading={loadingSwitch} />
                );
            },
        },
        ...colRender(),
        {
            key: 'sub_mit',
            title: '',
            className: '',
            dataIndex: 'id',
            render: (text, record, index) => {
                return (
                    <Tooltip title={t('label.save_change')}>
                        <Button
                            type="link"
                            loading={loadingSaveRow === text}
                            onClick={() => {
                                handleSubmit(text, index);
                            }}
                        >
                            <SaveOutlined />
                        </Button>
                    </Tooltip>
                );
            },
        },
    ];

    const getListPrice = useCallback(() => {
        setLoading(true);
        api.getListPrice(idService)
            .then(res => {
                const data = get(res, 'data.service_prices');
                let newData = map(data, item => ({
                    ...item,
                    width: get(item, 'width', 0) * 1000,
                    height: get(item, 'height', 0) * 1000,
                    length: get(item, 'length', 0) * 1000,
                }));
                setData(newData);
            })
            .catch(error => {
                processResponseError(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [idService]);

    useEffect(() => {
        getListPrice();
    }, [getListPrice]);

    useEffect(() => {
        setRule(autoPriceBy);
    }, [autoPriceBy]);

    const showModalAdd = () => {
        setVisibleAddPrice(true);
    };

    const handleChangeRule = value => {
        const data = {
            auto_price_by: value ? value : null,
        };
        setLoadingSelectRule(true);
        setLoading(true);
        api.changeLogicService(idService, data)
            .then(res => {
                notification.success(t('message.update_logic_price_apply_success'));
                events.dispatch(`RELOAD_LIST_SERVICE_${serviceType}`, {});
            })
            .catch(err => {
                notification.error(t('message.update_logic_price_apply_failed'));
            })
            .finally(() => {
                setLoadingSelectRule(false);
                setLoading(false);
            });
        setRule(value);
    };

    const handleChangeDefaultPrice = (idPrice, checked) => {
        const data = { is_default: checked };
        setLoadingSwitch(true);
        api.changeStatusDefaultPrice(idService, idPrice, data)
            .then(res => {
                notification.success(t('message.change_status_price_default_success'));
                getListPrice();
            })
            .catch(error => {
                const { response } = error;

                if (response.status === 400) {
                    let errors = get(response, 'data.data', {});
                    Object.keys(errors).forEach(item => {
                        if (item === 'is_default') {
                            notification.error(t('message.price_default_default'));
                        } else {
                            notification.error(t('message.change_status_price_default_failed'));
                        }
                    });
                } else {
                    notification.error(t('message.change_status_price_default_failed'));
                }
            })
            .finally(() => {
                setLoadingSwitch(false);
            });
    };

    const onChangeSelect = (value, index, name) => {
        let newData = [...data];
        newData[index][name] = value;

        setData(newData);
    };

    const handleSubmit = (idPrice, index) => {
        form.validateFields([['data', index]]).then(values => {
            const { data } = values;
            let newData = {};
            if (rule === 'SIZE') {
                newData = {
                    width: get(data, `${index}.width`, 0) / 1000,
                    height: get(data, `${index}.height`, 0) / 1000,
                    length: get(data, `${index}.length`, 0) / 1000,
                };
            }
            setLoadingSaveRow(idPrice);
            api.changeLogicServicePrice(idService, idPrice, rule === 'SIZE' ? newData : data[index])
                .then(res => {
                    getListPrice();
                    notification.success(t('message.update_logic_price_apply_success'));
                })
                .catch(err => {
                    notification.error(t('message.update_logic_price_apply_failed'));
                })
                .finally(() => {
                    setLoadingSaveRow('');
                });
        });
    };

    const onChangeInput = (value, index, name) => {
        let newData = [...data];
        newData[index][name] = value;
        setData(newData);
    };

    return (
        <>
            <div className="d-flex justify-content-between mt-4">
                <div>
                    <p className="mb-2">{t('label.logic_apply')}</p>
                    <Select
                        style={{ width: 150 }}
                        onChange={handleChangeRule}
                        placeholder={t('placeholder.service_logic')}
                        value={rule}
                        loading={loadingSelectRule}
                        allowClear
                    >
                        {serviceType !== SERVICE_TYPE.EXTENT && (
                            <>
                                <Select.Option value="SIZE">Size</Select.Option>
                                <Select.Option value="VOLUME">CBM</Select.Option>
                            </>
                        )}
                        <Select.Option value="SELLER">Seller</Select.Option>
                    </Select>
                </div>
                {auth.can(permissions.SERVICE_ADD) && (
                    <Button
                        type="primary"
                        onClick={showModalAdd}
                        className={`_service-price-list-table__${serviceType.toLowerCase()} mt-1`}
                    >
                        {t('btn.add_price')}
                    </Button>
                )}
            </div>

            <Form form={form}>
                <TableCustomize
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    className={'list-products-table'}
                />
            </Form>

            {visibleAddPrice && (
                <ModalAddPrice
                    serviceType={serviceType}
                    setVisibleAddPrice={setVisibleAddPrice}
                    idService={idService}
                    currency={currency}
                    getListPrice={getListPrice}
                    data={data}
                    rule={rule}
                />
            )}
        </>
    );
};

export default ModalPriceList;
