import { Modal, notification, Button } from 'antd';
import i18next from 'i18next';
import lodash from 'lodash';
import React, { useState } from 'react';

import { events } from '../../../../system/events';
import { t } from '../../../../system/i18n';
import UploadFile from '../../../App/Upload/UploadFile';
import { EVENTS } from '../../services/constants';
import { renderError } from '../errors';
import {CloudUploadOutlined} from "@ant-design/icons";

export default function UploadFileForOrder({
    fileNameAddress,
    btnTittle,
    modalTittle,
    fileUpdatedAt,
    fileName,
    fileLabel,
    apiService,
    btnType
}) {
    const [visible, setVisible] = useState(false);
    const [messages, setMessages] = useState([]);

    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setMessages([]);
        setVisible(false);
    }

    function handleCancel() {
        setMessages([]);
        setVisible(false);
    }

    function handleUpload(file) {
        setMessages([]);
        const filename = lodash.get(file, 'name', '');
        let formData = new FormData();
        formData.append('file', file);
        apiService(formData)
            .then(res => {
                const messages = lodash.uniqWith(lodash.get(res, 'data.errors', []), lodash.isEqual);
                const hasInvalid = lodash.findIndex(messages, ['errors', 'invalid']);
                if (hasInvalid === -1) {
                    setMessages(messages);
                    if (messages.length === 0) {
                        notification.success({
                            message: t('order:message.upload_file.success', {
                                filename,
                            }),
                        });
                        setVisible(false);
                    }
                } else {
                    notification.error({
                        message: t('order:message.upload_file.fail', {
                            filename,
                        }),
                    });
                }
                events.dispatch(EVENTS.IMPORT_ORDER_FROM_EXCEL, {});
            })
            .catch(err => {
                notification.error({
                    message: t('common:message.server_error'),
                });
            });
    }

    return (
        <>
            <Button type={btnType ? btnType : "primary"} onClick={showModal} icon={<CloudUploadOutlined/>}>{btnTittle}</Button>
            <Modal
                title={modalTittle.toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
            >
                <p>
                    - {t('label.latest_at_update_sample')}: <strong>{fileUpdatedAt}</strong>
                </p>
                <p>
                    - {t('label.download_sample_file')}:
                    <strong>
                        <a href={process.env.PUBLIC_URL + `/template/${i18next.language}/${fileName}.xlsx`}> {fileLabel}</a>
                    </strong>
                    {/*}*/}
                </p>
                <p>
                    - {t('label.download_location_address')}:{/*{*/}
                    <strong>
                        <a href={process.env.PUBLIC_URL + `/template/${i18next.language}/${fileNameAddress}.xlsx`}>
                            {` ${fileNameAddress}.xlsx`}
                        </a>
                    </strong>
                    {/*}*/}
                </p>
                <p className="mb-3">
                    - {t('label.maximum_file_size')}: <strong>5MB</strong>
                </p>
                <UploadFile
                    onChange={handleUpload}
                    accept=".xls,.xlsx"
                    fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                    name="file import đơn hàng"
                    maxFileSize={5}
                    showUploadList={true}
                />
                {messages.length > 0 && (
                    <div className="pt-3">
                        {messages.map((item, index) => {
                            return (
                                <p key={index} className="text-danger">
                                    - {renderError(item)}
                                </p>
                            );
                        })}
                    </div>
                )}
            </Modal>
        </>
    );
}
