import { Space, Table, Typography, Avatar } from 'antd';
import React from 'react';

import Box from '@Modules/App/Box';
import styles from '@Modules/Document/screens/Importing/ScanPurchasePackage/scan-purchase-package.module.scss';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { v4 } from "uuid";

const { Title } = Typography;

function PackageOrderInfo(props) {
    const { orders, loading } = props;
    const columns = [
        {
            title: t('product:label.name'),
            dataIndex: 'purchasing_order_item',
            key: 'product_name',
            render: (text) => {
                const variant_properties = getVar(text, 'variant_properties', []);
                const image = getVar(text, 'product_image', '');
                const name = getVar(text, 'item_name', '');
                return (
                    <div className="d-flex align-items-center">
                        <Avatar shape="square" className="mr-2" size={36} src={image} />
                        <div className="d-flex flex-wrap">
                            <span>
                                <strong style={{ fontWeight: 500 }}>{name}</strong>
                                {variant_properties.map((item, index) => {
                                    const originalName = getVar(item, 'originalName', '');
                                    const originalValue = getVar(item, 'originalValue', '');
                                    return <p key={index}>{`${originalName}: ${originalValue}`}</p>;
                                })}
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            title: t('product:label.product_name_vn'),
            dataIndex: ['purchasing_order_item', 'item_translated_name'],
            key: 'product_name_vn',
        },
        {
            title: t('product:label.sku'),
            dataIndex: ['sku', 'code'],
            key: 'sku_code',
        },
        {
            title: t('sku:label.name'),
            dataIndex: ['sku', 'name'],
            key: 'sku_name',
        },
    ];
    return (
        <Box className={styles.order}>
            <Table
                className="_package-order-info"
                title={() => (
                    <div className="d-flex justify-content-between border-bottom align-items-center p-0">
                        <Space className="mb-3">
                            <Title level={5}>{t('title.product_list_of_order')}</Title>
                        </Space>
                    </div>
                )}
                rowKey={_ => v4()}
                columns={columns}
                pagination={false}
                loading={loading}
                dataSource={orders}
            />
        </Box>
    );
}

export default PackageOrderInfo;
