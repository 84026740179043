import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import StyledTable from '@Modules/App/Table/StyledTable';
import DeleteConnection from '@Modules/ConnectedAccount/components/DeleteConnection';

import { t } from '@System/i18n';

const AccountsTable = ({ accounts, loading, pagination, onChange, onSuccessDelete }) => {
    const columns = useMemo(
        () => [
            {
                className: '_account-column',
                dataIndex: ['store', 'marketplace_store_id'],
                key: 'account',
                title: t('linked_account'),
                render: text => (
                    <div>
                        <Avatar className="mr-3" size={24} />
                        {text}
                    </div>
                ),
            },
            {
                className: '_merchant-column',
                dataIndex: ['merchant', 'name'],
                key: 'merchant',
                showSorterTooltip: false,
                sorter: true,
                title: t('vendor'),
            },
            {
                align: 'right',
                className: '_actions-column',
                key: 'actions',
                dataIndex: ['store', 'id'],
                render: text => <DeleteConnection id={text} onSuccessDelete={onSuccessDelete} />,
            },
        ],
        [onSuccessDelete]
    );

    return (
        <StyledTable
            columns={columns}
            dataSource={accounts}
            loading={loading}
            pagination={pagination}
            rowKey={record => record?.store?.id}
            serverPagination
            onChange={onChange}
        />
    );
};

AccountsTable.propTypes = {
    accounts: PropTypes.array,
};

AccountsTable.defaultProps = {
    accounts: [],
};

export default AccountsTable;
