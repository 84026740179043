import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import React, { useState } from 'react';

import { t } from '@System/i18n';
import moment from "moment";

type RangeValue = [Moment | null, Moment | null] | null;

interface IRangPickerLimitProps {
    limit: number;
    format: string;
    value?: RangeValue;
    onChange?: (value: any) => void;
    defaultValue?: [form: any, to: any ],
    allowClear: boolean
}

const { RangePicker } = DatePicker;

const RangPickerLimit: React.FC<IRangPickerLimitProps> = ({ limit, format, value, onChange, defaultValue, allowClear = true }) => {
    const [dates, setDates] = useState<RangeValue>(null);

    const disabledDate = (current: Moment) => {
        if (!dates) {
            return false;
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > limit;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > limit;
        return !!tooEarly || !!tooLate;
    };

    return (
        <RangePicker
            defaultValue={defaultValue}
            value={value || dates}
            disabledDate={disabledDate}
            onCalendarChange={val => setDates(val)}
            onChange={onChange}
            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
            style={{ width: '100%' }}
            format={format}
            allowClear={allowClear }
        />
    );
};

export default RangPickerLimit;
