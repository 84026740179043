import React, {useState, useEffect} from 'react';
import {t} from "../../../../system/i18n";
import {Table, Button, notification} from "antd";
import CustomizedPagination from "../../../App/CustomizedPagination";
import useFilter from "../../../App/Hooks/useFilter";
import {setDocumentTitle, setNavigator} from "../../../App/services";
import FormSearch from "./FormSearch";
import TableEmpty from "../../../App/Utilities/TableEmpty";
import UpdateCategory from "../UpdateCategory";
import api from "../../services/api";
import _ from "lodash";
import {events} from "../../../../system/events";
import {EVENTS} from "../../services/constants";
import {auth} from "../../../Auth/services";
import PERMISSIONS from "../../../Auth/services/permissions";
import {PlusOutlined} from "@ant-design/icons";
import useListCategoriesQuery from "@Modules/ProductCategories/Hooks/useListCategoriesQuery";
import {getVar} from "@System/support/helpers";

export default function ProductCategories(props) {
    const {history, location} = props;
    const [pagination, setPagination] = useState({});
    const [categories, setCategories] = useState([]);
    const {filters, addFilter} = useFilter(history, location);
    const [visible, setVisible] = useState(false);
    const [categoryInfo, setCategoryInfo] = useState({});
    const {data, isFetching:loading, refetch} = useListCategoriesQuery(filters);

    useEffect(() => {
        setNavigator(
            t("common:title.config_product_category"), [
                {
                    name: t("common:breadcrumb.products"),
                    route: "products.list"
                },
                {
                    name: t("common:breadcrumb.config_product_category"),
                }
            ]);
        setDocumentTitle(t("title.config_product_category"));

        return events.listen(EVENTS.RELOAD_CATEGORIES, () => {
            addFilter({});
            refetch();
        })
    }, []);

    useEffect(() => {
        setCategories(getVar(data, "data.categories", []));
        setPagination(getVar(data, "data.pagination", {}));

    }, [data]);


    const columns = [
        {
            title: t("product:label.category_code"),
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => {
                return <a onClick={() => openModalUpdateCategory(record.category)}>
                    <strong style={{fontWeight: 500}}>{record.category.code}</strong>
                </a>
            }
        },
        {
            title: t("product:label.category_name"),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                return <p>{record.category.name}</p>
            }
        },
        {
            title: t("product:label.note"),
            dataIndex: 'note',
            key: 'note',
            render: (text, record) => {
                return <p>{record.category.note}</p>
            }
        },
    ];

    function openModalUpdateCategory(info) {
        setVisible(true);
        setCategoryInfo(info);
    }

    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{t("common:heading.product_category")}</h2>
                    <div className="pr-3">
                        {
                            auth.can(PERMISSIONS.CONFIG_CATEGORIES_UPDATE) &&
                            <Button key="submit" type="primary" onClick={() => openModalUpdateCategory({})}
                                    icon={<PlusOutlined/>}>
                                {t("product:btn.create_category")}
                            </Button>
                        }
                    </div>
                </div>
                <div>
                    <FormSearch
                        filters={filters}
                        addFilter={addFilter}
                    />
                    <Table
                        className="m-3"
                        loading={loading}
                        dataSource={categories}
                        columns={columns}
                        pagination={false}
                        rowKey={record => record.category.id}
                        locale={{emptyText: <TableEmpty/>}}
                    />
                    <CustomizedPagination
                        pagination={pagination}
                        filters={filters}
                        addFilter={addFilter}
                    />
                </div>
                {
                    visible &&
                    <UpdateCategory
                        visible={visible}
                        setVisible={setVisible}
                        categoryInfo={categoryInfo}
                    />
                }
            </div>
        </div>
    );
}
