import {scanImportingSkuStorage} from "@Modules/App/services";
import _ from "lodash";

export const ACTIONS = {
    UPDATE_DATA: "IMPORTING_SKU.UPDATE_DATA",
    ADD_NEW_SCAN: "IMPORTING_SKU.ADD_NEW_SCAN",
    DELETE_SKU: "IMPORTING_SKU.DELETE_SKU",
    ADD_SKU: "IMPORTING_SKU.ADD_SKU",
    UPDATE_SKU: "IMPORTING_SKU.UPDATE_SKU",
    IS_SAVED: "IMPORTING_SKU.IS_SAVED",
}

export default function reducer(state, action) {
    let newState;
    let skus = _.get(state, "skus", []);
    let {payload} = action;

    switch (action.type) {
        case ACTIONS.ADD_NEW_SCAN:
            newState = {...payload};
            break;
        case ACTIONS.UPDATE_DATA:
            newState = {...state, ...action.payload};
            break;
        case ACTIONS.ADD_SKU:
            newState = addSku(state, action.payload);
            break;
        case ACTIONS.UPDATE_SKU:
            newState = {...state, skus: skus.map(item => payload.sku_id === item.sku_id ? {...item, ...payload} : item)}
            break;
        case ACTIONS.DELETE_SKU:
            newState = {...state, skus: skus.filter(item => item.sku_id !== payload)}
            break;
        case ACTIONS.IS_SAVED:
            newState = {...state, isSaved: true}
            break;
        default:
            newState = {...state};
            break;
    }
    scanImportingSkuStorage.setValue(newState);
    return newState;
}

function addSku(state, newSkus) {
    let existedIds = _.map(_.get(state, "skus", []), 'sku_id');
    if (existedIds.length === 0) {
        // Sử lý các sku trùng nhau trong lần đầu thêm
        let uniqSkus = _.uniqBy(newSkus, "sku_id");
            uniqSkus = uniqSkus.map(item => {
                let sameSkus = newSkus.filter(sku => item.sku_id === sku.sku_id);
                if (sameSkus.length > 1) {
                    let quantity = sameSkus.reduce((total, sku) => total + sku.quantity, 0);
                    return {...item, quantity};
                }
                return item;
            });

        return {...state, skus: [...uniqSkus]};
    } else {
        // Các sku đã có trong danh sách
        let existedSkus = newSkus.filter(item => existedIds.includes(item.sku_id));
        // Các sku chưa có trong danh sách
        let notExistedSkus = newSkus.filter(item => !existedIds.includes(item.sku_id));

        let updatedSkus = [...state.skus].map(item => {
            let newSkus = existedSkus.filter(ite => ite.sku_id === item.sku_id);
            let quantity = newSkus.reduce((total, sku) => total + sku.quantity, parseInt(item.quantity));
            return {...item, quantity}
        });

        return {...state, skus: [...updatedSkus, ...notExistedSkus]};
    }
}
