import { DownloadOutlined, HistoryOutlined } from '@ant-design/icons'
import { Button, Modal, Switch, Table, Tooltip } from 'antd'
import lodash from 'lodash'
import isEmpty from 'lodash/isEmpty'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import DynamicTable from '@Modules/App/DynamicTable'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import useExportProductInventory from '@Modules/Product/Hooks/useExportProductInventory'
import FilterExportStorageFee from '@Modules/Product/components/FilterExportStorageFee'
import ModalStorageCostDetail from '@Modules/Product/components/ModalStorageCostDetail'
import HistoryOfProductVariant from '@Modules/ProductVariants/components/HistoryOfProductVariant'

import { t } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

function List({ pagination, data, filters, addFilter, loading, onChangeViewBatch, viewStockBatch }) {
    const { isLoading: exportLoading, refetch: fetchExport } = useExportProductInventory(filters)

    const [skuId, setSkuId] = useState()
    const [visibleLog, setVisibleLog] = useState(false)
    function handleShowLog(sku_id) {
        setSkuId(sku_id)
        setVisibleLog(true)
    }
    const columns = [
        {
            className: '_inventory-list-sku-id',
            title: t('product:label.sku_id'),
            dataIndex: ['sku', 'id'],
            key: 'sku_id',
            render: (text, record) => {
                const product_id = lodash.get(record, 'sku.product_id', '')
                return <Link to={url.to('products.detail', { id: product_id })}>{text}</Link>
            },
            disabled: true,
        },
        {
            className: '_inventory-list-sku-code',
            title: t('product:label.sku_code'),
            dataIndex: ['sku', 'code'],
            key: 'sku_code',
            render: (text, record) => {
                const product_id = lodash.get(record, 'sku.product_id', '')
                return <Link to={url.to('products.detail', { id: product_id })}>{text}</Link>
            },
            disabled: true,
        },
        {
            className: '_inventory-list-sku-name',
            title: t('product:label.sku_name'),
            dataIndex: 'sku_name',
            key: 'sku_name',
            render: (text, record) => {
                const sku_name = lodash.get(record, 'sku.name', '')
                return <p>{sku_name}</p>
            },
        },
        {
            className: '_inventory-merchant-info',
            title: t('label.seller_info'),
            dataIndex: 'sellers',
            key: 'sellers',
            render: (text, record) => {
                const sellers = getVar(record, 'sellers', [])
                return sellers.map((item, index) => {
                    const name = item?.name ?? ''
                    const code = item?.code ?? ''

                    return <p key={index}>{(name ? name : '') + (code ? ' - ' + code : '')}</p>
                })
            },
        },
        {
            className: '_inventory-list-warehouse-name',
            title: t('product:label.warehouse_name'),
            dataIndex: 'warehouse_name',
            key: 'warehouse_name',
            render: (text, record) => {
                let warehouse_code = lodash.get(record, 'warehouse.code', '')
                let warehouse_name = lodash.get(record, 'warehouse.name', '')
                if (warehouse_code === 'default') warehouse_code = t('order:label.default')
                if (warehouse_name === 'default') warehouse_name = t('order:label.default')
                return <p>{warehouse_name + (warehouse_code ? ' (' + warehouse_code + ')' : '')}</p>
            },
        },
        {
            className: '_inventory-list-warehouse-area-name',
            title: t('product:label.warehouse_area_name'),
            dataIndex: 'warehouse_area_name',
            key: 'warehouse_area_name',
            render: (text, record) => {
                let warehouseArea_code = lodash.get(record, 'warehouse_area.code', '')
                let warehouseArea_name = lodash.get(record, 'warehouse_area.name', '')
                if (warehouseArea_code === 'default') warehouseArea_code = t('order:label.default')
                if (warehouseArea_name === 'default') warehouseArea_name = t('order:label.default')
                return <p>{warehouseArea_name + (warehouseArea_code ? ' (' + warehouseArea_code + ')' : '')}</p>
            },
        },
        {
            className: '_inventory-list-sku-stock',
            title: t('product:label.sku_stock'),
            dataIndex: 'sku_stock',
            key: 'sku_stock',
            render: (text, record) => {
                const real_quantity = lodash.get(record, 'stock.real_quantity', '')
                return <p>{real_quantity}</p>
            },
        },
        {
            className: '_inventory-list-sku-stock-tmp',
            title: t('product:label.sku_stock_tmp'),
            dataIndex: 'sku_stock_tmp',
            key: 'sku_stock_tmp',
            render: (text, record) => {
                const quantity = lodash.get(record, 'stock.quantity', '')
                return <p>{quantity}</p>
            },
        },
        {
            className: '_inventory-list-total_storage-cost',
            title: t('product:label.total_storage_cost'),
            dataIndex: 'total_storage_fee',
            key: 'total_storage_fee',
            render: (text, record) => {
                const total_storage_fee = lodash.get(record, 'stock.total_storage_fee', '')
                const id = lodash.get(record, 'stock.id', '')
                const currency = lodash.get(record, 'currency', '')
                return (
                    <Button
                        type="link"
                        className="cursor-pointer"
                        onClick={() => showModalStorageTotal(id)}
                    >
                        <Tooltip title={t('btn.detail_view')}>
                            {!isEmpty(currency) ? formatCurrency(total_storage_fee ?? 0, currency) : total_storage_fee}
                        </Tooltip>
                    </Button>
                )
            },
        },
        {
            title: t('label.function'),
            className: '_inventory-list-action ',
            key: 'action',
            render: (_, record) => {
                const sku_id = getVar(record, 'sku.id', '')
                return (
                    <Tooltip title={t('label.history_change')}>
                        <Button
                            onClick={() => handleShowLog(sku_id)}
                            icon={<HistoryOutlined />}
                            type="link"
                        />
                    </Tooltip>
                )
            },
        },
    ]

    const showModalStorageTotal = id => {
        Modal.confirm({
            title: false,
            icon: false,
            closable: true,
            width: 1000,
            content: <ModalStorageCostDetail id={id} />,
            cancelText: t('btn.close'),
            okButtonProps: { style: { display: 'none' } },
        })
    }

    const [visibleExportModal, setVisibleExportModal] = useState(false)

    function onDownloadExcel() {
        if (getVar(filters, 'warehouse_id', undefined)) {
            fetchExport(filters)
        } else {
            notification.error( t('product:message.choose_warehouse_export'))
        }
    }

    const showConfirm = () => {
        setVisibleExportModal(true)
    }

    return (
        <>
            <div className="bg-white rounded-12 p-4 mt-4">
                <div className="data-box">
                    <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex align-items-center">
                            <h3 className="text-fz-18">
                                {t('title.inventory_management')} ({isEmpty(pagination) ? 0 : pagination.total})
                            </h3>{' '}
                            <div className="d-flex align-items-center ml-2">
                                <Switch
                                    onChange={onChangeViewBatch}
                                    size="small"
                                    className="mr-2"
                                    checked={viewStockBatch}
                                />
                                <span className="font-weight-500">{t('product:label.view_stock_batch')}</span>
                            </div>
                        </div>

                        <div>
                            <Button
                                className="_export-excel-storage-fee mr-2"
                                type="primary"
                                onClick={showConfirm}
                            >
                                <DownloadOutlined /> {t('product:btn.export_storage_fee')}
                            </Button>
                            <Button
                                className="_export-excel"
                                loading={exportLoading}
                                type="primary"
                                onClick={onDownloadExcel}
                            >
                                <DownloadOutlined /> {t('common:btn.export_excel')}
                            </Button>
                        </div>
                    </div>
                    <div className="data-box--body">
                        {/* <Table
                            className="m-3 _product-inventory-management-table"
                            dataSource={data}
                            columns={columns}
                            loading={loading}
                            pagination={false}
                            scroll={{ x: true }}
                            rowKey={record => record.stock.id}
                            locale={{
                                emptyText: (
                                    <TableEmpty
                                        className="_inventory-list-no-data"
                                        loading={loading}
                                    />
                                ),
                            }}
                        /> */}
                        <DynamicTable
                            initColumns={columns}
                            dataSource={data}
                            className="m-3 _product-inventory-management-table"
                            loading={loading}
                            rowKey={record => record.stock.id}
                        />

                        <CustomizedPagination
                            pagination={pagination}
                            filters={filters}
                            addFilter={addFilter}
                        />
                        <HistoryOfProductVariant
                            sku_id={skuId}
                            visible={visibleLog}
                            setVisible={setVisibleLog}
                            setSkuId={setSkuId}
                        />
                    </div>
                </div>
            </div>
            {visibleExportModal && (
                <FilterExportStorageFee
                    filters={filters}
                    setVisible={setVisibleExportModal}
                />
            )}
        </>
    )
}
export default List
