import {Button, Col, Dropdown, Menu, notification} from 'antd';
import filter from 'lodash/filter';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';

import BarcodeWidget from '@Modules/Product/components/BarcodeWidget';
import api from '@Modules/Product/services/api';
import {PRINT_SKU_TYPE, TYPE_STAMP_SIZE} from '@Modules/Product/services/constants';

import {t} from '@System/i18n';
import {convertBlobDataToPDF, getVar} from '@System/support/helpers';

function PrintSkusBarcode({className, printType = PRINT_SKU_TYPE.SKU_CODE, skuIds, data = []}) {
    const [loading, setLoading] = useState(false);

    function printStamp(stampSize, skus) {
        setLoading(true);
        const printInfos = [];
        forEach(skus, skuInfo => {
            let quantity =
                data.length > 0
                    ? sumBy(filter(data, ['sku_id', skuInfo?.id]), item => {
                        return Number(get(item, 'real_quantity', get(item, 'quantity', 0)));
                    })
                    : getVar(stampSize, 'item_per_page', 1);
            for (let i = 0; i < quantity; i++) {
                printInfos.push({
                    code: getVar(skuInfo, 'code', ''),
                    name: skuInfo?.name,
                    sku_id: printType === PRINT_SKU_TYPE.SKU_ID ? getVar(skuInfo, 'id', '') : undefined
                });
            }
        });

        api.downloadStampPdf({data: printInfos, column: stampSize.item_per_page})
            .then(res => {
                notification.success({message: t('product:message.download_file_print.success'), duration: 6});
                convertBlobDataToPDF(res.data, `sku-print`);

            })
            .catch(err => {
                notification.error({message: t('product:message.download_file_print.failed'), duration: 6});
            })
            .finally(() => setLoading(false));
    }

    function handlePrint(stampSize) {
        api.getInfoSkuByIds({sku_ids: skuIds})
            .then(res => {
                const skus = getVar(res, 'data.skus', []);
                if (isEmpty(skus)) {
                    notification.error({message: t('product:message.not_info_sku_print'), duration: 6});
                } else {
                    printStamp(stampSize, skus);
                }
            })
            .catch(() => {
                notification.error({message: t('message.fetch_fail'), duration: 6});
            });
    }

    const handlePrintStampButtonClick = stampSizeInfo => {
        if (skuIds.length) {
            handlePrint(stampSizeInfo);
        } else {
            notification.error({message: t('product:message.choose_sku_print_required')});
        }
    };
    const selectedAction = (
        <Menu className="lading-dropdown-action">
            {TYPE_STAMP_SIZE.map((item, index) => {
                return (
                    <Menu.Item
                        key={index}
                        className="_scan-order-list-of-lading-btn-remove-selected mb-3 mb-xl-0"
                        onClick={() => handlePrintStampButtonClick(item)}
                    >
                        {t(`product:TYPE_STAMP_SIZE.${item.label}`, {count: item.item_per_page})}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    return (
        <>
            <Dropdown overlay={selectedAction} trigger={['click']} className="_dropdown-action">
                <Button className={`${className} _print-stamp-btn`} type="primary" onClick={e => e.preventDefault()} loading={loading}>
                    {t(`product:print_product_btn.${printType}`)}
                </Button>
            </Dropdown>
        </>
    );
}

PrintSkusBarcode.propTypes = {
    skuIds: PropTypes.array,
    className: PropTypes.string,
};

PrintSkusBarcode.defaultProps = {
    skuIds: [],
    className: '',
};

export default PrintSkusBarcode;
