import { Select } from 'antd'
import React, { useEffect, useState } from 'react'

import apiService from '@Modules/Supplier/services/api'
import { get, isEmpty } from 'lodash'
import { auth } from '@Modules/Auth/services'

const { Option } = Select

export default function SelectSuppliers(props) {
    const [data, setData] = useState([])
    const user = auth.user();

    useEffect(() => {
        // setLoading(true)
        // apiService
        //     .getSuppliers({ per_page: 1000 })
        //     .then(result => {
        //         setData(get(result, 'data.suppliers', []))
        //     })
        //     .catch(err => {})
        //     .finally(() => setLoading(false))

        setData(get(user, 'suppliers',[]))
    }, [user])

    return (
        <Select
            {...props}
            value={!isEmpty(data) && props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {!isEmpty(data) &&
                data.map(opt => {
                    return (
                        <Option
                            key={get(opt, 'id')}
                            value={get(opt, 'id')}
                        >
                            {get(opt, 'name')}
                        </Option>
                    )
                })}
        </Select>
    )
}
