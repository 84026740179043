import { Table, Row, Col, Avatar } from 'antd'
import { filter, get, isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'

import { getVar } from '@System/support/helpers'

import { t } from '../../../../../system/i18n'
import { formatCurrency } from '../../../../../system/support/numberFormat'
import TableEmpty from '../../../../App/Utilities/TableEmpty'

export default function ProductList({ orderDetail }) {
    let { order } = orderDetail
    const [data, setData] = useState([])

    useEffect(() => {
        const orderSkuCombo = get(orderDetail, 'order_sku_combo', [])
        const orderSkus = filter(get(orderDetail, 'orderSkus', []), item => !item?.orderSku?.from_sku_combo)

        const dataCombo = map(orderSkuCombo, item => {
            return {
                sku: {
                    ...get(item, 'order_sku_combo', {}),
                    ...get(item, 'order_sku_combo.skuCombo', {}),
                    images: get(item, 'order_sku_combo.skuCombo.image'),
                },
                orderSku: {
                    ...get(item, 'order_sku_combo', {}),
                },
                unit: { name: 'Combo' },
                skuCombo: map(get(item, 'order_skus', []), sku => {
                    return {
                        orderSku: {
                            ...sku,
                        },
                        sku: {
                            ...get(sku, 'sku', {}),
                        },
                    }
                }),
            }
        })
        setData([...dataCombo, ...orderSkus])
    }, [orderDetail])

    const extraServices = get(order, 'extra_services', [])

    let getColumns = [
        {
            title: t('product:label.sku_code'),
            dataIndex: 'code',
            key: 'code',
            className: 'text-nowrap d-flex align-items-center _order-detail-product-list-code',
            render: (text, record) => {
                let { sku } = record
                const image = getVar(sku, 'images.0', '')
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            src={image}
                            shape="square"
                            className="mr-2"
                            size={36}
                        />
                        <div>
                            <span className="text-primary">{sku.code}</span>
                            <p>{sku.name}</p>
                        </div>
                    </div>
                )
            },
        },
        {
            title: t('product:label.unit_name'),
            dataIndex: 'unit',
            key: 'unit',
            className: 'text-nowrap _order-detail-product-list-unit',
            render: (text, record) => {
                let unit_name = get(record, 'unit.name', '')
                return unit_name
            },
        },
        {
            title: t('product:label.quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            className: 'text-right text-nowrap _order-detail-product-list-quantity',
            render: (text, record) => {
                return <span>{get(record, 'orderSku.quantity')}</span>
            },
        },
        {
            title: t('product:label.price_for_one'),
            dataIndex: 'price',
            key: 'price',
            className: 'text-right text-nowrap _order-detail-product-list-price',
            render: (text, record) => {
                let { orderSku } = record
                return formatCurrency(parseFloat(orderSku.price))
            },
        },
        {
            title: t('product:label.sale_off'),
            dataIndex: 'discount_amount',
            key: 'discount_amount',
            className: 'text-right text-nowrap _order-detail-product-list-discount_amount',
            render: (text, record) => {
                let { orderSku } = record
                return <span className="text-red">{(orderSku.discount_amount > 0 ? '-' : '') + formatCurrency(parseFloat(orderSku.discount_amount || 0))}</span>
            },
        },
        {
            title: t('product:label.price_for_all'),
            dataIndex: 'total_amount',
            key: 'total_amount',
            className: 'text-right text-nowrap _order-detail-product-list-total_amount',
            render: (text, record) => {
                let { orderSku } = record
                return formatCurrency(parseFloat(orderSku.total_amount))
            },
        },
    ]

    return (
        <div>
            <Table
                className="pb-3 _order-detail-product-list-table"
                dataSource={data}
                rowKey={record => record?.sku?.id}
                columns={getColumns}
                pagination={false}
                locale={{ emptyText: <TableEmpty /> }}
                childrenColumnName="skuCombo"
                scroll={{ x: true }}
            />
            <Row
                gutter={20}
                className="text-right mt-2"
            >
                <Col
                    xs={{ span: 14 }}
                    md={{ span: 16 }}
                    xl={{ span: 21 }}
                >
                    <strong>{t('orderDetail:label.total')}</strong>
                </Col>
                <Col
                    xs={{ span: 10 }}
                    md={{ span: 8 }}
                    xl={{ span: 3 }}
                    className="_order-detail-product-list-order_amount"
                >
                    {formatCurrency(order.order_amount ? order.order_amount : 0)}
                </Col>
            </Row>

            <Row
                gutter={20}
                className="text-right mt-2"
            >
                <Col
                    xs={{ span: 14 }}
                    md={{ span: 16 }}
                    xl={{ span: 21 }}
                >
                    <strong>{t('orderDetail:label.total_promotion')}</strong>
                </Col>
                <Col
                    xs={{ span: 10 }}
                    md={{ span: 8 }}
                    xl={{ span: 3 }}
                    className="_order-detail-product-list-total_promotion"
                >
                    <strong className="text-red">
                        {(order.discount_amount ? '-' : '') + formatCurrency(order.discount_amount ? order.discount_amount : 0)}
                    </strong>
                </Col>
            </Row>
            <Row
                gutter={20}
                className="text-right mt-2"
            >
                <Col
                    xs={{ span: 14 }}
                    md={{ span: 16 }}
                    xl={{ span: 21 }}
                >
                    <strong>{t('orderDetail:label.deliveried_money')}</strong>
                </Col>
                <Col
                    xs={{ span: 10 }}
                    md={{ span: 8 }}
                    xl={{ span: 3 }}
                    className="_order-detail-product-list-deliveried_money"
                >
                    {order.shipping_amount ? formatCurrency(order.shipping_amount) : 0}
                </Col>
            </Row>
            {!isEmpty(extraServices) &&
                extraServices.map((item, index) => {
                    return (
                        <Row
                            gutter={20}
                            className="text-right mt-2"
                            key={index}
                        >
                            <Col
                                xs={{ span: 14 }}
                                md={{ span: 16 }}
                                xl={{ span: 21 }}
                            >
                                <strong>{item.name}</strong>
                            </Col>
                            <Col
                                xs={{ span: 10 }}
                                md={{ span: 8 }}
                                xl={{ span: 3 }}
                            >
                                {item.amount ? formatCurrency(item.amount) : 0}
                            </Col>
                        </Row>
                    )
                })}
            <Row
                gutter={20}
                className="text-right mt-2"
            >
                <Col
                    xs={{ span: 14 }}
                    md={{ span: 16 }}
                    xl={{ span: 21 }}
                >
                    <strong>{t('orderDetail:label.money_customer_pay')}</strong>
                </Col>
                <Col
                    xs={{ span: 10 }}
                    md={{ span: 8 }}
                    xl={{ span: 3 }}
                    className="_order-detail-product-list-money_customer_pay"
                >
                    <strong className="text-red">{formatCurrency(order.total_amount ? order.total_amount : 0)}</strong>
                </Col>
            </Row>
        </div>
    )
}
