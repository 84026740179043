import { PlusCircleOutlined, MenuUnfoldOutlined, ContactsTwoTone, PlusCircleTwoTone } from '@ant-design/icons'
import { Button, Space, Table, Tag, Tooltip, Modal, notification } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React, { useState, useEffect } from 'react'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import useServicePackListQuery from '@Modules/ServicesManager/Hook/useServicePackListQuery'
import ListActiveCode from '@Modules/ServicesManager/components/ServiceCombo/ListActiveCode'
import ModalAddServiceCombo from '@Modules/ServicesManager/components/ServiceCombo/ModalAddServiceCombo'
import api from '@Modules/ServicesManager/services/api'
import { EVENTS } from '@Modules/ServicesManager/services/constants'

import { events } from '@System/events'
import { t } from '@System/i18n'

import ModalSellerService from './ModalSellerService'
import ServicePackDetail from './ServicePackDetail'

interface IPackListProps {
    countryId: number
}

const PackList: React.FC<IPackListProps> = ({ countryId }) => {
    const [servicePacks, setServicePacks] = useState<any[]>([])
    const [servicePack, setServicePack] = useState({})
    const [isVisibleDetail, setIsVisibleDetail] = useState(false)
    const [isVisibleSeller, setIsVisibleSeller] = useState(false)
    const [idCreateCode, setIdCreateCode] = useState(0)
    const [servicePackId, setServicePackId] = useState(0)
    const [idCombo, setIdCombo] = useState(0)
    const [loading, setLoading] = useState(false)

    const { data: response, isFetching, refetch } = useServicePackListQuery({ country_id: countryId })

    useEffect(() => {
        const servicePacks = get(response, 'data.service_packs', [])
        const data: any[] = []
        servicePacks.map(item => {
            const servicePack = get(item, 'service_pack', {})
            const serviceCombos = get(item, 'service_combos', [])
            data.push({
                ...servicePack,
                serviceCombos: serviceCombos.length > 0 ? serviceCombos : undefined,
            })
        })
        setServicePacks(data)
    }, [response])

    useEffect(() => {
        events.listen(EVENTS.REFETCH_SERVICE_PACK, () => refetch())
    }, [])

    const columns = [
        {
            className: ` _manage-service-column-name`,
            title: t('label.service_pack'),
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                const servicePackId = get(record, 'service_pack_id')
                return (
                    <p>
                        {text ? (
                            !!servicePackId ? (
                                text
                            ) : (
                                <Button
                                    type="link"
                                    className="font-weight-500"
                                    onClick={() => handleShowModalDetail(record)}
                                >
                                    {text}
                                </Button>
                            )
                        ) : (
                            t('noText')
                        )}
                        {!!servicePackId && (
                            <Tag
                                color="#74a6c7"
                                className="ml-2"
                            >
                                combo
                            </Tag>
                        )}
                    </p>
                )
            },
        },
        {
            className: ` _manage-service-column-service_pack_code`,
            title: t('label.service_code'),
            dataIndex: 'code',
            key: 'code',
        },

        {
            className: `_manage-service-column-extra_day text-center`,
            title: t('label.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : t('noText')}</p>
            },
        },
        {
            className: `_manage-service-column-action text-center`,
            dataIndex: 'id',
            key: 'action',
            render: (text, record) => {
                const servicePackId = get(record, 'service_pack_id')
                return (
                    <Space>
                        {!servicePackId && (
                            <>
                                <Tooltip title={t('heading.list-merchants')}>
                                    <Button onClick={() => handleShowModalSeller(record)}>
                                        <ContactsTwoTone twoToneColor={'#4551BB'} />
                                    </Button>
                                </Tooltip>
                                {auth.can(permissions.SERVICE_ADD) && (
                                    <Tooltip title={t('serviceManager:btn.create_service_combo')}>
                                        <Button
                                            onClick={() => setServicePackId(text)}
                                            disabled={loading}
                                        >
                                            <PlusCircleTwoTone twoToneColor={'#4551BB'} />
                                        </Button>
                                    </Tooltip>
                                )}
                            </>
                        )}
                        {!!servicePackId && (
                            <>
                                {auth.can(permissions.SERVICE_ADD) && (
                                    <Tooltip title={t('serviceManager:title.create_active_code')}>
                                        <Button onClick={() => setIdCreateCode(text)}>
                                            <PlusCircleOutlined style={{ color: '#3d7a1f' }} />
                                        </Button>
                                    </Tooltip>
                                )}
                                <Tooltip title={t('serviceManager:title.list_active_code')}>
                                    <Button onClick={() => setIdCombo(text)}>
                                        <MenuUnfoldOutlined style={{ color: '#3d7a1f' }} />
                                    </Button>
                                </Tooltip>
                            </>
                        )}
                    </Space>
                )
            },
        },
    ]

    const handleShowModalDetail = (servicePack: any) => {
        setIsVisibleDetail(true)
        setServicePack(servicePack)
    }

    const handleHideModalDetail = () => {
        setIsVisibleDetail(false)
    }

    const handleShowModalSeller = (servicePack: any) => {
        setIsVisibleSeller(true)
        setServicePack(servicePack)
    }

    const handleHideModalSeller = () => {
        setIsVisibleSeller(false)
    }

    const handleCreateServiceCombo = servicePackId => {
        handleHideModalDetail()
        setServicePackId(servicePackId)
    }

    const hiddenCreateCombo = () => {
        setServicePackId(0)
        refetch()
    }

    const handleCreateActiveCode = () => {
        setLoading(true)
        api.createActiveCode({ service_combo_id: idCreateCode, type: 'SERVICE_COMBO' })
            .then(() => {
                setIdCreateCode(0)
                setIdCombo(idCreateCode)
                notification.success({ message: t('serviceManager:message.create_active_code_success') })
            })
            .catch(() => {
                notification.error({ message: t('serviceManager:message.create_active_code_failed') })
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <div
                style={{ border: '2px solid #e5e5e5' }}
                className={`mt-4`}
            >
                <Table
                    rowKey={record => record?.code}
                    className={'service-pack-list-table'}
                    dataSource={servicePacks}
                    scroll={{ x: 768 }}
                    loading={isFetching}
                    columns={columns}
                    childrenColumnName={'serviceCombos'}
                    pagination={false}
                    locale={{
                        emptyText: (
                            <TableEmpty
                                className="_product-list-empty"
                                loading={isFetching}
                            />
                        ),
                    }}
                />
            </div>
            {isVisibleDetail && (
                <ServicePackDetail
                    handleHideModal={handleHideModalDetail}
                    servicePackId={get(servicePack, 'id')}
                    countryId={countryId}
                    handleCreateServiceCombo={handleCreateServiceCombo}
                />
            )}

            {isVisibleSeller && (
                <ModalSellerService
                    servicePack={servicePack}
                    handleHideModal={handleHideModalSeller}
                    countryId={countryId}
                    servicePackId={get(servicePack, 'id')}
                />
            )}

            {!!servicePackId && (
                <ModalAddServiceCombo
                    servicePackId={servicePackId}
                    hiddenCreateCombo={hiddenCreateCombo}
                />
            )}

            {!!idCreateCode && (
                <Modal
                    title={t('serviceManager:title.confirm_create_active_code')}
                    visible={!!idCreateCode}
                    className="_modal-confirm-create-active-code"
                    closable={true}
                    maskClosable={false}
                    footer={[
                        <Button
                            key="cancel"
                            onClick={() => setIdCreateCode(0)}
                        >
                            {t('btn.cancel')}
                        </Button>,
                        <Button
                            key="ok"
                            type="primary"
                            loading={loading}
                            onClick={handleCreateActiveCode}
                        >
                            {t('btn.ok')}
                        </Button>,
                    ]}
                >
                    {t('serviceManager:content.confirm_create_active_code')}
                </Modal>
            )}
            {!!idCombo && (
                <ListActiveCode
                    comboId={idCombo}
                    setVisible={setIdCombo}
                    visible={!!idCombo}
                />
            )}
        </>
    )
}

export default PackList
