import { Select } from 'antd';
import isFunction from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { t } from '../../../system/i18n';
import { warehouseStorage, warehouseAreaStorage } from '../../App/services';
import useWarehouseItemsQuery from '../Hooks/useWarehouseItemsQuery';

const { Option } = Select;

export default function SelectWarehouse(props) {
    const { isLoading, data } = useWarehouseItemsQuery({
        per_page: 1000,
    });

    const { onChange, value, noSuggest, ...rest } = props;

    function _onChange(value, option) {
        if (!noSuggest) {
            warehouseStorage.setValue(value);
            warehouseAreaStorage.reset();
        }

        if (isFunction(onChange)) {
            onChange(value, option);
        }
    }

    return (
        <Select
            loading={isLoading}
            onChange={_onChange}
            value={data?.data?.warehouses?.length > 0 && value ? value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            {...rest}
        >
            {data?.data?.warehouses?.map(warehouse => (
                <Option key={warehouse?.warehouse?.id} value={warehouse?.warehouse?.id}>
                    {warehouse?.warehouse?.name +
                        (warehouse?.warehouse?.code
                            ? ' (' +
                              (warehouse?.warehouse?.code === 'default'
                                  ? t('order:label.default')
                                  : warehouse?.warehouse?.code) +
                              ')'
                            : '')}
                </Option>
            ))}
        </Select>
    );
}

SelectWarehouse.propTypes = {
    noSuggest: PropTypes.bool,
};

SelectWarehouse.defaultProps = {
    noSuggest: true,
};
