import React, {useEffect} from "react";

import List from "../../../components/List";
import {t} from "../../../../../system/i18n";
import {TYPES} from "../../../services/constants";
import {setDocumentTitle, setNavigator} from "../../../../App/services";
import FormSearch from "../../../components/FormSearch";
import useFilter from "../../../../App/Hooks/useFilter";
import {warehouseStorage} from "../../../../App/services";
import useImportingDocumentsQuery from '@Modules/Document/Hooks/useImportingDocumentsQuery';

export default function DocumentImporting({history, location}) {
    const {filters, addFilter} = useFilter(history, location, {
        warehouse_id: warehouseStorage.value || ""
    });
    const { isLoading: loading, data } = useImportingDocumentsQuery(filters);

    useEffect(() => {
        setNavigator(t("title.documents_importing"), [
            {
                name: t("breadcrumb.documents_importing")
            }
        ]);
        setDocumentTitle(t("title.documents_importing"));
    }, []);

    return (
        <div className="site-content mh-100">
            <FormSearch
                onSearch={addFilter}
                filters={filters}
                initFilters={{}}
                loading={loading}
                type={TYPES.TYPE_IMPORTING}
            />
            <List
                documents={data?.data?.documents}
                pagination={data?.data?.pagination}
                addFilter={addFilter}
                filters={filters}
                loading={loading}
                type={TYPES.TYPE_IMPORTING}
            />
        </div>
    );
}
