import { Row, Col, Spin } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import useFinanceOrderStatisticQuery from '@Modules/Finance/Hooks/useFinanceOrderStatisticQuery';
import { STATISTIC_CODE } from '@Modules/Finance/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

function Summary({ filters }) {
    const [summary, setSummary] = useState({});
    const [currency, setCurrency] = useState({});

    const { data, isFetching, refetch, isError, error } = useFinanceOrderStatisticQuery(filters);
    useEffect(() => {
        refetch();
    }, [filters, refetch]);

    useEffect(() => {
        setSummary(get(data, 'data', {}));
        setCurrency(get(data, 'data.currency', {}));
    }, [data]);

    return (
        <Row className="mt-4 finance_order" gutter={16} justify="space-between" type="flex">
            {!isEmpty(summary) &&
                STATISTIC_CODE.map((item, index) => {
                    const value = summary[item];
                    return (
                        <Col key={index} className="mb-2" xs={{ span: 12 }} lg={{ span: index === 0 || index === 4 ? 4 : 5 }}>
                            <div className="finance_stats d-flex flex-column">
                                <span>{t(`finance:label.${item}`)}</span>
                                <span className="stats_amount">{value ? !isEmpty(currency) ? (
                                    formatCurrency(value, currency)
                                ) : (
                                    value
                                ) : ''}</span>
                            </div>
                        </Col>
                    );
                })}
        </Row>
    );
}

export default Summary;
