import {api} from "../../../system/api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getMerchants: () => api.get("/merchants"),
    getAllMerchants: (params) => api.get("/merchants/suggest", {params}),
    getMerchantItems: params => api.get("/merchants/items", {
        params: params,
        singleRequest: true,
        loading: false
    }),
    toggleStatus: (id, data) => api.put(`/merchants/${id}/status`, data),
    createMerchant: data => api.post("/merchants", data),
    updateMerchant: (id, data) => api.put(`/merchants/${id}`, data),
    connectShopbase: (merchantId, data) => api.put(`merchants/${merchantId}/connect-shop-base`, data),
    disConnectShopbase: (merchantId) => api.put(`merchants/${merchantId}/disconnect-shop-base`),
    getMerchantsTransaction:(id,params) => api.get(`merchants/${id}/transactions`, {params}),
    editMerchantsWallet: (id, data) => api.post(`merchants/${id}/transaction`, data),
}
