import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import api from '../services/api';

const useHistoryOfProductVariant = (sku_id, params) =>
    useQuery(['history-product-variant', sku_id, params], () => api.getLogChargeSku({ ...params, sku_id }), {
        retry: false,
        enabled: !!sku_id,
        onError: error => {
            processResponseError(error);
        },
    });

export default useHistoryOfProductVariant;
