import { api } from '../../../system/api';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getUserMerchants: params => api.get(`/users/merchants`, { params, singleRequest: true, loading: false }),
    addSellerForUser: data => api.post(`users/${data.user_id}/merchants`, data),
    updateWarehouses: data => api.post(`users/${data.user_id}/warehouses`, data),
    updateMarkets: data => api.post(`users/${data.user_id}/countries`, data),
    updateSuppliers: data => api.post(`users/${data.user_id}/suppliers`, data),
    getUserDetail: userId => api.get(`/users/${userId}`, { singleRequest: true, loading: false })
};
