import { Button } from 'antd';
import React, { useState } from 'react';

import { t } from '@System/i18n';

import ModalSelectStaff from './ModalSelectStaff';

const AddStaffForOrder = ({ isSelectAllPage, ids, filters }) => {
    const [visible, setVisible] = useState(false);

    const confirmModalStaff = () => {
        setVisible(true);
    };

    return (
        <>
            <Button className="_prepare-order-btn-create-choose_handler btn-menu-item" onClick={confirmModalStaff}>
                {t('order:btn.choose_handler')}
            </Button>
            {visible && (
                <ModalSelectStaff
                    setVisible={setVisible}
                    isSelectAllPage={isSelectAllPage}
                    ids={ids}
                    filters={filters}
                />
            )}
        </>
    );
};

export default AddStaffForOrder;
