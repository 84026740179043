import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'
import { Tag, Tooltip } from 'antd'
import isEmpty from 'lodash/isEmpty'
// import map from 'lodash/map';
import truncate from 'lodash/truncate'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import DynamicTable from '@Modules/App/DynamicTable'
import { auth } from '@Modules/Auth/services'
import PERMISSIONS from '@Modules/Auth/services/permissions'
import {
    // ORDER_PACKING_STATUS,
    ORDER_TAGS,
} from '@Modules/Order/services/constants'

import { t, trans } from '@System/i18n'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'
import { isNilOrEmptyString } from '@System/support/string'

import CustomizedPagination from '../../../App/CustomizedPagination'
import PerformAction from './PerformAction'

export default function List({
    orderPackingsData,
    addFilter,
    filters,
    loading,
    rowSelection,
    renderSelection,
    isSelectAllPage,
    freightBills,
    ids,
    orderPackings,
    pagination,
    setSelectedRowKeys,
    setting,
}) {
    function addSort(sort_by, sort) {
        addFilter({ ...filters, sort_by, sort })
    }

    const columns = useMemo(
        () => [
            {
                className: '_prepare-order-list-order-code',
                title: t('order:label.order_code'),
                dataIndex: ['order', 'code'],
                key: 'order_code',
                disabled: true,
                render: (text, record) => {
                    const tags = getVar(record, 'tags', [])
                    const refCode = getVar(record, 'order.ref_code', [])

                    return (
                        <div>
                            <div className="d-flex flex-column">
                                <Link to={url.to('orders.detail', { id: record?.order_id })}>{text}</Link>
                                <small className="text-muted ">{refCode}</small>
                            </div>
                            <div className="d-flex">
                                {tags.map((item, index) => {
                                    const tag = getVar(ORDER_TAGS, item, {})
                                    if (!isEmpty(tag)) {
                                        return (
                                            <Tag
                                                className="mr-1"
                                                key={index}
                                                color={tag.color}
                                            >
                                                {t(`order:label.tag.${item}`)}
                                            </Tag>
                                        )
                                    }
                                    return false
                                })}
                            </div>
                        </div>
                    )
                },
            },
            {
                className: '_prepare-order-list-merchant-info',
                title: t('order:label.seller_info'),
                key: 'seller_info',
                render: (_, record) => {
                    const name = record?.merchant_name
                    const code = record?.merchant_code

                    return !setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.OPERATION_VIEW_LIST_ORIGINAL)) ? (
                        <p>{[name, code].filter(item => !isNilOrEmptyString(item)).join(' - ')}</p>
                    ) : (
                        '***'
                    )
                },
            },
            {
                className: '_prepare-order-list-receiver-info',
                title: t('order:label.receiver_info'),
                key: 'receiver_info',
                render: (_, record) => {
                    const name = record?.receiver_name
                    const phone = record?.receiver_phone

                    return !setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.OPERATION_VIEW_LIST_ORIGINAL))
                        ? orderPackingsData?.can_view_customer
                            ? [name, phone].filter(item => !isNilOrEmptyString(item)).join(' - ')
                            : name
                        : '***'
                },
            },
            {
                className: '_prepare-order-list-shipping-partner-info',
                dataIndex: 'shipping_partner',
                title: t('order:label.shipping_partner_id'),
                key: 'shipping_partner',
                render: text => {
                    const name = text?.name
                    const code = text?.code

                    return <p>{[name, code].filter(item => !isNilOrEmptyString(item)).join(' - ')}</p>
                },
            },
            {
                className: '_prepare-order-list-freight_bill',
                title: t('order:label.freight_bill'),
                dataIndex: 'freight_bill',
                key: 'freight_bill',
            },
            {
                className: '_prepare-order-list-picker',
                title: t('order:label.picker'),
                dataIndex: 'picker',
                key: 'picker',
                render: text => (text ? text?.username : ''),
            },
            {
                className: '_prepare-order-list-reason-failed',
                title: t('order:label.reason_failed'),
                dataIndex: 'error_type',
                key: 'error_type',
                render: text => (text ? t(`order:error_type.${text}`) : null),
            },
            {
                className: '_prepare-order-list-intended-delivery-at',
                title: trans('order:label.intended_delivery_at', {
                    sort:
                        filters.sort_by === 'intended_delivery_at' ? (
                            <>
                                {filters?.sort === 'asc' ? (
                                    <SortAscendingOutlined
                                        style={{ color: '#ce3c32' }}
                                        onClick={() => addSort('intended_delivery_at', 'desc')}
                                    />
                                ) : (
                                    <SortDescendingOutlined
                                        style={{ color: '#ce3c32' }}
                                        onClick={() => addSort('intended_delivery_at', 'asc')}
                                    />
                                )}
                            </>
                        ) : (
                            <SortAscendingOutlined onClick={() => addSort('intended_delivery_at', 'asc')} />
                        ),
                }),
                dataIndex: 'intended_delivery_at',
                key: 'intended_delivery_at',
                render: text => {
                    return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>
                },
            },
            {
                className: '_prepare-order-list-created-at',
                title: trans('order:label.created_at', {
                    sort:
                        filters.sort_by === 'created_at' ? (
                            <>
                                {filters?.sort === 'asc' ? (
                                    <SortAscendingOutlined
                                        style={{ color: '#ce3c32' }}
                                        onClick={() => addSort('created_at', 'desc')}
                                    />
                                ) : (
                                    <SortDescendingOutlined
                                        style={{ color: '#ce3c32' }}
                                        onClick={() => addSort('created_at', 'asc')}
                                    />
                                )}
                            </>
                        ) : (
                            <SortAscendingOutlined onClick={() => addSort('created_at', 'asc')} />
                        ),
                }),
                dataIndex: 'created_at',
                key: 'created_at',
                render: text => {
                    return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>
                },
            },
            {
                className: '_prepare-order-list-created-at',
                title: trans('order:label.remark', {
                    sort:
                        filters.sort_by === 'remark' ? (
                            <>
                                {filters?.sort === 'asc' ? (
                                    <SortAscendingOutlined
                                        style={{ color: '#ce3c32' }}
                                        onClick={() => addSort('remark', 'desc')}
                                    />
                                ) : (
                                    <SortDescendingOutlined
                                        style={{ color: '#ce3c32' }}
                                        onClick={() => addSort('remark', 'asc')}
                                    />
                                )}
                            </>
                        ) : (
                            <SortAscendingOutlined onClick={() => addSort('remark', 'asc')} />
                        ),
                }),
                dataIndex: 'remark',
                key: 'remark',
                render: text => {
                    return (
                        <>
                            <Tooltip title={text}>
                                {truncate(text, {
                                    length: 24,
                                    separator: ' ',
                                })}
                            </Tooltip>
                        </>
                    )
                },
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [orderPackingsData]
    )

    // const filteredColumns = useMemo(() => {
    //     let columnsTable = columns;
    //     if (
    //         map([ORDER_PACKING_STATUS.WAITING_PROCESSING], 'key').includes(filters.order_status) ||
    //         filters.error_tracking
    //     ) {
    //         columnsTable = columns.filter(item => item.key !== 'freight_bill');
    //     }

    //     return filters.error_tracking ? columnsTable : columnsTable.filter(item => item.key !== 'error_type');
    // }, [columns, filters]);

    return (
        <div className="p-3">
            <div className="d-flex flex-wrap">
                {renderSelection()}
                {rowSelection?.selectedRowKeys.length !== 0 || isSelectAllPage ? (
                    <PerformAction
                        className="mb-3 ml-auto"
                        filters={filters}
                        ids={ids}
                        freightBills={freightBills}
                        isSelectAllPage={isSelectAllPage}
                        orderPackings={orderPackings}
                        setSelectedRowKeys={setSelectedRowKeys}
                        pagination={pagination}
                    />
                ) : (
                    ''
                )}
            </div>
            {/* <Table
                className="list-products-table pb-3"
                dataSource={orderPackingsData?.order_packings}
                rowKey={record => record.id}
                columns={filteredColumns}
                pagination={false}
                rowSelection={rowSelection}
                loading={loading}
                locale={{ emptyText: <TableEmpty loading={loading} /> }}
                scroll={{ x: 576 }}
            /> */}
            <DynamicTable
                initColumns={columns}
                dataSource={orderPackingsData?.order_packings || []}
                rowKey={record => record?.id}
                className="list-prepare-table pb-3"
                loading={loading}
                rowSelection={rowSelection}
            />
            <CustomizedPagination
                pagination={orderPackingsData?.pagination}
                addFilter={addFilter}
                filters={filters}
            />
        </div>
    )
}
