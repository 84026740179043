import React, {useEffect, useState} from "react";
import {setNavigator} from "../../../App/services";
import api from "../../services/api";
import lodash from "lodash";
import {notification, Tabs} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import Detail from "./Detail";
import {t} from "../../../../system/i18n";
import VariantStocks from "./VariantStocks/index";
import _ from "lodash";
import processResponseError from "@System/api/processResponseError";

const {TabPane} = Tabs;

export default function VariantDetail({match}) {
    const {params} = match;
    const [skuInfo, setSkuInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [activeKey, setActiveKey] = useState("1");

    useEffect(() => {
        setNavigator(t("common:title.variant-detail"), [
            {
                name: t("common:breadcrumb.list-variants"),
                route: "variants.list"
            },
            {
                name: t("common:breadcrumb.variant-detail")
            }
        ]);
        fetchSkuDetail();

        // eslint-disable-next-line
    }, []);

    function fetchSkuDetail() {
        setLoading(true);
        return api.getSkuDetail(params.id)
            .then(res => {
                setSkuInfo(lodash.get(res, "data", {}));
            })
            .catch(err => {
                processResponseError(err)
            })
            .finally(() => setLoading(false));
    }

    return (
        <div className="site-content bg-white order-detail">
            <div className="title-page d-flex justify-content-between align-items-center pb-3">
                <div className="d-flex">
                    <h2 className="text-uppercase">[{_.get(skuInfo, "sku.code", "")}] - {t("common:heading.variant-detail")}</h2>
                </div>
            </div>
            <div>
                <Tabs
                    className="customize-ant-tabs"
                    defaultActiveKey={activeKey}
                    onChange={(activeKey) => setActiveKey(activeKey)}
                    type="card"
                >
                    <TabPane tab={t("product:title.common_info")} key="1">
                        {
                            loading ?
                                <div className="p-5 text-center"><LoadingOutlined /></div> :
                                <Detail
                                    skuInfo={skuInfo}
                                    setSkuInfo={setSkuInfo}
                                />
                        }
                    </TabPane>
                    <TabPane tab={t("product:title.stock_info")} key="2">
                        <VariantStocks
                            skuInfo={lodash.get(skuInfo, "sku", {})}
                        />
                    </TabPane>
                </Tabs>
            </div>
        </div>
    );
}
