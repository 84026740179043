import { notification } from 'antd';
import isFunction from 'lodash/isFunction';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import apiService from '../services/api';
import { t } from '../../../system/i18n';
import { notifyErrorFromServer } from '../../../system/support/helpers';

const useCreateExportingDocumentMutation = payload => {
    if (!payload) {
        payload = {};
    }

    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit({
        ...payload,
        onSuccess: ({ data }) => {
            if (isFunction(payload?.onSuccess)) {
                payload.onSuccess(data);
            }
    
            notification.success({
                message: t('document:create_exporting_document.success'),
                duration: 6
            });
        },
        onError: error => {
            if (isFunction(payload?.onError)) {
                payload.onError(error);
            }
    
            if (payload.notifyWhenError) {
                notifyErrorFromServer(error, t('document:create_exporting_document.failed'), 'document:create_exporting_document.errors');
            }
        }
    }, ['notifyWhenError']);

    return useMutation(data => apiService.createExportDocument(data), formattedConfig);
};

export default useCreateExportingDocumentMutation;
