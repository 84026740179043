import React, {useEffect} from 'react';
import {Table} from "antd";
import {t} from "../../../../system/i18n";
import TableEmpty from "../../../App/Utilities/TableEmpty";
import _ from 'lodash';
import FormSearch from "./FormSearch";
import useFilter from "../../../App/Hooks/useFilter";
import {setDocumentTitle, setNavigator, warehouseAreaStorage, warehouseStorage} from "../../../App/services";
import CustomizedPagination from "../../../App/CustomizedPagination";
import {Link} from "react-router-dom";
import {url} from "../../../../system/routing";
import useWarehouseAreasQuery from '@Modules/WarehouseArea/Hooks/useWarehouseAreasQuery';

function ListWarehouseArea(props) {
    const {history, location} = props;
    const {filters, addFilter} = useFilter(history, location, {
        warehouse_id: warehouseStorage.value || "",
        id: warehouseAreaStorage.value || ""
    });
    const { isLoading: loading, data } = useWarehouseAreasQuery(filters);

    useEffect(() => {
        setNavigator(t("common:title.list_warehouse_area"), [
            {
                name: t("common:breadcrumb.warehouses"),
                route: "warehouses.list"
            },
            {
                name: t("common:breadcrumb.list_warehouse_area")
            }
        ]);
        setDocumentTitle(t("title.list_warehouse_area"));
    }, []);

    const columns = [
        {
            title: t("product:label.warehouse_code"),
            dataIndex: 'warehouse_code',
            key: 'warehouse_code',
            render: (text, record) => {
                const warehouse_code = _.get(record, "warehouse.code", "");
                const warehouse_id = _.get(record, "warehouse.id", "");
                return <Link to={url.to("warehouses.detail", {id: warehouse_id})}>
                    <strong style={{fontWeight: "500"}}>
                        {warehouse_code === "default" ? t("order:label.default") : warehouse_code}
                    </strong>
                </Link>
            }
        },
        {
            title: t("product:label.warehouse_name"),
            dataIndex: 'warehouse_name',
            key: 'warehouse_name',
            render: (text, record) => {
                const warehouse_name = _.get(record, "warehouse.name", "");
                return <p>{warehouse_name === "default" ? t("order:label.default") : warehouse_name}</p>
            }
        },
        {
            title: t("warehouseArea:label.code"),
            dataIndex: 'warehouseArea.code',
            key: 'warehouseArea.code',
            render: (text, record) => {
                const warehouse_area_code = _.get(record, "warehouseArea.code", "");
                return <p>{warehouse_area_code === "default" ? t("order:label.default") : warehouse_area_code}</p>
            }
        },
        {
            title: t("warehouseArea:label.name"),
            dataIndex: 'warehouse_area_name',
            key: 'warehouse_area_name',
            render: (text, record) => {
                const warehouse_area_name = _.get(record, "warehouseArea.name", {});
                return <p>{warehouse_area_name === "default" ? t("order:label.default") : warehouse_area_name}</p>
            }
        }
    ];

    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{t("common:heading.list_warehouse_area")}</h2>
                </div>
                <div className="m-3">
                    <FormSearch
                        onSearch={addFilter}
                        filters={filters}
                        loading={loading}
                    />
                    <Table
                        rowKey={(record) => record.warehouseArea.id}
                        loading={loading}
                        dataSource={data?.data?.warehouseAreas}
                        columns={columns}
                        pagination={false}
                        locale={{emptyText: <TableEmpty/>}}
                    />
                    <CustomizedPagination
                        pagination={data?.data?.pagination}
                        filters={filters}
                        addFilter={addFilter}
                    />
                </div>
            </div>
        </div>
    )
}

export default ListWarehouseArea;
