import { useRef } from 'react';

// modified from https://ahooks.js.org/hooks/advanced/use-persist-fn

const usePersistFn = fn => {
    const fnRef = useRef();
    const persistFn = useRef();

    fnRef.current = fn;

    if (!persistFn.current) {
        persistFn.current = function (...args) {
            return fnRef.current.apply(this, args);
        };
    }

    return persistFn.current;
};

export default usePersistFn;
