import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useShippingPartnersQuery = (params, settings) =>
    createUseQuery({
        name: ['shipping-partners', params],
        query: () => apiService.getShippingPartners(params),
        settings: {
            ...settings,
            enabled: false,
        },
    });

export default useShippingPartnersQuery;
