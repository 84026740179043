import { Badge, Modal, Table } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import React from 'react';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { dateFormatter } from '@Modules/App/services';
import { SERVICES_PRICE_COLORS } from '@Modules/Dropshipping/services/constants';

import { t } from '@System/i18n';

import ModalPriceDetail from './ModalPriceDetail';

const History = ({ prices, pagination, addFilter, filters, isFetching, currency, fetchPrice }) => {
    const { xs } = useBreakpoint();
    let columns = [
        {
            className: '_quote-create-table-quotation',
            title: t('product:label.quotation'),
            dataIndex: ['product_price', 'id'],
            key: 'id',
            render: (text, record) => {
                return (
                    <p className="font-weight-bold cursor-pointer" style={{ color: '#4551BB' }} onClick={() => showConfirm(text)}>
                        #{text}
                    </p>
                );
            },
        },
        {
            className: '_quote-create-table-creator',
            title: t('product:label.creator'),
            dataIndex: ['creator', 'name'],
            key: 'creator',
        },
        {
            className: '_quote-create-table-created_at',
            title: t('common:label.created_at'),
            dataIndex: ['product_price', 'created_at'],
            key: 'created_at',
            render: text => dateFormatter.full(text),
        },
        {
            className: '_quote-create-table-status',
            title: t('common:label.status'),
            dataIndex: ['product_price', 'status'],
            key: 'status',
            render: text => {
                return text ? (
                    <Badge color={SERVICES_PRICE_COLORS[text]} text={t(`product:SERVICES_PRICE_STATUS.${text}`)} />
                ) : null;
            },
        },
    ];

    const showConfirm = id => {
        Modal.confirm({
            title: t('product:label.quotation_detail'),
            icon: false,
            width: '1200px',

            cancelText: t('product:btn.close'),
            okButtonProps: { style: { display: 'none' } },
            content: <ModalPriceDetail id={id} currency={currency} fetchPrice={fetchPrice} />,
        });
    };

    return (
        <div
            className={`${xs ? 'p-2' : 'p-3'} m-2 mt-4 _dropship-service-list_new-history`}
            style={{ border: '1px solid #e5e5e5' }}
        >
            <h3 className="text-fz-20">{t('product:title.quotation_history')}</h3>
            <Table
                className="_dropship-service-list_new-table-history pb-3 mt-4"
                rowKey={record => record?.product_price?.id}
                columns={columns}
                dataSource={prices && prices}
                loading={isFetching}
                scroll={{ x: true }}
                pagination={false}
                locale={{ emptyText: <TableEmpty className="_product-list-empty" /> }}
            />
            <CustomizedPagination pagination={pagination} filters={filters} addFilter={addFilter} />
        </div>
    );
};

export default History;
