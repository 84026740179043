import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useUpdateSkuInventoryDocumentMutation = createUseMutation({
    namespace: 'document:sku_inventories_document.update',
    mutation: data => apiService.update(data),
});

export default useUpdateSkuInventoryDocumentMutation;
