import { Form } from 'antd';
import omit from 'lodash/omit';
import React, { useEffect } from 'react';

import useAntdTable from '@Modules/App/Hooks/useAntdTable';
import useDeepCompareEffect from '@Modules/App/Hooks/useDeepCompareEffect';
import useTableSelection from '@Modules/App/Hooks/useTableSelection';
import {setDocumentTitle, setNavigator} from '@Modules/App/services';
import useWarehouseStocksQuery from '@Modules/Inventory/Hooks/useWarehouseStocksQuery';

import { t } from '@System/i18n';

import Body from './components/Body';
import Header from './components/Header';

const WarehouseStocks = () => {
    const [form] = Form.useForm();
    const {
        filters,
        isFetching,
        pagination,
        warehouse_stocks: warehouseStocks,
        search,
    } = useAntdTable(useWarehouseStocksQuery, {
        form,
        defaultParams: {
            out_of_stock: 1,
            sku_status: 'ON_SELL',
        },
    });
    const tableSelectionData = useTableSelection({
        totalInAllPage: pagination?.total || 0,
        totalInCurrentPage: warehouseStocks?.length || 0,
        totalPage: pagination?.page_total || 0,
        type: t('inventory:out_of_stock_product').toLowerCase(),
    });
    const { clearSelection } = tableSelectionData;

    const handleChangePage = params => {
        search.submit(params);
    };

    useDeepCompareEffect(() => {
        clearSelection();
    }, [omit(filters, 'page'), clearSelection]);

    useEffect(() => {
        setNavigator(t('menu.warehouse_stocks'), [
            {
                name: t('breadcrumb.warehouses'),
                route: 'warehouses.list',
            },
            {
                name: t('menu.warehouse_stocks'),
            },
        ]);
        setDocumentTitle(t("menu.warehouse_stocks"));
    }, []);

    return (
        <div className="px-4 py-px-32">
            <Header className="mb-4" form={form} loading={isFetching} onClear={search.clear} onSubmit={search.submit} />
            <Body
                loading={isFetching}
                pagination={pagination}
                tableSelectionData={tableSelectionData}
                warehouseStocks={warehouseStocks}
                onChangePage={handleChangePage}
                onRefetch={search.submit}
            />
        </div>
    );
};

export default WarehouseStocks;
