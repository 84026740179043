import React from "react";
import {Select} from "antd";

import {t} from "../../../system/i18n";
import { VARIAN_STATUS } from "@Modules/ProductVariants/services/constants";

const {Option} = Select;

export default function SelectStatuses(props) {
    return (
        <Select
            {...props}
            value={ props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
           {Object.keys(VARIAN_STATUS).map(opt => {
                return (
                    <Option key={VARIAN_STATUS[opt]} value={VARIAN_STATUS[opt]}>
                        {t(`product:status.${VARIAN_STATUS[opt]}`)}
                    </Option>
                );
            })}
        </Select>
    );
}
