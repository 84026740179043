import { useQuery } from 'react-query'

import processResponseError from '@System/api/processResponseError'

import api from '../services/api'

const useServiceListQuery = params => {
    return useQuery(['service-manage', params], () => api.getServices(params), {
        retry: false,
        onError: err => {
            processResponseError(err)
        },
    })
}

export default useServiceListQuery
