import { useState, useEffect } from 'react';

import useDebounceFn from './useDebounceFn';

// modified from https://ahooks.js.org/hooks/side-effect/use-debounce

function useDebounce(value, options) {
    const [debounced, setDebounced] = useState(value);

    const { run } = useDebounceFn(() => {
        setDebounced(value);
    }, options);

    useEffect(() => {
        run();

        // eslint-disable-next-line
    }, [value]);

    return debounced;
}

export default useDebounce;
