import React from "react";
import {Breadcrumb} from "antd";
import {Link, url} from "../../../system/routing";
import { Link as LinkRouterDom } from 'react-router-dom';
import {t} from "../../../system/i18n";
import {getVar} from "@System/support/helpers";
import isEmpty from 'lodash/isEmpty';

function Navigator({data}) {

    return (
        <Breadcrumb className="breadcrumb-header align-items-center">
            <Breadcrumb.Item key={1}>
                <Link to={"home"}>{t("common:breadcrumb.home")}</Link>
            </Breadcrumb.Item>
            {
                data && data.length > 0 &&
                    data.map((item, index) => {
                        const {name, route} = item;
                        const params = getVar(item, "params", {});
                        return (
                            <Breadcrumb.Item key={index}>
                                {
                                    item.route ?
                                        (isEmpty(params) ?
                                            <LinkRouterDom to={url.to(route, params)}>{name}</LinkRouterDom> :
                                            <Link to={route}>{name}</Link>) :
                                        <span>{name}</span>
                                }
                            </Breadcrumb.Item>
                        );
                    })
            }
        </Breadcrumb>
    );
}

export default Navigator;
