
import createUseStorageState from './createUseStorageState';

// modified from https://ahooks.js.org/hooks/state/use-local-storage-state

const useLocalStorageState = createUseStorageState(
    typeof window === 'object' ? window.localStorage : null,
);

export default useLocalStorageState;
