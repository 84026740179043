import {find, get, isEmpty, map, includes, filter, orderBy} from 'lodash';

import {updateCollectionItem} from '../../App/services/helpers';
import moment from 'moment';

export function calculatorTotalSku(data) {
    let skus = [];
    data.map(skuInfo => {
        const sku_id = get(skuInfo, 'sku_id');
        const quantity = get(skuInfo, 'quantity', 0);
        const skuExist = find(skus, ['sku_id', sku_id]);
        if (isEmpty(skuExist)) {
            skus.push(skuInfo);
        } else {
            const skuQuantity = get(skuExist, 'quantity', 0);
            skus = updateCollectionItem(skus, sku_id, {quantity: quantity + skuQuantity}, 'sku_id');
        }
    });
    return skus;
}

export function handleSortListByKey(data, statusAllow) {
    const newData = data.sort(function compare(a, b) {
        var dateA = new Date(a.scanned_at);
        var dateB = new Date(b.scanned_at);
        return dateB - dateA;
      })
 
    const dataWithStatusAllow = [];
    const dataWithoutStatusAllow = [];
    map(newData, item => {
        if (includes(statusAllow, item.status)) {
            dataWithStatusAllow.push(item);
        } else {
            dataWithoutStatusAllow.push(item);
        }
    });
    return dataWithoutStatusAllow.concat(dataWithStatusAllow);
}

export function convertDataOrderPacking(data, scanPackingOrderIds) {
    const newData = [];
    data.map(item => {
        const order_packing = get(item, 'order_packing', item);
        const order_packing_id = get(order_packing, 'id', '');
        const skus = get(order_packing, 'order.order_skus', []);
        const order_stocks = get(item, 'order_stocks', []);
        const scanned_at = get(find(scanPackingOrderIds, ['id', order_packing_id]), 'scanned_at', Date.now());
        return newData.unshift({
            key: order_packing_id,
            id: order_packing_id,
            order_packing_id,
            order_code: get(order_packing, 'order.code', ''),
            receiver_country_id: get(item, 'order.receiver_country_id', undefined),
            order_id: get(item, 'order.id', ''),
            freight_bill_code: get(order_packing, 'freight_bill', ''),
            status: get(order_packing, 'status'),
            skus: calculatorTotalSku(skus),
            scanned_at: moment(scanned_at).format('YYYY-MM-DD HH:mm:ss'),
            shipping_partner: get(order_packing, 'shipping_partner', {}),
            services: filter(get(item, 'order_packing_services')) || [],
            merchant_id: get(order_packing, "merchant_id", undefined),
            order_stocks
        });
    });
    return orderBy(newData, ['scanned_at'], ['desc']);
}

export function convertDataExportOrder(data, scanExportOrderIds) {
    const newData = [];
    const ids_exist = isEmpty(scanExportOrderIds) ? [] : scanExportOrderIds;
    data.map(item => {
        const order_exporting = get(item, 'order_exporting', item);
        const order_exporting_id = get(order_exporting, 'id');
        const created_at = get(find(ids_exist, ['id', order_exporting_id]), 'scan_created_at', Date.now());
        const skus = get(order_exporting, 'order_exporting_items', []);
        return newData.push({
            key: order_exporting_id,
            id: order_exporting_id,
            order_packing_id: get(order_exporting, 'order_packing_id'),
            order_code: get(order_exporting, 'order.code', ''),
            order_id: get(order_exporting, 'order.id', ''),
            freight_bill_code: get(order_exporting, 'freight_bill', ''),
            status: get(order_exporting, 'status'),
            skus: calculatorTotalSku(skus),
            scan_created_at: created_at,
            shipping_partner: get(order_exporting, 'shipping_partner', {}),
        });
    });
    return newData;
}
