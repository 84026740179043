export const LANGUAGES = [
    {key: "vi", value: "VIETNAM", icon: "vietnam"},
    {key: "zh", value: "CHINA", icon: "china"},
    {key: "en", value: "ENGLISH", icon: "english"},
    {key: "id", value: "INDONESIA", icon: "indonesia"},
    {key: "th", value: "THAILAND", icon: "thai"}
];

export const SHIPPING_PARTNER_INFO = {
    GHN: {color: "#E38225", wildcard: "N"},
    GHTK: {color: "#41BA7C", wildcard: "T"},
    JNTP: {color: "#EE1C25", wildcard: "J"},
    LWE: {color: "#1E7029", wildcard: "T"},
    SHIPPO: {color: "#029240", wildcard: "O"},
    SNAPPY: {color: "#1E2A4F", wildcard: "S"},
    GOSHIP: {color: "#ba1321", wildcard: "G"},
    JNTVN: {color: "#EE1C25", wildcard: "J"},
    SAPI: {color: "#ee80a0", wildcard: "SAP"},
    FLASH: {color: "#BE1507", wildcard: "FL"},
    BESTVN: {color: "#032345", wildcard: "B"},
};

export const ERROR_ORDER_CODE = [
    'SKU_UNMAPPED',
    'MERCHANT_UNMAPPED',
    'TECHNICAL',
];

export const PAGE_SIZE_DESKTOP = 250;
export const PAGE_SIZE_MOBILE = 10;

export const pageSizeOptions = [1, 5, 10, 20, 50, 100, 250, 500];

export const FREIGHT_BILL_STATUS = {
    WAIT_FOR_PICK_UP: 'WAIT_FOR_PICK_UP',// Chờ lấy hàng
    PICKED_UP: 'PICKED_UP', // Đã đi lấy hàng nhưng chưa xác nhận lấy được hàng
    CONFIRMED_PICKED_UP: 'CONFIRMED_PICKED_UP', // Xác nhận đã lấy hàng
    DELIVERING: 'DELIVERING', // Đang giao
    DELIVERED: 'DELIVERED', // Đã giao
    CANCELLED: 'CANCELLED',// Hủy
    FAILED_PICK_UP: 'FAILED_PICK_UP', // Lấy hàng không thành công
    RETURN: 'RETURN', // Sẽ trả hàng hoặc đang trả hàng
    RETURN_COMPLETED: 'RETURN_COMPLETED', //Đã trả hàng
    FAILED_DELIVERY: "FAILED_DELIVERY", // Hủy
};

export const SKU_STATUS = {
    ON_SELL: "ON_SELL",
    STOP_SELLING: "STOP_SELLING"
};
