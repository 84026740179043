import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';
import api from "@Modules/Finance/services/api";

const useFinanceControlDeliveryDetailQuery = documentId => {
    return useQuery(['finance-control-delivery-document-detail', documentId], () => api.getDetailControlDeliveryDocument(documentId), {
        retry: false,
        enabled: !!documentId,
    });
};

export default useFinanceControlDeliveryDetailQuery;
