import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, notification } from 'antd';
import React, { useState } from 'react';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import { convertBlobDataToExcel } from '@System/support/helpers';

import api from '../../services/api';

export default function ExportCosts({ filters }) {
    const [loadingDelivery, setLoadingDelivery] = useState(false);
    const [visible, setVisible] = useState(false);

    const onDownloadExcel = type => {
        setLoadingDelivery(true);
        api.exportCosts({ ...filters, type_export: type })
            .then(response => {
                convertBlobDataToExcel(response.data, 'export-costs');
                notification.success({ message: t('order:export_excel.success') });
            })
            .catch(err => {
                processResponseError(err);
            })
            .finally(() => {
                setLoadingDelivery(false);
                setVisible(false);
            });
    };

    const menu = (
        <Menu className="export_costs-dropdown-action menu-dropdown-action">
            <Menu.Item key="1" className="export_costs-dropdown-action-export__goods mb-3 mb-xl-0 pl-1">
                <Button
                    type="link"
                    className="text-dark"
                    onClick={() => onDownloadExcel('export_cost')}
                    loading={loadingDelivery}
                >
                    {t('order:btn.product_delivery_fee')}
                </Button>
            </Menu.Item>
            <Menu.Item key="2" className="export_costs-dropdown-action-return__goods mb-3 mb-xl-0 pl-1">
                <Button
                    type="link"
                    className="text-dark"
                    onClick={() => onDownloadExcel('export_refund_cost')}
                    loading={loadingDelivery}
                >
                    {t('order:btn.refund_fee')}
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']} visible={visible} onVisibleChange={value => setVisible(value)}>
            <Button>
                {t('btn.export_costs')}
                <DownOutlined />
            </Button>
        </Dropdown>
    );
}
