import SelectMerchantItems from '@Modules/Merchant/components/SelectMerchantItems';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';
import { t } from '@System/i18n';
import { Button, Col, DatePicker, Form, Modal, Row } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import useExportStorageFee from '../Hooks/useExportStorageFee';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const FilterExportStorageFee = ({ filters, setVisible }) => {
    const [form] = Form.useForm()

    const { warehouse_id, merchant_id } = filters || {};

    const [input, setInput] = useState({});

    const { isLoading, refetch: fetchExport } = useExportStorageFee(input);

    const closing_time_from = get(input, 'closing_time[from]', '');
    const closing_time_to = get(input, 'closing_time[to]', '');

    useEffect(() => {
        setInput({ warehouse_id, merchant_id })
    }, [])

    function _onChange(name, value) {
        let params = {};
        const filters = { ...input, ...params, [name]: value };
        setInput(filters);
    }

    function _onSubmit() {
        form.validateFields().then(() => {
            fetchExport()
        })
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;
        const filters = {
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        };
        setInput(filters);
    }

    return (
        <Modal
            title={t("common:btn.export_excel")}
            width={800}
            visible={true}
            onCancel={() => setVisible(false)}
            footer={[
                <Button key="close" onClick={() => setVisible(false)}>
                    {t('btn.close')}
                </Button>,
                <Button key="submit" type="primary" onClick={_onSubmit} loading={isLoading}>
                    {t('btn.ok')}
                </Button>,
            ]}>
            <div className="m-3">
                <Form layout="vertical" form={form}>
                    <Row gutter={10} className="pb-3">
                        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                            <Form.Item label={t('product:label.warehouse_name')}>
                                <SelectWarehouse
                                    noSuggest={false}
                                    className="_inventory-search-warehouse-name"
                                    allowClear={true}
                                    placeholder={t('product:placeholder.warehouse_name')}
                                    value={input?.warehouse_id ? parseInt(input?.warehouse_id) : undefined}
                                    onChange={value => _onChange('warehouse_id', value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                            <Form.Item className="mb-3" label={t('label.seller_info')}>
                                <SelectMerchantItems
                                    className={'_inventory-search-merchant-id'}
                                    placeholder={t('placeholder.select_seller')}
                                    allowClear={true}
                                    value={input?.merchant_id ? parseInt(input?.merchant_id) : undefined}
                                    onChange={value => _onChange('merchant_id', value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 8 }}>
                            <Form.Item className='mb-3' label={t('label.time')} name='closing_time' rules={[{
                                required: true,
                                message: t("validation:required", { attribute: t('label.time') })
                            }]}>
                                <RangePicker
                                    className="_finance-search-created-at"
                                    value={
                                        closing_time_from && closing_time_to
                                            ? [moment(closing_time_from), moment(closing_time_to)]
                                            : undefined
                                    }
                                    format={dateFormat}
                                    placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                    onChange={date => _onChangeDate('closing_time', date)}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}

export default FilterExportStorageFee