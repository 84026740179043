import { Button, Modal, notification, Spin } from 'antd'
import i18next from 'i18next'
import lodash from 'lodash'
import React, { useState } from 'react'

import { events } from '@System/events'
import { t } from '@System/i18n'

import UploadFile from '../../../App/Upload/UploadFile'
import api from '../../services/api'
import { EVENTS } from '../../services/constants'
import { renderError } from '../errors'

export default function UploadChangeFreightBillStatus({ btnType }) {
    const [visible, setVisible] = useState(false)
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(false)
    function showModal(e) {
        e.preventDefault()
        setVisible(true)
    }

    function handleOk() {
        setMessages([])
        setVisible(false)
    }

    function handleCancel() {
        setMessages([])
        setVisible(false)
    }

    function handleUpload(file) {
        setMessages([])
        const filename = lodash.get(file, 'name', '')
        const formData = new FormData()
        formData.append('file', file)
        setLoading(true)
        api.updateChangeFreightBillStatus(formData)
            .then(res => {
                const messages = lodash.uniqWith(lodash.get(res, 'data.errors', []), lodash.isEqual)
                const hasInvalid = lodash.findIndex(messages, ['errors', 'invalid'])
                if (hasInvalid === -1) {
                    setMessages(messages)
                    if (messages.length === 0) {
                        notification.success({ message: t('order:message.upload_file.success', { filename }) })
                        setVisible(false)
                        events.dispatch(EVENTS.IMPORT_ORDER_FROM_EXCEL, {})
                    }
                } else {
                    notification.error({ message: t('order:message.upload_file.fail', { filename }) })
                }
            })
            .catch(err => {
                notification.error({
                    message: t('common:message.server_error'),
                })
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <Button
                type={btnType ? btnType : 'primary'}
                className="mr-2 _prepare-order-btn-update-return-order"
                onClick={showModal}
            >
                {t('order:btn.update_freight_bill_status')}
            </Button>
            <Modal
                title={t('order:label.update_freight_bill_status').toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
            >
                <p>
                    - {t('label.latest_at_update_sample')}: <strong>14/11/2022</strong>
                </p>
                <p>
                    - {t('label.download_sample_file')}:
                    <strong>
                        <a href={process.env.PUBLIC_URL + `/template/${i18next.language}/file_mau_cap_nhat_trang_thai_van_don.xlsx`}>
                            file_mau_cap_nhat_trang_thai_van_don.xlsx
                        </a>
                    </strong>
                </p>
                <p className="mb-3">
                    - {t('label.maximum_file_size')}: <strong>5MB</strong>
                </p>
                <Spin spinning={loading}>
                    <UploadFile
                        className={'_prepare-order-update-return-order-upload-file'}
                        onChange={handleUpload}
                        accept=".xls,.xlsx"
                        fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                        name={t('label.file_upload')}
                        maxFileSize={5}
                        showUploadList={true}
                    />
                    {messages.length > 0 && (
                        <div className="pt-3">
                            {messages.map((item, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="text-danger"
                                    >
                                        - {renderError(item)}
                                    </p>
                                )
                            })}
                        </div>
                    )}
                </Spin>
            </Modal>
        </>
    )
}
