import { Avatar, Badge, Table } from 'antd';
import lodash, { get } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { PRODUCT_STATUS_COLORS } from '@Modules/Product/services/constants';

import { getVar } from '@System/support/helpers';


import CustomizedPagination from '../../../App/CustomizedPagination';
import TableEmpty from '../../../App/Utilities/TableEmpty';

import { url } from '@System/routing';
import { t } from '@System/i18n';
import { dateFormatter } from '@Modules/App/services';

export default function List({ data, pagination, addFilter, filters, loading }) {
    let getColumns = [
        {
            className: '_dropshipping-list-name',
            title: t('product:label.product'),
            dataIndex: ['product', 'name'],
            key: 'name',
            render: (text, record) => {
                const images = get(record, 'product.images');
                const id = get(record, 'product.id');

                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            shape="square"
                            src={images ? images[0] : ''}
                            className="mr-2"
                            size={36}
                            style={{ flexShrink: 0 }}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <strong style={{ fontWeight: '500' }}>
                                    <Link to={url.to('dropshipping.detail', { id })}>{text}</Link>
                                </strong>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            className: '_dropshipping-list-code text-left',
            title: t('product:label.code'),
            dataIndex: ['product', 'code'],
            key: 'code',
            render: (text, record) => {
                return <p>{text}</p>;
            },
        },
        {
            className: '_dropshipping-list-category-seller',
            title: t('common:label.seller_info'),
            dataIndex: 'merchant',
            key: 'merchant',
            render: (text, record) => (text ? `${text.code} - ${text.name} ` : '---'),
        },
        {
            className: '_dropshipping-list-quantity text-right',
            title: t('product:label.varian_quantity'),
            dataIndex: 'sku_total',
            key: 'sku_total',
            render: (text, record) => (text ? text : '0'),
        },

        {
            className: '_dropshipping-list-created-at text-right',
            title: t('product:label.created_at'),
            dataIndex: ['product', 'created_at'],
            key: 'created_at',
            render: (text, record) => {
                return <p>{dateFormatter.full(text, 'DD/MM/YYYY')}</p>;
            },
        },
        {
            title: t('common:status'),
            dataIndex: ['product', 'status'],
            key: 'status',
            className: '_dropshipping-list-status text-left text-nowrap',
            render: (text, record) => {
                return text ? <Badge color={PRODUCT_STATUS_COLORS[text]} text={t(`product:DROPSHIP_STATUS.${text}`)} /> : null;
            },
        },
    ];

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {t('common:heading.list-products')}
                        <span className="number-circle _importing-document-product-list-total">
                            {getVar(pagination, 'total', 0)}
                        </span>
                    </h3>
                </div>

                <div className="data-box--body">
                    <Table
                        className="order-list-table pb-3 _dropshipping-list "
                        dataSource={data}
                        rowKey={record => record.product.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{ emptyText: <TableEmpty className={'_dropshipping-list-no-data'} loading={loading} /> }}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
}
