import { Modal, notification } from 'antd'
import clsx from 'clsx'
import { get, isEmpty, filter, map, find, includes } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Box from '@Modules/App/Box'
import ServiceWarehouses from '@Modules/Warehouse/services/ServiceWarehouses'

import { clearErrors } from '@State/scanErrors/actions'
import { useScanErrorsOfType } from '@State/scanErrors/hooks'

import { t, trans } from '@System/i18n'
import { url } from '@System/routing'

import {
    dateFormatter,
    exportOrderIdsStorage,
    scanBarcodeTypeStorage,
    scanWarehouseStorage,
    setDocumentTitle,
    setNavigator,
    showResetExportOrderStorage,
} from '../../../App/services'
import { updateCollectionItem } from '../../../App/services/helpers'
import apiDocument from '../../../Document/services/api'
import { EXPORT_ORDER_STATUS } from '../../../Order/services/constants'
import ListOrderInfo from '../../components/ListOrderInfo'
import ScanLadingBill from '../../components/ScanLadingBill'
import renderErrorScan from '../../components/customerError'
import { convertDataExportOrder, handleSortListByKey } from '../../components/customerFunction'
import api from '../../services/api'

import styles from './../scan-order.module.scss'

const statusAllow = [EXPORT_ORDER_STATUS.NEW.key]
const typeAction = 'export_order'

function ExportOrder() {
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const barcodeType = scanBarcodeTypeStorage.value
    const [exportOrderIds, setExportOrderIds] = useState(exportOrderIdsStorage.value)
    const showResetExportOrder = showResetExportOrderStorage.value
    const [scanExportOrderListInfo, setScanExportOrderListInfo] = useState([])
    const dispatch = useDispatch()
    const scanErrors = useScanErrorsOfType('exportWarehouse')

    useEffect(() => {
        ServiceWarehouses.list()
            .then(res => {
                setWarehouses(res)
            })
            .catch(() => {
                setWarehouses([])
            })
    }, [])

    useEffect(() => {
        setNavigator(t('title.export_order'), [
            {
                name: t('breadcrumb.export_order'),
            },
        ])
        setDocumentTitle(t('title.export_order'))
    }, [])

    useEffect(() => {
        let ids_exists = isEmpty(exportOrderIds) ? [] : [...exportOrderIds]
        let ids_new = []
        scanExportOrderListInfo.map(item => {
            const id_info = find(ids_exists, ['id', item.id])
            if (isEmpty(id_info)) {
                return ids_new.push({ id: item.id, scan_created_at: item.scan_created_at })
            } else {
                return ids_new.push(id_info)
            }
        })
        setExportOrderIds(ids_new)
        if (ids_new.length > 0) {
            exportOrderIdsStorage.setValue(ids_new)
        } else {
            exportOrderIdsStorage.reset()
        }
    }, [scanExportOrderListInfo])

    useEffect(() => {
        if (!isEmpty(exportOrderIds)) {
            getScanExportOrderListByIds(map(exportOrderIds, 'id'))
        }
    }, [])

    function getScanExportOrderListByIds(ids) {
        const params = { warehouse_id: scanWarehouseStorage.value, ids, sort_by_ids: true, paginate: 0 }
        setLoading(true)
        api.getScanExportOrderList(params)
            .then(res => {
                const data = get(res, 'data.order_exportings', [])
                if (!isEmpty(data)) {
                    updateListOfLading(data)
                }
            })
            .catch(err => {
                renderErrorScan({ err, typeAction, params })
            })
            .finally(() => setLoading(false))
    }

    function updateListOfLading(data) {
        const new_list = [...scanExportOrderListInfo]
        const newData = convertDataExportOrder(data, exportOrderIds)
        if (newData.length > 0) {
            setScanExportOrderListInfo(handleSortListByKey(new_list.concat(newData), statusAllow))
        }
    }

    function handleCancel() {
        showResetExportOrderStorage.setValue(0)
        exportOrderIdsStorage.reset()
        setScanExportOrderListInfo([])
    }

    function removeAllOrderPackingNotNew(data) {
        const new_data = data.filter(item => includes(statusAllow, item.status))
        setScanExportOrderListInfo(new_data)
        setError(undefined)
    }

    function handleCreateDocumentExport() {
        const data = {
            warehouse_id: scanWarehouseStorage.value,
            barcode_type: barcodeType,
            order_exporting_ids: exportOrderIds?.map(item => ({ ...item, scan_created_at: dateFormatter.full(item.scan_created_at) })),
            scan:true
        }

        apiDocument
            .createExportDocument(data)
            .then(res => {
                const dataDocumentExport = get(res, 'data.document_exporting', {})
                showResetExportOrderStorage.setValue(1)
                notification.success({ message: t('order:message.export_order.success') })
                dispatch(clearErrors('exportWarehouse'))
                url.redirectTo('documents.exporting.detail', {
                    id: dataDocumentExport?.id,
                })
            })
            .catch(err => {
                const { response } = err
                showResetExportOrderStorage.setValue(0)
                const errorsDataWarehouse = get(response, 'data.data.warehouse')

                if (!isEmpty(errorsDataWarehouse)) {
                    const listMessage = errorsDataWarehouse
                        .map(order => order + ',')
                        .join('')
                        .slice(0, -1)

                    notification.error({
                        message: t('order:message.warehouse_not_select', {
                            order_code: listMessage,
                        }),
                    })
                } else {
                    notification.error({ message: t('common:message.server_error') })
                }
            })
    }

    function handleSubmit() {
        const doSubmit = () => {
            setError(undefined)
            if (!isEmpty(exportOrderIds)) {
                setLoadingSubmit(true)
                setLoading(true)
                const dataChecking = {
                    warehouse_id: scanWarehouseStorage.value,
                    order_packing_ids: map(scanExportOrderListInfo, 'order_packing_id'),
                }
                apiDocument
                    .checkingExportWarnings(dataChecking)
                    .then(res => {
                        const invalid_order_exportings = get(res, 'data.invalid_order_exportings', [])
                        if (isEmpty(invalid_order_exportings)) {
                            handleCreateDocumentExport()
                        } else {
                            showResetExportOrderStorage.setValue(0)
                            let orderPackingsInfo = [...scanExportOrderListInfo]
                            invalid_order_exportings.map(order_exporting => {
                                return (orderPackingsInfo = updateCollectionItem(orderPackingsInfo, order_exporting.id, {
                                    status: order_exporting.status,
                                }))
                            })
                            const ordersExporting = handleSortListByKey(orderPackingsInfo, statusAllow)
                            setScanExportOrderListInfo(ordersExporting)
                            setError(
                                trans(`order:message.export_order.exists_order_packing_not_new`, {
                                    action: (
                                        <a
                                            className="ml-0"
                                            onClick={() => removeAllOrderPackingNotNew(ordersExporting)}
                                        >
                                            <b>{t('order:title.remove_all_order_packing_not_new')}</b>
                                        </a>
                                    ),
                                })
                            )
                        }
                    })
                    .catch(() => {
                        showResetExportOrderStorage.setValue(0)
                        notification.error({ message: t('common:message.server_error') })
                    })
                    .finally(() => {
                        setLoadingSubmit(false)
                        setLoading(false)
                    })
            }
        }

        if (scanErrors?.length) {
            Modal.confirm({
                title: t('order:message.export_order.scan_errors_confirm'),
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
                onOk: doSubmit,
            })
        } else {
            doSubmit()
        }
    }

    function removeScanExportOrderInfo(ids) {
        setError(undefined)
        const data = filter(scanExportOrderListInfo, item => {
            const id = get(item, 'id')
            return !ids.includes(id)
        })
        setScanExportOrderListInfo(data)
    }

    function getExportOrderScan(params) {
        return api.exportOrderScan(params)
    }

    return (
        <div className={clsx(styles['scan-order'], 'site-content')}>
            <Box className={styles.header}>
                <ScanLadingBill
                    warehouses={warehouses}
                    errorKey="exportWarehouse"
                    typeAction={typeAction}
                    setLoadingData={setLoading}
                    disableBarcode={showResetExportOrder}
                    scanOrderListInfo={scanExportOrderListInfo}
                    getScanInfo={getExportOrderScan}
                    updateListOfLading={updateListOfLading}
                    disableChange={!isEmpty(exportOrderIds) || showResetExportOrder}
                />
            </Box>

            <ListOrderInfo
                error={error}
                loading={loading}
                type={typeAction}
                barcodeType={barcodeType}
                statusAllow={statusAllow}
                loadingSubmit={loadingSubmit}
                dataSource={scanExportOrderListInfo}
                showResetButton={showResetExportOrder}
                handleOk={handleSubmit}
                handleCancel={handleCancel}
                removeScanOrderInfo={removeScanExportOrderInfo}
                errorKey="exportWarehouse"
            />
        </div>
    )
}

export default ExportOrder
