import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Form } from 'antd';
import i18next from 'i18next';
import lodash, { get, isEmpty, keys } from 'lodash';
import React, { useCallback, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import useToggle from '@Modules/App/Hooks/useToggle';
import UploadFile from '@Modules/App/Upload/UploadFileV2';
import useImportCreateFinanceControlMutation from '@Modules/Finance/Hooks/useImportCreateFinanceControlMutation';
import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

function CreateDocumentFinanceControl() {
    const [form] = Form.useForm();
    const [errors, setErrors] = useState([]);
    const [messages, setMessages] = useState([]);
    const [file, setFile] = useState();
    const [visible, { setLeft, setRight }] = useToggle(false);
    const { isLoading, mutateAsync: createFinanceControl } = useImportCreateFinanceControlMutation();

    const handleUpload = useCallback(file => {
        setErrors([]);
        setMessages([]);
        setFile(file);
        form.setFields([{ name: 'file', value: file }]);
    }, []);

    const handleCancel = usePersistFn(() => {
        setLeft();
        setFile();
        setErrors([]);
        setMessages([]);
        form.resetFields();
    });

    const handleOk = usePersistFn(async (isConfirm = 0) => {
        try {
            form.validateFields().then(async values => {
                let formData = { ...values };
                if (isConfirm) formData = { ...values, confirm: isConfirm };
                const { data } = await createFinanceControl(formData);
                const errors = getVar(data, 'errors', []);
                const messagesError = getVar(data, 'message', []);

                if (!isEmpty(messagesError) || !isEmpty(errors)) {
                    setMessages(messagesError);
                    setErrors(errors);
                } else {
                    const documentId = getVar(data, 'document.id', undefined);
                    if (documentId) {
                        notification.success(t('order:confirm_order_info.success'));
                        handleCancel();
                        url.redirectTo('finances.control.detail', {
                            id: documentId,
                        });
                    } else {
                        notification.error(t('finance:message.create_finance_control.document_not_created'));
                    }
                }
            });
        } catch (error) {}
    }, []);

    const ERRORS = ['not_exist', 'already_exist', 'required', 'invalid', 'numeric', 'empty', 'exists'];

    function renderError(error) {
        let line = lodash.get(error, 'line', '');
        const content = lodash.get(error, 'errors', error);
        let ErrorMessages = [];
        if (typeof content === 'object') {
            Object.entries(content).forEach(entry => {
                let [key, errors] = entry;
                ERRORS.forEach(errorKey => {
                    if (key === 'file' && errors === errorKey) {
                        line = 1;
                        ErrorMessages.push(t(`finance:message.create_finance_control.file_empty`));
                    } else {
                        if (errors.hasOwnProperty(errorKey)) {
                            ErrorMessages.push(
                                t(`finance:message.create_finance_control.${errorKey}`, {
                                    attribute: t(`finance:finance_control.label.${key}`),
                                })
                            );
                        }
                    }
                });
            });
        } else {
            if (content === 'INVALID') {
                return t('finance:message.create_finance_control.fail');
            }
        }
        if (ErrorMessages.length > 0)
            return t('finance:message.create_finance_control.line', { line: line, content: ErrorMessages.join(' ') });
        return t('finance:message.create_finance_control.unknown_error');
    }

    const renderMessage = mess => {
        const line = get(mess, 'line');
        const messageError = keys(get(mess, 'message'));

        const keyError = get(messageError, '0');

        return t('finance:message.create_finance_control.line', {
            line: line,
            content: t(`finance:finance_control.message.gt`, {
                attribute: t(`finance:finance_control.label.${keyError}`),
                value: 0,
            }),
        });
    };

    return (
        <>
            <Button type="primary" className="_btn-create-finance-control" onClick={setRight}>
                <PlusOutlined /> {t('common:btn.create_document_control')}
            </Button>

            {visible && (
                <Modal
                    cancelText={t('btn.cancel')}
                    className="_create-finance-control-modal"
                    footer={
                        !isEmpty(errors) || !isEmpty(messages)
                            ? [
                                  <Button
                                      key={'handle_cancel'}
                                      className="_btn-cancel-create-finance-control"
                                      onClick={handleCancel}
                                  >
                                      {t('common:btn.cancel')}
                                  </Button>,

                                  <Button
                                      key={'handle_continue'}
                                      loading={isLoading}
                                      type="primary"
                                      className="ml-2 _btn-confirm-create-finance-control"
                                      onClick={() => handleOk(1)}
                                  >
                                      {t('common:btn.continue')}
                                  </Button>,
                              ]
                            : [
                                  <Button
                                      key={'handle_cancel'}
                                      className="_btn-cancel-create-finance-control"
                                      onClick={handleCancel}
                                  >
                                      {t('common:btn.cancel')}
                                  </Button>,
                                  <Button
                                      key={'handle_ok'}
                                      loading={isLoading}
                                      type="primary"
                                      className="ml-2 _btn-confirm-create-finance-control"
                                      onClick={() => handleOk(0)}
                                  >
                                      {t('common:btn.ok')}
                                  </Button>,
                              ]
                    }
                    okText={t('btn.ok')}
                    title={t('finance:finance_control.title.create_finance_control')}
                    visible={visible}
                    onCancel={handleCancel}
                    width={650}
                >
                    <Form form={form}>
                        <p>
                            - {t('label.latest_at_update_sample')}: <strong>21/05/2021</strong>
                        </p>
                        <p>
                            - {t('label.download_sample_file')}:
                            <strong>
                                <a
                                    href={
                                        process.env.PUBLIC_URL + `/template/${i18next.language}/file_mau_doi_soat_cod.xlsx`
                                    }
                                >
                                    file_mau_doi_soat_cod.xlsx
                                </a>
                            </strong>
                        </p>
                        <p className="mb-3">
                            - {t('label.maximum_file_size')}: <strong>5MB</strong>
                        </p>
                        <Form.Item
                            className="mb-3"
                            label={t('order:label.order_shipping_partner')}
                            name="shipping_partner_id"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('order:label.order_shipping_partner'),
                                    }),
                                },
                            ]}
                        >
                            <SelectShippingPartner
                                className={'_prepare-order-search-shipping-partner-id'}
                                placeholder={t('order:placeholder.shipping_partner_id')}
                                allowClear={true}
                                onChange={value =>
                                    form.setFields([{ name: 'shipping_partner_id', value, errors: null }])
                                }
                                locationByUser
                            />
                        </Form.Item>
                        <Form.Item
                            className="mb-3"
                            name="file"
                            required
                            rules={[{ required: true, message: t('finance:message.required_file') }]}
                        >
                            <UploadFile
                                accept=".xls,.xlsx"
                                fileType={[
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                    'application/vnd.ms-excel',
                                ]}
                                maxFileSize={5}
                                showUploadList
                                onChange={handleUpload}
                            />
                        </Form.Item>
                    </Form>
                    {!isEmpty(errors) && (
                        <div className="pt-3">
                            {errors.map((error, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {renderError(error)}
                                    </p>
                                );
                            })}
                        </div>
                    )}

                    {!isEmpty(messages) && (
                        <div className="pt-3">
                            {messages.map((mess, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {renderMessage(mess)}
                                    </p>
                                );
                            })}
                        </div>
                    )}

                    {(!isEmpty(errors) || !isEmpty(messages)) && (
                        <p key={'confirm_create_finance_control'} className="mt-3">
                            {t('finance:finance_control.title.confirm_create_finance_control')}
                        </p>
                    )}
                </Modal>
            )}
        </>
    );
}
export default CreateDocumentFinanceControl;
