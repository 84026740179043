import React, {useEffect, useState} from "react";
import {Select} from "antd";
import ServiceMarkets from "../services/ServiceMarkets";

const {Option} = Select;

export default function SelectMarket(props) {
    let [markets, setMarkets] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceMarkets.list()
            .then(res => {
                setMarkets(res);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    return (
        <Select
            loading={isLoading}
            {...props}
            value={(markets.length > 0 && props.value) ? props.value : undefined}
            showSearch
           
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                markets.length > 0 &&
                markets.map(opt => {
                    return <Option key={opt.id} value={opt.id}>{opt.label}</Option>;
                })
            }
        </Select>
    );
}
