import React, {useEffect, useState} from "react";
import {Table, Divider, Button, Select, InputNumber} from "antd";
import {t} from "../../../../system/i18n";
import _ from "lodash";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";

const {Option} = Select;

export default function SkuList({exportedSkus, addSku, removeSku, setSku, stocks}) {
    const [skus, setSkus] = useState([]);

    // Phân loại các stocks thành mảng skuStocks theo từng sku
    useEffect(() => {
        let uniqSkus = _.uniqBy(stocks.map(item => item.sku), 'id');
        let skuStocks = uniqSkus.map(item => {
            let skuItem = {...item, skuStocks: []};
            stocks.forEach(stock => {
                let {sku} = stock;
                if (sku.id === item.id)
                    skuItem.skuStocks.push(stock);
            });
            return skuItem;
        });
        setSkus(skuStocks);
    }, [stocks]);

    // Chọn sku thì reset lại vị trí kho và số lượng
    function _onChangeSku(index, value) {
         setSku(index, {sku_id: value, warehouse_area_id: undefined, quantity: undefined});
    }

    // Chọn sku thì reset lại số lượng
    function _onChangeWarehouseArea(index, value) {
        let exportedSku = exportedSkus.find((item, n) => index === n);
        if (exportedSku) {
            let stock = stocks.find(item => {
                let {sku, warehouseArea} = item;
                return sku.id === parseInt(exportedSku.sku_id) && parseInt(value) === warehouseArea.id;
            });
            setSku(index, {warehouse_area_id: value, stock: _.get(stock, "stock.quantity", 0), quantity: undefined});
        }
    }

    function _onChangeQuantity(index, value) {
        setSku(index, {quantity: value});
    }

    let getColumns = [
        {
            title: t("manageExporting:label.code"),
            dataIndex: "sku_id",
            key: 'sku_id',
            width: "30%",
            render: (text, record, index) => {
                return (
                    <Select
                        style={{width: "100%"}}
                        placeholder={t("manageExporting:placeholder.sku")}
                        value={record.sku_id}
                        onSelect={value => _onChangeSku(index, value)}
                        showSearch
                        optionFilterProp="label"
                        dropdownMatchSelectWidth={400}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {
                            skus.map(sku => <Option key={sku.id} value={sku.id}>{sku.code + " - " + sku.name}</Option>)
                        }
                    </Select>
                );
            },
        },
        {
            title: t("manageExporting:label.warehouse_area_id"),
            dataIndex: 'warehouse_area_id',
            key: 'warehouse_area_id',
            width: "25%",
            render: (text, record, index) => {
                let skuBill = skus.find(sku => record.sku_id === sku.id);
                let skuStocks = _.get(skuBill, "skuStocks", []);

                return (
                    <Select
                        style={{width: "100%"}}
                        placeholder={t("manageExporting:placeholder.sku")}
                        value={record.warehouse_area_id}
                        onSelect={value => _onChangeWarehouseArea(index, value)}
                    >
                        {
                            skuStocks.map(skuStock => {
                                let {warehouseArea, merchant} = skuStock;
                                return <Option key={warehouseArea.id} value={warehouseArea.id}>{(warehouseArea.name === "default" ? t("label.default") : warehouseArea.name) + ' - ' + merchant.name}</Option>;
                            })
                        }
                    </Select>
                );
            },
        },
        {
            title: t("manageExporting:label.stock"),
            dataIndex: 'stock',
            key: 'stock',
            className: 'text-center',
            width: "20%",
        },
        {
            title: t("manageExporting:label.exported_quantity"),
            dataIndex: 'sku.exported_quantity',
            key: 'sku.exported_quantity',
            className: 'text-center',
            width: "20%",
            render: (text, record, index) => {
                return (
                    <InputNumber
                        min={1}
                        onChange={value => _onChangeQuantity(index, value)}
                        value={record.quantity}
                        className="text-center"
                        placeholder={t("manageExporting:placeholder.quantity")}
                    />
                );
            },
        },
        {
            title: <Button onClick={addSku}><PlusOutlined />{t("btn.plus")}</Button>,
            dataIndex: "sku.action",
            key: 'sku.action',
            width: "5%",
            className: 'text-center',
            render: (text, record, index) => {
                return exportedSkus.length > 1 ? <DeleteOutlined className="text-red" onClick={() => removeSku(index)} /> : null;
            },
        }
    ];

    return (
        <div>
            <Divider />
            <Table
                className="list-products-table pb-3"
                dataSource={exportedSkus}
                rowKey={"id"}
                columns={getColumns}
                pagination={false}
                bordered={true}
            />
        </div>
    );
}
