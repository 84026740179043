import { Col, Form, Input, Row, Typography, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';

import { LENGTH_PRODUCT_CODE_WARNING, PATTERN_CHECK_HAS_SPECIAL_CHARACTER } from '@Modules/Product/services/constants';
import SelectCategories from '@Modules/ProductCategories/components/SelectCategories';
import SelectUnits from '@Modules/ProductUnits/components/SelectUnits';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import SelectSuppliers from '@Modules/Product/components/SelectSupplier';

const { TextArea } = Input;
const { Title } = Typography;

const VALIDATE_CREATE_PRODUCT = {
    code: [{ required: true }],
    name: [{ required: true }],
    category_id: [],
    unit_id: [],
    dropship: [],
    supplier:[]
};

export default function ProductForm({ product, form }) {
    const [warningProductCode, setWarningProductCode] = useState(false);
    const [warningLengthProductCode, setWarningLengthProductCode] = useState(false);
    useEffect(() => {
        form.setFields([
            {
                name: 'dropship',
                value: !!product.dropship,
            },
        ]);
    }, [product]);

    function _onChange(e) {
        form.setFields([
            {
                name: 'dropship',
                value: e.target.checked ? 1 : 0,
            },
        ]);
    }

    function onChangeInput(e) {
        const { name, value } = e.target;
        if (name === 'code') {
            const isValid = PATTERN_CHECK_HAS_SPECIAL_CHARACTER.test(value);
            setWarningProductCode(!isValid);
            setWarningLengthProductCode(value.length > LENGTH_PRODUCT_CODE_WARNING);
        }
        form.setFields([{ name, value, errors: null }]);
    }

    return (
        <div className="_product-detail-common-info">
            <Title className="mb-4" level={5}>
                {t('product_detail')} 
            </Title>

           <>
           <Row gutter={20}>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                    <Form.Item label={t('product:label.code')}>
                        <b>{getVar(product, 'code', undefined)}</b>
                    </Form.Item>
                    {warningProductCode && (
                        <p className={`text-warning ${warningLengthProductCode ? 'mb-1' : 'mb-3'}`}>
                            {t('product:message.has_special_character', { attribute: t('product:label.code') })}
                        </p>
                    )}
                    {warningLengthProductCode && (
                        <p className="text-warning mb-3">
                            {t('product:message.too_long', { attribute: t('product:label.code') })}
                        </p>
                    )}
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                    <Form.Item label={t('product:label.name')} name="name" rules={VALIDATE_CREATE_PRODUCT.name}>
                        <Input className="_product-detail-code-name" placeholder={t('product:placeholder.name')} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                    <Form.Item
                        label={t('product:label.category_id')}
                        name="category_id"
                        rules={VALIDATE_CREATE_PRODUCT.category_id}
                    >
                        <SelectCategories
                            className="_product-detail-category-id"
                            placeholder={t('product:placeholder.category_id')}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                    <Form.Item label={t('product:label.unit_id')} name="unit_id" rules={VALIDATE_CREATE_PRODUCT.unit_id}>
                        <SelectUnits className="_product-detail-unit-id" placeholder={t('product:placeholder.unit_id')} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 4 }}>
                    <Form.Item label={t('product:label.supplier')} name="supplier_id" rules={VALIDATE_CREATE_PRODUCT.supplier}>
                        <SelectSuppliers className="_product-detail-supplier-id" placeholder={t('product:placeholder.supplier')} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 2 }}>
                    <Form.Item label=" " name="dropship" valuePropName="checked">
                        <Checkbox className="_product-detail-dropship" onChange={_onChange}>
                            {t('product:label.dropship')}
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={20}>
                <Col xs={{ span: 24 }}>
                    <Form.Item
                        label={t('product:label.description')}
                        name="description"
                        rules={VALIDATE_CREATE_PRODUCT.description}
                    >
                        <TextArea
                            className="_product-detail-description"
                            placeholder={t('product:placeholder.description')}
                            style={{ height: '100px' }}
                        />
                    </Form.Item>
                </Col>
            </Row>
           </>
        </div>
    );
}
