import { Alert } from 'antd';
import React from 'react';

const ErrorTittle = ({ title }) => <p className="text-fz-14 text-red">• {title}</p>;
const ErrorMessage = ({ message }) => <p className="text-fz-13">{message}</p>;

export default function CustomizedAlert({ title, errors, onClose }) {
    if (!errors || errors.length === 0) return null;

    return (
        <Alert
            message={<ErrorTittle title={title} />}
            description={
                <>
                    {errors.map((item, index) => (
                        <ErrorMessage key={index} message={item} />
                    ))}
                </>
            }
            type="error"
            className="mt-3"
            closable
            onClose={onClose}
        />
    );
}
