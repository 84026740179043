import { ScanOutlined } from '@ant-design/icons';
import { Tooltip, Modal, notification } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import BarcodeScannerComponent from 'react-qr-barcode-scanner';

import { t } from '../../../system/i18n';

function ScanBarcode(props) {
    const { onUpdate, disabled } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        if (!disabled) setIsModalVisible(true);
    };

    const handleUpdate = (_, result) => {
        const barcode = get(result, 'text');
        if (barcode) {
            setIsModalVisible(false);
            onUpdate(barcode);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        onUpdate('abcdef');
    };

    const handleError = error => {
        if (error.name === 'NotAllowedError') {
            notification.error({ message: t('order:message.scan_order.cannot_scan') });
        }

        setIsModalVisible(false);
    };

    return (
        <>
            <Tooltip title={t('order:title.scan_barcode')}>
                <ScanOutlined onClick={showModal} style={{ color: 'rgba(0,0,0,.45)' }} className="_scan-order-scan-barcode" />
            </Tooltip>
            {isModalVisible && (
                <Modal
                    title={t('order:title.scan_barcode')}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={false}
                    width={650}
                >
                    <BarcodeScannerComponent
                        delay={300}
                        onError={handleError}
                        onUpdate={handleUpdate}
                        style={{ width: '100%', height: '100%' }}
                        stopStream={!isModalVisible}
                    />
                </Modal>
            )}
        </>
    );
}

export default ScanBarcode;
