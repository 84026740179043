import { useQuery } from 'react-query';

import api from '@Modules/Finance/services/api';

import processResponseError from '@System/api/processResponseError';

const useDebtsShippingStatsQuery = params => {
    return useQuery(
        ['finance-debit-shipping-stats', params],
        () => {
            if (params?.shipping_partner_id) {
                return api.getFinanceDebitShippingStats(params);
            }
        },
        {
            retry: false,
            onError: err => {
                processResponseError(err);
            },
        }
    );
};

export default useDebtsShippingStatsQuery;
