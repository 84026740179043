import { CloudUploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';

import { t } from '@System/i18n';

import ImportRefSkusModal from './ImportRefSkusModal';

const ImportRefSkus = ({ className, filters, isSelectAllPage, selectedRowKeys, onSuccessImport }) => {
    const [visible, setVisible] = useState(false);

    const handleOk = usePersistFn(data => {
        setVisible(false);
        onSuccessImport(data);
    });

    return (
        <>
            <Button className={className} type="primary" onClick={setVisible.bind(this, true)}>
                <CloudUploadOutlined />
                {t('product:update_product_type_code')}
            </Button>

            <ImportRefSkusModal
                filters={filters}
                isSelectAllPage={isSelectAllPage}
                selectedRowKeys={selectedRowKeys}
                visible={visible}
                onCancel={setVisible.bind(this, false)}
                onOk={handleOk}
            />
        </>
    );
};

export default ImportRefSkus;
