import React, {useEffect, useState} from "react";
import {setDocumentTitle, setNavigator} from "../../../App/services";
import {t} from "../../../../system/i18n";
import {Row, Col, Button, Form} from "antd";
import OrderInfo from "./components/OrderInfo";
import ReceivedInfo from "./components/ReceivedInfo";
import ProductInfo from "./components/ProductInfo";
import PaymentInfo from "./components/PaymentInfo";
import {INIT_ORDER, VALIDATED_RULES} from "../../services/constants";
import {PlusOutlined} from "@ant-design/icons";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import api from "../../services/api";
import {url} from "../../../../system/routing";
import _ from "lodash";
import ServiceMerchant from "../../../Merchant/services/ServiceMerchant";
import {catchErrors} from "../../../App/services/helpers";

export default function CreateOrder() {
    const [form] = Form.useForm();
    const validateMessages = useValidatedMessages();
    const [loading, setLoading] = useState(false);
    const [extraServices, setExtraServices] = useState([]);
    const [paidTotal, setPaidTotal] = useState(0);
    const [sellers, setSellers] = useState([]);

    // Danh sách các sku (sản phẩm) được thêm vào đơn hàng, giá theo seller hiện tại
    const [orderSkus, setOrderSkus] = useState([]);

    // Seller hiện tại để hiển thị tỉnh/thành, định dạng tiền tệ của đơn theo thị trường seller bán hàng
    const [currentSeller, setCurrentSeller] = useState(null);

    useEffect(() => {
        setNavigator(t("title.create-order"), [
            { name: t("breadcrumb.orders"), route: "orders.list" },
            { name: t("breadcrumb.create-order") }
        ]);
        setDocumentTitle(t("title.create-order"));
        fetchMerchants();
    }, []);

    function fetchMerchants() {
        ServiceMerchant.list()
            .then(res => setSellers(res));
    }

    function _onSubmit() {
        form.validateFields()
            .then(values => {
                setLoading(true);
                let data = {...values};

                if (extraServices.length > 0)
                    data = {...data, extra_services: extraServices};

                api.createOrder({...data, orderSkus: orderSkus.map(item => item.tax === null ? _.omit(item, ["tax"]) : item)})
                    .then(res => {
                        let order = _.get(res, "data.order", {});
                        url.redirectTo("orders.list");
                    })
                    .catch(catchErrors(t('common:message.create_failed', {attribute: t("common:label.order_detail")}), showErrors))
                    .finally(() => setLoading(false));
            });
    }

    // Hiển thị lỗi với từng field tương ứng
    function showErrors(errors) {
        let error_messages = [];

        Object.keys(errors).forEach(item => {
            let error = errors[item];

            if (item != "sku_errors") {
                for (let prop in error) {
                    error_messages.push({name: prop, errors: [t(`order:message.${error[prop]}`, {attribute: t(`order:label.${prop}`)})]});
                }
            }

            // Hiển thị từng dòng sản phẩm nếu bị lỗi
            if (item === "sku_errors") {
                let error_lines = [];
                for (let prop in error) {
                    let line = prop.replace( /^\D+/g, '');
                    let errObj = error[prop];
                    for (let opt in errObj) {
                        errObj[opt].forEach(key => {
                            error_lines.push(t("order:message.line", {line, content: t(`order:message.${opt}`, {attribute: t(`order:label.${key}`)})}));
                        })
                    }
                }
                error_messages.push({name: "product_quantity", errors: error_lines});
            }
        });

        form.setFields(error_messages);
    }

    return (
        <div className="site-content bg-gray create-order">
            <Form
                initialValues={INIT_ORDER}
                className={"form"}
                validateMessages={validateMessages}
                size="default"
                form={form}
                layout="vertical"
            >
                <div className="title-page pb-1 d-flex justify-content-between align-items-center">
                    <h2 className="text-uppercase">{t("common:heading.create-order")}</h2>
                    <div className="text-right">
                        <Button
                            type="primary"
                            size="large"
                            onClick={_onSubmit}
                            className="create-order-btn"
                            disabled={loading}
                            loading={loading}
                        ><PlusOutlined/> {t("order:btn.create")}</Button>
                    </div>
                </div>

                <Row gutter={20} className="mt-3 mb-3">
                    <Col xs={{span: 17}}>
                        <OrderInfo
                            validatedRules={VALIDATED_RULES}
                            setCurrentSeller={setCurrentSeller}
                            currentSeller={currentSeller}
                            sellers={sellers}
                            setOrderSkus={setOrderSkus}
                            form={form}
                        />

                        <ProductInfo
                            orderSkus={orderSkus}
                            setOrderSkus={setOrderSkus}
                            currentSeller={currentSeller}
                            extraServices={extraServices}
                            setExtraServices={setExtraServices}
                            setPaidTotal={setPaidTotal}
                            paidTotal={paidTotal}
                            validatedRules={VALIDATED_RULES}
                            form={form}
                        />
                    </Col>

                    <Col xs={{span: 7}}>
                        <ReceivedInfo
                            validatedRules={VALIDATED_RULES}
                            currentSeller={currentSeller}
                            form={form}
                        />

                        <PaymentInfo
                            validatedRules={VALIDATED_RULES}
                            paidTotal={paidTotal}
                            form={form}
                        />
                    </Col>
                </Row>

                <div className="text-right pt-3">
                    <Button
                        type="primary"
                        size="large"
                        onClick={_onSubmit}
                        className="create-order-btn"
                        disabled={loading}
                        loading={loading}
                    ><PlusOutlined/> {t("order:btn.create")}</Button>
                </div>
            </Form>
        </div>
    );
}
