import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import useFilter from '@Modules/App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import PERMISSIONS from '@Modules/Auth/services/permissions';
import useFinanceControlDeliveryQuery from '@Modules/Finance/Hooks/useFinanceControlDeliveryQuery';
import CreateFinanceControlDelivery from '@Modules/Finance/screens/ControlDelivery/Create';
import List from '@Modules/Finance/screens/ControlDelivery/List/List';
import FormSearch from '@Modules/Finance/screens/ControlDelivery/List/FormSearch';

import { t } from '@System/i18n';

interface IForwardInfoProps {
    history: any;
    location: any;
}

const ControlDelivery: React.FC<IForwardInfoProps> = ({ history, location }) => {
    const { filters, addFilter } = useFilter(history, location);
    const [pagination, setPagination] = useState({});
    const [documents, setDocuments] = useState([]);
    const { data, isLoading, isError, refetch, error } = useFinanceControlDeliveryQuery(filters);

    useEffect(() => {
        setNavigator(t('menu.finances'), [
            {
                name: t('menu.finance_control_delivery'),
            },
        ]);
        setDocumentTitle(t('menu.finance_control_delivery'));
    }, []);

    useEffect(() => {
        refetch();
    }, [refetch, filters]);

    useEffect(() => {
        setDocuments(get(data, 'data.documents', []));
        setPagination(get(data, 'data.pagination', {}));
    }, [data]);

    if (isError) {
        const status = get(error, 'response.status');
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }
    return (
        <div className="site-content">
            <div className="d-flex justify-content-end">
                {/* <h3 className="text-fz-18">{t('menu.finance_control_delivery')}</h3> */}
                {auth.can(PERMISSIONS.FINANCE_CREATE_DELIVERY_STATEMENT) && <CreateFinanceControlDelivery />}
            </div>
            <FormSearch addFilter={addFilter} filters={filters} />
            <List pagination={pagination} addFilter={addFilter} filters={filters} data={documents} loading={isLoading} />
        </div>
    );
};

export default ControlDelivery;
