import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Input, Select } from 'antd';
import { map } from 'lodash';
import React, { useState, useEffect } from 'react';

import useSize from '@Modules/App/Hooks/useSize';
import { ERROR_ORDER_CODE } from '@Modules/App/services/constants';

import { t } from '@System/i18n';

const { Option } = Select;

function FormSearch({ filters, onSearch }) {
    const { isMobile, isTablet } = useSize();
    const [input, setInput] = useState(filters);
    const { code, error_code } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({ ...input, [name]: value });
    }

    function _onSubmit() {
        onSearch(input);
    }

    function _onReset() {
        onSearch({});
    }

    return (
        <div className="bg-white rounded-12 p-4 mt-3">
            <div className="search-box">
                <div className="search-box--title d-lg-flex justify-content-between pb-4">
                    <h3 className="text-fz-18">{t('common:heading.list-orders')}</h3>
                </div>
                <Form layout="vertical">
                    <Row gutter={20}>
                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                            <Form.Item className="mb-3" label={t('order:label.order_code')}>
                                <Input
                                    className="_search-order-code"
                                    name="code"
                                    value={code}
                                    placeholder={t('order:placeholder.order_code')}
                                    onChange={e => _onChange('code', e.target.value)}
                                    onPressEnter={_onSubmit}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                            <Form.Item className="mb-3" label={t('order:label.reason_failed')}>
                                <Select
                                    allowClear={true}
                                    name="error_code"
                                    value={error_code}
                                    onChange={value => _onChange('error_code', value)}
                                    onPressEnter={_onSubmit}
                                    placeholder={t('order:placeholder.reason_failed')}
                                >
                                    {map(ERROR_ORDER_CODE, (item, index) => {
                                        return (
                                            <Option key={index} value={item}>
                                                {t(`order:ERROR_ORDER_CODE.${item}`)}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 8 }} className="text-right">
                            <Form.Item className="mb-0" label={isMobile || isTablet ? '' : ' '}>
                                <Button className="mr-2 _search-btn-reset" onClick={_onReset}>
                                    <UndoOutlined /> {t('common:btn.reset')}
                                </Button>
                                <Button type="primary" onClick={_onSubmit} className="_search-btn-search">
                                    <SearchOutlined /> {t('common:btn.search')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default FormSearch;
