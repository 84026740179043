import { useQuery } from 'react-query';

import api from '@Modules/Finance/services/api';

import processResponseError from '@System/api/processResponseError';

const useFinanceOrderStatisticQuery = params => {
    return useQuery(['finance-order-statistic-query', params], () => api.getOrderStatistic(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useFinanceOrderStatisticQuery;
