import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useUpdateMarketsMutation = createUseMutation({
    namespace: 'user:update_market',
    mutation: data => apiService.updateMarkets(data),
});

export default useUpdateMarketsMutation;
