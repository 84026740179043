import { Card, Modal, Spin } from 'antd';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import trim from 'lodash/trim';
import React, { useCallback, useEffect, useState } from 'react';
import useSound from 'use-sound';

import Page404 from '@Modules/App/Error/404';
import Loading from '@Modules/App/Loading';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useCreateExportingInventoryDocumentMutation from '@Modules/Document/Hooks/useCreateExportingInventoryDocumentMutation';
import useExportingDocumentQuery from '@Modules/Document/Hooks/useExportingDocumentQuery';
import { SCAN_TYPE } from '@Modules/Document/constants';

import { t, trans } from '@System/i18n';

import { url } from '../../../../../system/routing';
import errorSound from './../../../../../resources/sounds/error.mp3';
import Body from './Body';
import Header from './Header';

const ForControl = ({ match }) => {
    const [playErrorSound] = useSound(errorSound);
    const [notInDocumentList, setNotInDocumentList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [uncheckedList, setUncheckedList] = useState([]);
    const [exportingDocument, setExportingDocument] = useState();
    const [orderExportings, setOrderExportings] = useState([]);
    const [orderExportingBarcodes, setOrderExportingBarcodes] = useState([]);
    const { isError, isLoading, data: exportingDocumentData } = useExportingDocumentQuery(match.params.id);
    const { mutateAsync: createExportingInventoryDocument, isLoading: isCreateExportingInventory } =
        useCreateExportingInventoryDocumentMutation();

    const checkBarcode = useCallback(
        (barcode, checkedList, uncheckedList, notInDocumentList, notifyError = false) => {
            barcode = trim(barcode);
            const notInDocumentListIndex = notInDocumentList.findIndex(item => item === barcode);
            const checkedListIndex = checkedList.findIndex(item => item === barcode);
            const uncheckedListIndex = uncheckedList.findIndex(item => item === barcode);
            let newCheckedList = checkedList;
            let newUncheckedList = uncheckedList;
            let newNotInDocumentList = notInDocumentList;

            if (isEmpty(barcode)) {
                return [newCheckedList, newUncheckedList, newNotInDocumentList];
            }

            if (notInDocumentListIndex === -1 && checkedListIndex === -1 && uncheckedListIndex === -1) {
                newNotInDocumentList = [barcode, ...notInDocumentList];

                if (notifyError) {
                    playErrorSound();
                    // notification.error(trans('document:this_barcode_not_exist_in_document'));
                }
            } else if (uncheckedListIndex !== -1) {
                newCheckedList = [...checkedList, barcode];
                newUncheckedList = [
                    ...uncheckedList.slice(0, uncheckedListIndex),
                    ...uncheckedList.slice(uncheckedListIndex + 1),
                ];
                newNotInDocumentList = notInDocumentList;
            } else if (notifyError && (notInDocumentListIndex !== -1 || checkedListIndex !== -1)) {
                playErrorSound();
                // notification.error(trans('this_code_has_been_scanned_before'));
            }

            return [newCheckedList, newUncheckedList, newNotInDocumentList];
        },
        [playErrorSound]
    );

    const handleCheckBarcodes = useCallback(
        barcodes => {
            let notifyError = false;

            if (!isArray(barcodes)) {
                barcodes = [barcodes];
                notifyError = true;
            }

            let newCheckedList = checkedList;
            let newUnCheckedList = uncheckedList;
            let newNotInDocumentList = notInDocumentList;

            for (const barcode of barcodes) {
                [newCheckedList, newUnCheckedList, newNotInDocumentList] = checkBarcode(
                    barcode,
                    newCheckedList,
                    newUnCheckedList,
                    newNotInDocumentList,
                    notifyError
                );
            }

            setCheckedList(newCheckedList);
            setUncheckedList(newUnCheckedList);
            setNotInDocumentList(newNotInDocumentList);
        },
        [checkBarcode, checkedList, uncheckedList, notInDocumentList]
    );

    const handleConfirm = useCallback(() => {
        Modal.confirm({
            width: 600,
            cancelText: trans('btn.cancel'),
            centered: true,
            maskClosable: true,
            okText: trans('btn.ok'),
            title: (
                <>
                    <p>
                        {trans('document:create_exporting_inventory_document.confirm_info', {
                            checked: checkedList?.length || 0,
                            redundant: notInDocumentList?.length || 0,
                            total: orderExportingBarcodes?.length || 0,
                            type: (exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER
                                ? trans('order_code')
                                : trans('freight_bill_code')
                            ).toLowerCase(),
                        })}
                    </p>
                    <br />
                    <p>
                        {trans('document:create_exporting_inventory_document.confirm', {
                            type: (exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER
                                ? trans('order_code')
                                : trans('freight_bill_code')
                            ).toLowerCase(),
                        })}
                    </p>
                </>
            ),
            onOk: async () => {
                await createExportingInventoryDocument({
                    document_id: exportingDocument?.document_exporting?.id,
                    barcodes: [...notInDocumentList, ...checkedList],
                    uncheck_barcodes: uncheckedList,
                });

                handleChangeExportingDocumentData(exportingDocumentData);
                url.redirectTo('documents.exporting.detail', {
                    id: exportingDocument?.document_exporting?.id,
                });
            },
        });
    }, [
        exportingDocument,
        exportingDocumentData,
        checkedList,
        notInDocumentList,
        uncheckedList,
        orderExportingBarcodes,
        createExportingInventoryDocument,
    ]);

    const handleRedo = useCallback(() => {
        handleChangeExportingDocumentData(exportingDocumentData);
    }, [exportingDocumentData]);

    const handleChangeExportingDocumentData = exportingDocumentData => {
        const key =
            exportingDocumentData?.data?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER
                ? 'order_code'
                : 'freight_bill';
        const barcodes = map(exportingDocumentData?.data?.order_exportings, key);
        setExportingDocument(exportingDocumentData?.data);
        setOrderExportings(exportingDocumentData?.data?.order_exportings);
        setOrderExportingBarcodes(barcodes);
        setCheckedList([]);
        setUncheckedList(barcodes);
        setNotInDocumentList([]);
    };

    useEffect(() => {
        handleChangeExportingDocumentData(exportingDocumentData);
    }, [exportingDocumentData]);

    useEffect(() => {
        setNavigator(trans('document:types.exporting'), [
            {
                name: trans('document:list.exporting'),
                route: 'documents.exporting.list',
            },
            {
                name: trans('document:for_control_exporting_document'),
            },
        ]);
        setDocumentTitle(t('document:for_control_exporting_document'));
    }, []);

    if (isError) {
        return <Page404 />;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Card
            className="flex-grow-1 bg-white m-4"
            title={
                <Header
                    exportingDocument={exportingDocument}
                    onConfirm={handleConfirm}
                    onRedo={handleRedo}
                    isConfirming={isCreateExportingInventory}
                />
            }
        >
            <Body
                checkedList={checkedList}
                exportingDocument={exportingDocument}
                notInDocumentList={notInDocumentList}
                orderExportings={orderExportings}
                orderExportingBarcodes={orderExportingBarcodes}
                uncheckedList={uncheckedList}
                onCheckBarcodes={handleCheckBarcodes}
            />
        </Card>
    );
};

export default ForControl;
