import { notification } from 'antd';
import axios from 'axios';
import { get } from 'lodash';
import { useQuery } from 'react-query';

import { t } from '../../../system/i18n';
import api from '../services/api';

const useListVariantsQuery = params => {
    return useQuery(['ListVariants', params], () => api.getSkus(params), {
        retry: false,
        onError: err => {
            if (!axios.isCancel(err)) {
                const status = get(err, 'response.status', null);
                if (status === 403) notification.error({ message: t('common:message.403') });
                else if (status === 404) notification.error({ message: t('common:message.404') });
                else notification.error({ message: t('common:message.fetch_fail') });
            }
        },
    });
};

export default useListVariantsQuery;
