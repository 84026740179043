import React, {useEffect, useState} from "react";
import TableEmpty from "../../../../App/Utilities/TableEmpty";
import {Avatar, Button, notification, Table} from "antd";
import {SaveOutlined} from "@ant-design/icons";
import {t} from "../../../../../system/i18n";
import {catchErrors, updateCollectionItem} from "../../../../App/services/helpers";
import StackRow from "./StackRow";
import api from "../../../services/api";
import _, { sumBy } from "lodash";
import Inspection from "@Modules/Order/screens/OrderDetail/InspectionProducts/Inspection";
import {getVar} from "@System/support/helpers";

export default function CheckProducts({orderDetail, setOrderDetail, skus, setSkus, fetchOrder}) {
    const {order, orderStocks} = orderDetail;
    const [canInspect, setCanInspect] = useState(false);
    const [lineErrors, setLineErrors] = useState([]);
    const inspectedSkus = _.map(orderStocks, "orderStock.sku_id");

    const columns = [
        {
            title: t("product:label.sku_code"),
            dataIndex: 'code',
            key: 'code',
            className: "text-nowrap _sku-code-column",
            render: (text, record) => {
                let {sku, product} = record;
                const image = getVar(product, "images.0", "");
                return (
                    <div className="d-flex align-items-center">
                        <Avatar src={image} shape="square" className="mr-2" size={36} />
                        <div>
                            <span className="text-primary">{sku.code}</span>
                            <p>{sku.name}</p>
                        </div>
                    </div>
                );
            }
        },
        {
            title: t("product:label.quantity"),
            dataIndex: 'quantity',
            key: 'quantity',
            className: "text-center text-nowrap _quantity-column",
            render: (text) => {
                return <strong>{text}</strong>;
            }
        },
        {
            title: t("order:label.unit"),
            dataIndex: 'unit',
            key: 'unit',
            className: "text-center text-nowrap _unit-column ",
            render: (text, record) => _.get(record, "unit.name", "")
        },
        {
            dataIndex: 'action',
            key: 'action',
            width: 70,
            className: "text-center text-nowrap _action-column",
            render: (text, record, idx) => {
                let {warehouses} = record;
                if (!warehouses) return null;
                let totalWarehouseAreas = warehouses.reduce((total, row) => total + row.warehouseAreas.length, 0);
                /**
                 * Nếu số lượng sản phẩm nhỏ hơn = số lượng stack đã được thêm
                 * hoặc số lượng Vị trí kho nhỏ hơn = số lượng stack
                 * thì disabled nút Thêm stack
                 */

                const quantity = getVar(record, 'quantity', 0)
                const stockQuantity = sumBy(getVar(record, 'stacks', []), 'stock_quantity')

                return (
                    <Button
                        className="_btn-add"
                        onClick={() => addRow(record.id)}
                        type="primary"
                        disabled={quantity <= record.stacks.length || totalWarehouseAreas <= record.stacks.length || quantity <= stockQuantity}
                    >{t("order:btn.add")}</Button>
                );
            }
        }
           
    ];

    /**
     * Nếu tổng số lượng các stack sau kiểm hàng = tổng số sản phẩm của mỗi orderSku
     * Và mã kho và mã vị trí kho của mỗi stack đã được cập nhật
     * Và số lượng mỗi stack phải có số lượng lớn hơn 0
     * Thì cho phép click vào nút Xác nhận kiểm hàng
     */
    useEffect(() => {
        let tmpCanInspect = skus.every(sku => {
            if (inspectedSkus.includes(sku.sku_id)){
                return true;
            }

            let isEnough = false;
            let {product} = sku;
            let totalProducts = sku.stacks.reduce((total, stack) => {
                isEnough = (
                    stack.warehouse_id !== ""
                    && stack.warehouse_area_id !== ""
                    && stack.quantity !== ""
                    && stack.quantity > 0
                    && stack.stock_quantity !== ""
                    && (stack.stock_quantity >= stack.quantity || product?.dropship === 1)
                );
                return total + (stack.quantity !== "" ? stack.quantity : 0);
            }, 0);
            return totalProducts === sku.quantity && isEnough;
        });
       
        setCanInspect(tmpCanInspect);
    }, [skus]);

    /**
     * Gửi thông tin kiểm hàng
     */
    function inspectOrder() {
        let orderStocks = [];
        skus.forEach(sku => {
            let {stacks} = sku;
            let stocks = stacks.map(stack => ({
                sku_id: getVar(sku, "sku.id", undefined),
                warehouse_area_id: stack.warehouse_area_id,
                quantity: stack.quantity
            }));
            orderStocks = [...orderStocks, ...stocks];
        });

        api.inspectOrder(order.id, {order_stocks: orderStocks})
            .then(res => {
                let data = _.get(res, "data", {});
                // setOrderDetail({...orderDetail, ...data});
                fetchOrder()
                notification.success({message: t("order:message.inspect_success")});
            })
            .catch(catchErrors(t('order:message.inspect_fail'), showErrors));
    }

    function showErrors(data) {
        let errors = _.get(data, "errors", {});
        let tmpLineErrors = [];
        Object.keys(errors).forEach(errorKey => {
            tmpLineErrors.push({
                sku_id: parseInt(errorKey.replace("sku_", "")),
                error_code: Object.keys(errors[errorKey])
            })
        });
        setLineErrors(tmpLineErrors);
    }

    /**
     * Thêm kho và vị trí kho cho 1 sku
     */
    function addRow(orderSkuId) {
        let sku = skus.find(item => orderSkuId === item.id);
        let new_stack = {
            id: Date.now(),
            warehouse_id: "",
            warehouse_area_id: "",
            quantity: "",
            stock_quantity: ""
        };
        setSkus(updateCollectionItem(skus, sku.id, {...sku, stacks: [...sku.stacks, new_stack]}));
    }

    /**
     * Xóa kho khỏi danh sách kho tương ứng với từng sku
     */
    function removeRow(order_sku_id, row_id) {
        let sku = skus.find(item => order_sku_id === item.id);
        let stacks = sku.stacks.filter(row => row.id != row_id);
        setSkus(updateCollectionItem(skus, sku.id, {...sku, stacks}));
    }
    function renderStacks(sku) {
        let {stacks} = sku;
        return (
            stacks.map((item, index) => (
                <StackRow
                    skus={skus}
                    setSkus={setSkus}
                    sku={sku}
                    stack={item}
                    key={index}
                    index={index}
                    removeRow={removeRow}
                    lineErrors={lineErrors}
                    setLineErrors={setLineErrors}
                    setCanInspect={setCanInspect}
                    canInspect={canInspect}
                />
                ))
        );
    }

    function renderInspection(sku) {
        let orderStocksBySku = orderStocks.filter(item => {
            let {orderStock} = item;
            return sku.sku_id === orderStock.sku_id;
        });

        return (
            orderStocksBySku.map((row, index) => (
                <Inspection
                    stock={row}
                    key={index}
                    index={index}
                />
            ))
        );
    }

    if (skus.length <= 0)
        return null;

    return (
        <div>
            <Table
                className="_check-product"
                dataSource={skus}
                rowKey={"id"}
                columns={columns}
                pagination={false}
                locale={{ emptyText: <TableEmpty /> }}
                expandable={{
                    defaultExpandAllRows: true,
                    rowExpandable: () => {return true;},
                    expandedRowRender: sku => {

                        const quantity = getVar(sku, 'quantity', 0)
                        const stockQuantity = sumBy(getVar(sku, 'stacks', []), 'stock_quantity')

                        return inspectedSkus.includes(sku.sku_id) && quantity <= stockQuantity  ?
                        renderInspection(sku) :
                        renderStacks(sku)
                    }
                }}
                scroll={{x: 800}}
            />
            <p className="text-right mt-3">
                <Button
                    size="large"
                    type="primary"
                    className="text-uppercase"
                    disabled={!canInspect}
                    onClick={inspectOrder}
                ><SaveOutlined /> {t("order:btn.confirm_check")}</Button>
            </p>
        </div>
    );
}
