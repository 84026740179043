/* eslint-disable import/no-anonymous-default-export */
import { api } from '../../../system/api';

export default {
    getOrders: params => api.get('/orders', { params, singleRequest: true, loading: false }),
    importOrder: data => api.post('/orders/import', data),
    importStatus: data => api.post('/orders/import-status', data),
    importInspectionOrder: data => api.post('/orders/import-check-commodities', data),
    getOrder: id => api.get(`/orders/${id}`, { singleRequest: true, loading: false }),
    createOrder: params => api.post('/orders', params),
    exportOrder: params =>
        api.get('/orders/export', {
            params,
            singleRequest: true,
            loading: false,
            responseType: 'blob',
        }),
    inspectOrder: (id, data) => api.post(`/orders/${id}/inspection`, data),
    getWarehouses: orderId => api.get(`/orders/${orderId}/warehouses`, { singleRequest: true, loading: false }),
    getSkusWaitingPick: (orderId, params) =>
        api.get(`/orders/${orderId}/skus/waiting-pick`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    deliverOrder: order_id => api.put(`orders/${order_id}/delivery`),
    cancelOrder: (order_id, data) => api.put(`/orders/${order_id}/cancel`, data),
    confirmPayment: (orderId, data) => api.post(`orders/${orderId}/payment-confirm`, data),
    getReImport: orderId => api.get(`/orders/${orderId}/reimports`, { singleRequest: true, loading: false }),
    getHistoryOrder: orderId => api.get(`/orders/${orderId}/logs`, { singleRequest: true, loading: false }),
    importFreightBill: data => api.post('/orders/import-freight-bill', data),
    updateShippingPartner: (order_id, data) => api.put(`/orders/${order_id}/shipping-partner`, data),
    getOrderPackings: params =>
        api.get(`/order-packings`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    createFreightBill: data => api.post('/order-packings/tracking-no', data),
    downloadItemList: params =>
        api.get('/order-packings/download-list-items', {
            params,
            responseType: 'blob',
        }),
    updateShippingPartnerForOrderPackings: data => api.put(`/order-packings/shipping-partner`, data),
    importForUpdate: data => api.post('orders/import-for-update', data),
    importForConfirm: data => api.post('orders/import-for-confirm', data),
    getInvalidOrders: params => api.get(`/invalid-orders`, { params, singleRequest: true }),
    reSyncOrderError: id => api.post(`/invalid-orders/${id}/resync`),
    deleteInvalidOrder: id => api.delete(`invalid-orders/${id}`),
    resyncMultiInvalidOrders: data => api.post('invalid-orders/resync-multi', data),
    updateReturnOrderInfo: data => api.post('orders/import-freight-bill-status', data),
    update: data => api.put(`orders/${data?.id}`, data),
    cancelFreightBill: data => api.post('/order-packings/cancel-tracking-no', data),
    importChangeFinancialStatus: data => api.post('/orders/import-finance-status', data),
    exportCosts: params =>
        api.get('/orders/export-services', { params, singleRequest: true, loading: true, responseType: 'blob' }),
    downloadFileCreateFreightBill: params =>
        api.get('/order-packings/download-temp-trackings', {
            params,
            singleRequest: true,
            loading: true,
            responseType: 'blob',
        }),
    getOrderPackingBeforeTrackingNo: params =>
        api.get(`order-packings/before-tracking-no`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    addWarehouseArea: data => api.post(`/order-packings/add-warehouse-area`, data),
    removeWarehouseArea: data => api.post(`/order-packings/remove-warehouse-area`, data),
    createPickingSession: data => api.post(`/picking-sessions`, data),
    getPickingSessionById: sessionId =>
        api.get(`/picking-sessions/${sessionId}`, {
            singleRequest: true,
            loading: false,
        }),
    confirmPickPiece: (sessionId, sessionPieceId) =>
        api.get(`/picking-sessions/${sessionId}/pieces/${sessionPieceId}/picked`),
    confirmFinishPickup: sessionId => api.get(`/picking-sessions/${sessionId}/picked`),
    addPriority: data => api.post(`order-packings/add-priority`, data),
    getOrderPackingBeforeWarehouseArea: data => api.post(`order-packings/before-remove-warehouse-area`, data),
    addStaffForOrder: params =>
        api.get(`order-packings/grant-picker`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    processPickingSession: params =>
        api.get(`picking-sessions/processing-picking-session`, {
            params,
        }),
    updateChangeFreightBillStatus: data => api.post('orders/import-freight-bill-status-new', data),
};
