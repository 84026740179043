import React, {useState, useEffect} from 'react';
import {Form, Row, DatePicker, Col, Button, Input, Select, Radio} from 'antd';
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";
import {t} from "../../../../system/i18n";

function FormSearch({addFilter, filters, initFilters}) {
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const {
        code,
        name,
        product_code,
        product_name
    } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({...input, [name]: value});
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onSubmit() {
        addFilter(input);
    }

    function _onReset() {
        addFilter(initFilters);
    }

    return (
        <Form
            className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray"
            form={form}
            layout="vertical"
        >
            <Row gutter={20}>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-3"
                        label={t("synchronousProduct:label.product_code")}
                    >
                        <Input
                            name="product_code"
                            value={product_code}
                            placeholder={t("synchronousProduct:placeholder.product_code")}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-3"
                        label={t("synchronousProduct:label.product_name")}
                    >
                        <Input
                            name="product_name"
                            value={product_name}
                            placeholder={t("synchronousProduct:placeholder.product_name")}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-3"
                        label={t("synchronousProduct:label.code")}
                    >
                        <Input
                            name="code"
                            value={code}
                            placeholder={t("synchronousProduct:placeholder.code")}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-3"
                        label={t("synchronousProduct:label.name")}
                    >
                        <Input
                            name="name"
                            value={name}
                            placeholder={t("synchronousProduct:placeholder.name")}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={{span: 24}} className="text-right" style={{marginTop: "6px"}}>
                    <Button type="primary" onClick={_onSubmit}><SearchOutlined/> {t("common:btn.search")}</Button>
                    <Button className="ml-2" onClick={_onReset}><UndoOutlined/> {t("common:btn.reset")}</Button>
                </Col>
            </Row>

        </Form>
    );
}

export default FormSearch;
