import { useCallback, useEffect, useRef } from 'react';

import usePersistFn from './usePersistFn';
import useUrlState from './useUrlState';

const useAntdTable = (queryHook, options = {}) => {
    const { defaultParams, form } = options;
    const [filters, setFilters, clearFilters] = useUrlState(defaultParams);
    const result = queryHook(filters);
    const formRef = useRef(form);
    formRef.current = form;

    const { refetch } = result;

    const submit = usePersistFn(params => {
        _submit(params);
    });

    const _submit = useCallback(
        params => {
            const values = formRef.current ? formRef.current.getFieldsValue() : {};
            const formattedValues = params
                ? {
                      ...values,
                      ...params,
                  }
                : values;

            setFilters(formattedValues);

            // wait for new filter effected
            setTimeout(() => {
                refetch();
            });
        },
        [refetch, setFilters]
    );

    const clear = useCallback(() => {
        if (!formRef.current) {
            return;
        }

        formRef.current.resetFields();
        clearFilters();

        // wait for new filter effected
        setTimeout(() => {
            refetch();
        });
    }, [clearFilters, refetch]);

    useEffect(() => {
        if (!formRef.current) {
            return;
        }

        formRef.current.setFieldsValue(filters);

        refetch();

        // eslint-disable-next-line
    }, [filters]);

    return {
        ...result,
        ...result?.data?.data,
        filters,
        search: {
            submit,
            clear,
        },
    };
};

export default useAntdTable;
