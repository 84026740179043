import {useEffect, useState} from "react";

const SMALL = 576;
const MEDIUM = 768;
const LARGE = 992;
const EXTRA_LARGE = 1200;
const EXTRA_EXTRA_LARGE = 1600;

export default function useSize() {
    const [isXSmall, setIsXSmall] = useState(false);
    const [isSmall, setIsSmall] = useState(false);
    const [isMedium, setIsMedium] = useState(false);
    const [isLarge, setIsLarge] = useState(true);
    const [isExtraLarge, setIsExtraLarge] = useState(true);
    const [isExtraExtraLarge, setIsExtraExtraLarge] = useState(true);

    useEffect(() => {
        setScreenSize();
        return window.addEventListener("resize", setScreenSize);
    }, []);

    function setScreenSize() {
        setIsXSmall( SMALL > window.innerWidth ? true : false);
        setIsSmall( (SMALL <= window.innerWidth && MEDIUM > window.innerWidth) ? true : false);
        setIsMedium((MEDIUM <= window.innerWidth && LARGE > window.innerWidth) ? true : false);
        setIsLarge((LARGE <= window.innerWidth && EXTRA_LARGE > window.innerWidth) ? true : false);
        setIsExtraLarge((EXTRA_LARGE <= window.innerWidth && EXTRA_EXTRA_LARGE > window.innerWidth) ? true : false);
        setIsExtraExtraLarge(EXTRA_EXTRA_LARGE <= window.innerWidth ? true : false);
    }

    return {
        isXSmall, isSmall, isMedium, isLarge, isExtraLarge, isExtraExtraLarge,
        isMobile: isXSmall || isSmall,
        isTablet: isMedium,
        isDesktop: isLarge || isExtraLarge || isExtraExtraLarge
    };
}
