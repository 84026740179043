
import { useEffect, useRef } from 'react';

// modified from https://ahooks.js.org/hooks/life-cycle/use-update-effect

const useUpdateEffect = (effect, deps) => {
    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
        } else {
            return effect();
        }

        // eslint-disable-next-line
    }, deps);
};

export default useUpdateEffect;
