import { CheckOutlined } from '@ant-design/icons';
import { Typography, Button, Row, Col } from 'antd';
import React from 'react';

import { t, trans } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { join, map } from 'lodash';

const { Title } = Typography;
function OrderInfo({ storeData, handlePass }) {
    const order = getVar(storeData, 'orderInfo.order', {});
    const skuList = getVar(storeData, 'orderInfo.order_stocks', []);
    return (
        <div className="_order-info">
            <div className="d-flex justify-content-between align-items-center">
                <Title level={3}>{trans('packing:title.order_info', { code: <b>{`#${getVar(order, 'code', '')}`}</b> })}</Title>
                <Button className="_btn-pass" type="primary" onClick={handlePass}>
                    {t('btn.pass')}
                </Button>
            </div>
            {skuList.map((item, index) => {
                const warehouseArea = getVar(item, 'warehouse_area.name', '');
                const quantity = getVar(item, 'quantity', 0);
                const scanned = getVar(item, 'scanned', 0);
                const sku = getVar(item, 'sku', {});
                return (
                    <Row
                        gutter={24}
                        key={index}
                        className={`_order-info-row p-3 ${index + 1 < skuList.length ? 'border-bottom' : ''}`}
                    >
                        <Col lg={{ span: 3 }} span={24} className="_order-info-sku-id">
                            {`${t('order:label.sku_id')}: ${item?.sku_id}`}
                        </Col>
                        <Col lg={{ span: 4 }} span={24} className="_order-info-sku-code">
                            {`${t('order:label.sku_info')}: ${getVar(sku, "code")}`}
                        </Col>
                        <Col lg={{ span: 4 }} span={24} className="_order-info-warehouse-area">
                            {`${t('label.warehouse_area')}: ${
                                warehouseArea === 'default' ? t('order:label.default') : warehouseArea
                            }`}
                        </Col>
                        <Col lg={{ span: 4 }} span={24} className="_order-info-quantity">
                            {`${t('label.serial_number')}: ${join(getVar(item,'serial_numbers', []), " ,")}`}
                        </Col>
                        <Col lg={{ span: 5 }} span={24} className="_order-info-quantity">
                            {`${t('label.quantity')}: ${quantity}`}
                        </Col>
                       
                        <Col lg={{ span: 4 }} span={24} className="_order-info-scanned">
                            {`${t('label.scanned')}: ${scanned}`}
                            {Number(quantity) === Number(scanned) && (
                                <CheckOutlined className={'ml-2'} style={{ color: 'green' }} />
                            )}
                        </Col>
                    </Row>
                );
            })}
        </div>
    );
}
export default OrderInfo;
