import { ScanOutlined } from '@ant-design/icons';
import { Tooltip, Modal } from 'antd';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import React, { useState } from 'react';
import BarcodeScanner from 'react-qr-barcode-scanner';

import { t } from '@System/i18n';
import notification from '@System/notification';

function ScanBarcode({ onUpdate }) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleUpdate = (_, result) => {
        const barcode = get(result, 'text');

        if (barcode) {
            setIsModalVisible(false);

            if (isFunction(onUpdate)) {
                onUpdate(barcode);
            }
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleError = error => {
        if (error.name === 'NotAllowedError') {
            notification.error(t('cannot_scan_code'));
        }

        setIsModalVisible(false);
    };

    return (
        <>
            <Tooltip title={t('input_or_scan_barcode')}>
                <ScanOutlined onClick={showModal} style={{ color: 'rgba(0,0,0,.45)' }} />
            </Tooltip>

            {isModalVisible && (
                <Modal
                    title={t('input_or_scan_barcode')}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={false}
                    width={650}
                >
                    <BarcodeScanner
                        delay={300}
                        onError={handleError}
                        onUpdate={handleUpdate}
                        style={{ width: '100%', height: '100%' }}
                        stopStream={!isModalVisible}
                    />
                </Modal>
            )}
        </>
    );
}

export default ScanBarcode;
