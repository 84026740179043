import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

import { t } from '@System/i18n'
import { classes } from '@System/support/helpers'

const ModalConfirm = ({ okButtonProps, cancelButtonProps, handleHideModal, visible, content, className }) => {
    return (
        <>
            {visible && (
                <Modal
                    title=""
                    visible={true}
                    cancelText={t('btn.cancel')}
                    okText={t('btn.confirm')}
                    className={classes('custom-confirm-modal', {
                        [className]: !isEmpty(className),
                    })}
                    closable
                    onCancel={handleHideModal}
                >
                    <div className="confirm-body d-flex">
                        <div>
                            <ExclamationCircleOutlined />
                        </div>
                        <p >{content}</p>
                    </div>

                    <div className="confirm-btn-group">
                        <Button
                            {...cancelButtonProps}
                            className={classes('mr-2', {
                                [cancelButtonProps?.className]: cancelButtonProps?.className,
                            })}
                            onClick={handleHideModal}
                        >
                            {t('btn.back')}
                        </Button>
                        <Button
                            type="primary"
                            {...okButtonProps}
                        >
                            {t('btn.confirm')}
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    )
}
ModalConfirm.propTypes = {
    handleHideModal: PropTypes.func,
    isLoading: PropTypes.bool,
    okButtonProps: PropTypes.object,
    cancelButtonProps: PropTypes.object,
    className: PropTypes.string,
}

export default ModalConfirm
