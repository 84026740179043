import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { AutoComplete, Col, Form, Row, Select, Spin, Tooltip } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { filter, get, isEmpty, isNil, map } from 'lodash'
import React, { useState } from 'react'

import { t } from '@System/i18n'
import { classes } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

import { IError } from './CreateServicePack'

const { Option } = AutoComplete
interface IAddService {
    errors: IError[]
    services: any
    setServiceGroup: (value: any) => void
    serviceGroup: any
    selectedList: any
    setSelectedList: (value: any) => void
    isFetching: boolean
    currency: any
    setErrors: (value: IError[]) => void
    setIsEmptyGroup?: (value: boolean) => void
}

const AddService: React.FC<IAddService> = ({
    services,
    setServiceGroup,
    serviceGroup,
    selectedList,
    setSelectedList,
    isFetching,
    currency,
    errors,
    setErrors,
    setIsEmptyGroup,
}) => {
    const { xs } = useBreakpoint()

    const idServiceGroups = map(serviceGroup, 'service.id')

    const onSelectService = value => {
        const newList = isEmpty(value) ? [] : services.filter(item => value.includes(item.service.id))

        const newSelectedList = newList.map(item => {
            selectedList.forEach(val => {
                if (item.service.id === val.service.id) {
                    item = val
                }
            })
            return item
        })
        setErrors([])

        setSelectedList(newSelectedList)
    }

    const onRemoveService = id => {
        setSelectedList(prev => filter(prev, val => val?.service?.id !== id))
    }

    const handleChangePrice = (idService, idPrice, index) => {
        if (!isNil(idService) && !isNil(idPrice)) {
            const newSelectedPrices = selectedList.map(selected => (selected.service.id === idService ? { ...selected, idPrice } : selected))
            const errorList: IError[] = map(errors, (item, idx) => {
                if (index === idx) {
                    item = {
                        message: '',
                        error: false,
                    }
                }
                return item
            })

            setErrors(errorList)
            setSelectedList(newSelectedPrices)
        }
    }

    const handleSaveToList = (serviceRow, index) => {
        if (!get(serviceRow, 'idPrice')) {
            const errorList: IError[] = map(selectedList, (item, idx) => {
                return {
                    message: index === idx ? t('order:message.required', { attribute: t('label.price_level') }) : '',
                    error: index === idx,
                }
            })

            setErrors(errorList)
            return
        }

        if (!idServiceGroups.includes(get(serviceRow, 'service.id'))) {
            setServiceGroup(prev => [...prev, { ...serviceRow, service_price: get(filter(serviceRow.servicePrices, ['id', get(serviceRow, 'idPrice')]), '0') }])
            setSelectedList(prev => prev.filter(item => item.service.id !== serviceRow.service.id))

            if (setIsEmptyGroup) {
                setIsEmptyGroup(false)
            }
        }
    }

    return (
        <div className="mt-4">
            <Spin spinning={isFetching}>
                <div className="d-flex justify-content-center align-items-center mb-4">
                    <span className={classes('mr-4', { 'text-nowrap': !xs })}>{t('label.search_service')}</span>
                    <Select
                        mode="multiple"
                        size="middle"
                        style={{
                            width: '100%',
                        }}
                        onChange={onSelectService}
                        placeholder={t('placeholder.type_keyword')}
                        value={isEmpty(selectedList) ? [] : map(selectedList, 'service.id')}
                        filterOption={(inputValue, option: any) => {
                            if (!option.value) {
                                return false
                            } else {
                                const value = get(filter(services, ['service.id', option.value]), '0')
                                return (
                                    (get(value, 'service.name') as unknown as string).toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 ||
                                    (get(value, 'service.code').toLowerCase() as unknown as string).indexOf(inputValue.toLowerCase()) !== -1
                                )
                            }
                        }}
                        allowClear
                    >
                        {services?.map(item => {
                            return (
                                !idServiceGroups.includes(get(item, 'service.id')) && (
                                    <Option
                                        key={get(item, 'service.id')}
                                        value={get(item, 'service.id')}
                                    >
                                        {get(item, 'service.name')} - {get(item, 'service.code')}
                                    </Option>
                                )
                            )
                        })}
                    </Select>
                </div>

                <div>
                    {!isEmpty(selectedList) &&
                        selectedList.map((item, index) => {
                            const idService = get(item, 'service.id')
                            const service_price = get(item, 'servicePrices')

                            return (
                                <div
                                    className="d-flex"
                                    key={idService}
                                >
                                    <div>
                                        <div className="d-flex align-items-center mb-2">
                                            <div>
                                                <span className="mr-3">{t('service')}:</span>
                                                <span>{get(item, 'service.name')}</span>
                                            </div>
                                        </div>

                                        <Form.Item
                                            label={t('label.price_level')}
                                            validateStatus={get(errors, `${index}.error`) ? 'error' : undefined}
                                            help={get(errors, `${index}.message`) ? get(errors, `${index}.message`) : false}
                                        >
                                            {!isEmpty(service_price) ? (
                                                <div>
                                                    <Select
                                                        style={{
                                                            width: 180,
                                                        }}
                                                        placeholder={t('placeholder.choose_price_level')}
                                                        onChange={id => handleChangePrice(idService, id, index)}
                                                        filterOption={(input, option: any) => {
                                                            if (!option.value) {
                                                                return false
                                                            } else {
                                                                const value = get(filter(service_price, ['id', option.value]), '0')
                                                                return get(value, 'label').toLowerCase().indexOf(input.toLowerCase()) !== -1
                                                            }
                                                        }}
                                                        showSearch
                                                    >
                                                        {service_price.map(price => (
                                                            <Option
                                                                value={price.id}
                                                                key={price.id}
                                                            >
                                                                <Row>
                                                                    <Col span={12}>{price.label}</Col>
                                                                    <Col span={12}>
                                                                        {isEmpty(currency)
                                                                            ? get(price, 'price')
                                                                            : formatCurrency(get(price, 'price') || 0, currency)}
                                                                    </Col>
                                                                </Row>
                                                            </Option>
                                                        ))}
                                                    </Select>

                                                    {!isEmpty(service_price) && (
                                                        <Tooltip title={t('label.save_change')}>
                                                            <SaveOutlined
                                                                className="cursor-pointer text-save p-2 pb-0 mt-1"
                                                                onClick={() => handleSaveToList(item, index)}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <span className="text-nowrap text-red">{t('label.not_level_price')}</span>
                                                    {isEmpty(service_price) && (
                                                        <Tooltip title={t('btn.delete')}>
                                                            <DeleteOutlined
                                                                className="ml-3 cursor-pointer text-delete p-2"
                                                                onClick={() => onRemoveService(idService)}
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </Spin>
        </div>
    )
}

export default AddService
