import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useWarehouseStocksQuery = (params, settings) =>
    createUseQuery({
        name: ['warehouse-stocks', params],
        query: () => apiService.warehouseStocks(params),
        settings,
    });

export default useWarehouseStocksQuery;
