import { api as apiSystem } from "../../../system/api";
import { ISettings } from "../interfaces";

const api =  {
    getBanner: (code: string) => apiSystem.get(`tenants/${code}/images`),
    uploadBanner: (data: FormData) => apiSystem.post(`tenant-settings/update-banner`, data),
    getLogicBillLading: () => apiSystem.get(`/tenant-settings`),
    changeLogicBillLading: (data :  Pick<ISettings, "auto_create_freight_bill">) => apiSystem.post(`/tenant-settings`, data),
    changeCompulsorySizePurchasePackage: (data :  Pick<ISettings, "document_importing">) => apiSystem.post(`/tenant-settings/document-setting`, data),
    hiddenPostSetting: (data :  Pick<ISettings, "encode_info">) => apiSystem.post(`tenant-settings/encode-info`, data),

}

export default api