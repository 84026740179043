import {api} from '../../../system/api';

export default {
    importInventoryProducts: (data) => api.post("/stocks/import", data),
    importPrice: (data) => api.post("/skus/import-price", data),
    searchSkus: (params) => api.get("/skus/suggest", {params, singleRequest: true, loading: false}),
    getSkus: params => api.get("/skus", {
        params: params,
        singleRequest: true,
        loading: false
    }),
    createSku: data => api.post("/skus", data),
    updateSku: (skuId, data) => api.put(`/skus/${skuId}`, data),
    getSkuDetail: (skuId, params) => api.get(`/skus/${skuId}`, {params, singleRequest: true, loading: false}),
    getSkuStocks: skuId => api.get(`/skus/${skuId}/stocks`, {singleRequest: true, loading: false}),
    getLogsImport: (params) => api.get(`/import-histories`, {params, singleRequest: true, loading: false}),
    getImportHistoriesDetail: (historyId) => api.get(`/import-histories/${historyId}`, {
        singleRequest: true,
        loading: false
    }),
    getImportHistoriesItems: (historyId, params) => api.get(`/import-histories/${historyId}/items`, {
        params,
        singleRequest: true,
        loading: false
    }),
    updateStatusSku: (data) => api.put(`skus/status`, data),
    getLogChargeSku: (params) => api.get("/stock-logs", {params, singleRequest: true, loading: false})
}
