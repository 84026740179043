import { Checkbox, Form, Select, Table, Tooltip } from 'antd';
import { get } from 'lodash';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { updateCollectionItem } from '@Modules/App/services/helpers';
import { EVENTS } from '@Modules/Product/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

const { Option } = Select;

function RenderServiceByType({
    product,
    form,
    serviceType,
    setServiceIds,
    dataServices,
    dataSource,
    setDataSource,
    className = '',
    currency,
}) {
    const [services, setServices] = useState(getServiceInitial());
    const [requiredServices, setRequiredServices] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    // const rowSelection = {
    //     selectedRowKeys,
    //     onChange: (selectedRowKeys, selectedRows) => {
    //         if (selectedRowKeys.length > 0) {
    //             setSelectedRowKeys(selectedRowKeys);
    //             setServiceIds(preState => ({ ...preState, [serviceType]: selectedRowKeys }));
    //             setServices(selectedRows);
    //         } else {
    //             setSelectedRowKeys(map(requiredServices, ['service.id']));
    //             setServiceIds(preState => ({ ...preState, [serviceType]: map(requiredServices, ['service.id']) }));
    //             setServices(requiredServices);
    //         }
    //     },

    //     renderCell: (checked, record) => {
    //         const disabledRowByRequired = get(record, 'service.is_required');
    //         const disabledRowByInactive = get(record, 'service.status', 'ACTIVE') === 'INACTIVE';
    //         let renderCheckbox = <Checkbox checked={checked} onChange={() => handleChangeCheckbox(checked, record)} />;
    //         if (disabledRowByRequired) {
    //             renderCheckbox = <Tooltip title={t('message.service_required')}>
    //                 <Checkbox checked={disabledRowByRequired} disabled={disabledRowByRequired} />
    //             </Tooltip>;
    //         }else if (disabledRowByInactive) {
    //             renderCheckbox = <Tooltip title={t('message.service_inactive')}>
    //                 <Checkbox checked={disabledRowByInactive} disabled={disabledRowByInactive} />
    //             </Tooltip>;
    //         }
    //         return (renderCheckbox);
    //     },
    // };
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRowKeys.length > 0) {
                setSelectedRowKeys(selectedRowKeys);
                setServiceIds(preState => ({ ...preState, [serviceType]: selectedRowKeys }));
                setServices(selectedRows);
            } else {
                setSelectedRowKeys(map(requiredServices, ['service.id']));
                setServiceIds(preState => ({ ...preState, [serviceType]: map(requiredServices, ['service.id']) }));
                setServices(requiredServices);
            }
        },

        renderCell: (checked, record, index) => {
            const disabledRow = get(record, 'service.is_required');
            return (
                <>
                    {disabledRow ? (
                        <Tooltip title={t('message.service_required')}>
                            <Checkbox checked={disabledRow} disabled={disabledRow} />
                        </Tooltip>
                    ) : (
                        <Checkbox checked={checked} onChange={() => handleChangeCheckbox(checked, record)} />
                    )}
                </>
            );
        },
    };

    const handleChangeCheckbox = (checked, record) => {
        const { key } = record;
        let newSelectedKeys = [...selectedRowKeys, key];
        let newServices = [...services, record];
        if (checked) {
            newSelectedKeys = filter(selectedRowKeys, item => item !== key);
            newServices = filter(services, item => item.key !== key);
        }
        setServiceIds(preState => ({ ...preState, [serviceType]: newSelectedKeys }));
        setServices(newServices);
        setSelectedRowKeys(selectedRowKeys);
    };

    useEffect(() => {
        return events.listen(EVENTS.RELOAD_PRODUCT_SERVICE, () => {
            setServices(getServiceInitial());
            const data = refactorServiceSource(filter(dataServices, ['service.type', serviceType]));
            setDataSource(preState => ({ ...preState, [serviceType]: data }));
        });
    }, []);

    useEffect(() => {
        setServices([...services, ...requiredServices])
    }, [requiredServices]);

    useEffect(() => {
        const data = refactorServiceSource(filter(dataServices, ['service.type', serviceType]));
        setDataSource(preState => ({ ...preState, [serviceType]: data }));
    }, [dataServices]);

    useEffect(() => {
        setServices(getServiceInitial());
    }, [product]);

    useEffect(() => {
        setSelectedRowKeys(map(services, 'key'));
        setServiceIds(preState => ({ ...preState, [serviceType]: map(services, 'key') }));
    }, [services]);

    function refactorServiceSource(data) {
        const newData = [];
        const requiredServices = [];
        const dataInactive = map(filter(services, item => item.status === "INACTIVE"), 'id');
        data.map(item => {

            if (item.service.status === "ACTIVE" || dataInactive.includes(item.service.id)) {
                const servicePrices = getVar(item, 'servicePrices', []);
                const servicePriceId = undefined;
                const price = undefined;
                let info = { ...item, key: v4(), service_price_id: servicePriceId, price };
                if (services.length > 0) {
                    let hasServicePrice = false;
                    servicePrices.map(servicePrice => {
                        const serviceInfo = find(services, ['service_price_id', servicePrice.id]);
                        if (!isEmpty(serviceInfo)) {
                            hasServicePrice = true;
                            newData.push({
                                ...item,
                                key: servicePrice.id,
                                service_price_id: servicePrice.id,
                                price: getVar(serviceInfo, 'price', 0),
                            });
                        }
                    });
                    if (!hasServicePrice) newData.push(info);
                } else {
                    newData.push(info);
                }
                if (item.service.is_required) {
                    requiredServices.push(info);
                }
            }
        });
        setRequiredServices(requiredServices);
        return newData;
    }

    function getServiceInitial() {
        const serviceSelected = getVar(product, 'services', []);
        const servicePrices = getVar(product, 'servicePrices', []);
        const serviceCodes = map(servicePrices, 'service_code');

        const services = [];
        filter(serviceSelected, item => {
            if (serviceType === item.type && serviceCodes.includes(item.code)) {
                const servicePrice = find(servicePrices, ['service_code', item.code]);
                services.push({
                    ...item,
                    key: servicePrice.id,
                    service_price_id: servicePrice.id,
                    price: getVar(servicePrice, 'price', 0),
                });
            }
        });
        return services;
    }


    const columns = [
        {
            className: '_service-list-name text-nowrap',
            title: t('label.service'),
            dataIndex: ['service', 'name'],
            key: 'name',
        },
        {
            className: '_service-price-level text-nowrap',
            title: t('label.price_level'),
            dataIndex: 'service_price_id',
            editable: true,
            render: (text, record) => {
                const servicePrices = getVar(record, 'servicePrices', []);
                const servicePricesInfo = find(servicePrices, ['id', text]);
                return !isEmpty(servicePricesInfo) ? getVar(servicePricesInfo, 'label', '') : t('label.not_available');
            },
        },
        {
            className: 'text-right _service-list-unit-price',
            title: t('label.service_price'),
            dataIndex: 'price',
            width: '150px',
            render: (text, record) => {
                const servicePriceId = getVar(record, 'service_price_id', undefined);
                const price = getVar(record, 'price', 0);
                return servicePriceId
                    ? price
                        ? formatCurrency(price, currency ? currency : null)
                        : t('label.price_free')
                    : t('label.not_available');
            },
        },
    ];

    function updateServiceInfo(servicePriceId, data) {
        const currentSource = getVar(dataSource, serviceType, []);
        const serviceInfo = find(currentSource, ['key', data.key]);
        if (!isEmpty(serviceInfo) && serviceInfo['service_price_id'] !== servicePriceId) {
            const servicePrices = getVar(data, 'servicePrices', []);
            const servicePriceInfo = find(servicePrices, ['id', servicePriceId]);
            const price = getVar(servicePriceInfo, 'price', 0);
            const key = servicePriceId ? servicePriceId : v4();
            const payload = { key, price, service_price_id: servicePriceId };
            const newDataSources = updateCollectionItem(currentSource, data.key, { ...payload }, 'key');
            const newServices = updateCollectionItem(services, data.key, { ...payload }, 'key');
            setDataSource(preState => ({ ...preState, [serviceType]: newDataSources }));
            setServices(newServices);
        }
    }

    const tableColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record, index) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                index: record.key,
                updateServiceInfo,
                selectedRowKeys,
            }),
        };
    });

    const EditableCell = ({
        editable,
        children,
        dataIndex,
        index,
        record,
        updateServiceInfo,
        selectedRowKeys,
        ...restProps
    }) => {
        const servicePrices = getVar(record, 'servicePrices', []);
        const status = getVar(record, 'service.status', 'ACTIVE');
        useEffect(() => {
            if (editable) {
                form.setFields([{ name: ['services', index, dataIndex], value: record[dataIndex] }]);
            }
        }, [editable]);

        const onChangePriceLevel = value => {
            form.setFields([{ name: ['services', index, dataIndex], value }]);
            updateServiceInfo(value, record);
        };

        let childNode = children;
        if (editable && selectedRowKeys.includes(record.key)) {
            if (dataIndex === 'service_price_id') {
                childNode = (
                    <Form.Item
                        className="mb-0"
                        name={['services', index, dataIndex]}
                        rules={[{ required: true, message: t('product:message.required') }]}
                    >
                        <Select
                            className="_service-choose-price-level"
                            placeholder={t('placeholder.choose_price_level')}
                            allowClear={true}
                            onChange={value => onChangePriceLevel(value)}
                            value={record[dataIndex] ? parseInt(record[dataIndex]) : undefined}
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {servicePrices.map(item => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                );
            }
        }

        return <td {...restProps}>{childNode}</td>;
    };
    const currentData = getVar(dataSource, serviceType, []);
    return (
        <div className={`bg-white rounded-12 p-4 mb-4 ${className}`}>
            <div className="d-flex justify-content-between pb-4">
                <h3>{t(`product:label.product_service.${serviceType}`)}</h3>
            </div>

            <Table
                bordered
                scroll={{ x: 576 }}
                columns={tableColumns}
                dataSource={currentData}
                rowSelection={rowSelection}
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                pagination={false}
                className="_service-list-info"
                rowClassName="_service-list-info-row"
                footer={() => {
                    return services.length > 0 ? (
                        <div className="d-flex justify-content-between">
                            <strong>{t(`product:estimated_value.${serviceType}`)}</strong>
                            {
                                serviceType !== 'EXTENT' && <strong className="_service-list-total">{`${formatCurrency(
                                    sumBy(services, item => {
                                        const amount = getVar(item, 'price', 0);
                                        if (amount) {
                                            return Number(amount);
                                        }
                                        return 0;
                                    }),
                                    currency ? currency : null
                                )}`}</strong>
                            }
                        </div>
                    ) : (
                        false
                    );
                }}
                locale={{ emptyText: <TableEmpty className="_service-list-no-data" /> }}
            />
        </div>
    );
}
export default RenderServiceByType;
