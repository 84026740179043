import { Select } from 'antd';
import { values } from 'lodash';
import React from 'react';

import { t } from '@System/i18n';

import { STATUSES } from '../services/constants';

const { Option } = Select;

const StatusesSelect = props => {
    return (
        <Select {...props}>
            {values(STATUSES).map(status => (
                <Option key={status.value} value={status.value}>
                    {t(`purchasingAccount:statuses.${status.value}`)}
                </Option>
            ))}
        </Select>
    );
};

export default StatusesSelect;
