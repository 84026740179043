import React from "react";
import UserInfo from "../Header/UserInfo";
import {Avatar, Layout} from "antd";
import {auth} from "../../Auth/services";
import {
    ArrowLeftOutlined,
    CaretRightOutlined,
    DownOutlined,
    KeyOutlined,
    LogoutOutlined,
    UserOutlined
} from "@ant-design/icons";
import ConfigMenu from "../Header/ConfigMenu";
import Language from "../Language";
import {t} from "../../../system/i18n";

const {Header} = Layout;

export default function ProfileMobile({isShown, setIsShown}) {
    const user = auth.user();

    function logout(e) {
        e.preventDefault();
        auth.logout();
    }

    const menuConfig = [
        {
            item:  <span className="d-flex justify-content-between align-items-center">{t("label.lang")} <CaretRightOutlined className="mr-0" /></span>,
            componentTab: <Language/>
        },
        {item:  <a href="#"><UserOutlined className="mr-1"/> {t("account_info")}</a>},
        {item:  <a href="#"><KeyOutlined className="mr-1"/> {t("change_pin")}</a>},
        {item:  <a className="text-red" href="#" onClick={logout}><LogoutOutlined className="mr-1"/> {t("common:menu.logout")}</a>}
    ]

    return (
        <div className={`mobile-tab ${isShown ? "mobile-tab--shown" : ""}`}>
            <Header className="page-header">
                <div className="profile-mobile-tab--back text-fz-18 cursor-pointer" onClick={() => setIsShown(false)}>
                    <ArrowLeftOutlined />
                </div>

                <span>{user.name || user.username}</span>

                <UserInfo/>
            </Header>
            <ConfigMenu visible={true} items={menuConfig} width={"100%"} />
        </div>
    );
}
