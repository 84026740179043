import { createReducer } from '@reduxjs/toolkit';
import moment from 'moment';

import { addError, clearErrors, deleteError, deleteErrorByKey } from './actions';

const initialState = {
    errors: {},
};

const reducer = createReducer(initialState, builder =>
    builder
        .addCase(addError, (state, { payload: { type, ...error } }) => {
            if (!state.errors[type]) {
                state.errors[type] = [];
            }

            if (!error?.created_at) {
                error.created_at = moment();
            }

            state.errors[type].unshift(error);
        })
        .addCase(deleteError, (state, { payload: { type, index } }) => {
            if (state.errors[type]) {
                state.errors[type].splice(index, 1);
            }
        })
        .addCase(deleteErrorByKey, (state, { payload: { type, key } }) => {
            if (state.errors[type]) {
                state.errors[type] = state.errors[type].filter(error => error.key !== key);
            }
        })
        .addCase(clearErrors, (state, { payload }) => {
            state.errors[payload] = [];
        })
);

export default reducer;
