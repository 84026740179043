import { Pagination, Select, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import useFilter from '@Modules/App/Hooks/useFilter';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { dateFormatter } from '@Modules/App/services';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import { formatCurrency } from '@System/support/numberFormat';

import api from '../services/api';

const ModalStorageCostDetail = ({ id, }) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loading, setLoading] = useState(false);

    const [params, setParams] = useState({});
    useEffect(() => {
        const getStorageFee = async () => {
            try {
                setLoading(true);
                const res = await api.getStorageFee(id, params);
                setData(get(res, 'data.sku_storage_fee_dailies'));
                setPagination(get(res, 'data.pagination'));
            } catch (error) {
                processResponseError(error);
            } finally {
                setLoading(false);
            }
        };
        getStorageFee();
    }, [id, params]);

    const columns = [
        {
            className: '_storage_cost-list-storage_cost',
            title: t('common:label.time'),
            dataIndex: 'closing_time',
            key: 'closing_time',
            render: text => {
                return <p>{dateFormatter.date(text)}</p>;
            },
        },
        {
            className: '_storage_cost-list-product_quantity text-right',
            title: t('sku:label.product_quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: text => {
                return <p>{text ? text : '---'}</p>;
            },
        },

        {
            className: '_storage_cost-cbm_remain text-right',
            title: t('product:label.cbm_remain'),
            dataIndex: 'volume',
            key: 'volume',
            render: text => {
                return <p>{text ? text : '---'}</p>;
            },
        },
        {
            className: '_storage_cost-list-storage_cost_per_day text-right',
            title: t('product:label.storage_cost_per_day'),
            dataIndex: 'fee',
            key: 'fee',
            render: (text, record) => {
                const currency = get(record, 'currency');
                return !isEmpty(currency) ? formatCurrency(text ?? 0, currency) : '---';
            },
        },
    ];

    return (
        <div className="data-box--body">
            <h3 className="text-fz-18">
                {t('product:title.storage_cost_list')} ({isEmpty(pagination) ? 0 : pagination.total})
            </h3>

            <Table
                className="m-3"
                dataSource={data}
                columns={columns}
                pagination={false}
                loading={loading}
                scroll={{ x: true }}
                rowKey={record => record?.id}
                locale={{
                    emptyText: <TableEmpty className="_inventory-list-no-data" />,
                }}
            />
            <CustomizedPagination pagination={pagination} filters={params} addFilter={setParams} />
        </div>
    );
};

export default React.memo(ModalStorageCostDetail);
