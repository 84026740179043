import {List, Typography} from 'antd';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React from 'react';

import Badge from '@Modules/App/Badge';
import Space from '@Modules/App/Space';

import {t} from '@System/i18n';
import {Link} from '@System/routing';

const {Paragraph, Title} = Typography;

const ProductList = ({products, quantityRender, totalQuantityRender}) => {
    return (
        <div>
            <Space className="mb-3" type="flex">
                <Title className="mb-0" level={5}>
                    {t('product_list')}
                </Title>
                <span className="number-circle">{products.length}</span>
            </Space>

            <List
                dataSource={products}
                footer={
                    <Space justifyContent="between" type="flex">
                        <strong>{t('sum')}</strong>
                        {totalQuantityRender ? totalQuantityRender(products) :
                            <strong>{sumBy(products, 'quantity')}</strong>}
                    </Space>
                }
                header={
                    <Space justifyContent="between" type="flex">
                        <Paragraph className="mb-0">SKU</Paragraph>
                        <Paragraph className="mb-0">{t('quantity')}</Paragraph>
                    </Space>
                }
                renderItem={product => (
                    <List.Item key={product.id}>
                        <List.Item.Meta
                            description={product?.sku?.name}
                            title={
                                <Link
                                    className="text-primary"
                                    isNewTab
                                    params={{
                                        id: product?.sku?.product_id,
                                    }}
                                    to="products.detail"
                                >
                                    {product?.sku?.code}
                                </Link>
                            }
                        />
                        <div className="text-right">{quantityRender ? quantityRender(product) : product.quantity}</div>
                    </List.Item>
                )}
            />
        </div>
    );
};

ProductList.propTypes = {
    products: PropTypes.array,
};

ProductList.defaultProps = {
    products: [],
};

export default ProductList;
