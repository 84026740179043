import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import omit from 'lodash/omit';
import { useCallback } from 'react';

import useScanSounds from './useScanSounds';

const useScanBarcode = (fn, options) => {
    const { playSuccessSound, playErrorSound } = useScanSounds();

    const callback = useCallback(
        async (...params) => {
            const formattedParams = omit(...params, 'playSound');
            const playSound = isNil(params?.[0]?.playSound) ? true : params?.[0].playSound;

            try {
                const response = await fn(formattedParams);

                if (playSound) {
                    playSuccessSound();
                }

                if (isFunction(options?.onSuccess)) {
                    options.onSuccess(response, formattedParams);
                }

                if (isFunction(options?.onSettled)) {
                    options.onSettled(formattedParams);
                }

                return response;
            } catch (error) {
                if (playSound) {
                    playErrorSound();
                }

                if (isFunction(options?.onError)) {
                    options.onError(error, formattedParams);
                }

                if (isFunction(options?.onSettled)) {
                    options.onSettled(formattedParams);
                }

                throw error;
            }
        },
        [playSuccessSound, playErrorSound, fn, options]
    );

    return callback;
};

export default useScanBarcode;
