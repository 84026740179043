/* eslint-disable import/no-anonymous-default-export */
import {
    AppstoreOutlined,
    AuditOutlined,
    OrderedListOutlined,
    SettingOutlined,
    UnorderedListOutlined,
    ProjectOutlined,
    ShopOutlined,
    BuildOutlined,
    CalculatorOutlined,
    BarChartOutlined,
    SyncOutlined,
    ApartmentOutlined,
    FileDoneOutlined,
    ImportOutlined,
    SnippetsOutlined,
    BookOutlined,
    ExportOutlined,
    UserOutlined,
    LinkOutlined,
    CloudUploadOutlined,
    IssuesCloseOutlined,
    EditOutlined,
    RotateLeftOutlined,
    DotChartOutlined,
    BlockOutlined,
    UngroupOutlined,
    NodeIndexOutlined,
    PullRequestOutlined,
    DeploymentUnitOutlined,
    ContainerOutlined,
    GroupOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import React from 'react';

import { t } from '../../../system/i18n';
import PERMISSIONS from '../../Auth/services/permissions';

export const ExpandContext = React.createContext(false);

const menu = [
    {
        name: t('common:menu.import_order'),
        permissions: [
            PERMISSIONS.OPERATION_IMPORT,
            PERMISSIONS.MERCHANT_PURCHASING_ORDER_ALL,
            PERMISSIONS.MERCHANT_PURCHASING_ORDER_ASSIGNED,
            PERMISSIONS.OPERATION_HISTORY_IMPORT,
        ],
        submenu: [
            {
                name: t('common:menu.importing_scan'),
                url: 'documents.importing.scan',
                icon: <ImportOutlined />,
                permissions: [PERMISSIONS.OPERATION_IMPORT],
            },
            {
                name: t('common:menu.purchasing_package'),
                url: 'purchasing_packages.list',
                icon: <UnorderedListOutlined />,
                permissions: [PERMISSIONS.FINANCE_VIEW_INBOUND_SHIPMENT],
            },
            {
                name: t('common:menu.import_by_purchase_package'),
                url: 'documents.importing.scan-purchase-package',
                icon: <CloudUploadOutlined />,
                permissions: [PERMISSIONS.OPERATION_IMPORT],
            },
            {
                name: t('common:menu.all_purchasing_orders'),
                url: 'purchasing_orders.list',
                icon: <SnippetsOutlined />,
                permissions: [PERMISSIONS.MERCHANT_PURCHASING_ORDER_ALL],
            },

            {
                name: t('common:menu.purchasing_orders_by_vendor'),
                url: 'purchasing_orders.list_by_vendor',
                icon: <BookOutlined />,
                permissions: [PERMISSIONS.MERCHANT_PURCHASING_ORDER_ASSIGNED],
            },
            {
                name: t('common:menu.document_import'),
                url: 'documents.importing.list',
                icon: <ImportOutlined />,
                permissions: [PERMISSIONS.OPERATION_HISTORY_IMPORT, PERMISSIONS.OPERATION_IMPORT],
            },
            {
                name: t('common:menu.scan_return_order'),
                url: 'documents.importing.scan-return-order',
                icon: <RotateLeftOutlined />,
                permissions: [PERMISSIONS.OPERATION_IMPORT],
            },
        ],
    },
    {
        name: t('common:menu.export_order'),
        permissions: [
            PERMISSIONS.ORDER_VIEW_LIST,
            PERMISSIONS.ORDER_VIEW_FAILED_ORDER,
            PERMISSIONS.OPERATION_PREPARATION,
            PERMISSIONS.OPERATION_HISTORY_PREPARATION,
            PERMISSIONS.OPERATION_EXPORT,
            PERMISSIONS.OPERATION_HISTORY_EXPORT,
        ],
        submenu: [
            {
                name: t('common:menu.orders'),
                url: 'orders.list',
                icon: <AuditOutlined />,
                permissions: [PERMISSIONS.ORDER_VIEW_LIST],
            },

            {
                name: t('menu.handle_error_order'),
                icon: <IssuesCloseOutlined />,
                url: 'orders.handle-error',
                permissions: [PERMISSIONS.ORDER_VIEW_FAILED_ORDER],
            },
            {
                name: t('common:menu.requirement_prepare_orders'),
                url: 'orders.prepare',
                icon: <OrderedListOutlined />,
                permissions: [PERMISSIONS.OPERATION_PREPARATION],
            },
            {
                name: t('common:menu.pick_up_order'),
                url: 'orders.pickup-waiting-list',
                icon: <NodeIndexOutlined />,
                permissions: [PERMISSIONS.OPERATION_PICKING],
            },
            {
                name: t('common:menu.packing_confirm'),
                url: 'packing.confirm',
                icon: <FileDoneOutlined />,
                permissions: [PERMISSIONS.OPERATION_PREPARATION],
            },
            {
                name: t('common:menu.document_packing'),
                url: 'documents.packing.list',
                permissions: [PERMISSIONS.OPERATION_HISTORY_PREPARATION, PERMISSIONS.OPERATION_PREPARATION],
            },
            {
                name: t('common:menu.order_export'),
                url: 'orders.export',
                icon: <ExportOutlined />,
                permissions: [PERMISSIONS.OPERATION_EXPORT],
            },
            {
                name: t('common:menu.document_export'),
                url: 'documents.exporting.list',
                icon: <SnippetsOutlined />,
                permissions: [PERMISSIONS.OPERATION_EXPORT, PERMISSIONS.OPERATION_HISTORY_EXPORT],
            },
        ],
    },
    {
        name: t('common:menu.operation_warehouse'),
        permissions: [
            PERMISSIONS.STOCK_VIEW,
            PERMISSIONS.WAREHOUSE_IMPORT_HISTORY,
            PERMISSIONS.WAREHOUSE_VIEW,
            PERMISSIONS.OPERATION_HISTORY_AUDIT_VIEW,
        ],
        submenu: [
            {
                name: t('common:menu.inventory_management'),
                icon: <CalculatorOutlined />,
                url: 'inventory-management',
                permissions: [PERMISSIONS.STOCK_VIEW],
            },
            {
                name: t('menu.warehouse_stocks'),
                url: 'inventories.warehouse-stocks',
                icon: <ShopOutlined />,
                permissions: [PERMISSIONS.STOCK_VIEW],
            },
            {
                name: t('common:menu.history_import_products'),
                icon: <BarChartOutlined />,
                url: 'import-history',
                permissions: [PERMISSIONS.WAREHOUSE_IMPORT_HISTORY],
            },
            {
                name: t('common:menu.warehouses'),
                icon: <ShopOutlined />,
                url: 'warehouses.list',
                permissions: [PERMISSIONS.WAREHOUSE_VIEW],
            },

            {
                name: t('common:menu.warehouse_areas'),
                icon: <BuildOutlined />,
                url: 'warehouse-areas',
                permissions: [PERMISSIONS.WAREHOUSE_VIEW],
            },
            {
                name: t('common:menu.document_inventory'),
                url: 'documents.inventory.list',
                icon: <EditOutlined />,
                permissions: [PERMISSIONS.OPERATION_HISTORY_AUDIT_VIEW],
            },
            {
                name: t('common:menu.move_products'),
                url: 'move_products',
                icon: <PullRequestOutlined />,
                permissions: [PERMISSIONS.OPERATION_ARRANGE],
            },
        ],
    },

    {
        name: t('common:menu.product_manager'),
        permissions: [
            PERMISSIONS.PRODUCT_VIEW_LIST,
            PERMISSIONS.CONFIG_CATEGORIES_VIEW,
            PERMISSIONS.SKU_VIEW_LIST_EXTERNAL_CODE,
            PERMISSIONS.PRODUCT_MANAGE_ALL,
        ],
        submenu: [
            {
                name: t('common:menu.products'),
                icon: <ProjectOutlined />,
                url: 'products.list',
                permissions: [PERMISSIONS.PRODUCT_VIEW_LIST, PERMISSIONS.PRODUCT_MANAGE_ALL],
            },
            {
                name: t('common:menu.variants'),
                icon: <AppstoreOutlined />,
                url: 'variants.list',
                permissions: [PERMISSIONS.PRODUCT_VIEW_LIST, PERMISSIONS.PRODUCT_MANAGE_ALL],
            },
            {
                name: t('common:menu.product_dropshipping_list'),
                icon: <BlockOutlined />,
                url: 'dropshipping.list',
                permissions: [PERMISSIONS.PRODUCT_VIEW_LIST],
            },
            {
                name: t('common:menu.product_categories'),
                icon: <UnorderedListOutlined />,
                url: 'products.categories',
                permissions: [PERMISSIONS.CONFIG_CATEGORIES_VIEW],
            },

            {
                name: t('common:menu.synchronous_products'),
                icon: <SyncOutlined />,
                url: 'config.synchronous',
                permissions: [PERMISSIONS.SKU_VIEW_LIST_EXTERNAL_CODE],
            },
        ],
    },
    {
        name: t('common:menu.finances'),
        permissions: [PERMISSIONS.FINANCE_VIEW_SELLER_REPORT, PERMISSIONS.FINANCE_VIEW_STATEMENT],
        submenu: [
            {
                name: t('common:menu.order_financial_management'),
                icon: <BarChartOutlined />,
                url: 'finances.statistic',
                permissions: [PERMISSIONS.FINANCE_VIEW_SELLER_REPORT],
            },
            {
                name: t('common:menu.order_financial_control'),
                icon: <DotChartOutlined />,
                url: 'finances.control.list',
                permissions: [PERMISSIONS.FINANCE_VIEW_STATEMENT],
            },
            {
                name: t('common:menu.debts_shipping_unit'),
                icon: <ContainerOutlined />,
                url: 'finances.debts-shipping',
                permissions: [PERMISSIONS.FINANCE_VIEW_STATEMENT],
            },
            {
                name: t('common:menu.finance_control_delivery'),
                icon: <GroupOutlined />,
                url: 'finances.control-delivery.list',
                permissions: [PERMISSIONS.FINANCE_VIEW_STATEMENT],
            }
        ],
    },

    {
        name: t('common:menu.configuration'),
        permissions: [
            PERMISSIONS.MERCHANT_VIEW,
            PERMISSIONS.USER_MERCHANT_ADD,
            PERMISSIONS.MERCHANT_CONNECT_PURCHASING,
            PERMISSIONS.MERCHANT_MANAGE_STORE,
            PERMISSIONS.SERVICE_VIEW,
            PERMISSIONS.ADMIN_CHANGE_BANNER,
            PERMISSIONS.ADMIN_SET_ORDER_FLOW,
        ],
        submenu: [
            {
                name: t('common:menu.list_merchants'),
                icon: <ApartmentOutlined />,
                url: 'config.merchants.list',
                permissions: [PERMISSIONS.MERCHANT_VIEW],
            },
            {
                name: t('common:menu.list_services'),
                icon: <UngroupOutlined />,
                url: 'config.manager-services',
                permissions: [PERMISSIONS.SERVICE_VIEW],
            },
            {
                name: t('common:menu.operation_management'),
                icon: <SettingOutlined />,
                url: 'config.user-operation',
                permissions: [PERMISSIONS.USER_MERCHANT_VIEW, PERMISSIONS.USER_MERCHANT_ADD],
            },
            {
                name: t('menu.manage_purchasing_account'),
                icon: <UserOutlined />,
                url: 'config.purchasing-accounts',
                permissions: [PERMISSIONS.MERCHANT_CONNECT_PURCHASING],
            },
            {
                name: t('common:menu.connected_account'),
                icon: <LinkOutlined />,
                url: 'config.connected-accounts',
                permissions: [PERMISSIONS.MERCHANT_MANAGE_STORE],
            },
            {
                name: t('common:menu.supplier_list'),
                icon: <SolutionOutlined />,
                url: 'config.supplier.list',
                permissions: [PERMISSIONS.OPERATION_VIEW_SUPPLIER],
            },
            {
                name: t('common:menu.configuration'),
                icon: <DeploymentUnitOutlined />,
                url: 'config.operation-config',
                permissions: [PERMISSIONS.ADMIN_CHANGE_BANNER, PERMISSIONS.ADMIN_SET_ORDER_FLOW],
            },
        ],
    },
];
export default menu;
