import React, {useState} from "react";
import ImportExcel from "../../../App/Upload/ImportExcel";
import lodash from "lodash";
import {t} from "../../../../system/i18n";
import errorCodes from "../../../App/Error/errorCodes";
import api from "../../services/api";
import {events} from "../../../../system/events";
import {EVENTS} from "../../services/constants";
import PERMISSIONS from "../../../Auth/services/permissions";
import {auth} from "../../../Auth/services";

// Mã lỗi của riêng endpoint này
const localCodes = {};

export default function ImportSynchronousProduct () {
    const [messages, setMessages] = useState([]);
    const ERROR_CODES = {...errorCodes, ...localCodes};

    function renderError(error) {
        const line = lodash.get(error, "line", "");
        const content = lodash.get(error, "errors", {});
        const file = lodash.get(error, "file", "");
        let errorMessages = [];
        if (file === "empty") {
            return t("synchronousProduct:message.file_empty");
        } else {
            Object.entries(content).forEach(entry => {
                let [key, errors] = entry;
                Object.values(ERROR_CODES).forEach(errorKey => {
                    if (errors.hasOwnProperty(errorKey)) {
                        errorMessages.push(t(`synchronousProduct:message.${errorKey}`, {
                            attribute: t(`synchronousProduct:label.${key}`)
                        }));
                    }
                });
            });

            if (errorMessages.length > 0)
                return t("synchronousProduct:message.line", {line: line, content: errorMessages.join(" ")});
        }

        return t("synchronousProduct:message.unknown_error");
    }

    function dispatch(payload) {
        events.dispatch(EVENTS.IMPORT_SKU_FOBIZ_FROM_EXCEL, {});
    }

    return (
        auth.can(PERMISSIONS.SKU_CONFIG_EXTERNAL_CODE) &&
        <ImportExcel
            modal={{
                buttonName: t("synchronousProduct:btn.import_synchronous_product"),
                title: t("synchronousProduct:title.title_popup")
            }}
            file={{
                name: "file_mau_cau_hinh_sku",
                updatedAt: "28/05/2021"
            }}
            messages={messages}
            setMessages={setMessages}
            renderError={renderError}
            apiService={api.importFobizCode}
            dispatch={dispatch}
        />
    );
}
