import { every, find, flattenDeep, forEach, get, isArray, isEmpty, map, merge, mergeWith, reduce } from 'lodash'

import { scanReturnOrderStorage } from '@Modules/App/services'
import { updateCollectionItem } from '@Modules/App/services/helpers'

import { getVar } from '@System/support/helpers'

export const ACTIONS_SCAN_RETURN_ORDER = {
    NEW_SCAN: 'ACTIONS_SCAN_RETURN_ORDER.NEW_SCAN',
    UPDATE_SCAN_INFO: 'ACTIONS_SCAN_RETURN_ORDER.UPDATE',
    DELETE_RETURN_ORDERS: 'ACTIONS_SCAN_RETURN_ORDER.DELETE_RETURN_ORDERS',
    ADD_RETURN_ORDER: 'ACTIONS_SCAN_RETURN_ORDER.ADD_RETURN_ORDER',
    UPDATE_RECEIVED_QUANTITY: 'ACTIONS_SCAN_RETURN_ORDER.UPDATE_RECEIVED_QUANTITY',
    UPDATE_SERVICES: 'ACTIONS_UPDATE_SERVICES',
    IS_SAVED: 'ACTIONS_SCAN_RETURN_ORDER.IS_SAVED',
    UPDATE_SERVICES_ON_START: 'UPDATE_SERVICES_ON_START',
}

export default function reducer(state, action) {
    let newState
    let { payload } = action
    let skus = getVar(state, 'skus', [])
    switch (action.type) {
        case ACTIONS_SCAN_RETURN_ORDER.NEW_SCAN:
            newState = { ...payload }
            break
        case ACTIONS_SCAN_RETURN_ORDER.UPDATE_SCAN_INFO:
            newState = { ...state, ...action.payload }
            break
        case ACTIONS_SCAN_RETURN_ORDER.ADD_RETURN_ORDER:
            newState = addReturnOrder(state, payload)
            break
        case ACTIONS_SCAN_RETURN_ORDER.UPDATE_RECEIVED_QUANTITY:
            newState = updateReceivedQuantity(state, payload)
            break
        case ACTIONS_SCAN_RETURN_ORDER.DELETE_RETURN_ORDERS:
            newState = deleteReturnOrders(state, payload)
            break
        case ACTIONS_SCAN_RETURN_ORDER.IS_SAVED:
            newState = { ...state, isSaved: true }
            break
        case ACTIONS_SCAN_RETURN_ORDER.UPDATE_SERVICES:
            newState = updateServices(state, payload)
            break
        case ACTIONS_SCAN_RETURN_ORDER.UPDATE_SERVICES_ON_START:
            newState = updateServiceOnStart(state, payload)
            break

        default:
            newState = { ...state }
            break
    }
    scanReturnOrderStorage.setValue(newState)
    return newState
}

function addReturnOrder(state, payload) {
    const returnOrders = getVar(state, 'return_orders', [])
    const newReturnOrders = [...returnOrders]
    newReturnOrders.push(payload)

    const newScanOrder = map(newReturnOrders, item => {
        const skus = get(item, 'skus.skus')
        const newSkus = map(skus, val => ({ ...val, receiverQuantity: val?.quantity }))
        return {
            ...item,
            skus: {
                ...item.skus,
                skus: newSkus,
            },
        }
    })
    const skus = calculatorTotalSku(newScanOrder)
    return { ...state, return_orders: newScanOrder, skus }
}

function deleteReturnOrders(state, freightBillCodes) {
    const returnOrders = getVar(state, 'return_orders', [])
    const dataDelete = []
    const newData = []
    map(returnOrders, item => {
        const id = getVar(item, 'freight_bill.freight_bill_code', '')
        if (freightBillCodes.includes(id)) {
            dataDelete.push(item)
        } else {
            newData.push(item)
        }
    })
    const skus = calculatorTotalSku(newData)
    return { ...state, return_orders: newData, skus }
}

function getDistinctSkus(data) {
    return reduce(
        data,
        function (result, item, key) {
            const skus = getVar(item, 'skus.skus', [])
            skus.map(skuInfo => {
                const sku_code = getVar(skuInfo, 'code')
                const skuExist = find(result, ['code', sku_code])
                const quantity = getVar(skuInfo, 'quantity', 0)
                if (!isEmpty(skuExist)) {
                    const skuQuantity = getVar(skuExist, 'quantity', 0)
                    result = updateCollectionItem(
                        result,
                        sku_code,
                        {
                            quantity: parseInt(quantity) + parseInt(skuQuantity),
                        },
                        'code'
                    )
                } else {
                    result.push(skuInfo)
                }
            })
            return result
        },
        []
    )
}

function updateServices(state, payload) {
    const { orderIds, pricesInfo } = payload
    const returnOrders = getVar(state, 'return_orders', [])
    const data = map(returnOrders, item => {
        const orderId = getVar(item, 'order.id', '')
        if (orderIds.includes(orderId)) {
            return { ...item, import_return_goods_service_prices: pricesInfo }
        }
        return item
    })
    return { ...state, return_orders: data }
}

const updateReceivedQuantity = (state, payload) => {
    const returnOrder = get(state, 'return_orders', [])
    const skus = get(state, 'skus', [])
    const { receiverQuantity, skuId, orderId, serial_number } = payload

    const newReturnOrders = map(returnOrder, item => {
        const orderPerLine = get(item, 'order.id')
        if (orderId === orderPerLine) {
            item.skus = {
                ...item.skus,
                skus: updateReceivedQuantitySkus(item.skus.skus, skuId, receiverQuantity, serial_number),
                // serial_number: updateSerialSkus(item.skus.skus, skuId, serial_number),
            }
        }

        return item
    })
    const newSkus = updateLocalReceivedQuantitySkus(skus, newReturnOrders)

    const errors = onCheckError(newReturnOrders)
    const newState = {
        ...state,
        return_orders: newReturnOrders,
        skus: newSkus,
        errors,
    }

    return newState
}

const updateReceivedQuantitySkus = (skus, skuId, receiverQuantity, serialNumber) => {
    return updateCollectionItem(skus, skuId, { receiverQuantity: receiverQuantity, serial_number: serialNumber }, 'id')
}

const updateLocalReceivedQuantitySkus = (skus, returnOrders) => {
    const skusOrder = flattenDeep(returnOrders.map(item => item?.skus?.skus))

    const skusReal = Object.values(
        skusOrder.reduce((acc, item) => {
            acc[item.id] = acc[item.id] ? { ...item, receiverQuantity: item.receiverQuantity + acc[item.id].receiverQuantity } : item
            return acc
        }, {})
    )

    const newSkus = skus.map(item => {
        skusReal.forEach(re => {
            if (re.id === item.id) {
                if (item.is_serial_number) {
                    item.receiverQuantity = re?.serial_number?.length || 0
                    item.serial_number = re.serial_number
                } else {
                    item.receiverQuantity = re.receiverQuantity
                }
            }
        })

        return {
            ...item,
        }
    })
    return newSkus
}

const calculatorTotalSku = payload => {
    const skusReturnOrder = flattenDeep(map(payload, item => item?.skus?.skus))
    const newSkus = Object.values(
        skusReturnOrder.reduce((acc, item) => {
            acc[item?.id] = acc[item?.id]
                ? {
                      ...item,
                      receiverQuantity: item?.receiverQuantity + acc[item?.id].receiverQuantity,
                      quantity: item?.quantity + acc[item?.id].quantity,
                  }
                : item
            return acc
        }, {})
    )

    return newSkus
}

const onCheckError = data => {
    const errs = map(data, item => {
        const skuElert = item?.skus?.skus.map(sku => ({ alert: sku?.receiverQuantity > sku?.quantity }))
        return skuElert
    })

    return errs
}

const updateServiceOnStart = (state, payload) => {
    const { document_importing_return_goods } = payload
    const returnOrders = getVar(state, 'return_orders', [])
    const data = map(returnOrders, item => {
        const orderId = getVar(item, 'order.id', '')
        forEach(document_importing_return_goods, val => {
            const newOrderId = getVar(val, 'order.id', '')
            if (orderId === newOrderId) {
                item = { ...item, import_return_goods_service_prices: get(val, 'import_return_goods_service_prices') }
            }
        })

        return item
    })

    return { ...state, return_orders: data }
}
