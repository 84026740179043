/* eslint-disable import/no-anonymous-default-export */
export default {
    ORDER_VIEW_LIST: 'ORDER:VIEW_LIST',
    ORDER_CREATE: 'ORDER:CREATE',
    ORDER_PACKAGED: 'ORDER:PACKAGED',
    ORDER_VIEW_DETAIL: 'ORDER:VIEW_DETAIL',
    ORDER_UPDATE: 'ORDER:UPDATE',
    ORDER_REMOVE_FAILED_ORDER: 'ORDER:REMOVE_FAILED_ORDER',
    ORDER_UPDATE_CARRIER: 'ORDER:UPDATE_CARRIER',
    ORDER_VIEW_LIST_ORIGINAL: 'ORDER:VIEW_LIST_ORIGINAL',
    ORDER_VIEW_DETAIL_ORIGINAL: 'ORDER:VIEW_DETAIL_ORIGINAL',
    PRODUCT_VIEW_LIST: 'PRODUCT:VIEW_LIST',
    PRODUCT_CREATE: 'PRODUCT:CREATE',
    SKU_IMPORT_STOCK: 'SKU:IMPORT_STOCK',
    SKU_IMPORT_PRICE: 'SKU:IMPORT_PRICE',
    PRODUCT_VIEW_DETAIL: 'PRODUCT:VIEW_DETAIL',
    PRODUCT_UPDATE: 'PRODUCT:UPDATE',
    SKU_CONFIG_EXTERNAL_CODE: 'SKU:CONFIG_EXTERNAL_CODE',
    SKU_VIEW_LIST_EXTERNAL_CODE: 'SKU:VIEW_LIST_EXTERNAL_CODE',
    WAREHOUSE_VIEW: 'WAREHOUSE:VIEW',
    WAREHOUSE_CREATE: 'WAREHOUSE:CREATE',
    WAREHOUSE_UPDATE: 'WAREHOUSE:UPDATE',
    WAREHOUSE_CREATE_AREA: 'WAREHOUSE:CREATE_AREA',
    STOCK_VIEW: 'STOCK:VIEW',
    WAREHOUSE_IMPORT_HISTORY: 'WAREHOUSE:IMPORT_HISTORY',
    MERCHANT_VIEW: 'MERCHANT:VIEW',
    MERCHANT_CREATE: 'MERCHANT:CREATE',
    MERCHANT_UPDATE: 'MERCHANT:UPDATE',
    MERCHANT_CONNECT_SHOP_BASE: 'MERCHANT:CONNECT_SHOP_BASE',
    MERCHANT_CONNECT_PURCHASING: 'MERCHANT:CONNECT_PURCHASING',
    MERCHANT_MANAGE_STORE: 'MERCHANT:MANAGE_STORE',
    CONFIG_CATEGORIES_UPDATE: 'CONFIG:CATEGORIES_UPDATE',
    CONFIG_CATEGORIES_VIEW: 'CONFIG:CATEGORIES_VIEW',
    USER_MERCHANT_VIEW: 'USER_MERCHANT:VIEW',
    USER_MERCHANT_ADD: 'USER_MERCHANT:ADD',
    DELIVERY_NOTE_CREATE: 'DELIVERY_NOTE:CREATE',
    DELIVERY_NOTE_VIEW: 'DELIVERY_NOTE:VIEW',
    ORDER_IMPORT_FREIGHT_BILL: 'ORDER:IMPORT_FREIGHT_BILL',
    ORDER_IMPORT_STATUS: 'ORDER:IMPORT_STATUS',
    OPERATION_PREPARATION: 'OPERATION:PREPARATION',
    OPERATION_HISTORY_PREPARATION: 'OPERATION:HISTORY:PREPARATION',
    OPERATION_EXPORT: 'OPERATION:EXPORT',
    OPERATION_HISTORY_EXPORT: 'OPERATION:HISTORY:EXPORT',
    OPERATION_IMPORT: 'OPERATION:IMPORT',
    OPERATION_HISTORY_IMPORT: 'OPERATION:HISTORY:IMPORT',
    OPERATION_HISTORY_AUDIT_VIEW: 'OPERATION:HISTORY:AUDIT:VIEW',
    OPERATION_HISTORY_AUDIT_EDIT: 'OPERATION:HISTORY:AUDIT:EDIT',
    OPERATION_HISTORY_AUDIT_CONFIRM: 'OPERATION:HISTORY:AUDIT:CONFIRM',
    OPERATION_PRINT_BILL: 'OPERATION:PRINT_BILL',
    OPERATION_VIEW_LIST_ORIGINAL: 'OPERATION:VIEW_LIST_ORIGINAL',
    MERCHANT_PURCHASING_ORDER_ALL: 'MERCHANT:PURCHASING_ORDER:ALL',
    MERCHANT_PURCHASING_ORDER_ASSIGNED: 'MERCHANT:PURCHASING_ORDER:ASSIGNED',
    ORDER_VIEW_FAILED_ORDER: 'ORDER:VIEW_FAILED_ORDER',
    OPERATION_SCAN_RTS: 'OPERATION:SCAN_RTS',
    FINANCE_VIEW_INBOUND_SHIPMENT: 'FINANCE:VIEW_INBOUND_SHIPMENT',
    ORDER_CHANGE_FINANCIAL_STATUS: 'ORDER:CHANGE_FINANCIAL_STATUS',
    ORDER_CANCEL_FREIGHT_BILL: 'ORDER:CANCEL_FREIGHT_BILL',
    PRODUCT_MANAGE_ALL: 'PRODUCT:MANAGE_ALL',
    FINANCE_VIEW_SELLER_REPORT: 'FINANCE:VIEW_SELLER_REPORT',
    FINANCE_VIEW_STATEMENT: 'FINANCE:VIEW_STATEMENT',
    OPERATION_VIEW_REPORT: 'OPERATION:VIEW_REPORT',
    FINANCE_CREATE_STATEMENT: 'FINANCE:CREATE_STATEMENT',
    FINANCE_CONFIRM_STATEMENT: 'FINANCE:CONFIRM_STATEMENT',
    FINANCE_VIEW_SELLER_WALLET: 'FINANCE:VIEW_SELLER_WALLET',
    FINANCE_EDIT_SELLER_WALLET: 'FINANCE:EDIT_SELLER_WALLET',
    SKU_UPDATE: 'SKU:UPDATE',
    QUOTATION_CREATE: 'QUOTATION:CREATE',
    QUOTATION_CANCEL: 'QUOTATION:CANCEL',
    SERVICE_VIEW: 'SERVICE:VIEW',
    SERVICE_ADD: 'SERVICE:ADD',
    SERVICE_STOP: 'SERVICE:STOP',
    OPERATION_PICKING: 'OPERATION:PICKING',
    OPERATION_ARRANGE: 'OPERATION:ARRANGE',
    ADMIN_CHANGE_BANNER: 'ADMIN:CHANGE_BANNER',
    OPERATION_SCAN_AFTER_PACKAGED: 'OPERATION:SCAN_AFTER_PACKAGED',
    ADMIN_SET_ORDER_FLOW: 'ADMIN:SET_ORDER_FLOW',
    FINANCE_CREATE_DELIVERY_STATEMENT: 'FINANCE:CREATE_DELIVERY_STATEMENT', //tạo chứng từ đối soát giao nhận vận chuyển
    FINANCE_VIEW_DELIVERY_STATEMENT: 'FINANCE:VIEW_DELIVERY_STATEMENT', //xem chứng từ đối soát giao nhận vận chuyển
    FINANCE_SHIPPING_PARTNER_EXPECTED_TRANSPORTING_PRICES_CONFIG: 'FINANCE:SHIPPING_PARTNER_EXPECTED_TRANSPORTING_PRICES_CONFIG',
    ADMIN_SYSTEM_CONFIG: 'ADMIN:SYSTEM_CONFIG',
    ADMIN_PACKAGE_CREATE: 'ADMIN:PACKAGE_CREATE',
    OPERATION_VIEW_SUPPLIER: 'OPERATION:VIEW_SUPPLIER',
    ADMIN_UPDATE_SUPPLIER: 'ADMIN:UPDATE_SUPPLIER',
    ADMIN_CREATE_SUPPLIER: 'ADMIN:CREATE_SUPPLIER',
    FINANCE_CREATE_SUPPLIER_TRANSACTION: 'FINANCE:CREATE_SUPPLIER_TRANSACTION',
    ADMIN_ASSIGN_SUPPLIER: "ADMIN:ASSIGN_SUPPLIER",
    HIDE_SETTING_UPDATE:"hide_setting:update"
}
