import { Avatar, Button, Tooltip, Typography } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import useSize from '@Modules/App/Hooks/useSize';
import StyledTable from '@Modules/App/Table/StyledTable';

import { getVar } from '@System/support/helpers';

import moment from "moment";
import { t } from '../../../../../../system/i18n';
import { Link } from '../../../../../../system/routing';
import Badge from '../../../../../App/Badge';
import Space from '../../../../../App/Space';
import { SHIPPING_PARTNER_INFO } from '../../../../../App/services/constants';
import { ORDER_STATUSES, SCAN_TYPE } from './../../../../constants';

const { Paragraph, Title } = Typography;

const OrderPackingList = ({
    isExportForGoods,
    orderPackings,
    packingDocument,
    onDelete,
    pagination,
    filters,
    setFilters,
    loadingOrderPackingList,
    validOrderExportingIds,
}) => {
    const { isLarge } = useSize();

    const columns = useMemo(() => {
        const columns = [
            {
                className: '_index-column',
                title: t('table.#'),
                key: '#',
                render: (_, __, index) => (pagination?.current_page - 1) * pagination?.per_page + index + 1,
            },
            {
                className: '_freight-bill-column',
                title: t('freight_bill_code'),
                key: 'freight_bill',
                dataIndex: 'freight_bill',
            },
            {
                className: '_shipping-partner-column',
                key: 'shipping_partner',
                dataIndex: 'shipping_partner',
                width: '0px',
                render: text => {
                    const code = get(text, 'code', '');
                    const shipping_partner_info = get(SHIPPING_PARTNER_INFO, code, {});
                    return (
                        <Tooltip title={text?.name}>
                            <Avatar
                                style={{ backgroundColor: `${get(shipping_partner_info, 'color', '')}` }}
                                className="flex-shrink-0"
                                size={20}
                            >
                                {get(shipping_partner_info, 'wildcard', '')}
                            </Avatar>
                        </Tooltip>
                    );
                },
            },
            {
                className: '_order-column',
                title: t('order_code'),
                key: 'order_code',
                dataIndex: ['order', 'code'],
                render: (text, record) => (
                    <Link
                        isNewTab
                        params={{
                            id: record.order_id,
                        }}
                        to="orders.detail"
                    >
                        {text}
                    </Link>
                ),
            },
            {
                className: '_sku-column',
                title: t('SKU'),
                key: 'SKU',
                dataIndex: ['order', 'order_skus'],
                render: text =>
                    map(text, skus =>{
                        return  (
                            <Paragraph className="mb-0" key={skus.id}>
                                <Link
                                    isNewTab
                                    params={{
                                        id: skus?.product_id,
                                    }}
                                    to="products.detail"
                                >
                                    {skus?.code}
                                </Link>
                            </Paragraph>
                        )
                    }),
            },
            {
                className: '_quantity-column',
                title: t('quantity'),
                key: 'quantity',
                dataIndex: ['order', 'order_skus'],
                render: text =>
                    map(text, skus => (
                        <Paragraph className="mb-0" key={skus.id}>
                        {get(skus, 'quantity')}
                    </Paragraph>
                    )),
            },
            {
                className: '_scan-time-column',
                title: t('scan_time'),
                key: 'scan_time',
                dataIndex: ['pivot', 'created_at'],
                render: (text) => {
                    return text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : null
                },
            },
            {
                className: '_status-order-exporting-column',
                title: t('export_request'),
                key: 'status',
                dataIndex: 'status_order_exporting',
                render: text => (
                    <Badge color={ORDER_STATUSES[text?.toUpperCase()]?.color} text={t(`document:order_statuses.${text}`)} />
                ),
            },
        ];

        if (isExportForGoods) {
            columns.push({
                key: 'action',
                render: (_, record) => {
                    const order_exporting_id = getVar(record, 'order_exporting_id', undefined);
                    if (validOrderExportingIds.includes(order_exporting_id)) {
                        return (
                            <Button className="_delete-btn" type="primary" onClick={onDelete.bind(undefined, record)}>
                                {t('btn.delete')}
                            </Button>
                        );
                    } else {
                        return null;
                    }
                },
            });
        }

        if (packingDocument?.document_packing?.info?.barcode_type === SCAN_TYPE.ORDER) {
            columns.splice(1, 1);
        }

        return columns;
    }, [isExportForGoods, packingDocument, pagination, onDelete]);

    function renderTitle() {
        if (packingDocument?.document_packing?.info?.barcode_type === SCAN_TYPE.FREIGHT_BILL) {
            return t('freight_bill_list');
        }

        if (packingDocument?.document_packing?.info?.barcode_type === SCAN_TYPE.ORDER) {
            return t('order_list');
        }

        return '';
    }
    return (
        <div>
            <Space className="mb-3" type="flex">
                <Title className="mb-0" level={5}>
                    {renderTitle()}
                </Title>
                <span className="number-circle">{pagination?.total}</span>
            </Space>

            <div>
                <StyledTable
                    rowClassName={record => {
                        const order_exporting_id = getVar(record, 'order_exporting_id', undefined);
                        return isExportForGoods
                            ? validOrderExportingIds.includes(order_exporting_id)
                                ? ''
                                : 'order-packing-disable'
                            : '';
                    }}
                    loading={loadingOrderPackingList}
                    columns={columns}
                    dataSource={orderPackings}
                    pagination={false}
                    rowKey="id"
                    scroll={{ x: 576 }}
                />
                <CustomizedPagination pagination={pagination} filters={filters} addFilter={setFilters} />
            </div>
        </div>
    );
};

OrderPackingList.propTypes = {
    orderPackings: PropTypes.array,
};

OrderPackingList.defaultProps = {
    orderPackings: [],
};

export default OrderPackingList;
