import { Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd';
import React from 'react';

import { trans } from './../../../../../system/i18n';
import { dateFormatter } from '../../../../App/services';
import { auth } from './../../../../Auth/services';
import permissions from './../../../../Auth/services/permissions';

const { Paragraph } = Typography;

const Header = ({ creating, exporting, form, isExportForGoods, packingDocument, onCreateExportingDocument, onExport }) => {
    return (
        <Form form={form}>
            {isExportForGoods ? (
                <div className="w-100">
                    <div className="d-flex mb-4 justify-content-md-between align-items-md-center">
                        <div className="mb-md-0 mb-3">
                            <Paragraph className="text-fz-16 mb-0">
                                {trans('document:export_warehouse_for_goods')}: #{packingDocument?.document_packing?.code}
                            </Paragraph>
                        </div>

                        <div>
                            <Button
                                className="_save-export-warehouse-info-btn"
                                loading={creating}
                                type="primary"
                                onClick={onCreateExportingDocument}
                            >
                                {trans('document:save_export_warehouse_info')}
                            </Button>
                        </div>
                    </div>

                    <div>
                        <Row gutter={72}>
                            <Col className="mb-3 mb-lg-0" lg={{ span: 15 }} span={24}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <div className="mb-2 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                                            {trans('receiver_info')}

                                            {/* <Form.Item
                                                className="mb-0"
                                                initialValue={true}
                                                name="save_receiver_info"
                                                valuePropName="checked"
                                            >
                                                <Checkbox>
                                                    {trans('save_receiver_info')}
                                                </Checkbox>
                                            </Form.Item> */}
                                        </div>
                                    </Col>

                                    <Col className="mb-3 mb-lg-0" md={{ span: 8 }} span={24}>
                                        <Form.Item className="mb-0" name="receiver_name">
                                            <Input
                                                className="_receiver-name"
                                                placeholder={trans('receiver')}
                                                tabIndex={1}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col className="mb-3 mb-lg-0" md={{ span: 8 }} span={24}>
                                        <Form.Item className="mb-0" name="receiver_phone">
                                            <Input
                                                className="_receiver-phone"
                                                placeholder={trans('receiver_phone')}
                                                tabIndex={2}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col md={{ span: 8 }} span={24}>
                                        <Form.Item className="mb-0" name="receiver_license">
                                            <Input
                                                className="_license"
                                                placeholder={trans('document:placeholder.license')}
                                                tabIndex={3}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={{ span: 9 }} span={24}>
                                <div className="mb-2 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                                    {trans('receive_company')}

                                    {/* <Form.Item
                                        className="mb-0"
                                        initialValue={true}
                                        name="save_receiver_company_info"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>
                                            {trans('save_receive_company_info')}
                                        </Checkbox>
                                    </Form.Item> */}
                                </div>

                                <Form.Item className="mb-0" name="partner">
                                    <Input className="_partner" tabIndex={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            ) : (
                <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
                    <div className="mb-md-0 mb-3">
                        <Paragraph className="font-weight-bold text-fz-16 mb-0">
                            {trans('document:types.packing')}: #{packingDocument?.document_packing?.code}
                        </Paragraph>
                        <div>
                            {trans('document:confirm_by', {
                                date: dateFormatter.date(packingDocument?.document_packing.verified_at),
                                confirmer: packingDocument?.document_packing?.verifier_username || '--'
                            })}
                        </div>
                    </div>

                    <div>
                        {auth.can(permissions.OPERATION_EXPORT) && (
                            <Button className="_export-warehouse-btn" loading={exporting} type="primary" onClick={onExport}>
                                {trans('document:export_warehouse_all_according_to_this_document')}
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </Form>
    );
};

export default Header;
