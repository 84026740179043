import { Button, Form, Spin, Tag, Row, Col } from 'antd';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import useFilter from '@Modules/App/Hooks/useFilter';
import useLocalStorageState from '@Modules/App/Hooks/useLocalStorageState';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import useListPrepareOrderQuery from '@Modules/Order/Hooks/useListPrepareOrderQuery';
import ModalContinuePickup from '@Modules/Order/components/Pickup/ModalContinuePickup';
import PickingUpProduct from '@Modules/Order/components/Pickup/PickingUpProduct';
import api from '@Modules/Order/services/api';
import { ORDER_PACKING_STATUS, ORDER_TAGS } from '@Modules/Order/services/constants';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

const PickupWaitingList = ({ history, location }) => {
    const user = auth.user();
    const [warehouseId, setWarehouseId] = useLocalStorageState('warehouse');

    const [visibleWarning, setVisibleWarning] = useState(false);
    const [visible, setVisible] = useState(false);
    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({});
    const [processPicking, setProcessPicking] = useState();


    const { filters, addFilter } = useFilter(history, location, {
        warehouse_id: warehouseId,
    });
    const { data, isFetching, refetch } = useListPrepareOrderQuery({
        ...filters,
        sort_by: 'grant_picker_at',
        sort: 'asc',
        picker_id: user.id,
        picking_session_id: 0,
        status: ORDER_PACKING_STATUS.WAITING_PICKING.key,
    });

    useEffect(() => {
        setNavigator(t('title.pickup_waiting_list'), [
            {
                name: t('common:breadcrumb.orders'),
                route: 'orders.list',
            },
            {
                name: t('breadcrumb.pickup_waiting_list'),
            },
        ]);
        setDocumentTitle(t('title.pickup_waiting_list'));
    }, []);

    useEffect(() => {
        if (filters?.warehouse_id) {
            refetch();
        }
    }, [filters, refetch]);

    useEffect(() => {
        setOrders(getVar(data, 'data.order_packings', []));
        setPagination(getVar(data, 'data.pagination', {}));
    }, [data]);

    useEffect(() => {
        api.processPickingSession({ warehouse_id: warehouseId })
            .then(res => {
                setProcessPicking(get(res, 'data.picking_session'));
                if (!isEmpty(get(res, 'data.picking_session'))) {
                    setVisibleWarning(true);
                }
            })
            .catch(err => processResponseError(err));
    }, [warehouseId]);

    const handleVisibleDrawler = () => {
        if (isEmpty(processPicking)) {
            setVisible(true);
        } else {
            setVisibleWarning(true);
        }
    };

    function handleChangeWarehouse(value) {
        addFilter({ ...filters, warehouse_id: value });
        setWarehouseId(value);
    }

    return (
        <div className="site-content ">
            <div className="bg-white rounded-12 p-4">
                <h3 className="text-fz-18 mb-3">
                    {t('order:title.pickup_waiting_list')}
                    <span className="number-circle _importing-document-product-list-total">
                        {getVar(pagination, 'total', 0)}
                    </span>
                </h3>

                <Form.Item
                    className="mt-2"
                    label={t('order:label.export_warehouse')}
                    required={true}
                    validateStatus={!warehouseId && 'error'}
                    help={!warehouseId && t('order:message.required', { attribute: t('order:label.export_warehouse') })}
                >
                    <SelectWarehouse
                        className={'_prepare-order-search-warehouse-id'}
                        placeholder={t('order:placeholder.export_warehouse')}
                        value={filters?.warehouse_id && parseInt(filters?.warehouse_id)}
                        onChange={handleChangeWarehouse}
                    />
                </Form.Item>

                {warehouseId && orders.length > 0 && (
                    <Button type="primary" onClick={handleVisibleDrawler}>
                        {t('order:btn.start_picking_order')}
                    </Button>
                )}
                <div className="pb-4">
                    <Spin spinning={isFetching} className="text-center p-4">
                        {orders.length > 0 ? (
                            <Row gutter={24}>
                                {orders.map((item, index) => {
                                    const order_id = getVar(item, 'order_id', undefined);
                                    const order_code = getVar(item, 'order.code', undefined);
                                    const tags = getVar(item, 'tags', []);
                                    const order_packing_items = getVar(item, 'order_packing_items', []);
                                    return (
                                        <Col key={index} className="order-box-list" xs={{ span: 24 }} lg={{ span: 6 }}>
                                            <div className="order-box-list__item mt-4">
                                                <p>
                                                    <b>{t('order:label.order')}: </b>
                                                    <Link
                                                        target="_blank"
                                                        to={url.to('orders.detail', { id: order_id })}
                                                    >
                                                        {order_code}
                                                    </Link>
                                                </p>
                                                <div className={order_packing_items.length <= 1 ? 'd-flex' : undefined}>
                                                    <b>SKU:</b>
                                                    {order_packing_items.map((order_packing_item, index) => {
                                                        const sku_code = getVar(order_packing_item, 'sku_code');
                                                        const quantity = getVar(order_packing_item, 'quantity', 0);
                                                        return (
                                                            <p
                                                                className="ml-1"
                                                                key={index}
                                                            >{`${sku_code} x ${quantity}`}</p>
                                                        );
                                                    })}
                                                </div>
                                                <div className="d-flex mt-1">
                                                    {tags.map((tag, ind) => {
                                                        const tagInfo = getVar(ORDER_TAGS, tag, {});
                                                        if (!isEmpty(tagInfo)) {
                                                            return (
                                                                <Tag className="mr-1" key={ind} color={tagInfo.color}>
                                                                    {t(`order:label.tag.${tag}`)}
                                                                </Tag>
                                                            );
                                                        }
                                                        return false;
                                                    })}
                                                </div>
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>
                        ) : (
                            <div className="d-flex justify-content-center">
                                <div className="text-center">
                                    <TableEmpty loading={isFetching} />
                                </div>
                            </div>
                        )}
                    </Spin>
                </div>
                <CustomizedPagination filters={filters} addFilter={addFilter} pagination={pagination} />
            </div>
            {warehouseId && visible && (
                <PickingUpProduct
                    setVisible={setVisible}
                    visible={visible}
                    totalOrders={getVar(pagination, 'total', 0)}
                    warehouseId={warehouseId}
                />
            )}
            {visibleWarning && (
                <ModalContinuePickup setVisibleWarning={setVisibleWarning} processPicking={processPicking} />
            )}
        </div>
    );
};

export default PickupWaitingList;
