import { UndoOutlined, SearchOutlined } from '@ant-design/icons'
import { Form, Row, DatePicker, Col, Button, Input, Select } from 'antd'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useState, useEffect } from 'react'

import { auth } from '@Modules/Auth/services'
import PERMISSIONS from '@Modules/Auth/services/permissions'
import SelectStaff from '@Modules/Order/components/SelectStaff'
import SelectStore from '@Modules/Order/components/SelectStore'
import { ORDER_TAGS } from '@Modules/Order/services/constants'
import SelectMobileDevice from '@Modules/WarehouseArea/components/SelectMobileDevice'

import { getVar } from '@System/support/helpers'

import { t } from '../../../../system/i18n'
import SelectMerchants from '../../../Merchant/components/SelectMerchants'
import SelectShippingPartner from '../../../ShippingPartners/components/SelectShippingPartner'
import SelectWarehouse from '../../../Warehouse/components/SelectWarehouse'
import QuickFilter from './QuickFilter'

const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY'

function FormSearch({ addFilter, filters, loading, pagination, setting }) {
    const keyTags = Object.keys(ORDER_TAGS)
    const [errors, setErrors] = useState({})
    const [input, setInput] = useState(filters)
    const [tag, setTag] = useState(undefined)
    const {
        warehouse_id,
        order_code,
        shipping_partner_id,
        merchant_id,
        receiver_name,
        receiver_phone,
        product_code,
        freight_bill,
        picker_id,
        pickup_truck_id,
        store_id,
    } = input || {}

    const intended_delivery_at_from = get(input, 'intended_delivery_at[from]', '')
    const intended_delivery_at_to = get(input, 'intended_delivery_at[to]', '')
    const created_at_from = get(input, 'created_at[from]', '')
    const created_at_to = get(input, 'created_at[to]', '')

    useEffect(() => {
        setInput(filters)
        validateSearch(filters)
        keyTags.map(item => {
            const value = getVar(filters, item, undefined)
            if (value) setTag(item)
        })
    }, [filters])

    function _onChangeInput(e) {
        const name = e.target.name
        const value = e.target.value
        setInput(prevState => {
            return { ...prevState, ...input, [name]: value }
        })
    }

    function _onChangeSelect(name, value) {
        if (name) {
            let params = {}
            if (name === 'warehouse_id') {
                setErrors({ ...errors, warehouse_id: undefined })
            }
            let filters = {}
            if (name === 'clear_all_tag' || keyTags.includes(name)) {
                if (name === 'clear_all_tag') setTag(undefined)
                let input_tags = {}
                keyTags.map(item => (input_tags[item] = item === name ? value : undefined))
                filters = { ...input, ...params, ...input_tags }
            } else {
                filters = { ...input, ...params, [name]: value }
            }

            setInput(filters)
            _onSubmit(filters)
        }
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`
        const name_to = `${name}[to]`
        const filters = {
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        }
        setInput(filters)
        _onSubmit(filters)
    }

    function _onPressEnter(e) {
        const { name, value } = e.target
        _onSubmit({ ...input, [name]: value })
    }

    function _onSubmit(params) {
        const validate = validateSearch(params)
        if (validate) {
            addFilter(params)
        }
    }

    function validateSearch(filters) {
        const warehouse_id = get(filters, 'warehouse_id', undefined)
        if (!warehouse_id) {
            setErrors({
                ...errors,
                warehouse_id: t('validation:required', { attribute: t('order:label.export_warehouse') }),
            })
            return false
        }
        setErrors({})
        return true
    }

    function _onReset() {
        addFilter({})
        setTag(undefined)
    }

    return (
        <Form
            className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray"
            layout="vertical"
        >
            <Row gutter={20}>
                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 4 }}
                >
                    <Form.Item
                        label={t('order:label.export_warehouse')}
                        required={true}
                        validateStatus={isEmpty(get(errors, 'warehouse_id')) ? null : 'error'}
                        help={get(errors, 'warehouse_id', undefined)}
                    >
                        <SelectWarehouse
                            className={'_prepare-order-search-warehouse-id'}
                            allowClear={true}
                            placeholder={t('order:placeholder.export_warehouse')}
                            value={warehouse_id ? parseInt(warehouse_id) : undefined}
                            onChange={value => _onChangeSelect('warehouse_id', value)}
                        />
                    </Form.Item>
                </Col>

                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 4 }}
                >
                    <Form.Item
                        className="mb-3"
                        label={t('order:label.order_code')}
                    >
                        <Input
                            className={'_prepare-order-search-order-code'}
                            name="order_code"
                            value={order_code}
                            placeholder={t('order:placeholder.order_code')}
                            onChange={_onChangeInput}
                            onPressEnter={_onPressEnter}
                        />
                    </Form.Item>
                </Col>

                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 4 }}
                >
                    <Form.Item
                        className="mb-3"
                        label={t('product:label.product_code')}
                    >
                        <Input
                            name="product_code"
                            value={product_code}
                            className="_search-code"
                            placeholder={t('product:placeholder.product_code')}
                            onChange={_onChangeInput}
                            onPressEnter={_onPressEnter}
                        />
                    </Form.Item>
                </Col>
                {(!setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.OPERATION_VIEW_LIST_ORIGINAL))) && (
                    <Col
                        xs={{ span: 12 }}
                        lg={{ span: 4 }}
                    >
                        <Form.Item
                            className="mb-3"
                            label={t('order:label.seller_info')}
                        >
                            <SelectMerchants
                                className={'_prepare-order-search-merchant-id'}
                                placeholder={t('order:placeholder.seller_info')}
                                allowClear={true}
                                value={merchant_id ? parseInt(merchant_id) : undefined}
                                onChange={value => _onChangeSelect('merchant_id', value)}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 4 }}
                >
                    <Form.Item
                        className="mb-3"
                        label={t('order:label.shipping_partner_id')}
                    >
                        <SelectShippingPartner
                            className={'_prepare-order-search-shipping-partner-id'}
                            placeholder={t('order:placeholder.shipping_partner_id')}
                            allowClear={true}
                            value={shipping_partner_id ? parseInt(shipping_partner_id) : undefined}
                            onChange={value => _onChangeSelect('shipping_partner_id', value)}
                            all
                        />
                    </Form.Item>
                </Col>
                {(!setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.OPERATION_VIEW_LIST_ORIGINAL))) && (
                    <>
                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 4 }}
                        >
                            <Form.Item
                                className="mb-3"
                                label={t('order:label.receiver_name')}
                            >
                                <Input
                                    className={'_prepare-order-search-receiver-name'}
                                    name="receiver_name"
                                    value={receiver_name}
                                    placeholder={t('order:placeholder.receiver_name')}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onPressEnter}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 4 }}
                        >
                            <Form.Item
                                className="mb-3"
                                label={t('order:label.receiver_phone')}
                            >
                                <Input
                                    className={'_prepare-order-search-receiver-phone'}
                                    name="receiver_phone"
                                    value={receiver_phone}
                                    placeholder={t('order:placeholder.receiver_phone')}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onPressEnter}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 6 }}
                        >
                            <Form.Item
                                className="mb-3"
                                label={t('order:label.intended_delivery_at')}
                            >
                                <RangePicker
                                    className={'_prepare-order-search-intended-delivery-at'}
                                    value={
                                        intended_delivery_at_from && intended_delivery_at_to
                                            ? [moment(intended_delivery_at_from), moment(intended_delivery_at_to)]
                                            : undefined
                                    }
                                    format={dateFormat}
                                    placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                    onChange={date => _onChangeDate('intended_delivery_at', date)}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </>
                )}

                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 6 }}
                >
                    <Form.Item
                        className="mb-3"
                        label={t('order:label.created_at')}
                    >
                        <RangePicker
                            className={'_prepare-order-search-created-at'}
                            value={created_at_from && created_at_to ? [moment(created_at_from), moment(created_at_to)] : undefined}
                            format={dateFormat}
                            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                            onChange={date => _onChangeDate('created_at', date)}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 4 }}
                >
                    <Form.Item
                        className="mb-3"
                        label={t('label.freight_bill_code')}
                    >
                        <Input
                            className={'_prepare-order-search-freight-bill'}
                            name="freight_bill"
                            value={freight_bill}
                            placeholder={t('placeholder.freight_bill_code')}
                            onChange={_onChangeInput}
                            onPressEnter={_onPressEnter}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 4 }}
                >
                    <Form.Item
                        className="mb-3"
                        label={t('order:label.select_tag')}
                    >
                        <Select
                            allowClear={true}
                            placeholder={t('order:label.select_tag')}
                            onChange={value => _onChangeSelect(value ? value : 'clear_all_tag', 1)}
                            value={tag}
                        >
                            {keyTags.map((item, index) => {
                                return (
                                    <Select.Option
                                        key={index}
                                        value={item}
                                    >
                                        {t(`order:label.tag.${item}`)}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 4 }}
                >
                    <Form.Item
                        className="mb-3"
                        label={t('order:label.picker')}
                    >
                        <SelectStaff
                            allowClear={true}
                            placeholder={t('order:placeholder.select_staff')}
                            value={picker_id ? parseInt(picker_id) : undefined}
                            onChange={value => _onChangeSelect('picker_id', value)}
                        />
                    </Form.Item>
                </Col>
                {warehouse_id && (
                    <Col
                        xs={{ span: 12 }}
                        lg={{ span: 4 }}
                    >
                        <Form.Item
                            className="mb-3"
                            label={t('order:label.mobile_device')}
                        >
                            <SelectMobileDevice
                                allowClear={true}
                                placeholder={t('order:placeholder.mobile_device')}
                                warehouseid={warehouse_id ? parseInt(warehouse_id) : undefined}
                                value={pickup_truck_id ? parseInt(pickup_truck_id) : undefined}
                                onChange={value => _onChangeSelect('pickup_truck_id', value)}
                            />
                        </Form.Item>
                    </Col>
                )}
                <Col
                    xs={{ span: 12 }}
                    lg={{ span: 4 }}
                >
                    <Form.Item
                        className="mb-3"
                        label={t('order:label.shopee_store')}
                    >
                        <SelectStore
                            allowClear={true}
                            placeholder={t('order:placeholder.shopee_store')}
                            value={store_id ? parseInt(store_id) : undefined}
                            onChange={value => _onChangeSelect('store_id', value)}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row className={'mt-4'}>
                <Col
                    xs={{ span: 24 }}
                    lg={{ span: 24 }}
                    className="text-right"
                >
                    <Button
                        className="_prepare-order-search-btn-reset"
                        onClick={_onReset}
                    >
                        <UndoOutlined /> {t('common:btn.reset')}
                    </Button>
                    <Button
                        loading={loading}
                        type="primary"
                        className="ml-2 _prepare-order-search-btn-search"
                        onClick={() => _onSubmit(input)}
                    >
                        <SearchOutlined /> {t('common:btn.search')}
                    </Button>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    lg={{ span: 24 }}
                    className="text-left d-flex mt-3"
                >
                    <QuickFilter
                        pagination={pagination}
                        filters={filters}
                        addFilter={addFilter}
                        validateSearch={validateSearch}
                        loading={loading}
                    />
                </Col>
            </Row>
        </Form>
    )
}

export default FormSearch
