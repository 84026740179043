import { CloseOutlined, DownOutlined, EditOutlined } from '@ant-design/icons'
import { Table, Space, Badge, Input, Row, Col, Typography, Menu, Dropdown, Form, InputNumber, Tooltip } from 'antd'
import { get, filter, isEmpty, map, find, flatMapDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Box from '@Modules/App/Box'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { pageSizeOptions } from '@Modules/App/services/constants'
import { getPageSizeScale } from '@Modules/App/services/helpers'
import api from '@Modules/Document/services/api'
import UpdateServiceCreateDocument from '@Modules/Services/components/UpdateServiceCreateDocument'
import apiService from '@Modules/Services/services/api'
import { SERVICE_TYPE } from '@Modules/Services/services/constants'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

import SelectSerial from '../../components/SelectSerial'

const { Title } = Typography

function WaybillList({ dataSource, action, data, storeActions, disabled, errors }) {
    const isSaved = get(data, 'isSaved', false)
    const [countryId, setCountryId] = useState(undefined)
    const [inputSearch, setInputSearch] = useState()
    // const [showService, setShowService] = useState(false)
    // const [services, setServices] = useState([])
    const [showFormUpdateServices, setShowFormUpdateServices] = useState(false)
    const [currentData, setCurrentData] = useState([])
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [merchantRowId, setMerchantRowId] = useState()
    const [loading, setLoading] = useState(false)

    const hasSelected = selectedRowKeys.length > 0
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, rows) => {
            setSelectedRowKeys(selectedRowKeys)
            setSelectedRows(rows)
        },
    }

    const pagination = {
        pageSizeOptions: pageSizeOptions,
        locale: { items_per_page: '' },
        defaultPageSize: getPageSizeScale(),
        defaultCurrent: 1,
        showSizeChanger: true,
    }

    useEffect(() => {
        setCountryId(getVar(data, 'countryId', undefined))
        setCurrentData(dataSource)
    }, [data])

    // useEffect(() => {
    //     if (countryId) {
    //         apiService.list({ country_id: countryId, hidden_init_service: true }).then(res => {
    //             let services = getVar(res, 'data.services', []);
    //             services = services.filter(item => {
    //                 const type = getVar(item, 'service.type', '');
    //                 return type === SERVICE_TYPE.IMPORTING_RETURN_GOODS;
    //             });
    //             if (!isEmpty(services)) setShowService(true);
    //             setServices(services);
    //         });
    //     } else {
    //         setServices([]);
    //     }
    // }, [countryId]);

    useEffect(() => {
        if (!isEmpty(dataSource)) {
            const params = {
                ids: map(dataSource, 'order.id'),
            }
            setLoading(true)
            api.getScanListReturnOrder(params)
                .then(res => {
                    action.updateServiceOnStart(res.data)
                })
                .catch(errors => {
                    processResponseError(errors)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [])

    const columns = [
        {
            className: '_waybill-freight-bill',
            title: t('order:label.freight_bill'),
            dataIndex: ['freight_bill', 'freight_bill_code'],
            key: 'freight_bill_code',
        },
        {
            className: '_waybill-order-code',
            title: t('order:label.order_code'),
            dataIndex: ['order', 'code'],
            key: 'order_code',
            render: (text, record) => {
                return <Link to={url.to('orders.detail', { id: getVar(record, 'order.id', '') })}>{text}</Link>
            },
        },
        {
            className: '_waybill-sku-info',
            title: t('order:label.sku_info'),
            dataIndex: ['skus', 'skus'],
            key: 'sku_info',
            render: text => {
                return text?.map((item, index) => {
                    return (
                        <p key={index}>
                            <Link to={url.to('products.detail', { id: item?.product_id })}>{item?.code}</Link>
                        </p>
                    )
                })
            },
        },
        {
            className: '_waybill-quantity text-right',
            title: t('order:label.quantity'),
            dataIndex: ['skus', 'skus'],
            key: 'quantity',
            render: (text, record, rowIndex) => {
                const orderId = get(record, 'order.id')

                return map(text, (item, index) => {
                    const { quantity, receiverQuantity, id, serial_number, is_serial_number } = item

                    return (
                        <Form.Item
                            key={index}
                            validateStatus={get(errors, `${rowIndex}.${index}.alert`) ? 'error' : false}
                            help={get(errors, `${rowIndex}.${index}.alert`) ? t('document:messages.quantity_received_not_bigger_quantity') : false}
                            className="mb-0"
                        >
                            <div className="d-flex align-items-center mb-2">
                                {is_serial_number ? (
                                    <SelectSerial
                                        onChange={value => handleChangeReceiverQuantitySkuSerial(value, id, orderId)}
                                        value={serial_number}
                                    />
                                ) : (
                                    <InputNumber
                                        value={receiverQuantity}
                                        min={0}
                                        onBlur={e => handleChangeReceiverQuantitySku(e.target.value, id, orderId)}
                                        onPressEnter={e => handleChangeReceiverQuantitySku(e.target.value, id, orderId)}
                                        disabled={isSaved}
                                        className="w-100"
                                    />
                                )}

                                <div className="ml-2">
                                    {is_serial_number ? serial_number?.length || 0 :receiverQuantity}/{quantity}
                                </div>
                            </div>
                        </Form.Item>
                    )
                })
            },
        },
        {
            className: '_waybill-status text-right',
            title: t('status'),
            dataIndex: ['freight_bill', 'status'],
            key: 'status',
            render: text => <p className="whitespace-nowrap">{text ? t(`order:FREIGHT_BILL_STATUS.${text}`) : ''}</p>,
        },
        {
            className: '_service',
            title: t('order:label.service'),
            dataIndex: 'import_return_goods_service_prices',
            key: 'services',
            render: (text, record) => {
                return map(text, (item, index) => {
                    const service_name = getVar(item, 'service_name', '')
                    const service_price_name = getVar(item, 'service_price_label', '')
                    return (
                        <div
                            className="whitespace-nowrap d-flex align-items-center mb-1"
                            key={index}
                        >
                            <p key={index}>
                                <Badge status="default" /> {`${service_name ? `${service_name} - ` : ''} ${service_price_name}`}
                            </p>
                            {!isSaved && (
                                <Tooltip title={t('order:btn.select_service')}>
                                    <EditOutlined
                                        className="mx-1"
                                        onClick={() => handleChangeServiceByRow(record, record?.order?.merchant_id)}
                                    />
                                </Tooltip>
                            )}
                        </div>
                    )
                })
            },
        },

        {
            className: '_waybill-action text-right',
            title: '',
            dataIndex: ['freight_bill', 'freight_bill_code'],
            key: 'waybill_action',
            width: '50px',
            render: text =>
                !isSaved ? (
                    <span
                        className="_span--delete cursor-pointer _waybill-delete-btn"
                        onClick={() => action.onDelete([text])}
                    >
                        <CloseOutlined />
                    </span>
                ) : null,
        },
    ]

    function handleChangeServiceByRow(record, merchantId) {
        setMerchantRowId(merchantId)
        setSelectedRows([record])
        setShowFormUpdateServices(true)
    }

    function removeReturnOrders() {
        action.onDelete(selectedRowKeys)
        setSelectedRowKeys([])
    }

    function handleSearchTable(value) {
        if (value) {
            const data = filter(dataSource, item => {
                const skus = getVar(item, 'skus.skus', [])
                return skus.some(function (sku) {
                    const sku_code = get(sku, 'code', '')
                    return sku_code.toString().toLowerCase().includes(value.toLowerCase())
                })
            })
            setCurrentData(data)
        } else {
            setCurrentData(dataSource)
        }
    }

    const renderColumn = () => {
        const tableColumns = columns
        if (isEmpty(action)) {
            return filter(tableColumns, item => item.key !== 'waybill_action' && item.key !== 'services')
        } else {
            return tableColumns
        }
    }

    const selectedAction = (
        <Menu className="waybill-dropdown-action">
            {/* {showService && (
                <Menu.Item
                    className="mb-3 mb-xl-0 _scan-order-list-of-lading-btn-change-service"
                    onClick={() => setShowFormUpdateServices(true)}
                >
                    {t('order:btn.select_service')}
                </Menu.Item>
            )} */}
            <Menu.Item
                key={1}
                className="_scan-order-list-of-lading-btn-remove-selected mb-3 mb-xl-0"
                onClick={removeReturnOrders}
            >
                {t('order:btn.remove_selected')}
            </Menu.Item>
        </Menu>
    )

    function makeServicePriceIdsForSelectedReturnOrder() {
        const selectedOrders = map(selectedRows, 'order.id')
        return currentData
            .filter(returnOrder => selectedOrders.includes(getVar(returnOrder, 'order.id', undefined)))
            .reduce((prices, returnOrder) => [...prices, ...returnOrder.import_return_goods_service_prices], [])
            .map(price => price.service_price_id)
    }

    function handleUpdateService(priceIds) {
        const orderIds = map(selectedRows, 'order.id')
        return api.updateReturnOrderServices({ order_ids: orderIds, service_price_ids: priceIds })
    }

    function reloadData(services, priceIds) {
        const orderIds = map(selectedRows, 'order.id')
        const pricesInfo = []
        priceIds.map(priceId => {
            services.map(item => {
                const serviceInfo = getVar(item, 'service', {})
                const serviceId = getVar(serviceInfo, 'id', undefined)
                const serviceName = getVar(serviceInfo, 'name', undefined)
                const servicePrice = find(getVar(item, 'servicePrices', []), ['id', priceId])
                if (!isEmpty(servicePrice)) {
                    pricesInfo.push({
                        service_id: serviceId,
                        service_name: serviceName,
                        service_price_id: priceId,
                        service_price_label: getVar(servicePrice, 'label', ''),
                    })
                }
            })
        })
        action.updateService({ pricesInfo, orderIds })
        return true
    }

    const handleChangeReceiverQuantitySkuSerial = (value, skuId, orderId) => {
        storeActions.updateReceivedQuantity({
            receiverQuantity: value?.length || 0,
            serial_number: value,
            skuId,
            orderId,
        })
    }

    const handleChangeReceiverQuantitySku = (receiverQuantity, skuId, orderId) => {
        let newValue = 0
        if (receiverQuantity) {
            newValue = parseInt(receiverQuantity.replace(/^\d\d?[,.]\d\d?$/, ''))

            if (Number.isNaN(newValue)) {
                newValue = 0
             
            }
        }
        
        storeActions.updateReceivedQuantity({
            receiverQuantity: newValue,
            skuId,
            orderId,
        })
    }

    return (
        <Box className="main">
            <div className="d-flex flex-wrap justify-content-between mb-3 align-items-center">
                <Space className="mb-3 order-0 mb-xl-0">
                    <Title level={3}>{t(`document:scan_return_order.title.FREIGHT_BILL`)}</Title>
                    <span className="number-circle _waybill-total">{currentData.length}</span>
                </Space>
            </div>
            <div className="border-top">
                <Row className={'justify-content-between p-2'}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        className="mb-3 mb-xl-0"
                    >
                        {!isEmpty(action) && hasSelected && currentData.length > 0 && !isSaved && (
                            <>
                                {t(`order:title.selected.FREIGHT_BILL`, { count: selectedRowKeys.length })}
                                <Dropdown
                                    overlay={selectedAction}
                                    trigger={['click']}
                                    className="_dropdown-action"
                                >
                                    <a
                                        className="ant-dropdown-link ml-2 "
                                        onClick={e => e.preventDefault()}
                                    >
                                        {t('choose_action')} <DownOutlined />
                                    </a>
                                </Dropdown>
                            </>
                        )}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                    >
                        <Input.Search
                            value={inputSearch}
                            onChange={e => setInputSearch(e.target.value)}
                            placeholder={t(`product:placeholder.sku_code`)}
                            onSearch={() => handleSearchTable(inputSearch)}
                            enterButton
                            autoComplete="off"
                            className="_waybill-btn-search"
                        />
                    </Col>
                </Row>
                <Table
                    rowKey={record => record?.freight_bill?.freight_bill_code}
                    rowSelection={isEmpty(action) ? null : rowSelection}
                    className="_waybill-table"
                    dataSource={currentData}
                    columns={renderColumn()}
                    pagination={pagination}
                    scroll={{ x: 576 }}
                    loading={loading}
                    // footer={() => {
                    //     return dataSource.length > 0 ? (
                    //         <div className="d-flex justify-content-between">
                    //             <strong>{t('sum')}</strong>
                    //             <strong className="_product-list-total">
                    //                 {`${sumBy(dataSource, 'real_quantity')}/${sumBy(dataSource, 'quantity')}`}
                    //             </strong>
                    //         </div>
                    //     ) : null;
                    // }}
                    locale={{ emptyText: <TableEmpty className="_waybill-no-data" /> }}
                />
            </div>
            {showFormUpdateServices && (
                <UpdateServiceCreateDocument
                    visible={showFormUpdateServices}
                    priceIds={makeServicePriceIdsForSelectedReturnOrder()}
                    onClose={() => setShowFormUpdateServices(false)}
                    reloadData={reloadData}
                    handleUpdate={handleUpdateService}
                    serviceType={SERVICE_TYPE.IMPORTING_RETURN_GOODS}
                    countryId={countryId}
                    merchantId={merchantRowId}
                />
            )}
        </Box>
    )
}

export default WaybillList
