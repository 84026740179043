import { Col, Row, Table } from 'antd'
import { get, map, mapValues } from 'lodash'
import React, { useEffect, useState } from 'react'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import useSkuComboHistoryQuery from '@Modules/Product/Hooks/useSkuComboHistoryQuery'
import { SKU_COMBO_LOG_KEYS } from '@Modules/Product/services/constants'

import { t, trans } from '@System/i18n'
import { getVar } from '@System/support/helpers'

function ProductHistory({ comboId, activeKey }) {
    const { data, isLoading, refetch } = useSkuComboHistoryQuery(comboId)
    const [dataSource, setDataSource] = useState([])

    useEffect(() => {
        if (activeKey === '4') {
            refetch()
        }
    }, [activeKey])

    useEffect(() => {
        setDataSource(getVar(data, 'data.logs', []))
    }, [data])

    const columns = [
        {
            className: '_product-history-user-changed',
            title: t('label.user_changed'),
            dataIndex: 'creator',
            key: 'creator',
            width: '25%',
            render: text => {
                const name = getVar(text, 'name', '')
                const username = getVar(text, 'username', '')
                return <p>{`${name} (${username})`}</p>
            },
        },
        {
            className: '_product-history-action',
            title: t('label.action'),
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return renderLogsInfo(record)
            },
        },
        {
            className: '_product-history-action-time',
            title: t('label.time'),
            dataIndex: 'created_at',
            key: 'created_at',
            width: '20%',
        },
    ]

    function renderLogsInfo(log) {
        const action = getVar(log, 'action', '')
        const payload = getVar(log, 'payload', {})

        switch (action) {
            case SKU_COMBO_LOG_KEYS.SKU_COMBO_CREATE:
                return trans('product:log.PRODUCT_CREATE')

            case SKU_COMBO_LOG_KEYS.SKU_COMBO_UPDATE:
                return (
                    <div>
                        {Object.entries(payload).map(([key, val]) => {
                            const old = get(val, '0')
                            const newVal = get(val, '1')

                            return (
                                <div>
                                    {trans('product:message.change_attribute_sku_combo', {
                                        attribute: t(`product:label.${key}`),
                                        old: <b>{old ? old : t('noText')}</b>,
                                        new: <b>{newVal ? newVal : t('noText')}</b>,
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )

            case SKU_COMBO_LOG_KEYS.SKU_COMBO_UPDATE_SKU_CHILD:
                return (
                    <div>
                        {map(payload, item => {
                            return (
                                <div>
                                    {trans(`product:message.action_log_combo.${get(item, 'action')}`, {
                                        code: <b>{get(item, 'sku_code')}</b>,
                                        old: <b>{get(item, 'old_quantity')}</b>,
                                        new: <b>{get(item, 'quantity')}</b>,
                                    })}
                                </div>
                            )
                        })}
                    </div>
                )

            default:
                return trans('order:log.ORDER_UPDATE')
        }
    }

    return (
        <Row className="p-4">
            <Col
                xs={{ span: 24 }}
                lg={{ span: 24 }}
            >
                <Table
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isLoading}
                    locale={{ emptyText: <TableEmpty loading={isLoading} /> }}
                    scroll={{ x: 576 }}
                />
            </Col>
        </Row>
    )
}
export default ProductHistory
