import { t } from '@System/i18n'
import notification from '@System/notification'
import { Form, Modal } from 'antd'
import { map, mapValues, } from 'lodash'
import React, { useState } from 'react'
import api from '../services/api'
import TableAdditionalProduct from './TableAdditionalProduct'

const ModalAdditionalSku = ({ setVisible, skusLackInfo, refetchImportingDocument ,refetchSkusImporting}) => {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)


    const handleSubmit = () => {
        form.validateFields().then(async (values) => {
            setLoading(true)
            const data = map(values?.skus, item => {
                const newItem = mapValues(item, (val, key) => {
                    if (key !== 'sku_id') {
                        val /= 1000
                    }
                    return val
                })
                return newItem
            })

            try {
                await api.updateAdditionalProduct({ skus: data })
                notification.success(t('document:messages.update_info_sku_success'))
                refetchSkusImporting()
                refetchImportingDocument()
                setVisible(false)

            } catch (error) {
                notification.error(t('document:messages.update_info_sku_failed'))
            } finally {
                setLoading(false)
            }

        })
    }

    return (
        <Modal
            title={t('document:title.update_info_sku')}
            visible={true}
            onCancel={() => setVisible(false)}
            width={1000}
            okText={t('btn.ok')}
            cancelText={t('btn.cancel')}
            onOk={handleSubmit}
            getContainer={false}
            okButtonProps={{ loading }}
        >
            <TableAdditionalProduct form={form} skusLackInfo={skusLackInfo} loading={loading} />
        </Modal>
    )
}

export default ModalAdditionalSku