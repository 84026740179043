import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, DatePicker, Col, Button, Input, Select } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import useSize from '@Modules/App/Hooks/useSize';
import SelectMerchants from '@Modules/Merchant/components/SelectMerchants';
import SelectFinanceStatuses from '@Modules/PurchasingPackage/components/SelectFinanceStatuses';
import SelectStatuses from '@Modules/PurchasingPackage/components/SelectStatuses';
import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';

import { t } from '@System/i18n';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

const initialFilters = {
    code: undefined,
    sku_code: undefined,
    freight_bill_code: undefined,
    status: undefined,
    shipping_partner_id: undefined,
    destination_warehouse_id: undefined,
    'created_at[from]': undefined,
    'created_at[to]': undefined,
    'imported_at[from]': undefined,
    'imported_at[to]': undefined,
    finance_status: undefined,
    merchant_id: undefined,
};

function FormSearch({ onSearch, filters }) {
    const { isMobile, isTablet } = useSize();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);

    const {
        code,
        sku_code,
        freight_bill_code,
        status,
        shipping_partner_id,
        finance_status,
        merchant_id,
        destination_warehouse_id,
    } = input || {};

    const created_at_from = get(input, 'created_at[from]', '');
    const created_at_to = get(input, 'created_at[to]', '');

    const imported_at_from = get(input, 'imported_at[from]', '');
    const imported_at_to = get(input, 'imported_at[to]', '');

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({ ...input, [name]: value });
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;
        setInput({
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        });
    }

    function _onSubmit() {
        onSearch(input);
    }

    function _onReset() {
        onSearch(initialFilters);
    }

    return (
        <div>
            <Form form={form} layout="vertical">
                <div className="bg-white rounded-12 p-4 mt-3">
                    <div className="_purchasing-package-search-box">
                        <Row gutter={20}>
                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('product:label.package_code')}>
                                    <Input
                                        className="_purchasing-package-search-code"
                                        name="code"
                                        value={code}
                                        placeholder={t('product:placeholder.package_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('product:label.sku_code')}>
                                    <Input
                                        className="_purchasing-package-search-sku-code"
                                        name="sku_code"
                                        value={sku_code}
                                        placeholder={t('product:placeholder.sku_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.freight_bill_code')}>
                                    <Input
                                        className="_purchasing-package-search-freight-bill-code"
                                        name="freight_bill_code"
                                        value={freight_bill_code}
                                        placeholder={t('placeholder.freight_bill_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                <Form.Item className={isMobile || isTablet ? 'mb-3' : 'mb-0'} label={t('label.created_at')}>
                                    <RangePicker
                                        className="_purchasing-package-search-created-at"
                                        value={
                                            created_at_from && created_at_to
                                                ? [moment(created_at_from), moment(created_at_to)]
                                                : undefined
                                        }
                                        format={dateFormat}
                                        placeholder={[t('label.time_start'), t('label.time_end')]}
                                        onChange={date => _onChangeDate('created_at', date)}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.purchasing_package_status')}>
                                    <SelectStatuses
                                        className="_purchasing-package-search-status"
                                        placeholder={t('placeholder.status')}
                                        allowClear={true}
                                        value={status}
                                        onChange={value => _onChange('status', value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.receive_warehouse')}>
                                    <SelectWarehouse
                                        className="_purchasing-package-search-destination-warehouse-id"
                                        value={destination_warehouse_id ? parseInt(destination_warehouse_id) : undefined}
                                        allowClear={true}
                                        onChange={value => _onChange('destination_warehouse_id', value)}
                                        placeholder={t('placeholder.receive_warehouse')}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.shipping_partner')}>
                                    <SelectShippingPartner
                                        className="_purchasing-package-search-shipping-partner-id"
                                        value={shipping_partner_id ? parseInt(shipping_partner_id) : undefined}
                                        allowClear={true}
                                        onChange={value => _onChange('shipping_partner_id', value)}
                                        placeholder={t('placeholder.shipping_partner')}
                                        all
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.finance_status')}>
                                    <SelectFinanceStatuses
                                        className="_purchasing-package-search-finance-status"
                                        placeholder={t('placeholder.status')}
                                        allowClear={true}
                                        value={finance_status}
                                        onChange={value => _onChange('finance_status', value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 8 }}>
                                <Form.Item className={isMobile || isTablet ? 'mb-3' : 'mb-0'} label={t('purchasingOrder:label.delivery_time')}>
                                    <RangePicker
                                        className="_purchasing-package-search-delivery_time"
                                        value={
                                            imported_at_from && imported_at_to
                                                ? [moment(imported_at_from), moment(imported_at_to)]
                                                : undefined
                                        }
                                        format={dateFormat}
                                        placeholder={[t('label.time_start'), t('label.time_end')]}
                                        onChange={date => _onChangeDate('imported_at', date)}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                                <Form.Item className="mb-3" label={t('label.select_seller')}>
                                    <SelectMerchants
                                        placeholder={t('placeholder.select_seller')}
                                        className="_purchasing-package-search-merchant-id"
                                        allowClear={true}
                                        value={merchant_id ? parseInt(merchant_id) : undefined}
                                        onChange={value => _onChange('merchant_id', value)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={{ span: 24 }} lg={{ span: 24 }} className="text-right">
                                <Form.Item className="mb-0" label={isMobile || isTablet ? '' : ' '}>
                                    <Button className="mr-2 _purchasing-package-search-btn-reset" onClick={_onReset}>
                                        <UndoOutlined /> {t('common:btn.set_again')}
                                    </Button>
                                    <Button type="primary" onClick={_onSubmit} className="_purchasing-package-search-btn-search">
                                        <SearchOutlined /> {t('common:btn.search')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default FormSearch;
