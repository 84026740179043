import { useQuery } from 'react-query'

import api from '@Modules/Finance/services/api'

import processResponseError from '@System/api/processResponseError'

const useDebtsShippingListQuery = params => {
    return useQuery(
        ['finance-debit-shipping-list', params],
        () => {
            if (params?.shipping_partner_id) {
                return api.getFinanceDebitShipping(params)
            }
        },
        {
            retry: false,
            onError: err => {
                processResponseError(err)
            },
        }
    )
}

export default useDebtsShippingListQuery
