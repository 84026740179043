import {Button, Modal} from "antd";
import {PlusCircleOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {t} from "@System/i18n";
import SelectWarehouse from "@Modules/Warehouse/components/SelectWarehouse";
import useAddSkuInventoryDocumentMutation
    from "@Modules/Document/screens/Inventory/Hooks/useAddSkuInventoryDocumentMutation";
import {url} from "@System/routing";
import useGeneratorKey, { IdempotencyStorage } from "@System/hooks/useGeneratorKey";
import LeavePageBlocker from "../../Importing/components/LeavePageBlocker";
import { get } from "lodash";
import notification from "@System/notification";

export default function ChoseWarehouseModal() {
    const { uniqueKey, generatorKey } = useGeneratorKey()
    const [dirty, setDirty] = useState(false)

    let [visible, setVisible] = useState(false);
    let [warehouseId, setWarehouseId] = useState(null);

    const { isLoading, mutate } = useAddSkuInventoryDocumentMutation({
        onSuccess: data => {
            setDirty(false)
            url.redirectTo('documents.inventory.detail', {
                id: data?.document_sku_inventory?.id,
            });
        },
        onError: error => {
            const { response } = error
            const code = get(response, 'data.code')
            if (code === 'REQUEST_PROCESSED') {
                setDirty(true)
                notification.warning(t('message.request_process'))
            }else{
                setDirty(false)
                generatorKey()
            }
        },
    });

    const handleFinish = () => {
        setDirty(true)
        mutate({warehouse_id: warehouseId});
    };

    function _onChange(value) {
        setWarehouseId(value);
    }

    return (
        <>
            {dirty && <LeavePageBlocker when={dirty} />}
            <Button
                type="primary"
                icon={<PlusCircleOutlined/>}
                onClick={() => setVisible(true)}
            >{t("document:create_inventory_document")}</Button>
            <Modal
                title={t("document:select_inventory_warehouse").toUpperCase()}
                visible={visible}
                onOk={handleFinish}
                okButtonProps={{loading: isLoading, disabled: !warehouseId}}
                onCancel={() => setVisible(false)}
                okText={t('btn.add')}
                cancelText={t('btn.cancel')}
                width={360}
            >
                <div className="d-flex justify-content-center">
                    <SelectWarehouse
                        placeholder={t("select_warehouse")}
                        style={{width: "100%"}}
                        onChange={_onChange}
                    />
                </div>
            </Modal>
        </>
    );
}
