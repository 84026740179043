import { Button } from 'antd'
import { get, map, toLower } from 'lodash'
import find from 'lodash/find'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'

import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import { SHIPPING_PARTNER_CODE_ALLOW_PRINT_BY_API } from '@Modules/Order/services/constants'
import useShippingPartnerStampUrlQuery from '@Modules/ShippingPartners/Hooks/useShippingPartnerStampUrlQuery'
import ServiceShippingPartners from '@Modules/ShippingPartners/services/ServiceShippingPartners'

import { t } from '@System/i18n'
import notification from '@System/notification'
import { getVar, notifyErrorFromServer } from '@System/support/helpers'

const PROVIDER_PRINT = ['shopee', 'tiktokshop', 'lazada']
const ShippingPartnerPrint = ({ shippingPartnerId, freightBills, orderPackings, shippingStampRef, storeId }) => {
    const [shippingPartners, setShippingPartners] = useState([])

    console.log('orderPackings', orderPackings)

    useEffect(() => {
        ServiceShippingPartners.list().then(res => {
            setShippingPartners(res)
        })
    }, [])
    const printDefaultStamp = useReactToPrint({
        content: () => shippingStampRef.current,
    })

    const { isFetching, refetch: printStamp } = useShippingPartnerStampUrlQuery(
        {
            id: shippingPartnerId,
            freight_bill_codes: freightBills,
            store_id: storeId,
            merchants: map(orderPackings, 'merchant_name')
        },
        {
            onSuccess: ({ data }) => {
                const url = getVar(data, 'url', '')
                if (!isEmpty(url)) {
                    if (isArray(url)) {
                        for (const element of url) {
                            setTimeout(function () {
                                window.open(element, '_blank')
                            }, 500)
                        }
                    } else {
                        window.open(url, '_blank').focus()
                    }
                }
            },
            onError: error => {
                notifyErrorFromServer(error, t('order:get_shipping_partner_stamp_url.failed'), 'order:get_shipping_partner_stamp_url.errors')
            },
        }
    )

    const handlePrint = () => {
        if (isFetching || !auth.can(permissions.OPERATION_PRINT_BILL)) {
            return
        }

        const shippingPartner = find(shippingPartners, ['id', shippingPartnerId ? Number.parseInt(shippingPartnerId) : undefined])
        if (isEmpty(shippingPartner)) {
            return notification.error(t('order:get_shipping_partner_stamp_url.errors.choose_shipping_partner'))
        }

        if (freightBills?.length) {
            const shippingPartnerCode = orderPackings[0]?.shipping_partner?.code
            if (shippingPartnerCode !== shippingPartner?.code) {
                return notification.error(t('order:get_shipping_partner_stamp_url.errors.tracking_nos_not_belong_shipping_partner.tracking_nos'))
            }
            const provider = toLower(getVar(shippingPartner, 'provider', 'm32'))

            if (provider === 'shopee' && isEmpty(storeId)) {
                return notification.error(t('order:message.choose_shopee_store'))
            }

            // TODO: replace hardcoded partner code with data from server
            // SHIPPING_PARTNER_CODE_ALLOW_PRINT_BY_API.includes(shippingPartnerCode)
            if (get(shippingPartner, 'provider') === 'm32' || PROVIDER_PRINT.includes(provider)) {
                return printStamp()
            } else {
                return printDefaultStamp()
            }
        } else {
            notification.error(t('order:print_shipping_stamp.errors.ids.required'))
        }
    }

    return (
        <Button
            className="_print-stamp-btn btn-menu-item"
            onClick={handlePrint}
            loading={isFetching}
        >
            {t('print_stamp')}
        </Button>
    )
}

export default ShippingPartnerPrint
