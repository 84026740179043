import { find, isEmpty } from 'lodash'
import React from 'react'

import { t } from '@System/i18n'
import notification from '@System/notification'

import AddProduct from './AddProduct'
import AddProductByExcel from './AddProductByExcel'
import ProductList from './ProductList'

const InfoProduct = ({ products, setProducts, form }) => {
    function addProduct(data) {
        const exists = find(products, ['sku_id', data.sku_id])

        if (isEmpty(exists)) {
            let objBatch = data.is_batch ? { batch_of_goods: [{ id: undefined, quantity: 1 }] } : {quantity: 1}
            const newData = {
                ...data,
                ...objBatch,
            }
            setProducts([...products, newData])
        } else {
            notification.error(t('product:message.added', { attribute: t('product:label.product') }))
        }
    }

    return (
        <div className="bg-white rounded-12 p-4">
            <div className="mb-3 d-flex align-items-center justify-content-between">
                <h3>{t('product_list')}</h3>
                <div className="d-flex align-items-center">
                    <AddProductByExcel
                        existsData={products}
                        setProducts={setProducts}
                    />
                    <AddProduct
                        existsData={products}
                        setProducts={setProducts}
                        addProduct={addProduct}
                    />
                </div>
            </div>
            <ProductList
                form={form}
                products={products}
                setProducts={setProducts}
            />
        </div>
    )
}

export default InfoProduct
