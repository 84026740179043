import { Button, Input, Typography } from 'antd';
import clsx from 'clsx';
import filter from 'lodash/filter';
import isNil from 'lodash/isNil';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useState } from 'react';

import Box from '@Modules/App/Box';
import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import StyledTag from '@Modules/App/StyledTag';
import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import { STATUSES } from '@Modules/Document/constants';
import { STATUS_COLORS } from '@Modules/Document/services/constants';

import { t } from '@System/i18n';
import { Link } from '@System/routing';

import useBalanceMutation from '../../Hooks/useBalanceMutation';
const { Paragraph, Title } = Typography;

const Summary = ({ canUpdate, inventoryDocument, screen, onSuccessBalance, onUpdateSkuInventoryDocument }) => {
    const [checkedSkus, setCheckedSkus] = useState([]);
    const [hasUncheckedSku, setHasUncheckedSku] = useState(true);
    const [note, setNote] = useState();
    const [totalQuantityInStock, setTotalQuantityInStock] = useState(0);
    const [totalQuantityChecked, setTotalQuantityChecked] = useState(0);
    const [diff, setDiff] = useState(0);
    const { isLoading: balancing, mutate: balance } = useBalanceMutation({
        onSuccess: onSuccessBalance,
    });
        

    const handleChangeNote = usePersistFn(event => {
        const value = event.target.value;

        setNote(value);
    });

    const emitOnUpdateSkuInventoryDocument = usePersistFn(event => {
        onUpdateSkuInventoryDocument({
            note,
        });
    });

    const handleBalanceButtonClick = usePersistFn(() => {
        balance(inventoryDocument?.document_sku_inventory?.id);
    });

    useEffect(() => {
        const skuInventories = inventoryDocument?.sku_inventories;
        const totalQuantityInStock = sumBy(skuInventories, 'quantity_in_stock_before_balanced');
        const totalQuantityChecked = sumBy(skuInventories, 'quantity_checked');
        const diff = totalQuantityChecked - totalQuantityInStock;
        const checkedSkus = filter(
            skuInventories,
            skuInventory => !isNil(skuInventory?.quantity_checked) && skuInventory?.quantity_checked !== ''
        );
        const hasUncheckedSku = checkedSkus?.length !== skuInventories?.length;

        setTotalQuantityInStock(totalQuantityInStock);
        setTotalQuantityChecked(totalQuantityChecked);
        setNote(inventoryDocument?.document_sku_inventory?.note);
        setDiff(diff);
        setCheckedSkus(checkedSkus);
        setHasUncheckedSku(hasUncheckedSku);
    }, [inventoryDocument]);

    return (
        <Box className="_summary mt-2">
            <Title className="mb-4" level={3}>
                {screen === 'preview' ? (
                    <>
                        {`${t('document:types.inventory')}: #${inventoryDocument?.document_sku_inventory?.code}`}
                        <StyledTag
                            className="ml-2"
                            type={inventoryDocument?.document_sku_inventory?.status === STATUSES.COMPLETED && 'success'}
                            color={
                                inventoryDocument?.document_sku_inventory?.status
                                    ? STATUS_COLORS[inventoryDocument?.document_sku_inventory?.status]
                                    : undefined
                            }
                        >
                            {t(`document:inventory_statuses.${inventoryDocument?.document_sku_inventory?.status}`)}
                        </StyledTag>
                    </>
                ) : (
                    t('summary')
                )}
            </Title>

            <div className="d-flex align-items-center justify-content-between _warehouse-row">
                <Paragraph className="_label">{t('document:inventory_warehouse')}</Paragraph>
                <Paragraph className="_value">{inventoryDocument?.document_sku_inventory?.warehouse_code ?? '--'}</Paragraph>
            </div>

            <div className="d-flex align-items-center justify-content-between _total-sku-row">
                <Paragraph className="_label">{t('total_sku')}</Paragraph>
                <Paragraph className="_value">
                    {checkedSkus.length}/{inventoryDocument?.sku_inventories?.length || 0}
                </Paragraph>
            </div>

            <div className="d-flex align-items-center justify-content-between _total-quantity-checked-row">
                <Paragraph className="_label">{t('document:inventory_after_checking')}</Paragraph>
                <Paragraph className="_value">{totalQuantityChecked}</Paragraph>
            </div>

            <div className="d-flex align-items-center justify-content-between _total-quantity-in_stock-row">
                <Paragraph className="_label">{t('document:current_inventory')}</Paragraph>
                <Paragraph className="_value">{totalQuantityInStock}</Paragraph>
            </div>

            <div className="d-flex align-items-center justify-content-between _deviated-row">
                <Paragraph className="_label" strong>
                    {t('document:deviated')}
                </Paragraph>
                <Paragraph
                    className={clsx('_value', {
                        'text-red': diff < 0,
                        'text-green': diff > 0,
                    })}
                >
                    {diff > 0 && '+'}
                    {diff}
                </Paragraph>
            </div>

            <Input.TextArea
                autoSize={{ minRows: 3, maxRows: 3 }}
                className="_note mb-4"
                disabled={!canUpdate || inventoryDocument?.document_sku_inventory?.status !== STATUSES.DRAFT}
                placeholder={t('note')}
                value={note}
                onBlur={emitOnUpdateSkuInventoryDocument}
                onChange={handleChangeNote}
            />

            {inventoryDocument?.document_sku_inventory?.status !== STATUSES.COMPLETED && (
                <div className="d-flex align-items-center">
                    {screen === 'preview' ? (
                        <>
                            {auth.can(permissions.OPERATION_HISTORY_AUDIT_CONFIRM) && !hasUncheckedSku && (
                                <Button
                                    className="mr-3 _balance-btn"
                                    loading={balancing}
                                    type="primary"
                                    onClick={handleBalanceButtonClick}
                                >
                                    <Link
                                        params={{ id: inventoryDocument?.document_sku_inventory?.id }}
                                        to="documents.inventory.preview"
                                    >
                                        {t('document:balance_warehouse_by_document')}
                                    </Link>
                                </Button>
                            )}
                            {auth.can(permissions.OPERATION_HISTORY_AUDIT_EDIT) && (
                                <Button className="_edit-btn">
                                    <Link
                                        params={{ id: inventoryDocument?.document_sku_inventory?.id }}
                                        to="documents.inventory.detail"
                                    >
                                        {t('edit')}
                                    </Link>
                                </Button>
                            )}
                        </>
                    ) : (
                        <Button className="_preview-btn" type="primary">
                            <Link params={{ id: inventoryDocument?.document_sku_inventory?.id }} to="documents.inventory.preview">
                                {t('preview')}
                            </Link>
                        </Button>
                    )}
                </div>
            )}
        </Box>
    );
};

export default Summary;
