import { useQuery } from 'react-query';

import api from '@Modules/Finance/services/api';

const useFinanceControlDocument = id =>
    useQuery(['finance-control-document', id], () => api.getFinanceControlDocument(id), {
        retry: false,
        enabled: !!id,
    });

export default useFinanceControlDocument;
