import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';

import RangeInput from '@Modules/App/Input/RangeInput';

import { t } from '@System/i18n';

const { Title } = Typography;

const Header = ({ className, form, loading, onClear, onSubmit }) => (
    <Card className={clsx('rounded-12', className)}>
        <Title className="font-weight-bold text-fz-16 mb-4" level={3}>
            {t('search')}
        </Title>

        <Form className="_search-form" form={form} layout="vertical" onFinish={onSubmit}>
            <div className="d-flex flex-column flex-lg-row align-items-lg-end justify-content-lg-between">
                <Row className="flex-grow-1 row-gap-16 mb-3 mb-lg-0" gutter={16}>
                    <Col lg={{ span: 5 }} span={24}>
                        <Form.Item className="mb-0" label={t('inventory:label.product')} name="product_keyword">
                            <Input className="_product" placeholder={t('inventory:placeholder.product')} />
                        </Form.Item>
                    </Col>
                    <Col lg={{ span: 5 }} span={24}>
                        <Form.Item className="mb-0" label={t('inventory:label.sku')} name="sku_keyword">
                            <Input className="_sku" placeholder={t('inventory:placeholder.sku')} />
                        </Form.Item>
                    </Col>

                    <Col lg={{ span: 5 }} span={24}>
                        <Form.Item className="mb-0" label={t('inventory:label.temporary_inventory')}>
                            <RangeInput
                                className="_quantity"
                                fromFormItemProps={{
                                    name: 'quantity[from]',
                                }}
                                fromInputProps={{
                                    className: '_from',
                                    placeholder: t('from'),
                                }}
                                toFormItemProps={{
                                    name: 'quantity[to]',
                                }}
                                toInputProps={{
                                    className: '_to',
                                    placeholder: t('to'),
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={{ span: 5 }} span={24}>
                        <Form.Item className="mb-0" label={t('inventory:prognosis')}>
                            <RangeInput
                                className="_saleable-quantity"
                                fromFormItemProps={{
                                    name: 'saleable_quantity[from]',
                                }}
                                fromInputProps={{
                                    className: '_from',
                                    placeholder: t('from'),
                                }}
                                toFormItemProps={{
                                    name: 'saleable_quantity[to]',
                                }}
                                toInputProps={{
                                    className: '_to',
                                    placeholder: t('to'),
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <div className="d-flex flex-wrap flex-lg-nowrap gap-16">
                    <Button loading={loading} onClick={onClear}>
                        <RedoOutlined />
                        {t('btn.set_again')}
                    </Button>

                    <Button htmlType="submit" loading={loading} type="primary">
                        <SearchOutlined />
                        {t('btn.search')}
                    </Button>
                </div>
            </div>
        </Form>
    </Card>
);

export default Header;
