import { Input } from 'antd';
import React, { useCallback } from 'react';

import ScanBarcode from '@Modules/ScanOrder/components/ScanBarcode';

const ScannableInput = ({ name, onChange, ...props }) => {
    const handleUpdate = useCallback(
        value => {
            onChange({
                target: {
                    name,
                    value,
                },
            });
        },
        [name, onChange]
    );

    return (
        <Input autoComplete="off" name={name} suffix={<ScanBarcode onUpdate={handleUpdate} />} onChange={onChange} {...props} />
    );
};

export default ScannableInput;
