import { Table, Avatar, Badge } from 'antd'
import get from 'lodash/get'
import React from 'react'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { dateFormatter } from '@Modules/App/services'
import ExportList from '@Modules/PurchasingPackage/components/ExportList'
import { FINANCE_STATUS_COLORS, PURCHASING_PACKAGE_STATUS_COLORS } from '@Modules/PurchasingPackage/services/constants'

import { t } from '@System/i18n'
import { Link } from '@System/routing'
import { getVar } from '@System/support/helpers'
import DynamicTable from '@Modules/App/DynamicTable'

export default function List({ purchasingPackages, pagination, addFilter, filters, loading }) {
    const getColumns = [
        {
            className: '_purchasing-package-info',
            title: t('product:label.import_package'),
            dataIndex: ['purchasing_package', 'code'],
            key: 'import_package',
            render: (text, record) => {
                const count = getVar(record, 'purchasing_package_items', []).length
                const image = getVar(record, 'images.0', '')
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            src={image}
                            shape="square"
                            className="mr-2"
                            size={36}
                        />
                        <div>
                            <span>
                                <Link
                                    params={{ id: get(record, 'purchasing_package.id') }}
                                    to={'import-package.detail'}
                                >
                                    <strong style={{ fontWeight: 500 }}>{`#${text}`}</strong>
                                </Link>
                            </span>
                            <p style={{ color: '#999999' }}>{t('label.has_product', { count })}</p>
                        </div>
                    </div>
                )
            },
            disabled: true,
        },
        {
            className: '_purchasing-package-freight-bill',
            title: t('label.freight_bill_code'),
            dataIndex: ['purchasing_package', 'freight_bill_code'],
            key: 'freight_bill_code',
        },
        {
            className: '_purchasing-package-freight-bill',
            title: t(`heading.documents.IMPORTING`),
            dataIndex: ['document'],
            key: 'document',
            render: (text, record) => {
                return (
                    <Link
                        params={{
                            id: get(text, 'id'),
                        }}
                        to={`documents.importing.detail`}
                    >
                        <strong style={{ fontWeight: 500 }}> {get(text, 'code', '')}</strong>
                    </Link>
                )
            },
        },
        {
            className: '_purchasing-package-declare-quantity text-right',
            title: t('product:label.declare_quantity'),
            dataIndex: ['purchasing_package', 'quantity'],
            key: 'declare_quantity',
        },
        {
            className: '_purchasing-package-real-quantity text-right',
            title: t('product:label.real_quantity'),
            dataIndex: ['purchasing_package', 'received_quantity'],
            key: 'real_quantity',
        },
        {
            className: '_purchasing-package-created-at',
            title: t('label.created_at'),
            dataIndex: ['purchasing_package', 'created_at'],
            key: 'created_at',
            render: text => (text ? dateFormatter.full(text) : ''),
        },
        {
            className: '_purchasing-package-warehouse',
            title: t('product:label.warehouse'),
            dataIndex: ['destination_warehouse', 'name'],
            key: 'warehouse',
        },
        {
            className: '_purchasing-package-status',
            title: t('label.purchasing_package_status'),
            dataIndex: ['purchasing_package', 'status'],
            key: 'order.status',
            render: text => {
                return text ? (
                    <Badge
                        color={PURCHASING_PACKAGE_STATUS_COLORS[text]}
                        text={t(`product:PURCHASING_PACKAGE_STATUS.${text}`)}
                    />
                ) : null
            },
        },
        {
            className: '_purchasing-package-seller',
            title: 'Seller',
            dataIndex: 'merchant',
            render: text => {
                const name = text?.name ?? ''
                const code = text?.code ?? ''

                return (name ? name : '') + (code ? ' - ' + code : '')
            },
        },
        {
            className: '_purchasing-package-status',
            title: t('label.finance_status'),
            dataIndex: ['purchasing_package', 'finance_status'],
            key: 'order.finance_status',
            render: text =>
                text ? (
                    <Badge
                        color={FINANCE_STATUS_COLORS[text]}
                        text={t(`product:FINANCE_STATUS.${text}`)}
                    />
                ) : null,
        },
    ]

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {`${t('title.purchasing_packages')} `}
                        <span className="number-circle _purchasing-package-list-total">{pagination?.total ?? 0}</span>
                    </h3>
                    <div>
                        <ExportList filters={filters} />
                    </div>
                </div>
                <div className="data-box--body">
                    {/* <Table
                        className="_purchasing-package-table pb-3"
                        dataSource={purchasingPackages}
                        rowKey={record => record?.purchasing_package?.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: <TableEmpty className="_purchasing-package-no-data" />,
                        }}
                        scroll={{ x: true }}
                    /> */}

                    <DynamicTable
                        initColumns={getColumns}
                        dataSource={purchasingPackages}
                        className="_purchasing-package-table pb-3"
                        loading={loading}
                        rowKey={record => record?.purchasing_package?.id}
                    />

                    <CustomizedPagination
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                    />
                </div>
            </div>
        </div>
    )
}
