import isFunction from 'lodash/isFunction';
import { useEffect } from 'react';

import usePersistFn from './usePersistFn';

// modified from https://ahooks.js.org/hooks/life-cycle/use-unmount

const useUnmount = fn => {
    const fnPersist = usePersistFn(fn);

    useEffect(
        () => () => {
            if (isFunction(fnPersist)) {
                fnPersist();
            }
        },
        // eslint-disable-next-line
        []
    );
};

export default useUnmount;
