import { useQuery } from 'react-query'

import processResponseError from '@System/api/processResponseError'

import apiService from '../services/api'

const useListSkuComboQuery = params =>
    useQuery(['combo-list', params], () => apiService.getComboList(params), {
        retry: false,
        onError: err => {
            processResponseError(err)
        },
    })

export default useListSkuComboQuery
