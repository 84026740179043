import React from "react";
import {DeleteOutlined} from "@ant-design/icons";
import {catchErrors} from "../../../App/services/helpers";
import {Modal, notification} from "antd";
import {t} from "../../../../system/i18n";
import api from "../../services/api";
import {events} from "../../../../system/events";
import {EVENTS} from "../../services/constants";

export default function DeleteFobizSku({storeSku, canUpdate}) {

    function _onClick() {
        Modal.confirm({
            content: t("synchronousProduct:message.confirm_clear_fobiz_code"),
            okText: t("btn.confirm"),
            cancelText: t("btn.cancel"),
            onOk: clearFobizCode
        });
    }

    function clearFobizCode() {
        api.deleteSkusFobiz(storeSku?.store_sku_id)
            .then(() => {
                events.dispatch(EVENTS.IMPORT_SKU_FOBIZ_FROM_EXCEL, {});
                notification.success({message: t('message.delete_success', {attribute: t('synchronousProduct:label.configuration')})});
            })
            .catch(catchErrors(t('message.delete_failed', {attribute: t('synchronousProduct:label.configuration')})));
    }

    if (!canUpdate)
        return null;

    return (
        <span className="cursor-pointer text-red" onClick={_onClick}>
            <DeleteOutlined />
        </span>
    );
}
