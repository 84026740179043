export const SERVICE_TYPE = {
    IMPORT: 'IMPORT',
    EXPORT: 'EXPORT',
    STORAGE: 'STORAGE',
    IMPORTING_RETURN_GOODS: 'IMPORTING_RETURN_GOODS',
    EXTENT: 'EXTENT',
}

export const EVENTS = {
    REFETCH_SERVICE_PACK: 'REFETCH_SERVICE_PACK',
}

export const SELLER_HISTORY_ACTION = {
    SERVICE_PACK_ADD_SELLER: 'SERVICE_PACK:ADD_SELLER',
    SERVICE_PACK_REMOVE_SELLER: 'SERVICE_PACK:REMOVE_SELLER',
}
