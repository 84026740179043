import { notification } from 'antd';
import map from 'lodash/map';
import React, { useState, useEffect, useCallback } from 'react';

import useDeleteInvalidOrderMutation from '@Modules/Order/Hooks/useDeleteInvalidOrderMutation';
import useResyncMultiInvalidOrdersMutation from '@Modules/Order/Hooks/useResyncMultiInvalidOrdersMutation';

import { t } from '@System/i18n';

import useFilter from '../../../App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '../../../App/services';
import api from '../../services/api';
import FormSearch from './FormSearch';
import List from './List';
import useListErrorOrderQuery from "@Modules/Order/Hooks/useListErrorOrderQuery";
import {getVar} from "@System/support/helpers";

export default function HandleErrorOrders({ history, location }) {
    const { mutateAsync: deleteInvalidOrder } = useDeleteInvalidOrderMutation({
        onSuccess: () => {
            refetch();
        },
    });
    const { isLoading: resyncingMulti, mutateAsync: resyncMulti } = useResyncMultiInvalidOrdersMutation({
        onSuccess: () => {
            refetch();
        },
    });
    const { filters, addFilter } = useFilter(history, location);
    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({});
    const {data, isFetching:loading, refetch} = useListErrorOrderQuery(filters);

    useEffect(() => {
        setOrders(getVar(data, "data.invalid_orders", []));
        setPagination(getVar(data, "data.pagination", []));
    }, [data]);

    const handleResyncMulti = useCallback(() => {
        const ids = map(orders, 'id');

        resyncMulti({
            ids,
        });
    }, [orders, resyncMulti]);

    useEffect(() => {
        setNavigator(t('title.handle_error_order'), [
            {
                name: t('breadcrumb.handle_error_order'),
            },
        ]);
        setDocumentTitle(t('title.handle_error_order'));
    }, []);

    return (
        <div className="site-content">
            <FormSearch filters={filters} loading={loading} onSearch={addFilter} />
            <List
                deleteInvalidOrder={deleteInvalidOrder}
                filters={filters}
                loading={loading}
                orders={orders}
                pagination={pagination}
                resyncingMulti={resyncingMulti}
                addFilter={addFilter}
                fetchFailedOrders={refetch}
                onResyncMulti={handleResyncMulti}
            />
        </div>
    );
}
