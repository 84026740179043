import omit from 'lodash/omit';

import { api } from '@System/api';

const BASE_URL = 'marketplaces';

export default {
    list: params =>
        api.get(BASE_URL, {
            params,
        }),
    oAuthUrl: params =>
        api.get(`${BASE_URL}/${params.code}/oauth-url`, {
            params: omit(params, 'code'),
        }),
};
