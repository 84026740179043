import lodash from 'lodash';
import { Button, Modal, notification, Form } from 'antd';
import React, { useState } from 'react';
import { CloudUploadOutlined } from '@ant-design/icons';
import i18next from "i18next";

import api from '../../services/api';
import { renderError } from '../errors';
import { t } from '@System/i18n';
import { EVENTS } from "../../services/constants";
import { events } from "@System/events";
import UploadFile from '../../../App/Upload/UploadFile';
import SelectWarehouse from '../../../Warehouse/components/SelectWarehouse';
import useLocalStorageState from "../../../App/Hooks/useLocalStorageState";

export default function UploadFreightBillFile() {
	const [warehouseId, setWarehouseId, getStoredValue] = useLocalStorageState('warehouse');
	const [visible, setVisible] = useState(false);
	const [messages, setMessages] = useState([]);
	const [error, setError] = useState();

	function showModal(e) {
		e.preventDefault();
		setVisible(true);
		setWarehouseId(getStoredValue())
	}

	function handleOk() {
		setMessages([]);
		setVisible(false);
	}

	function handleCancel() {
		setMessages([]);
		setVisible(false);
		setWarehouseId(getStoredValue())
	}

	function handleUpload(file) {
		setMessages([]);
		if (warehouseId) {
			const filename = lodash.get(file, "name", "");
			let formData = new FormData();
			formData.append("file", file);
			formData.append("warehouse_id", warehouseId);
			api.importFreightBill(formData)
				.then(res => {
					const messages = lodash.uniqWith(lodash.get(res, "data.errors", []), lodash.isEqual);
					const hasInvalid = lodash.findIndex(messages, ['errors', "invalid"]);
					if (hasInvalid === -1) {
						setMessages(messages);
						if (messages.length === 0) {
							notification.success({ message: t("order:message.upload_file.success", { filename }) });
							setVisible(false);
							events.dispatch(EVENTS.IMPORT_ORDER_FROM_EXCEL, {});
						}
					} else {
						notification.error({ message: t("order:message.upload_file.fail", { filename }) });
					}
				}).catch(err => {
					notification.error({
						message: t("common:message.server_error")
					})
				});
		} else {
			setError(t("order:message.order_status.required", {attribute: t("order:label.warehouse_code")}))
		}
	}

	function onChangeWarehouseId(value) {
		setMessages([]);
		setError(undefined);
		setWarehouseId(value);
	}

	return (
		<>
			{
				// auth.can(permissions.ORDER_IMPORT_FREIGHT_BILL) && (
					<Button
						type="primary"
						className="ml-2 _prepare-order-btn-import-freight-bill"
						onClick={showModal}
					><CloudUploadOutlined /> {t('order:btn.import_freight_bill')}</Button>
				// )
			}

			<Modal
				title={t('order:label.import_freight_bill').toUpperCase()}
				visible={visible}
				onOk={handleOk}
				onCancel={handleCancel}
				footer={null}
				maskClosable={false}
			>
				<p>- {t("label.latest_at_update_sample")}: <strong>21/05/2021</strong></p>
				<p>- {t("label.download_sample_file")}:
					<strong><a href={process.env.PUBLIC_URL + `/template/${i18next.language}/file_mau_cap_nhat_ma_van_don.xlsx`}>file_mau_cap_nhat_ma_van_don.xlsx</a></strong>
				</p>
				<p className="mb-3">- {t("label.maximum_file_size")}: <strong>5MB</strong></p>

				<Form.Item
					label={t("order:label.warehouse_code")}
					required
					validateStatus={error ? "error" : null}
					help={error}
				>
					<SelectWarehouse
						className={"_prepare-order-import-freight-bill-warehouse-code"}
						allowClear={true}
						placeholder={t("order:placeholder.warehouse_code")}
						value={warehouseId ? parseInt(warehouseId) : undefined}
						onChange={value => onChangeWarehouseId(value)}
					/>
				</Form.Item>

				<UploadFile
					className={"_prepare-order-import-freight-bill-upload-file"}
					onChange={handleUpload}
					accept=".xls,.xlsx"
					fileType={[
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
						"application/vnd.ms-excel",
					]}
					name={t('order:label.import_freight_bill')}
					maxFileSize={5}
					showUploadList={true}
				/>
				{
					messages.length > 0 &&
					<div className="pt-3">
						{
							messages.map((item, index) => {
								return <p key={index} className="text-danger">- {renderError(item)}</p>
							})
						}
					</div>
				}
			</Modal>
		</>
	);
}
