import { Modal, notification } from 'antd';
import clsx from 'clsx';
import { get, isEmpty, filter, map, find, includes, pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Box from '@Modules/App/Box';
import withScanErrors from '@Modules/App/ScanErrors/withScanErrors';
import ListOrderInfo from '@Modules/ScanOrder/screens/Packing/ConfirmPacking/components/ListOrderInfo';
import ServiceWarehouses from '@Modules/Warehouse/services/ServiceWarehouses';

import { clearErrors } from '@State/scanErrors/actions';
import { useScanErrorsOfType } from '@State/scanErrors/hooks';

import { t, trans } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

import {
    scanBarcodeTypeStorage,
    scanConfirmPacking,
    scanWarehouseStorage,
    setDocumentTitle,
    setNavigator,
    showResetConfirmPackingStorage,
} from '../../../../App/services';
import { updateCollectionItem } from '../../../../App/services/helpers';
import { SCAN_TYPE } from '../../../../Document/constants';
import { ORDER_PACKING_STATUS } from '../../../../Order/services/constants';
import ScanLadingBill from '../../../components/ScanLadingBill';
import renderErrorScan from '../../../components/customerError';
import { convertDataOrderPacking, handleSortListByKey } from '../../../components/customerFunction';
import api from '../../../services/api';

import styles from './../../scan-order.module.scss';

const ScanLadingBillWithErrors = withScanErrors(ScanLadingBill, 'confirmPacking');

function ConfirmPacking({ activeKey, page }) {
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const barcodeType = scanBarcodeTypeStorage.value;
    const initialValue = scanConfirmPacking.value;
    const showResetConfirmPacking = showResetConfirmPackingStorage.value;
    const [warehouses, setWarehouses] = useState([]);
    const [countryId, setCountryId] = useState(undefined);

    const [typeAction, setTypeAction] = useState('confirm_packing')

    const [scanOrderPackingListInfo, setScanOrderPackingListInfo] = useState(isEmpty(initialValue) ? [] : initialValue);
    const [statusAllow, setStatusAllow] = useState([
        ORDER_PACKING_STATUS.WAITING_PICKING.key,
        ORDER_PACKING_STATUS.WAITING_PACKING.key,
    ]);
    const dispatch = useDispatch();
    const scanErrors = useScanErrorsOfType('confirmPacking');


    useEffect(() => {
        ServiceWarehouses.list()
            .then(res => {
                setWarehouses(res);
            })
            .catch(() => {
                setWarehouses([]);
            });
    }, []);

    useEffect(() => {
        if (activeKey === 'confirm_after_packing') {
            setNavigator(t('title.packing_confirm'), [
                {
                    name: t('breadcrumb.packing_confirm'),
                },
            ]);
            setDocumentTitle(t('title.packing_confirm'));
        }
    }, [activeKey]);

    useEffect(() => {
        if (barcodeType === SCAN_TYPE.ORDER) {
            setStatusAllow([ORDER_PACKING_STATUS.WAITING_PACKING.key, ORDER_PACKING_STATUS.WAITING_PICKING.key]);
        } else {
            setStatusAllow([ORDER_PACKING_STATUS.WAITING_PACKING.key, ORDER_PACKING_STATUS.WAITING_PICKING.key]);
        }
    }, [barcodeType]);

    useEffect(() => {
        let ids_exists = map(scanConfirmPacking.value, 'id');
        let ids_new = [];
        let newCountryId = countryId;
        scanOrderPackingListInfo.map(item => {
            if (!newCountryId) {
                newCountryId = getVar(item, 'receiver_country_id', undefined);
            }
            const id_info = find(ids_exists, ['order_packing_id', item.id]);
            if (isEmpty(id_info)) {
                return ids_new.push({ order_packing_id: item.id });
            } else {
                return ids_new.push({ ...id_info });
            }
        });
        setCountryId(newCountryId);
        scanConfirmPacking.setValue(scanOrderPackingListInfo);
    }, [scanOrderPackingListInfo]);

    useEffect(() => {
        reloadData();
    }, []);

    function getScanOrderPackingListByIds(ids) {
        const params = { warehouse_id: scanWarehouseStorage.value, ids };
        setLoading(true);
        api.getScanOrderPackingList(params)
            .then(res => {
                const data = get(res, 'data.order_packings', []);
                if (!isEmpty(data)) {
                    const newData = convertDataOrderPacking(data, scanConfirmPacking.value);
                    if (newData.length > 0) {
                        setScanOrderPackingListInfo(handleSortListByKey(newData, statusAllow));
                    }
                }
            })
            .catch(err => {
                renderErrorScan({ err, typeAction, params });
            })
            .finally(() => setLoading(false));
    }

    function updateListOfLading(data) {
        const newData = convertDataOrderPacking(data, scanConfirmPacking.value);
        if (newData.length > 0) {
            setScanOrderPackingListInfo(handleSortListByKey([...newData, ...scanOrderPackingListInfo], statusAllow));
        }
    }

    function handleCancelConfirm() {
        showResetConfirmPackingStorage.setValue(0);
        scanConfirmPacking.reset();
        setScanOrderPackingListInfo([]);
    }

    function removeAllOrderPackingNotProcess(data) {
        const new_data = data.filter(item => includes(statusAllow, item.status));
        setScanOrderPackingListInfo(new_data);
        setError(undefined);
    }

    function handleConfirm() {
        const confirmPackingData = scanConfirmPacking.value;
        const doConfirm = () => {
            setError(undefined);
            if (!isEmpty(confirmPackingData)) {
                const data = {
                    warehouse_id: scanWarehouseStorage.getValue(),
                    scan_type: barcodeType,
                    order_packings: map(confirmPackingData, item => pick(item, ['order_packing_id', 'scanned_at'])),
                };
                setLoadingSubmit(true);
                api.confirmOrderPacking(data)
                    .then(res => {
                        const dataPackingDocument = get(res, 'data.document', {});
                        showResetConfirmPackingStorage.setValue(1);
                        notification.success({ message: t('order:message.confirm_packing.success') });
                        const new_data = scanOrderPackingListInfo.map(item => {
                            return { ...item, status: ORDER_PACKING_STATUS.WAITING_DELIVERY.key };
                        });
                        dispatch(clearErrors('confirmPacking'));
                        setScanOrderPackingListInfo(new_data);
                        url.redirectTo('documents.packing.detail', {
                            id: dataPackingDocument?.id,
                        });
                    })
                    .catch(err => {
                        showResetConfirmPackingStorage.setValue(0);
                        const status = get(err, 'response.status');
                        if (status === 400) {
                            let errors = get(err, 'response.data.data', {});
                            Object.keys(errors).forEach(item => {
                                if (item === 'exists_order_packing_not_process') {
                                    let orderPackingsInfo = [...scanOrderPackingListInfo];
                                    const orderPackings = errors[item];
                                    orderPackings.map(orderPacking => {
                                        return (orderPackingsInfo = updateCollectionItem(
                                            orderPackingsInfo,
                                            orderPacking.order_packing_id,
                                            { status: orderPacking.order_packing_status },
                                            'key'
                                        ));
                                    });
                                    const ordersParking = handleSortListByKey(orderPackingsInfo, statusAllow);
                                    setScanOrderPackingListInfo(ordersParking);
                                    setError(
                                        trans(`order:message.confirm_packing.exists_order_packing_not_process`, {
                                            action: (
                                                <a
                                                    className="ml-0"
                                                    onClick={() => removeAllOrderPackingNotProcess(ordersParking)}
                                                >
                                                    <b>{t('order:title.remove_all_order_packing_invalid')}</b>
                                                </a>
                                            ),
                                        })
                                    );
                                } else {
                                    let attribute = t(`order:label.${item}`);
                                    if (item === 'barcode') attribute = t(`order:label.barcode`);
                                    const error_messages = [];
                                    Object.keys(errors[item]).forEach(error =>
                                        error_messages.push(
                                            t(`order:message.scan_order.${error}`, { ...data, attribute })
                                        )
                                    );
                                    setError(error_messages.join(', '));
                                }
                            });
                        } else if (status === 403) notification.error({ message: t('common:message.403') });
                        else if (status === 404) notification.error({ message: t('common:message.404') });
                        else notification.error({ message: t('common:message.server_error') });
                    })
                    .finally(() => setLoadingSubmit(false));
            }
        };

        if (scanErrors?.length) {
            Modal.confirm({
                title: t('order:message.confirm_packing.scan_errors_confirm'),
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
                onOk: doConfirm,
            });
        } else {
            doConfirm();
        }
    }

    function removeScanOrderPackingInfo(ids) {
        setError(undefined);
        const data = filter(scanOrderPackingListInfo, item => {
            const id = get(item, 'id', '');
            return !ids.includes(id);
        });
        setScanOrderPackingListInfo(data);
    }

    function getOrderPackingScan(params) {
        return api.orderPackingScan(params);
    }

    function reloadData() {
        if (!isEmpty(scanConfirmPacking.value)) {
            getScanOrderPackingListByIds(map(scanConfirmPacking.value, 'id'));
        }
    }

    return (
        <div className={clsx(styles['scan-order'])}>
            <Box className={styles.header}>
                <ScanLadingBillWithErrors
                    warehouses={warehouses}
                    errorKey="confirmPacking"
                    typeAction={typeAction}
                    disableBarcode={showResetConfirmPacking}
                    scanOrderListInfo={scanOrderPackingListInfo}
                    setLoadingData={setLoading}
                    getScanInfo={getOrderPackingScan}
                    updateListOfLading={updateListOfLading}
                    disableChange={!isEmpty(scanOrderPackingListInfo) || showResetConfirmPacking}
                    reloadData={reloadData}
                    setTypeAction={setTypeAction}
                    countryId={countryId}
                    page={page}
                />
            </Box>

            <ListOrderInfo
                error={error}
                loading={loading}
                statusAllow={statusAllow}
                loadingSubmit={loadingSubmit}
                dataSource={scanOrderPackingListInfo}
                handleOk={handleConfirm}
                showResetButton={showResetConfirmPacking}
                handleCancel={handleCancelConfirm}
                removeScanOrderInfo={removeScanOrderPackingInfo}
                errorKey="confirmPacking"
                countryId={countryId}
                reloadData={reloadData}
            />
        </div>
    );
}

export default ConfirmPacking;
