import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Table } from 'antd';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useMemo } from 'react';
import { v4 } from 'uuid';

import { t } from '@System/i18n';

import Box from '../Box';
import DeleteButton from '../Buttons/DeleteButton';
import { dateFormatter } from '../services';

const ScanErrors = ({ className, storeErrors, errors, hasError, errorMessageRender }) => {
    const confirmDeleteAll = () => {
        Modal.confirm({
            title: t('confirm_delete_all_scan_errors'),
            cancelText: t('btn.cancel'),
            okText: t('btn.ok'),
            onOk: () => storeErrors.clear(),
        });
    };
    const handleDeleteError = useCallback(index => storeErrors.remove(index), [storeErrors]);
    const formattedErrors = useMemo(() => {
        return errors.map(error => {
            let scanInfo = {};
            let keyValuePairs = error.key.split('-');
            for (let keyValuePair of keyValuePairs) {
                const [key, value] = keyValuePair?.split(':');
                scanInfo[key] = value;
            }
            return { ...scanInfo, ...error };
        });
    }, [errors]);

    const columns = useMemo(
        () => [
            {
                className: '_scan-code-column',
                title: t('scan_code'),
                key: 'barcode',
                dataIndex: 'barcode',
            },
            {
                className: '_error-content-column',
                title: t('error_content'),
                key: 'code_type',
                dataIndex: 'message',
                render: text => {
                    return isFunction(errorMessageRender) ? errorMessageRender(text) : text;
                },
            },
            {
                className: '_scan-time-column',
                title: t('scan_time'),
                key: 'scan_time',
                dataIndex: 'created_at',
                render: text => (text ? dateFormatter.full(text) : null),
            },
            {
                className: '_actions-column text-right',
                key: 'actions',
                render: (_, __, index) => (
                    <DeleteButton className="_delete-btn" onClick={handleDeleteError.bind(this, index)}>
                        {t('btn.delete')}
                    </DeleteButton>
                ),
            },
        ],
        [errorMessageRender, handleDeleteError]
    );

    if (!hasError) return null;

    return (
        <Box className={clsx('scan-errors mb-3 _scan-errors', className)}>
            <div className="d-flex align-items-center justify-content-between">
                <h3 className="text-fz-16 text-red">{t('scan_error_turn')}</h3>
                {errors?.length > 0 && (
                    <Button className="_delete-all-btn" onClick={confirmDeleteAll}>
                        <DeleteOutlined /> {t('delete_all')}
                    </Button>
                )}
            </div>

            <Table
                columns={columns}
                dataSource={formattedErrors}
                pagination={false}
                scroll={{ x: 576 }}
                rowKey={_ => v4()}
            />
        </Box>
    );
};

export default ScanErrors;
