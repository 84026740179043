import { CloseOutlined } from '@ant-design/icons';
import { Table, Space, Typography } from 'antd';
import get from 'lodash/get';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@Modules/App/Box';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { pageSizeOptions } from '@Modules/App/services/constants';
import { getPageSizeScale } from '@Modules/App/services/helpers';
import { auth } from '@Modules/Auth/services';

import { t } from '@System/i18n';
import { url } from '@System/routing';

import styles from '../scan-purchase-package.module.scss';

const { Title } = Typography;

function PackageList({ storeActions, storeData }) {
    const dataSource = get(storeData, 'purchase_packages', []);
    const isSaved = get(storeData, 'isSaved', false);
    const [currentData, setCurrentData] = useState([]);
    const pagination = {
        pageSizeOptions: pageSizeOptions,
        locale: { items_per_page: '' },
        defaultPageSize: getPageSizeScale(),
        defaultCurrent: 1,
        showSizeChanger: true,
    };

    useEffect(() => {
        setCurrentData(dataSource);
    }, [storeData]);

    function _onDelete(purchasePackageIds) {
        storeActions.deletePurchasePackage(purchasePackageIds);
    }

    const columns = [
        {
            className: '_package-list-numerical-order',
            title: t('order:label.numerical_order'),
            dataIndex: 'stt',
            key: 'stt',
            responsive: ['xl'],
            render: (text, record, index) => {
                return <p>{index + 1}</p>;
            },
        },
        {
            className: '_package-list-package-code',
            title: t('order:label.package_code'),
            dataIndex: 'code',
            key: 'purchasing_package_code',
        },
        {
            className: '_package-list-freight-bill-code',
            title: t('order:label.freight_bill_code'),
            dataIndex: 'freight_bill_code',
            key: 'freight_bill_code',
        },
        {
            className: '_package-list-sku-info',
            title: t('order:label.sku_info'),
            dataIndex: 'skus',
            key: 'sku_info',
            render: text => {
                return text.map((item, index) => {
                    return (
                        <p key={index}>
                            <Link to={url.to('products.detail', { id: item?.product_id })}>{item?.code}</Link>
                        </p>
                    );
                });
            },
        },
        {
            className: '_package-list-quantity text-right',
            title: t('order:label.quantity'),
            dataIndex: 'skus',
            key: 'quantity',
            render: text => {
                return text.map((item, index) => {
                    return <p key={index}>{item?.received_quantity}</p>;
                });
            },
        },
        {
            className: '_package-list-scanner-info',
            title: t('order:label.scanner'),
            dataIndex: 'scanned_at',
            key: 'scanned_at',
            render: (text, record) => {
                const user = auth.user();
                return (
                    <>
                        <p>
                            {get(user, 'name', '')}({get(user, 'username', '')})
                        </p>
                        <p>{record.scanned_at ? moment(record.scanned_at).format('DD/MM/YYYY HH:mm') : ''}</p>
                    </>
                );
            },
        },
        {
            className: '_package-list-purchase-package-key',
            title: '',
            dataIndex: 'purchase_package_key',
            key: 'purchase-package-key',
            width: '50px',
            render: text =>
                !isSaved ? (
                    <div className="d-flex justify-content-end">
                        <span
                            className="_span--delete cursor-pointer _package-list-delete-btn d-flex align-items-center justify-content-center"
                            onClick={() => _onDelete([text])}
                        >
                            <CloseOutlined />
                        </span>
                    </div>
                ) : null,
        },
    ];

    return (
        <Box className={styles.main}>
            <div className="d-flex flex-wrap justify-content-between mb-3 align-items-center">
                <Space className="mb-3 order-0 mb-xl-0">
                    <Title level={3}>{t(`document:import_by_purchase_package.title.${storeData.barcode_type}`)}</Title>
                    <span className="number-circle _package-list-total">{currentData.length}</span>
                </Space>
            </div>

            <div className="border-top">
                <Table
                    rowKey={record => record.purchase_package_key}
                    className="_package-list-table"
                    dataSource={currentData}
                    columns={columns}
                    pagination={false}
                    scroll={{ x: 576 }}
                    locale={{ emptyText: <TableEmpty className="_package-list-no-data" /> }}
                />
            </div>
        </Box>
    );
}

export default PackageList;
