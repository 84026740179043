import React from 'react';
import {Row, Col, Button, Select} from 'antd';
import {t} from "../../../../../system/i18n";
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";
import SelectMerchants from "../../../../Merchant/components/SelectMerchants";
import _ from "lodash";

const {Option} = Select;

function FormSearch({setFilters, filters, stocks, applyFilters}) {
    const {
        warehouse_id,
        warehouse_area_id,
        merchant_id
    } = filters || {};

    function _onChange(name, value) {
        setFilters({...filters, [name]: value});
    }

    function _onSubmit() {
        applyFilters(filters);
    }

    function _onReset() {
        setFilters({});
        applyFilters({});
    }

    function getWarehouses() {
        let warehouses = _.map(stocks, 'warehouse');
        let warehouseIds = [];
        return _.sortBy(warehouses.filter(warehouse => {
            if (warehouseIds.includes(warehouse.id))
                return false;
            else {
                warehouseIds.push(warehouse.id);
                return true;
            }
        }), ["id"]);
    }

    function getWarehouseAreas() {
        let warehouseAreas = _.map(stocks, 'warehouseArea');
        let warehouseAreaIds = [];
        if (filters.warehouse_id)
            return _.sortBy(warehouseAreas.filter(warehouseArea => {
                if (!warehouseAreaIds.includes(warehouseArea.id) && warehouseArea.warehouse_id === filters.warehouse_id) {
                    warehouseAreaIds.push(warehouseArea.id);
                    return true;
                } else {
                    return false;
                }
            }), ["id"]);
        else
            return [];
    }

    return (
        <Row gutter={10} className="p-4 border-bottom">
            <Col xs={{span: 19}}>
                <Row gutter={10}>
                    <Col xs={{span: 12}} lg={{span: 8}}>
                        <Select
                            style={{width: "100%"}}
                            placeholder={t("product:placeholder.select_warehouse")}
                            onChange={value => _onChange("warehouse_id", value)}
                            value={warehouse_id}
                        >
                            {
                                getWarehouses().map(warehouse => {
                                    return <Option value={warehouse.id}>{warehouse.code} - {warehouse.name}</Option>
                                })
                            }
                        </Select>
                    </Col>

                    <Col xs={{span: 12}} lg={{span: 8}}>
                        <Select
                            style={{width: "100%"}}
                            placeholder={t("product:placeholder.select_warehouse_area")}
                            onChange={value => _onChange("warehouse_area_id", value)}
                            value={warehouse_area_id}
                        >
                            {
                                getWarehouseAreas().map(warehouseArea => {
                                    return <Option value={warehouseArea.id}>{warehouseArea.code === 'default' ? t("label.default") : warehouseArea.code} - {warehouseArea.name === 'default' ? t("label.default") : warehouseArea.name}</Option>
                                })
                            }
                        </Select>
                    </Col>

                    <Col xs={{span: 12}} lg={{span: 8}}>
                        <SelectMerchants
                            placeholder={t("product:placeholder.select_merchant")}
                            name="merchant_id"
                            onChange={value => _onChange("merchant_id", value)}
                            value={merchant_id}
                            style={{width: "100%"}}
                        />
                    </Col>
                </Row>
            </Col>

            <Col xs={{span: 5}}>
                <Row gutter={10}>
                    <Col xs={{span: 12}}>
                        <Button
                            type="primary"
                            onClick={_onSubmit}
                            style={{width: "100%"}}
                        ><SearchOutlined/> {t("common:btn.search")}</Button>
                    </Col>
                    <Col xs={{span: 12}}>
                        <Button
                            onClick={_onReset}
                            style={{width: "100%"}}
                        ><UndoOutlined/> {t("common:btn.reset")}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default FormSearch;
