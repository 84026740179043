import { Button, Col, Form, Input, Row, Typography } from 'antd';
import React, { useState } from 'react';

import { STATUS_COLORS } from '@Modules/Document/services/constants';

import { trans } from '../../../../../system/i18n';
import StyledTag from '../../../../App/StyledTag';
import { dateFormatter } from '../../../../App/services';
import useUpdateImportDocumentMutation from '../../../Hooks/useUpdateImportingDocumentMutation';
import { STATUSES } from '../../../constants';

const { Paragraph } = Typography;

const Header = ({ form, importingDocument, handleDelete, handleImport, onSuccessUpdate, handleSave, isChanged, updatingSku, isImporting}) => {
    const [editing, setEditing] = useState(false);

    const { isLoading: updating, mutate: updateImportingDocument } = useUpdateImportDocumentMutation({
        id: importingDocument?.id,
        onSuccess: data => {
            setEditing(false);
            onSuccessUpdate(data);
        },
    });

    const handleUpdateButtonClick = () => {
        updateImportingDocument(form.getFieldsValue());
    };

    return (
        <Form className="bg-white mb-3 p-3" form={form}>
            <div className="w-100">
                <div className="mb-3 d-flex flex-column flex-lg-row justify-content-lg-between">
                    <div className="d-flex mb-3 mb-lg-0 flex-wrap">
                        <div className="mr-lg-3 mb-3">
                            <div className="d-flex">
                                <StyledTag
                                    className="mb-1 _importing-document-status"
                                    type={importingDocument?.status === STATUSES.COMPLETED && 'success'}
                                    color={importingDocument?.status ? STATUS_COLORS[importingDocument?.status] : undefined}
                                >
                                    {trans(`document:statuses_type.IMPORTING.${importingDocument?.status}`)}
                                </StyledTag>
                                <Paragraph className="font-weight-bold text-fz-16 mb-0 mr-2 _importing-document-code">
                                    {trans('document:types.importing')}: #{importingDocument?.code}
                                </Paragraph>
                            </div>
                            <div className="_importing-document-created-by">
                                {trans('document:created_by', {
                                    date: dateFormatter.date(importingDocument?.created_at),
                                    confirmer: importingDocument?.creator_username || '--',
                                })}
                            </div>
                        </div>
                        <div className="ml-lg-auto">
                            {importingDocument?.status === STATUSES.DRAFT && (
                                <Button danger onClick={handleDelete} className="_importing-document-delete">
                                    {trans('document:delete.title')}
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className="d-flex flex-wrap">
                        {editing ? (
                            <Button
                                className="_importing-document-save-sender-info"
                                loading={updating}
                                type="primary"
                                onClick={handleUpdateButtonClick}
                            >
                                {trans('document:btn.save_delivery_person_info')}
                            </Button>
                        ) : (
                            <>
                                {importingDocument?.status === STATUSES.DRAFT && (
                                    <>
                                        <Button
                                            className="mb-3 _importing-document-edit-sender-info"
                                            onClick={setEditing.bind(this, true)}
                                        >
                                            {trans('document:btn.edit_delivery_person_info')}
                                        </Button>

                                        <Button
                                            className="_importing-document-btn-save ml-3"
                                            onClick={handleSave}
                                            loading={updatingSku}
                                        >
                                            {trans('btn.save')}
                                        </Button>
                                        <Button
                                            className="ml-3 _importing-document-btn-confirm"
                                            type="primary"
                                            onClick={handleImport}
                                            loading={isImporting}
                                        >
                                            {isChanged ? trans('btn.save_and_confirm') : trans('btn.confirm')}
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>

                {editing ? (
                    <Row gutter={72}>
                        <Col className="mb-3 mb-lg-0" lg={{ span: 15 }} span={24}>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <div className="mb-2 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                                        {trans('document:label.sender_info')}
                                    </div>
                                </Col>

                                <Col className="mb-3 mb-lg-0" md={{ span: 8 }} span={24}>
                                    <Form.Item className="mb-0" name="sender_name">
                                        <Input
                                            className="_importing-document-input-sender-name"
                                            placeholder={trans('document:label.sender_name')}
                                            tabIndex={1}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="mb-3 mb-lg-0" md={{ span: 8 }} span={24}>
                                    <Form.Item className="mb-0" name="sender_phone">
                                        <Input
                                            className="_importing-document-input-sender-phone"
                                            placeholder={trans('document:label.sender_phone')}
                                            tabIndex={2}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col md={{ span: 8 }} span={24}>
                                    <Form.Item className="mb-0" name="sender_license">
                                        <Input
                                            className="_importing-document-input-sender-license"
                                            placeholder={trans('document:label.sender_license')}
                                            tabIndex={3}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col lg={{ span: 9 }} span={24}>
                            <div className="mb-2 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                                {trans('document:label.sender_company')}
                            </div>

                            <Form.Item className="mb-0" name="sender_partner">
                                <Input tabIndex={4} className="_importing-document-input-sender-company" />
                            </Form.Item>
                        </Col>
                    </Row>
                ) : (
                    <Row>
                        <Col lg={{ span: 18 }} span={24}>
                            <Row gutter={16}>
                                <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                    <Paragraph className="mb-0 text-grey">{trans('full_name')}</Paragraph>
                                    <Paragraph className="mb-0 _importing-document-sender-name">
                                        {importingDocument?.info?.sender_name}
                                    </Paragraph>
                                </Col>

                                <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                    <Paragraph className="mb-0 text-grey">{trans('phone')}</Paragraph>
                                    <Paragraph className="mb-0 _importing-document-sender-phone">
                                        {importingDocument?.info?.sender_phone}
                                    </Paragraph>
                                </Col>

                                <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                    <Paragraph className="mb-0 text-grey">{trans('document:placeholder.license')}</Paragraph>
                                    <Paragraph className="mb-0 _importing-document-sender-license">
                                        {importingDocument?.info?.sender_license}
                                    </Paragraph>
                                </Col>

                                <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                    <Paragraph className="mb-0 text-grey">{trans('document:label.sender_company')}</Paragraph>
                                    <Paragraph className="mb-0 _importing-document-sender-company">
                                        {importingDocument?.info?.sender_partner}
                                    </Paragraph>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </div>
        </Form>
    );
};

export default Header;
