import { Tabs } from 'antd';
import React, { useState } from 'react';

import ConfirmPacking from '@Modules/ScanOrder/screens/Packing/ConfirmPacking';
import PackingPurchasingPackage from '@Modules/ScanOrder/screens/Packing/PurchasingPackage';
import { auth } from '@Modules/Auth/services';
import { t } from '@System/i18n';

import permissions from '@Modules/Auth/services/permissions';

const { TabPane } = Tabs;
function ScanPacking() {
    const [activeKey, setActiveKey] = useState('packing_purchasing_package');
    return (
        <div className="site-content">
            <Tabs className="customize-ant-tabs" defaultActiveKey={activeKey} type="card" onTabClick={key => setActiveKey(key)}>
                <TabPane tab={t('packing:title.packing_purchasing_package')} key="packing_purchasing_package">
                    <PackingPurchasingPackage page="packing_purchasing_package"/>
                </TabPane>
               {auth.can(permissions.OPERATION_SCAN_AFTER_PACKAGED) && <TabPane tab={t('packing:title.confirm_after_packing')} key="confirm_after_packing">
                    <ConfirmPacking activeKey={activeKey} page="confirm_after_packing"/>
                </TabPane>}
            </Tabs>
        </div>
    );
}

export default ScanPacking;
