import { ArrowLeftOutlined } from '@ant-design/icons'
import { Row, Col, notification, Tabs, Divider } from 'antd'
import { get, isEmpty, uniqWith } from 'lodash'
import React, { useEffect, useState } from 'react'

import Loading from '@Modules/App/Loading'
import DocumentList from '@Modules/Order/screens/OrderDetail/DocumentList'

import { getVar } from '@System/support/helpers'

import { events } from '../../../../system/events'
import { t } from '../../../../system/i18n'
import CurrencyFormat from '../../../../system/support/CurrencyFormat'
import { setDocumentTitle, setNavigator } from '../../../App/services'
import api from '../../services/api'
import { ORDER_STATUS } from '../../services/constants'
import { EVENTS } from '../../services/constants'
import CheckProducts from './CheckProducts'
import CustomerInfo from './CustomerInfo'
import InspectionProducts from './InspectionProducts'
import OrderInfo from './OrderInfo'
import PaymentInfo from './PaymentInfo'
import ProductList from './ProductList'
import { useSettingConfigQuery } from '@Modules/OperationConfig/hooks/useSettingConfigQuery'

const { TabPane } = Tabs
export default function OrderDetail({ match, history }) {
    const { params } = match
    const [orderDetail, setOrderDetail] = useState(null)
    const [loading, setLoading] = useState(false)
    const [skus, setSkus] = useState([])
    const {data: setting} = useSettingConfigQuery()

    const { order, canInspection, orderSkus, orderStocks, warehouse } = orderDetail || {}

    useEffect(() => {
        setLoading(true)
        fetchOrder().finally(() => setLoading(false))
        setNavigator(t('common:title.order-detail'), [
            {
                name: t('common:breadcrumb.orders'),
                route: 'orders.list',
            },
            {
                name: t('common:breadcrumb.order-detail'),
            },
        ])

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setDocumentTitle(t('title_page.order_detail', { order_code: order?.code }))
    }, [order])

    useEffect(() => {
        return events.listen(EVENTS.FETCH_ORDER_DETAIL_AGAIN, () => fetchOrder())

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        return events.listen(EVENTS.UPDATE_SHIPPING_PARTNER_SUCCESSFULL, (_, payload) => setOrderDetail(payload))
    }, [])

    /**
     * Format dữ liệu kiểm hàng trước khi truyền vào bảng kiểm hàng
     */

    useEffect(() => {
        if (orderSkus && orderSkus.length > 0) {
            const products = orderSkus.map(item => {
                let orderStock = orderStocks.find(stock => stock.orderStock.sku_id === item.sku.id)
                let stack = {
                    id: Date.now(),
                    warehouse_id: getVar(warehouse, 'id', ''),
                    warehouse_area_id: '',
                    quantity: '',
                    stock_quantity: '',
                    warehouse,
                }

                if (orderStock) {
                    let stock = get(orderStock, 'orderStock', {})
                    stack = {
                        id: stock.id,
                        warehouse_id: stock.warehouse_id,
                        warehouse_area_id: stock.warehouse_area_id,
                        quantity: stock.quantity,
                        stock_quantity: stock.quantity,
                        warehouse,
                    }
                }

                return {
                    ...item.orderSku,
                    sku: item.sku,
                    stocks: item.stocks,
                    unit: item.unit,
                    warehouses: item.warehouses,
                    product: item.product,
                    stacks: [stack],
                }
            })

            const newData = mergeSku(products)

            setSkus(newData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderSkus, orderStocks])

    let mergeSku = array => {
        return uniqWith(array, (pre, cur) => {
            if (pre.sku_id === cur.sku_id) {
                cur.quantity = cur.quantity + pre.quantity
                return true
            }
            return false
        })
    }
    function fetchOrder() {
        return api
            .getOrder(params.id)
            .then(res => {
                // Thiết lập định dạng tiền tệ cho đơn hàng theo thị trường
                CurrencyFormat.setCurrency(get(res, 'data.currency', null))
                setOrderDetail(get(res, 'data'))
                // getReImport();
            })
            .catch(err => {
                const status = get(err, 'response.status', null)
                if (status === 403) notification.error({ message: t('common:message.403') })
                else if (status === 404) notification.error({ message: t('common:message.404') })
                else
                    notification.error({
                        message: t('common:message.fetch_fail', { attribute: t('label.order_detail') }),
                    })
            })
    }

    // function getReImport() {
    //     setLoadingReImports(true);
    //     return api.getReImport(params.id)
    //         .then(res => {
    //             setReImport(_.get(res, "data.reimportSkus", []));
    //         })
    //         .catch(err => {
    //             const status = _.get(err, "response.status", null);
    //             if (status === 403)
    //                 notification.error({message: t('common:message.403')});
    //             else if (status === 404)
    //                 notification.error({message: t('common:message.404')});
    //             else
    //                 notification.error({message: t('common:message.fetch_fail')});
    //         })
    //         .finally(() => {
    //             setLoadingReImports(false);
    //         });
    // }

    function activeTab() {
        if (order.status === ORDER_STATUS.CANCELED) return '1'

        return '2'
    }

    const handleSuccessUpdate = () => {
        fetchOrder()
    }

    if (loading) {
        return <Loading />
    }

    if (orderDetail === null) return null

    return (
        <div className="site-content order-detail">
            <div className="title-page d-flex justify-content-between align-items-center pb-3">
                <span
                    className="cursor-pointer"
                    onClick={() => history.goBack()}
                >
                    <ArrowLeftOutlined /> {t('btn.back')}
                </span>
            </div>
            <div>
                <Row gutter={20}>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 17 }}
                    >
                        <CustomerInfo orderDetail={orderDetail} setting={setting?.data} />

                        <Tabs
                            className="heading-fz-14 customize-order-ant-tabs mt-3"
                            defaultActiveKey={activeTab()}
                            type="card"
                        >
                            <TabPane
                                tab={t('order:title_box.title_list')}
                                key="1"
                            >
                                <ProductList orderDetail={orderDetail} />
                            </TabPane>
                            <TabPane
                                tab={t('order:title_box.title_check')}
                                key="2"
                            >
                                {(order.status === ORDER_STATUS.WAITING_INSPECTION && canInspection) ||
                                (order.status === ORDER_STATUS.WAITING_CONFIRM && isEmpty(orderStocks)) ||
                                (order.status === ORDER_STATUS.WAITING_PROCESSING && orderStocks.length < orderSkus.length) ||
                                (order.status === ORDER_STATUS.WAITING_PICKING && orderStocks.length < orderSkus.length) ? (
                                    <CheckProducts
                                        orderDetail={orderDetail}
                                        setOrderDetail={setOrderDetail}
                                        skus={skus}
                                        setSkus={setSkus}
                                        fetchOrder={fetchOrder}
                                    />
                                ) : (
                                    <InspectionProducts orderDetail={orderDetail} />
                                )}
                            </TabPane>
                            {/*{*/}
                            {/*    reImports.length > 0 &&*/}
                            {/*    <TabPane tab={t("orderDetail:label.reImportInfo")} key="4">*/}
                            {/*        <ReImportInfo*/}
                            {/*            reImports={reImports}*/}
                            {/*            getReImport={getReImport}*/}
                            {/*            loadingReImports={loadingReImports}*/}
                            {/*        />*/}
                            {/*    </TabPane>*/}
                            {/*}*/}
                            <TabPane
                                tab={t('order:title_box.title_document')}
                                key="5"
                            >
                                <DocumentList orderDetail={orderDetail} />
                            </TabPane>
                        </Tabs>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 7 }}
                    >
                        <div className="bg-white rounded-12 p-4 mt-3 mt-lg-0">
                            <OrderInfo
                                orderDetail={orderDetail}
                                onSuccessUpdate={handleSuccessUpdate}
                                setting={setting?.data}
                            />

                            <Divider
                                className="mt-3 mb-3"
                                style={{ borderTop: '1px solid #D9DBE9' }}
                            />

                            <PaymentInfo orderDetail={orderDetail} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
