import { Button, Divider, Form, Input, Modal, Table } from 'antd'
import { capitalize, get, isEmpty, map } from 'lodash'
import React, { useState, useEffect } from 'react'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import useServiceListQuery from '@Modules/ServicesManager/Hook/useServiceListQuery'
import api from '@Modules/ServicesManager/services/api'
import { EVENTS } from '@Modules/ServicesManager/services/constants'

import { events } from '@System/events'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { formatCurrency } from '@System/support/numberFormat'

import AddService from './AddService'

export interface IError {
    message: string
    error: boolean
}

const CreateServicePack = ({ countryId, handleHideModal }) => {
    const [form] = Form.useForm()

    const [serviceGroup, setServiceGroup] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [currency, setCurrency] = useState({} as any)
    const [services, setServices] = useState([])
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState<IError[]>([])
    const [isEmptyGroup, setIsEmptyGroup] = useState(false)
    const { data: response, isFetching } = useServiceListQuery({ country_id: countryId, status: 'ACTIVE' })

    useEffect(() => {
        setServices(get(response, 'data.services'))
        setCurrency(get(response, 'data.currency'))
    }, [response])

    const columns = [
        {
            className: ` _manage-service-column-type`,
            title: t('label.service_groups'),
            dataIndex: ['service', 'type'],
            key: 'type',
            render: text => <span>{t(`service_manage_status.${text}`)}</span>,
        },
        {
            className: ` _manage-service-column-name`,
            title: t('label.service_name'),
            dataIndex: ['service', 'name'],
            key: 'name',
        },
        {
            className: ` _manage-service-column-service_code`,
            title: t('label.service_code'),
            dataIndex: ['service', 'code'],
            key: 'code',
        },
        {
            className: `_manage-service-column-price_level`,
            title: t('label.price_level'),
            dataIndex: ['service_price', 'label'],
            key: 'servicePrices',
            render: (text, record) => {
                const price = get(record, 'service_price.price')
                return <span>{text ? `${text} - ${isEmpty(currency) ? price : formatCurrency(price || 0, currency)}` : t('noText')}</span>
            },
        },
        {
            className: `_manage-service-column-additional_costs`,
            title: t('label.additional_costs'),
            dataIndex: ['service_price', 'yield_price'],
            key: 'servicePrices',
            render: text => {
                return <span>{isEmpty(currency) ? text : formatCurrency(text || 0, currency)}</span>
            },
        },
        {
            className: `_manage-service-column-note`,
            title: t('note'),
            dataIndex: ['servicePrices'],
            key: 'servicePrices',
            render: text => {
                return <span>{get(text, '0.note') ? get(text, '0.note') : t('noText')}</span>
            },
        },
        {
            className: `_manage-service-column-func`,
            dataIndex: ['service', 'id'],
            key: 'note',
            render: text => {
                return (
                    <Button
                        danger
                        onClick={() => handleRemoveRow(text)}
                    >
                        {t('btn.delete')}
                    </Button>
                )
            },
        },
    ]

    const handleCreateService = () => {
        form.validateFields().then(values => {
            const { code, name } = values

            if (isEmpty(serviceGroup)) {
                setIsEmptyGroup(true)
            }

            if (!isEmpty(selectedList)) {
                const errorList: IError[] = map(selectedList, (item: any) => {
                    let message = ''

                    if (item?.idPrice) {
                        message = t('message.service_has_been_saved')
                    } else if (!isEmpty(item.servicePrices)) {
                        message = t('order:message.required', { attribute: t('label.price_level') })
                    }

                    return {
                        message: message,
                        error: true,
                    }
                })

                setErrors(errorList)
                return
            }

            const data = {
                country_id: countryId,
                code,
                name,
                service_price_ids: map(serviceGroup, 'idPrice'),
            }

            setLoading(true)
            api.createServicePack(data)
                .then(res => {
                    notification.success(t('message.create_service_pack_success'))
                    handleHideModal()
                    events.dispatch(EVENTS.REFETCH_SERVICE_PACK, {})
                })
                .catch(error => {
                    const {
                        response: { data },
                    } = error
                    renderError(data)
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    const renderError = error => {
        const { data } = error
        if (!isEmpty(data)) {
            Object.keys(data).forEach(key => {
                Object.keys(data[key]).forEach(error => {
                    if (key === 'code') {
                        notification.error(t(`message.${error}`, { attribute: t(`label.service_code`) }))
                        return
                    }

                    if (error === 'missing') {
                        notification.error(t(`message.missing`, { attribute: `4 ${capitalize(t(`label.service_groups`))}` }))
                        return
                    }
                })
            })
        } else {
            notification.error(t('message.create_service_pack_failed'))
        }
    }

    const handleRemoveRow = id => {
        setServiceGroup(prev => prev.filter((item: any) => item.service.id !== id))
    }

    return (
        <Modal
            title={t('btn.create_service_pack')}
            visible={true}
            okText={t('btn.ok')}
            cancelText={t('btn.cancel')}
            onCancel={handleHideModal}
            onOk={handleCreateService}
            width={1000}
            maskClosable={false}
            okButtonProps={{ loading: loading }}
        >
            <Form
                name="dynamic_form_nest_item"
                form={form}
            >
                <Form.Item
                    label={t('label.service_name')}
                    name="name"
                    wrapperCol={{
                        span: 8,
                    }}
                    rules={[
                        {
                            required: true,
                            message: t('order:message.required', { attribute: t('label.service_name') }),
                        },
                    ]}
                >
                    <Input
                        allowClear
                        placeholder={t('placeholder.service_name')}
                    />
                </Form.Item>
                <Form.Item
                    label={t('label.service_code')}
                    name="code"
                    wrapperCol={{
                        span: 8,
                    }}
                    rules={[
                        {
                            required: true,
                            message: t('order:message.required', { attribute: t('label.service_code') }),
                        },
                    ]}
                >
                    <Input
                        allowClear
                        placeholder={t('placeholder.service_code')}
                    />
                </Form.Item>
                <Divider />
                <AddService
                    setServiceGroup={setServiceGroup}
                    serviceGroup={serviceGroup}
                    selectedList={selectedList}
                    setSelectedList={setSelectedList}
                    currency={currency}
                    isFetching={isFetching}
                    services={services}
                    errors={errors}
                    setErrors={setErrors}
                    setIsEmptyGroup={setIsEmptyGroup}
                />
                <Table
                    rowKey={(record: any) => record?.service?.id}
                    rowClassName={'service-list-table'}
                    dataSource={serviceGroup}
                    scroll={{ x: 768 }}
                    columns={columns}
                    pagination={false}
                    locale={{
                        emptyText: (
                            <TableEmpty
                                className="_product-list-empty"
                                loading={undefined}
                            />
                        ),
                    }}
                />
                <div>{isEmptyGroup && <p className="text-red mt-2">{t('message.need_select_service')}</p>}</div>
            </Form>
        </Modal>
    )
}

export default CreateServicePack
