import { Card, Form, Modal, Table } from 'antd';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import Loading from '@Modules/App/Loading';
import ModalConfirm from '@Modules/App/Modal/ModalConfirm';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useDeleteExportingDocumentMutation from '@Modules/Document/Hooks/useDeleteExportingDocumentMutation';
import useExportMutation from '@Modules/Document/Hooks/useExportMutation';
import useExportingDocumentQuery from '@Modules/Document/Hooks/useExportingDocumentQuery';

import { t, trans } from '@System/i18n';
import notification from '@System/notification';
import { getVar } from '@System/support/helpers';

import Body from './Body';
import Header from './Header';

const Detail = ({ match }) => {
    const [exportingDocument, setExportingDocument] = useState();
    const [orderExportings, setOrderExportings] = useState([]);

    const [visibleCancel, setVisibleCancel] = useState(false);
    const [form] = Form.useForm();
    const { isError, isLoading, data: exportingDocumentData, error } = useExportingDocumentQuery(match.params.id);
    const { mutateAsync: deleteExportingDocument, isLoading: isDeleting } = useDeleteExportingDocumentMutation({
        id: match.params.id,
        onSuccess: data => {
            setExportingDocument(data);
            setOrderExportings(data?.order_exportings);
            setVisibleCancel(false);
        },
    });
    const { mutateAsync: exportDocument, isLoading: isExporting } = useExportMutation({
        id: match.params.id,
        notifyWhenError: false,
        onSuccess: data => {
            setExportingDocument(data);
            setOrderExportings(data?.order_exportings);
        },
        onError: error => {
            const message = getVar(error, 'response.data.data', '');

            if ('insufficient' in message) {
                const insufficient = getVar(error, 'response.data.data.insufficient', {});
                showModalErrorSku(insufficient);
                notification.error(t('document:export_warehouse.insufficient'));
            } else {
                notification.error(t('document:export_warehouse.failed'));
            }
        },
    });

    const showModalErrorSku = (data = []) => {
        const columns = [
            {
                title: t('SKU'),
                dataIndex: 'sku',
                key: 'sku',
            },
            {
                title: t('quantity'),
                key: 'real_quantity',
                dataIndex: 'real_quantity',
            },
        ];

        Modal.confirm({
            title: t('order:title.out_of_sock_list'),
            cancelText: t('btn.close'),
            okButtonProps: { style: { display: 'none' } },
            icon: false,
            width: 600,
            content: (
                <>
                    <Table
                        columns={columns}
                        className="_out_of_sock_list-table mt-3"
                        rowKey={record => record?.stock_id}
                        dataSource={data}
                        pagination={false}
                    />
                </>
            ),
        });
    };

    const handleExport = useCallback(() => {
        Modal.confirm({
            cancelText: trans('btn.cancel'),
            content: trans('document:export_warehouse.confirm'),
            okText: trans('btn.confirm'),
            onOk: exportDocument,
            cancelButtonProps: {
                className: '_modal-confirm-export-warehouse-btn-cancel',
            },
            okButtonProps: {
                className: '_modal-confirm-export-warehouse-btn-ok',
            },
        });
    }, [exportDocument]);

    const handleCancel = () => {
        setVisibleCancel(true);
    };

    const handleSuccessUpdate = useCallback(data => {
        setExportingDocument(data);
    }, []);

    useEffect(() => {
        setExportingDocument(exportingDocumentData?.data);
        setOrderExportings(exportingDocumentData?.data?.order_exportings);
    }, [exportingDocumentData]);

    useEffect(() => {
        form.setFieldsValue({
            receiver_name: exportingDocument?.document_exporting?.info?.receiver_name,
            receiver_phone: exportingDocument?.document_exporting?.info?.receiver_phone,
            receiver_license: exportingDocument?.document_exporting?.info?.receiver_license,
            partner: exportingDocument?.document_exporting?.info?.partner,
        });

        let document_code = _.get(exportingDocument, 'document_exporting.code', '');
        setDocumentTitle(t('title_page.document_exporting', { document_code: document_code }));
        // eslint-disable-next-line
    }, [exportingDocument]);

    useEffect(() => {
        setNavigator(trans('document:types.exporting'), [
            {
                name: trans('document:list.exporting'),
                route: 'documents.exporting.list',
            },
            {
                name: trans('document:detail.exporting'),
            },
        ]);
    }, []);

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <>
            <Card
                className="flex-grow-1 bg-white m-4"
                title={
                    <Header
                        form={form}
                        exportingDocument={exportingDocument}
                        onDelete={handleCancel}
                        onExport={handleExport}
                        onSuccessUpdate={handleSuccessUpdate}
                        isExporting={isExporting}
                    />
                }
            >
                <Body exportingDocument={exportingDocument} orderExportings={orderExportings} />
            </Card>

            <ModalConfirm
                content={t('document:delete.confirm')}
                handleHideModal={() => setVisibleCancel(false)}
                visible={visibleCancel}
                cancelButtonProps={{ className: '_modal-confirm-delete-exporting-document-btn-cancel' }}
                okButtonProps={{
                    className: '_modal-confirm-delete-exporting-document-btn-ok',
                    loading: isDeleting,
                    onClick: deleteExportingDocument,
                }}
            />
        </>
    );
};

export default Detail;
