import { Button, Col, Form, Input, Row } from 'antd';
import { filter, isEmpty, map } from 'lodash';
import React, { useRef, useState } from 'react';

import ScanBarcode from '@Modules/ScanOrder/components/ScanBarcode';

import { t } from '@System/i18n';

import SelectWarehouseItems from '../../../Warehouse/components/SelectWarehouseItems';
import { UndoOutlined } from '@ant-design/icons';
import { events } from '@System/events';

const FormSearch = ({ _onChange, warehouse_id, warehouseAreaList, warehouse_area_id, setInput, setChildrenReset }) => {
    const [form] = Form.useForm();

    const [isScanning, setIsScanning] = useState(false);

    const barcodeInputRef = useRef();

    const scanData = data => {
        setIsScanning(true)
        setChildrenReset('')
        const id_location = map(filter(warehouseAreaList, item => item.code === data), 'id')

        _onChange('warehouse_area_id', id_location[0])

        form.setFields([
            {
                name: 'barcode',
                errors: isEmpty(id_location) ? [t('warehouseArea:message.location_invalid')] : false,
            },
        ]);
        setIsScanning(false)
    };

    const _onReset = () => {
        setInput({})
        form.resetFields()
        setChildrenReset('RESET')
    }

    return (
        <div className="bg-white rounded-12 pt-4 pl-4 pr-4 pb-2">
            <Form layout="vertical" form={form}>
                <div className="search-box">
                    <div className="search-box--title d-lg-flex justify-content-between pb-4">
                        <h3 className="text-fz-18">{t('common:menu.move_products')}</h3>
                    </div>
                    <Row gutter={20}>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            <Form.Item className="mb-2" label={t('label.select_warehouse_global')}>
                                <SelectWarehouseItems
                                    className="_orders-search-warehouse-id"
                                    allowClear={true}
                                    placeholder={t('product:placeholder.warehouse_name')}
                                    value={warehouse_id ? parseInt(warehouse_id) : undefined}
                                    onChange={value => _onChange('warehouse_id', value)}
                                    noSuggest={false}
                                    disabled={!!warehouse_area_id}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} sm={{ span: 12 }} lg={{ span: 6 }}>
                            {warehouse_id && (
                                <Form.Item label={t('order:label.barcode')} name="barcode">
                                    <Input
                                        className="_warehouse-by-scan-barcode"
                                        onPressEnter={e => scanData(e.target.value.trim())}
                                        placeholder={t('warehouse:placeholder.scan_type_location')}
                                        // onChange={e => _onChange('warehouse_area_id', e.target.value)}
                                        suffix={
                                            <ScanBarcode
                                                onUpdate={value => scanData(value.trim())}
                                                disabled={isScanning}
                                            />
                                        }
                                        ref={barcodeInputRef}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            )}
                        </Col>

                    </Row>
                </div>
            </Form>
            <div className="text-right mb-2">
                <Button onClick={_onReset}><UndoOutlined /> {t("common:btn.reset")}</Button>
            </div>
        </div>
    );
};

export default FormSearch;
