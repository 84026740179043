export const RETURNED_TYPE = {
    INT: 'int',
    FLOAT: 'float',
    JSON: 'json',
    STRING: 'string', // default
    BOOLEAN: 'boolean',
    ARRAY: 'array',
};

const ARRAY_SEPARATOR = `\\,`;

export default class SessionStorageApi {
    constructor(myStorageName, returnType = RETURNED_TYPE.STRING) {
        this.myStorage = window.localStorage;
        this.myStorageName = myStorageName;
        this.type = returnType;
    }

    get value() {
        return this.getValue();
    }

    getValue() {
        let localValue = this.myStorage.getItem(this.myStorageName);

        if (!localValue && this.type === RETURNED_TYPE.JSON)
            return {};

        if (!localValue)
            return null;

        switch (this.type) {
            case RETURNED_TYPE.INT:
                return parseInt(localValue);
            case RETURNED_TYPE.FLOAT:
                return parseFloat(localValue);
            case RETURNED_TYPE.JSON:
                return this.parse(localValue);
            case RETURNED_TYPE.BOOLEAN:
                return localValue === "true" ? true : false;
            case RETURNED_TYPE.ARRAY:
                return localValue.split(ARRAY_SEPARATOR);
            default:
                return localValue;
        }
    }

    setValue(value) {
        if ([undefined, null, ""].includes(value)) {
            this.reset();
            return;
        }

        let tmpValue = value;
        switch (this.type) {
            case RETURNED_TYPE.JSON:
                tmpValue = this.stringify(value);
                break;
            case RETURNED_TYPE.BOOLEAN:
                tmpValue = (value === true || value === 1) ? true : false;
                break;
            case RETURNED_TYPE.ARRAY:
                tmpValue = Array.isArray(value) ? value.join(ARRAY_SEPARATOR) : value;
                break;
        }

        this.myStorage.setItem(this.myStorageName, tmpValue);
    }

    reset() {
        this.myStorage.removeItem(this.myStorageName);
    }

    /**
     * parse localStorage value if it is json
     */
    parse(storage) {
        return this.isJson(storage) ? JSON.parse(storage) : {};
    }

    /**
     * convert Json Object to string
     */
    stringify(value) {
        return JSON.stringify((typeof value === "object" && value !== null && value !== undefined) ? value : {});
    }

    /**
     * check item is json or not
     */
    isJson(item) {
        item = typeof item !== "string" ? JSON.stringify(item) : item;

        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }

        if (typeof item === "object" && item !== null) {
            return true;
        }

        return false;
    }
}
