import { Select } from 'antd';
import React, {useEffect, useState} from 'react';

import ServiceAllMerchants from "@Modules/Merchant/services/ServiceAllMerchants";

const { Option } = Select;

export default function SelectAllMerchants({ loading, value, ...props }) {
    let [data, setData] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceAllMerchants.list()
            .then(res => {
                setData(res);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    return (
        <Select
            {...props}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            loading={isLoading || loading}
            optionFilterProp="label"
            showSearch
            value={data.length > 0 && value ? value : undefined}
        >
            {
                data.map(merchant => {
                    return (
                        <Option key={merchant?.id} value={merchant?.code}>
                            {`${merchant?.code}${merchant?.name ? ` - ${merchant?.name}` : ''}`}
                        </Option>
                    );
                })
            }
        </Select>
    );
}
