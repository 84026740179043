import React, { useEffect, useState } from 'react'

import useStocksQuery from '@Modules/Product/Hooks/useStocksQuery'

import { getVar } from '@System/support/helpers'

import { events } from '../../../../system/events'
import { t } from '../../../../system/i18n'
import useFilter from '../../../App/Hooks/useFilter'
import { setDocumentTitle, setNavigator, warehouseAreaStorage, warehouseStorage } from '../../../App/services'
import { EVENTS } from '../../services/constants'
import FormSearch from './FormSearch'
import ListProductContainer from './ListProductContainer'

export default function InventoryManagement(props) {
    const { history, location } = props
    const [viewStockBatch, setViewStockBatch] = useState(false)

    const { filters, addFilter } = useFilter(history, location, {
        warehouse_id: warehouseStorage.value || '',
        warehouse_area_id: warehouseAreaStorage.value || '',
    })

    const { isLoading: loading, data, refetch } = useStocksQuery(filters, { enabled: !viewStockBatch })
   
    useEffect(() => {
        setNavigator(t('common:menu.inventory_management'), [
            {
                route: 'warehouses.list',
                name: t('common:breadcrumb.warehouses'),
            },
            {
                name: t('common:breadcrumb.inventory_management'),
            },
        ])
        setDocumentTitle(t('menu.inventory_management'))
        return events.listen(EVENTS.IMPORT_VARIANT_INVENTORY, () => refetch())

        // eslint-disable-next-line
    }, [])

    const onChangeViewBatch = (checked)=>{
        addFilter({})
        setViewStockBatch(checked)
    }

    return (
        <>
            {' '}
            <div className="site-content">
                <div className="bg-white rounded-12">
                    <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                        <h3 className="p-3 text-fz-18">{t('heading.inventory_management')}</h3>
                        {/*<div className="p-3">*/}
                        {/*    {*/}
                        {/*        auth.can(PERMISSIONS.SKU_IMPORT_STOCK) &&*/}
                        {/*        <UploadVariantInventory/>*/}
                        {/*    }*/}
                        {/*</div>*/}
                    </div>
                    <FormSearch
                        filters={filters}
                        onSearch={addFilter}
                    />
                </div>
                <ListProductContainer
                    filters={filters}
                    addFilter={addFilter}
                    loading={loading}
                    pagination={getVar(data, 'data.pagination', {})}
                    data={getVar(data, 'data.stocks', [])}
                    onChangeViewBatch={onChangeViewBatch}
                    viewStockBatch={viewStockBatch}
                />
            </div>
        </>
    )
}
