import { CheckOutlined } from '@ant-design/icons';
import { Button, Form, notification } from 'antd';
import concat from 'lodash/concat';
import difference from 'lodash/difference';
import find from 'lodash/find';
import filter from 'lodash/filter';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import map from 'lodash/map';
import React, { useCallback, useEffect, useState } from 'react';

import Loading from '@Modules/App/Loading';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import RenderServiceByType from '@Modules/Product/screens/OriginalProduct/RenderServiceByType';
import api from '@Modules/Product/services/api';
import { EVENTS } from '@Modules/Product/services/constants';
import apiService from '@Modules/Services/services/api';
import { SERVICE_TYPE } from '@Modules/Services/services/constants';

import processResponseError from '@System/api/processResponseError';
import { events } from '@System/events';
import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

const servicesTypeAllow = [
    SERVICE_TYPE.IMPORT,
    SERVICE_TYPE.EXPORT,
    SERVICE_TYPE.IMPORTING_RETURN_GOODS,
    SERVICE_TYPE.STORAGE,
    SERVICE_TYPE.EXTENT,

];
function ProductServiceInfo({ product, productId, merchant, activeKey }) {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currency, setCurrency] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [dataSourceByType, setDataSourceByType] = useState({});
    const [selectedIdsByType, setSelectedIdsByType] = useState({});

    const getService = useCallback(async () => {
        setLoading(true);
        try {
            const res = await apiService.list({
                country_id: merchant ? merchant.location_id : 0,
                hidden_init_service: true
            });
            const { data } = res;
            const services = get(data, 'services');
            const servicesRefactor = services.filter(({ service }) => servicesTypeAllow.includes(service.type));
            setCurrency(get(data, 'currency'));
            setData(servicesRefactor);
        } catch (error) {
            processResponseError(error);
        } finally {
            setLoading(false);
        }
    }, [merchant]);

    useEffect(() => {
        getService();
    }, [getService]);

    useEffect(() => {
        if (activeKey === '3') {
            getService();
        }
    }, [activeKey]);

    function handleUpdate() {
        form.validateFields().then(() => {
            const servicePrices = getVar(product, 'servicePrices', []);
            const serviceCodes = map(servicePrices, 'service_code');
            const servicesAllow = map(flatten(Object.values(dataSourceByType)), 'service.code');
            const differenceServicePrice = difference(serviceCodes, servicesAllow);
            const otherServicePriceIds = [];
            if (differenceServicePrice && differenceServicePrice.length > 0) {
                map(differenceServicePrice, code => {
                    const servicePrice = find(servicePrices, ['service_code', code]);
                    otherServicePriceIds.push(getVar(servicePrice, 'id', undefined));
                });
            }
            const idsByType = filter(flatten(Object.values(selectedIdsByType)), item => !isNaN(Number(item)));
            setUpdating(true);
            api.editProduct(productId, {
                service_prices: concat(idsByType, otherServicePriceIds),
            })
                .then(res => {
                    events.dispatch(EVENTS.UPDATE_PRODUCT_SUCCESS, {});
                    notification.success({ message: t('message.update_success_by_attribute') });
                })
                .catch(err => {
                    notification.error({ message: t('message.update_failed_by_attribute') });
                })
                .finally(() => setUpdating(false));
        });
    }

    function handleCancel() {
        events.dispatch(EVENTS.RELOAD_PRODUCT_SERVICE, {});
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="bg-gray p-4">
            {data.length > 0 ? (
                <>
                    <Form form={form}>
                        {servicesTypeAllow.map((item, index) => {
                            return (
                                <RenderServiceByType
                                    key={index}
                                    form={form}
                                    product={product}
                                    serviceType={item}
                                    dataServices={data}
                                    className={`_service-list-${item.toLowerCase()}`}
                                    dataSource={dataSourceByType}
                                    setServiceIds={setSelectedIdsByType}
                                    setDataSource={setDataSourceByType}
                                    currency={currency}
                                />
                            );
                        })}
                    </Form>
                    <div className="d-flex justify-content-end mt-4">
                        <Button onClick={handleCancel} className="_service-btn-cancel">
                            {t('btn.refresh')}
                        </Button>
                        <Button
                            loading={updating}
                            type="primary"
                            className="ml-2 _service-btn-update"
                            onClick={handleUpdate}
                        >
                            <CheckOutlined />
                            {t('btn.update')}
                        </Button>
                    </div>
                </>
             ) : (
                 <div className="text-center">
                     <TableEmpty />
                 </div>
             )}
        </div>
    );
}
export default ProductServiceInfo;
