import lodash from 'lodash';
import {t} from '../../../system/i18n';
import {notification} from "antd";
import _ from "lodash";
import {PAGE_SIZE_DESKTOP, PAGE_SIZE_MOBILE} from "@Modules/App/services/constants";

/**
 * Merge data collection item
 *
 * @param {object} currentData
 * @param {object|function} newData
 * @return {object}
 */
const mergeCollectionItemData = (currentData, newData) => {
    let data = lodash.isFunction(newData) ? newData(currentData) : newData;

    return {...currentData, ...data};
};

/**
 * Update data của item trong collection
 *
 * @param {Array} collection
 * @param {string} itemId
 * @param {object|function} data
 * @param {string} idParam
 * @returns {Array}
 */
export const updateCollectionItem = (collection, itemId, data, idParam = 'id') => {
    itemId = lodash.isArray(itemId) ? itemId : [itemId];

    return collection.map(item => itemId.includes(item[idParam]) ? mergeCollectionItemData(item, data) : item);
};

/**
 * Gán data cho item trong collection (nếu collection không tồn tại item thì sẽ thêm mới)
 *
 * @param {Array} collection
 * @param {string} itemId
 * @param {object|function} data
 * @param {string} idParam
 * @returns {Array}
 */
export const setCollectionItem = (collection, itemId, data, idParam = 'id') => {
    let item = lodash.find(collection, {[idParam]: itemId});

    return item
        ? updateCollectionItem(collection, itemId, data, idParam)
        : [...collection, mergeCollectionItemData({[idParam]: itemId}, data)];
};

/**
 * Translate validation errors
 *
 * @param {string} attribute
 * @param {object} errors
 * @param {boolean} ignoreCustomRule
 * @return {object}
 */
export const translateValidationErrors = (attribute, errors, ignoreCustomRule = false) => {
    return lodash.mapValues(errors, (data, rule) => {
        let messageKey = 'validation:' + rule;
        let message = t(messageKey, {...data, attribute});

        return ignoreCustomRule && message === messageKey ? null : message;
    });
};

export const urlifyContent = (content) => {
    if (content) {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const urlImgeRegex = /https?:\/\/.*?\.(?:png|jpg|jpeg|gif)/ig;
        return content.replace(urlRegex, function (url) {
            if (url.match(urlImgeRegex) !== null) {
                return '<br/><img style="width: 80px; height: 80px" src="' + url + '"><br/>';
            }
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        });
    }
    return content;

}

export const catchErrors = (message = t('common:message.server_error'), cbs = undefined) => {
    let callbacks = {
        400: () => {

        },
        403: () => {
        },
        404: () => {
        },
        default: () => {
        }
    };

    if (typeof cbs === "object")
        callbacks = {...callbacks, ...cbs};

    if (typeof cbs === "function")
        callbacks = {...callbacks, 400: cbs};

    return function (err) {
        let status = _.get(err, "response.status", ""),
            data = _.get(err, "response.data.data", "");

        switch (status) {
            case 400:
                callbacks["400"](data);
                if (!message === false)
                    notification.error({message, duration: 6});
                break;
            case 403:
                callbacks["403"](data);
                notification.error({message: t('common:message.403'), duration: 6});
                break;
            case 404:
                callbacks["404"](data);
                notification.error({message: t('common:message.404'), duration: 6});
                break;
            default:
                callbacks.default(data);
                notification.error({message, duration: 6});
                break;
        }
    }
};

export function handleLoadImageShipping(code) {
    try {
        code = code.toLowerCase();
        return require(`../../../resources/img/shipping_partner/${code}.png`)
    } catch (err) {
        return "";
    }
}

export function getPageSizeScale() {
    const {innerWidth} = window;
    return innerWidth >= 1024 ? PAGE_SIZE_DESKTOP : PAGE_SIZE_MOBILE
}
