import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useDeletePurchasingAccountMutation = createUseMutation({
    namespace: 'purchasingAccount:delete',
    mutation: id => apiService.delete(id),
});

export default useDeletePurchasingAccountMutation;
