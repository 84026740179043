import { Form, Input, Modal, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { t } from '@System/i18n';
import notification from '@System/notification';

import api from '../services/api';
import SelectStaff from './SelectStaff';

const ModalSelectStaff = ({ visible, setVisible, isSelectAllPage, ids, filters }) => {
    const [form] = Form.useForm();
    const [staff, setStaff] = useState();
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            title: t('purchasingOrder:label.order'),
            key: 'order_code',
            dataIndex: 'code',
        },
        {
            title: t('sku:label.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>;
            },
        },
        {
            title: t('orderDetail:label.deliveried_date'),
            dataIndex: 'intended_delivery_at',
            key: 'intended_delivery_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>;
            },
        },
    ];

    const handleAddStaff = async () => {
        let order_packing_not_allows = [];
        if (staff) {
            try {
                setLoading(true);
                const res = await api.addStaffForOrder({
                    ...filters,
                    ids: isSelectAllPage ? [] : ids,
                    grant_for_picker_id: staff,
                });
                const data = get(res, 'data.order_packing_not_allows');
                order_packing_not_allows = [...data];

                if (order_packing_not_allows?.length > 1 || isEmpty(order_packing_not_allows)) {
                    notification.success(t('order:message.choose_handler_success'));
                }else{
                    notification.error(t('order:message.choose_handler_fail'));
                }

                setVisible(false);
            } catch (error) {
                notification.error(t('order:message.choose_handler_fail'));
            } finally {
                setLoading(false);
            }

            if (!isEmpty(order_packing_not_allows)) {
                Modal.confirm({
                    title: t('order:title.order_list_not_eligible'),
                    cancelText: t('btn.close'),
                    okButtonProps: { style: { display: 'none' } },
                    icon: false,
                    width: 600,
                    content: (
                        <>
                            <Table
                                columns={columns}
                                className="_not_eligible_staff-table mt-3"
                                rowKey={record => record?.id}
                                dataSource={order_packing_not_allows}
                                pagination={true}
                            />
                        </>
                    ),
                });
            }
        } else {
            let error_messages = [];

            error_messages.push({
                name: 'staff',
                errors: [
                    t('validation:required', {
                        attribute: t('order:label.picker'),
                    }),
                ],
            });

            form.setFields(error_messages);
        }
    };

    return (
        <Modal
            title={t('order:btn.choose_handler')}
            visible={true}
            onOk={handleAddStaff}
            okButtonProps={{ loading: loading }}
            onCancel={() => setVisible(false)}
            cancelText={t('btn.cancel')}
            okText={t('btn.ok')}
        >
            <Form form={form}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: t('validation:required', {
                                attribute: t('order:label.picker'),
                            }),
                        },
                    ]}
                    name="staff"
                >
                    <SelectStaff
                        value={staff}
                        onChange={value => setStaff(value)}
                        placeholder={t('order:placeholder.select_staff')}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalSelectStaff;
