import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import apiService from '../../Dropshipping/services/api';

const useQuotationQuery = (id, params) =>
    useQuery(['products-quotation', id, params], () => apiService.getQuotation(id, params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });

export default useQuotationQuery;
