import React from 'react';
import {t} from "../../../system/i18n";
import {Result} from 'antd';

export default function Error403() {
    return (
        <div className="d-flex align-items-center justify-content-center" style={{width: "100%"}}>
            <Result
                status="403"
                title="403"
                subTitle={t('message.403')}
            />
        </div>
    );
}
