import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useShippingPartnerStampUrlQuery = (params, settings) =>
    createUseQuery({
        name: ['shipping-partners', params],
        query: () => apiService.getShippingPartnerStampUrl(params),
        settings: {
            enabled: false,
            ...settings,
        },
    });

export default useShippingPartnerStampUrlQuery;
