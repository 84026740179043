import React, {useMemo, useState} from "react";
import {Badge, Table, Avatar, Button} from "antd";
import CustomizedPagination from "../../../App/CustomizedPagination";
import {t} from "@System/i18n";
import TableEmpty from "../../../App/Utilities/TableEmpty";
import lodash from 'lodash';
import moment from 'moment';
import {PURCHASING_ORDER_STATUS_COLORS} from "@Modules/PurchasingOrder/services/constants";
import _ from "lodash";
import MapSkuModal from "@Modules/PurchasingOrder/screens/ListPurchasingOrders/MapSkuModal";
import {cnCurrency, formatCurrency} from "@Modules/PurchasingOrder/services/constants";
import { Link } from "@System/routing";

export default function List({purchasingOrders, pagination, addFilter, filters, loading, fetchPurchasingOrders}) {
    const [mapOrder, setMapOrder] = useState(null);

    const formatedPurchasingOrders = useMemo(() => {
        return purchasingOrders.map(item => {
            let { exchange_rate, original_total_value, total_fee, grand_total, total_unpaid } = item;
            return {
                ...item,
                original_total_value: formatCurrency(original_total_value ? original_total_value : 0, cnCurrency),
                total_fee: formatCurrency(total_fee ? (total_fee / exchange_rate) : 0, cnCurrency),
                grand_total: formatCurrency(grand_total ? (grand_total / exchange_rate) : 0, cnCurrency),
                total_unpaid: formatCurrency(total_unpaid ? (total_unpaid / exchange_rate) : 0, cnCurrency),
                original_total_value_vnd: formatCurrency(original_total_value ? (original_total_value * exchange_rate) : 0),
                total_fee_vnd: formatCurrency(total_fee ? total_fee : 0),
                grand_total_vnd: formatCurrency(grand_total ? grand_total : 0),
                total_unpaid_vnd: formatCurrency(total_unpaid ? total_unpaid : 0)
            };
        });
    }, [purchasingOrders]);

    let getColumns = [
        {
            className: "_purchasing-orders-code text-nowrap",
            title: t("purchasingOrder:label.order"),
            dataIndex: "code",
            key: 'code',
            render: (text, record) => {
                return (
                    <div className="d-flex">
                        <Avatar shape="square" src={record.image} size={44} />
                        <div className="ml-2">
                            <a href={record.m1_order_url} target="_blank">{text}</a>
                            <p>{_.get(record, "purchasing_account.username", "")}</p>
                        </div>
                    </div>
                );
            },
        },
        {
            className: "_purchasing-orders-supplier_code text-nowrap",
            title: t("purchasingOrder:label.supplier"),
            dataIndex: "supplier_code",
            key: 'supplier_code',
            render: (text, record) => {
                return (
                    <>
                        <a href={record.supplier_url} target="_blank">{text}</a>
                        <p className="text-capitalize">{record.marketplace}</p>
                    </>
                );
            },
        },
        {
            className: "_purchasing-orders-received_quantity text-nowrap text-right",
            title: t("purchasingOrder:label.quantity"),
            dataIndex: "received_quantity",
            key: 'received_quantity',
            render: (text, record) => {
                return <p>{text}/{record.purchased_quantity}</p>;
            },
        },
        {
            className: "_purchasing-orders-original_total_value text-nowrap  text-right",
            title: t("purchasingOrder:label.original_total_value"),
            dataIndex: "original_total_value",
            key: 'original_total_value',
            render: (text, record) => {
                return (
                    <>
                        <p>{record.original_total_value_vnd}</p>
                        <p className="text-fz-12 text-color-label">{text}</p>
                    </>
                );
            },
        },
        {
            className: "_purchasing-orders-total_fee text-nowrap  text-right",
            title: t("purchasingOrder:label.total_fee"),
            dataIndex: "total_fee",
            key: 'total_fee',
            render: (text, record) => {
                return (
                    <>
                        <p>{record.total_fee_vnd}</p>
                        <p className="text-fz-12 text-color-label">{text}</p>
                    </>
                );
            },
        },
        {
            className: "_purchasing-orders-total_unpaid text-nowrap text-right",
            title: t("purchasingOrder:label.total_unpaid"),
            dataIndex: "total_unpaid",
            key: 'total_unpaid',
            render: (text, record) => {
                return (
                    <>
                        <p>{record.total_unpaid_vnd}</p>
                        <p className="text-fz-12 text-color-label">{text}</p>
                    </>
                );
            },
        },
        {
            className: "_purchasing-orders-grand_total text-nowrap text-right",
            title: t("purchasingOrder:label.grand_total"),
            dataIndex: "grand_total",
            key: 'grand_total',
            render: (text, record) => {
                return (
                    <>
                        <p>{record.grand_total_vnd}</p>
                        <p className="text-fz-12 text-color-label">{text}</p>
                    </>
                );
            },
        },
        {
            className: "_purchasing-orders-ordered_at text-nowrap",
            title: t("purchasingOrder:label.ordered_at"),
            dataIndex: "ordered_at",
            key: 'ordered_at',
            render: (text, record) => {
                const ordered_at = lodash.get(record, "ordered_at", "");
                return <p>{ordered_at ? moment(ordered_at).local().format("HH:mm DD/MM/YYYY") : ""}</p>;
            },
        },
        {
            className: "_purchasing-orders-status text-nowrap",
            title: t("purchasingOrder:label.status"),
            dataIndex: "status",
            key: 'status',
            render: text => text ? (
                <Badge color={PURCHASING_ORDER_STATUS_COLORS[text]} text={t(`purchasingOrder:status.${text}`)} />
            ) : null,
        },
        {
            className: "_purchasing-orders-entry_documents text-nowrap text-right",
            title: t("purchasingOrder:label.entry_documents"),
            dataIndex: "entry_documents",
            key: 'entry_documents',
            render: (text, record) => {
                return (
                    <Link
                        to={`documents.importing.detail`}
                        params={{
                            id: lodash.get(record, 'id'),
                        }}
                    >
                        {lodash.get(record, 'code', '')}
                    </Link>);
            },
        },
        {
            className: "_purchasing-orders-sku_count text-nowrap text-right",
            title: t("purchasingOrder:label.count"),
            dataIndex: "sku_count",
            key: 'sku_count',
            render: (text, record) => {
                return <p>{record.sku_matched}/{text}</p>;
            },
        },
        {
            className: "_purchasing-orders-map text-nowrap text-right",
            title: "",
            width: 100,
            render: (text, record) => {
                return _.get(record, "permission_views.mapping_sku", false)
                    ? <Button
                        className="_purchasing-orders-sku-map-btn bg-secondary-color text-primary border-0 pl-3 pr-3 text-fz-13"
                        size="small"
                        onClick={() => setMapOrder(record)}
                    >Map</Button>
                    : null;
            },
        }
    ];

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-1">
                    <h3 className="text-fz-18">{t("heading.purchasing_orders")} ({lodash.isEmpty(pagination) ? 0 : pagination.total})</h3>
                </div>
                <div className="data-box--body">
                    <Table
                        className="list-products-table pb-3"
                        dataSource={formatedPurchasingOrders}
                        rowKey={(record) => record.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{emptyText: <TableEmpty loading={loading}/>}}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                    />
                    <MapSkuModal
                        mapOrder={mapOrder}
                        setMapOrder={setMapOrder}
                        fetchPurchasingOrders={fetchPurchasingOrders}
                    />
                </div>
            </div>
        </div>
    );
}
