import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";

class ServiceAllMerchants extends BaseServiceData {
    runApi() {
        return api.getAllMerchants()
            .then(res => {
                return _.get(res, "data.merchants", []);
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("merchant:label.info").toLowerCase()})});
                return Promise.reject(err);
            });
    }
}

export default new ServiceAllMerchants();
