import { DeleteOutlined, MinusOutlined } from '@ant-design/icons'
import { Avatar, Button, Form, InputNumber, Table } from 'antd'
import { cloneDeep, filter, find, findLastIndex, get, isEmpty, map } from 'lodash'
import React, { useEffect } from 'react'

import { updateCollectionItem } from '@Modules/App/services/helpers'
import CreateBatchOfGoods from '@Modules/PurchasingPackage/components/CreateBatchOfGoods'
import SelectBatchOfGoods from '@Modules/PurchasingPackage/components/SelectBatchOfGoods'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

const ProductList = ({ products, form, setProducts }) => {
    useEffect(() => {
        form.setFieldsValue({
            [['batch_of_goods']]: map(products, 'batch_of_goods'),
        })
    }, [products])

    const columns = [
        {
            className: '_product-list-sku-name',
            title: t('product:label.sku_code'),
            dataIndex: 'sku_code',
            render: (text, record) => {
                const image = getVar(record, 'image', '')
                return (
                    <div className="d-flex align-items-center">
                        <Avatar
                            src={image}
                            shape="square"
                            className="mr-2"
                            size={36}
                        />
                        <div className="d-flex flex-wrap">
                            <span>
                                <strong style={{ fontWeight: 500 }}>{text}</strong>
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            className: '_product-list-name',
            title: t('product:label.name'),
            dataIndex: 'label',
            width: '30%',
        },
        {
            className: 'text-right text-nowrap _product-list-batch_number',
            title: t('packing:label.batch_number'),
            dataIndex: 'batch_number',
            // width: '200px',
            render: (text, record, indexRow) => {
                const batch_of_goods = get(record, 'batch_of_goods', [])
                const isBatch = get(record, 'is_batch', false)
                const selectedList = map(
                    filter(batch_of_goods, item => item.id),
                    'id'
                )

                return !isBatch
                    ? '---'
                    : map(batch_of_goods, (item, idx) => {
                          return (
                              <Form.Item
                                  name={['batch_of_goods', indexRow, idx, 'id']}
                                  className="mb-2"
                                  key={idx}
                                    rules={[{ required: true, message: t('order:message.required', { attribute: t('packing:placeholder.select_batch') }) }]}
                              >
                                  <SelectBatchOfGoods
                                      skuId={record.sku_id}
                                      onChange={(value, option) => onChangeBatch(indexRow, idx, value, option)}
                                      selectedList={selectedList}
                                  />
                              </Form.Item>
                          )
                      })
            },
        },

        {
            className: 'text-right text-nowrap _product-list-quantity',
            title: t('label.quantity'),
            dataIndex: 'quantity',
            // width: '200px',
            render: (text, record, indexRow) => {
                const batch_of_goods = get(record, 'batch_of_goods', [])
                const isBatch = get(record, 'is_batch', false)

                return isBatch ? (
                    map(batch_of_goods, (item, idx) => (
                        <div
                            className="d-flex align-items-center justify-content-end"
                            key={idx}
                        >
                            <Form.Item
                                name={['batch_of_goods', indexRow, idx, 'quantity']}
                                className="mb-2"
                                initialValue={item.quantity}
                                rules={[{ required: true, message: t('order:message.required', { attribute: t('label.quantity') }) }]}
                            >
                                <InputNumber
                                    min={1}
                                    placeholder={t('order:placeholder.quantity')}
                                    className="w-100"
                                    onBlur={e => updateQuantityBatch(indexRow, idx, e.target.value)}
                                />
                            </Form.Item>
                            {idx > 0 ? (
                                <DeleteOutlined
                                    className="mb-2 ml-2 text-danger cursor-pointer"
                                    onClick={() => onDeleteRowBatch(indexRow, idx)}
                                />
                            ) : (
                                <DeleteOutlined className="text-light mb-2 ml-2" />
                            )}
                        </div>
                    ))
                ) : (
                    <div className="d-flex align-items-center justify-content-end">
                        <Form.Item
                            name={['skus', indexRow, 'quantity']}
                            className="mb-2"
                            initialValue={text}
                            rules={[{ required: true, message: t('order:message.required', { attribute: t('label.quantity') }) }]}
                        >
                            <InputNumber
                                min={1}
                                placeholder={t('order:placeholder.quantity')}
                                className="w-100"
                                onBlur={e => updateQuantity('quantity', e.target.value, record)}
                            />
                        </Form.Item>
                        <DeleteOutlined className="text-light" />
                    </div>
                )
            },
        },
        {
            title: t('label.action'),
            className: 'text-right _importing-document-product-action',
            dataIndex: 'sku_id',
            render: (value, record, indexRow) => {
                const isBatch = get(record, 'is_batch', false)
                const firstBatch = get(record, 'batch_of_goods.0.id')

                return (
                    <div className="d-flex align-items-center justify-content-end">
                        {isBatch ? (
                            <>
                                {firstBatch ? (
                                    <Button
                                        className="mr-2"
                                        onClick={() => addBatchRow(indexRow)}
                                    >
                                        {t('packing:btn.add_batch')}
                                    </Button>
                                ) : null}
                                <CreateBatchOfGoods skuId={value} />
                            </>
                        ) : null}
                        <Button
                            onClick={() => handleDeleteRow(record)}
                            danger
                        >
                            {t('btn.delete')}
                        </Button>
                    </div>
                )
            },
        },
    ]

    function updateQuantity(name, value, data) {
        const productInfo = find(products, ['sku_id', data.sku_id])
        if (!isEmpty(productInfo) && productInfo[name] !== value) {
            const productsData = updateCollectionItem(products, data.sku_id, { [name]: value }, 'sku_id')
            setProducts(productsData)
        }
    }

    function updateQuantityBatch(indexRow, indexBatch, value) {
        const newData = cloneDeep(products)

        newData[indexRow].batch_of_goods[indexBatch] = {
            ...newData[indexRow].batch_of_goods[indexBatch],
            quantity: value,
        }

        setProducts(newData)
    }

    const onDeleteRowBatch = (rowIndex, index, name) => {
        const newData = cloneDeep(products)
        newData[rowIndex].batch_of_goods.splice(index, 1)

        setProducts([...newData])
    }

    function addBatchRow(rowIndex) {
        const newData = cloneDeep(products)

        newData[rowIndex].batch_of_goods = [
            ...newData[rowIndex].batch_of_goods,
            {
                id: undefined,
                quantity: 1,
            },
        ]
        setProducts([...newData])
    }

    const onChangeBatch = (indexRow, indexBatch, value, option) => {
        const newData = cloneDeep(products)

        newData[indexRow].batch_of_goods[indexBatch] = {
            ...newData[indexRow].batch_of_goods[indexBatch],
            id: value,
            sku_child_id: option.sku_child_id,
        }

        setProducts(newData)
    }

    function handleDeleteRow(record) {
        const newData = [...products]
        const lastIndex = findLastIndex(newData, ['sku_id', record.sku_id])
        newData.splice(lastIndex, 1)
        setProducts(newData)
    }

    return (
        <Table
            className="_product-list"
            rowKey={record => record.sku_id}
            dataSource={products}
            columns={columns}
            scroll={{ x: 576 }}
            pagination={false}
            locale={{
                emptyText: <p>{t('message.no_data_product')}</p>,
            }}
        />
    )
}

export default ProductList
