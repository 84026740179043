import { Row, Col, Spin } from 'antd';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import React from 'react';

import { IDebtsShippingStats } from '@Modules/Finance/Interfaces/interfaces';
import { KEY_SUMMARY_DEBIT_SHIPPING } from '@Modules/Finance/services/constants';

import { t } from '@System/i18n';
import { formatCurrency } from '@System/support/numberFormat';

interface ISummaryProps {
    data: IDebtsShippingStats;
    isLoading: boolean;
}

const Summary: React.FC<ISummaryProps> = ({ data, isLoading }) => {
    const currency = get(data, 'currency', {});

    return (
        <Row className="mt-4 finance_order d-flex" gutter={16} justify="space-between">
            {KEY_SUMMARY_DEBIT_SHIPPING.map((item, index) => {
                const value = get(data, item, null);
                return (
                    <Col key={index} className="mb-2" xs={{ span: 12 }} lg={{ span: 8 }}>
                        <div className="finance_stats d-flex flex-column">
                            <span>{t(`finance:label.${item}`)}</span>
                            <Spin spinning={isLoading}>
                                <span className="stats_amount">
                                    {!isEmpty(currency) ? formatCurrency(value || 0, currency) : value}
                                </span>
                            </Spin>
                        </div>
                    </Col>
                );
            })}
        </Row>
    );
};

export default Summary;
