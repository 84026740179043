import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, DatePicker, Col, Button, Input, Select } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { t } from '../../../../system/i18n';
import SelectCategories from '../../../ProductCategories/components/SelectCategories';
import SelectStatus from '../../../ProductStatuses/components/SelectStatuses';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;

function FormSearch({ addFilter, filters, initFilters }) {
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const { id, code, category_id, status, created_at_from, created_at_to, inventory } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({ ...input, [name]: value });
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onChangeDate(dates) {
        setInput({
            ...input,
            created_at_from: dates ? dates[0].format('YYYY-MM-DD') : '',
            created_at_to: dates ? dates[1].format('YYYY-MM-DD') : '',
        });
    }

    function _onSubmit() {
        addFilter(input);
    }

    function _onReset() {
        addFilter(initFilters);
    }

    return (
        <Form className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray _variant-search" form={form} layout="vertical">
            <Row gutter={20}>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('product:label.sku_id')}>
                        <Input
                            className="_variant-search-sku-id"
                            name="id"
                            value={id}
                            placeholder={t('product:placeholder.sku_id')}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('product:label.sku_code')}>
                        <Input
                            className="_variant-search-code"
                            name="code"
                            value={code}
                            placeholder={t('product:placeholder.sku_code')}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('product:label.category_id')}>
                        <SelectCategories
                            className="_variant-search-category-id"
                            placeholder={t('product:placeholder.category_id')}
                            allowClear={true}
                            value={category_id ? parseInt(category_id) : undefined}
                            onChange={value => _onChange('category_id', value)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-0" label={t('product:label.status')}>
                        <SelectStatus
                            className="_variant-search-status"
                            value={status}
                            placeholder={t('product:placeholder.status')}
                            onChange={value => _onChange('status', value)}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-0" label={t('product:label.product_created')}>
                        <RangePicker
                            className="_variant-search-created-at"
                            value={
                                created_at_from && created_at_to ? [moment(created_at_from), moment(created_at_to)] : undefined
                            }
                            format={dateFormat}
                            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                            onChange={_onChangeDate}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-0" label={t('product:label.stock_status')}>
                        <Select
                            className="_variant-search-stock-status"
                            placeholder={t('product:placeholder.stock_status')}
                            onChange={value => _onChange('inventory', value)}
                            value={[0, 1].includes(parseInt(inventory)) ? parseInt(inventory) : undefined}
                            optionFilterProp="children"
                            allowClear={true}
                        >
                            <Option key="0" value={0}>
                                {t('product:stock_status.OUT_OF_STOCK')}
                            </Option>
                            <Option key="1" value={1}>
                                {t('product:stock_status.IN_STOCK')}
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }} className="pt-4 text-right" style={{ marginTop: '6px' }}>
                    <Button className="_variant-search-btn-reset" onClick={_onReset}>
                        <UndoOutlined /> {t('common:btn.reset')}
                    </Button>
                    <Button className="ml-2 _variant-search-btn-search" type="primary" onClick={_onSubmit}>
                        <SearchOutlined /> {t('common:btn.search')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;
