import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Input } from 'antd';
import React, { useState, useEffect } from 'react';

import useSize from '@Modules/App/Hooks/useSize';

import { t } from '../../../../system/i18n';

function FormSearch({ addFilter, filters }) {
    const { isXSmall, isSmall } = useSize();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const { code, name } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        let newInput = { ...input, [name]: value };
        setInput(newInput);
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onSubmit() {
        addFilter(input);
    }

    function _onReset() {
        addFilter({});
    }
    return (
        <Form className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray" form={form} layout="vertical">
            <Row gutter={20}>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('warehouseArea:label.code')}>
                        <Input
                            name="code"
                            value={code}
                            placeholder={t('warehouseArea:placeholder.code')}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('warehouseArea:label.name')}>
                        <Input
                            name="name"
                            value={name}
                            placeholder={t('warehouseArea:placeholder.name')}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 12 }} className="d-flex justify-content-end">
                    <Form.Item className="mb-3" label={isXSmall || isSmall ? '' : ' '}>
                        <Button type="primary" onClick={_onSubmit}>
                            <SearchOutlined /> {t('common:btn.search')}
                        </Button>
                        <Button className="ml-2" onClick={_onReset}>
                            <UndoOutlined /> {t('common:btn.reset')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;
