import { Form, Modal } from 'antd'
import { get } from 'lodash'
import React, { useState, useEffect } from 'react'

import useServiceListQuery from '@Modules/ServicesManager/Hook/useServiceListQuery'

import { t } from '@System/i18n'

import AddService from './AddService'
import { IError } from './CreateServicePack'

const ModalAddService = ({ handleHideModal, countryId, setServiceGroup, serviceGroup }) => {
    const [services, setServices] = useState([])
    const [selectedList, setSelectedList] = useState([])
    const [currency, setCurrency] = useState({})
    const [errors, setErrors] = useState<IError[]>([])
    const { data: response, isFetching } = useServiceListQuery({ country_id: countryId, status: 'ACTIVE' })

    useEffect(() => {
        setServices(get(response, 'data.services'))
        setCurrency(get(response, 'data.currency'))
    }, [response])

    return (
        <Modal
            title={t('btn.add_service')}
            visible={true}
            cancelText={t('btn.close')}
            onCancel={handleHideModal}
            width={800}
            maskClosable={false}
            okButtonProps={{ style: { display: 'none' } }}
        >
            <AddService
                setServiceGroup={setServiceGroup}
                serviceGroup={serviceGroup}
                selectedList={selectedList}
                setSelectedList={setSelectedList}
                isFetching={isFetching}
                services={services}
                currency={currency}
                errors={errors}
                setErrors={setErrors}
            />
        </Modal>
    )
}

export default ModalAddService
