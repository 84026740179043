import { Badge, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import React from 'react';

import { dateFormatter } from '@Modules/App/services';
import { TRANSACTION_STATUS, TRANSACTION_STATUS_COLORS } from '@Modules/Merchant/services/constants';
import { numberFormatTransaction } from '@Modules/Merchant/services/numberFormatTransaction';

import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

import { t } from '../../../../system/i18n';
import CustomizedPagination from '../../../App/CustomizedPagination';
import TableEmpty from '../../../App/Utilities/TableEmpty';

export default function List({ transactions, pagination, addFilter, filters, loading, currency }) {
    let getColumns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            className: 'cl-id',
            render: text => {
                return <strong style={{ fontWeight: '500' }}>{text}</strong>;
            },
        },
        {
            title: t('merchant:label.time'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            className: 'cl-timestamp',
            render: (text, record) => {
                return dateFormatter.full(text);
            },
        },
        {
            title: t('merchant:label.type_transaction'),
            dataIndex: 'transactionType',
            key: 'transactionType',
            className: 'cl-transactionType',
            render: (text, record) => {
                let status = get(record, 'purchaseUnits[0].customType');
                return text ? (
                    <Badge
                        color={TRANSACTION_STATUS_COLORS[status]}
                        text={t(`merchant:TRANSACTION_STATUS.${TRANSACTION_STATUS[status]}`)}
                    />
                ) : (
                    ''
                );
            },
        },
        {
            title: t('finance:label.amount'),
            dataIndex: 'amount',
            key: 'amount',
            className: 'cl-amount',
            render: (text, record) => {
                let status = get(record, 'purchaseUnits[0].customType');
                return text.toString() && text !== 0 ? (
                    !isEmpty(currency) ? (
                        <p style={{ color: TRANSACTION_STATUS_COLORS[status] }}>
                            {numberFormatTransaction(text, currency)}
                        </p>
                    ) : (
                        text
                    )
                ) : (
                    ''
                );
            },
        },
        {
            title: t('merchant:label.balance_at'),
            dataIndex: 'balanceAfter',
            key: 'balanceAfter',
            className: 'cl-balanceAfter',
            render: (text, record) => {
                return text ? (
                    !isEmpty(currency) ? (
                        <p style={{ fontWeight: 500 }}>{formatCurrency(text, currency)}</p>
                    ) : (
                        text
                    )
                ) : (
                    <p style={{ fontWeight: 500 }}>{!isEmpty(currency) ? formatCurrency('0', currency) : '0'}</p>
                );
            },
        },
        {
            title: t('order:label.description'),
            dataIndex: 'memo',
            key: 'memo',
            className: 'cl-memo',
            render: (text, record) => {
                let description = get(record, 'purchaseUnits[0].description');
                let status = get(record, 'purchaseUnits[0].customType');
                let statusInit = get(record, 'transactionType');

                return renderDescription(status, description, text, statusInit);
            },
        },
        {
            title: t('orderDetail:label.creator_id'),
            dataIndex: 'account',
            key: 'account',
            className: 'text-center cl-account',
            render: (text, record) => {
                return <p>{text}</p>;
            },
        },
    ];
    const isJson = str => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const renderDescription = (status, description, memo, statusInit) => {
        if (statusInit === 'GENESIS') return memo;

        switch (status) {
            case 'COLLECT':
            case 'WITHDRAW':
            case 'DEPOSIT':
                return description;
            case 'STORAGE_FEE':
                return `${t('merchant:DOCUMENT_TYPE.STORAGE_FEE')} ${t('merchant:label.day').toLowerCase()} ${
                    description ? dateFormatter.date(JSON.parse(description)?.closing_time) : ''
                }`;
            case 'IMPORT_SERVICE':
            case 'EXPORT_SERVICE':
            case 'SHIPPING':
            case 'COD':
            case 'IMPORT_RETURN_GOODS_SERVICE':
            case 'EXTENT':
                return (
                    <>
                        {description && isJson(description) ? (
                            <p>
                                {JSON.parse(description).type
                                    ? `${t('merchant:label.confirm_voucher')} ${t(
                                          `merchant:DOCUMENT_TYPE.${JSON.parse(description).type}`
                                      )} #${JSON.parse(description).code}`
                                    : '---'}
                            </p>
                        ) : (
                            description
                        )}
                    </>
                );
            default:
                return '---';
        }
    };
    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-1">
                    <h3 className="text-fz-18">
                        {t('title.transaction_list')}
                        <span className="number-circle _importing-document-product-list-total ml-1">
                            {getVar(pagination, 'total', 0)}
                        </span>
                    </h3>
                </div>
                <div className="data-box--body">
                    <Table
                        className="list-products-table"
                        dataSource={transactions && transactions}
                        rowKey={'id'}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        scroll={{ x: true }}
                        locale={{ emptyText: <TableEmpty loading={loading} /> }}
                    />
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
}
