import { Table } from 'antd'
import { get, join, map } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import api from '@Modules/ServicesManager/services/api'
import { SELLER_HISTORY_ACTION } from '@Modules/ServicesManager/services/constants'

import { t, trans } from '@System/i18n'

interface ISellerServiceLogProps {
    servicePackId: number
    activeKey: string
}

const SellerServiceLog: React.FC<ISellerServiceLogProps> = ({ servicePackId, activeKey }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const getLogs = () => {
            setLoading(true)
            api.getSellerServicesPackLogs(servicePackId)
                .then(res => {
                    setData(get(res, 'data.seller_histories'))
                })
                .catch(err => {})
                .finally(() => {
                    setLoading(false)
                })
        }
        if (activeKey === 'log') {
            getLogs()
        }
    }, [activeKey, servicePackId])

    const columns = [
        {
            className: '_product-history-action',
            title: t('label.action'),
            dataIndex: ['action'],
            key: 'action',
            render: (text, record) => renderLog(text, record),
        },
        {
            className: '_product-history-action-time',
            title: t('label.time'),
            dataIndex: 'time',
            key: 'time',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : t('noText')}</p>
            },
            width: '200px',
        },
    ]

    const renderLog = (action, record) => {
        const payload = get(record, 'payload')
        switch (action) {
            case SELLER_HISTORY_ACTION.SERVICE_PACK_ADD_SELLER:
                return trans('serviceManager:log.SERVICE_PACK_ADD_SELLER', {
                    sellers: (
                        <strong>
                            {join(
                                map(get(payload, 'sellers'), item => ` ${item.code} - ${item.name}`),
                                ', '
                            )}
                        </strong>
                    ),
                })
            case SELLER_HISTORY_ACTION.SERVICE_PACK_REMOVE_SELLER:
                return trans('serviceManager:log.SERVICE_PACK_REMOVE_SELLER', {
                    sellers: (
                        <strong>
                            {get(payload, 'seller.code')} - {get(payload, 'seller.name')}
                        </strong>
                    ),
                })
            default:
                break
        }
    }

    return (
        <Table
            rowKey={(record: any) => record?.id}
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={loading}
            locale={{
                emptyText: (
                    <TableEmpty
                        loading={loading}
                        className="seller-log-empty"
                    />
                ),
            }}
            scroll={{ x: 576 }}
        />
    )
}

export default SellerServiceLog
