import { api } from './../../../system/api';

const BASE_URL = 'purchasing-managers/purchasing-accounts';
const BASE_PURCHASING_SERVICES_URL = 'purchasing-managers/purchasing-services';

export default {
    list: params =>
        api.get(BASE_URL, {
            params,
        }),
    add: data => api.post(BASE_URL, data),
    update: data => api.put(`${BASE_URL}/${data?.id}`, data),
    purchasingServices: () => api.get(`${BASE_PURCHASING_SERVICES_URL}/suggest`),
    delete: id => api.delete(`${BASE_URL}/${id}`),
    balance: id => api.get(`${BASE_URL}/${id}/balance`)
};
