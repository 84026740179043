import { Col, Row } from 'antd';
import flow from 'lodash/flow';
import identity from 'lodash/identity';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import React, { useCallback, useEffect, useState } from 'react';

import { accumulateByKeys } from '@System/support/array';

import ProductList from '../../components/ProductList';
import OrderExportingList from './OrderExportingList';

const Body = ({ exportingDocument, orderExportings }) => {
    const [products, setProducts] = useState([]);

    const getFormattedProducts = useCallback(
        flow(
            orderExportings =>
                reduce(
                    orderExportings,
                    (result, orderExporting) => [...result, ...map(orderExporting.order_exporting_items, identity)],
                    []
                ),
            products => accumulateByKeys(products, 'sku_id', 'quantity')
        ),
        []
    );

    useEffect(() => {
        const products = getFormattedProducts(orderExportings);

        setProducts(products);
    }, [orderExportings, getFormattedProducts]);

    return (
        <Row gutter={48} type="flex">
            <Col className="mb-3 mb-lg-0" lg={{ span: 18 }} span={24}>
                <OrderExportingList exportingDocument={exportingDocument} orderExportings={orderExportings} />
            </Col>
            <Col lg={{ span: 6 }} span={24}>
                <ProductList orderExportings={orderExportings} products={products} />
            </Col>
        </Row>
    );
};

export default Body;
