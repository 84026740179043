import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import useListSkusFobizQuery from '@Modules/Configuration/Hooks/useListSkusFobizQuery';

import { events } from '../../../../system/events';
import { t } from '../../../../system/i18n';
import useFilter from '../../../App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '../../../App/services';
import { EVENTS } from '../../services/constants';
import FormSearch from './FormSearch';
import ImportSynchronousProduct from './ImportSynchronousProducts';
import List from './List';

export default function SynchronousProducts({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, {});
    const [storeSkus, setStoreSkus] = useState([]);
    const [pagination, setPagination] = useState({});
    const [canUpdate, setCanUpdate] = useState(false);

    const { data, isFetching: loading, refetch } = useListSkusFobizQuery(filters);

    useEffect(() => {
        setNavigator(t('title.synchronous-products'), [
            {
                name: t('breadcrumb.configuration'),
                route: 'config.synchronous',
            },
            {
                name: t('breadcrumb.synchronous-products'),
            },
        ]);
        setDocumentTitle(t('title.synchronous-products'));

        return events.listen(EVENTS.IMPORT_SKU_FOBIZ_FROM_EXCEL, (event, payload) => refetch());
    }, []);

    useEffect(() => {
        const store_skus = _.get(data, 'data.store_skus', []);
        setStoreSkus(convertDataStoreSkus(store_skus));
        setPagination(_.get(data, 'data.pagination', {}));
        setCanUpdate(_.get(data, 'data.can_update', false));
    }, [data]);

    function convertDataStoreSkus(data) {
        return _.reduce(
            data,
            function (result, value) {
                const storeSku = {
                    sku_code: _.get(value, 'sku.code'),
                    store_sku_code: _.get(value, 'store_sku.code'),
                    store_sku_id: _.get(value, 'store_sku.id'),
                };
                result.push(storeSku);
                return result;
            },
            []
        );
    }

    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{t('common:heading.synchronous-products')}</h2>
                    <div className="pr-3">
                        <ImportSynchronousProduct />
                    </div>
                </div>

                <div>
                    <FormSearch addFilter={addFilter} filters={filters} initFilters={{}} loading={loading} />
                    <List
                        storeSkus={storeSkus}
                        setStoreSkus={setStoreSkus}
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                        loading={loading}
                        canUpdate={canUpdate}
                    />
                </div>
            </div>
        </div>
    );
}
