import React, {useEffect, useState} from "react";
import {Col, Form, InputNumber, Row, Table} from "antd";
import {
    DeleteOutlined
} from "@ant-design/icons";
import TableEmpty from "../../../../App/Utilities/TableEmpty";
import {t} from "../../../../../system/i18n";
import AddProduct from "./AddProduct";
import {updateCollectionItem} from "../../../../App/services/helpers";
import {INIT_ORDER} from "../../../services/constants";
import {formatCurrency} from "../../../../../system/support/numberFormat";
import CurrencyInput from "../../../../App/Utilities/CurrencyInput";

export default function ProductInfo({orderSkus, setOrderSkus, extraServices, currentSeller, setExtraServices, paidTotal, setPaidTotal, validatedRules, form}) {
    const [total, setTotal] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(INIT_ORDER.discount_amount);
    const [shippingAmount, setShippingAmount] = useState(INIT_ORDER.shipping_amount);

    useEffect(() => {
        let totalOrder = calculateTotal(orderSkus);
        setTotal(totalOrder);
        let totalService = extraServices.reduce((totalServ, item) => totalServ+parseFloat(item.amount), 0);
        setPaidTotal(parseFloat(totalOrder) - parseFloat(discountAmount) + parseFloat(shippingAmount) + parseFloat(totalService));
    }, [orderSkus, discountAmount, shippingAmount, extraServices]);

    /**
     * Nếu đã thêm sản phẩm thì bỏ cảnh báo "Chưa chọn sản phẩm nào"
     */
    useEffect(() => {
        form.setFields([{name: "product_quantity", value: orderSkus.length > 0 ? orderSkus.length : ""}]);
        if (orderSkus.length > 0)
            form.validateFields(["product_quantity"]);
    }, [orderSkus]);

    /**
     * Nếu thay đổi currentSeller(seller hiện tại) của đơn hàng thì cập nhật lại giá các sku trong đơn theo seller
     */
    useEffect(() => {
        if (currentSeller) {
            let tmpSkus = orderSkus.map(item => {
                let price = "";
                let priceByMerchant = item.prices.find(subitem => subitem.merchant_id === currentSeller.id);
                if (priceByMerchant)
                    price = priceByMerchant.retail_price;

                return {...item, price};
            });
            setOrderSkus(tmpSkus);
        }
    }, [currentSeller]);

    function _onBlur(sku_id, name, value) {
        if (parseFloat(value)) {
            setOrderSkus(updateCollectionItem(orderSkus, sku_id, {[name]: parseFloat(value)}, "sku_id"));
        } else {
            setOrderSkus(updateCollectionItem(orderSkus, sku_id, {[name]: 0}, "sku_id"));
        }
    }

    function _onRemove(e, sku_id) {
        e.preventDefault();
        setOrderSkus(orderSkus.filter(sku => sku.sku_id !== parseInt(sku_id)))
    }

    // Thành tiền ở từng line sản phẩm = Số lượng * Đơn giá + (Số lượng * Đơn giá * Thuế) - Số tiền chiết khấu
    function calculateTotalOnRow(sku) {
        let pricePerQuantity = parseInt(sku.quantity)*(sku.price ? parseFloat(sku.price) : 0);
        return (pricePerQuantity + (pricePerQuantity*(sku.tax/100)) - sku.discount_amount)
    }

    // Tổng thành tiền
    function calculateTotal() {
        if (orderSkus.length === 0)
            return 0;

        return orderSkus.reduce((total, sku) => {
            let totalRow = calculateTotalOnRow(sku);
            return total + totalRow;
        }, 0);
    }

    let getColumns = [
        {
            className: " order-create-sku_code",
            title: t("product:label.sku_code"),
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => {
                return <strong style={{fontWeight: "500"}}>{text}</strong>
            }
        },
        {   className: " order-create-sku_name",
            title: t("product:label.sku_name"),
            dataIndex: 'name',
            key: 'name'
        },
        {   className: " order-create-unit_name",
            title: t("product:label.unit_name"),
            dataIndex: 'unit',
            key: 'unit',
            width: 70
        },
        {   className: " order-create-quantity",
            title: t("product:label.quantity"),
            dataIndex: 'quantity',
            key: 'quantity',
            width: 85,
            render: (text, record) => {
                return (
                    <InputNumber
                        name="quantity"
                        value={text}
                        className="text-right"
                        onBlur={e => _onBlur(record.sku_id, "quantity", e.target.value)}
                        onChange={value => _onBlur(record.sku_id, "quantity", value)}
                        step={1}
                        min={0}
                        style={{width: "100%"}}
                    />
                );
            }
        },
        {   className: " order-create-price_for_one",
            title: t("product:label.price_for_one"),
            dataIndex: 'price',
            key: 'price',
            width: 130,
            render: (text, record) => {
                return (
                    <CurrencyInput
                        value={text}
                        name="price"
                        style={{width:"100%"}}
                        className="text-right"
                        onBlur={value => _onBlur(record.sku_id, "price", value)}
                    />
                );
            }
        },
        // {
        //     title: t("product:label.tax")+" (%)",
        //     dataIndex: 'tax',
        //     key: 'tax',
        //     width: 90,
        //     render: (text, record) => {
        //         return (
        //             <InputNumber
        //                 name="tax"
        //                 defaultValue={text}
        //                 onBlur={e => _onBlur(record.sku_id, "tax", e.target.value)}
        //                 onPressEnter={e => _onBlur(record.sku_id, "tax", e.target.value)}
        //                 min={0}
        //                 max={100}
        //                 step={1}
        //                 style={{width: "100%"}}
        //             />
        //         );
        //     }
        // },
        {
            className: " order-create-sale_off",
            title: t("product:label.sale_off"),
            dataIndex: 'discount_amount',
            key: 'discount_amount',
            width: 110,
            render: (text, record) => {
                return (
                    <CurrencyInput
                        value={text}
                        name="discount_amount"
                        style={{width:"100%"}}
                        className="text-right"
                        onBlur={value => _onBlur(record.sku_id, "discount_amount", value)}
                    />
                );
            }
        },
        {   className: " order-create-total_amount text-center",
            title: t("product:label.price_for_all"),
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (text, record) => {
                return formatCurrency(calculateTotalOnRow(record));
            }
        },
        {
            title: "Xóa",
            className: "text-center order-create-delete",
            dataIndex: 'delete',
            key: 'delete',
            render: (text, record) => {
                return <span className={"text-red table-btn-remove-row cursor-pointer"} onClick={e => _onRemove(e, record.sku_id)}><DeleteOutlined /></span>;
            }
        }
    ];

    return (
        <div className="border box-shadow bg-white pt-3 pr-4 pl-4 pb-4 mt-4">
            <h2 className="title-box mb-2">{t("order:label.product_info")}</h2>
            <div>
                <AddProduct
                    setOrderSkus={setOrderSkus}
                    orderSkus={orderSkus}
                    form={form}
                    currentSeller={currentSeller}
                />

                <Table
                    className="list-products-table order-detail-table pb-2 mt-3"
                    dataSource={orderSkus}
                    rowKey={"sku_id"}
                    columns={getColumns}
                    pagination={false}
                    locale={{ emptyText: <TableEmpty /> }}
                />

                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            className="mb-0 hidden_input product_quantity"
                            name="product_quantity"
                            rules={validatedRules.product_quantity}
                        >
                            <InputNumber
                                name="product_quantity"
                                placeholder={t("order:placeholder.product_quantity")}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Row gutter={20} className="text-right mt-2">
                            <Col span="17" className="text-uppercase pr-3" style={{fontWeight: "500"}}>{t("orderDetail:label.total")}</Col>
                            <Col span="7" className="product_total">{formatCurrency(total > 0 ? total : 0)}</Col>
                        </Row>
                        <Row gutter={20} className="text-right mt-2">
                            <Col span="17" className="text-uppercase pr-3 d-flex align-items-center justify-content-end" style={{fontWeight: "500"}}>{t("orderDetail:label.total_promotion")}</Col>
                            <Col span="7">
                                <Form.Item
                                    className="mb-0"
                                    name="discount_amount"
                                >
                                    <CurrencyInput
                                        name="discount_amount"
                                        style={{width:"100%"}}
                                        className="text-right discount_amount"
                                        onBlur={value => {
                                            let newValue = value || 0;
                                            form.setFields([{name: "discount_amount", value: newValue}]);
                                            setDiscountAmount(newValue);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {/*<Col span={24} className="mt-1">*/}
                            {/*    <ExtraServices*/}
                            {/*        extraServices={extraServices}*/}
                            {/*        setExtraServices={setExtraServices}*/}
                            {/*    />*/}
                            {/*</Col>*/}
                        </Row>
                        {/*<Row gutter={20} className="text-right mt-2">*/}
                        {/*    <Col span="17" className="text-uppercase pr-3 d-flex align-items-center justify-content-end" style={{fontWeight: "500"}}>{t("orderDetail:label.deliveried_money")}</Col>*/}
                        {/*    <Col span="7">*/}
                        {/*        <Form.Item*/}
                        {/*            className="mb-0"*/}
                        {/*            name="shipping_amount"*/}
                        {/*        >*/}
                        {/*            <InputVnd*/}
                        {/*                name="shipping_amount"*/}
                        {/*                className={"text-right"}*/}
                        {/*                onBlur={value => {*/}
                        {/*                    form.setFields([{name: "shipping_amount", value}]);*/}
                        {/*                    setShippingAmount(value);*/}
                        {/*                }}*/}
                        {/*                style={{width:"100%"}}*/}
                        {/*                defaultValue={0}*/}
                        {/*            />*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row gutter={20} className="text-right mt-2 text-red">
                            <Col span="17" className="text-uppercase pr-3" style={{fontWeight: "500"}}>{t("orderDetail:label.money_customer_pay")}</Col>
                            <Col span="7">
                                <strong className="text-red money_customer_pay">{formatCurrency(paidTotal)}</strong>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </div>
        </div>
    );
}
