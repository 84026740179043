import { Button, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import useToggle from '@Modules/App/Hooks/useToggle';
import StyledTable from '@Modules/App/Table/StyledTable';

import { t } from '@System/i18n';
import { Link } from '@System/routing';

import OrderPackingsModal from './OrderPackingsModal';

const { Text } = Typography;

const WarehouseStocksTable = ({ loading, pagination, tableSelectionData, warehouseStocks, onChange }) => {
    const [orderPackingsModalVisible, { setLeft: closeOrderPackingsModal, setRight: openOrderPackingsModal }] = useToggle();
    const [selectedSku, setSelectedSku] = useState();
    const { rowSelection, render: renderSelection } = tableSelectionData;

    const handleSelectSku = usePersistFn(sku => {
        openOrderPackingsModal();
        setSelectedSku(sku);
    });

    const columns = useMemo(
        () => [
            {
                className: '_product-column',
                dataIndex: 'product',
                title: t('product'),
                render: text => (
                    <>
                        <Link className="text-primary" isNewTab params={{ id: text?.id }} to="products.detail">
                            {text?.code}
                        </Link>
                        <br />
                        <Text className="text-fz-12">{text?.name}</Text>
                    </>
                ),
            },
            {
                className: '_sku-code-column',
                dataIndex: ['sku', 'code'],
                title: t('sku_code'),
                render: (text, column) => (
                    <Button className="p-0 text-uppercasse" type="link" onClick={handleSelectSku.bind(this, column?.sku)}>
                        {text}
                    </Button>
                ),
            },
            {
                className: '_sku-name-column',
                dataIndex: ['sku', 'name'],
                title: t('sku_name'),
            },
            {
                className: '_warehouse-column',
                dataIndex: ['warehouse', 'code'],
                title: t('warehouse'),
            },
            {
                className: '_real-quantity-column',
                dataIndex: ['warehouse_stock', 'real_quantity'],
                title: t('inventory:actual_inventory'),
            },
            {
                className: '_selling-column',
                dataIndex: 'warehouse_stock',
                title: t('selling'),
                render: text => Math.abs((text?.real_quantity ?? 0) - (text?.quantity ?? 0)),
            },
            {
                className: '_quantity-column text-danger',
                dataIndex: ['warehouse_stock', 'quantity'],
                title: t('inventory:temporary_inventory'),
            },
            {
                className: '_purchasing-column',
                dataIndex: ['warehouse_stock', 'purchasing_quantity'],
                title: t('inventory:purchasing'),
            },
            {
                className: '_saleable-quantity text-danger',
                dataIndex: ['warehouse_stock', 'saleable_quantity'],
                title: t('inventory:prognosis'),
            },
        ],
        []
    );

    return (
        <>
            {renderSelection()}

            <StyledTable
                columns={columns}
                dataSource={warehouseStocks}
                loading={loading}
                pagination={pagination}
                rowKey={record => record?.sku?.id}
                rowSelection={rowSelection}
                scroll={{ x: 768 }}
                serverPagination
                onChange={onChange}
            />

            <OrderPackingsModal sku={selectedSku} visible={orderPackingsModalVisible} onCancel={closeOrderPackingsModal} />
        </>
    );
};

WarehouseStocksTable.propTypes = {
    warehouseStocks: PropTypes.array,
};

WarehouseStocksTable.defaultProps = {
    warehouseStocks: [],
};

export default WarehouseStocksTable;
