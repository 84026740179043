import React, {useState, useEffect} from "react";
import api from "../services/api";
import {t} from "../../../system/i18n";
import {notification, Select, Spin} from "antd";
import lodash from 'lodash';
import processResponseError from "@System/api/processResponseError";

const {Option} = Select;

export default function SelectSku(props) {
    const oldValue = props.value;
    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [value, setValue] = useState(undefined);

    useEffect(() => {
        if (oldValue !== value) {
            getSkuInfo(oldValue)
        }

        // eslint-disable-next-line
    }, [oldValue]);

    function getSkuInfo(value) {
        if (value) {
            setValue(value);
            api.getSkuDetail(value)
                .then((res) => {
                    const sku = lodash.get(res, "data.sku", {});
                    if (!lodash.isEmpty(sku)) {
                        setData([sku])
                    } else {
                        setData([]);
                    }
                }).catch(err => {
                    setData([]);
                    setValue(value);
            });
        } else {
            setData([]);
            setValue(undefined);
        }
    }


    const fetchSkus = lodash.debounce((value) => {
        setData([]);
        if (value && value.length >= 2) {
            setFetching(true);
            api.searchSkus({keyword: value})
                .then(res => {
                    const skus = (lodash.get(res, "data.skus", []));
                    setData(skus.map(item => {
                        return item.sku;
                    }));
                }).catch(err => {
                processResponseError(err);
            }).finally(() => {
                setFetching(false);
            })
        }
    }, 500);

    function onChange(value) {
        setFetching(false);
        setValue(value);
        props.onChange(value);
    }

    return (
        <Select
            {...props}
            allowClear={true}
            showSearch
            value={value}
            placeholder={props.placeholder ? props.placeholder : t("product:placeholder.sku_name")}
            notFoundContent={fetching ? <Spin size="small"/> : null}
            filterOption={false}
            onSearch={(value) => fetchSkus(value)}
            onChange={(value) => onChange(value)}
            style={{width: '100%'}}
        >
            {
                data.map(sku => (
                    <Option key={sku.id}>
                        {
                            sku.name + (sku.code ? " (" + (sku.code === "default" ? t("order:label.default") : sku.code) + ")" : "")
                        }
                    </Option>
                ))
            }
        </Select>
    );
}
