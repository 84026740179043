import { EyeInvisibleOutlined, EyeTwoTone, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import isFunction from 'lodash/isFunction';
import React, { useEffect } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import SelectMerchants from '@Modules/Merchant/components/SelectMerchants';

import { t } from '@System/i18n';

import useAddPurchasingAccountMutation from '../Hooks/useAddPurchasingAccountMutation';
import ServicesSelect from './ServicesSelect';

const validatedRules = {
    purchasing_service_id: [
        {
            required: true,
            message: t('validation:required', {
                attribute: t('service'),
            }),
        },
    ],
    username: [
        {
            required: true,
            whitespace: true,
            message: t('validation:required', {
                attribute: t('username'),
            }),
        },
        {
            max: 255,
            message: t('validation:max.string', {
                attribute: t('username'),
                max: 255,
            }),
        },
    ],
    password: [
        {
            required: true,
            whitespace: true,
            message: t('validation:required', {
                attribute: t('password'),
            }),
        },
        {
            max: 255,
            message: t('validation:max.string', {
                attribute: t('password'),
                max: 255,
            }),
        },
    ],
};

const AddPurchasingAccountModal = ({ visible, onCancel, onOk }) => {
    const [form] = Form.useForm();
    const { isLoading: adding, mutate: addPurchasingAccount } = useAddPurchasingAccountMutation({
        onSuccess: () => {
            if (isFunction(onOk)) {
                onOk();
            }
        }
    });

    const handleOk = usePersistFn(() => {
        form.submit();
    });

    const handleFinish = usePersistFn(values => {
        addPurchasingAccount(values);
    });

    useEffect(() => {
        if (!visible) {
            form.resetFields();
        }

        // eslint-disable-next-line
    }, [visible]);

    return (
        <Modal
            className="_add-purchasing-account-modal"
            footer={
                <>
                    <Button className="_cancel-btn" onClick={onCancel}>{t('btn.close')}</Button>
                    <Button className="_ok-btn" type="primary" loading={adding} onClick={handleOk}>
                        <PlusOutlined />
                        {t('add_account')}
                    </Button>
                </>
            }
            title={t('add_account')}
            visible={visible}
            onCancel={onCancel}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item label={t('service')} name="purchasing_service_id" rules={validatedRules.purchasing_service_id}>
                    <ServicesSelect
                        className="_services"
                        dropdownClassName="_services-dropdown"
                        placeholder={t('select_service_need_to_link')}
                    />
                </Form.Item>

                <Form.Item label={t('username')} name="username" rules={validatedRules.username}>
                    <Input className="_username" placeholder={t('input_username')} />
                </Form.Item>

                <Form.Item label={t('password')} name="password" rules={validatedRules.password}>
                    <Input.Password
                        autoComplete="new-password"
                        className="_password"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        placeholder={t('input_password')}
                    />
                </Form.Item>

                <Form.Item label={t('pin')} name="pin_code">
                    <Input.Password className="_pin" placeholder={t('input_pin')} />
                </Form.Item>

                <Form.Item className="mb-0" label={t('vendor_info')} name="merchant_id">
                    <SelectMerchants
                        allowClear
                        className="_vendor"
                        dropdownClassName="_vendors-dropdown"
                        placeholder={t('select_link_vendor')}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddPurchasingAccountModal;
