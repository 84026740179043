import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import React from 'react';

import { t } from '@System/i18n';

import OptionItem from './OptionItem';

const { Title } = Typography;

export default function ProductOptions({ localOptions, setLocalOptions, checkOptions, form }) {
    function updateOptions(index, newOption) {
        let updatedOptions = localOptions.map((option, nth) => {
            return index === nth ? newOption : option;
        });
        setLocalOptions(updatedOptions);
        form.setFields([{ name: 'options', errors: [] }]);
    }

    function addOption() {
        if (checkOptions() && localOptions.length < 3) {
            let newOptions = [...localOptions];
            newOptions.push({ id: null, label: '', values: [] });
            setLocalOptions(newOptions);
        }
    }

    function removeOption(index) {
        let newOptions = localOptions.filter((_, nth) => index !== nth);
        setLocalOptions(newOptions);
    }

    return (
        <div className="product-options mt-4 _product-option">
            <div className="d-flex justify-content-between align-items-center">
                <Title className="mb-0" level={5}>
                    {t('product:product_property')}
                </Title>
                {localOptions.length < 3 && (
                    <Button className="cursor-pointer text-primary mr-2 _product-option-add-btn" onClick={addOption}>
                        <PlusOutlined /> {t('product:btn.option_add_btn')}
                    </Button>
                )}
            </div>
            <div>
                {localOptions.length > 0 &&
                    localOptions.map((option, index) => (
                        <OptionItem
                            key={index}
                            index={index}
                            option={option}
                            updateOptions={updateOptions}
                            removeOption={removeOption}
                        />
                    ))}
                {localOptions.length === 0 && <p className="text-center mt-4 mb-5">{t('product:message.not_options')}</p>}
                <Form.Item className="_product-option-input-options" label={null} name="options" hidden={true}>
                    <Input />
                </Form.Item>
            </div>
        </div>
    );
}
