import { Avatar, Badge, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import StyledTable from '@Modules/App/Table/StyledTable';

import { t } from '@System/i18n';

import { STATUSES } from '../services/constants';
import CheckingBalance from './PurchasingAccountsTable/CheckingBalance';

const PurchasingAccountsTable = ({ loading, pagination, purchasingAccounts, onChange, onDelete, onRowClick }) => {
    const columns = useMemo(
        () => [
            {
                className: '_avatar-column',
                dataIndex: 'username',
                key: ['purchasing_service', 'logo'],
                width: '0px',
                render: (text, record) => (
                    <Tooltip title={record?.purchasing_service?.name}>
                        <Avatar size={24} src={text} />
                    </Tooltip>
                ),
            },
            {
                className: '_username-column',
                dataIndex: 'username',
                key: 'username',
                title: t('purchasingAccount:table.columns.username'),
                render: (text, _, index) => (
                    <Button className="p-0" type="link" onClick={onRowClick.bind(this, index)}>
                        {text}
                    </Button>
                ),
            },
            {
                className: '_merchant-column',
                dataIndex: ['merchant', 'name'],
                key: 'merchant',
                title: t('purchasingAccount:table.columns.vendor'),
            },
            {
                className: '_status-column',
                dataIndex: 'status',
                key: 'status',
                title: t('purchasingAccount:table.columns.status'),
                render: text =>
                    text ? (
                        <Badge color={STATUSES[text.toUpperCase()]?.color} text={t(`purchasingAccount:statuses.${text}`)} />
                    ) : null,
            },
            {
                className: '_balance-column',
                dataIndex: 'id',
                key: 'balance',
                title: t('purchasingAccount:table.columns.balance'),
                render: (text, record) => <CheckingBalance disabled={record.status !== STATUSES.ACTIVE.value} id={text} />,
            },
            {
                className: '_actions-column',
                key: 'actions',
                render: (_, __, index) => (
                    <Button type="danger" onClick={onDelete.bind(this, index)}>
                        {t('btn.delete')}
                    </Button>
                ),
            },
        ],
        [onDelete, onRowClick]
    );

    return (
        <StyledTable
            columns={columns}
            dataSource={purchasingAccounts}
            loading={loading}
            pagination={pagination}
            rowKey="id"
            onChange={onChange}
        />
    );
};

PurchasingAccountsTable.propTypes = {
    purchasingAccounts: PropTypes.array,
};

PurchasingAccountsTable.defaultProps = {
    purchasingAccounts: [],
};

export default PurchasingAccountsTable;
