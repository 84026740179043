import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useGroupBatchQuery = (params, settings) =>
    createUseQuery({
        name: ['group-by-batch', params],
        query: () => apiService.getGroupBatchQuery(params),
        settings,
    });

export default useGroupBatchQuery;
