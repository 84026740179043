import { useQuery } from 'react-query';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { convertBlobDataToExcel, notifyErrorFromServer } from '@System/support/helpers';

import apiService from './../services/api';

const useDownloadRefSkusQuery = params =>
    useQuery(['downloadRefSkus', params], () => apiService.downloadRefSkus(params), {
        enabled: false,
        retry: false,
        onSuccess: ({ data }) => {
            notification.success(t('product:download_ref_skus.success'));
            convertBlobDataToExcel(data, 'product-list');
        },
        onError: error => {
            notifyErrorFromServer(error, t('product:download_ref_skus.failed'), 'product:download_ref_skus.errors');
        },
    });

export default useDownloadRefSkusQuery;
