import { Table } from 'antd'
import lodash, { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import StyledTag from '@Modules/App/StyledTag'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'

import { t } from '@System/i18n'
import { url } from '@System/routing'

import { STATUSES } from '../../../Document/constants'

export default function DocumentList({ orderDetail }) {
    let { order, documents } = orderDetail

    const redirectToDocument = (type, id) => {
        switch (type) {
            case 'PACKING':
            case 'EXPORTING':
                return url.to(`documents.${type.toLowerCase()}.detail`, { id })
            case 'FREIGHT_BILL_INVENTORY':
                return url.to(`finances.control.detail`, { id })
            case 'DELIVERY_COMPARISON':
                return url.to(`finances.control-delivery.detail`, { id })
            default:
                break
        }
    }

    let getColumns = [
        {
            title: t('document:label.type'),
            className: 'text-nowrap document-list-type ',
            dataIndex: 'type',
            key: 'type',
            render: text => t(`document:types.${text.toLowerCase()}`),
        },
        {
            title: t('document:label.code'),
            className: 'text-nowrap document-list-code',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => {
                return (
                    <Link
                        // isNewTab
                        // params={{ id: record.id }}
                        // to={`documents.${record.type.toLowerCase()}.detail`}
                        to={redirectToDocument(get(record, 'type', ''), get(record, 'id'))}
                        target={'_blank'}
                    >
                        {text}
                    </Link>
                )
            },
        },
        {
            title: t('document:label.verifier_username'),
            className: 'text-nowrap document-list-verifier_username',
            dataIndex: 'verifier_username',
            key: 'verifier_username',
        },
        {
            title: t('document:label.verified_at'),
            dataIndex: 'verified_at',
            key: 'verified_at',
            className: 'text-nowrap document-list-verified_at',
            render: text => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
        },
        {
            title: t('document:label.creator_username'),
            dataIndex: 'creator_username',
            className: 'text-nowrap document-list-creator_username',
            key: 'creator_username',
        },
        {
            title: t('document:label.created_at'),
            dataIndex: 'verified_at',
            key: 'verified_at',
            className: 'text-nowrap document-list-verified_at',
            render: text => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
        },
        {
            dataIndex: 'status',
            key: 'status',
            className: 'text-nowrap document-list-status',
            render: (text, record) => {
                const type = lodash.get(record, 'type', 'EXPORTING')
                return (
                    <StyledTag
                        className="mb-1"
                        type={text === STATUSES.COMPLETED && 'success'}
                    >
                        {t(`document:statuses_type.${type}.${text}`)}
                    </StyledTag>
                )
            },
        },
    ]

    return (
        <Table
            className="pb-3  document-list-table"
            dataSource={documents}
            rowKey={record => record.id}
            columns={getColumns}
            pagination={false}
            locale={{ emptyText: <TableEmpty /> }}
            scroll={{ x: true }}
        />
    )
}
