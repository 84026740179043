import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useWarehouseAreasQuery = (params, settings) =>
    createUseQuery({
        name: ['warehouse-areas', params],
        query: () => apiService.getListWarehouseArea(params),
        settings,
    });

export default useWarehouseAreasQuery;
