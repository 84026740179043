import { PlusOutlined, MinusOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Table, Tooltip, Typography } from 'antd';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import findLastIndex from 'lodash/findLastIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

import useTableSelection from '@Modules/App/Hooks/useTableSelection';
import { updateCollectionItem } from '@Modules/App/services/helpers';
import PrintSkusBarcode from '@Modules/Product/components/PrintSkusBarcode';
import { PRINT_SKU_TYPE } from '@Modules/Product/services/constants';
import SelectWarehouseArea from '@Modules/WarehouseArea/components/SelectWarehouseArea';

import { t } from '@System/i18n';
import { url } from '@System/routing';

import CustomizedPagination from '../../../../App/CustomizedPagination';
import useSize from '../../../../App/Hooks/useSize';
import Space from '../../../../App/Space';
import { STATUSES } from '../../../constants';
import ModalAdditionalSku from '../../../components/ModalAdditionalSku';
import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';

const { Title } = Typography;

const ProductList = props => {
    const { isXSmall, isSmall } = useSize();
    const {
        importingDocument,
        skusImporting,
        setSkusImporting,
        form,
        isChanged,
        setIsChanged,
        handleSave,
        setDocumentSkusDelete,
        documentSkusDelete,
        refactorDataSkusImporting,
        setFilters,
        isLoading,
        filters,
        skusImportingInfo,
        refetchImportingDocument,
        refetchSkusImporting
    } = props;
    const { rowSelection, selectedRowKeys } = useTableSelection({});
    const [pagination, setPagination] = useState({});
    const statusDocument = get(importingDocument, 'status', '');
    const [visibleModalAdditional, setVisibleModalAdditional] = useState(false)
    const [skusLackInfo, setSkusLackInfo] = useState([])

    useEffect(() => {
        setSkusImporting(refactorDataSkusImporting(skusImportingInfo?.data?.document_sku_importings));
        setPagination(skusImportingInfo?.data?.pagination);
    }, [skusImportingInfo]);

    useEffect(() => {
        const refactorData = () => {
            const newData = skusImporting.filter((item) => item.lack_of_information === false)
            setSkusLackInfo(newData)
        }

        refactorData()
    }, [skusImporting])


    const handleAddRow = data => {
        const newSkusImporting = [...skusImporting];
        const parentInfo = find(newSkusImporting, { sku_id: data.sku_id, isParent: true });
        const parentIndex = findIndex(newSkusImporting, { sku_id: data.sku_id, isParent: true });
        newSkusImporting[parentIndex] = {
            ...parentInfo,
            rowSpan: parentInfo.rowSpan + 1,
        };

        const lastIndex = findLastIndex(newSkusImporting, ['sku_id', data.sku_id]);
        newSkusImporting.splice(lastIndex + 1, 0, {
            ...data,
            documentSkuId: undefined,
            quantity: '',
            real_quantity: '',
            key: v4(),
            warehouse_area_id: null,
            isParent: false,
            rowSpan: 0,
        });

        setSkusImporting(newSkusImporting);
    };

    const handleDeleteRow = data => {
        const newSkusImporting = [...skusImporting];
        const newDocumentSkusDelete = [...documentSkusDelete];
        const parentInfo = find(newSkusImporting, { sku_id: data.sku_id, isParent: true });
        const parentIndex = findIndex(newSkusImporting, { sku_id: data.sku_id, isParent: true });
        newSkusImporting[parentIndex] = {
            ...parentInfo,
            rowSpan: parentInfo.rowSpan - 1,
        };

        if (data?.documentSkuId) {
            newDocumentSkusDelete.push({
                is_deleted: true,
                sku_importing_id: data.documentSkuId,
                warehouse_area_id: data.warehouse_area_id,
                real_quantity: data.real_quantity,
                sku_id: data.sku_id,
            });
            setDocumentSkusDelete(newDocumentSkusDelete);
        }

        const lastIndex = findLastIndex(newSkusImporting, ['key', data.key]);
        newSkusImporting.splice(lastIndex, 1);
        setSkusImporting(newSkusImporting);
    };

    function handleChangePagination(filters) {
        if (isChanged) {
            Modal.confirm({
                title: t('document:import_confirm.title_update_sku'),
                content: t('document:import_confirm.content_update_sku'),
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
                onOk() {
                    handleSave();
                    setFilters(filters);
                },
                onCancel() {
                    setFilters(filters);
                },
            });
        } else {
            setFilters(filters);
        }
        setIsChanged(false);
        setDocumentSkusDelete([]);
    }

    const columns = [
        {
            className: '_importing-document-product-list-sku-code',
            title: t('product:label.sku_code'),
            dataIndex: 'sku_code',
            width: '22%',
            render: (text, record) => {
                const product_id = get(record, 'product_id', '');
                const sku_code = get(record, 'sku_code', '');
                const lack_of_information = get(record, 'lack_of_information', '');

                return {
                    children: (
                        <strong style={{ fontWeight: '500' }}>
                            <Link target="_blank" to={url.to('products.detail', { id: product_id })}>
                                {sku_code}
                            </Link>
                            {statusDocument === STATUSES.DRAFT && lack_of_information === false && <Tooltip title={t('document:messages.skus_lack_of_info')} className='ml-1'>
                                <ExclamationCircleOutlined style={{ color: '#faad14' }} />
                            </Tooltip>}
                        </strong>
                    ),
                    props: {
                        rowSpan: record.rowSpan,
                    },
                };
            },
        },
        {
            className: '_importing-document-product-list-product-name',
            title: t('product:label.product_name'),
            dataIndex: 'sku_name',
            render: (text, record) => {
                return {
                    children: <p>{text}</p>,
                    props: {
                        rowSpan: record.rowSpan,
                    },
                };
            },
        },
        {
            className: '_importing-document-product-list-warehouse-area-name',
            title: t('product:label.warehouse_area_name'),
            dataIndex: 'warehouse_area_id',
            editable: statusDocument === STATUSES.DRAFT,
            width: '25%',
            render: (text, record) => {
                const warehouse_area = get(record, 'warehouse_area', {});
                if (isEmpty(warehouse_area)) {
                    return '';
                } else {
                    const { name, code } = warehouse_area;
                    return (
                        (name === 'default' ? t('order:label.default') : name) +
                        (code ? ' (' + (code === 'default' ? t('order:label.default') : code) + ')' : '')
                    );
                }
            },
        },
        {
            className: 'text-right text-nowrap _importing-document-product-list-quantity',
            title: t('product:label.quantity'),
            dataIndex: 'quantity',
            width: '100px',
            render: (text, record) => {
                return {
                    children: <p>{text}</p>,
                    props: {
                        rowSpan: record.rowSpan,
                    },
                };
            },
        },
        {
            className: '_importing-document-product-list-real-quantity',
            title: t('product:label.real_receiver_quantity'),
            dataIndex: 'real_quantity',
            editable: statusDocument === STATUSES.DRAFT,
            width: isXSmall || isSmall ? '150px' : '200px',
        },
        {
            className: 'text-right _importing-document-product-action',
            width: '50px',
            dataIndex: 'product-action',
            render: (text, record) => {
                const isParent = get(record, 'isParent', false);
                return isParent ? (
                    <span
                        className="_span--add cursor-pointer _importing-document-product-action-add"
                        onClick={() => handleAddRow(record)}
                    >
                        <PlusOutlined />
                    </span>
                ) : (
                    <span
                        className="_span--delete cursor-pointer _importing-document-product-action-delete"
                        onClick={() => handleDeleteRow(record)}
                    >
                        <MinusOutlined />
                    </span>
                );
            },
        },
    ];

    function checkWarehouseAreaDuplicate(data, value) {
        const skuImporting = find(skusImporting, { sku_id: data.sku_id, warehouse_area_id: value });
        return !isEmpty(skuImporting) && skuImporting.key !== data.key;
    }

    function updateSkuImportingInfo(name, value, data) {
        const skuInfoByKey = find(skusImporting, ['key', data.key]);
        if (!isEmpty(skuInfoByKey) && skuInfoByKey[name] !== value) {
            const newSkusImporting = updateCollectionItem(skusImporting, data.key, { [name]: value }, 'key');
            setSkusImporting(newSkusImporting);
            setIsChanged(true);
        }
    }

    const tableColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record, index) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                index,
                checkWarehouseAreaDuplicate,
                updateSkuImportingInfo,
            }),
        };
    });

    const EditableCell = ({
        editable,
        children,
        dataIndex,
        index,
        record,
        updateSkuImportingInfo,
        checkWarehouseAreaDuplicate,
        ...restProps
    }) => {
        useEffect(() => {
            if (editable) {
                form.setFields([{ name: [index, dataIndex], value: record[dataIndex] }]);
            }
        }, [editable]);

        const handleUpdateSkuImportingInfo = (name, value) => {
            updateSkuImportingInfo(name, value, record);
        };

        const onChangeWarehouseArea = (name, value) => {
            form.setFields([{ name: [index, name], value: value }]);
            if (!checkWarehouseAreaDuplicate(record, value)) {
                form.setFields([{ name: [index, name], errors: '' }]);
                handleUpdateSkuImportingInfo(name, value);
            }
        };

        let childNode = children;
        if (editable) {
            if (dataIndex === 'warehouse_area_id') {
                childNode = (
                    <Form.Item
                        className="mb-0"
                        name={[index, dataIndex]}
                        rules={[
                            { required: true, message: t('product:message.required') },
                            () => ({
                                validator(rule, value) {
                                    if (checkWarehouseAreaDuplicate(record, value)) {
                                        return Promise.reject(t('product:message.not_duplicate'));
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <SelectWarehouseArea
                            allowClear={true}
                            placeholder={t('product:placeholder.warehouse_area_name')}
                            warehouseid={importingDocument?.warehouse_id ? parseInt(importingDocument?.warehouse_id) : undefined}
                            value={record[dataIndex] ? parseInt(record[dataIndex]) : undefined}
                            onChange={value => onChangeWarehouseArea(dataIndex, value)}
                            noSuggest={false}
                        />
                    </Form.Item>
                );
            } else {
                form.setFields([{ name: [index, 'sku_id'], value: record['sku_id'] }]);

                if (record['documentSkuId']) {
                    form.setFields([{ name: [index, 'sku_importing_id'], value: record['documentSkuId'] }]);
                } else {
                    form.setFields([{ name: [index, 'sku_importing_id'], value: undefined }]);
                }

                childNode = (
                    <>
                        <Form.Item
                            className="mb-0"
                            name={[index, dataIndex]}
                            rules={[
                                { required: true, message: t('product:message.required') },
                                { pattern: /^\+?([0-9]\d*)$/, message: t('validation:min.numeric', { min: 0 }) },
                            ]}
                        >
                            <Input
                                className='_importing-document-product-list-real-quantity-input'
                                onPressEnter={e =>
                                    handleUpdateSkuImportingInfo(
                                        dataIndex,
                                        Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : ''
                                    )
                                }
                                onBlur={e =>
                                    handleUpdateSkuImportingInfo(
                                        dataIndex,
                                        Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : ''
                                    )
                                }
                            />
                        </Form.Item>
                        <Form.Item className="mb-0" hidden={true} name={[index, 'sku_id']}>
                            <Input />
                        </Form.Item>

                        <Form.Item className="mb-0" hidden={true} name={[index, 'sku_importing_id']}>
                            <Input />
                        </Form.Item>
                    </>
                );
            }
        }
        return <td {...restProps}>{childNode}</td>;
    };
    const handleOpenModalAdditional = () => {
        setVisibleModalAdditional(true)
    }
    return (
        <>
            <div className="d-flex flex-wrap justify-content-between p-2 pt-3 pb-3 align-items-center mb-2">
                <Space type="flex">
                    <Title className="mb-0" level={5}>
                        {t('product_list')}
                    </Title>
                    <span className="number-circle _importing-document-product-list-total">
                        {pagination?.total ?? 0}
                    </span>
                    {
                        statusDocument === STATUSES.DRAFT && !isEmpty(skusLackInfo) &&
                        <div className='d-flex justify-content-center align-items-center'>
                            <p className="mb-0 font-weight-500" style={{ color: '#ff4d4f' }}>
                                {t('document:messages.please_add_product_size')}
                            </p>
                            {auth.can(permissions.PRODUCT_UPDATE) &&
                                <Button type='primary' className='ml-2' onClick={handleOpenModalAdditional} loading={isLoading}>{t('document:btn.additional')}</Button>}
                        </div>
                    }
                </Space>
                <div>
                    <PrintSkusBarcode
                        className="product-list"
                        skuIds={selectedRowKeys}
                        data={skusImporting}
                        printType={PRINT_SKU_TYPE.SKU_ID}
                    />
                    <PrintSkusBarcode className="product-list ml-2" skuIds={selectedRowKeys} data={skusImporting} />
                </div>
            </div>
            <Form form={form} component={false}>
                <Table
                    bordered
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    rowSelection={{
                        getCheckboxProps: record => {
                            return {
                                disabled: record.isParent === false,
                            };
                        },
                        renderCell: (checked, record, index, originNode) => {
                            return {
                                children: originNode,
                                props: {
                                    rowSpan: record.rowSpan,
                                },
                            };
                        },
                        ...rowSelection,
                    }}
                    rowClassName={record => `_sku-code-${record.sku_code}`}
                    loading={isLoading}
                    dataSource={skusImporting}
                    columns={
                        statusDocument === STATUSES.DRAFT
                            ? tableColumns
                            : tableColumns.filter(col => col.dataIndex !== 'product-action')
                    }
                    pagination={false}
                    scroll={{ x: 576 }}
                />
            </Form>
            <CustomizedPagination pagination={pagination} filters={filters} addFilter={handleChangePagination} />
            {visibleModalAdditional && 
                <ModalAdditionalSku 
                    setVisible={setVisibleModalAdditional} 
                    skusLackInfo={skusLackInfo} 
                    refetchImportingDocument={refetchImportingDocument} 
                    refetchSkusImporting={refetchSkusImporting}
                /> 
            }
        </>
    );
};

export default ProductList;
