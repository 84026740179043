import { useQuery } from 'react-query';

import apiService from './../services/api';

const useSellerTransactionQuery = (id, params) =>
    useQuery(['get-seller-transaction', id, params], () => apiService.getMerchantsTransaction(id, params), {
        retry: false,
        enabled: !!id,
    });

export default useSellerTransactionQuery;
