import { Form } from 'antd';
import React, { useEffect } from 'react';

import useAntdTable from '@Modules/App/Hooks/useAntdTable';
import {setDocumentTitle, setNavigator} from '@Modules/App/services';
import usePurchasingAccountsQuery from '@Modules/PurchasingAccount/Hooks/usePurchasingAccountsQuery';

import { t } from '@System/i18n';

import Body from './components/Body';
import Header from './components/Header';

const List = () => {
    const [form] = Form.useForm();
    const {
        isFetching,
        pagination,
        purchasing_accounts: purchasingAccounts,
        search,
    } = useAntdTable(usePurchasingAccountsQuery, {
        form,
    });

    const handleChangePage = params => {
        search.submit(params);
    };

    useEffect(() => {
        setNavigator(t('purchasingAccount:manage_purchasing_account'));
        setDocumentTitle(t("purchasingAccount:manage_purchasing_account"));
    }, []);

    return (
        <div className="purchasing-account-page px-4 py-px-32">
            <Header className="mb-4" form={form} loading={isFetching} onClear={search.clear} onSubmit={search.submit} />
            <Body
                loading={isFetching}
                pagination={pagination}
                purchasingAccounts={purchasingAccounts}
                onChangePage={handleChangePage}
                onRefetch={search.submit}
            />
        </div>
    );
};

export default List;
