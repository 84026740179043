import { Button, Input, Typography } from 'antd';
import { every, get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import useUpdateImportDocumentMutation from '@Modules/Document/Hooks/useUpdateImportingDocumentMutation';
import ExportDocumentReturnOrder from '@Modules/Document/screens/Importing/ReturnOrder/components/ExportDocumentReturnOrder';

import { trans } from '../../../../../../system/i18n';
import StyledTag from '../../../../../App/StyledTag';
import { dateFormatter } from '../../../../../App/services';
import { STATUSES } from '../../../../constants';

const { Paragraph } = Typography;

const Header = ({
    importingDocument,
    handleDelete,
    handleImport,
    documentId,
    handleSave,
    updating,
    isChanged,
    isImporting,
    onSuccessUpdate = { onSuccessUpdate },
}) => {
    const statusDocument = get(importingDocument, 'status', '');

    const { mutate: updateImportingDocument } = useUpdateImportDocumentMutation({
        id: importingDocument?.id,
        type: importingDocument?.type,
        onSuccess: data => {
            onSuccessUpdate(data);
        },
    });

    const [note, setNote] = useState();

    useEffect(() => {
        setNote(get(importingDocument, 'note', undefined));
    }, [importingDocument]);

    const onUpdateData = (name, value) => {
        updateImportingDocument({ [name]: value });
    };

    return (
        <div className="bg-white pt-3 pr-3 pl-3">
            <div className="w-100">
                <div className="d-flex flex-column flex-lg-row justify-content-lg-between">
                    <div className="d-flex mb-3 mb-lg-0 flex-wrap">
                        <div className="mr-lg-3 mb-3">
                            <div className="d-flex">
                                <StyledTag
                                    className="mb-1 _importing-document-status"
                                    type={importingDocument?.status === STATUSES.COMPLETED && 'success'}
                                >
                                    {trans(`document:statuses_type.IMPORTING.${importingDocument?.status}`)}
                                </StyledTag>
                                <Paragraph className="font-weight-bold text-fz-16 mb-0 mr-2 _importing-document-code">
                                    {trans('document:types.importing_return_order')}: #{importingDocument?.code}
                                </Paragraph>
                            </div>
                            <div className="_importing-document-created-by">
                                {trans('document:created_by', {
                                    date: dateFormatter.date(importingDocument?.created_at),
                                    confirmer: importingDocument?.creator_username || '--',
                                })}
                            </div>
                        </div>
                        <div className="ml-lg-auto">
                            {importingDocument?.status === STATUSES.DRAFT && (
                                <Button danger onClick={handleDelete} className="_importing-document-delete">
                                    {trans('document:delete.title')}
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className="d-flex flex-wrap">
                        <ExportDocumentReturnOrder className="mr-2 mb-3" documentId={documentId} />
                        {importingDocument?.status === STATUSES.DRAFT && (
                            <>
                                <Button
                                    className="_importing-document-btn-save"
                                    onClick={handleSave}
                                    loading={updating}
                                    // disabled={!isError}
                                >
                                    {trans('btn.save')}
                                </Button>
                                <Button
                                    className="_importing-document-btn-confirm ml-2"
                                    type="primary"
                                    onClick={handleImport}
                                    loading={isImporting}
                                    // disabled={!isError}
                                >
                                    {isChanged ? trans('btn.save_and_confirm') : trans('btn.confirm')}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <div className="mt-2">
                    <Input.TextArea
                        className="_product-list-note"
                        placeholder={trans('note')}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        value={note}
                        onBlur={e => onUpdateData('note', e.target.value.trim())}
                        onChange={e => setNote(e.target.value)}
                        disabled={statusDocument !== STATUSES.DRAFT}
                    />
                </div>
            </div>
        </div>
    );
};

export default Header;
