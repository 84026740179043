import { Button, Form, Modal } from 'antd';
import React, { useEffect, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import useOAuthUrlQuery from '@Modules/Marketplace/Hooks/useOAuthUrlQuery';
import SelectMerchants from '@Modules/Merchant/components/SelectMerchants';

import { t } from '@System/i18n';

const validatedRules = {
    merchan_id: [
        {
            required: true,
            message: t('validation:required', {
                attribute: t('vendor'),
            }),
        },
    ],
};

const AddPurchasingAccountModal = ({ marketplaceCode, visible, onCancel, onOk }) => {
    const [merchantId, setMerchantId] = useState();
    const [form] = Form.useForm();
    const { isFetching: loadingOAuthUrl, refetch: getOAuthUrl } = useOAuthUrlQuery({
        code: marketplaceCode,
        merchant_id: merchantId,
    }, {
        onSuccess: ({ data }) => {
            window.location.href = data?.url;
        }
    });

    const handleOk = usePersistFn(() => {
        form.submit();
    });

    const handleFinish = usePersistFn(() => {
        getOAuthUrl();
    });

    useEffect(() => {
        if (!visible) {
            form.resetFields();
        }

        // eslint-disable-next-line
    }, [visible]);

    return (
        <Modal
            centered
            className="_add-connection-modal"
            footer={
                <>
                    <Button className="_cancel-btn" onClick={onCancel}>
                        {t('btn.close')}
                    </Button>
                    <Button className="_ok-btn" type="primary" loading={loadingOAuthUrl} onClick={handleOk}>
                        {t('add_connection')}
                    </Button>
                </>
            }
            title={t('add_connection')}
            visible={visible}
            onCancel={onCancel}
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item className="mb-0" label={t('vendor_info')} name="merchant_id" rules={validatedRules.merchan_id}>
                    <SelectMerchants
                        allowClear
                        className="_vendor"
                        dropdownClassName="_vendors-dropdown"
                        placeholder={t('select_link_vendor')}
                        onChange={setMerchantId}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddPurchasingAccountModal;
