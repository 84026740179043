import { Col, Row, Table } from 'antd';
import clsx from 'clsx';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import Barcode from 'react-barcode';

import { currencyFormatter } from '@Modules/App/services';
import {PATTERN_CHECK_IS_CODE_39} from '@Modules/Product/services/constants';

import { t } from '@System/i18n';

const ShippingStamp = forwardRef(({ orderPackings }, ref) => {
    return (
        <div className="A4" ref={ref}>
            {orderPackings?.length &&
                map(orderPackings, (orderPacking, index) => {
                    return (
                        <div
                            className={clsx('sheet stamp _shipping-stamp', {
                                'page-break': index !== 0,
                            })}
                            key={orderPacking?.id}
                        >
                            <div className="shipping-info">
                                <div className="p-3 text-center">
                                    <p className="mb-0 text-fz-20">{t('order:label.shipping_stamp').toUpperCase()}</p>
                                </div>

                                <div className="p-3 text-center">
                                    {!!orderPacking?.freight_bill && (
                                        <Barcode
                                            margin={0}
                                            fontSize={28}
                                            value={orderPacking?.freight_bill}
                                            // format={
                                            //     PATTERN_CHECK_IS_CODE_39.test(orderPacking?.freight_bill)
                                            //         ? 'CODE39'
                                            //         : 'CODE128'
                                            // }
                                        />
                                    )}
                                </div>

                                <div className="p-3 text-center">
                                    <p className="mb-0 text-fz-18">{`${t('shipping_unit')}: ${
                                        orderPacking?.shipping_partner?.name
                                    }`}</p>
                                </div>

                                <div className="p-3">
                                    <Row gutter={[0, 16]}>
                                        <Col span={6}>
                                            <strong>{t('sender')}:</strong>
                                        </Col>
                                        <Col span={18}>
                                            <p className="mb-0">{orderPacking?.warehouse?.name}</p>
                                            {!!orderPacking?.warehouse?.address && (
                                                <p className="mb-0">{orderPacking?.warehouse?.address}</p>
                                            )}
                                        </Col>

                                        <Col span={6}>
                                            <strong>{t('receiver')}:</strong>
                                        </Col>
                                        <Col span={18}>
                                            <p className="mb-0">
                                                {[orderPacking?.order?.receiver_name, orderPacking?.order?.receiver_phone]
                                                    .filter(item => item)
                                                    .join(' - ')}
                                            </p>
                                            <p className="mb-0">{orderPacking?.order?.receiver_address}</p>
                                        </Col>

                                        <Col span={6}>
                                            <strong>{t('total_receipt_receiver')}:</strong>
                                        </Col>
                                        <Col span={18}>
                                            <p className="mb-0 text-fz-18">
                                                {currencyFormatter.toLocaleStringCurrency(orderPacking?.order?.cod)} VNĐ
                                            </p>
                                        </Col>

                                        <Col span={6}>
                                            <strong>{t('note_when_delivery')}:</strong>
                                        </Col>
                                        <Col span={18}>
                                            <p className="mb-0">{orderPacking?.order?.receiver_note}</p>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="p-3 text-center tile">
                                    <p className="mb-0 text-fz-18">{t('label.orders').toUpperCase()}</p>
                                </div>

                                <Row className="p-3">
                                    <Col span={24}>
                                        <p>
                                            {`${t('weight')}: ${
                                                parseFloat(sumBy(orderPacking?.order?.order_skus, 'weight')).toFixed(2) || 0
                                            } kg`}
                                            :{' '}
                                        </p>
                                    </Col>

                                    <Col span={24}>
                                        <p>{`${t('total_product')} : ${orderPacking?.total_quantity || 0}`}</p>
                                    </Col>
                                </Row>
                            </div>

                            <Table
                                className={'p-3 orders-table'}
                                columns={[
                                    {
                                        title: t('table.#'),
                                        render: (_, __, index) => index + 1,
                                    },
                                    {
                                        title: t('order:label.warehouse_area'),
                                        dataIndex: 'warehouse_area',
                                    },
                                    {
                                        title: t('order:label.product_code'),
                                        dataIndex: 'sku_code',
                                        render: text => <p className="text-fz-18">{text}</p>,
                                    },
                                    {
                                        title: t('sku:label.sku_name'),
                                        dataIndex: 'sku_name',
                                    },
                                    {
                                        title: t('quantity'),
                                        dataIndex: 'quantity',
                                    },
                                ]}
                                dataSource={orderPacking?.order_packing_items}
                                bordered
                                pagination={false}
                                rowKey={row => row?.id}
                            />
                        </div>
                    );
                })}
        </div>
    );
});

ShippingStamp.propTypes = {
    orderPackings: PropTypes.array,
};

ShippingStamp.defaultProps = {
    orderPackings: [],
};

export default ShippingStamp;
