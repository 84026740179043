import React from 'react';
import {Upload, message} from "antd";
import {InboxOutlined, LoadingOutlined} from "@ant-design/icons";
import {t, trans} from "../../../system/i18n";

const {Dragger} = Upload;

export default function UploadFile({maxFileSize, fileType, name, accept, onChange, loading, className}) {

    function checkFile(file) {
        let fileFize = file.size/1024/1024;
        if (maxFileSize && fileFize > maxFileSize) {
            message.error(trans("validation:max.file", {
                attribute: <strong>{(name || t('common:label.avatar'))}</strong>,
                max: <strong>{maxFileSize}</strong>
            }));
            return false;
        }

        if (fileType && fileType.indexOf(file.type) < 0) {
            message.error(trans("validation:mimetypes", {
                attribute: <strong>{(name || t('listProducts:label.products_excel'))}</strong>,
                values: <strong>{accept}</strong>
            }));
            return false;
        }

        return true;
    }

    function _onChange(info) {
        if (checkFile(info.file)) {
            onChange(info.file);
        }
    }

    return (
        <Dragger
            name="file"
            onChange={_onChange}
            beforeUpload={() => false}
            showUploadList={true}
            action={null}
            fileList={[]}
            className={className}
        >
            <p className="ant-upload-drag-icon mb-2">
                {loading ? <LoadingOutlined /> : <InboxOutlined />}
            </p>
            <p className="ant-upload-text">{t("label.click_or_drag_file")}</p>
        </Dragger>
    )
}
