import { Spin, Tabs } from 'antd';
import _, { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useProductQuery from '@Modules/Product/Hooks/useProductQuery';
import ProductHistory from '@Modules/Product/screens/OriginalProduct/History';
import ProductServiceInfo from '@Modules/Product/screens/OriginalProduct/ProductServiceInfo';
import { EVENTS } from '@Modules/Product/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';

import BasicProduct from './BasicProduct';
import SkuPrices from './SkuPrices';
import Loading from "@Modules/App/Loading";

const { TabPane } = Tabs;

export default function OriginalProduct({ match, history, location }) {
    const { params } = match;
    const [activeKey, setActiveKey] = useState('1');
    const [originalProduct, setOriginalProduct] = useState({});
    const { product, options, skus, merchant } = originalProduct;
    const { data: productData, isError, error, refetch, isLoading } = useProductQuery(params.id);

    const handleChangeTab = useCallback(activeKey => {
        setActiveKey(activeKey);
    }, []);

    useEffect(() => {
        setNavigator(t('common:title.product-detail'), [
            {
                name: t('common:breadcrumb.products'),
                route: 'products.list',
            },
            {
                name: t('common:breadcrumb.product-detail'),
            },
        ]);
         events.listen(EVENTS.UPDATE_PRODUCT_SUCCESS, () => {
             refetch()
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOriginalProduct(productData?.data || {});
        setDocumentTitle(t('title_page.product', { code: _.get(productData, 'data.product.code', '') }));
    }, [productData]);

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    if (isLoading) {
        return <Loading/>;
    }

    if (isEmpty(originalProduct)) {
        return null;
    }

    return (
        <div className="site-content bg-white order-detail _product-detail">
            <Tabs
                className="customize-ant-tabs _product-detail-tabs"
                defaultActiveKey={activeKey}
                onChange={handleChangeTab}
                type="card"
            >
                <TabPane tab={t('product:title.common_info')} key="1" className="_product-detail-tab-common">
                    <BasicProduct product={product} options={options} skus={skus} setOriginalProduct={setOriginalProduct} fetchData={refetch}/>
                </TabPane>

                <TabPane tab={t('product:label.product_price_list')} key="2" className="_product-detail-tab-price-list">
                    <SkuPrices productInfo={originalProduct} />
                </TabPane>
                <TabPane tab={t('label.service')} key="3" className="_product-detail-tab-service">
                    <ProductServiceInfo product={originalProduct} productId={params.id} merchant={merchant} activeKey={activeKey}/>
                </TabPane>

                <TabPane tab={t('label.history_change')} key="4" className="_product-detail-tab-history">
                    <ProductHistory productId={params.id} activeKey={activeKey} />
                </TabPane>
            </Tabs>
        </div>
    );
}
