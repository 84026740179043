import { useRef } from 'react';

// modified from https://ahooks.js.org/hooks/advanced/use-creation

export default function useCreation(factory, deps) {
    const { current } = useRef({
        deps,
        obj: undefined,
        initialized: false,
    });

    if (current.initialized === false || !depsAreSame(current.deps, deps)) {
        current.deps = deps;
        current.obj = factory();
        current.initialized = true;
    }
    return current.obj;
}

function depsAreSame(oldDeps, deps) {
    if (oldDeps === deps) return true;

    for (let i = 0; i < oldDeps.length; i++) {
        if (oldDeps[i] !== deps[i]) return false;
    }

    return true;
}
