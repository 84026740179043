import { t } from '@System/i18n';
import { Table, Typography } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const { Title } = Typography

const ChildSku = ({ detail, loading }) => {
    const [skus, setSkus] = useState([])

    useEffect(() => {
        setSkus(get(detail, 'skus.data', []))
    }, [detail])

    const columns = [
        {
            className: '_product-list-sku-code',
            title: t('product:label.sku_code'),
            dataIndex: ['sku', 'data'],
            key: 'sku_code',
            ellipsis: {
                showTitle: false,
            },
            width: '180px',
            render: sku => (
                <Link to={`/products/detail/${get(sku, 'product_id')}/${get(sku, 'id')}`}>
                    <span className="text-dark font-weight-500">{get(sku, 'code', '')}</span>
                </Link>
            ),
        },
        {
            className: '_product-list-product text-wrap',
            title: t('product:label.product'),
            dataIndex: ['sku', 'data'],
            key: 'product',
            width: '300px',
            render: text => {
                return <span className="text-dark">{get(text, 'name', '')}</span>
            },
        },
        {
            title: t('product:label.category_id'),
            dataIndex: ['sku', 'data', 'category', 'data', 'name'],
            className: '_product-info-quantity',
            key: 'quantity',
            width: '180px',
            render: text => {
                return <span>{text ? text : t('noText')}</span>
            },
        },
        {
            title: t('label.quantity'),
            dataIndex: 'quantity',
            className: '_product-info-quantity',
            key: 'quantity',
            width: '180px',
            render: text => {
                return <span>{text ? text : 0}</span>
            },
        },
        // {
        //     title: t("product:label.dropship"),
        //     dataIndex: ['sku', 'data', 'dropship', ],
        //     className: '_product-info-dropship',
        //     key: 'dropship',
        //     width: '180px',
        //     render: text => {
        //         return (
        //             <Checkbox
        //                 checked={text}
        //                 onChange={() => {}}
        //             />
        //         )
        //     },
        // },
    ]
    return (
        <>
            <Title
                className="mt-4"
                level={5}
            >
                {t('product:label.child_sku_list')}
            </Title>
            <Table
                className="_product-child-sku__list"
                rowKey={record => get(record, 'sku.data.id')}
                dataSource={skus}
                columns={columns}
                scroll={{ x: 576 }}
                loading={loading}
                pagination={false}
                locale={{
                    emptyText: <p>{t('message.no_data_product')}</p>,
                }}
            />
        </>
    )
}

export default ChildSku
