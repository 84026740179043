import React, {useState, useEffect} from 'react';
import {Form, Row, Col, Button, Input} from 'antd';
import {t} from "../../../../system/i18n";
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";
import SelectWarehouseArea from "../../../WarehouseArea/components/SelectWarehouseArea";
import SelectWarehouse from "../../../Warehouse/components/SelectWarehouse";
import SelectSku from "../../components/SelectSku";
import {warehouseAreaStorage, warehouseStorage} from "../../../App/services";

function FormSearch({onSearch, filters, initFilters}) {
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const {
        code,
        sku_id,
        warehouse_id,
        warehouse_area_id
    } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        if (name === "warehouse_id") {
            setInput({...input, [name]: value, warehouse_area_id: undefined})
        } else {
            setInput({...input, [name]: value});
        }
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }


    function _onSubmit() {
        onSearch(input);
    }

    function _onReset() {
        warehouseStorage.reset();
        warehouseAreaStorage.reset();
        onSearch(initFilters);
    }

    return (
        <Form
            className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray"
            form={form}
            layout="vertical"
        >
            <Row gutter={10}>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-3"
                        label={t("product:label.import_code")}
                    >
                        <Input
                            name="code"
                            value={code}
                            placeholder={t("product:placeholder.import_code")}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-3"
                        label={t("product:label.sku_name")}
                    >
                        <SelectSku
                            value={sku_id}
                            onChange={value => _onChange("sku_id", value)}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-0"
                        label={t("product:label.warehouse_name")}
                    >
                        <SelectWarehouse
                            allowClear={true}
                            placeholder={t("product:placeholder.warehouse_name")}
                            value={warehouse_id ? parseInt(warehouse_id) : undefined}
                            onChange={value => _onChange("warehouse_id", value)}
                            noSuggest={false}
                        />
                    </Form.Item>
                </Col>

                {
                    warehouse_id &&
                    <Col xs={{span: 12}} lg={{span: 6}}>
                        <Form.Item
                            className="mb-0"
                            label={t("product:label.warehouse_area_name")}
                        >
                            <SelectWarehouseArea
                                allowClear={true}
                                placeholder={t("product:placeholder.warehouse_area_name")}
                                warehouseid={warehouse_id ? parseInt(warehouse_id) : undefined}
                                value={warehouse_area_id ? parseInt(warehouse_area_id) : undefined}
                                onChange={value => _onChange("warehouse_area_id", value)}
                                noSuggest={false}
                            />
                        </Form.Item>
                    </Col>
                }


                <Col sm={{span: 12}} lg={{span: warehouse_id ? 24 : 6}} className="pt-4 text-right"
                     style={{marginTop: "6px"}}>
                    <Button type="primary" onClick={_onSubmit}><SearchOutlined/> {t("common:btn.search")}</Button>
                    <Button className="ml-2" onClick={_onReset}><UndoOutlined/> {t("common:btn.reset")}</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;
