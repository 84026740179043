import { ExclamationCircleOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons'
import { Button, Modal, Space, Tabs } from 'antd'
import React, { useEffect } from 'react'
import { useState } from 'react'

import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import SelectMarket from '@Modules/Location/components/SelectMarket'
import CreateServicePack from '@Modules/ServicesManager/components/ServicePack/CreateServicePack'
import api from '@Modules/ServicesManager/services/api'
import { SERVICE_TYPE } from '@Modules/ServicesManager/services/constants'

import { t } from '@System/i18n'
import notification from '@System/notification'

import ServiceBox from './ServiceBox'
import ServicePack from './ServicePack'

const { TabPane } = Tabs

const ServicesManager = () => {
    const [market, setMarket] = useState(11935)
    const [loading, setLoading] = useState(false)
    const [isVisible, setIsVisible] = useState(false)
    const [selectedKey, setSelectedKey] = useState('0')


    useEffect(() => {
        setNavigator(t('menu.list_services'), [
            {
                name: t('breadcrumb.list_services'),
            },
        ])
        setDocumentTitle(t('title.list_services'))
    }, [])

    const showConfirmUpdate = () => {
        Modal.confirm({
            title: t('message.update_all_service_product'),
            icon: <ExclamationCircleOutlined />,
            content: false,
            cancelText: t('btn.back'),
            okText: t('btn.ok'),
            closable: true,
            width: 420,
            okButtonProps: { className: 'btn-modal-re', loading },
            onOk: handleUpdateAll,
        })
    }

    const handleUpdateAll = () => {
        setLoading(true)
        api.updateAllServiceProduct({ country_id: market })
            .then(res => {
                notification.success(t('message.update_all_service_product_success'))
            })
            .catch(error => {
                notification.error(t('message.update_all_service_product_failed'))
            })
            .finally(() => setLoading(false))
    }

    const onChangeTab = key => {
        setSelectedKey(key)
    }

    const handleHideModal = () => {
        setIsVisible(false)
    }

    const handleShowModal = () => {
        setIsVisible(true)
    }

    return (
        <div className="site-content service-manager">
            <div className="bg-white rounded-12 p-4 mt-4">
                <h5 className="text-fz-16 mb-2">{t('label.market')}</h5>
                <div className="d-flex justify-content-between mb-4">
                    <SelectMarket
                        placeholder={t('merchant:placeholder.market')}
                        className="cursor-pointer"
                        onChange={value => setMarket(value)}
                        value={market ? parseInt(market) : undefined}
                        style={{ width: 200 }}
                    />
                    {auth.can(permissions.SERVICE_ADD) && selectedKey !== '0' && (
                        <Button
                            type="primary"
                            onClick={showConfirmUpdate}
                        >
                            <SyncOutlined />
                            {t('btn.update_service_all_product')}
                        </Button>
                    )}
                </div>
                <Tabs
                    className="heading-fz-14 mt-4 service-manager-tabs"
                    activeKey={selectedKey}
                    type="card"
                    onChange={onChangeTab}
                    tabBarExtraContent={
                        selectedKey === '0' && (
                            <Space>
                                {auth.can(permissions.SERVICE_ADD) && (
                                    <>
                                        <Button
                                            type="primary"
                                            onClick={handleShowModal}
                                            icon={<PlusOutlined />}
                                        >
                                            {t('btn.create_service_pack')}
                                        </Button>
                                    </>
                                )}
                            </Space>
                        )
                    }
                >
                    <TabPane
                        tab={t('label.service_package_management')}
                        key="0"
                    >
                        <ServicePack countryId={market && parseInt(market)} />
                    </TabPane>
                    <TabPane
                        tab={t('title.list_services')}
                        key="1"
                    >
                        {Object.keys(SERVICE_TYPE).map(
                            key =>
                                key !== 'EXTENT' && (
                                    <ServiceBox
                                        key={key}
                                        serviceType={key}
                                        countryId={market && parseInt(market)}
                                    />
                                )
                        )}
                    </TabPane>
                    <TabPane
                        tab={t('label.extended_service_manager')}
                        key="2"
                    >
                        <ServiceBox
                            key={SERVICE_TYPE.EXTENT}
                            serviceType={SERVICE_TYPE.EXTENT}
                            countryId={market && parseInt(market)}
                        />
                    </TabPane>
                </Tabs>
            </div>
            {isVisible && (
                <CreateServicePack
                    countryId={market && parseInt(market)}
                    handleHideModal={handleHideModal}
                />
            )}
        </div>
    )
}

export default ServicesManager
