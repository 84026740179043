import { notification } from 'antd';
import _ from 'lodash';

import { t } from '@System/i18n';

import BaseServiceData from '../../App/services/BaseServiceData';
import api from './api';

class ServiceShippingPartners extends BaseServiceData {
    runApi() {
        return api
            .getShippingPartners()
            .then(res => _.get(res, 'data.shippingPartners', []))
            .catch(err => {
                notification.error({
                    message: t('common:message.fetch_fail', { attribute: t('packaging:label.shipping_partner').toLowerCase() }),
                });
                return Promise.reject(err);
            });
    }
}

export default new ServiceShippingPartners();
