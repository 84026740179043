import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from './../services/api';

const useStoresQuery = (params, settings) =>
    createUseQuery({
        name: ['stores', params],
        query: () => apiService.list(params),
        settings: {
            enabled: false,
            ...settings
        },
    });

export default useStoresQuery;
