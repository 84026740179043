import { Col, Form, Input, InputNumber, Row } from 'antd'
import { find, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner'
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse'
import ServiceWarehouses from '@Modules/Warehouse/services/ServiceWarehouses'

import { t } from '@System/i18n'

import ProductList from './InfoProduct'

const InfoCreate = ({ form }) => {
    const [warehousesInfo, setWarehousesInfo] = useState([])
    const [destinationWarehouseId, setDestinationWarehouseId] = useState()
    
    useEffect(() => {
        ServiceWarehouses.list().then(res => {
            setWarehousesInfo(res)
        })
    }, [])

    function onChange(name, value) {
        form.setFields([{ name, value: value ? value : undefined, errors: '' }])
        if (name === 'destination_warehouse_id') {
            setDestinationWarehouseId(value)
        }
    }

    const receive_warehouse = find(warehousesInfo, ['id', destinationWarehouseId])

    return (
        <>
            <div className="bg-white rounded-12 p-4 mb-4">
                <div>
                    <h3 className='mb-3'>{t('label.info')}</h3>
                </div>
                <Form.Item
                    label={t('label.receive_warehouse')}
                    labelAlign={'left'}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 6 }}
                    rules={[
                        {
                            required: true,
                            message: t('order:message.required', { attribute: t('label.receive_warehouse') }),
                        },
                    ]}
                    name="destination_warehouse_id"
                >
                    <SelectWarehouse
                        className="_orders-search-warehouse-id"
                        // allowClear={true}
                        placeholder={t('placeholder.receive_warehouse')}
                        // value={warehouse_id ? parseInt(warehouse_id) : undefined}
                        onChange={value => onChange('destination_warehouse_id', value)}
                        // noSuggest={false}
                    />
                </Form.Item>

                <Form.Item
                    labelAlign={'left'}
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 6 }}
                    label={t('warehouse:label.receive_warehouse_address')}
                >
                    <p>{!isEmpty(receive_warehouse) ? receive_warehouse?.address : '---'}</p>
                </Form.Item>

                <Row>
                    <Col
                        sm={12}
                        xs={24}
                    >
                        <Form.Item
                            labelAlign={'left'}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            label={t('order:label.freight_bill')}
                            name="freight_bill_code"
                        >
                            <Input placeholder={t('packaging:placeholder.freight_bill')}  onChange={e => onChange('freight_bill_code', e.target.value)}/>
                        </Form.Item>
                    </Col>
                    <Col
                        sm={12}
                        xs={24}
                    >
                        <Form.Item
                            labelAlign={'left'}
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 12 }}
                            label={t('order:label.shipping_partner_id')}
                            name="shipping_partner_id"
                        >
                            <SelectShippingPartner
                                className={'_prepare-order-search-shipping-partner-id'}
                                placeholder={t('order:placeholder.shipping_partner_id')}
                                all
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col
                        sm={12}
                        xs={24}
                    >
                        <Form.Item
                            labelAlign={'left'}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 12 }}
                            label={t('sku:label.weight')}
                            name="weight"
                        >
                            <InputNumber
                                placeholder={t('sku:placeholder.weight')}
                                min={1}
                                className="w-100"
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        sm={12}
                        xs={24}
                    >
                        <Form.Item
                            labelAlign={'left'}
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 12 }}
                            label={t('note')}
                            name="note"
                        >
                            <Input.TextArea
                                placeholder={t('placeholder.note')}
                                rows={4}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default InfoCreate
