import { get } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import DynamicTable from '@Modules/App/DynamicTable'
import { dateFormatter } from '@Modules/App/services'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import useListSkuComboQuery from '@Modules/Product/Hooks/useListSkuComboQuery'

import { t } from '@System/i18n'
import { url } from '@System/routing'

const ComboList = ({ filters, onSearch }) => {
    const { data, isLoading } = useListSkuComboQuery(filters)
    const [pagination, setPagination] = useState({})
    const [combos, setCombos] = useState([])

    useEffect(() => {
        setPagination(get(data, 'data.pagination'))
        setCombos(get(data, 'data.data'))
    }, [data])

    let columns = [
        {
            className: '_product-list-category',
            title: t('product:label.category'),
            dataIndex: ['category', 'data', 'name'],
            key: 'category',
            render: text => {
                return <span>{text ? text : t('noText')}</span>
            },
        },
        {
            className: '_product-list-product-code',
            title: t('product:label.sku_code'),
            dataIndex: 'id',
            key: 'product_code',
            disabled: true,
            render: (id, record) => {
                const code = get(record, 'code')
                return (
                    <>
                        <strong style={{ fontWeight: '500' }}>
                            <Link to={url.to('products.combo-detail', { id })}>{code}</Link>
                        </strong>
                    </>
                )
            },
        },
        {
            className: '_product-list-product-name',
            title: t('product:label.product_name'),
            dataIndex: 'name',
            key: 'sku_combo_name',
        },
        {
            className: '_product-list-merchant',
            title: t('product:label.merchant_code1'),
            dataIndex: ['merchant', 'data'],
            key: 'merchant_id',
            render: text => {
                const name = get(text, 'name', '')
                const code = get(text, 'code', '')
                return <span>{code + (name ? ' - ' + name : '')}</span>
            },
        },
        {
            className: '_product-list-creator',
            title: t('product:label.creator'),
            dataIndex: ['creator', 'data', 'name'],
            key: 'creator',
        },
        {
            className: '_product-list-created-at',
            title: t('product:label.created_at'),
            dataIndex: 'created_at',
            key: 'sku_combo_date',
            render: text => dateFormatter.formatDate(text, 'DD/MM/YYYY HH:mm'),
        },
    ]

    return (
        <div className="m-3">
            {/* <Table
                className="list-combos-table pb-3"
                dataSource={combos}
                rowKey={record => record?.sku_combo?.id}
                columns={columns}
                pagination={false}
                scroll={{ x: 576 }}
                loading={isLoading}
                locale={{
                    emptyText: (
                        <TableEmpty
                            className="_product-list-empty"
                            loading={isLoading}
                        />
                    ),
                }}
            /> */}
            <DynamicTable
                initColumns={columns}
                dataSource={combos}
                rowKey={record => record?.id}
                className="list-combos-table pb-3"
                loading={isLoading}
                locale={{
                    emptyText: (
                        <TableEmpty
                            className="_product-list-empty"
                            loading={isLoading}
                        />
                    ),
                }}
            />
            <CustomizedPagination
                pagination={pagination}
                addFilter={val => onSearch('pagination', val)}
                filters={filters}
            />
        </div>
    )
}

export default ComboList
