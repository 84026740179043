import { filter, get, isObject, isEmpty, reduce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import ScanErrors from '@Modules/App/ScanErrors';
import withScanErrors from '@Modules/App/ScanErrors/withScanErrors';
import { scanBarcodeTypeStorage } from '@Modules/App/services';
import { getPageSizeScale } from '@Modules/App/services/helpers';
import TotalProducts from '@Modules/ScanOrder/components/TotalProducts';
import renderErrorScan from '@Modules/ScanOrder/components/customerError';
import { calculatorTotalSku } from '@Modules/ScanOrder/components/customerFunction';

import { getVar } from '@System/support/helpers';

import ListOfLading from './ListOfLading';

import styles from '../../../scan-order.module.scss';

function ListOrderInfo(props) {
    const {
        reloadData,
        error,
        loading,
        dataSource,
        loadingSubmit,
        statusAllow,
        handleOk,
        handleCancel,
        showResetButton,
        removeScanOrderInfo,
        errorKey,
        countryId,
    } = props;

    const barcodeType = scanBarcodeTypeStorage.value;
    const [currentData, setCurrentData] = useState([]);
    const [filterData, setFilterData] = useState(null);
    const [pagination, setPagination] = useState({
        current_page: 1,
        page: 1,
        per_page: getPageSizeScale(),
    });

    useEffect(() => {
        const total = dataSource.length;
        const pageSize = get(pagination, 'per_page');
        setPagination({
            ...pagination,
            total,
            page_total: Math.ceil(total / pageSize),
        });

        // eslint-disable-next-line
    }, [dataSource]);

    useEffect(() => {
        let total = dataSource.length;
        if (filterData !== null) total = filterData.length;
        const pageSize = get(pagination, 'per_page');
        setPagination({
            ...pagination,
            total,
            page_total: Math.ceil(total / pageSize),
        });

        // eslint-disable-next-line
    }, [filterData]);

    useEffect(() => {
        const page = get(pagination, 'page');
        const pageSize = get(pagination, 'per_page');
        const offset = (page - 1) * pageSize;
        if (filterData !== null) {
            setCurrentData(filterData.slice(offset, offset + pageSize));
        } else {
            setCurrentData(dataSource.slice(offset, offset + pageSize));
        }

        // eslint-disable-next-line
    }, [pagination]);

    function handleChangePage(data) {
        let total = get(pagination, 'total');
        let page = get(data, 'page', get(pagination, 'page'));
        let per_page = get(data, 'per_page', get(pagination, 'per_page'));
        const page_total = Math.ceil(total / per_page);
        setPagination({
            ...pagination,
            ...data,
            page_total,
            current_page: page,
        });
    }

    function handleSearchTable(filters) {
        if (!isEmpty(filters)) {
            const { code, quantity } = filters;
            let data = [...dataSource];
            if (code) {
                data = filter(data, item => {
                    const freight_bill_code = getVar(item, 'freight_bill_code', '');
                    const order_code = getVar(item, 'order_code', '');
                    return (
                        freight_bill_code.toString().toLowerCase().includes(code.toLowerCase()) ||
                        order_code.toString().toLowerCase().includes(code.toLowerCase())
                    );
                });
            }
            if (quantity && Number.parseInt(quantity)) {
                data = filter(data, item => {
                    const skus = getVar(item, 'skus', []);
                    const sum = reduce(
                        skus,
                        (sum, sku) => {
                            return sum + Number(getVar(sku, 'quantity', 0));
                        },
                        0
                    );
                    return sum === Number(quantity);
                });
            }
            setFilterData(data);
        } else {
            setFilterData(null);
        }
    }

    function renderSkuData() {
        const skus = [];
        currentData.map(item => {
            return skus.push(...item.skus);
        });
        return calculatorTotalSku(skus);
    }

    const renderScanErrors = useCallback(errors => {
        if (isObject(errors)) {
            return renderErrorScan(errors);
        }

        return errors;
    }, []);

    const ScanErrorsWithErrors = withScanErrors(ScanErrors, errorKey);

    return (
        <>
            <ScanErrorsWithErrors errorMessageRender={renderScanErrors} />
            <ListOfLading
                totalData={filterData !== null ? filterData.length : dataSource.length}
                className={styles.main}
                error={error}
                loading={loading}
                pagination={pagination}
                dataSource={currentData}
                barcodeType={barcodeType}
                statusAllow={statusAllow}
                loadingSubmit={loadingSubmit}
                handleOk={handleOk}
                handleCancel={handleCancel}
                showResetButton={showResetButton}
                handleChangePage={handleChangePage}
                handleSearchTable={handleSearchTable}
                removeScanOrderInfo={removeScanOrderInfo}
                reloadData={reloadData}
                countryId={countryId}
            />
            <TotalProducts className={styles.aside} data={renderSkuData()} />
        </>
    );
}

export default ListOrderInfo;
