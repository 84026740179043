import React, {useState} from 'react';
import {Button, Modal, Form, Input, Row, Col, DatePicker, notification} from "antd";
import SelectPaymentMethod from "../../../components/SelectPaymentMethod";
import {EVENTS, PAYMENT_METHOD} from "../../../services/constants";
import useValidatedMessages from "../../../../../system/hooks/useValidatedMessages";
import api from "../../../services/api";
import {events} from "../../../../../system/events";
import _, { get, isEmpty, values } from 'lodash';
import moment from 'moment';
import CurrencyInput from "../../../../App/Utilities/CurrencyInput";
import {t} from "../../../../../system/i18n";
import {CheckOutlined} from "@ant-design/icons";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ModalConfirm from '@Modules/App/Modal/ModalConfirm';

const VALIDATE_CONFIRM_PAYMENT = {
    payment_method: [{required: true}],
    payment_amount: [{required: true}],
    bank_name: [{required: true}],
    bank_account: [{required: true}],
    payment_time: [{required: true}]
};

const layout = {
    labelCol: {span: 24},
    wrapperCol: {span: 24},
};

const dateFormat = "DD/MM/YYYY";

function ConfirmPayment(props) {
    const {orderId, orderDetail} = props;
    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const validateMessages = useValidatedMessages();
    const [visibleConfirm, setVisibleConfirm] = useState(false);

    function handleOk() {
        form.validateFields().then(values => {
            setLoading(true)
            api.confirmPayment(orderId, values)
                .then(res => {
                    notification.success({ message: t('common:message.create_success', { attribute: t('common:label.payment_confirm') }) })
                    handleCancel()
                    events.dispatch(EVENTS.FETCH_ORDER_DETAIL_AGAIN, {})
                })
                .catch(err => {
                    let { response } = err
                    if (response.status === 400) {
                        notification.error({ message: t('common:message.create_failed', { attribute: t('common:label.payment_confirm') }) })
                        showErrors(response)
                    } else if (response.status === 403) notification.error({ message: t('common:message.403') })
                    else if (response.status === 404) notification.error({ message: t('common:message.404') })
                    else notification.error({ message: t('common:message.create_failed', { attribute: t('common:label.package') }) })
                })
                .finally(() =>{
                    setLoading(false)
                    setVisibleConfirm(false)
                })
        })
        
    }

    const onConfirmWarning = () => {
        form.validateFields().then(values => {
            const isConfirm = !isEmpty(get(orderDetail, 'orderFreightBills', []))
            if (isConfirm) {
                // Modal.confirm({
                //     title: t('order:message.warning_confirm_payment'),
                //     icon: <ExclamationCircleOutlined />,
                //     content: '',
                //     okText: t('btn.ok'),
                //     cancelText: t('btn.cancel'),
                //     okButtonProps: { loading: loading },
                //     onOk:()=>handleOk(values)
                // })
                
                setVisibleConfirm(true)
            } else {
                handleOk()
            }
        })
    }

    const onHideModalConfirm = ()=>{
        setVisibleConfirm(false)
    }


    function showErrors(response) {
        let errors = _.get(response, "data.data", {});
        let error_messages = [];
        Object.entries(errors).forEach(entry => {
            const [key, error] = entry;
            for (let prop in error) {
                if (error.hasOwnProperty(prop)) {
                    error_messages.push({
                        name: key,
                        errors: [t(`payment:message.${prop.toLowerCase()}`, {
                            attribute: t(`payment:label.${key}`),
                            name: t(`payment:label.money_customer_pay`)
                        })]
                    });
                }
            }

        });

        form.setFields(error_messages);
    }


    function handleCancel() {
        form.resetFields();
        setVisible(false);
    }

    function openModal() {
        setVisible(true);
    }


    return (
        <>
            <Button
                type="primary"
                onClick={openModal}
                className="_btn-confirm"
            ><CheckOutlined /> {t("common:btn.confirm")}</Button>
            {
                visible &&
                <Modal
                    width={"50%"}
                    title={t("payment:title.payment_confirm").toUpperCase()}
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <div key="confirm_payment" className="d-flex justify-content-end _modal-btn-payment_confirm">
                            <Button
                                className="text-uppercase"
                                type="primary"
                                style={{width: "100"}}
                                onClick={onConfirmWarning}
                                loading={loading}
                            >
                                {t("common:btn.confirm")}
                            </Button>
                        </div>
                    ]}
                    className="_modal-payment_confirm"
                >
                    <Form
                        form={form}
                        {...layout}
                        labelAlign="left"
                        validateMessages={validateMessages}
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={t("payment:label.payment_method")}
                                    name="payment_method"
                                    rules={VALIDATE_CONFIRM_PAYMENT.payment_method}
                                    className="_form-payment_method"
                                >
                                    <SelectPaymentMethod
                                        name="payment_method"
                                        placeholder={t("order:placeholder.method")}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t("payment:label.payment_amount")}
                                    name="payment_amount"
                                    rules={VALIDATE_CONFIRM_PAYMENT.payment_amount}
                                    className="_form-payment_amount"
                                >
                                    <CurrencyInput
                                        name="payment_amount"
                                        style={{width:"100%"}}
                                        className="text-right"
                                    />
                                </Form.Item>
                            </Col>

                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) => prevValues.payment_method !== currentValues.payment_method}
                                
                            >
                                {({getFieldValue}) => {
                                    return getFieldValue('payment_method') === PAYMENT_METHOD.METHOD_BANK_TRANSFER ? (
                                        <>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("payment:label.bank_name")}
                                                    name="bank_name"
                                                    rules={VALIDATE_CONFIRM_PAYMENT.bank_name}
                                                    className="_form-bank_name"
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={t("payment:label.bank_account")}
                                                    name="bank_account"
                                                    rules={VALIDATE_CONFIRM_PAYMENT.bank_account}
                                                    className="_form-bank_account"
                                                >
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </>
                                    ) : null;
                                }}
                            </Form.Item>

                            <Col span={12}>
                                <Form.Item
                                    label={t("payment:label.payment_date")}
                                    name="payment_time"
                                    rules={VALIDATE_CONFIRM_PAYMENT.payment_time}
                                    className="_form-payment_time"
                                >
                                    <DatePicker
                                        style={{width: "100%"}}
                                        format={dateFormat}
                                        disabledDate={current => current && current > moment().endOf('day')}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t("payment:label.payment_note")}
                                    name="payment_note"
                                    className="_form-payment_note"
                                >
                                    <Input.TextArea autoSize={{minRows: 1, maxRows: 3}}/>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>
                </Modal>
            }
            {
                visibleConfirm && <ModalConfirm
                    content={t('order:message.warning_confirm_payment')}
                    handleHideModal={onHideModalConfirm}
                    visible={visibleConfirm}
                    cancelButtonProps={{ className: '_modal-confirm-delete-exporting-document-btn-cancel' }}
                    okButtonProps={{
                        className: '_modal-confirm-delete-exporting-document-btn-ok',
                        loading: loading,
                        onClick:()=> handleOk(values),
                    }}
                />
            }
             
        </>
    );
}

export default ConfirmPayment;
