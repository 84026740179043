import React from "react";
import {Table} from "antd";
import CustomizedPagination from "../../../App/CustomizedPagination";
import {t} from "../../../../system/i18n";
import TableEmpty from "../../../App/Utilities/TableEmpty";
import UpdateFobizSku from "./UpdateFobizSku";
import DeleteFobizSku from "./DeleteFobizSku";

export default function List({setStoreSkus, storeSkus, pagination, addFilter, filters, loading, canUpdate}) {

    let getColumns = [
        {
            title: t("synchronousProduct:label.code"),
            dataIndex: "sku_code",
            key: 'code',
            width: 250
        },
        {
            title: t("synchronousProduct:label.fobiz_code"),
            dataIndex: "store_sku_code",
            key: 'store_sku_code',
            width: 250,
            render: (text, record) => <UpdateFobizSku storeSku={record} setStoreSkus={setStoreSkus} storeSkus={storeSkus} canUpdate={canUpdate} />
        },
        {
            title: "",
            dataIndex: "store_sku_id",
            key: "store_sku_id",
            render: (text, record) => {
                return <div className="text-right pr-3"><DeleteFobizSku storeSku={record} canUpdate={canUpdate} /></div>;
            },
        }
    ];

    return (
        <div className="m-3">
            <Table
                className="list-products-table pb-3"
                dataSource={storeSkus}
                rowKey={(record) => record.store_sku_id}
                columns={getColumns}
                pagination={false}
                loading={loading}
                locale={{emptyText: <TableEmpty loading={loading}/>}}
            />
            <CustomizedPagination
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
            />
        </div>
    );
}
