import { useQuery } from 'react-query'

import processResponseError from '@System/api/processResponseError'

import api from '../services/api'

const useServicePackListQuery = params => {
    return useQuery(['service-pack', params], () => api.getServicesPackList(params), {
        retry: false,
        onError: err => {
            processResponseError(err)
        },
    })
}

export default useServicePackListQuery
