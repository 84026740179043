import { setNavigator } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import React, { useEffect, useState } from 'react';
import { t } from '../../../system/i18n';
import PERMISSIONS from '../../Auth/services/permissions';



export default function Home() {
    const [currentPermission, setCurrentPermission] = useState();
    const user = auth.user();

    useEffect(() => {
        setCurrentPermission(user.permissions.includes(PERMISSIONS.OPERATION_VIEW_REPORT));
        setNavigator(t('title_page.home'),[]) 
       
    }, [user.permissions]);

    return (
        <div className="site-content" style={{ height: 'calc(100vh - 70px)' }}>
            {currentPermission ? (
                <iframe
                    width="100%"
                    height="100%"
                    title="report"
                    src="https://datastudio.google.com/embed/reporting/5ef48906-6d12-4224-a709-aa272acafa52/page/p_jqbefuozoc"
                ></iframe>
            ) : (
                <div className="bg-white p-5">
                    <h1 className="text-center"> {t('common:welcome')}</h1>
                </div>
            )}
        </div>
    );
}
