import { DownOutlined, SearchOutlined, UndoOutlined, UpOutlined } from '@ant-design/icons'
import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { get, split, isArray } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import useSize from '@Modules/App/Hooks/useSize'
import { auth } from '@Modules/Auth/services'
import PERMISSIONS from '@Modules/Auth/services/permissions'
import SelectMarket from '@Modules/Location/components/SelectMarket'
import SelectMerchantsAssigned from '@Modules/Order/components/SelectMerchantsAssigned'
import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner'

import { t } from '@System/i18n'

import { warehouseAreaStorage, warehouseStorage } from '../../../App/services'
import SelectWarehouseItems from '../../../Warehouse/components/SelectWarehouseItems'
import SelectWarehouseArea from '../../../WarehouseArea/components/SelectWarehouseArea'
import SelectStatuses from '../../components/SelectStatuses'
import { MARKET_PLACE, PAYMENT_METHOD, PAYMENT_TYPE } from '../../services/constants'

const { RangePicker } = DatePicker
const dateFormat = 'DD/MM/YYYY'
const { Option } = Select

function FormSearch({ onSearch, filters, initFilters, setting }) {
    const { isMobile, isTablet } = useSize()

    const { xs } = useBreakpoint()

    const [form] = Form.useForm()
    const [input, setInput] = useState(filters)
    const [showOnMobile, setShowOnMobile] = useState(false)
    const {
        code,
        receiver_name,
        receiver_phone,
        list_status,
        merchant_ids,
        warehouse_id,
        warehouse_area_id,
        shipping_partner_id,
        no_for_control,
        payment_type,
        marketplace_code,
        payment_method,
        location_id,
        freight_bill,
        sku_code,
    } = input || {}

    const intended_delivery_at_from = get(input, 'intended_delivery_at[from]', '')
    const intended_delivery_at_to = get(input, 'intended_delivery_at[to]', '')

    const created_at_from = get(input, 'created_at[from]', '')
    const created_at_to = get(input, 'created_at[to]', '')

    const packed_at_from = get(input, 'packed_at[from]', '')
    const packed_at_to = get(input, 'packed_at[to]', '')

    const exportingWarehouseAtFrom = get(input, 'exporting_warehouse_at[from]', '')
    const exportingWarehouseAtTo = get(input, 'exporting_warehouse_at[to]', '')

    useEffect(() => {
        setInput(filters)
    }, [filters])

    function _onChange(name, value) {
        let inputTemp

        switch (name) {
            case 'warehouse_id':
                inputTemp = { ...input, [name]: value, warehouse_area_id: undefined }
                break
            case 'sku_code':
                const value_array = value ? split(value, ' ') : undefined
                inputTemp = { ...input, [name]: value, sku_code: value_array }
                break
            case 'payment_type':
                inputTemp = { ...input, [name]: value, payment_method: undefined }
                break
            default:
                inputTemp = { ...input, [name]: value }
                break
        }

        // onSearch(inputTemp);
        setInput(inputTemp)
    }

    function _onChangeInput(e) {
        const name = e.target.name
        const value = e.target.value
        _onChange(name, value)
    }

    function _onChangeDate(name, dates) {
        const name_from = `${name}[from]`
        const name_to = `${name}[to]`
        const newInput = {
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        }
        setInput(newInput)
        onSearch(newInput)
    }

    function _onSubmit() {
        onSearch(input)
    }

    function _onReset() {
        warehouseStorage.reset()
        warehouseAreaStorage.reset()
        onSearch(initFilters)
    }

    function _onToggleMobile() {
        setShowOnMobile(!showOnMobile)
    }

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
            >
                <div className="bg-white rounded-12 p-4">
                    <div className="search-box">
                        <Row gutter={20}>
                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 4 }}
                            >
                                <Form.Item
                                    className="mb-3"
                                    label={t('order:label.order_code')}
                                >
                                    <Input
                                        className="_orders-search-order-code"
                                        name="code"
                                        value={code}
                                        placeholder={t('order:placeholder.order_code')}
                                        onChange={_onChangeInput}
                                        onPressEnter={_onSubmit}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 8 }}
                            >
                                <Form.Item
                                    className={isMobile || isTablet ? 'mb-3' : 'mb-0'}
                                    label={t('order:label.created_at')}
                                >
                                    <RangePicker
                                        className="_orders-search-created-at"
                                        value={created_at_from && created_at_to ? [moment(created_at_from), moment(created_at_to)] : undefined}
                                        format={dateFormat}
                                        placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                        onChange={date => _onChangeDate('created_at', date)}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 4 }}
                            >
                                <Form.Item
                                    className="mb-3"
                                    label={t('label.order_status')}
                                >
                                    <SelectStatuses
                                        className="_orders-search-status"
                                        placeholder={t('order:placeholder.status')}
                                        allowClear={true}
                                        value={list_status ? list_status : []}
                                        onChange={value => _onChange('list_status', value)}
                                    />
                                </Form.Item>
                            </Col>
                            {!setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.ORDER_VIEW_LIST_ORIGINAL)) ? (
                                <>
                                    <Col
                                        xs={{ span: 12 }}
                                        lg={{ span: 4 }}
                                    >
                                        <Form.Item
                                            className="mb-3"
                                            label={t('order:label.receiver_phone')}
                                        >
                                            <Input
                                                className="_orders-search-receiver-phone"
                                                name="receiver_phone"
                                                value={receiver_phone}
                                                placeholder={t('order:placeholder.receiver_phone')}
                                                onChange={_onChangeInput}
                                                onPressEnter={_onSubmit}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={{ span: 12 }}
                                        lg={{ span: 4 }}
                                    >
                                        <Form.Item
                                            className="mb-3"
                                            label={t('order:label.seller_info')}
                                        >
                                            <SelectMerchantsAssigned
                                                className="_orders-search-merchant-id"
                                                placeholder={t('order:placeholder.seller_info')}
                                                allowClear={true}
                                                value={merchant_ids ? merchant_ids : []}
                                                onChange={value => _onChange('merchant_ids', value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col
                                        xs={{ span: 12 }}
                                        lg={{ span: 4 }}
                                    >
                                        <Form.Item
                                            className="mb-3"
                                            label={t('order:label.sku_code')}
                                        >
                                            <Input
                                                className="_orders-search-sku-code"
                                                name="sku_code"
                                                value={isArray(sku_code) ? sku_code.join(' ') : undefined}
                                                placeholder={t('order:placeholder.sku_code')}
                                                onChange={_onChangeInput}
                                                onPressEnter={_onSubmit}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        xs={{ span: 12 }}
                                        lg={{ span: 4 }}
                                    >
                                        <Form.Item
                                            label={t('order:label.payment_type')}
                                            className="mb-3"
                                        >
                                            <Select
                                                className="_orders-search-payment-type"
                                                placeholder={t('order:placeholder.payment_type')}
                                                onChange={value => _onChange('payment_type', value)}
                                                value={payment_type}
                                                optionFilterProp="children"
                                                allowClear={true}
                                            >
                                                {Object.keys(PAYMENT_TYPE).map((opt, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            value={PAYMENT_TYPE[opt]}
                                                        >
                                                            {t(`order:payment_type.${PAYMENT_TYPE[opt]}`)}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>
                        {showOnMobile && (
                            <Row gutter={20}>
                                {(!setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.ORDER_VIEW_LIST_ORIGINAL))) && (
                                    <>
                                        <Col
                                            xs={{ span: 12 }}
                                            lg={{ span: 4 }}
                                        >
                                            <Form.Item
                                                className="mb-3"
                                                label={t('order:label.sku_code')}
                                            >
                                                <Input
                                                    className="_orders-search-sku-code"
                                                    name="sku_code"
                                                    value={isArray(sku_code) ? sku_code.join(' ') : undefined}
                                                    placeholder={t('order:placeholder.sku_code')}
                                                    onChange={_onChangeInput}
                                                    onPressEnter={_onSubmit}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={{ span: 12 }}
                                            lg={{ span: 5 }}
                                        >
                                            <Form.Item
                                                className="mb-3"
                                                label={t('order:label.receiver_name')}
                                            >
                                                <Input
                                                    className="_orders-search-receiver-name"
                                                    name="receiver_name"
                                                    value={receiver_name}
                                                    placeholder={t('order:placeholder.receiver_name')}
                                                    onChange={_onChangeInput}
                                                    onPressEnter={_onSubmit}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col
                                            xs={{ span: 12 }}
                                            lg={{ span: 5 }}
                                        >
                                            <Form.Item
                                                label={t('order:label.payment_type')}
                                                className="mb-3"
                                            >
                                                <Select
                                                    className="_orders-search-payment-type"
                                                    placeholder={t('order:placeholder.payment_type')}
                                                    onChange={value => _onChange('payment_type', value)}
                                                    value={payment_type}
                                                    optionFilterProp="children"
                                                    allowClear={true}
                                                >
                                                    {Object.keys(PAYMENT_TYPE).map((opt, index) => {
                                                        return (
                                                            <Option
                                                                key={index}
                                                                value={PAYMENT_TYPE[opt]}
                                                            >
                                                                {t(`order:payment_type.${PAYMENT_TYPE[opt]}`)}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </>
                                )}
                                {payment_type === PAYMENT_TYPE.PAYMENT_TYPE_ADVANCE_PAYMENT && (
                                    <Col
                                        xs={{ span: 12 }}
                                        lg={{ span: 4 }}
                                    >
                                        <Form.Item
                                            className="mb-3"
                                            label={t('order:label.payment_method')}
                                        >
                                            <Select
                                                className="_orders-search-payment-method"
                                                allowClear={true}
                                                placeholder={t('order:placeholder.payment_method')}
                                                onChange={value => _onChange('payment_method', value)}
                                                value={payment_method}
                                                optionFilterProp="children"
                                            >
                                                {Object.keys(PAYMENT_METHOD).map((opt, index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            value={PAYMENT_METHOD[opt]}
                                                        >
                                                            {t(`order:payment_method.${PAYMENT_METHOD[opt]}`)}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                                <Col
                                    xs={{ span: 24 }}
                                    lg={{ span: 6 }}
                                >
                                    <Form.Item
                                        className={isMobile || isTablet ? 'mb-3' : 'mb-0'}
                                        label={t('order:label.intended_delivery_at')}
                                    >
                                        <RangePicker
                                            className="_orders-search-intended-delivery-at"
                                            value={
                                                intended_delivery_at_from && intended_delivery_at_to
                                                    ? [moment(intended_delivery_at_from), moment(intended_delivery_at_to)]
                                                    : undefined
                                            }
                                            format={dateFormat}
                                            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                            onChange={date => _onChangeDate('intended_delivery_at', date)}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 12 }}
                                    lg={{ span: 4 }}
                                >
                                    <Form.Item
                                        className={isMobile || isTablet ? 'mb-3' : 'mb-0'}
                                        label={t('merchant:label.market')}
                                    >
                                        <SelectMarket
                                            placeholder={t('merchant:placeholder.market')}
                                            onChange={value => _onChange('location_id', value)}
                                            value={location_id ? parseInt(location_id) : undefined}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 12 }}
                                    lg={{ span: 4 }}
                                >
                                    <Form.Item
                                        className="mb-3"
                                        label={t('label.freight_bill_code')}
                                    >
                                        <Input
                                            className={'_prepare-order-search-freight-bill'}
                                            name="freight_bill"
                                            value={freight_bill}
                                            placeholder={t('placeholder.freight_bill_code')}
                                            onChange={_onChangeInput}
                                            onPressEnter={_onSubmit}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 12 }}
                                    lg={{ span: 5 }}
                                    xl={{ span: 5 }}
                                >
                                    <Form.Item
                                        className="mb-2"
                                        label={t('label.select_warehouse_global')}
                                    >
                                        <SelectWarehouseItems
                                            className="_orders-search-warehouse-id"
                                            allowClear={true}
                                            placeholder={t('product:placeholder.warehouse_name')}
                                            value={warehouse_id ? parseInt(warehouse_id) : undefined}
                                            onChange={value => _onChange('warehouse_id', value)}
                                            noSuggest={false}
                                        />
                                    </Form.Item>
                                </Col>
                                {warehouse_id && (
                                    <Col
                                        xs={{ span: 12 }}
                                        lg={{ span: 5 }}
                                        xl={{ span: 5 }}
                                    >
                                        <Form.Item
                                            className="mb-2"
                                            label={t('label.warehouse_area')}
                                        >
                                            <SelectWarehouseArea
                                                className="_orders-search-warehouse-area-id"
                                                allowClear={true}
                                                placeholder={t('product:placeholder.warehouse_area_name')}
                                                warehouseid={warehouse_id ? parseInt(warehouse_id) : undefined}
                                                value={warehouse_area_id ? parseInt(warehouse_area_id) : undefined}
                                                onChange={value => _onChange('warehouse_area_id', value)}
                                                noSuggest={false}
                                            />
                                        </Form.Item>
                                    </Col>
                                )}
                                <Col
                                    xs={{ span: 24 }}
                                    lg={{ span: 6 }}
                                >
                                    <Form.Item
                                        className={isMobile || isTablet ? 'mb-3' : 'mb-0'}
                                        label={t('packaging:label.packaging_date')}
                                    >
                                        <RangePicker
                                            className="_orders-search-packed_at"
                                            value={packed_at_from && packed_at_to ? [moment(packed_at_from), moment(packed_at_to)] : undefined}
                                            format={dateFormat}
                                            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                            onChange={date => _onChangeDate('packed_at', date)}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                                {(!setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.ORDER_VIEW_LIST_ORIGINAL))) && (
                                    <Col
                                        xs={{ span: 12 }}
                                        lg={{ span: 5 }}
                                    >
                                        <Form.Item
                                            label={t('order:label.order_source')}
                                            className="mb-3"
                                        >
                                            <Select
                                                className="_orders-search-marketplace_code"
                                                placeholder={t('order:placeholder.order_source')}
                                                onChange={value => _onChange('marketplace_code', value)}
                                                value={marketplace_code}
                                                optionFilterProp="children"
                                                allowClear={true}
                                            >
                                                {Object.entries(MARKET_PLACE).map(([key, val], index) => {
                                                    return (
                                                        <Option
                                                            key={index}
                                                            value={val}
                                                        >
                                                            {t(`order:MARKET_PLACE.${key}`)}
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                )}
                                <Col
                                    xs={{ span: 12 }}
                                    lg={{ span: 4 }}
                                >
                                    <Form.Item
                                        className="mb-3"
                                        label={t('order:label.shipping_partner_id')}
                                    >
                                        <SelectShippingPartner
                                            className={'_prepare-order-search-shipping-partner-id'}
                                            placeholder={t('order:placeholder.shipping_partner_id')}
                                            allowClear={true}
                                            value={shipping_partner_id ? parseInt(shipping_partner_id) : undefined}
                                            onChange={value => _onChange('shipping_partner_id', value)}
                                            all
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 12 }}
                                    lg={{ span: 5 }}
                                >
                                    <Form.Item
                                        className="mb-3"
                                        label={t('label.finance_control_delivery')}
                                    >
                                        <Select
                                            className={'_order-list-no-for-control'}
                                            placeholder={t('placeholder.finance_control_delivery')}
                                            allowClear={true}
                                            value={no_for_control}
                                            onChange={value => _onChange('no_for_control', value)}
                                        >
                                            <Option value="1">{t('document:control_delivery_status.UN_COMPLETE')}</Option>
                                            <Option value="0">{t('document:control_delivery_status.COMPLETED')}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col
                                    xs={{ span: 24 }}
                                    lg={{ span: 6 }}
                                >
                                    <Form.Item
                                        className={isMobile || isTablet ? 'mb-3' : 'mb-0'}
                                        label={t('packaging:label.stock_out_date')}
                                    >
                                        <RangePicker
                                            className="_orders-search-export-warehouse-at"
                                            value={
                                                exportingWarehouseAtFrom && exportingWarehouseAtTo
                                                    ? [moment(exportingWarehouseAtFrom), moment(exportingWarehouseAtTo)]
                                                    : undefined
                                            }
                                            format={dateFormat}
                                            placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                            onChange={date => _onChangeDate('exporting_warehouse_at', date)}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}

                        <Row align="center">
                            {!xs && (
                                <Col
                                    span={12}
                                    className="align-items-center d-flex"
                                >
                                    <p
                                        className="text-fz-13 text-primary cursor-pointer"
                                        onClick={_onToggleMobile}
                                    >
                                        {showOnMobile ? (
                                            <>
                                                {t('order:btn.collapse_search')} <UpOutlined />
                                            </>
                                        ) : (
                                            <>
                                                {t('order:btn.expand_search')} <DownOutlined />
                                            </>
                                        )}
                                    </p>
                                </Col>
                            )}
                            <Col
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                className="align-items-center d-flex justify-content-end"
                            >
                                <Form.Item className="mb-0 text-right">
                                    <Button
                                        className="mr-2 _orders-search-btn-reset"
                                        onClick={_onReset}
                                    >
                                        <UndoOutlined />
                                        {t('common:btn.reset')}
                                    </Button>

                                    <Button
                                        type="primary"
                                        onClick={_onSubmit}
                                        className="_orders-search-btn-search"
                                    >
                                        <SearchOutlined /> {t('common:btn.search')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                        {xs && (
                            <p
                                className="text-fz-13 text-center text-primary cursor-pointer mt-3"
                                onClick={_onToggleMobile}
                            >
                                {showOnMobile ? (
                                    <>
                                        {t('order:btn.collapse_search')} <UpOutlined />
                                    </>
                                ) : (
                                    <>
                                        {t('order:btn.expand_search')} <DownOutlined />
                                    </>
                                )}
                            </p>
                        )}
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default FormSearch
