import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Avatar, Badge, Button, Form, Input, Modal } from 'antd';
import isFunction from 'lodash/isFunction';
import React, { useEffect } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';

import { t } from '@System/i18n';

import useUpdatePurchasingAccountMutation from '../Hooks/useUpdatePurchasingAccountMutation';
import { STATUSES } from '../services/constants';

const validatedRules = {
    password: [
        {
            max: 255,
            message: t('validation:max.string', {
                attribute: t('password'),
                max: 255,
            }),
        },
    ],
};

const EditPurchasingAccountModal = ({ purchasingAccount, visible, onCancel, onOk }) => {
    const [form] = Form.useForm();
    const { isLoading: updating, mutate: updatePurchasingAccount } = useUpdatePurchasingAccountMutation({
        onSuccess: () => {
            if (isFunction(onOk)) {
                onOk();
            }
        },
    });

    const handleOk = usePersistFn(() => {
        form.submit();
    });

    const handleFinish = usePersistFn(values => {
        updatePurchasingAccount({
            id: purchasingAccount?.id,
            ...values,
        });
    });

    useEffect(() => {
        form.setFieldsValue({
            pin_code: purchasingAccount?.pin_code,
            password: purchasingAccount?.password,
        });

        // eslint-disable-next-line
    }, [purchasingAccount]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();
        }

        // eslint-disable-next-line
    }, [visible]);

    return (
        <Modal
            className="_edit-purchasing-account-modal"
            footer={
                <>
                    <Button className="_cancel-btn" onClick={onCancel}>
                        {t('btn.close')}
                    </Button>
                    <Button className="_ok-btn" type="primary" loading={updating} onClick={handleOk}>
                        {t('btn.save')}
                    </Button>
                </>
            }
            title={t('account_info')}
            visible={visible}
            onCancel={onCancel}
        >
            <div className="d-flex align-items-center mb-3">
                <Avatar className="mr-2" size={36} />
                <div className="d-flex flex-column">
                    {purchasingAccount?.username}
                    <Badge
                        color={STATUSES[purchasingAccount?.status?.toUpperCase()]?.color}
                        text={t(`purchasingAccount:statuses.${purchasingAccount?.status}`)}
                    />
                </div>
            </div>

            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item label={t('password')} name="password" rules={validatedRules.password}>
                    <Input.Password
                        autoComplete="new-password"
                        className="_password"
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        placeholder={t('input_password')}
                    />
                </Form.Item>

                <Form.Item label={t('pin')} name="pin_code">
                    <Input.Password className="_pin" placeholder={t('input_pin')} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditPurchasingAccountModal;
