import React from "react";
import {FrownOutlined, SmileOutlined} from "@ant-design/icons";
import {t} from "../../../system/i18n";

export default function TableEmpty({loading, className}) {
    return (
        <>
            <p style={{fontSize: "24px"}}>{ loading ? <SmileOutlined /> : <FrownOutlined /> }</p>
            <p className={className}>{t(`message.${loading ? "loading" : "no_data"}`)}</p>
        </>
    );
}
