import React, {useEffect, useState} from "react";
import {Form, Select, Input} from "antd";
import {t} from "../../../../../system/i18n";
import ServiceLocation from "../../../../Location/services/ServiceLocation";
import {VIETNAME_CODE} from "../../../../Location/services/constants";
import _ from "lodash";

const {Option} = Select;
const ProvincesServ = new ServiceLocation();
const DistrictServ = new ServiceLocation();
const WardServ = new ServiceLocation();

export default function Location({validatedRules, form, currentSeller}) {
    let [provinces, setProvinces] = useState([]);
    let [loadingProvince, setLoadingProvince] = useState(false);

    let [districts, setDistricts] = useState([]);
    let [loadingDistrict, setLoadingDistrict] = useState(false);

    let [wards, setWards] = useState([]);
    let [loadingWard, setLoadingWard] = useState(false);

    const COUNTRY_CODE = _.get(currentSeller, "location.code", "");
    const [provinceId, setProvinceId] = useState(null);
    const [districtId, setDistrictId] = useState(null);

    useEffect(() => {
        if (currentSeller) {
            resetLocation();
            fetchProvince()
        }
    }, [currentSeller]);

    /**
     * Tải quận huyện nếu tỉnh thành thay đổi
     */
    useEffect(() => {
        if (provinceId)
            fetchDistrict();
        else
            setDistricts([]);
    }, [provinceId]);

    /**
     * Tải lại phường xã nếu quận huyện thay đổi
     */
    useEffect(() => {
        if (districtId)
            fetchWard();
        else
            setWards([]);
    }, [districtId]);


    function fetchProvince() {
        let {location} = currentSeller;
        setLoadingProvince(true);
        ProvincesServ.list("PROVINCE", location.code)
            .then(res => {
                setProvinces(res);
            })
            .finally(() => {
                setLoadingProvince(false);
            })
    }

    function fetchDistrict() {
        setLoadingDistrict(true);
        let province = provinces.find(item => item.id === provinceId);
        DistrictServ.list("DISTRICT", province.code)
            .then(res => {
                setDistricts(res);
            })
            .finally(() => {
                setLoadingDistrict(false);
            })
    }

    function fetchWard() {
        setLoadingWard(true);
        let district = districts.find(item => item.id === districtId);
        WardServ.list("WARD", district.code)
            .then(res => {
                setWards(res);
            })
            .finally(() => {
                setLoadingWard(false);
            })
    }

    function resetLocation() {
        setProvinceId(null);
        setDistrictId(null);
        form.setFields([
            {name: "receiver_province_id", value: null},
            {name: "receiver_district_id", value: null},
            {name: "receiver_ward_id", value: null}
        ]);
    }

    function selectProvince(value) {
        setProvinceId(value);
        setDistrictId(null);
        form.setFields([
            {name: "receiver_district_id", value: null},
            {name: "receiver_ward_id", value: null}
        ]);
    }

    function selectDistrict(value) {
        setDistrictId(value);
        form.setFields([{name: "receiver_ward_id", value: null}]);
    }

    return (
        <>
            <Form.Item
              className="mb-3 receiver_postal_code"
              name="receiver_postal_code"
              label={t(`label.postcode`)}
            >
                <Input placeholder={t("placeholder.postcode")}/>
            </Form.Item>

            <Form.Item
                className="mb-3 receiver_province_id"
                name="receiver_province_id"
                label={t(`warehouse:label.${COUNTRY_CODE === VIETNAME_CODE ? "province_id" : "other_province_id"}`)}
                rules={validatedRules["receiver_province_id"]}
            >
                <Select
                    loading={loadingProvince}
                    name="receiver_province_id"
                    placeholder={t(`warehouse:placeholder.${COUNTRY_CODE === VIETNAME_CODE ? "province_id" : "other_province_id"}`)}
                    onChange={selectProvince}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        provinces.length > 0 &&
                        provinces.map(opt => {
                            return <Option key={opt.id} value={opt.id}>{opt.label}</Option>;
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item
                className="mb-3 receiver_district_id"
                name="receiver_district_id"
                label={t(`warehouse:label.${COUNTRY_CODE === VIETNAME_CODE ? "district_id" : "other_district_id"}`)}
                rules={validatedRules["receiver_district_id"]}
            >
                <Select
                    loading={loadingDistrict}
                    name="receiver_district_id"
                    placeholder={t(`warehouse:placeholder.${COUNTRY_CODE === VIETNAME_CODE ? "district_id" : "other_district_id"}`)}
                    onChange={selectDistrict}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        districts.length > 0 &&
                        districts.map(opt => {
                            return <Option key={opt.id} value={opt.id}>{opt.label}</Option>;
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item
                className="mb-3 receiver_ward_id"
                name="receiver_ward_id"
                label={t(`warehouse:label.${COUNTRY_CODE === VIETNAME_CODE ? "ward_id" : "other_ward_id"}`)}
                rules={validatedRules["receiver_ward_id"]}
            >
                <Select
                    loading={loadingWard}
                    name="receiver_ward_id"
                    placeholder={t(`warehouse:placeholder.${COUNTRY_CODE === VIETNAME_CODE ? "ward_id" : "other_ward_id"}`)}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        wards.length > 0 &&
                        wards.map(opt => {
                            return <Option key={opt.id} value={opt.id}>{opt.label}</Option>;
                        })
                    }
                </Select>
            </Form.Item>
        </>
    );
}
