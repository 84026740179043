import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal, Select, Spin, Table } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { get, isEmpty, map, omit, uniqBy } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'

import { t, trans } from '@System/i18n'
import { classes } from '@System/support/helpers'

import SelectMerchantSuggest from './SelectMerchantSuggest'

interface ISellerServiceListProps {
    countryId: number
    sellers: any
    loading: boolean
    sellersAdded: any
    setSellerAdded: (value: any) => void
    servicePackId: number
}

const SellerServiceList: React.FC<ISellerServiceListProps> = ({ countryId, sellers, setSellerAdded, loading, sellersAdded, servicePackId }) => {
    const { xs } = useBreakpoint()

    const columns = [
        {
            className: ` _manage-service-column-service_seller-name`,
            title: t('label.merchant'),
            dataIndex: ['merchant', 'name'],
            key: 'code',
            render: (text, record) => {
                const code = get(record, 'merchant.code')
                return (
                    <span>
                        {code} - {text}
                    </span>
                )
            },
        },
        {
            className: `_manage-service-column-extra_day`,
            title: t('label.extra_day'),
            dataIndex: ['merchant', 'service_pack_added_at'],
            key: 'service_pack_added_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : t('noText')}</p>
            },
        },
    ]

    const onCheckSellerExisting = (value: any, option: any) => {
        if (get(option, 'data.service_pack.id') !== servicePackId && !isEmpty(get(option, 'data.service_pack'))) {
            const sellerAddedList = map(sellersAdded, 'merchant.id')
            if (sellerAddedList.includes(value)) {
                return
            }
            confirm(value, option)
        } else {
            onChangeSeller(value, option)
        }
    }

    const onChangeSeller = (value: any, option: any) => {
        if (!option) {
            return
        }

        const sellerList = map(sellers, 'merchant.id')

        if (sellerList.includes(value)) {
            return
        }
        const sellerAddedList = [...sellersAdded]
        sellerAddedList.push({ merchant: omit(get(option, 'data'), 'service_pack_added_at') })

        setSellerAdded(uniqBy(sellerAddedList, 'merchant.id'))
    }

    const confirm = (value: any, option: any) => {
        Modal.confirm({
            title: trans('serviceManager:message.seller_existing_another_service', {
                seller: <strong>{get(option, 'data.code')}</strong>,
                servicePack: <strong>{get(option, 'data.service_pack.code')}</strong>,
            }),
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: () => onChangeSeller(value, option),
        })
    }

    return (
        <>
            {auth.can(permissions.SERVICE_ADD) && (
                <Spin spinning={loading}>
                    <div className="mb-4 mt-4 d-flex align-items-center">
                        <span className={classes('mr-4', { 'text-nowrap': !xs })}><b>{t('merchant:label.add_seller')}:</b></span>
                        <SelectMerchantSuggest
                            className="_search-merchant w-100"
                            onChange={onCheckSellerExisting}
                            placeholder={t('merchant:label.search_seller')}
                            loading={loading}
                            value={null}
                            countryId={countryId}
                            sellers={uniqBy([...sellers, ...sellersAdded], 'merchant.id')}
                        />
                    </div>
                </Spin>
            )}
            <Table
                rowKey={(record: any) => record?.merchant?.id}
                rowClassName={'service-pack-list-seller'}
                dataSource={uniqBy([...sellers, ...sellersAdded], 'merchant.id')}
                scroll={{ x: 568 }}
                loading={loading}
                columns={columns}
                pagination={false}
                locale={{
                    emptyText: (
                        <TableEmpty
                            className="_product-list-empty"
                            loading={loading}
                        />
                    ),
                }}
            />
        </>
    )
}

export default SellerServiceList
