import { CloudUploadOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons'
import { Button, Input, Table } from 'antd'
import _, { get } from 'lodash'
import React from 'react'

import useSize from '@Modules/App/Hooks/useSize'
import useTableSelection from '@Modules/App/Hooks/useTableSelection'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { warehouseStorage } from '@Modules/App/services'
import { DOCUMENT_IMPORTING_SCAN_TYPE } from '@Modules/Document/services/constants'
import PrintSkusBarcode from '@Modules/Product/components/PrintSkusBarcode'

import { t } from '@System/i18n'

import SelectSerial from '../components/SelectSerial'

export default function VariantList({ storeActions, storeData, form }) {
    const { rowSelection, selectedRowKeys } = useTableSelection({})
    const { isXSmall, isSmall } = useSize()
    const skus = _.get(storeData, 'skus', [])

    const isSaved = _.get(storeData, 'isSaved', false)
    const sku_code =
        storeData.barcode_type === DOCUMENT_IMPORTING_SCAN_TYPE.SKU_CODE
            ? 'sku_code'
            : storeData.barcode_type === DOCUMENT_IMPORTING_SCAN_TYPE.SKU_REF
            ? 'ref_code'
            : 'sku_id'

    function _onChangeSerial(sku_id, value) {
        storeActions.updateSku({ sku_id, quantity: value?.length || 0, serial_numbers: value })
    }

    function _onBlur(sku_id, value) {
        if (value === '') storeActions.updateSku({ sku_id, quantity: 1 })
    }

    function _onChangeQuantity(sku_id, value) {
        storeActions.updateSku({ sku_id, quantity: value  })
    }

    function _onDelete(sku_id) {
        storeActions.deleteSku(sku_id)
    }

    function _onReset() {
        let defaultValues = { barcode_type: DOCUMENT_IMPORTING_SCAN_TYPE.SKU_CODE }
        if (warehouseStorage.value) defaultValues.warehouse_id = warehouseStorage.value

        storeActions.reset({ ...storeData, ...defaultValues, skus: [] })
        let formatedData = Object.keys(defaultValues).map(key => ({ name: key, value: defaultValues[key] }))
        form.setFields(formatedData)
    }

    let getColumns = [
        {
            className: '_importing_scan--sku_code',
            title: t(`document:label.${sku_code}`),
            dataIndex: sku_code,
            key: sku_code,
            render: text => {
                return <span>{text}</span>
            },
        },
        {
            className: '_importing_scan--sku_name text-nowrap',
            title: t('document:label.sku_name'),
            dataIndex: 'sku_name',
            key: 'sku_name',
        },
        {
            className: '_importing_scan--quantity text-right',
            title: t('document:label.quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            width: isXSmall || isSmall ? '100px' : '320px',
            render: (text, record) => {
                const serialNumbers = get(record, 'serial_numbers', [])
                const quantity = get(record, 'quantity', [])

                const isSerial = get(record, 'is_serial_number', false)

                return (
                    <div className="d-flex justify-content-center align-items-center">
                        {isSerial ? (
                            <SelectSerial
                                onChange={value => _onChangeSerial(record.sku_id, value)}
                                value={serialNumbers}
                                disabled={isSaved}
                            />
                        ) : (
                            <Input
                                className="text-right _importing_scan--quantity-input"
                                value={record.quantity}
                                onChange={e => _onChangeQuantity(record.sku_id, e.target.value)}
                                onBlur={e => _onBlur(record.sku_id, e.target.value)}
                                disabled={isSaved}
                            />
                        )}{' '}
                        <span className="ml-3 font-weight-500">{quantity}</span>
                    </div>
                )
            },
        },
        {
            className: '_importing_scan--sku_id text-right',
            title: '',
            dataIndex: 'sku_id',
            key: 'sku_id',
            width: '50px',
            render: (text, record) =>
                !isSaved ? (
                    <span
                        className="_span--delete cursor-pointer _importing_scan--delete-btn"
                        onClick={() => _onDelete(record.sku_id)}
                    >
                        <CloseOutlined />
                    </span>
                ) : null,
        },
    ]

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between">
                    <h3 className="text-fz-18">
                        {t('document:label.sku_list')} ({skus && skus.length > 0 ? skus.length : 0})
                    </h3>
                    <div className="d-flex justify-content-between">
                        <Button
                            className="_importing_scan--cancel-list-btn rounded-4"
                            onClick={_onReset}
                            disabled={isSaved}
                        >
                            <ReloadOutlined /> {t('btn.cancel')}
                        </Button>
                        <PrintSkusBarcode
                            skuIds={selectedRowKeys}
                            className="ml-3"
                            data={skus}
                        />
                    </div>
                </div>
                <div className="data-box--body">
                    <Table
                        rowSelection={rowSelection}
                        className="list-products-table pt-4 _importing_scan--table-list"
                        dataSource={skus}
                        rowKey={record => record?.sku_id}
                        columns={getColumns}
                        pagination={false}
                        locale={{ emptyText: <TableEmpty className="_importing_scan--table-empty" /> }}
                        scroll={{ x: true }}
                    />
                </div>
            </div>
        </div>
    )
}
