import { DownOutlined } from '@ant-design/icons';
import { Table, Space, Input, Row, Col, Typography, Menu, Dropdown, Form, InputNumber } from 'antd';
import { get, filter, isEmpty, map, find, flattenDeep, flatMapDeep, every } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@Modules/App/Box';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { pageSizeOptions } from '@Modules/App/services/constants';
import { getPageSizeScale, updateCollectionItem } from '@Modules/App/services/helpers';
import api from '@Modules/Document/services/api';
import UpdateServiceCreateDocument from '@Modules/Services/components/UpdateServiceCreateDocument';
import apiService from '@Modules/Services/services/api';
import { SERVICE_TYPE } from '@Modules/Services/services/constants';

import { t } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

const { Title } = Typography;

function WaybillList({
    dataSource,
    action,
    data,
    documentId,
    form,
    setSkusImporting,
    skusImporting,
    setDataSource,
    disabled,
    errorAlert,
    setErrorAlert,
}) {
    const isSaved = get(data, 'isSaved', false);
    const [countryId, setCountryId] = useState(undefined);
    const [inputSearch, setInputSearch] = useState();
    const [showService, setShowService] = useState(false);
    const [services, setServices] = useState([]);
    const [showFormUpdateServices, setShowFormUpdateServices] = useState(false);
    const [currentData, setCurrentData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const hasSelected = selectedRowKeys.length > 0;
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, rows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(rows);
        },
    };

    const pagination = {
        pageSizeOptions: pageSizeOptions,
        locale: { items_per_page: '' },
        defaultPageSize: getPageSizeScale(),
        defaultCurrent: 1,
        showSizeChanger: true,
    };

    useEffect(() => {
        setCountryId(getVar(data, 'countryId', undefined));
        setCurrentData(dataSource);
    }, [data, dataSource]);

    // useEffect(() => {
    //     const skus = flatMapDeep(map(dataSource, item => item.skus));

    //     const newError = [];
    //     skus.forEach(sku => {
    //         if (sku?.real_quantity > sku?.quantity) {
    //             newError.push({ alert: true });
    //         } else {
    //             newError.push({ alert: false });
    //         }
    //     });
    //     if (!isEmpty(newError)) {
    //         setErrorAlert(newError);
    //     }
    // }, [dataSource]);

    useEffect(() => {
        if (countryId) {
            apiService.list({ country_id: countryId, hidden_init_service: true }).then(res => {
                let services = getVar(res, 'data.services', []);
                services = services.filter(item => {
                    const type = getVar(item, 'service.type', '');
                    return type === SERVICE_TYPE.IMPORTING_RETURN_GOODS;
                });
                if (!isEmpty(services)) setShowService(true);
                setServices(services);
            });
        } else {
            setServices([]);
        }
    }, [countryId]);

    const columns = [
        {
            className: '_waybill-freight-bill',
            title: t('order:label.freight_bill'),
            dataIndex: ['freight_bill', 'freight_bill_code'],
            key: 'freight_bill_code',
        },
        {
            className: '_waybill-sku_name',
            title: t('product:label.product_name'),
            dataIndex: 'skus',
            key: 'sku_name',
            render: (text, record) => {
                return map(text, (item, index) => {
                    const { name } = item;

                    return (
                        <p key={index} className="py-2 _waybill-sku_name-row">
                            {name}
                        </p>
                    );
                });
            },
        },
        {
            className: '_waybill-sku-info',
            title: t('order:label.sku_info'),
            dataIndex: 'skus',
            key: 'sku_info',
            render: text => {
                return text?.map((item, index) => {
                    return (
                        <p key={index} className="py-2 _waybill-sku-info-row">
                            <Link to={url.to('products.detail', { id: item?.product_id })}>{item?.code}</Link>
                        </p>
                    );
                });
            },
        },
        {
            className: '_waybill-quantity text-right',
            title: t('order:label.quantity'),
            dataIndex: 'skus',
            key: 'quantity',
            render: text => {
                return map(text, (item, index) => {
                    const { current_sku } = item;

                    return (
                        <div className="py-2 _waybill-quantity-row" key={index}>
                            {current_sku}
                        </div>
                    );
                });
            },
        },
        {
            className: '_waybill-real_quantity text-right',
            title: t('document:label.actual_amount_received'),
            dataIndex: 'skus',
            key: 'real_quantity',
            render: (text, record, rowIndex) => {
                return map(text, (item, index) => {
                    const { current_sku, id, real_quantity } = item;

                    return (
                        <Form.Item
                            key={index}
                            validateStatus={get(errorAlert, `${rowIndex}.${index}.alert`) ? 'error' : false}
                            help={
                                get(errorAlert, `${rowIndex}.${index}.alert`)
                                    ? t('document:messages.quantity_received_not_bigger_quantity')
                                    : false
                            }
                            className="mb-0"
                            required
                        >
                            <div className="mb-2" key={index}>
                                <InputNumber
                                    value={real_quantity}
                                    min={0}
                                    disabled={disabled}
                                    className="_waybill-reality_quantity"
                                    onBlur={e =>
                                        handleChangeReceiverQuantitySku(
                                            e.target.value,
                                            id,
                                            current_sku,
                                            real_quantity,
                                            rowIndex
                                        )
                                    }
                                    onPressEnter={e =>
                                        handleChangeReceiverQuantitySku(
                                            e.target.value,
                                            id,
                                            current_sku,
                                            real_quantity,
                                            rowIndex
                                        )
                                    }
                                />
                            </div>
                        </Form.Item>
                    );
                });
            },
        },
    ];

    function removeReturnOrders() {
        action.onDelete(selectedRowKeys);
        setSelectedRowKeys([]);
    }

    function handleSearchTable(value) {
        if (value) {
            const data = filter(dataSource, item => {
                const skus = getVar(item, 'skus.skus', []);
                return skus.some(function (sku) {
                    const sku_code = get(sku, 'code', '');
                    return sku_code.toString().toLowerCase().includes(value.toLowerCase());
                });
            });
            setCurrentData(data);
        } else {
            setCurrentData(dataSource);
        }
    }

    const renderColumn = () => {
        const tableColumns = showService ? columns : filter(columns, item => item.key !== 'services');
        if (isEmpty(action)) {
            return filter(tableColumns, item => item.key !== 'waybill_action' && item.key !== 'services');
        } else {
            return tableColumns;
        }
    };

    const selectedAction = (
        <Menu className="waybill-dropdown-action">
            {showService && (
                <Menu.Item
                    className="mb-3 mb-xl-0 _scan-order-list-of-lading-btn-change-service"
                    onClick={() => setShowFormUpdateServices(true)}
                >
                    {t('order:btn.select_service')}
                </Menu.Item>
            )}
            <Menu.Item
                key={1}
                className="_scan-order-list-of-lading-btn-remove-selected mb-3 mb-xl-0"
                onClick={removeReturnOrders}
            >
                {t('order:btn.remove_selected')}
            </Menu.Item>
        </Menu>
    );

    function makeServicePriceIdsForSelectedReturnOrder() {
        const selectedOrders = map(selectedRows, 'order.id');
        return currentData
            .filter(returnOrder => selectedOrders.includes(getVar(returnOrder, 'order.id', undefined)))
            .reduce((prices, returnOrder) => [...prices, ...returnOrder.import_return_goods_service_prices], [])
            .map(price => price.service_price_id);
    }

    function handleUpdateService(priceIds) {
        const orderIds = map(selectedRows, 'order.id');
        return api.updateReturnOrderServices({ order_ids: orderIds, service_price_ids: priceIds });
    }

    function reloadData(priceIds) {
        const orderIds = map(selectedRows, 'order.id');
        const pricesInfo = [];
        priceIds.map(priceId => {
            services.map(item => {
                const serviceInfo = getVar(item, 'service', {});
                const serviceId = getVar(serviceInfo, 'id', undefined);
                const servicePrice = find(getVar(item, 'servicePrices', []), ['id', priceId]);
                if (!isEmpty(servicePrice)) {
                    pricesInfo.push({ service_id: serviceId, service_price_id: priceId });
                }
            });
        });
        action.updateService({ pricesInfo, orderIds });
        return true;
    }

    const handleChangeReceiverQuantitySku = (value, skuId, quantity, realQuantity, rowIndex) => {
        const newData = [...currentData];
        const currSkuImporting = [...skusImporting];
        let newValue = 0;

        if (value) {
            newValue = parseInt(value.replace(/^\d\d?[,.]\d\d?$/, ''));

            if (Number.isNaN(newValue)) {
                newValue = 0;
            }
        }

        if (!isEmpty(newData[rowIndex])) {
            newData[rowIndex].skus = updateCollectionItem(
                get(newData, `${rowIndex}.skus`, []),
                skuId,
                { real_quantity: newValue },
                'id'
            );
        }

        const skus = flattenDeep(newData.map(item => item.skus));

        const skusReal = Object.values(
            skus.reduce((acc, item) => {
                acc[item.id] = acc[item.id]
                    ? { ...item, real_quantity: parseInt(item.real_quantity) + parseInt(acc[item.id].real_quantity) }
                    : item;
                return acc;
            }, {})
        );

        const newSkus = currSkuImporting.map(item => {
            skusReal.forEach(re => {
                if (re.id === item.id) {
                    item.real_quantity = re.real_quantity;
                }
            });

            return {
                ...item,
            };
        });

        const newErrors = map(newData, item => {
            const skuElert = item?.skus.map(sku => ({ alert: sku?.real_quantity > sku?.current_sku }));
            return skuElert;
        });

        setErrorAlert(newErrors);
        setDataSource(newData);
        setSkusImporting(newSkus);
    };

    return (
        <Box className="main">
            <div className="d-flex flex-wrap justify-content-between mb-3 align-items-center">
                <Space className="mb-3 order-0 mb-xl-0">
                    <Title level={3}>{t(`document:scan_return_order.title.FREIGHT_BILL`)}</Title>
                    <span className="number-circle _waybill-total">{currentData.length}</span>
                </Space>
            </div>
            <div className="border-top">
                <Row className={'justify-content-between p-2'}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} className="mb-3 mb-xl-0">
                        {!isEmpty(action) && hasSelected && currentData.length > 0 && !isSaved && (
                            <>
                                {t(`order:title.selected.FREIGHT_BILL`, { count: selectedRowKeys.length })}
                                <Dropdown overlay={selectedAction} trigger={['click']} className="_dropdown-action">
                                    <a className="ant-dropdown-link ml-2 " onClick={e => e.preventDefault()}>
                                        {t('choose_action')} <DownOutlined />
                                    </a>
                                </Dropdown>
                            </>
                        )}
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
                        <Input.Search
                            value={inputSearch}
                            onChange={e => setInputSearch(e.target.value)}
                            placeholder={t(`product:placeholder.sku_code`)}
                            onSearch={() => handleSearchTable(inputSearch)}
                            enterButton
                            autoComplete="off"
                            className="_waybill-btn-search"
                        />
                    </Col>
                </Row>
                <Table
                    rowKey={record => record?.freight_bill?.freight_bill_code}
                    rowSelection={isEmpty(action) ? null : rowSelection}
                    className="_waybill-table"
                    dataSource={currentData}
                    columns={renderColumn()}
                    pagination={pagination}
                    scroll={{ x: 576 }}
                    // footer={() => {
                    //     return dataSource.length > 0 ? (
                    //         <div className="d-flex justify-content-between">
                    //             <strong>{t('sum')}</strong>
                    //             <strong className="_product-list-total">
                    //                 {`${sumBy(dataSource, 'real_quantity')}/${sumBy(dataSource, 'quantity')}`}
                    //             </strong>
                    //         </div>
                    //     ) : null;
                    // }}
                    locale={{ emptyText: <TableEmpty className="_waybill-no-data" /> }}
                />
            </div>
            {showFormUpdateServices && (
                <UpdateServiceCreateDocument
                    visible={showFormUpdateServices}
                    priceIds={makeServicePriceIdsForSelectedReturnOrder()}
                    onClose={() => setShowFormUpdateServices(false)}
                    reloadData={reloadData}
                    handleUpdate={handleUpdateService}
                    serviceType={SERVICE_TYPE.IMPORTING_RETURN_GOODS}
                    countryId={countryId}
                />
            )}
        </Box>
    );
}

export default WaybillList;
