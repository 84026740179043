import { Button, Modal } from 'antd';
import clsx from 'clsx';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import React, { useCallback, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import useToggle from '@Modules/App/Hooks/useToggle';
import UploadFile from '@Modules/App/Upload/UploadFileV2';

import { t } from '@System/i18n';
import useImportChangeFinancialStatusMutation from "@Modules/Order/Hooks/useImportChangeFinancialStatusMutation";
import lodash from "lodash";

const ImportChangeFinancialStatus = ({ btnType, className, onSuccessImport }) => {
    const [errors, setErrors] = useState([]);
    const [file, setFile] = useState();
    const [visible, { setLeft, setRight }] = useToggle(false);
    const { isLoading: importing, mutateAsync: importForConfirm } = useImportChangeFinancialStatusMutation();

    const handleUpload = useCallback(file => {
        setFile(file);
    }, []);

    const handleCancel = usePersistFn(() => {
        setLeft();
        setFile();
        setErrors([]);
    });

    const handleOk = usePersistFn(async () => {
        const { data } = await importForConfirm({
            file,
        });

        const errors = uniqWith(data?.errors, isEqual);

        if (errors.length) {
            setErrors(errors);
        } else {
            handleCancel();
            onSuccessImport();
        }
    });

    function renderError(error) {
        const ERRORS = ['order_code_invalid', 'order_status_invalid'];
        const line = lodash.get(error, 'line', '');
        const content = lodash.get(error, 'errors', {});
        let messages = [];

        if (typeof content === 'string') {
            messages.push(t(`order:message.upload_file.${content}`));
        } else {
            Object.entries(content).forEach(entry => {
                let [key, errors] = entry;
                ERRORS.forEach(errorKey => {
                    if (errors.hasOwnProperty(errorKey)) {
                        messages.push(
                            t(`order:message.upload_file.${errorKey}`, {
                                attribute: t(`order:label.${key}`),
                            })
                        );
                    }
                });
            });
        }

        if (messages.length > 0) return t('product:message.upload_file.line', { line: line, content: messages.join(' ') });
        return t('product:message.upload_file.unknown_error');
    }

    return (
        <>
            <Button
                type={btnType ? btnType : 'primary'}
                className={clsx('_change-financial-status-btn', className)}
                onClick={setRight}
            >
                {t('order:change_financial_status.title')}
            </Button>

            {visible && (
                <Modal
                    cancelText={t('btn.cancel')}
                    className="_change-financial-status-modal"
                    footer={
                        <>
                            <Button onClick={handleCancel}>{t('btn.cancel')}</Button>

                            <Button loading={importing} type="primary" onClick={handleOk}>
                                {t('btn.ok')}
                            </Button>
                        </>
                    }
                    cancelButtonProps={{ className: '_change-financial-status-modal-btn-cancel' }}
                    okButtonProps={{ className: '_change-financial-status-modal-btn-ok' }}
                    okText={t('btn.ok')}
                    title={t('order:change_financial_status.title')}
                    visible={visible}
                    onCancel={handleCancel}
                >
                    <UploadFile
                        accept=".xls,.xlsx"
                        fileType={[
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                        ]}
                        maxFileSize={5}
                        showUploadList
                        onChange={handleUpload}
                    />
                    {errors && errors.length > 0 && (
                        <div className="pt-3">
                            {errors.map((error, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {renderError(error)}
                                    </p>
                                );
                            })}
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
};

export default ImportChangeFinancialStatus;
