export const TYPES = {
    TYPE_PACKING: 'PACKING', // Đóng hàng
    TYPE_EXPORTING: 'EXPORTING', // Xuất hàng
    TYPE_IMPORTING: 'IMPORTING',
    TYPE_SKU_INVENTORY: 'SKU_INVENTORY',
};

export const STATUS = {
    STATUS_DRAFT: 'DRAFT',
    STATUS_COMPLETED: 'COMPLETED',
    STATUS_CANCELLED: 'CANCELLED',
};

export const DOCUMENT_IMPORTING_SCAN_TYPE = {
    SKU_CODE: 'SKU_CODE',
    SKU_REF: 'SKU_REF',
    SKU_ID: 'SKU_ID'
};

export const SCAN_PURCHASE_ORDER_TYPE = {
    ORDER_CODE: 'ORDER_CODE',
    PACKAGE_CODE: 'PACKAGE_CODE',
};

export const SCAN_RETURN_ORDER_TYPE = {
    ORDER_CODE: 'ORDER_CODE',
    FREIGHT_BILL: 'FREIGHT_BILL',
};
export const LIST_TYPE_IMPORTING_DOCUMENT = {
    IMPORTING: 'IMPORTING',
    RETURN_GOODS: 'IMPORTING_RETURN_GOODS',
};

export const SCAN_PURCHASE_PACKAGE_TYPE = {
    PACKAGE_CODE: 'PACKAGE_CODE',
    PACKAGE_FREIGHT_BILL: 'PACKAGE_FREIGHT_BILL',
};

export const STATUS_COLORS = {
    DRAFT: '#ffa634',
    COMPLETED: '#09b8af',
    CANCELLED: '#bdbdbd',
};
