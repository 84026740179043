import { destroyFns } from 'antd/lib/modal/Modal';
import { getConfirmLocale } from 'antd/lib/modal/locale';
import isFunction from 'lodash/isFunction';
import React from 'react';
import * as ReactDOM from 'react-dom';

import ConfirmDialog from './ConfirmDialog';

const confirm = config => {
    const div = document.createElement('div');
    document.body.appendChild(div);
    let currentConfig = { ...config, close, visible: true };
  
    function destroy(...args) {
        const unmountResult = ReactDOM.unmountComponentAtNode(div);

        if (unmountResult && div.parentNode) {
            div.parentNode.removeChild(div);
        }
        const triggerCancel = args.some(param => param && param.triggerCancel);
        if (config.onCancel && triggerCancel) {
            config.onCancel(...args);
        }

        for (let i = 0; i < destroyFns.length; i++) {
            const fn = destroyFns[i];

            if (fn === close) {
                destroyFns.splice(i, 1);
                break;
            }
        }
    }
  
    function render({ okText, cancelText, ...props }) {
        /**
         * https://github.com/ant-design/ant-design/issues/23623
         *
         * Sync render blocks React event. Let's make this async.
         */
        setTimeout(() => {
            const runtimeLocale = getConfirmLocale();
    
            ReactDOM.render(
                <ConfirmDialog
                    {...props}
                    okText={okText || (props.okCancel ? runtimeLocale.okText : runtimeLocale.justOkText)}
                    cancelText={cancelText || runtimeLocale.cancelText}
                />,
                div
            );
        });
    }
  
    function close(...args) {
        currentConfig = {
            ...currentConfig,
            visible: false,
            afterClose: () => {
                if (isFunction(config.afterClose)) {
                    config.afterClose();
                }

                destroy.apply(this, args);
            }
        };

        render(currentConfig);
    }
  
    function update(configUpdate) {
        if (isFunction(configUpdate)) {
            currentConfig = configUpdate(currentConfig);
        } else {
            currentConfig = {
                ...currentConfig,
                ...configUpdate,
            };
        }

        render(currentConfig);
    }
  
    render(currentConfig);
  
    destroyFns.push(close);
  
    return {
        destroy: close,
        update
    };
};

export default confirm;
