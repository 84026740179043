export const PURCHASING_PACKAGE_STATUS = {
    TRANSPORTING: 'TRANSPORTING',
    IMPORTED: 'IMPORTED',
    INIT: 'INIT',
    PUTAWAY: 'PUTAWAY',
    READY_FOR_DELIVERY: 'READY_FOR_DELIVERY',
    REQUEST_FOR_DELIVERY: 'REQUEST_FOR_DELIVERY',
    DELIVERING: 'DELIVERING',
    DELIVERED: 'DELIVERED',
    MIA: 'MIA',
    INACTIVE: 'INACTIVE',
    WAIT_FOR_RETURN: 'WAIT_FOR_RETURN',
    RETURNING: 'RETURNING',
    RETURNED: 'RETURNED',
    WAIT_FOR_LIQUIDATION: 'WAIT_FOR_LIQUIDATION',
    LIQUIDATED: 'LIQUIDATED',
    REFUSED: 'REFUSED',
};

export const PURCHASING_PACKAGE_STATUS_COLORS = {
    TRANSPORTING: '#008DF9',
    IMPORTED: '#09B8AF',
    INIT: '#2e70ff',
    PUTAWAY: '#06d087',
    READY_FOR_DELIVERY: '#bdbdbd',
    REQUEST_FOR_DELIVERY: '#ed6e52',
    DELIVERING: '#4fff37',
    DELIVERED: '#1ef8ff',
    MIA: '#ff4559',
    INACTIVE: '#ff4559',
    WAIT_FOR_RETURN: '#ffa634',
    RETURNING: '#1e8eff',
    RETURNED: '#ff4559',
    WAIT_FOR_LIQUIDATION: '#ffa634',
    LIQUIDATED: '#ff4559',
    REFUSED: '#ff4559',
};

export const FINANCE_STATUS = {
    UNPAID: 'UNPAID',
    PAID: 'PAID',
};

export const FINANCE_STATUS_COLORS = {
    UNPAID: '#f9364a',
    PAID: '#0cb80b',
};


export const INPUT_INVALID = "INPUT_INVALID"