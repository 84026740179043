import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from './../services/api';

const usePurchasingAccountsQuery = params =>
    createUseQuery({
        name: ['purchasing-accounts', params],
        query: () => apiService.list(params),
    });

export default usePurchasingAccountsQuery;
