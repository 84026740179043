/* eslint-disable import/no-anonymous-default-export */
import { api } from '@System/api'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getOrderFinance: params => api.get('/orders/finance', { params, singleRequest: true, loading: false }),
    getOrderStatistic: params => api.get('/orders/stats', { params, singleRequest: true, loading: false }),
    importCreateFinanceControl: data => api.post('/document-freight-bill-inventories', data),
    getFinanceControlDocument: documentId => api.get(`/document-freight-bill-inventories/${documentId}`, { singleRequest: true, loading: false }),
    exportFreightBill: (documentId, data) => api.post(`/document-freight-bill-inventories/${documentId}/export-freight-bill`, data, { responseType: 'blob' }),
    deleteFinanceControlDocument: documentId => api.put(`document-freight-bill-inventories/${documentId}/cancel`),
    confirmFinanceControlDocument: (documentId, data) => api.put(`document-freight-bill-inventories/${documentId}/confirm`, data),
    getFinanceControl: params => api.get('/document-freight-bill-inventories', { params, singleRequest: true, loading: false }),
    updateFinanceControlDocument: (documentId, data) => api.put(`document-freight-bill-inventories/${documentId}`, data),
    updateFreightBill: (documentId, data) => api.post(`/document-freight-bill-inventories/${documentId}/update-info`, data),
    getFinanceDebitShipping: params => api.get(`/debt-shipping-partners`, { params, singleRequest: true, loading: false }),
    getFinanceDebitShippingStats: params => api.get(`/debt-shipping-partners/stats`, { params, singleRequest: true, loading: false }),
    checkingCreateControlDeliveryDocument: data => api.post(`document-delivery-comparisons/checking`, data),
    createControlDeliveryDocument: data => api.post(`document-delivery-comparisons`, data),
    getDetailControlDeliveryDocument: documentId => api.get(`document-delivery-comparisons/${documentId}`, { singleRequest: true, loading: false }),
    getListControlDeliveryDocument: params => api.get(`document-delivery-comparisons`, { params, singleRequest: true, loading: false }),
    updateFinanceControlDeliveryDocument: (documentId, data) => api.put(`document-delivery-comparisons/${documentId}`, data),
    downloadDeliveryDocumentComparison: (documentId, params) =>
        api.get(`document-delivery-comparisons/${documentId}/download-error-comparison`, { params, responseType: 'blob' }),
    downloadDebtsShipping: params => api.post(`/debt-shipping-partners/export-excel`, null, { params, responseType: 'blob' }),
    getExpectedTransportingTemplate: (id, params) =>
        api.get(`shipping-partners/${id}/download-expected-transporting-template`, { params, responseType: 'blob' }),
    uploadExpectedTransportingPrice: (id, data) => api.post(`shipping-partners/${id}/upload-expected-transporting-price`, data),
}
