import { useQuery } from 'react-query';

import apiService from './../services/api';

const useExportingDocumentQuery = id => useQuery(['exporting-document', id], () => apiService.exportingDocument(id), {
    retry: false,
    enabled: !!id
});

export default useExportingDocumentQuery;
