import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import {router, url} from './';

/**
 * Tạo element <a> xử lý redirect theo router
 * Có thể truyền các thuộc tính mặc định như className, id, ...
 *
 * Example:
 * <Link href="/path/to/page">Text</Link>
 * <Link to="route_name" params={{key: value}}>Text</Link>
 */
class Link extends React.Component {
    onClick(url, event) {
        const { isNewTab } = this.props;
        event.preventDefault();

        if (url) {
            if (isNewTab) {
                window.open(url, "_blank");
            } else {
                router.redirect(url);
            }
        }
    }

    render() {
        let {to, params = {}, children} = this.props;
        let props = lodash.omit(this.props, ['to', 'params', 'children', 'isNewTab']);

        if (to) {
            props.href = url.to(to, params);
        }

        return <a {...props} onClick={this.onClick.bind(this, props.href)}>{children}</a>;
    }
}

Link.propTypes = {
    to: PropTypes.string,
    params: PropTypes.object,
    href: PropTypes.string,
    isNewTab: PropTypes.bool
};

export default Link;