import { Select } from 'antd'
import isFunction, { filter, get, isEmpty, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { warehouseAreaStorage, warehouseStorage } from '@Modules/App/services'
import useWarehouseItemsQuery from '@Modules/Warehouse/Hooks/useWarehouseItemsQuery'

import { t } from '@System/i18n'

const { Option } = Select

export default function SelectWarehouse(props) {
    const { onChange, value, noSuggest, shippingPartner, ...rest } = props
    const { isLoading, data } = useWarehouseItemsQuery({
        per_page: 1000,
    })
    const [dataWarehouse, setDataWarehouse] = useState([])

    useEffect(() => {
        setDataWarehouse()
    }, [data])

    useEffect(() => {
        const filterDataShippingPartner = () => {
            const newData = filter(get(data, 'data.warehouses', []), item => {
                return get(shippingPartner, 'countryId') === get(item, 'country.id')
            })
            setDataWarehouse(newData)
        }
        filterDataShippingPartner()
    }, [data, shippingPartner])

    function _onChange(value, option) {
        if (!noSuggest) {
            warehouseStorage.setValue(value)
            warehouseAreaStorage.reset()
        }

        if (isFunction(onChange)) {
            onChange(value, option)
        }
    }

    return (
        <Select
            loading={isLoading}
            onChange={_onChange}
            value={!isEmpty(dataWarehouse) && value ? value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            {...rest}
        >
            {dataWarehouse?.map(warehouse => (
                <Option
                    key={warehouse?.warehouse?.id}
                    value={warehouse?.warehouse?.id}
                >
                    {warehouse?.warehouse?.name +
                        (warehouse?.warehouse?.code
                            ? ' (' + (warehouse?.warehouse?.code === 'default' ? t('order:label.default') : warehouse?.warehouse?.code) + ')'
                            : '')}
                </Option>
            ))}
        </Select>
    )
}

SelectWarehouse.propTypes = {
    noSuggest: PropTypes.bool,
}

SelectWarehouse.defaultProps = {
    noSuggest: true,
}
