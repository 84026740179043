import { Button, Col, Row } from 'antd';
import flow from 'lodash/flow';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sumBy from 'lodash/sumBy';
import values from 'lodash/values';
import React, { useCallback, useEffect, useState } from 'react';

import ScannableInput from '@Modules/App/Input/ScannableInput';
import { SCAN_TYPE } from '@Modules/Document/constants';

import { t } from '@System/i18n';

import ImportBarcodesFileModal from '../../components/ImportBarcodesFileModal';
import InputMultipleBarcodesModal from '../../components/InputMultipleBarcodesModal';
import ProductList from '../../components/ProductList';
import OrderExportingList from './OrderExportingList';

const Body = ({ checkedList, exportingDocument, notInDocumentList, orderExportings, uncheckedList, onCheckBarcodes }) => {
    const [modalVisibles, setModalVisibles] = useState({
        inputMultipleBarcodes: false,
        importBarcodesFile: false,
    });
    const [barcode, setBarcode] = useState();
    const [products, setProducts] = useState([]);
    const getFormattedProducts = useCallback(
        flow(
            orderExportings => {
                const key =
                    exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER ? 'order_code' : 'freight_bill';

                return reduce(
                    orderExportings,
                    (result, orderExporting) => [
                        ...result,
                        ...map(orderExporting.order_exporting_items, orderExportingItem => ({
                            ...orderExportingItem,
                            is_checked: checkedList.includes(orderExporting[key]),
                        })),
                    ],
                    []
                );
            },
            products =>
                reduce(
                    products,
                    (result, item) => ({
                        ...result,
                        [item.sku_id]: {
                            ...item,
                            quantity: ((result[item.sku_id] && result[item.sku_id].quantity) || 0) + item.quantity,
                            checked_quantity: item.is_checked
                                ? ((result[item.sku_id] && result[item.sku_id].checked_quantity) || 0) + (item.quantity || 0)
                                : item.checked_quantity || 0,
                        },
                    }),
                    {}
                ),
            products => values(products)
        ),
        [checkedList]
    );

    const openModal = useCallback(
        type => {
            setModalVisibles({
                ...modalVisibles,
                [type]: true,
            });
        },
        [modalVisibles]
    );

    const closeModal = useCallback(
        type => {
            setModalVisibles({
                ...modalVisibles,
                [type]: false,
            });
        },
        [modalVisibles]
    );

    const handleChangeBarcode = useCallback(event => {
        setBarcode(event.target.value);
    }, []);

    const handleCheckBarcode = useCallback(() => {
        const formattedBarcode = barcode.trim();

        onCheckBarcodes(formattedBarcode);
        setBarcode();
    }, [barcode, onCheckBarcodes]);

    const handleCheckBarcodes = useCallback(
        (type, barcodes) => {
            onCheckBarcodes(barcodes);
            closeModal(type);
        },
        [closeModal, onCheckBarcodes]
    );

    useEffect(() => {
        const products = getFormattedProducts(orderExportings);

        setProducts(products);
    }, [checkedList, orderExportings, getFormattedProducts]);

    return (
        <Row gutter={48} type="flex">
            <InputMultipleBarcodesModal
                visible={modalVisibles.inputMultipleBarcodes}
                onCancel={closeModal.bind(this, 'inputMultipleBarcodes')}
                onOk={handleCheckBarcodes.bind(this, 'inputMultipleBarcodes')}
            />
            <ImportBarcodesFileModal
                visible={modalVisibles.importBarcodesFile}
                onCancel={closeModal.bind(this, 'importBarcodesFile')}
                onOk={handleCheckBarcodes.bind(this, 'importBarcodesFile')}
            />

            <Col className="mb-4" lg={{ span: 18 }} span={24}>
                <Row>
                    <Col className="mb-2" span={24}>
                        {t('input_or_scan_barcode')}
                    </Col>

                    <Col className="d-md-flex" span={24}>
                        <ScannableInput
                            className="mr-3"
                            value={barcode}
                            onChange={handleChangeBarcode}
                            onPressEnter={handleCheckBarcode}
                        />
                        <Button
                            className="mt-3 mt-md-0 mr-3 _input-multiple-barcodes-btn"
                            onClick={openModal.bind(this, 'inputMultipleBarcodes')}
                        >
                            {t('input_multiple_codes')}
                        </Button>
                        <Button
                            className="mt-3 mt-md-0 _import-barcodes-file-btn"
                            onClick={openModal.bind(this, 'importBarcodesFile')}
                        >
                            {t('input_from_excel_file')}
                        </Button>
                    </Col>
                </Row>
            </Col>
            <Col className="mb-3 mb-lg-0" lg={{ span: 18 }} span={24}>
                <OrderExportingList
                    checkedList={checkedList}
                    exportingDocument={exportingDocument}
                    notInDocumentList={notInDocumentList}
                    orderExportings={orderExportings}
                    uncheckedList={uncheckedList}
                />
            </Col>
            <Col lg={{ span: 6 }} span={24}>
                <ProductList
                    orderExportings={orderExportings}
                    products={products}
                    quantityRender={product => (
                        <>
                            <strong>{product.checked_quantity}</strong>/<span className="text-grey">{product.quantity}</span>
                        </>
                    )}
                    totalQuantityRender={products => (
                        <div>
                            <strong>{sumBy(products, 'checked_quantity')}</strong>/
                            <span className="text-grey text-right">{sumBy(products, 'quantity')}</span>
                        </div>
                    )}
                />
            </Col>
        </Row>
    );
};

export default Body;
