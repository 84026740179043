import { Form, Modal } from 'antd';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import uniqWith from 'lodash/uniqWith';
import React, { useCallback, useEffect, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import UploadFile from '@Modules/App/Upload/UploadFileV2';
import SelectMerchants from '@Modules/Merchant/components/SelectMerchants';

import { t } from '@System/i18n';
import { renderImportErrors } from '@System/support/helpers';

import useImportSkusMutation from '../Hooks/useImportSkusMutation';

const ImportSkusModal = ({ inventoryDocumentId, visible, onCancel, onOk }) => {
    const [form] = Form.useForm();
    const [errors, setErrors] = useState([]);
    const [file, setFile] = useState();
    const { isLoading: importing, mutateAsync: importSkus } = useImportSkusMutation();

    const handleCancel = usePersistFn(() => {
        if (isFunction(onCancel)) {
            onCancel();
        }
    }, []);

    const handleOk = usePersistFn(async () => {
        try {
            form.validateFields().then(async () => {
                const merchant_id = form.getFieldValue("merchant_id");
                const { data } = await importSkus({
                    id: inventoryDocumentId,
                    merchant_id,
                    file,
                });

                const errors = uniqWith(data?.errors, isEqual);

                if (errors.length) {
                    setErrors(errors);
                }

                if (isFunction(onOk)) {
                    onOk(data);
                }
            })
        } catch (error) {}
    }, []);

    const handleUpload = useCallback(file => {
        setFile(file);
    }, []);

    useEffect(() => {
        if (!visible) {
            setErrors([]);
            setFile();
        }
    }, [visible]);

    return (
        visible && (
            <Modal
                cancelText={t('btn.close')}
                className="_import-skus-modal"
                maskClosable
                okButtonProps={{
                    loading: importing,
                }}
                okText={t('btn.ok')}
                title={t('input_list_with_type', {
                    type: 'SKU',
                })}
                visible={visible}
                onCancel={handleCancel}
                onOk={handleOk}
            >
                <Form form={form}>
                    <Form.Item
                        name="merchant_id"
                        label={t('label.select_seller')}
                        initialValue={0}
                        rules={[
                            {
                                required: true,
                                type: 'integer',
                                message: t('order:message.required', { attribute: t('label.select_seller') }),
                            },
                        ]}
                    >
                        <SelectMerchants
                            alloption={1}
                            placeholder={t('placeholder.select_seller')}
                            className="_import-by-purchase-package-scan-seller-id"
                            allowClear={true}
                            onChange={value => form.setFields([{name: "merchant_id", value, errors: ''}]) }
                        />
                    </Form.Item>
                    <UploadFile
                        accept=".xls,.xlsx"
                        fileType={[
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                        ]}
                        onChange={handleUpload}
                    />
                    {errors && errors.length > 0 && (
                        <div className="pt-3">
                            {errors.map((error, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {renderImportErrors(error, 'document')}
                                    </p>
                                );
                            })}
                        </div>
                    )}
                </Form>
            </Modal>
        )
    );
};

export default ImportSkusModal;
