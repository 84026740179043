import React, {useState, useEffect} from 'react';
import {Form, Row, Col, Button, Input, Select} from 'antd';
import {t} from "../../../../system/i18n";
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";
import Location from "./Location";

const {Option} = Select;

function FormSearch({addFilter, filters, initFilters}) {
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const [reset, setReset] = useState(0);
    const {
        code,
        name,
        status
    } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({...input, [name]: value});
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onSubmit() {
        addFilter(input);
    }

    function _onReset() {
        setReset(reset+1);
        addFilter(initFilters);
    }

    return (
        <Form
            className="pl-4 pr-4 pt-3 pb-2 border-bottom bg-light-gray"
            form={form}
            layout="vertical"
        >
            <Row gutter={15}>
                <Col xs={{span: 19}} lg={{span: 19}}>
                    <Row gutter={15}>
                        <Col xs={{span: 12}} lg={{span: 6}}>
                            <Form.Item
                                className="mb-3"
                                label={t("warehouse:label.code")}
                            >
                                <Input
                                    name="code"
                                    value={code}
                                    placeholder={t("warehouse:placeholder.code")}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                    allowClear={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{span: 12}} lg={{span: 6}}>
                            <Form.Item
                                className="mb-3"
                                label={t("warehouse:label.name")}
                            >
                                <Input
                                    name="name"
                                    value={name}
                                    placeholder={t("warehouse:placeholder.name")}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                    allowClear={true}
                                />
                            </Form.Item>
                        </Col>

                        <Location setInput={setInput} input={input} reset={reset} />

                        <Col xs={{span: 12}} lg={{span: 6}}>
                            <Form.Item
                                className="mb-3"
                                label={t("warehouse:label.status")}
                            >
                                <Select
                                    name="status"
                                    value={status !== undefined ? parseInt(status) : undefined}
                                    placeholder={t("warehouse:placeholder.status")}
                                    onChange={value => setInput({...input, status: value})}
                                    showSearch
                                    optionFilterProp="label"

                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value={1}>{t("merchant:label.active")}</Option>
                                    <Option value={0}>{t("merchant:label.inactive")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col xs={{span: 5}} lg={{span: 5}} className="text-right">
                    <Row gutter={15} className="mt-4 pt-1">
                        <Col xs={{span: 12}}>
                            <Button type="primary" onClick={_onSubmit} style={{width: "100%"}}><SearchOutlined/> {t("common:btn.search")}</Button>
                        </Col>
                        <Col xs={{span: 12}}>
                            <Button onClick={_onReset} style={{width: "100%"}}><UndoOutlined/> {t("common:btn.reset")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;
