/* eslint-disable import/no-anonymous-default-export */
import { generatorHashKey } from '@System/support/helpers';
import { api } from './../../../system/api';

const PACKING_BASE_URL = 'document-packings';
const EXPORTING_BASE_URL = 'document-exportings';
const EXPORTING_INVENTORIES_BASE_URL = 'document-exporting-inventories';

export default {
    packingDocument: id => api.get(`${PACKING_BASE_URL}/${id}`),
    checkingExportWarnings: data => api.post(`${EXPORTING_BASE_URL}/checking-warnings`, data),
    export: id => api.put(`${EXPORTING_BASE_URL}/${id}/export`), // Xuất kho chứng từ xuất hàng
    createExportDocument: data => api.post(EXPORTING_BASE_URL, data), // Tạo chứng từ xuất hàng,
    exportingDocument: id => api.get(`${EXPORTING_BASE_URL}/${id}`),
    deleteExportingDocument: id => api.delete(`${EXPORTING_BASE_URL}/${id}`),
    updateExportingDocument: (id, data) => api.put(`${EXPORTING_BASE_URL}/${id}`, data),
    getDocumentPackings: params => api.get(`/document-packings`, { singleRequest: true, loading: false, params }),
    getDocumentExportings: params => api.get(`/document-exportings`, { singleRequest: true, loading: false, params }),
    createExportingInventoryDocument: data => api.post(EXPORTING_INVENTORIES_BASE_URL, data), // Tạo chứng từ đối soát phiếu xuất
    exportingInventoryDocument: id => api.get(`${EXPORTING_INVENTORIES_BASE_URL}/${id}`), // Chi tiết chứng từ đối soát phiếu xuất
    scanImportingSku: params => api.get(`document-importings/scan`, { singleRequest: true, loading: false, params }), // Màn hình nhập hàng
    createDocumentImportingSkus: data => api.post(`document-importings`, data, { idempotencyKey: generatorHashKey(data) }), // Tạo chứng từ nhập hàng
    getImportingDocuments: params => api.get(`document-importings`, { singleRequest: true, loading: false, params }),
    importingDocument: id => api.get(`document-importings/${id}`, { singleRequest: true, loading: false }),
    importingConfirm: id => api.put(`document-importings/${id}/confirm`),
    updateImportingDocument: (id, data) => api.put(`document-importings/${id}`, data),
    importingCancel: id => api.put(`document-importings/${id}/cancel`),
    getSkusDocumentImporting: (id, params) =>
        api.get(`document-importings/${id}/sku-importings`, {
            singleRequest: true,
            loading: false,
            params,
        }),
    updateSkuRealQUantityDocumentImporting: (documentId, data) =>
        api.put(`document-importings/${documentId}/real-quantity`, data),
    scanImportByPurchaseOrder: params =>
        api.get(`document-importings/scan-purchasing-order`, {
            loading: false,
            singleRequest: true,
            params,
        }),
    createDocumentImportingByPurchaseOrder: data => api.post(`document-importings/purchasing-order`, data, {idempotencyKey: generatorHashKey(data)}),
    getInventoryDocuments: params =>
        api.get(`document-sku-inventories`, {
            params,
            loading: false,
            singleRequest: true,
        }),
    scanImportByReturnOrder: params =>
        api.get(`document-importing-return-goods/scan`, {
            loading: false,
            singleRequest: true,
            params,
        }),
    createDocumentImportingByReturnOrder: data => api.post(`document-importing-return-goods`, data, { idempotencyKey: generatorHashKey(data)}),
    importingReturnOrderDocument: id =>
        api.get(`document-importing-return-goods/${id}`, {
            singleRequest: true,
            loading: false,
        }),
    importingReturnOrderConfirm: id => api.put(`document-importing-return-goods/${id}/confirm`),
    importingReturnOrderCancel: id => api.put(`document-importing-return-goods/${id}/cancel`),
    updateImportingReturnOrderDocument: (id, data) => api.put(`document-importing-return-goods/${id}`, data),
    exportSkusDocumentImportingReturnOrder: id =>
        api.get(`document-importing-return-goods/${id}/export-skus`, {
            singleRequest: true,
            loading: false,
            responseType: 'blob',
        }),
    updateSkuDocumentImporting: (id, data) => api.put(`/document-importings/${id}/sku-importings`, data, { idempotencyKey: generatorHashKey(data) }),
    getPackingOrderList: (documentId, params) =>
        api.get(`/document-packings/${documentId}/order-packings`, { params, singleRequest: true, loading: false }),
    getProductListOrderPacking: (documentId, params) =>
        api.get(`/document-packings/${documentId}/sku-stats`, { params, singleRequest: true, loading: false }),
    updateReturnOrderServices: data => api.put(`document-importing-return-goods/services`, data),
    updateAdditionalProduct: (data) => api.post(`/skus/update-list-sku`, data),
    updateImportingReturnGoods: (id,data) => api.put(`document-importing-return-goods/${id}/update-importing-barcodes`, data),
    getScanListReturnOrder: (params)=> api.get('/document-importing-return-goods/scan-list',{params})
};
