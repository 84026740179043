/* eslint-disable import/no-anonymous-default-export */
import {api} from '../../../system/api';

export default {
    orderPackingScan: (params) => api.get('order-packings/scan', {
        params,
        singleRequest: true
    }),
    getScanOrderPackingList: (params) => api.get('order-packings/scan-list', {
        params,
        singleRequest: true
    }),
    updateOrderPackingsServices: (data) => api.put('order-packings/services', data),
    confirmOrderPacking: (data) => api.post('document-packings', data),
    exportOrderScan: (params) => api.get(`order-exportings/scan`, {params, singleRequest: true}),
    getScanExportOrderList: (params) => api.get('/order-exportings', {
        params,
        singleRequest: true
    }),
    importBarcodePackingConfirm: (data) => api.post('/order-packings/import-barcode', data),
    scanServicePriceCode: (params) => api.get('order-packings/scan-service-code', {
        params,
        singleRequest: true
    }),
    scanSerialNumber: (id,params) => api.get(`/products/skus/${id}/serial-numbers`, {
        params,
        singleRequest: true
    }),
}
