import { Button, Modal } from 'antd';
import React from 'react';

import { t } from '@System/i18n';

const FinishPickup = ({ visible, setVisible, isFinished, handleFinishSession, loading }) => {
    return (
        <Modal
            title={t('order:title.finished_pickup')}
            onCancel={() => {
                setVisible(false);
            }}
            visible={visible}
            width={600}
            footer={null}
        >
            <div className="d-flex flex-column justify-content-around" style={{ height: '100%' }}>
                {isFinished ? (
                    <p>{t('order:message.order_picking_finished')}</p>
                ) : (
                    <p>{t('order:message.order_picking_no_finished')}</p>
                )}
                <Button
                    className="mt-4"
                    type="primary"
                    onClick={handleFinishSession}
                    loading={loading}
                    style={{ width: '100%' }}
                >
                    {t('order:btn.end_picking')}
                </Button>
            </div>
        </Modal>
    );
};

export default FinishPickup;
