import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useSkuInventoryDocumentQuery = (id, settings) =>
    createUseQuery({
        name: ['sku-inventory-document', id],
        query: () => apiService.detail(id),
        settings: {
            enabled: !!id,
            ...settings,
        },
    });

export default useSkuInventoryDocumentQuery;
