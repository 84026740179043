import { useQuery } from 'react-query';

import apiService from './../services/api';
import {get} from "lodash";
import {notification} from "antd";
import {t} from "@System/i18n";

const useUserDetailQuery = userId =>
    useQuery(['user-detail', userId], () => apiService.getUserDetail(userId), {
        retry: false,
        onError: (err) => {
            const status = get(err, "response.status", null);
            if (status === 403)
                notification.error({message: t('common:message.403')});
            else if (status === 404)
                notification.error({message: t('common:message.404')});
            else
                notification.error({message: t('common:message.fetch_fail')});
        }
    });

export default useUserDetailQuery;
