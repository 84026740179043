import { useQuery } from 'react-query';

import apiService from '../../Dropshipping/services/api';
import processResponseError from '@System/api/processResponseError';

const useProductDropshipQuery = params =>
    useQuery(['products-dropship', params], () => apiService.getProductsDropship(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });

export default useProductDropshipQuery;
