import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useState } from 'react';

import api from '@Modules/Order/services/api';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import notification from '@System/notification';

import ModalPriorityCreate from './ModalPriorityCreate';

const disablePagination = {
    page: undefined,
    per_page: undefined,
    current_page: undefined,
};

function RemoveWarehouseArea({ filters, ids, isSelectAllPage, className }) {
    const [loading, setLoading] = useState(false);
    const [visibleModal, setVisibleModal] = useState(false);
    const [orderPriority, setOrderPriority] = useState([]);

    const handleConfirm = () => {
        Modal.confirm({
            title: t('order:title.confirm_remove_warehouse_area'),
            icon: <ExclamationCircleOutlined />,
            content: t('order:message.confirm_remove_warehouse_area'),
            onOk: handleRemove,
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
        });
    };

    const handleRemove = () => {
        setLoading(true);
        api.removeWarehouseArea({ ...filters, ...disablePagination, ids: isSelectAllPage ? [] : ids })
            .then(() => {
                notification.success(
                    t('message.process_background', { attribute: t('order:btn.remove_warehouse_area') })
                );
            })
            .catch(() => {
                notification.error(t('message.create_failed', { attribute: t('order:btn.remove_warehouse_area') }));
            })
            .finally(() => setLoading(false));
    };


    const handleCheckStatusOrder = async () => {
        const params = {
            ...filters,
            ids: isSelectAllPage ? [] : ids,
        };

        let priority_orders = [];
        setLoading(true);

        try {
            const res = await api.getOrderPackingBeforeWarehouseArea(params);
            let data = await get(res, 'data.order_packings');
            priority_orders = [...data];
        } catch (error) {
            processResponseError(error);
        } finally {
            setLoading(false);
        }

        setOrderPriority(priority_orders);
        if (isEmpty(priority_orders)) {
            handleConfirm();
        } else {
            setVisibleModal(true);
        }
    };

    return (
        <>
            <Button className={className} loading={loading} onClick={handleCheckStatusOrder}>
                {t('order:btn.remove_warehouse_area')}
            </Button>

            {visibleModal && (
                <ModalPriorityCreate
                    setVisibleModal={setVisibleModal}
                    isSelectAllPage={isSelectAllPage}
                    ids={ids}
                    filters={filters}
                    orderPriority={orderPriority}
                />
            )}
        </>
    );
}
export default RemoveWarehouseArea;
