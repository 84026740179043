import { notification } from 'antd';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import { t } from '@System/i18n';
import { notifyErrorFromServer } from '@System/support/helpers';

import apiService from './../services/api';

const useImportRefSkusMutation = payload => {
    if (!payload) {
        payload = {};
    }

    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit(
        {
            ...payload,
            onSuccess: ({ data }) => {
                if (isFunction(payload?.onSuccess)) {
                    payload.onSuccess(data);
                }

                if (!data?.errors?.length) {
                    notification.success({
                        message: t('product:import_ref_skus.success'),
                    });
                }
            },
            onError: error => {
                if (isFunction(payload?.onError)) {
                    payload.onError(error);
                }

                if (payload.notifyWhenError) {
                    notifyErrorFromServer(error, t('product:import_ref_skus.failed'), 'product:import_ref_skus.errors');
                }
            },
        },
        'notifyWhenError'
    );

    return useMutation(data => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        return apiService.importRefSkus(formData);
    }, formattedConfig);
};

export default useImportRefSkusMutation;
