import React, {useState} from "react";
import {t} from "../../../../system/i18n";
import {
    Modal,
    Form,
    Input,
    Row,
    Col,
    Button,
    notification,
    Checkbox
} from "antd";
import api from "../../services/api";
import {PlusOutlined} from "@ant-design/icons";
import Location from "./Location";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import _ from "lodash";

const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18}
};
const tailLayout = {
    wrapperCol: {offset: 6, span: 18},
};

// list of rules for every input
const validatedRules = {
    code: [{required: true, max: 255, type: "string"}],
    name: [{required: true, max: 255, type: "string"}],
    country_id: [{required: true, type: "integer"}],
    province_id: [{type: "integer"}],
    district_id: [{type: "integer"}],
    ward_id: [{type: "integer"}],
    address: [],
    description: []
};

function CreateWarehouse({fetchWarehouses}) {
    const validateMessages = useValidatedMessages();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setVisible(false);
    }

    function handleCancel() {
        form.resetFields();
        setVisible(false);
    }

    function handleSubmit(e) {
        form.validateFields()
            .then(values => {
                setLoading(true);
                let formData = new FormData();
                for (const prop in values) {
                    if (values[prop] && values[prop] !== undefined)
                        if (prop === "is_main") {
                            formData.append(prop, values[prop] ? 1 : 0);
                        } else {
                            formData.append(prop, values[prop]);
                        }
                }

                api.createWarehouse(formData)
                    .then(res => {
                        notification.success({message: t("message.create_success", {attribute: t("label.warehouses")})});
                        form.resetFields();
                        setVisible(false);
                        fetchWarehouses();
                    })
                    .catch(err => {
                        let {response} = err;
                        if (response.status === 400) {
                            let error_messages = [];
                            let errors = _.get(response, "data.data", {});
                            Object.keys(errors).forEach(item => {
                                let key_errors = [];
                                Object.keys(errors[item]).forEach(error => key_errors.push(t(`warehouse:message.${error}`, {attribute: t(`warehouse:label.${item}`)})));
                                error_messages.push({name: item, errors: key_errors})
                            });
                            form.setFields(error_messages);
                        }
                        notification.error({message: t("message.create_failed", {attribute: t("label.warehouses")})});
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
    }

    function onReset() {
        form.resetFields();
    }

    return (
        <>
            <Button type="primary" className="mt-2 mt-md-0"
                    onClick={showModal}><PlusOutlined/> {t("warehouse:label.create")}</Button>
            <Modal
                title={t("warehouse:title.create_warehouse").toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={600}
                footer={null}
                maskClosable={false}
            >
                <Form
                    className={"form"}
                    validateMessages={validateMessages}
                    size="default"
                    form={form}
                    labelCol={formItemLayout.labelCol}
                    wrapperCol={formItemLayout.wrapperCol}
                    colon=":"
                >
                    <Form.Item
                        className="mb-3"
                        name="code"
                        label={t('warehouse:label.code')}
                        rules={validatedRules.code}
                    >
                        <Input
                            name="code"
                            placeholder={t('warehouse:placeholder.code')}
                        />
                    </Form.Item>

                    <Form.Item
                        className="mb-3"
                        name="name"
                        label={t('warehouse:label.name')}
                        rules={validatedRules.name}
                    >
                        <Input
                            name="subject"
                            placeholder={t('warehouse:placeholder.name')}
                        />
                    </Form.Item>

                    <Location
                        validatedRules={validatedRules}
                        form={form}
                    />

                    <Form.Item
                        className="mb-3"
                        name="address"
                        label={t('warehouse:label.address')}
                        rules={validatedRules.address}
                    >
                        <Input
                            name="address"
                            placeholder={t('warehouse:placeholder.address')}
                        />
                    </Form.Item>

                    <Form.Item
                        className="mb-3"
                        name="description"
                        label={t('warehouse:label.note')}
                        rules={validatedRules.description}
                    >
                        <TextArea
                            name="description"
                            placeholder={t('warehouse:placeholder.note')}
                            style={{height: 100}}
                        />
                    </Form.Item>

                    <Form.Item
                        {...tailLayout}
                        name="is_main"
                        valuePropName="checked"
                    >
                        <Checkbox>{t("warehouse:label.is_main_warehouse")}</Checkbox>
                    </Form.Item>

                    <Row className="mt-3">
                        <Col span={18} offset={6}>
                            <Button
                                htmlType={"submit"}
                                type="primary"
                                onClick={handleSubmit}
                                loading={loading}
                                style={{textTransform: "uppercase"}}
                            >{t("common:btn.create")}</Button>
                            <Button
                                htmlType={"reset"}
                                className="ml-2"
                                onClick={onReset}
                                style={{textTransform: "uppercase"}}
                            >{t("common:btn.reset_form")}</Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
}

export default CreateWarehouse;
