import { Badge, Table } from 'antd'
import { get } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import { STATUS_FINANCE_CONTROL_DOCUMENT_COLORS } from '@Modules/Finance/services/constants'

import { t } from '@System/i18n'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

export default function List({ data, pagination, addFilter, filters, loading }) {
    const getColumns = [
        {
            className: '_finances-info',
            title: t('document:label.code'),
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => {
                const documentId = getVar(record, 'id', undefined)
                return (
                    <strong style={{ fontWeight: '500' }}>
                        <Link to={url.to('finances.control.detail', { id: documentId })}>{text}</Link>
                    </strong>
                )
            },
        },
        {
            className: '_finances-creator_username text-nowrap',
            title: t('document:label.creator_username'),
            dataIndex: 'creator_username',
            render: text => {
                return text
            },
        },

        {
            className: '_finances-created_at text-right',
            title: t('document:label.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => {
                return text ? <p>{moment(text).format('DD/MM/YYYY')}</p> : '---'
            },
        },
        {
            className: '_finances-collect-order_shipping_partner text-right',
            title: t('order:label.order_shipping_partner'),
            dataIndex: 'shipping_partner_name',
            key: 'shipping_partner_name',
            render: (text, record) => {
                return text ? text : '---'
            },
        },
        {
            className: '_finances-packing-status text-right',
            title: t('label.status'),
            dataIndex: 'status',
            key: 'status',
            render: text =>
                text ? (
                    <Badge
                        color={STATUS_FINANCE_CONTROL_DOCUMENT_COLORS[text]}
                        text={t(`finance:finance_control.status.${text}`)}
                    />
                ) : null,
        },
        {
            className: '_finances-shipping-verified_at text-right',
            title: t('document:label.verified_at'),
            dataIndex: 'verified_at',
            key: 'verified_at',
            render: (text, record) => {
                return text ? <p>{moment(text).format('DD/MM/YYYY')}</p> : '---'
            },
        },
        {
            className: '_finances-shipping-sum_total_amount text-right',
            title: t('finance:finance_control.label.sum_total_amount'),
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (text, record) => {
                const currency = get(record, 'currency')
                return !isEmpty(currency) ? formatCurrency(text || 0, currency) : text
            },
        },
        {
            className: '_finances-collect-remaining_amount text-right',
            title: t('finance:label.remaining_amount'),
            dataIndex: 'remaining_total_amount',
            key: 'remaining_total_amount',
            render: (text, record) => {
                const currency = get(record, 'currency')
                return !isEmpty(currency) ? formatCurrency(text || 0, currency) : text
            },
        },
        {
            className: '_finances-shipping-date_receipt_documents text-right',
            title: t('finance:label.date_receipt_documents'),
            dataIndex: 'received_date',
            key: 'received_date',
            render: (text, record) => {
                return text ? <p>{moment(text).format('DD/MM/YYYY')}</p> : '---'
            },
        },
    ]

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {`${t('finance:label.finance_document_control')} `}
                        <span className="number-circle _importing-document-product-list-total">{getVar(pagination, 'total', 0)}</span>
                    </h3>
                </div>

                <div className="data-box--body">
                    <Table
                        className="order-list-table pb-3"
                        dataSource={data}
                        rowKey={record => record?.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: <p>{t(`message.${loading ? 'loading' : 'no_data_order'}`)}</p>,
                        }}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                    />
                </div>
            </div>
        </div>
    )
}
