import {CheckCircleFilled} from '@ant-design/icons';
import {Avatar, Tooltip, Typography} from 'antd';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import unionBy from 'lodash/unionBy';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import useSize from '@Modules/App/Hooks/useSize';
import Space from '@Modules/App/Space';
import StyledTag from '@Modules/App/StyledTag';
import {SCAN_TYPE} from '@Modules/Document/constants';

import {t} from '@System/i18n';
import {Link} from '@System/routing';

import OrderExportingTable from '../../components/OrderExportingTable';

const {Paragraph, Text, Title} = Typography;

const OrderExportingList = ({checkedList, exportingDocument, notInDocumentList, orderExportings, uncheckedList}) => {
    const {isLarge} = useSize();
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: isLarge ? 250 : 10,
    });
    const [dataSource, setDataSource] = useState([]);
    const columns = useMemo(() => {
        const columns = [
            {
                className: '_index-column',
                title: t('table.#'),
                key: '#',
                render: (_, __, index) => (pagination?.current_page - 1) * pagination?.per_page + index + 1,
            },
            {
                className: '_freight-bill-column',
                title: t('freight_bill_code'),
                key: 'freight_bill',
                dataIndex: 'freight_bill',
            },
            {
                className: '_checked-column',
                key: 'checked',
                dataIndex:
                    exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER ? 'order_code' : 'freight_bill',
                render: text => (
                    <div className="d-flex text-fz-20">
                        {checkedList.includes(text) ? (
                            <CheckCircleFilled
                                style={{
                                    color: '#09b8af',
                                }}
                            />
                        ) : notInDocumentList.includes(text) ? null : (
                            <Tooltip title={text?.name}>
                                <Avatar className="flex-shrink-0" size={20}/>
                            </Tooltip>
                        )}
                    </div>
                ),
            },
            {
                className: '_order-column',
                title: t('order_code'),
                key: 'order_code',
                dataIndex: ['order', 'code'],
                render: (text, record) => {
                    const scan_type_order = exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER;
                    const code_not_in_document = notInDocumentList.includes(text);
                    return code_not_in_document ? (scan_type_order ? text : "") : <Link
                        isNewTab
                        params={{
                            id: record.order_id,
                        }}
                        to="orders.detail"
                    >
                        {text}
                    </Link>
                },
            },
            {
                className: '_sku-column',
                title: t('SKU'),
                key: 'SKU',
                dataIndex: 'order_exporting_items',
                render: text =>
                    map(unionBy(text, 'sku_id'), orderExportingItem => (
                        <Paragraph className="mb-0" key={orderExportingItem.id}>
                            <Link
                                isNewTab
                                params={{
                                    id: orderExportingItem.sku_id,
                                }}
                                to="products.detail"
                            >
                                {orderExportingItem.sku_code}
                            </Link>
                        </Paragraph>
                    )),
            },
            {
                className: '_quantity-column',
                title: t('quantity'),
                key: 'quantity',
                dataIndex: 'order_exporting_items',
                render: text =>
                    map(groupBy(text, 'sku_id'), orderExportingItems => (
                        <Paragraph className="mb-0" key={orderExportingItems[0].id}>
                            {sumBy(orderExportingItems, 'quantity')}
                        </Paragraph>
                    )),
            },
            {
                className: '_status-column',
                key: 'status',
                dataIndex:
                    exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER ? 'order_code' : 'freight_bill',
                render: text =>
                    notInDocumentList.includes(text) && (
                        <StyledTag color="#eff0f6">{t('document:code_not_in_document')}</StyledTag>
                    ),
            },
        ];

        if (exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER) {
            columns.splice(1, 1);
        }

        return columns;
    }, [checkedList, exportingDocument, notInDocumentList, pagination]);

    const handleChangePage = useCallback(
        params => {
            setPagination({
                ...pagination,
                ...params,
            });
        },
        [pagination]
    );

    function renderTitle() {
        if (exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.FREIGHT_BILL) {
            return t('freight_bill_list');
        }

        if (exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER) {
            return t('order_list');
        }

        return '';
    }

    useEffect(() => {
        setDataSource([
            ...notInDocumentList.map(item => ({
                id: `not-in-document-${item}`,
                freight_bill: item,
                order_code: item,
            })),
            ...orderExportings,
        ]);
    }, [notInDocumentList, orderExportings]);

    return (
        <div>
            <Space className="mb-3" type="flex">
                <Title className="mb-0" level={5}>
                    <Text className="mr-2">{renderTitle()}</Text>
                    <StyledTag className>{uncheckedList.length} {t("common:label.unscan")}</StyledTag>
                    <StyledTag type="success">{checkedList.length} {t("common:label.scanned")}</StyledTag>
                </Title>
            </Space>

            <OrderExportingTable
                columns={columns}
                dataSource={dataSource}
                exportingDocument={exportingDocument}
                pagination={pagination}
                onChangePage={handleChangePage}
            />
        </div>
    );
};

OrderExportingList.propTypes = {
    checkedList: PropTypes.array,
    orderExportings: PropTypes.array,
    uncheckedList: PropTypes.array,
};

OrderExportingList.defaultProps = {
    checkedList: [],
    orderExportings: [],
    uncheckedList: [],
};

export default OrderExportingList;
