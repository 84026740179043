import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Modal, Form } from 'antd';
import i18next from 'i18next';
import _ from 'lodash';
import React from 'react';
import readXlsxFile from 'read-excel-file';

import UploadFile from '@Modules/App/Upload/UploadFile';
import CustomizedAlert from '@Modules/Document/screens/Importing/Scan/CustomizedAlert';

import { t } from '@System/i18n';

export default function UploadSkus({ onScan, errors, onResetErrors, visible, setVisible, excelRef, form }) {
    function showModal(e) {
        e.preventDefault();
        onResetErrors();
        setVisible(true);
    }

    function handleOk() {
        setVisible(false);
    }

    function handleCancel() {
        onResetErrors();
        setVisible(false);
    }

    function handleUpload(file) {
        onResetErrors();
        readXlsxFile(file).then(rows => {
            let rowsNotTitle = _.drop(rows);
            let skuCodes = rowsNotTitle.map(formatBarcodes);
            skuCodes = skuCodes.filter(removeNullBarcodes);

            excelRef.current = rows;
            onScan(skuCodes);
        });
    }

    function formatBarcodes(item) {
        let [skuCode, quantity] = item;
        skuCode = skuCode ? skuCode.toString().trim() : '';
        quantity = Number.isInteger(parseInt(quantity)) ? parseInt(quantity) : 1;
        return [skuCode, quantity];
    }

    function removeNullBarcodes(item) {
        let [skuCode] = item;
        return skuCode !== '';
    }

    return (
        <>
            <span className={'mr-2'}>
                <Button
                    className="rounded-4 _importing_scan--import-btn"
                    onClick={showModal}
                    disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
                >
                    <CloudUploadOutlined /> {t('document:btn.import_sku')}
                </Button>
            </span>
            <Modal
                title={t('document:label.import_skus').toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
            >
                <p>
                    - {t('label.latest_at_update_sample')}: <strong>25/02/2021</strong>
                </p>
                <p>
                    - {t('label.download_sample_file')}:
                    <strong>
                        <a href={process.env.PUBLIC_URL + `/template/${i18next.language}/file_mau_import_sku_va_so_luong.xlsx`}>
                            {' '}
                            file_mau_import_sku_va_so_luong.xlsx
                        </a>
                    </strong>
                </p>
                <p className="mb-3">
                    - {t('label.maximum_file_size')}: <strong>5MB</strong>
                </p>
                <UploadFile
                    onChange={handleUpload}
                    accept=".xls,.xlsx"
                    fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                    name="file import sku"
                    maxFileSize={5}
                    showUploadList={true}
                    className="_importing_scan--upload-excel"
                />
                {visible && <CustomizedAlert title={'Không thể nhập mã'} onClose={onResetErrors} errors={errors} />}
            </Modal>
        </>
    );
}
