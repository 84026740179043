import React, {useEffect, useState} from "react";
import {Table, Checkbox, notification} from "antd";
import CustomizedPagination from "../../../App/CustomizedPagination";
import {t} from "../../../../system/i18n";
import TableEmpty from "../../../App/Utilities/TableEmpty";
import {dateFormatter} from "../../../App/services";
import {url} from "../../../../system/routing";
import {Link} from "react-router-dom";
import lodash from 'lodash';

export default function BillList({bills, pagination, addFilter, filters, loading}) {

    let getColumns = [
        {
            title: t("manageExporting:label.id"),
            dataIndex: 'id',
            key: 'id',
            className: "text-center",
            width: 180,
            render: (text, record) => {
                return (<Link to={url.to("detailed-bill", {id: record.id})}><strong style={{fontWeight: "500"}}>#{record.id}</strong></Link>);
            },
        },
        {
            title: t("manageExporting:label.warehouse_id"),
            dataIndex: "merchant.name",
            key: 'merchant.name',
            render: (text, record) => {
                let warehouse_code = lodash.get(record, "warehouse.code", "");
                let warehouse_name = lodash.get(record, "warehouse.name", "");
                if( warehouse_code === "default") warehouse_code = t("order:label.default");
                if (warehouse_name === "default") warehouse_name = t("order:label.default");
                return <p>{warehouse_name + (warehouse_code ? " (" + warehouse_code + ")" : "")}</p>
            },
        },
        {
            title: t("manageExporting:label.created_at"),
            dataIndex: "merchant.phone",
            key: 'merchant.phone',
            render: (text, record) => {
                return dateFormatter.formatDate(record.created_at, "DD/MM/YYYY HH:mm");
            },
        },
        {
            title: t("manageExporting:label.creator"),
            dataIndex: "merchant.phone",
            key: 'merchant.phone',
            render: (text, record) => {
                let {creator} = record;
                return creator.username + " - " + creator.name;
            },
        }
    ];

    return (
        <div className="m-3">
            <Table
                className="list-products-table pb-3"
                dataSource={bills}
                rowKey={"id"}
                columns={getColumns}
                pagination={false}
                loading={loading}
                locale={{emptyText: <TableEmpty loading={loading}/>}}
            />
            <CustomizedPagination
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
            />
        </div>
    );
}
