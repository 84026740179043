import { Badge, List, Space, Typography } from 'antd';
import { sumBy } from 'lodash';
import React from 'react';

import Box from '@Modules/App/Box';

import { t } from '@System/i18n';
import {  url } from '@System/routing';

import TableEmpty from '../../App/Utilities/TableEmpty';
import { Link } from 'react-router-dom';

const { Title } = Typography;

function TotalProducts({ className, data }) {
    return (
        <Box className={className}>
            <div className="d-flex justify-content-between border-bottom pb-3 align-items-center">
                <Space>
                    <Title level={3}>{t('order:title.list_products')}</Title>
                    <span className="number-circle">{data.length}</span>
                </Space>
            </div>
            <List
                className="ml-3 mr-3 _scan-order-products-info"
                header={
                    <div className="d-flex justify-content-between text-secondary">
                        <p>{t('order:label.sku_info')}</p>
                        <p>{t('order:label.quantity')}</p>
                    </div>
                }
                footer={
                    data.length > 0 ? (
                        <div className="d-flex justify-content-between">
                            <strong>Tổng</strong>
                            <strong className="_scan-order-products-total">{sumBy(data, 'quantity')}</strong>
                        </div>
                    ) : (
                        false
                    )
                }
                dataSource={data}
                renderItem={item => (
                    <List.Item key={item.sku_id}>
                        <List.Item.Meta
                            title={<Link to={url.to('products.detail', { id: item?.product_id })}>{item?.code}</Link>}
                            description={item?.name}
                        />
                        <div className="_scan-order-product-quantity">{item.quantity}</div>
                    </List.Item>
                )}
                locale={{ emptyText: <TableEmpty className="_scan-order-products-no-data" /> }}
            />
        </Box>
    );
}

export default TotalProducts;
