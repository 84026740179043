import { useQuery } from 'react-query';

import { t } from '@System/i18n';
import notification from '@System/notification';

import apiService from './../services/api';

const useProductsQuery = params =>
    useQuery(['products', params], () => apiService.getProducts(params), {
        retry: false,
        onError: error => {
            const status = error?.response?.status;
            if (status === 403) {
                notification.error(t('message.403'));
            } else if (status === 404) {
                notification.error(t('message.404'));
            } else {
                notification.error(t('message.fetch_fail'));
            }
        },
    });

export default useProductsQuery;
