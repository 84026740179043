import React from 'react';
import UploadFileForOrder from '../common/UploadFileForOrder';
import api from '../../services/api';
import {t} from '@System/i18n';

export default function UploadOrderFile() {
    return (
        <UploadFileForOrder
            btnType={"link"}
            btnTittle={t('order:btn.create_order_by_import')}
            modalTittle={t('order:label.import_order')}
            fileUpdatedAt='25/02/2021'
            fileName='file_mau_import_don_hang'
            fileNameAddress={'file_danh_sach_dia_chi'}
            fileLabel='file_mau_nhap_don_hang.xlxs'
            apiService={api.importOrder}
        />
    );
}
