import { CloseCircleFilled } from '@ant-design/icons';
import { Modal, Tag } from 'antd';
import React from 'react';

import { catchErrors } from '@Modules/App/services/helpers';

import { t } from '@System/i18n';

import api from '../../../../services/api';

export default function ValueItem({ value, removeValue }) {
    function deleteValue(event) {
        event.preventDefault();

        if (value.id) {
            checkDeleteValue();
        } else {
            removeValue();
        }
    }

    function checkDeleteValue() {
        api.checkDeleteValue({ optionsValueIds: [value.id] })
            .then(() => removeValue())
            .catch(catchErrors(t('message.delete_failed', { attribute: t('product:label.properties_value') }), showErrors));
    }

    function showErrors(errors) {
        if (errors.delete !== undefined && !errors.delete) {
            Modal.error({
                title: t('product:message.cant_delete'),
                content: t('product:message.delete_content'),
            });
        }
    }

    return (
        <Tag className="_product-option-tag-item mt-1 mb-1 mr-1" closable closeIcon={<CloseCircleFilled />} onClose={deleteValue}>
            {value.label}
        </Tag>
    );
}
