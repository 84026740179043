import { notification, Tooltip } from 'antd';
import { Avatar } from 'antd';
import _, { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import { SHIPPING_PARTNER_INFO } from '@Modules/App/services/constants';

import { getVar } from '@System/support/helpers';

import { events } from '../../../../../system/events';
import { t } from '../../../../../system/i18n';
import InlineEdit from '../../../../App/Utilities/InlineEdit';
import { catchErrors } from '../../../../App/services/helpers';
import { auth } from '../../../../Auth/services';
import PERMISSIONS from '../../../../Auth/services/permissions';
import SelectShippingPartner from '../../../../ShippingPartners/components/SelectShippingPartner';
import ServiceShippingPartners from '../../../../ShippingPartners/services/ServiceShippingPartners';
import api from '../../../services/api';
import { EVENTS } from '../../../services/constants';
import { ORDER_STATUS } from '../../../services/constants';

export default function EditShippingPartner({ shippingPartner, order, orderDetail }) {
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [shippingPartners, setShippingParnters] = useState([]);

    useEffect(() => {
        ServiceShippingPartners.list().then(res => {
            setShippingParnters(res);
        });
    }, []);

    function _onBlur() {
        setEdit(false);
    }

    function _onChange(shipping_partner_id) {
        setLoading(true);
        api.updateShippingPartner(order.id, { shipping_partner_id })
            .then(res => {
                let shipping_partner = shippingPartners.find(item => item.id === shipping_partner_id);
                if (shipping_partner)
                    events.dispatch(EVENTS.UPDATE_SHIPPING_PARTNER_SUCCESSFULL, {
                        ...orderDetail,
                        shippingPartner: shipping_partner,
                    });
                notification.success({ message: t('order:message.change_shipping_partner_success') });
            })
            .catch(catchErrors(t('order:message.change_shipping_partner_fail')))
            .finally(() => {
                setEdit(false);
                setLoading(false);
            });
    }
    function renderAvatarShipping() {
        const code = get(shippingPartner, 'code', '');
        const shipping_partner_info = get(SHIPPING_PARTNER_INFO, code, {});
        return (
            <Tooltip title={shippingPartner?.name}>
                <Avatar
                    style={{ backgroundColor: `${get(shipping_partner_info, 'color', '')}` }}
                    className="flex-shrink-0 mr-1 "
                    size={20}
                >
                    {get(shipping_partner_info, 'wildcard', '')}
                </Avatar>
            </Tooltip>
        );
    }
    return (
        <InlineEdit
            text={
                <>
                    {shippingPartner && renderAvatarShipping()}
                    {_.get(shippingPartner, 'name', t('noText'))}
                </>
            }
            isEdit={edit}
            setIsEdit={setEdit}
            can={
                orderDetail?.can_update_carrier &&
                ![ORDER_STATUS.DELIVERING, ORDER_STATUS.DELIVERED, ORDER_STATUS.CANCELED, ORDER_STATUS.FINISH].includes(
                    order.status
                ) &&
                auth.can(PERMISSIONS.ORDER_UPDATE)
            }
        >
            <SelectShippingPartner
                style={{ width: '100%' }}
                placeholder={t('order:placeholder.shipping_partner_id')}
                onBlur={_onBlur}
                autoFocus={true}
                value={_.get(shippingPartner, 'id', undefined)}
                onChange={_onChange}
                loading={loading}
                className="select-shipping-partner"
                location_id={getVar(orderDetail, 'merchant.location_id', undefined)}
            />
        </InlineEdit>
    );
}
