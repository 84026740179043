import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import useFilter from '@Modules/App/Hooks/useFilter'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import { TYPES } from '@Modules/Document/services/constants'
import CreateDocumentFinanceControl from '@Modules/Finance/screens/Control/Create'

import { t } from '@System/i18n'

import useListFinanceControlQuery from '../../../Hooks/useFinanceControlQuery'
import FormSearch from './FormSearch'
import List from './List'

const FinanceControl = ({ history, location }) => {
    const { filters, addFilter } = useFilter(history, location, {})
    const { data, isFetching, refetch, isError, error } = useListFinanceControlQuery(filters)

    const [documents, setDocuments] = useState([])
    const [pagination, setPagination] = useState({})

    useEffect(() => {
        setNavigator(t('menu.finances'), [
            {
                name: t('breadcrumb.order_financial_control'),
            },
        ])
        setDocumentTitle(t('title.order_financial_control'))
    }, [])

    useEffect(() => {
        refetch()
    }, [refetch])

    useEffect(() => {
        setDocuments(get(data, 'data.documents', []))
        setPagination(get(data, 'data.pagination', {}))
    }, [data])

    if (isError) {
        const status = get(error, 'response.status')
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    return (
        <>
            <div className="site-content">
                <div className="d-flex justify-content-between">
                    <h3 className="text-fz-18">{t('title.order_financial_control')}</h3>
                    {auth.can(permissions.FINANCE_CREATE_STATEMENT) && <CreateDocumentFinanceControl />}
                </div>
                <FormSearch
                    onSearch={addFilter}
                    filters={filters}
                    loading={isFetching}
                />
                <List
                    data={documents}
                    addFilter={addFilter}
                    filters={filters}
                    pagination={pagination}
                    loading={isFetching}
                    type={TYPES.TYPE_IMPORTING}
                />
            </div>
        </>
    )
}

export default FinanceControl
