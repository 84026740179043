import { CloudUploadOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import i18next from 'i18next'
import { drop, find, isEmpty } from 'lodash'
import React, { useCallback, useState } from 'react'
import readXlsxFile from 'read-excel-file'

import useToggle from '@Modules/App/Hooks/useToggle'
import UploadFile from '@Modules/App/Upload/UploadFileV2'
import api from '@Modules/Product/services/api'
import { PRODUCT_STATUS } from '@Modules/Product/services/constants'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

const AddProductByExcel = ({ existsData, setProducts }) => {
    const [file, setFile] = useState()
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)
    const [visible, { setLeft, setRight: showModal }] = useToggle(false)
    const [messages, setMessages] = useState([])

    function handleCancel() {
        setMessages([])
        setLeft()
        setFile(undefined)
        setFileList([])
    }

    const handleUpload = useCallback(file => {
        setMessages([])
        setFile(file)
    }, [])

    async function handleOk() {
        setLoading(true)
        await readXlsxFile(file).then(async rows => {
            const errors = []
            let rowsNotTitle = drop(rows)
            const addProducts = []
            if (rowsNotTitle.length > 0) {
                const skusExists = []
                const skuCodes = []
                const skuDuplicates = []
                rowsNotTitle.map(item => {
                    if (!skuCodes.includes(item[0])) {
                        skuCodes.push(item[0])
                    } else {
                        if (!skuDuplicates.includes(item[0])) {
                            skuDuplicates.push(item[0])
                        }
                    }
                })
                await api
                    .getSkus({ sku_codes: skuCodes, per_page: 1000, status: PRODUCT_STATUS.ON_SELL })
                    .then(res => {
                        const skus = getVar(res, 'data.skus', [])
                        skus.map(item => {
                            let { sku, product } = item
                            const image = getVar(product, 'images.0', '')
                            let objBatch = sku.is_batch ? { batch_of_goods: [{ id: undefined, quantity: 1 }] } : { quantity: 1 }
                            skusExists.push({
                                label: sku.name,
                                value: sku.id,
                                sku_id: sku.id,
                                sku_code: sku.code,
                                image,
                                ...objBatch,
                                is_batch: sku.is_batch,
                                sku_parent_id: sku.sku_parent_id,
                            })
                            return true
                        })
                    })
                    .catch(() => {
                        errors.push(t('common:message.server_error'))
                    })
                rowsNotTitle.map((item, index) => {
                    const skuCode = item[0]
                    if (skuCode) {
                        const quantity = item[1]
                        const exists = find(existsData, ['sku_code', skuCode.toString()])
                        const skuExists = find(skusExists, ['sku_code', skuCode.toString()])

                        if (isEmpty(exists)) {
                            if (!isEmpty(skuExists)) {
                                if (skuDuplicates.includes(skuCode)) {
                                    return errors.push(
                                        t('product:message.upload_file.line', {
                                            line: index + 2,
                                            content: t('product:message.sku_code_duplicated', {
                                                attribute: skuCode,
                                            }),
                                        })
                                    )
                                }

                                if (skuExists.sku_parent_id) {
                                    return errors.push(
                                        t('product:message.upload_file.line', {
                                            line: index + 2,
                                            content: t('product:message.invalid', {
                                                attribute: skuCode,
                                            }),
                                        })
                                    )
                                }

                                const reg = /^\+?([1-9]\d*)$/

                                if (reg.test(quantity)) {
                                    let objBatch = skuExists.is_batch ? { batch_of_goods: [{ id: undefined, quantity }] } : { quantity }
                                    addProducts.push({
                                        ...skuExists,
                                        ...objBatch,
                                    })
                                    return
                                } else {
                                    return errors.push(
                                        t('product:message.upload_file.line', {
                                            line: index + 2,
                                            content: t('product:message.upload_file.invalid', {
                                                attribute: t('label.quantity'),
                                            }),
                                        })
                                    )
                                }
                            }
                            return errors.push(
                                t('product:message.upload_file.line', {
                                    line: index + 2,
                                    content: t('product:message.upload_file.invalid', {
                                        attribute: `${t('product:label.sku_code')} ${skuCode}`,
                                    }),
                                })
                            )
                        }
                        return errors.push(
                            t('product:message.upload_file.line', {
                                line: index + 2,
                                content: t('product:message.added', {
                                    attribute: `${t('product:label.sku_code')} ${skuCode}`,
                                    info: t('product_list'),
                                }),
                            })
                        )
                    }
                    return errors.push(
                        t('product:message.upload_file.line', {
                            line: index + 2,
                            content: t('product:message.upload_file.required', {
                                attribute: `${t('product:label.sku_code')} ${skuCode}`,
                            }),
                        })
                    )
                })
            }

            if (addProducts.length > 0) {
                setProducts([...existsData, ...addProducts])
            }
            if (errors.length === 0) {
                handleCancel()
            } else {
                setMessages(errors)
            }
        })
        setLoading(false)
    }
    return (
        <>
            <Button
                type={'primary'}
                onClick={showModal}
                icon={<CloudUploadOutlined />}
                className="mr-2"
            >
                {t('btn.import_by_excel')}
            </Button>

            {visible && (
                <Modal
                    title={t('product:title.import_file_product')}
                    visible={true}
                    onCancel={handleCancel}
                    okText={t('btn.ok')}
                    cancelText={t('btn.cancel')}
                    footer={
                        <>
                            <Button onClick={handleCancel}>{t('btn.cancel')}</Button>

                            <Button
                                loading={loading}
                                type="primary"
                                onClick={handleOk}
                            >
                                {t('btn.ok')}
                            </Button>
                        </>
                    }
                    maskClosable={false}
                    width={650}
                >
                    <p>
                        - {t('label.latest_at_update_sample')}: <strong>16/05/2023</strong>
                    </p>
                    <p>
                        - {t('label.download_sample_file')}:
                        <strong>
                            <a href={process.env.PUBLIC_URL + `/template/${i18next.language}/sample_file_import_add_product_create_import_package.xlsx`}>
                                {' '}
                                {t('product:label.file_add_product')}
                            </a>
                        </strong>
                    </p>
                    <strong className="text-red">- {t('product:message.notice_limit_sku_in_file')}</strong>
                    <p className="mb-3">
                        - {t('label.maximum_file_size')}: <strong>5MB</strong>
                    </p>
                    <UploadFile
                        onChange={handleUpload}
                        accept=".xls,.xlsx"
                        fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                        name={t('product:label.file_add_product')}
                        maxFileSize={5}
                        showUploadList={true}
                        setFileList={setFileList}
                        fileList={fileList}
                    />
                    {messages.length > 0 && (
                        <div className="pt-3">
                            {messages.map((item, index) => {
                                return (
                                    <p
                                        key={index}
                                        className="text-danger"
                                    >
                                        - {item}
                                    </p>
                                )
                            })}
                        </div>
                    )}
                </Modal>
            )}
        </>
    )
}

export default AddProductByExcel
