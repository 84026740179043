import { CheckOutlined, LoadingOutlined, PlusOutlined, SaveFilled, SearchOutlined } from '@ant-design/icons'
import { AutoComplete, Avatar, Button, Input, Modal, Table } from 'antd'
import { debounce, filter, find, get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import useSkusQuery from '@Modules/Product/Hooks/useSkusQuery'
import { PRODUCT_STATUS } from '@Modules/Product/services/constants'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

const AddProduct = ({ existsData, setProducts, addProduct }) => {
    const [visible, setVisible] = useState(false)
    const [filters, setFilters] = useState({ status: PRODUCT_STATUS.ON_SELL, per_page: 20 })
    const [options, setOptions] = useState([])
    const [keyword, setKeyword] = useState()
    const [page, setPage] = useState({})
    const { data, isFetching, refetch } = useSkusQuery(filters)

    function handleCancel() {
        setVisible(false)
        setFilters({ status: PRODUCT_STATUS.ON_SELL, per_page: 20 })
        setKeyword(undefined)
    }

    useEffect(() => {
        refetch(filters)
    }, [filters])

    useEffect(() => {
        setOptions(filter(get(data, 'data.skus', []), item=>!item?.sku?.sku_parent_id))
        setPage(get(data, 'data.pagination', {}))
    }, [data])

    const _onSearch = useCallback(
        debounce(value => {
            if (value !== '' && value !== null && value !== undefined) {
                setFilters({ keyword: value, status: PRODUCT_STATUS.ON_SELL, page: 1 })
            } else {
                setFilters({ status: PRODUCT_STATUS.ON_SELL, page: 1 })
            }
        }, 300),
        []
    )

    const getOptions = option => {
        let { sku } = option
        const image = getVar(sku, 'images.0', '')
        return {
            label: sku.name,
            value: sku.id,
            sku_id: sku.id,
            sku_code: sku.code,
            image,
            is_batch: sku.is_batch,
        }
    }

    function _onChange(value) {
        setKeyword(value ? value : undefined)
    }

    const onSearch = page => {
        setFilters({ ...page, keyword, status: PRODUCT_STATUS.ON_SELL })
    }

    const handleOpenModal = () => {
        setVisible(true)
    }

    let getColumns = [
        {
            className: '_list-product-name',
            dataIndex: 'label',
            key: 'label',
            render: (text, record) => {
                const image = getVar(record, 'image', '')
                return (
                    <div className="d-flex align-items-center">
                        <div>
                            <Avatar
                                src={image}
                                shape="square"
                                className="mr-2"
                                size={36}
                            />
                        </div>
                        <div>
                            <span>
                                <strong style={{ fontWeight: 500, whiteSpace: 'break-spaces' }}>{text}</strong>
                            </span>
                        </div>
                    </div>
                )
            },
        },
        {
            className: '_list-product-sku-code',
            dataIndex: 'sku_code',
            key: 'sku_code',
        },
        {
            className: 'text-right _list-product-action',
            key: 'action',
            fixed: 'right',
            width: '140px',
            render: (text, record) => {
                const existsSku = find(existsData, ['sku_id', record.sku_id])
                if (existsSku) {
                    return (
                        <Button
                            className="_list-product-action-disabled-added"
                            type="primary"
                            disabled
                            icon={<CheckOutlined />}
                        >
                            {t('btn.added')}
                        </Button>
                    )
                } else {
                    return (
                        <Button
                            className="_list-product-action-added"
                            type="primary"
                            onClick={() => addProduct(record)}
                            icon={<PlusOutlined />}
                        >
                            {t('btn.plus')}
                        </Button>
                    )
                }
            },
        },
    ]

    const dataSource = options.map(getOptions)

    return (
        <>
            <Button
                type={'primary'}
                className={`ml-2 _btn-add-product`}
                onClick={handleOpenModal}
                icon={<PlusOutlined />}
            >
                {t('product:btn.add_product')}
            </Button>
            {visible && (
                <Modal
                    className="_modal-search-product"
                    title={t('product:btn.add_product')}
                    visible={visible}
                    onCancel={handleCancel}
                    width={800}
                    footer={[
                        <Button
                            className="_modal-btn-finished"
                            type={'primary'}
                            key={'ok'}
                            onClick={handleCancel}
                            icon={<SaveFilled />}
                        >
                            {t('btn.finished')}
                        </Button>,
                    ]}
                >
                    <AutoComplete
                        className="_search-product-autocomplete"
                        options={false}
                        style={{ width: '100%' }}
                        onSearch={_onSearch}
                        onChange={_onChange}
                        value={keyword}
                    >
                        <Input
                            className="_input-search-product-"
                            size={'large'}
                            placeholder={t('product:placeholder.search_product_by_name_code')}
                            prefix={
                                isFetching ? <LoadingOutlined style={{ color: 'rgba(0,0,0,.45)' }} /> : <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                            }
                            allowClear={true}
                        />
                    </AutoComplete>
                    <Table
                        showHeader={false}
                        className="add-product-import-package pb-2 mt-3"
                        dataSource={dataSource}
                        rowKey={record => record.sku_id}
                        columns={getColumns}
                        loading={isFetching}
                        pagination={false}
                        scroll={{ x: 576 }}
                        locale={{
                            emptyText: <p>{t('message.no_data_product')}</p>,
                        }}
                    />
                    <CustomizedPagination
                        pagination={page}
                        addFilter={onSearch}
                        filters={filters}
                    />
                </Modal>
            )}
        </>
    )
}

export default AddProduct
