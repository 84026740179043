import React, {forwardRef} from "react";
import PropTypes from "prop-types";
import lodash from "lodash";

import {dateFormatter} from "../../../../App/services";
import {trans, t} from "../../../../../system/i18n";
import {SCAN_TYPE} from "../../../constants";
import {calculatorTotalSku} from "../../../../ScanOrder/components/customerFunction";

const ExportingDetailToSprint = forwardRef(({exportingDocument}, ref) => {
    const orderExportings = lodash.get(exportingDocument, "order_exportings", []);
    const barcode_type = lodash.get(exportingDocument, "document_exporting.info.barcode_type", "");

    return (
        <div className="A4 print-exporting-detail" ref={ref}>
            <div className="p-4">
                <div className="d-flex justify-content-between mb-0 border-bottom pb-4">
                    <div>
                        <div>
                            <b>{trans('document:label.tmp_code')}: #{exportingDocument?.document_exporting?.code}</b>
                        </div>
                        <div>
                            {trans('document:created_by', {
                                date: dateFormatter.date(exportingDocument?.document_exporting?.created_at),
                                confirmer: exportingDocument?.document_exporting?.creator_username || '--'
                            })}
                        </div>
                    </div>
                    <div className="ml-auto">
                        {dateFormatter.full(Date.now())}
                    </div>
                </div>
                <div className="pt-4 pb-4">
                    <div className="pb-2">
                        <b>{trans('receiver_info')}</b>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col">{trans('full_name')}</div>
                                <div className="col">{exportingDocument?.document_exporting?.info?.receiver_name}</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col">
                                    {trans('document:placeholder.license')}
                                </div>
                                <div
                                    className="col">{exportingDocument?.document_exporting?.info?.receiver_license}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="row">
                                <div className="col">{trans('phone')}</div>
                                <div className="col">{exportingDocument?.document_exporting?.info?.receiver_phone}</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col">{trans('receive_company')}</div>
                                <div className="col">{exportingDocument?.document_exporting?.info?.partner}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="pb-2 border-bottom">
                        <b className="pb-4">
                            {barcode_type === SCAN_TYPE.FREIGHT_BILL ? trans('freight_bill_list') : trans('order_list')}
                        </b>
                        <div className="row">
                            <div className="col-10">
                                <b style={{fontWeight: 500}}>
                                    {barcode_type === SCAN_TYPE.FREIGHT_BILL ? trans('document:label.total_freight_bill') : trans('document:label.total_order')}
                                </b>
                            </div>
                            <div className="col-2 text-right">{orderExportings.length}</div>
                        </div>
                    </div>
                    <div className="pt-2 pb-2 border-bottom">
                        <div className="row">
                            <div className="col-4">
                                <strong style={{fontWeight: 500}}>{trans("order_code")}</strong>
                            </div>
                            <div className="col-6">
                                <strong style={{fontWeight: 500}}>{trans("freight_bill_code")}</strong>
                            </div>
                            <div className="col-2 text-right"><strong style={{fontWeight: 500}}>{trans("quantity")}</strong></div>
                        </div>
                    </div>
                    {
                        orderExportings.map((item, index) => {
                            const orderExportingItems = calculatorTotalSku(lodash.get(item, "order_exporting_items", []));
                            return <div className="pt-2 pb-2 border-bottom" key={index}>
                                <div className="row">
                                    <div className="col-4">
                                        <strong style={{fontWeight: 500}}>
                                            {lodash.get(item, "order_code", "")}
                                        </strong>
                                    </div>
                                    <div className="col-6">
                                        <strong style={{fontWeight: 500}}>
                                            {lodash.get(item, "freight_bill", "")}
                                        </strong>
                                    </div>
                                    <div className="col-2"/>
                                </div>
                                {
                                    orderExportingItems.map((sku, idx) => {
                                        return <div className="row sku-info" key={idx}>
                                            <div className="col-4 sku-code">
                                                {lodash.get(sku, "sku_code", "")}
                                            </div>
                                            <div className="col-6">{lodash.get(sku, "sku_name", "")}</div>
                                            <div className="col-2 text-right">{lodash.get(sku, "quantity", "")}</div>
                                        </div>
                                    })
                                }
                            </div>
                        })
                    }

                </div>
                <div className="pt-4">
                    <div className="row pb-5 text-center">
                        <div className="col-6">{t("document:label.exported_staff")}</div>
                        <div className="col-6">{t("document:label.received_staff")}</div>
                    </div>
                    <div className="row text-center">
                        <div className="col-6 font-italic">({t("document:label.signature_fullname")})</div>
                        <div className="col-6 font-italic">({t("document:label.signature_fullname")})</div>
                    </div>
                </div>
            </div>
        </div>
    )
});
ExportingDetailToSprint.propTypes = {
    exportingDocument: PropTypes.object,
};

ExportingDetailToSprint.defaultProps = {
    exportingDocument: {},
};

export default ExportingDetailToSprint;
