import { Badge, Table } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { STATUS_FREIGHT_BIll_INVENTORY_COLORS } from '@Modules/Finance/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

function ListOfLading({ dataSource, loading }) {
    const columns = [
        {
            className: '_list-of-lading-freight-bill-code',
            title: t('label.freight_bill_code'),
            dataIndex: 'freight_bill_code',
            key: 'freight_bill_code',
        },
        {
            className: '_list-of-lading-cod-total-amount',
            title: t('finance:finance_control.label.cod_total_amount'),
            dataIndex: 'cod_total_amount',
            key: 'cod_total_amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_list-of-lading-cod-paid-amount',
            title: t('finance:finance_control.label.cod_paid_amount'),
            dataIndex: 'cod_paid_amount',
            key: 'cod_paid_amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_list-of-lading-cod-fee-amount',
            title: t('finance:finance_control.label.cod_fee_amount'),
            dataIndex: 'cod_fee_amount',
            key: 'cod_fee_amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_list-of-lading-shipping-amount',
            title: t('finance:finance_control.label.shipping_amount'),
            dataIndex: 'shipping_amount',
            key: 'shipping_amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_list-of-lading-shipping-amount',
            title: t('finance:finance_control.label.service_fee_amount'),
            dataIndex: 'extent_amount',
            key: 'extent_amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return !isEmpty(currency) ? formatCurrency(text || 0, currency) : '';
            },
        },
        {
            className: '_list-of-lading-shipping-amount',
            title: t('label.status'),
            dataIndex: 'status',
            key: 'status',
            render: text =>
                text ? (
                    <Badge
                        color={STATUS_FREIGHT_BIll_INVENTORY_COLORS[text]}
                        text={t(`finance:finance_control.status_freight_bill_inventory.${text}`)}
                    />
                ) : null,
        },
        {
            className: '_list-of-lading-total-amount',
            title: t('finance:finance_control.label.total_amount'),
            dataIndex: 'total_amount',
            key: 'total_amount',
            render: (text, record) => {
                const currency = getVar(record, 'currency', {});
                return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : '';
            },
        },
        {
            className: '_list-of-lading-cod-warning',
            title: t('finance:finance_control.label.warning'),
            dataIndex: 'warning',
            key: 'warning',
            render: text => {
                return text ? t('finance:message.warning_document') : '';
            },
        },
    ];
    return (
        <Table
            scroll={{ x: 576 }}
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            rowKey={record => record.freight_bill_code}
            locale={{ emptyText: <TableEmpty loading={loading} /> }}
        />
    );
}

export default ListOfLading;
