import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import apiService from './../services/api';

const useProductHistoryQuery = productId =>
    useQuery(['product-history', productId], () => apiService.getProductHistory(productId), {
        enabled: !!productId,
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });

export default useProductHistoryQuery;
