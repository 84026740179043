import React, {useState, useEffect} from 'react';
import {message} from 'antd';
import {api} from '../services';
import Error403 from '../../App/Error/403';

export default function Login() {
    const [error, setError] = useState(false);

    useEffect(login, []);

    function login() {
        const hideLoading = message.loading('Loading...', 0);

        api.login({domain: window.location.host})
            .then(res => window.location = res.data.url)
            .catch(() => setError(true))
            .finally(() => hideLoading());
    }

    return error ? <Error403/> : null;
}
