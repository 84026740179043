import { Badge, Table, Typography } from 'antd'
import { get, isEmpty, isNil } from 'lodash'
import moment from 'moment/moment'
import React from 'react'
import { Link } from 'react-router-dom'
import { v4 } from 'uuid'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import { TFinanceDocumentStatus, TFinanceShippingStatus } from '@Modules/Finance/Interfaces/interfaces'
import ExportDebtsShipping from '@Modules/Finance/components/ExportDebtsShipping'
import { DOCUMENT_CONTROL_STATUS, FINANCE_SHIPPING_STATUS_COLOR, STATUS_FINANCE_CONTROL_DOCUMENT_COLORS } from '@Modules/Finance/services/constants'
import { TOrderStatus } from '@Modules/Order/Interfaces/interfaces'
import { ORDER_STATUS_COLORS } from '@Modules/Order/services/constants'

import { t } from '@System/i18n'
import { formatCurrency } from '@System/support/numberFormat'

interface IListProps {
    loading: boolean
    orders: object[]
    pagination: object
    addFilter: any
    filters: object
    currency: any
}

const { Paragraph } = Typography

const List: React.FC<IListProps> = props => {
    const { filters, pagination, orders, loading, addFilter, currency } = props

    const columns = [
        {
            className: '_finances-order-id',
            title: t('order:label.order_id'),
            dataIndex: ['order', 'code'],
            key: 'order_code',
            render: (text: string, record: any) => {
                return (
                    <Paragraph
                        copyable={{
                            text: text,
                        }}
                    >
                        <Link to={`/orders/${record?.order?.id}`}>{text}</Link>
                    </Paragraph>
                )
            },
        },
        {
            className: '_finances-freight-bill',
            title: t('order:label.freight_bill'),
            dataIndex: ['order', 'freight_bill'],
            key: 'freight_bill',
        },
        {
            className: '_finances-cod_obtained text-nowrap',
            title: t('finance:label.cod_obtained'),
            dataIndex: ['order', 'paid_amount'],
            key: 'cod_obtained',
            render: (text: any) => {
                return !isNil(text) ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : t('noText')
            },
        },

        {
            className: '_finances-cod_fee_amount text-right',
            title: t('finance:finance_control.label.cod_fee_amount'),
            dataIndex: ['order', 'cod_fee_amount'],
            key: 'cod_fee_amount',
            render: (text: any) => {
                return !isNil(text) ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : t('noText')
            },
        },
        {
            className: '_finances-shipping_amount text-right',
            title: t('finance:finance_control.label.shipping_amount'),
            dataIndex: ['order', 'shipping_amount'],
            key: 'shipping_amount',
            render: (text: any) => {
                return !isNil(text) ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : t('noText')
            },
        },
        {
            className: '_finances-other_fee text-right',
            title: t('finance:finance_control.label.other_fee'),
            dataIndex: ['order', 'other_fee'],
            key: 'other_fee',
            render: (text: any) => {
                return !isNil(text) ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : t('noText')
            },
        },
        {
            className: '_finances-order_status text-right',
            title: t('order:label.order_status'),
            dataIndex: ['order', 'status'],
            key: 'order_status',
            render: (text: TOrderStatus) => {
                return text ? (
                    <Badge
                        color={ORDER_STATUS_COLORS[text]}
                        text={t(`order:status.${text}`)}
                    />
                ) : (
                    t('noText')
                )
            },
        },
        {
            className: '_finances-documents_control_status text-right',
            title: t('finance:label.documents_control_status'),
            dataIndex: ['document', 'status'],
            key: 'documents_control_status',
            render: (status: TFinanceDocumentStatus) => {
                return status ? (
                    <Badge
                        color={STATUS_FINANCE_CONTROL_DOCUMENT_COLORS[status]}
                        text={t(`finance:finance_control.status.${status}`)}
                    />
                ) : (
                    t('noText')
                )
            },
        },
        {
            className: '_finances-finance_shipping_status text-right',
            title: t('finance:label.finance_shipping_status'),
            dataIndex: ['order', 'shipping_financial_status'],
            key: 'shipping_financial_status',
            render: (status: TFinanceShippingStatus) => {
                return status ? (
                    <Badge
                        color={FINANCE_SHIPPING_STATUS_COLOR[status]}
                        text={t(`finance:FINANCE_SHIPPING_STATUS.${status}`)}
                    />
                ) : (
                    t('noText')
                )
            },
        },
        {
            className: '_finances-bill_lading_date text-right',
            title: t('finance:label.bill_lading_date'),
            dataIndex: ['order', 'freight_bill_created_at'],
            key: 'freight_bill_created_at',
            render: (text: string) => {
                return text ? moment(text).format('DD/MM/YYYY HH:mm') : t('noText')
            },
        },
    ]

    const inventoryDocumentStatus = get(filters, 'inventory_document_status', '')

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {`${t('order:title.order_list')} `}
                        <span className="number-circle _importing-document-product-list-total">{get(pagination, 'total', 0)}</span>
                    </h3>

                    {inventoryDocumentStatus === DOCUMENT_CONTROL_STATUS.NONE   && <ExportDebtsShipping filters={filters} />}
                </div>
                <div className="data-box--body">
                    <Table
                        className="order-list-table pb-3"
                        dataSource={orders}
                        rowKey={record => v4()}
                        columns={columns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: <p>{t(`message.${loading ? 'loading' : 'no_data'}`)}</p>,
                        }}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                    />
                </div>
            </div>
        </div>
    )
}

export default List
