import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";

class ServiceUnits extends BaseServiceData {

    runApi() {
        return api.getUnits()
            .then(res => _.get(res, "data.units", []))
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("common:label.product_unit").toLowerCase()})});
                return Promise.reject(err);
            });
    }
}

export default new ServiceUnits();
