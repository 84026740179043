import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useStocksQuery = (params, settings) =>
    createUseQuery({
        name: ['stocks', params],
        query: () => apiService.getStocks(params),
        settings,
    });

export default useStocksQuery;
