import React, {useState} from "react";
import {notification, Button, Form, Input, Row, Col} from "antd";
import {MinusCircleOutlined, PlusOutlined} from "@ant-design/icons";
import api from "../../services/api";
import lodash from "lodash";
import SelectCategories from "../../../ProductCategories/components/SelectCategories";
import SelectUnits from "../../../ProductUnits/components/SelectUnits";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import {t} from "../../../../system/i18n";
import PERMISSIONS from "../../../Auth/services/permissions";
import {auth} from "../../../Auth/services";

const layoutLeft = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};
const layoutRight = {
    labelCol: {span: 4},
    wrapperCol: {span: 16},
};

const tailLayout = {
    wrapperCol: {offset: 10, span: 14},
};
const VALIDATE_UPDATE_PRODUCT = {
    code: [{required: true}],
    name: [{required: true}],
};

export default function Detail({skuInfo, setSkuInfo}) {
    const [form] = Form.useForm();
    const productId = lodash.get(skuInfo, "sku.id", undefined);
    const validateMessages = useValidatedMessages();
    const initialValues = getInitialValues();
    const [loading, setLoading] = useState(false);

    function validateSkuPrices() {
        let error_messages = form.getFieldsError();
        const sku_prices = form.getFieldValue('sku_prices');
        let merchants = [];
        let hasError = false;
        sku_prices.forEach((element, index) => {
            let {merchant_id, cost_price, wholesale_price, retail_price} = element || {};
            if (merchant_id) {
                if (merchants.includes(merchant_id)) {
                    hasError = true;
                    error_messages.push({
                        name: ['sku_prices', index, 'merchant_id'],
                        errors: [t("product:message.not_duplicate_seller")]
                    });
                } else {
                    error_messages.push({name: ['sku_prices', index, 'merchant_id'], errors: null});
                }
                merchants.push(merchant_id);
            } else {
                if (cost_price >= 0 || wholesale_price  >= 0 || retail_price  >= 0) {
                    hasError = true;
                    error_messages.push({
                        name: ['sku_prices', index, 'merchant_id'],
                        errors: [t("validation:required", {attribute: t("order:label.merchant_id")})]
                    });
                }
            }
        });

        form.setFields(error_messages);
        return !hasError;
    }

    const handleOk = () => {
        form.validateFields()
            .then(values => {
                const result = validateSkuPrices([]);
                if (result) {
                    let newOptions = {};
                    let sku_prices = undefined;
                    for (let prop in values) {
                        if (values[prop] !== undefined) {
                            if (prop === "options") {
                                const options = values[prop];
                                if (options.length > 0) {
                                    options.forEach((element, index) => {
                                        const properties_name = lodash.get(element, "properties_name", null);
                                        const properties_value = lodash.get(element, "properties_value", null);
                                        if (properties_name) {
                                            newOptions[properties_name] = properties_value;
                                        }
                                    });
                                }
                            }
                            if (prop === "sku_prices") {
                                const prices = lodash.remove(values[prop], function (sku_price) {
                                    return sku_price.merchant_id
                                });
                                sku_prices = prices.length > 0 ? prices : undefined;
                            }
                        }
                    }
                    form.setFields([{
                        name: 'sku_prices',
                        value: sku_prices && sku_prices.length > 0 ? sku_prices : [{merchant_id: ""}]
                    }]);
                    setLoading(true);
                    api.updateSku(productId, {...values, options: newOptions, sku_prices}).then(res => {
                        setSkuInfo(lodash.get(res, "data.sku"));
                        notification.success({message: t('common:message.update_success', {attribute: t("common:label.products")})});
                    }).catch(err => {
                        let {response} = err;
                        if (response.status === 400) {
                            notification.error({message: t('common:message.update_failed', {attribute: t("common:label.products")})});
                            showErrors(response);
                        } else if (response.status === 403)
                            notification.error({message: t('common:message.403')});
                        else if (response.status === 404)
                            notification.error({message: t('common:message.404')});
                        else
                            notification.error({message: t('common:message.update_failed', {attribute: t("common:label.products")})});
                    }).finally(() => setLoading(false))
                }
            }).catch(() => {
            validateSkuPrices();
        })
    };

    function showErrors(response) {
        let errors = lodash.get(response, "data.data", {});
        let error_messages = [];
        Object.entries(errors).forEach(entry => {
            const [key, error] = entry;
            for (let prop in error) {
                if (error.hasOwnProperty(prop)) {
                    error_messages.push({
                        name: key,
                        errors: [t(`product:message.${prop.toLowerCase()}`, {
                            attribute: t(`product:label.${key}`)
                        })]
                    });
                }
            }

        });

        form.setFields(error_messages);
    }

    function getInitialValues() {
        let newOptions = [];
        const sku = lodash.get(skuInfo, "sku", {});
        if (!lodash.isEmpty(skuInfo)) {
            let options = lodash.get(skuInfo, "sku.options", []);
            if (typeof options === "string") {
                options = JSON.parse(options);
            }
            if (!lodash.isEmpty(options)) {
                Object.entries(options).forEach(entry => {
                    const [key, value] = entry;
                    newOptions.push({"properties_name": key, "properties_value": value})
                });
            }
        }

        return {...sku, options: newOptions};
    }

    const handleCancel = () => {
        form.resetFields();
    };

    if (lodash.isEmpty(skuInfo))
        return null;


    return (
        <Form
            {...layoutLeft}
            form={form}
            validateMessages={validateMessages}
            initialValues={initialValues}
            className="mt-4"
        >
            <Row gutter={24}>
                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item label={t("product:label.sku_code")} name="code">
                        <Input placeholder={t("product:placeholder.sku_code")} disabled={true}
                               style={{color: "#2f2d2d"}}/>
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item label={t("product:label.name")} name="name"
                               rules={VALIDATE_UPDATE_PRODUCT.name} {...layoutRight}>
                        <Input placeholder={t("product:placeholder.name")}/>
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item label={t("product:label.barcode")} name="barcode">
                        <Input placeholder={t("product:placeholder.barcode")}/>
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item label={t("product:label.unit_name")} name="unit_id" {...layoutRight}>
                        <SelectUnits placeholder={t("product:placeholder.unit_id")}/>
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item label={t("product:label.category_id")} name="category_id">
                        <SelectCategories placeholder={t("product:placeholder.category_id")}/>
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item label={t("product:label.color")} name="color" {...layoutRight}>
                        <Input placeholder={t("product:placeholder.color")}/>
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item label={t("product:label.size")} name="size">
                        <Input placeholder={t("product:placeholder.size")}/>
                    </Form.Item>
                </Col>

                <Col xs={{span: 24}} lg={{span: 12}}>
                    <Form.Item label={t("product:label.classify")} name="type" {...layoutRight}>
                        <Input placeholder={t("product:placeholder.type")}/>
                    </Form.Item>
                </Col>

                <Col span={24} className="pl-2">
                    <Form.Item
                        label={t("product:label.description")}
                        name="description"
                        labelCol={{span: 4}}
                        wrapperCol={{span: 18}}
                    >
                        <Input.TextArea autoSize={{minRows: 3, maxRows: 5}}
                                        placeholder={t("product:placeholder.description")}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.List name="options">
                        {(fields, {add, remove}) => (
                            <>
                                {
                                    fields.map((field, index) =>
                                        (
                                            <Form.Item
                                                labelCol={{span: 4}}
                                                wrapperCol={{span: 18}}
                                                label={t("product:label.properties") + " " + (index + 1)}
                                                required={false}
                                                key={field.key}
                                                className="mb-0"
                                            >
                                                <Row gutter={10}>
                                                    <Col span={11}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'properties_name']}
                                                            fieldKey={[field.fieldKey, 'properties_name']}
                                                        >
                                                            <Input placeholder={t("product:label.properties_name")}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Form.Item
                                                            {...field}
                                                            name={[field.name, 'properties_value']}
                                                            fieldKey={[field.fieldKey, 'properties_value']}
                                                            className="ml-2"
                                                        >
                                                            <Input placeholder={t("product:label.properties_value")}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1}>
                                                        <div className="ml-2 float-right">
                                                            <MinusCircleOutlined onClick={() => remove(field.name)}/>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Form.Item>
                                        )
                                    )
                                }
                                <Form.Item wrapperCol={{offset: 4, span: 18}} className="mb-0">
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}>
                                        {t("product:label.add_properties")}
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>

            {/*<SkuPrices*/}
                {/*form={form}*/}
                {/*validateSkuPrices={validateSkuPrices}*/}
                {/*skuInfo={skuInfo}*/}
            {/*/>*/}
            {
                auth.can(PERMISSIONS.PRODUCT_UPDATE) &&
                <Form.Item {...tailLayout}>
                    <Button onClick={handleOk} loading={loading} type={"primary"}>{t("common:btn.update")}</Button>
                    <Button className="ml-2" onClick={handleCancel}>{t("common:btn.cancel")}</Button>
                </Form.Item>
            }

        </Form>
    );
}
