import React from "react";
import {Radio} from "antd";
import {t} from "../../../system/i18n";
import {PAYMENT_TYPE} from "../services/constants";

const radioStyle = {
    display: 'inline-block',
    height: '30px',
    lineHeight: '30px',
    marginRight: '20px'
};

export default function RadioPaymentType(props) {
    return (
        <Radio.Group {...props}>
            {
                Object.keys(PAYMENT_TYPE).map((opt, index) => {
                    return <Radio
                        key={index}
                        style={radioStyle}
                        value={PAYMENT_TYPE[opt]}
                    >{t(`order:payment_type.${PAYMENT_TYPE[opt]}`)}</Radio>;
                })
            }
        </Radio.Group>
    );
}
