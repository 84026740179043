import { Button, Modal, Form, Input } from 'antd';
import { map, get, find } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import InputNumber from '@Modules/App/Utilities/InputNumber';
import api from '@Modules/Order/services/api';
import warehouseAreaApi from '@Modules/WarehouseArea/services/api';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

const PickingUpProduct = ({ setVisible, visible, totalOrders, warehouseId }) => {
    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const [form] = Form.useForm();
    const inputRef = useRef();
    useEffect(() => {
        fetchDevices();
    }, []);
    const handleSubmit = () => {
        form.validateFields().then(values => {
            const { order_number, device_code } = values;
            const deviceId = getVar(find(devices, ['warehouseArea.code', device_code]), 'warehouseArea.id', '');
            setLoading(true);
            api.createPickingSession({ order_number, warehouse_area_id: deviceId })
                .then(res => {
                    const sessionId = getVar(res, 'data.picking_session.id', undefined);
                    url.redirectTo('orders.picking-up', {
                        sessionId,
                    });
                    handleClose();
                })
                .catch(err => {
                    const status = getVar(err, 'response.status', 0);
                    const code  = getVar(err, 'response.data.code', '')

                    if(code === "EXCEPTION" || status === 500){
                        return notification.error(t('server_error'));
                    }

                    if (status === 400) {
                        renderError(getVar(err, 'response.data.data', {}));
                    } else {
                        notification.error(t('message.fetch_fail', { attribute: t('order:label.picking_up_info') }));
                    }
                })
                .finally(() => setLoading(false));
        });
    };

    function renderError(errors) {
        const fields_enable = ['order_number', 'device_code'];
        const error_messages = [];
        Object.keys(errors).forEach(item => {
            let key_errors = [];
            Object.keys(errors[item]).forEach(error => {
                if (item === 'warehouse_area_id') item = 'scan_device_code';
                key_errors.push(t(`order:message.${error}`, { attribute: t(`order:label.${item}`) }));
            });

            if (fields_enable.includes(item)) {
                error_messages.push({ name: item, errors: key_errors });
            } else {
                notification.error(key_errors.join(', '));
            }
        });
        form.setFields(error_messages);
    }

    function fetchDevices() {
        return warehouseAreaApi
            .getWarehouseAreas(warehouseId, { movable: 1 })
            .then(res => {
                setDevices(get(res, 'data.areas', []));
            })
            .catch(err => {
                processResponseError(err);
            });
    }

    const setFocus = isOpen => {
        isOpen && inputRef.current.focus();
    };

    function handleClose() {
        setVisible(false);
        form.resetFields();
    }
    return (
        <>
            {visible && (
                <Modal
                    title={t('order:title.start_picking_order')}
                    onClose={handleClose}
                    onCancel={() => {
                        setVisible(false);
                    }}
                    visible={visible}
                    afterVisibleChange={setFocus}
                    width={600}
                    footer={false}
                >
                    <Form form={form} layout="vertical" onFinish={handleSubmit}>
                        <Form.Item
                            label={t('order:label.scan_device_code')}
                            name="device_code"
                            rules={[
                                {
                                    required: true,
                                    message: t('order:message.required', {
                                        attribute: t('order:placeholder.device_code'),
                                    }),
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value || map(devices, 'warehouseArea.code').includes(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(
                                                t('order:message.not_exist', {
                                                    attribute: t('order:label.scan_device_code'),
                                                })
                                            )
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                ref={inputRef}
                                placeholder={t('order:placeholder.device_code')}
                                style={{ width: '100%' }}
                                autoFocus
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('order:label.order_number')}
                            name="order_number"
                            rules={[
                                {
                                    required: true,
                                    message: t('order:message.required', { attribute: t('quantity') }),
                                },
                                { pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) },
                                () => ({
                                    validator(_, value) {
                                        if (!value || value <= totalOrders) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error(t('order:message.quantity_per_session_invalid'))
                                        );
                                    },
                                }),
                            ]}
                        >
                            <InputNumber placeholder={t('order:placeholder.quantity')} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item>
                            <Button loading={loading} type="primary" style={{ width: '100%' }} htmlType="submit">
                                {t('label.time_start')}
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>
    );
};

export default PickingUpProduct;
