import { CheckCircleFilled } from '@ant-design/icons'
import { Form, Input, Checkbox, Badge, Tooltip, Switch, Select, Spin } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import _, { filter, get, mapKeys } from 'lodash'
import React, { useEffect, useState } from 'react'
import { v4 } from 'uuid'

import { SKU_STATUS } from '@Modules/App/services/constants'
import api from '@Modules/Product/services/api'
import { EVENTS, LOGIC_BATCH } from '@Modules/Product/services/constants'
import { INPUT_INVALID } from '@Modules/PurchasingPackage/services/constants'

import { events } from '@System/events'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { getVar } from '@System/support/helpers'

import SerialLogic from './SerialLogic'
import SwitchLogic from './SwitchLogic'

const { Option } = Select

export default function ProductVariations({
    product,
    localOptions,
    localVariations,
    setLocalVariations,
    prevLocalOptions,
    skus,
    onChangeCheck,
    onCheckAllChange,
    indeterminate,
    checkAll,
    selectedRowKeys,
    fetchData,
}) {
    const { xs } = useBreakpoint()
    const [form] = Form.useForm()

    useEffect(() => {
        if (_.isEmpty(prevLocalOptions.current)) {
            prevLocalOptions.current = localOptions
        } else {
            createVariations()
            prevLocalOptions.current = localOptions
        }
    }, [localOptions])

    useEffect(() => {
        const data = [...localVariations]
        skus &&
            skus.find(item => {
                const sku_id = getVar(item, 'sku.id', '')
                if (_.isEmpty(_.find(data, ['id', sku_id]))) {
                    const weight = getVar(item, 'sku.weight', undefined)
                    const length = getVar(item, 'sku.length', undefined)
                    const width = getVar(item, 'sku.width', undefined)
                    const height = getVar(item, 'sku.height', undefined)
                    data.push({
                        code: getVar(item, 'sku.code'),
                        id: getVar(item, 'sku.id'),
                        key: getVar(item, 'sku.id'),
                        option_values: [],
                        ref: getVar(item, 'sku.ref'),
                        status: getVar(item, 'sku.status'),
                        weight: weight ? Number(weight) * 1000 : '',
                        length: length ? Number(length) * 1000 : '',
                        width: width ? Number(width) * 1000 : '',
                        height: height ? Number(height) * 1000 : '',
                    })
                }
            })
        setLocalVariations(_.sortBy(data, ['id']))
    }, [skus])

    function createVariations() {
        let variations = []

        /**
         * Tạo lại mảng các biến thể theo thuộc tính mới được thêm vào
         */
        localOptions.forEach(option => {
            let { label, values } = option
            if (!_.isEmpty(label) && !_.isEmpty(values)) {
                if (variations.length === 0) {
                    values.forEach(value =>
                        variations.push({
                            id: null,
                            code: null,
                            option_values: [{ id: value.id, label: value.label, option_label: option.label }],
                        })
                    )
                } else {
                    let new_variations = []
                    values.forEach(value => {
                        variations.forEach(variation => {
                            let { option_values } = variation
                            new_variations.push({
                                id: variation.id,
                                code: null,
                                option_values: [...option_values, { id: value.id, label: value.label, option_label: option.label }],
                            })
                        })
                    })
                    variations = [...new_variations]
                }
            }
        })

        /**
         * Cập nhật lại id và code cho các biến thể mới được tạo lại dựa vào các biến thể cũ
         * Lấy ra biến thể cũ có các giá trị thuộc tính trùng với biến thể mới để cập nhật
         */
        let existedSkuIds = []
        let variationsFormat = variations.map(item => {
            let currentItem = localVariations.find(localItem => {
                if (localItem.status !== SKU_STATUS.STOP_SELLING) {
                    let gteCheck = item.option_values.length >= localItem.option_values.length,
                        optValues = _.get(gteCheck ? localItem : item, 'option_values', []),
                        optValueIds = _.map(_.get(gteCheck ? item : localItem, 'option_values', []), 'id')

                    return !existedSkuIds.includes(localItem.id) && optValues.every(value => optValueIds.includes(value.id))
                }
                return false
            })

            if (currentItem) {
                existedSkuIds.push(currentItem.id)
                return {
                    ...item,
                    key: currentItem.id,
                    id: currentItem.id,
                    code: currentItem.code,
                    weight: currentItem.weight,
                    length: currentItem.length,
                    width: currentItem.width,
                    height: currentItem.height,
                    ref: currentItem.ref,
                }
            } else {
                return { ...item, key: v4() }
            }
        })
        setLocalVariations(_.sortBy(variationsFormat, ['id']))
    }

    const onChangeInfo = (name, data, index) => {
        const value = getVar(data, name, '')
        const newLocalVariations = [...localVariations]
        newLocalVariations[index] = { ...data, [name]: value }
        setLocalVariations(newLocalVariations)
    }

    const checkInput = (index, item, value) => {
        if (value) {
            value = Number(value)
            if (isNaN(value) || !Number.isInteger(value)) {
                form.setFields([{ name: ['variant', index, item], errors: [t('validation:integer')] }])
            } else if (value < 0) {
                form.setFields([{ name: ['variant', index, item], errors: [t('validation:min.numeric', { min: 0 })] }])
            } else {
                form.setFields([{ name: ['variant', index, item], value, errors: [] }])
            }
        } else {
            form.setFields([{ name: ['variant', index, item], errors: [] }])
        }
    }

    const save = (index, item, record, e) => {
        const { value } = e.target
        checkInput(index, item, value)
        onChangeInfo(item, { ...record, [item]: value }, index)
    }

    const notIsBatch = filter(localVariations, item => !item.sku_parent_id)

    return (
        <Form form={form}>
            {notIsBatch.length > 0 && (
                <div className={`ant-table-wrapper list-products-table ant-table-scroll-horizontal ${xs && 'ant-table-ping-right'}`}>
                    <div className="ant-table-container">
                        <div
                            className="ant-table-content"
                            style={{ overflow: 'auto hidden' }}
                        >
                            <table
                                style={{ width: '576px', minWidth: '100%', tableLayout: 'auto' }}
                                className="_list-products-table mt-3"
                            >
                                <thead className="ant-table-thead">
                                    <tr>
                                        <th className="ant-table-cell ant-table-selection-column">
                                            <div className="ant-table-selection">
                                                <Checkbox
                                                    onChange={onCheckAllChange}
                                                    indeterminate={indeterminate}
                                                    checked={checkAll}
                                                />
                                            </div>
                                        </th>
                                        <th className="ant-table-cell _sku-column">{t('product:label.sku_code')}</th>
                                        <th className="ant-table-cell _name-column">{t('product:label.sku_name')}</th>
                                        <th className="ant-table-cell _weight-column">{t('sku:label.weight')}</th>
                                        <th className="ant-table-cell _length-column">{t('sku:label.length')}</th>
                                        <th className="ant-table-cell _width-column">{t('sku:label.width')}</th>

                                        <th className="ant-table-cell _height-column">{t('sku:label.height')}</th>
                                        <th className="ant-table-cell _ref-column">{t('sku:label.category_code')}</th>
                                        <th className="ant-table-cell _ref-column text-right">{t('sku:label.manage')}</th>
                                    </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                    {notIsBatch.map((variation, index) => {
                                        const { option_values, status, confirm_weight_volume, code, id, ref, is_batch, logic_batch, is_serial_number, using_serial } =
                                            variation
                                        const skuInfo = _.find(skus, ['sku.id', getVar(variation, 'id', undefined)])
                                        return (
                                            <tr
                                                key={index}
                                                className="ant-table-row ant-table-row ant-table-row-level-0"
                                            >
                                                <td className="ant-table-cell ant-table-selection-column">
                                                    <Checkbox
                                                        disabled={!id}
                                                        checked={selectedRowKeys.includes(id)}
                                                        onChange={e => onChangeCheck(id, e)}
                                                    />
                                                </td>
                                                <td className="ant-table-cell _sku-column">
                                                    <div className="d-flex align-items-center">
                                                        <p>{code ? code : `${product.code}-XXX`}</p>
                                                        {status === SKU_STATUS.STOP_SELLING && (
                                                            <Badge
                                                                className="ml-2"
                                                                count={t(`product:status.${status}`)}
                                                                style={{ backgroundColor: '#ffc107' }}
                                                            />
                                                        )}
                                                        {confirm_weight_volume && (
                                                            <Tooltip
                                                                title={t('sku:message.confirm_weight_volume.is_confirmed')}
                                                                className="ml-2"
                                                            >
                                                                <CheckCircleFilled style={{ color: 'green' }} />
                                                            </Tooltip>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="ant-table-cell _name-column">
                                                    <p>
                                                        {status === SKU_STATUS.STOP_SELLING
                                                            ? getVar(skuInfo, 'sku.name', '')
                                                            : `${product.name} ${
                                                                  option_values.length > 0 ? `- ` + option_values.map(opt => opt.label).join(', ') : ''
                                                              }`}
                                                    </p>
                                                </td>
                                                {['weight', 'length', 'width', 'height'].map((item, ind) => {
                                                    return (
                                                        <td
                                                            className={`ant-table-cell _${item}-column`}
                                                            key={ind}
                                                        >
                                                            <div className="d-flex align-items-center justify-content-between">
                                                                <Form.Item
                                                                    name={['variant', index, item]}
                                                                    className="mb-0"
                                                                    initialValue={getVar(variation, item, undefined)}
                                                                >
                                                                    <Input
                                                                        className={`text-right _product-variants-${item}-input`}
                                                                        onPressEnter={e => save(index, item, variation, e)}
                                                                        onBlur={e => save(index, item, variation, e)}
                                                                        style={{ width: 120 }}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                        </td>
                                                    )
                                                })}
                                                <td className="_ref-column">
                                                    <p>{ref}</p>
                                                </td>
                                                <td className="_ref-batch_manage">
                                                    {!variation.sku_parent_id && (
                                                        <SwitchLogic
                                                            onChangeInfo={onChangeInfo}
                                                            fetchData={fetchData}
                                                            isBatch={is_batch}
                                                            logicBatch={logic_batch}
                                                            variation={variation}
                                                            index={index}
                                                        />
                                                    )}

                                                    <SerialLogic
                                                        isSerialNumber={is_serial_number}
                                                        onChangeInfo={onChangeInfo}
                                                        fetchData={fetchData}
                                                        variation={variation}
                                                        index={index}
                                                        usingSerial={using_serial}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
            {notIsBatch.length === 0 && <p className="text-center mt-4 mb-5">{t('product:message.not_variation')}</p>}
        </Form>
    )
}
