import { Input, InputNumber } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import { formatCurrency } from '../../../system/support/numberFormat'

export default function InputNumberCurrency(props) {
    let { value, onChange, onBlur, onFocus, currency, min, max } = props
    let [edit, setEdit] = useState(false)
    let [data, setData] = useState(value ? parseFloat(value) : '')
    const labelRef = useRef()
    const valueRef = useRef()

    useEffect(() => {
        setData(value ? value : '')
    }, [value])

    useEffect(() => {
        if (edit) {
            valueRef.current.focus()
        }
    }, [edit])

    function _onChange(value) {
        setData(value)
        if (typeof onChange === 'function') onChange(value)
    }

    function _onFocus() {
        setEdit(true)
        if (typeof onFocus === 'function') onFocus(data)
    }

    function _onBlur() {
        setEdit(false)

        if (typeof onBlur === 'function') onBlur(data)
    }

    return edit ? (
        <InputNumber
            {...props}
            value={data}
            ref={valueRef}
            min={min}
            max={max}
            onBlur={_onBlur}
            onChange={_onChange}
        />
    ) : (
        <Input
            style={props.style}
            placeholder={props.placeholder}
            className={props.className}
            onFocus={_onFocus}
            ref={labelRef}
            value={data ? formatCurrency(data, currency ? currency : null) : ''}
        />
    )
}
