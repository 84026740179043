import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useDeleteInvalidOrderMutation = createUseMutation({
    namespace: 'order:delete',
    mutation: id => apiService.deleteInvalidOrder(id),
});

export default useDeleteInvalidOrderMutation;
