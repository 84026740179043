import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'

import api from '@Modules/Finance/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { convertBlobDataToExcel } from '@System/support/helpers'

interface IExportProps {
    documentId: number
    filters: object
}
const ExportFinanceFreightBill: React.FC<IExportProps> = ({ documentId, filters }) => {
    const [loading, setLoading] = useState(false)

    function onDownloadExcel() {
        setLoading(true)
        api.downloadDeliveryDocumentComparison(documentId, filters)
            .then(response => {
                convertBlobDataToExcel(response.data, t('finance:btn.export_incorrect_order'))
                notification.success(t('finance:message.export_excel_success', { attribute: t('finance:btn.export_incorrect_order') }))
            })
            .catch(err => {
                processResponseError(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <Button
            className="_export-finance-freight-bill ml-2"
            loading={loading}
            type="primary"
            onClick={onDownloadExcel}>
            <DownloadOutlined /> {t('finance:btn.export_incorrect_order')}
        </Button>
    )
}
export default ExportFinanceFreightBill
