import { DownloadOutlined } from '@ant-design/icons'
import { Button, Switch, Table } from 'antd'
import { get, isEmpty, uniqueId } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import DynamicTable from '@Modules/App/DynamicTable'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import useExportProductInventory from '@Modules/Product/Hooks/useExportProductInventory'
import useGroupBatchQuery from '@Modules/Product/Hooks/useGroupBatchQuery'
import FilterExportStorageFee from '@Modules/Product/components/FilterExportStorageFee'

import { t } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

import TableChildBatch from './TableChildBatch'

const TableViewBatch = ({ filters, addFilter, loading, viewStockBatch, onChangeViewBatch }) => {
    const { isLoading: exportLoading, refetch: fetchExport } = useExportProductInventory(filters)
    const { isLoading: isLoadingBatch, data: rawData } = useGroupBatchQuery(
        {
            ...filters,
            include:
                'sku_child_by_sku.stocks.warehouse,sku_child_by_sku.stocks.warehouse_area,sku_child_by_sku.stocks.merchants,sku_child_by_sku.stocks.warehouse,sku_child_by_sku.stocks.warehouse_area,sku,merchants',
        },
        { enabled: viewStockBatch }
    )
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({})

    const [visibleExportModal, setVisibleExportModal] = useState(false)

    useEffect(() => {
        setData(get(rawData, 'data.data', []))
        setPagination(get(rawData, 'data.meta.pagination', []))
    }, [rawData])

    const columns = [
        {
            title: t('product:label.sku_id'),
            dataIndex: ['sku', 'data', 'id'],
            key: 'id',
            width: '16.666667%',
            render: (text, record) => {
                const product_id = get(record, 'sku.data.product_id', '')
                return <Link to={url.to('products.detail', { id: product_id })}>{text}</Link>
            },
            disabled: true
        },
        {
            title: t('product:label.sku_code'),
            dataIndex: ['sku', 'data', 'code'],
            key: 'sku_code',
            width: '16.666667%',
        },
        {
            title: t('product:label.sku_name'),
            dataIndex: ['sku', 'data', 'name'],
            key: 'name',
            width: '16.666667%',
        },
        {
            title: t('label.merchant'),
            width: '16.666667%',
            dataIndex: 'merchants',
            key: 'merchants',
            render: (text, record) => {
                const merchants = get(record, 'merchants.data', [])
                return merchants.map((item, index) => {
                    const name = item?.name ?? ''
                    const code = item?.code ?? ''

                    return <p key={index}>{(name ? name : '') + (code ? ' - ' + code : '')}</p>
                })
            },
        },
        {
            className: '_inventory-list-sku-stock',
            title: t('product:label.sku_stock'),
            dataIndex: 'total_real_quantity',
            key: 'total_real_quantity',
            width: '16.666667%',
        },
        {
            className: '_inventory-list-sku-stock-tmp',
            title: t('product:label.sku_stock_tmp'),
            dataIndex: 'total_quantity',
            key: 'total_quantity',
            width: '16.666667%',
        },
    ]

    function onDownloadExcel() {
        if (getVar(filters, 'warehouse_id', undefined)) {
            fetchExport(filters)
        } else {
            notification.error(t('product:message.choose_warehouse_export'))
        }
    }

    const showConfirm = () => {
        setVisibleExportModal(true)
    }
    return (
        <>
            <div className="bg-white rounded-12 p-4 mt-4">
                <div className="data-box">
                    <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                        <div className="d-flex align-items-center">
                            <h3 className="text-fz-18">
                                {t('title.inventory_management')} ({isEmpty(pagination) ? 0 : pagination.total})
                            </h3>
                            <div className="d-flex align-items-center ml-2">
                                <Switch
                                    onChange={onChangeViewBatch}
                                    size="small"
                                    className="mr-2"
                                    checked={viewStockBatch}
                                />
                                <span className="font-weight-500">{t('product:label.view_stock_batch')}</span>
                            </div>
                        </div>

                        <div>
                            <Button
                                className="_export-excel-storage-fee mr-2"
                                type="primary"
                                onClick={showConfirm}
                            >
                                <DownloadOutlined /> {t('product:btn.export_storage_fee')}
                            </Button>
                            <Button
                                className="_export-excel"
                                loading={exportLoading}
                                type="primary"
                                onClick={onDownloadExcel}
                            >
                                <DownloadOutlined /> {t('common:btn.export_excel')}
                            </Button>
                        </div>
                    </div>{' '}
                    {/* <Table
                        className="table-parent"
                        columns={columns}
                        loading={isLoadingBatch}
                        rowKey={record => record?.sku?.data?.id}
                        expandable={{
                            expandedRowRender: record => <TableChildBatch data={get(record, 'sku_child_by_sku.data')} />,
                            rowExpandable: record => !isEmpty(get(record, 'sku_child_by_sku.data', [])),
                        }}
                        dataSource={data}
                        pagination={false}
                        locale={{
                            emptyText: (
                                <TableEmpty
                                    className="_inventory-list-no-data"
                                    loading={loading}
                                />
                            ),
                        }}
                    /> */}
                    <DynamicTable
                        initColumns={columns}
                        dataSource={data}
                        className="table-parent"
                        loading={isLoadingBatch}
                        rowKey={record => record?.sku?.data?.id}
                        expandable={{
                            expandedRowRender: record => <TableChildBatch data={get(record, 'sku_child_by_sku.data')} />,
                            rowExpandable: record => !isEmpty(get(record, 'sku_child_by_sku.data', [])),
                        }}
                    />
                    <CustomizedPagination
                        pagination={pagination}
                        filters={filters}
                        addFilter={addFilter}
                    />
                </div>
            </div>
            {visibleExportModal && (
                <FilterExportStorageFee
                    filters={filters}
                    setVisible={setVisibleExportModal}
                />
            )}
        </>
    )
}

export default TableViewBatch
