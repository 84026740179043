import React from "react";
import {t} from "@System/i18n";
import {PAYMENT_TYPE} from "@Modules/Order/services/constants";
import {Select} from "antd";

const {Option} = Select;

export const ORIGIN_SITES = [
    {name: "Taobao", value: "taobao", logo: ""},
    {name: "Tmall", value: "tmall", logo: ""},
    {name: "1688", value: "1688", logo: ""}
];

export default function SelectSite(props) {
    return (
        <Select
            {...props}
            optionFilterProp="children"
            allowClear={true}
        >
            {
                ORIGIN_SITES.map((opt, index) => {
                    return (
                        <Option
                            key={index}
                            value={opt.value}
                        >{opt.name}</Option>
                    );
                })
            }
        </Select>
    );
}
