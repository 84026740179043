import { Button } from 'antd';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import { getVar } from '@System/support/helpers';

import { t } from '../../../../system/i18n';
import { ORDER_PACKING_STATUS } from '../../services/constants';

function QuickFilter({ addFilter, filters, className, validateSearch, loading, pagination }) {
    const [status, setStatus] = useState();

    useEffect(() => {
        validateSearch(filters);
        if (getVar(filters, 'error_tracking')) {
            setStatus('error_tracking');
        } else {
            setStatus(getVar(filters, 'order_status'));
        }
    }, [filters]);

    function onClick(value) {
        setStatus(value);
        const input = {
            ...filters,
            order_status: value === 'error_tracking' ? undefined : value,
            error_tracking: value === 'error_tracking' ? 1 : undefined,
        };

        const validate = validateSearch(input);
        if (validate) {
            addFilter(input);
        }
    }
    return (
        <div className={className}>
            {Object.values(ORDER_PACKING_STATUS).map(item => (
                <Button
                    className={`mr-2 mb-2 _prepare-order-quick-filter-${item.key}`}
                    type={item.key === status ? 'primary' : 'default'}
                    shape="round"
                    key={item.key}
                    onClick={() => onClick(item.key)}
                    loading={item.key === status ? loading : false}
                    style={{ minWidth: '100px' }}
                >
                    {t(`order:status.${item.key}`)}
                    {item.key === status ? ` (${pagination?.total ? pagination?.total : 0})` : ''}
                </Button>
            ))}
        </div>
    );
}

export default QuickFilter;
