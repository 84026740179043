import { find, isEmpty, filter, map } from 'lodash';

import { scanImportingPurchasePackageStorage } from '@Modules/App/services';
import { updateCollectionItem } from '@Modules/App/services/helpers';

import { getVar } from '@System/support/helpers';

export const ACTIONS_IMPORT_BY_PURCHASE_PACKAGE = {
    NEW_SCAN: 'ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.NEW_SCAN',
    UPDATE_SCAN_INFO: 'ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE',
    DELETE_PURCHASE_PACKAGE: 'ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.DELETE_PURCHASE_PACKAGE',
    ADD_PURCHASE_PACKAGE: 'ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.ADD_PURCHASE_PACKAGE',
    UPDATE_RECEIVED_QUANTITY: 'ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE_RECEIVED_QUANTITY',
    UPDATE_SERVICE: 'ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE_SERVICE',
    ADD_SKU: 'ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.ADD_SKU',
    IS_SAVED: 'ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.IS_SAVED',
};

export default function reducer(state, action) {
    let newState;
    let { payload } = action;
    let skus = getVar(state, 'skus', []);
    switch (action.type) {
        case ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.NEW_SCAN:
            newState = { ...payload };

            break;
        case ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE_SCAN_INFO:
            let data = { ...state, ...action.payload }
            const skusNew = map(data.skus, item => {
                return {
                    ...item,
                    serial_numbers: item?.serial_numbers || [],
                    received_quantity: 0
                }
            })
            newState = { ...state, ...action.payload, skus: skusNew };
            break;
        case ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.ADD_PURCHASE_PACKAGE:
            newState = addPurchasePackage(state, payload);
            break;
        case ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE_RECEIVED_QUANTITY:
            newState = { ...state, skus: skus.map(item => (payload.id === item.id ? { ...item, ...payload } : item)) };
            break;
        case ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.DELETE_PURCHASE_PACKAGE:
            newState = updatePurchasePackage(state, payload);
            break;
        case ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.IS_SAVED:
            newState = { ...state, isSaved: true };
            break;
        case ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE_SERVICE:
            newState = { ...state, services: payload };
            break;
        case ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.ADD_SKU:
            newState = addSku(state, payload);
            break;
        default:
            newState = { ...state };
            break;
    }
    scanImportingPurchasePackageStorage.setValue(newState);
    return newState;
}

function addPurchasePackage(state, payload) {
    const services = getVar(payload, 'services', []);
    const purchasePackages = getVar(state, 'purchase_packages', []);
    const newPurchasePackages = [...purchasePackages];
    newPurchasePackages.push({ ...payload, services: undefined });
    const skus = calculatorTotalSku(state, newPurchasePackages);
    return { ...state, purchase_packages: newPurchasePackages, skus, services };
}

function updatePurchasePackage(state, purchasePackageIds) {
    const purchasePackages = getVar(state, 'purchase_packages', []);
    const data = filter(purchasePackages, item => {
        const id = getVar(item, 'purchase_package_key', '');
        return !purchasePackageIds.includes(id);
    });
    const skus = calculatorTotalSku(state, data);
    return { ...state, purchase_packages: data, skus };
}

function calculatorTotalSku(state, data) {
    const skus = [];
    data.map(item => {
        return skus.push(...item.skus);
    });
    let localSkus = getVar(state, 'skus', []);
    let skusUniq = [];
    skus.map(skuInfo => {
        const sku_id = getVar(skuInfo, 'id');
        const skuLocalInfo = find(localSkus, ['id', sku_id]);
        const receivedQuantity = getVar(skuInfo, 'received_quantity', 0);
        const isSerial = getVar(skuInfo, 'is_serial_number', '')
        let newReceivedQuantity = receivedQuantity;
        if (!isEmpty(skuLocalInfo)) {
            newReceivedQuantity = getVar(skuLocalInfo, 'received_quantity', 0);
        }
        const skuExist = find(skusUniq, ['id', sku_id]);
        if (isEmpty(skuExist)) {
            skusUniq.push({
                ...skuInfo,
                received_quantity: isSerial ? skuInfo?.serial_numbers?.length || 0 : parseInt(newReceivedQuantity),
                old_received_quantity: parseInt(receivedQuantity),
            });
        } else {
            const skuReceivedQuantity = getVar(skuExist, 'old_received_quantity', 0);
            const newQuantity = parseInt(receivedQuantity) + parseInt(skuReceivedQuantity);
            skusUniq = updateCollectionItem(skusUniq, sku_id, {
                received_quantity: isSerial ? skuInfo?.serial_numbers?.length || 0: parseInt(newReceivedQuantity),
                old_received_quantity: parseInt(newQuantity),
            });
        }
        return true;
    });
    return skusUniq;
}

function addSku(state, payload) {
    const { skus, purchase_packages, services } = state;
    const newServices = [];
    const { purchasePackageId, sku } = payload;
    const newSkus = [...skus, sku];
    const purchasePackages = updateCollectionItem(purchase_packages, purchasePackageId, { skus: newSkus });
    services.map(item => {
        const service = getVar(item, 'service', {});
        if (service.is_required && service.status === 'INACTIVE') {
            newServices.push({ ...item, sku_ids: map(newSkus, 'id') });
        } else {
            newServices.push(item);
        }
    });
    return { ...state, services: newServices, purchase_packages: purchasePackages, skus: newSkus };
}
