import {get} from "lodash";
import {notification} from "antd";
import {useQuery} from 'react-query';

import {t} from "../../../system/i18n";
import api from "../services/api";

export const useSettingConfigQuery = () =>  {
  return useQuery(['settingConfig'],
      () => api.getLogicBillLading(), {
          retry: false,
          onError: (err) => {
              const status = get(err, "response.status", null);
              if (status === 403)
                  notification.error({message: t('common:message.403')});
              else if (status === 404)
                  notification.error({message: t('common:message.404')});
              else
                  notification.error({message: t('common:message.fetch_fail')});
          }
      });
}
