import React, {useState} from 'react';
import {Button, Drawer, notification, Spin, Timeline} from "antd";
import lodash, { get, join } from 'lodash';
import api from "../../../services/api";
import {ORDER_LOG_KEYS} from "../../../services/constants";
import {dateFormatter} from "../../../../App/services";
import {trans, t} from "../../../../../system/i18n";
import useSize from "@Modules/App/Hooks/useSize";
import { map } from 'lodash';
import { FINANCE_SHIPPING_STATUS } from '@Modules/Finance/services/constants';

export default function HistoryOrder({orderInfo}) {
    const {isMobile} = useSize();
    const {id} = orderInfo;
    const [visible, setVisible] = useState(false);
    const [logs, setLogs] = useState(false);
    const [loading, setLoading] = useState(false)

    const showDrawer = () => {
        setVisible(true);
        fetchHistoryOrder();
    };

    const onClose = () => {
        setVisible(false);
    };

    function fetchHistoryOrder() {
        setLoading(true)
        api.getHistoryOrder(id).then(res => {
            setLogs(lodash.get(res, "data.logs", []));
        }).catch(err => {
            const status = lodash.get(err, "response.status", null);
            if (status === 403)
                notification.error({message: t('common:message.403')});
            else if (status === 404)
                notification.error({message: t('common:message.404')});
            else
                notification.error({message: t("common:message.fetch_fail", {attribute: t("order:label.history_order")})})
        }).finally(()=>{
            setLoading(false)
        })
    }

    function renderLogsInfo(log) {
        const action = lodash.get(log, "action", "");
        const payload = lodash.get(log, "payload", {});
        const packageInfo = lodash.get(payload, "package", {});
        const old_status = lodash.get(payload, "old_status", "");
        const new_status = lodash.get(payload, "new_status", "");
        const payload_keys = lodash.keys(payload);
        switch (action) {
            case ORDER_LOG_KEYS.ORDER_CREATE:
                return (trans("order:log.ORDER_CREATE"));
            case ORDER_LOG_KEYS.ORDER_UPDATE:
                let message_update = [] ;
                payload_keys.forEach(item => {
                    const values = lodash.get(payload, item, {});
                    message_update.push(trans("order:log.ORDER_UPDATE", {
                        field: <strong>{trans("order:label." + item)}</strong>,
                        old_value: <strong>{typeof (values.old) === "object" ? "" : values.old}</strong>,
                        new_value: <strong>{values.new}</strong>
                    }));
                });
                return message_update;
            case ORDER_LOG_KEYS.ORDER_CHANGE_STATUS:
                return trans("order:log.ORDER_CHANGE_STATUS", {
                    old_status: <strong>{trans("order:status." + old_status)}</strong>,
                    new_status: <strong>{trans("order:status." + new_status)}</strong>
                });
            case ORDER_LOG_KEYS.ORDER_INSPECTION:
                return trans("order:log.ORDER_INSPECTION");
            case ORDER_LOG_KEYS.ORDER_CREATE_PACKAGE:
                return trans("order:log.ORDER_CREATE_PACKAGE", {package_code: packageInfo.code ? "[" + packageInfo.code + "]" : ""});
            case ORDER_LOG_KEYS.ORDER_PAYMENT_CONFIRM:
                return trans("order:log.ORDER_PAYMENT_CONFIRM");
            case ORDER_LOG_KEYS.ORDER_CANCEL:
                return trans("order:log.ORDER_CANCEL");
            case ORDER_LOG_KEYS.ORDER_CHANGE_FREIGHT_BILL:
                return trans("order:log.ORDER_CHANGE_FREIGHT_BILL");
            case ORDER_LOG_KEYS.ORDER_CHANGE_PACKAGE_STATUS:
                return trans("order:log.ORDER_CHANGE_PACKAGE_STATUS", {
                    package_code: packageInfo.code ? "[" + packageInfo.code + "]" : "",
                    old_status: <strong>{t("order:status." + old_status)}</strong>,
                    new_status: <strong>{t("order:status." + new_status)}</strong>
                });
            case ORDER_LOG_KEYS.ORDER_UPDATE_SKUS:
                let message =  map(payload, (item, index)=> 
                <div key={index}> 
                    <span> {trans("order:log.ORDER_UPDATE_SKUS", {
                        sku_code: <strong>{item.sku_code}</strong>,
                        quantity: <strong>{item.quantity}</strong>,
                        price: <strong>{item.price}</strong>
                        })}
                    </span>
                </div>)
                return  <>{t('order:label.product_change_info')} {message}</>;
            case ORDER_LOG_KEYS.CHANGE_SHIPPING_PARTNER:
                let name_from = lodash.get(payload, "from.name", null),
                    name_to = lodash.get(payload, "to.name", null);
                return trans("order:log.CHANGE_SHIPPING_PARTNER", {
                    from: <strong>{name_from ? name_from : t('noText')}</strong>,
                    to: <strong>{name_to ? name_to : t('noText')}</strong>
                });
            case ORDER_LOG_KEYS.ORDER_ADD_WAREHOUSE_AREA:
                return trans("order:log.ORDER_ADD_WAREHOUSE_AREA");
            case ORDER_LOG_KEYS.ORDER_REMOVE_WAREHOUSE_AREA:
                return trans("order:log.ORDER_REMOVE_WAREHOUSE_AREA");
            case ORDER_LOG_KEYS.ORDER_ADD_PRIORITY:
                return trans("order:log.ORDER_ADD_PRIORITY");
            case ORDER_LOG_KEYS.ORDER_UPDATE_ATTRIBUTES:
                return map(Object.entries(payload),([key,value])=>{
                    const oldValue = get(value,'0')
                    const newValue = get(value,'1')
                    
                    if(key === 'payment_type'){
                        return trans("order:log.ORDER_UPDATE",{
                            field: t(`order:label.${key}`),
                            old_value: <strong>{oldValue ? t(`order:payment_type.${oldValue}`) : ""}</strong>,
                            new_value: <strong>{ newValue ?t(`order:payment_type.${newValue}`) : ""}</strong>
                        })
                    }
                 
                    if(key === "shipping_financial_status"){

                        if(newValue === FINANCE_SHIPPING_STATUS.INIT){
                            return t('finance:message.init_status_financial')
                        }

                        return  trans("order:log.ORDER_UPDATE",{
                            field: t(`order:label.${key}`),
                            old_value: <strong>{t(`finance:FINANCE_SHIPPING_STATUS.${oldValue}`)}</strong>,
                            new_value: <strong>{t(`finance:FINANCE_SHIPPING_STATUS.${newValue}`)}</strong>
                        })
                    }

                    return  trans("order:log.ORDER_UPDATE",{
                        field: t(`order:label.${key}`),
                        old_value: <strong>{oldValue}</strong>,
                        new_value: <strong>{newValue}</strong>
                    })
                });
            case ORDER_LOG_KEYS.ORDER_UPDATE_EXPECTED_TRANSPORTING_PRICE:
                return trans("order:log.ORDER_UPDATE_EXPECTED_TRANSPORTING_PRICE");
            case ORDER_LOG_KEYS.ORDER_COMPLETE_BATCH:
                return trans("order:log.ORDER_COMPLETE_BATCH");
            case ORDER_LOG_KEYS.ORDER_UPDATE_BATCH:
                const listSku = join( map(payload, 'sku_code'), ' ,')

                return trans("order:log.ORDER_UPDATE_BATCH",
               { 
                attribute: <b>{listSku}</b>
                }
                );
            default:
                return trans("order:log.ORDER_UPDATE");
        }
    }

    return (
        <>
            <span className="mr-2">
                <Button className="_order-histories-btn" type="primary" onClick={showDrawer}>
                    {t("order:label.history_order")}
                </Button>
            </span>
        <Drawer
                className="_order-histories-drawer histories_order"
                title={t("order:label.history_order").toUpperCase()}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
                width={isMobile ? "80%": "38%"}
            >  <Spin spinning={loading}>
                {
                    logs.length > 0 &&
                    <Timeline mode="left" className="histories_order_timeline">
                        {
                            logs.map((item, index) => {
                                return (
                                    <Timeline.Item
                                        className="_order-history-item"
                                        label={dateFormatter.formatDate(item.created_at, "DD/MM/YYYY HH:mm:ss")}
                                        key={index}
                                    >
                                        {item.creator.name || item.creator.username || item.creator.email} - {renderLogsInfo(item)}
                                    </Timeline.Item>
                                )
                            })
                        }
                    </Timeline>
                }</Spin>
            </Drawer>
      
        </>
    );
}
