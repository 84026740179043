import { Button, Col, Form, Input, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

import { getVar } from '@System/support/helpers';

import useValidatedMessages from '../../../system/hooks/useValidatedMessages';
import { t } from '../../../system/i18n';
import { auth } from '../../Auth/services';
import PERMISSIONS from '../../Auth/services/permissions';
import SelectMarket from '../../Location/components/SelectMarket';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const validatedRules = {
    code: [{ required: true, max: 255, type: 'string' }],
    name: [{ required: true, max: 255, type: 'string' }],
    phone: [],
    address: [],
    description: [],
    location_id: [{ required: true, type: 'integer' }],
    username: [],
    free_storage_day: [
        {
            pattern: /^^[0-9]*$$/,
            message: t('validation:numeric', { attribute: t('merchant:label.free_storage_day') }),
        },
    ],
};

export default function MerchantForm({ handleSubmit, onReset, form, merchant, loading }) {
    const validateMessages = useValidatedMessages();
    let showButtonUpdate = true;
    if (merchant) {
        showButtonUpdate = auth.can([PERMISSIONS.MERCHANT_UPDATE]);
    } else {
        showButtonUpdate = auth.can([PERMISSIONS.MERCHANT_CREATE]);
    }
    return (
        <Form
            className={'form'}
            validateMessages={validateMessages}
            size="default"
            form={form}
            labelCol={formItemLayout.labelCol}
            wrapperCol={formItemLayout.wrapperCol}
            colon=":"
            initialValues={merchant ? merchant : {}}
        >
            <Form.Item className="mb-3" name="code" label={t('merchant:label.code')} rules={validatedRules.code}>
                <Input
                    className="input-code"
                    name="code"
                    placeholder={t('merchant:placeholder.code')}
                    disabled={!!merchant}
                />
            </Form.Item>
            {!isEmpty(merchant) && (
                <>
                    <Form.Item className="mb-3" label={t('merchant:label.email')}>
                        <p>{getVar(merchant, 'user.email', undefined)}</p>
                    </Form.Item>

                    <Form.Item className="mb-3" label={t('merchant:label.ref')}>
                        <p>{getVar(merchant, 'ref', undefined)}</p>
                    </Form.Item>
                </>
            )}

            <Form.Item className="mb-3" name="name" label={t('merchant:label.name')} rules={validatedRules.name}>
                <Input className="input-subject" name="subject" placeholder={t('merchant:placeholder.name')} />
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="location_id"
                label={t('merchant:label.market')}
                rules={validatedRules.location_id}
            >
                <SelectMarket
                    className="select-location_id"
                    placeholder={t('merchant:placeholder.market')}
                    name="location_id"
                />
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="username"
                label={t('merchant:label.username')}
                rules={validatedRules.username}
            >
                <Input className="input-username" name="username" placeholder={t('merchant:placeholder.username')} />
            </Form.Item>

            <Form.Item className="mb-3" name="phone" label={t('merchant:label.phone')} rules={validatedRules.phone}>
                <Input className="input-phone" name="phone" placeholder={t('merchant:placeholder.phone')} />
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="address"
                label={t('merchant:label.address')}
                rules={validatedRules.address}
            >
                <Input className="input-address" name="address" placeholder={t('merchant:placeholder.address')} />
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="free_days_of_storage"
                label={t('merchant:label.free_storage_day')}
                rules={validatedRules.free_storage_day}
                initialValue={30}
            >
                <Input
                    className="input-free_days_of_storage"
                    name="free_days_of_storage"
                    placeholder={t('merchant:placeholder.free_storage_day')}
                />
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="description"
                label={t('merchant:label.description')}
                rules={validatedRules.description}
            >
                <TextArea
                    name="description"
                    className="input-description"
                    placeholder={t('merchant:placeholder.description')}
                    style={{ height: 100 }}
                />
            </Form.Item>
            {showButtonUpdate && (
                <Row className="mt-3">
                    <Col span={18} offset={6}>
                        <Button
                            htmlType={'submit'}
                            type="primary"
                            onClick={handleSubmit}
                            loading={loading}
                            style={{ textTransform: 'uppercase' }}
                            className={`btn-seller-${merchant ? 'update' : 'create'}`}
                        >
                            {t(`common:btn.${merchant ? 'update' : 'create'}`)}
                        </Button>
                        <Button
                            htmlType={'reset'}
                            className="ml-2"
                            onClick={onReset}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {t('common:btn.reset_form')}
                        </Button>
                    </Col>
                </Row>
            )}
        </Form>
    );
}
