import { Input, Typography } from 'antd';
import clsx from 'clsx';
import filter from 'lodash/filter';
import map from 'lodash/map';
import React, { useCallback, useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Box from '@Modules/App/Box';
import useDebounce from '@Modules/App/Hooks/useDebounce';
import TableCount from '@Modules/App/Table/TableCount';
import { dateFormatter } from '@Modules/App/services';

import { t } from '@System/i18n';

import styles from './../../detail.module.scss';

const { Paragraph, Title } = Typography;

const ScanHistories = ({ scanHistories }) => {
    const [filteredScanHistories, setFilteredScanHistories] = useState([]);
    const [sku, setSku] = useState();
    const debouncedSku = useDebounce(sku, { wait: 350 });

    const filterScanHistories = useCallback((sku, scanHistories) => {
        let filteredScanHistories = scanHistories;

        if (sku) {
            filteredScanHistories = filter(filteredScanHistories, scanHistory =>
                scanHistory?.payload?.sku?.trim().toLowerCase().includes(sku?.trim().toLowerCase())
            );
        }

        setFilteredScanHistories(filteredScanHistories);
    }, []);

    const handleChange = useCallback(event => {
        setSku(event.target.value);
    }, []);

    useEffect(() => {
        filterScanHistories(debouncedSku, scanHistories);

        // eslint-disable-next-line
    }, [debouncedSku, scanHistories]);

    return (
        <Box className={'_scan-histories mb-3'}>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <Title level={3}>
                    {t('scan_history')} <TableCount className="ml-2" text={scanHistories?.length ?? 0} />
                </Title>
                <div>
                    <Input
                        className="_sku"
                        value={sku}
                        placeholder={t('search_with_type', {
                            type: 'SKU',
                        })}
                        onChange={handleChange}
                    />
                </div>
            </div>

            <PerfectScrollbar options={{ suppressScrollX: true, wheelPropagation: false }} style={{ maxHeight: '150px' }}>
                {map(filteredScanHistories, scanHistory => (
                    <div className="d-flex align-items-center justify-content-between" key={scanHistory?.id}>
                        <Paragraph>{scanHistory?.payload?.sku}</Paragraph>
                        <Paragraph className="text-grey">{dateFormatter.full(scanHistory?.time)}</Paragraph>
                    </div>
                ))}
            </PerfectScrollbar>
        </Box>
    );
};

export default ScanHistories;
