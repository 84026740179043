import React, {useState} from "react";
import {Avatar} from "antd";
import {DownOutlined, CaretRightOutlined, LogoutOutlined, KeyOutlined, UserOutlined} from "@ant-design/icons";
import Language from "../Language";
import {auth} from "../../Auth/services";
import {t} from "../../../system/i18n";
import ConfigMenu from "./ConfigMenu";
import useSize from "../Hooks/useSize";

export default function UserInfo({isShown, setIsShown}) {
    const {isXSmall, isSmall, isMedium, isLarge, isExtraLarge, isExtraExtraLarge} = useSize();
    const [isVisible, setIsVisible] = useState(false);
    const user = auth.user();

    function logout(e) {
        e.preventDefault();
        auth.logout();
    }

    function _onClick(e) {
        e.preventDefault();
        setIsVisible(!isVisible);
    }

    const menuConfig = [
        {
            item:  <span className="d-flex justify-content-between align-items-center">{t("label.lang")} <CaretRightOutlined className="mr-0" /></span>,
            componentTab: <Language/>
        },
        {item:  <a href="#"><UserOutlined className="mr-1"/> {t("account_info")}</a>},
        {item:  <a href="#"><KeyOutlined className="mr-1"/> {t("change_pin")}</a>},
        {item:  <a className="text-red" href="#" onClick={logout}><LogoutOutlined className="mr-1"/> {t("common:menu.logout")}</a>}
    ]

    return (
        <div className="user-info">
            {
                (isXSmall || isSmall) ?
                    <a className="ant-dropdown-link user-info text-fz-15" onClick={() => setIsShown(true)}>
                        <Avatar shape="square" src={user.avatar}/>
                    </a> :
                    <ConfigMenu items={menuConfig} isDropDown={true} visible={isVisible} onVisible={setIsVisible}>
                        <a className="ant-dropdown-link user-info text-fz-15" onClick={_onClick}>
                            {user.name || user.username} <DownOutlined className="mx-1" /> <Avatar shape="square" src={user.avatar}/>
                        </a>
                    </ConfigMenu>
            }
        </div>
    );
}
