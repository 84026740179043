import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import { ExpandableConfig, GetRowKey } from 'antd/lib/table/interface'
import { uniqueId } from 'lodash'
import React, { useState } from 'react'

import { classes } from '@System/support/helpers'

import TableEmpty from '../Utilities/TableEmpty'
import SettingColumns, { IColumn } from './SettingColumns'

interface IDynamicTable extends TableProps<any> {
    initColumns: IColumn[]
    dataSource: any[]
    className?: string
    loading: boolean
    expandable?: ExpandableConfig<any>
    rowKey?: string | GetRowKey<any>
    onChangeActiveKeys?: (keys: string[]) => void
}

const DynamicTable: React.FC<IDynamicTable> = props => {
    const { initColumns, dataSource, className = '', loading, expandable, rowKey, onChangeActiveKeys } = props
    const [columns, setColumns] = useState(initColumns)

    return (
        <div>
            <div className="text-right">
                <SettingColumns
                    columns={initColumns}
                    setColumns={setColumns}
                    onChangeActiveKeys={onChangeActiveKeys}
                />
            </div>
            <Table
                {...props}
                className={classes('dynamic-table pb-3', {
                    [className]: className,
                })}
                dataSource={dataSource}
                expandable={expandable}
                rowKey={rowKey ? rowKey : () => uniqueId()}
                columns={columns}
                pagination={false}
                loading={loading}
                locale={{
                    emptyText: (
                        <TableEmpty
                            loading={loading}
                            className="dynamic-table"
                        />
                    ),
                }}
                scroll={{ x: true }}
            />
        </div>
    )
}

export default DynamicTable
