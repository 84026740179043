export const EVENTS = {
    FETCH_MERCHANT_AGAIN: "MERCHANT.FETCH_MERCHANT_AGAIN",
    FETCH_LIST_MERCHANT_AGAIN: "MERCHANT.FETCH_LIST_MERCHANT_AGAIN"
};

export const TRANSACTION_STATUS = {
    IMPORT_SERVICE: 'IMPORT_SERVICE',
    EXPORT_SERVICE: 'EXPORT_SERVICE',
    SHIPPING: 'SHIPPING',
    COD: 'COD',
    DEPOSIT: 'DEPOSIT',
    WITHDRAW:'WITHDRAW',
    STORAGE_FEE:'STORAGE_FEE',
    IMPORT_RETURN_GOODS_SERVICE: 'IMPORT_RETURN_GOODS_SERVICE',
    EXTENT: 'EXTENT',
    COLLECT: 'COLLECT'
};

export const TRANSACTION_STATUS_COLORS = {
    IMPORT_SERVICE: '#f9364a',
    EXPORT_SERVICE: '#f9364a',
    SHIPPING: '#f9364a',
    IMPORT_RETURN_GOODS_SERVICE: '#f9364a',
    COD: '#09B2AA',
    DEPOSIT: '#09B2AA',
    STORAGE_FEE:'#f9364a',
    WITHDRAW:'#f9364a',
    EXTENT:'#f9364a',
    COLLECT: '#f9364a'
};
