import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';
import get from 'lodash/get';
import { parse } from 'query-string';
import React, { useEffect, useState } from 'react';

import useToggle from '@Modules/App/Hooks/useToggle';
import {setDocumentTitle, setNavigator} from '@Modules/App/services';
import useMarketplacesQuery from '@Modules/Marketplace/Hooks/useMarketplacesQuery';

import { t } from '@System/i18n';
import notification from '@System/notification';

import AddConnectionModal from './../../components/AddConnectionModal';
import Body from './Body';

const { Title } = Typography;

const List = ({ history, location }) => {
    const [visible, { setLeft, setRight }] = useToggle();
    const [selectedMarketplace, setSelectedMarketplace] = useState();
    const { data: marketplaces } = useMarketplacesQuery();

    useEffect(() => {
        setSelectedMarketplace(get(marketplaces, 'data.marketplaces[0]'));
    }, [marketplaces]);

    useEffect(() => {
        setNavigator(t('connected_account'));
        setDocumentTitle(t("connected_account"));

        const queryParams = parse(location.search);

        if (queryParams?.error) {
            notification.error(t(`connectedAccount:add_connect.errors.store.${queryParams.error}`, {
                payload: queryParams.merchant_name,
            }));

            history.replace(location.pathname);
        }

        // eslint-disable-next-line
    }, []);

    return (
        <Card
            className="mx-4 my-px-32"
            title={
                selectedMarketplace ? (
                    <div className="d-flex align-items-center justify-content-between">
                        <Title className="font-weight-bold text-fz-16 mb-0" level={3}>
                            {t('connectedAccount:account_type', {
                                type: selectedMarketplace?.name,
                            })}
                        </Title>
                        <Button type="primary" onClick={setRight}>
                            <PlusOutlined />
                            {t('add_connection')}
                        </Button>
                    </div>
                ) : null
            }
        >
            {selectedMarketplace ? <Body marketplace={selectedMarketplace} /> : null}
            <AddConnectionModal marketplaceCode={selectedMarketplace?.code} visible={visible} onCancel={setLeft} />
        </Card>
    );
};

export default List;
