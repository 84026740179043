import { Checkbox, Form, Spin } from 'antd'
import React, { useState } from 'react'

import api from '@Modules/Product/services/api'

import { t } from '@System/i18n'
import { get, mapKeys } from 'lodash'
import notification from '@System/notification'
import { INPUT_INVALID } from '@Modules/PurchasingPackage/services/constants'

const SerialLogic = ({ onChangeInfo, fetchData, variation, index, isSerialNumber, usingSerial }) => {
    const [loading, setLoading] = useState(false)

    const onChangeLogic = (name, item, value, index) => {

        const data = {
            is_serial_number: value,
        }
        changeLogicSerial(item.id,data)
        // onChangeInfo(name, { ...item, [name]: value }, index)
    }

    const changeLogicSerial = (id, data) => {
        setLoading(true)
        api.changeLogicSerial(id, data)
            .then(result => {
                notification.success(t('product:message.update_serial_logic_success'))
                fetchData()
            })
            .catch(err => {
                const code = get(err, 'response.data.code')
                const data = get(err, 'response.data.data')

                if (code === INPUT_INVALID) {
                    mapKeys(data, (value, key) => {
                        notification.error(t(`packing:message.${key}.${Object.keys(value)[0]}`))
                    })
                } else {
                    notification.error(t('product:message.update_serial_logic_fail'))
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="mt-3">
            <Spin spinning={loading}>
                <div className="">
                    <div className="text-right  font-weight-500">{t('sku:label.serial_manage')}</div>
                    <div className="d-flex align-items-center justify-content-end">
                        <Form.Item
                            name={['is_serial_number', isSerialNumber]}
                            className="mb-0"
                        >
                            <Checkbox
                                checked={isSerialNumber}
                                loading={loading}
                                disabled={usingSerial}
                                onChange={e => onChangeLogic('is_serial_number', variation, e.target.checked, index)}
                            />
                        </Form.Item>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default SerialLogic
