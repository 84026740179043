import { Form, Modal } from 'antd';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import Loading from '@Modules/App/Loading';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useConfirmFinanceControlDocumentMutation from '@Modules/Finance/Hooks/useConfirmFinanceControlDocumentMutation';
import useDeleteFinanceControlDocumentMutation from '@Modules/Finance/Hooks/useDeleteFinanceControlDocumentMutation';
import useFinanceControlDocument from '@Modules/Finance/Hooks/useFinanceControlDocumentQuery';
import useUpdateFinanceControlDocumentMutation from '@Modules/Finance/Hooks/useUpdateFinanceControlDocumentMutation';
import Body from '@Modules/Finance/screens/Control/Detail/Body';
import Header from '@Modules/Finance/screens/Control/Detail/Header';

import { t, trans } from '@System/i18n';
import notification from '@System/notification';
import { getVar } from '@System/support/helpers';

function FinanceControlDetail(props) {
    const [form] = Form.useForm();
    const { match } = props;
    const documentId = match.params.id;
    const [confirm, setConfirm] = useState(false);
    const [canUpdate, setCanUpdate] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [currency, setCurrency] = useState({});
    const [documentInfo, setDocumentInfo] = useState({});
    const [shippingPartner, setShippingPartner] = useState({});
    const [freightBillInventories, setFreightBillInventories] = useState([]);

    const { error, isError, isLoading, data, refetch } = useFinanceControlDocument(documentId);

    const { mutate: updateDocument } = useUpdateFinanceControlDocumentMutation({
        id: documentId,
        onSuccess: () => {
            notification.success(
                t('message.update_success_by_attribute', { attribute: trans('title.finance_control_detail') })
            );
        },
        onError: () => {
            form.resetFields();
            refetch();
            notification.error(
                t('message.update_failed_by_attribute', { attribute: trans('title.finance_control_detail') })
            );
        },
    });

    const { mutateAsync: deleteDocument } = useDeleteFinanceControlDocumentMutation({
        id: documentId,
        onSuccess: data => {
            setDocumentInfo(data);
            setConfirmDelete(false);
            refetch();
        },
    });
    const { mutate: confirmDocument } = useConfirmFinanceControlDocumentMutation({
        id: match.params.id,
        onSuccess: data => {
            refetch();
            setDocumentInfo(data);
            setConfirm(false);
        },
    });

    useEffect(() => {
        setNavigator(trans('title.finance_control_detail'), [
            {
                name: trans('finance:label.finance_document_control'),
                route: 'finances.control.list',
            },
            {
                name: trans('title.finance_control_detail'),
            },
        ]);
    }, []);

    useEffect(() => {
        setCanUpdate(getVar(data, 'data.can_update', false));
        setDocumentInfo(getVar(data, 'data.document', {}));
        setCurrency(getVar(data, 'data.document_freight_bill_inventories.0.currency', {}));
        setShippingPartner(getVar(data, 'data.shipping_partner', {}));
        setFreightBillInventories(getVar(data, 'data.document_freight_bill_inventories', []));
    }, [data]);

    useEffect(() => {
        setDocumentTitle(t('title_page.document_finance_control', { document_code: get(documentInfo, 'code', '') }));
    }, [documentInfo]);

    if (isLoading) {
        return <Loading />;
    }

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    function handleConfirm() {
        let isConfirm = undefined;
        if (freightBillInventories.filter(info => info.warning).length > 0) {
            isConfirm = 1;
        }
        const payload = { documentId, confirm: isConfirm };
        confirmDocument(payload);
    }

    function handleUpdate() {
        form.validateFields().then(data => {
            updateDocument({ ...data });
        });
    }

    const onConfirm = () => {
        form.validateFields().then(data => {
            setConfirm(true);
        });
    };

    return (
        <div className="site-content order-detail">
            <Form form={form} layout="vertical">
                <Header
                    documentInfo={documentInfo}
                    shippingPartner={shippingPartner}
                    documentId={documentId}
                    handleDelete={() => setConfirmDelete(true)}
                    handleConfirm={onConfirm}
                    form={form}
                />
                {documentId && (
                    <Body
                        form={form}
                        loading={isLoading}
                        currency={currency}
                        canUpdate={canUpdate}
                        documentId={documentId}
                        documentInfo={documentInfo}
                        handleUpdate={handleUpdate}
                        freightBillInventories={freightBillInventories}
                    />
                )}
            </Form>

            {confirmDelete && (
                <Modal
                    title={trans('document:delete.title')}
                    visible={confirmDelete}
                    okText={trans('btn.confirm')}
                    cancelText={trans('btn.close')}
                    onOk={deleteDocument}
                    onCancel={() => setConfirmDelete(false)}
                    cancelButtonProps={{ className: '_modal-confirm-delete-document-btn-cancel' }}
                    okButtonProps={{ className: '_modal-confirm-delete-document-btn-ok' }}
                >
                    <p>{trans('document:delete.confirm', { code: <b>#{documentInfo?.code}</b> })}</p>
                </Modal>
            )}

            {confirm && (
                <Modal
                    title={trans('finance:finance_control.title.confirm_document')}
                    visible={confirm}
                    okText={trans('btn.confirm')}
                    cancelText={trans('btn.close')}
                    onOk={handleConfirm}
                    onCancel={() => setConfirm(false)}
                    cancelButtonProps={{ className: '_modal-confirm-btn-cancel' }}
                    okButtonProps={{ className: '_modal-confirm-btn-ok' }}
                >
                    <p className="text-fz-16">
                        {freightBillInventories.filter(info => info.warning).length > 0
                            ? trans('finance:finance_control.title.confirm_document_content_warning', {
                                  count: (
                                      <b>{`${freightBillInventories.filter(info => info.warning).length} ${t(
                                          'finance:finance_control.label.order_warning'
                                      )}`}</b>
                                  ),
                                  code: <b>#{documentInfo?.code}</b>,
                              })
                            : trans('finance:finance_control.title.confirm_document_content_no_warning', {
                                  code: <b>#{documentInfo?.code}</b>,
                              })}
                    </p>
                </Modal>
            )}
        </div>
    );
}
export default FinanceControlDetail;
