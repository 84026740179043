import React, { useEffect } from 'react';

import useAntdTable from '@Modules/App/Hooks/useAntdTable';
import useStoresQuery from '@Modules/Store/Hooks/useStoresQuery';

import AccountsTable from './AccountsTable';

const Body = ({ marketplace }) => {
    const { isLoading, pagination, stores, search } = useAntdTable(useStoresQuery, {
        marketplace_code: marketplace?.code,
    });

    useEffect(() => {
        if (marketplace?.code) {
            search.submit();
        }

        // eslint-disable-next-line
    }, [marketplace]);

    return (
        <div>
            <AccountsTable
                loading={isLoading}
                pagination={pagination}
                accounts={stores}
                onChange={search.submit}
                onSuccessDelete={search.submit}
            />
        </div>
    );
};

export default Body;
