import { Badge } from 'antd'
import lodash, { get } from 'lodash'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import DynamicTable from '@Modules/App/DynamicTable'
import { dateFormatter } from '@Modules/App/services'
import { auth } from '@Modules/Auth/services'
import PERMISSIONS from '@Modules/Auth/services/permissions'
import ExportCosts from '@Modules/Order/components/ExportData/ExportCosts'
import ExportExcel from '@Modules/Order/components/ExportData/ExportExcel'
import { ORDER_STATUS_COLORS } from '@Modules/Order/services/constants'
import { FINANCE_STATUS_COLORS } from '@Modules/PurchasingPackage/services/constants'

import { t } from '@System/i18n'
import { url } from '@System/routing'

export default function List({ canViewCustomer, orders, pagination, addFilter, filters, loading, setting }) {
    const getColumns = useMemo(
        () => [
            {
                className: '_orders-order-code text-nowrap',
                title: t('order:label.order_code'),
                dataIndex: ['order', 'code'],
                key: 'order.order_code',
                render: (text, record) => {
                    const isCombo = get(record, 'order_sku_combo', []).length
                    const refCode = get(record, 'order.ref_code', [])
                    return (
                        <div>
                            <div className="d-flex">
                                <div className="d-flex flex-column">
                                    <Link to={url.to('orders.detail', { id: record?.order?.id })}>{text}</Link>
                                    <small className="text-muted">{refCode}</small>
                                </div>
                                {isCombo ? (
                                    <span
                                        className="combo-tag ml-2"
                                        style={{ maxWidth: 80 }}
                                    >
                                        {t('label.combo')}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                    )
                },
                disabled: true,
            },
            {
                className: '_orders-merchant-code text-nowrap',
                title: t('order:label.seller_info'),
                dataIndex: 'merchant',
                key: 'order.merchant',
                render: text => {
                    const name = text?.name ?? ''
                    const code = text?.code ?? ''
                    return setting.encode_info
                        ? auth.can(PERMISSIONS.ORDER_VIEW_LIST_ORIGINAL)
                            ? (name ? name : '') + (code ? ' - ' + code : '')
                            : name
                        : (name ? name : '') + (code ? ' - ' + code : '')
                },
            },
            {
                className: '_orders-receiver-info text-nowrap',
                title: t('order:label.receiver_info'),
                dataIndex: 'order',
                key: 'order.receiver_info',
                render: text => {
                    const name = text?.receiver_name
                    const phone = canViewCustomer ? text?.receiver_phone : ''

                    return (name ? name : '') + (phone ? ' - ' + phone : '')
                },
            },
            {
                className: '_orders-status text-nowrap',
                title: t('label.order_status'),
                dataIndex: ['order', 'status'],
                key: 'order.status',
                render: text =>
                    text ? (
                        <Badge
                            color={ORDER_STATUS_COLORS[text]}
                            text={t(`order:status.${text}`)}
                        />
                    ) : null,
            },
            {
                className: '_orders-intended-delivery-at text-nowrap',
                title: t('order:label.intended_delivery_at'),
                dataIndex: ['order', 'intended_delivery_at'],
                key: 'order.intended_delivery_at',
                render: text => (text ? dateFormatter.full(text) : ''),
            },
            {
                className: '_orders-created-at text-nowrap',
                title: t('order:label.created_at'),
                dataIndex: ['order', 'created_at'],
                key: 'order.created_at',
                render: text => (text ? dateFormatter.full(text) : ''),
            },
            {
                className: '_orders-finance-status text-nowrap',
                title: t('label.finance_status'),
                dataIndex: ['order', 'finance_status'],
                key: 'order.finance_status',
                render: text => {
                    return text ? (
                        <Badge
                            color={FINANCE_STATUS_COLORS[text]}
                            text={t(`product:FINANCE_STATUS.${text}`)}
                        />
                    ) : null
                },
            },
            {
                className: '_orders-order_source text-nowrap',
                title: t('order:label.order_source'),
                dataIndex: ['order', 'marketplace_code'],
                key: 'order_source',
                render: text => {
                    return setting.encode_info ? (
                        auth.can(PERMISSIONS.ORDER_VIEW_LIST_ORIGINAL) ? (
                            <p>{t(`order:MARKET_PLACE.${text}`)}</p>
                        ) : (
                            "***"
                        )
                    ) : (
                        <p>{t(`order:MARKET_PLACE.${text}`)}</p>
                    )
                },
            },
            {
                className: '_orders-creator-order text-nowrap',
                title: t('orderDetail:label.creator'),
                dataIndex: ['creator', 'username'],
                key: 'creator-order',
                render: text => {
                    return text ? <p>{text}</p> : '---'
                },
            },
        ],
        [canViewCustomer]
    )

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {t('title.list-orders')} ({lodash.isEmpty(pagination) ? 0 : pagination.total})
                    </h3>
                    <div>
                        {auth.can(PERMISSIONS.ORDER_CHANGE_FINANCIAL_STATUS) && <ExportCosts filters={filters} />}
                        <ExportExcel filters={filters} />
                    </div>
                </div>
                <div className="data-box--body">
                    <DynamicTable
                        initColumns={getColumns}
                        dataSource={orders}
                        className="_list-table-product"
                        loading={loading}
                        rowKey={record => record?.order?.id}
                    />

                    <CustomizedPagination
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                    />
                </div>
            </div>
        </div>
    )
}
