import _ from "lodash";

export function Pipe(data, fns) {
    return fns.reduce((ctx, fn) => fn(ctx, data), {});
}

export function checkUnique(ctx, data) {
    let errors = _.get(ctx, "errors", {});
    let valid = _.get(ctx, "valid", true);
    let existedSku = [];

    data.forEach((item, index) => {
        let line = `line_${index+1}`;
        if (item.sku_id && item.warehouse_area_id && existedSku.includes(`${item.sku_id}_${item.warehouse_area_id}`)) {
            errors[line] = errors[line] ? [...errors[line], "unique_row"] : ["unique_row"];
            valid = false;
        } else {
            existedSku.push(`${item.sku_id}_${item.warehouse_area_id}`);
            if (errors[line])
                errors[line] = errors[line].filter(error => error !== "unique_row");
        }
    });
    return {valid, errors};
}

export function checkGte(ctx, data) {
    let errors = _.get(ctx, "errors", {});
    let valid = _.get(ctx, "valid", true);

    data.forEach((item, index) => {
        let line = `line_${index+1}`;
        if (item.quantity && item.stock && item.quantity > item.stock) {
            errors[line] = errors[line] ? [...errors[line], "gte_stock"] : ["gte_stock"];
            valid = false;
        } else {
            if (errors[line])
                errors[line] = errors[line].filter(error => error !== "gte_stock");
        }
    });
    return {valid, errors};
}

export function checkFull(ctx, data) {
    let errors = _.get(ctx, "errors", {});
    let valid = _.get(ctx, "valid", true);

    data.forEach((item, index) => {
        let line = `line_${index+1}`;
        if (!item.sku_id || !item.warehouse_area_id || !item.quantity) {
            errors[line] = errors[line] ? [...errors[line], "not_enough"] : ["not_enough"];
            valid = false;
        } else {
            if (errors[line])
                errors[line] = errors[line].filter(error => error !== "not_enough");
        }
    });
    return {valid, errors};
}
