import { CheckOutlined, UndoOutlined } from '@ant-design/icons'
import { Table, Button, Space, Badge, Alert, Avatar, Tooltip, Typography, Input, Row, Col } from 'antd'
import { get, filter, includes } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Box from '@Modules/App/Box'
import { auth } from '@Modules/Auth/services'

import { t } from '@System/i18n'
import { url } from '@System/routing'

import CustomizedPagination from '../../App/CustomizedPagination'
import TableEmpty from '../../App/Utilities/TableEmpty'
import { SHIPPING_PARTNER_INFO } from '../../App/services/constants'
import { ORDER_PACKING_STATUS, EXPORT_ORDER_STATUS } from '../../Order/services/constants'
import { BARCODE_TYPE } from '../services/constants'

const { Title } = Typography

function ListOfLading(props) {
    const [inputSearch, setInputSearch] = useState()
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const hasSelected = selectedRowKeys.length > 0
    const rowSelection = { selectedRowKeys, onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys) }
    const {
        type,
        error,
        loading,
        pagination,
        dataSource,
        barcodeType,
        statusAllow,
        loadingSubmit,
        handleOk,
        handleCancel,
        showResetButton,
        handleChangePage,
        handleSearchTable,
        removeScanOrderInfo,
    } = props

    function handleRemoveOrderPackingInfoSelected() {
        removeScanOrderInfo(selectedRowKeys)
        setSelectedRowKeys([])
    }

    function renderStatusInfo(status) {
        const isAllow = includes(statusAllow, status)
        if (status) status = status.toUpperCase()
        const className = isAllow || showResetButton ? '' : 'text-danger'
        let statusInfo = {}
        switch (type) {
            case 'confirm_packing':
                statusInfo = ORDER_PACKING_STATUS[status]
                break
            case 'export_order':
                statusInfo = EXPORT_ORDER_STATUS[status]
                break
            default:
                break
        }
        return { className, ...statusInfo }
    }

    const titleAction = {
        confirm_packing: {
            start_new_turn: t('order:btn.start_new_confirm'),
            cancel: t('order:btn.reset_confirm'),
            ok: t('order:btn.confirm'),
        },
        export_order: {
            start_new_turn: t('order:btn.start_create_document_export'),
            cancel: t('order:btn.cancel'),
            ok: t('order:btn.create_document_export'),
        },
    }

    const columns = [
        {
            className: '_scan-order-list-of-lading-numerical-order',
            title: t('order:label.numerical_order'),
            dataIndex: 'stt',
            key: 'stt',
            responsive: ['xl'],
            render: (text, record, index) => {
                const pageSize = get(pagination, 'per_page')
                const page = get(pagination, 'page')
                return (page - 1) * pageSize + index + 1
            },
        },
        {
            className: 'avatar-shipping-partner _scan-order-list-of-lading-shipping-partner',
            dataIndex: 'shipping_partner',
            key: 'shipping_partner',
            width: '0px',
            render: text => {
                const code = get(text, 'code', '')
                const shipping_partner_info = get(SHIPPING_PARTNER_INFO, code, {})
                return (
                    <Tooltip title={text?.name}>
                        <Avatar
                            style={{ backgroundColor: `${get(shipping_partner_info, 'color', '')}` }}
                            className="flex-shrink-0"
                            size={20}
                        >
                            {get(shipping_partner_info, 'wildcard', '')}
                        </Avatar>
                    </Tooltip>
                )
            },
        },
        {
            className: '_scan-order-list-of-lading-freight-bill-code',
            title: t('order:label.freight_bill'),
            dataIndex: 'freight_bill_code',
            key: 'freight_bill_code',
            render: (text, record) => {
                const statusInfo = renderStatusInfo(record.status)
                return (
                    <Link
                        className={statusInfo.className}
                        to={url.to('orders.detail', { id: record.order_id })}
                    >
                        {text}
                    </Link>
                )
            },
        },
        {
            className: '_scan-order-list-of-lading-order-code',
            title: t('order:label.order_code'),
            dataIndex: 'order_code',
            key: 'order_code',
            render: (text, record) => {
                const statusInfo = renderStatusInfo(record.status)
                return (
                    <Link
                        className={statusInfo.className}
                        to={url.to('orders.detail', { id: record.order_id })}
                    >
                        {text}
                    </Link>
                )
            },
        },
        {
            className: '_scan-order-list-of-lading-sku-info',
            title: t('order:label.sku_info'),
            dataIndex: 'skus',
            key: 'skus',
            render: (text, record) => {
                const statusInfo = renderStatusInfo(record.status)
                const skus = record.skus
                return skus.map((item, index) => {
                    const sku = get(item, 'sku', {})
                    return (
                        <p key={index}>
                            <Link
                                className={statusInfo.className}
                                to={url.to('products.detail', { id: get(sku, 'product_id', 0) })}
                            >
                                {get(sku, 'code', '')}
                            </Link>
                        </p>
                    )
                })
            },
        },
        {
            className: '_scan-order-list-of-lading-quantity',
            title: t('order:label.quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => {
                const skus = record.skus
                return skus.map((item, index) => {
                    return <p key={index}>{get(item, 'quantity', 0)}</p>
                })
            },
        },
        {
            className: '_scan-order-list-of-lading-scanner-info',
            title: t('order:label.scanner'),
            dataIndex: 'scanner',
            key: 'scanner',
            render: (text, record) => {
                const user = auth.user()
                return (
                    <>
                        <p>
                            {get(user, 'name', '')}({get(user, 'username', '')})
                        </p>
                        <p>{record.scan_created_at ? moment(record.scan_created_at).format('DD/MM/YYYY HH:mm') : ''}</p>
                    </>
                )
            },
        },
        {
            className: '_scan-order-list-of-lading-status',
            title: t('order:label.status'),
            dataIndex: 'status',
            key: 'status',
            responsive: ['xl'],
            render: text => {
                const statusInfo = renderStatusInfo(text)
                return (
                    <p className="text-dark">
                        <Badge color={statusInfo.color} />
                        {statusInfo.text_name}
                    </p>
                )
            },
        },
        {
            dataIndex: 'id',
            key: 'action',
            render: text => {
                return (
                    <Button
                        className="_scan-order-list-of-lading-btn-action-remove-info"
                        style={{ backgroundColor: '#EAEEFF', color: '#4551BB', borderRadius: '4px' }}
                        onClick={() => removeScanOrderInfo([text])}
                    >
                        {t('btn.delete')}
                    </Button>
                )
            },
        },
    ]

    const renderColumn = () => {
        if (barcodeType === BARCODE_TYPE.FREIGHT_BILL) {
            return columns
        } else {
            return filter(columns, item => item.key !== 'freight_bill_code')
        }
    }

    function handleReset() {
        handleCancel()
        handleSearchTable(undefined)
        setInputSearch(undefined)
    }

    return (
        <Box className={props.className}>
            <div className="d-flex flex-wrap justify-content-between pb-3 align-items-center">
                <Space className="order-0">
                    <Title level={3}>
                        {barcodeType ? t(`order:title.barcode_type.${BARCODE_TYPE[barcodeType]}`) : t(`order:title.barcode_type.FREIGHT_BILL`)}
                    </Title>
                    <span className="number-circle _scan-order-list-of-lading-total">{props.totalData}</span>
                </Space>

                {hasSelected && dataSource.length > 0 && (
                    <div className="mb-3 mb-xl-0 order-2 order-lg-1">
                        <Button
                            type="primary"
                            className="_scan-order-list-of-lading-btn-remove-selected"
                            onClick={handleRemoveOrderPackingInfoSelected}
                        >
                            {t('order:btn.remove_selected')}
                        </Button>
                    </div>
                )}
                <div className="order-1 order-lg-2">
                    {!!showResetButton && (
                        <Button
                            type="primary"
                            onClick={handleCancel}
                            icon={<UndoOutlined />}
                            className="mr-2 mr-xs-0 mb-3 mb-xl-0 _scan-order-list-of-lading-btn-start-new-turn"
                        >
                            {titleAction[type].start_new_turn}
                        </Button>
                    )}
                    {!!(dataSource.length > 0 && !showResetButton) && (
                        <>
                            <Button
                                onClick={handleReset}
                                icon={<UndoOutlined />}
                                className="mr-2 mr-xs-0 mb-3 mb-xl-0 _scan-order-list-of-lading-btn-cancel"
                            >
                                {titleAction[type].cancel}
                            </Button>
                            <Button
                                onClick={handleOk}
                                loading={loadingSubmit}
                                type="primary"
                                icon={<CheckOutlined />}
                                className="_scan-order-list-of-lading-btn-ok"
                            >
                                {titleAction[type].ok}
                            </Button>
                        </>
                    )}
                </div>
            </div>

            {error && (
                <div className="p-2 pb-3 pt-0">
                    <Alert
                        className="p-2 pb-3 pt-0"
                        description={error}
                        type="error"
                        closable
                    />
                </div>
            )}

            <div className="border-top">
                <Row className={'justify-content-end p-2'}>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                    >
                        <Input.Search
                            value={inputSearch}
                            onChange={e => setInputSearch(e.target.value)}
                            placeholder={t(`order:placeholder.search_code`)}
                            onSearch={() => handleSearchTable(inputSearch)}
                            enterButton
                            autoComplete="off"
                            className="_scan-order-list-of-lading-btn-search"
                        />
                    </Col>
                </Row>
                <Table
                    loading={loading}
                    className="table-list-of-lading _scan-order-list-of-lading"
                    rowClassName={record => {
                        const statusInfo = renderStatusInfo(record.status)
                        return `align-top ${statusInfo.className}`
                    }}
                    rowSelection={rowSelection}
                    dataSource={dataSource}
                    columns={renderColumn()}
                    pagination={false}
                    scroll={{ x: 576 }}
                    locale={{
                        emptyText: (
                            <TableEmpty
                                className="_scan-order-list-of-lading-no-data"
                                loading={loading}
                            />
                        ),
                    }}
                />
                <CustomizedPagination
                    pagination={pagination}
                    filters={pagination}
                    addFilter={handleChangePage}
                />
            </div>
        </Box>
    )
}

export default ListOfLading
