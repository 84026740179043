import { SaveOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { filter, flatten, isEmpty, map } from 'lodash'
import React, { useState, useEffect } from 'react'

import api from '@Modules/PurchasingPackage/services/api'

import { t } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

import InfoCreate from './InfoCreate'
import InfoProduct from './InfoProduct'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'

const PurchasingPackageCreate = () => {
    const [form] = Form.useForm()
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        setNavigator(t('title.purchasing_packages'), [
            {
                name: t('packaging:btn.purchase_create'),
            },
        ])
        setDocumentTitle(t('packaging:btn.purchase_create'))
    }, [])


    const onCreatePackage = () => {
        form.validateFields().then(values => {
            let weight = undefined

            if (getVar(values, 'weight', undefined)) {
                weight = values.weight / 1000
            }

            const getListHasBatch = filter(products, item => !isEmpty(item.batch_of_goods))
            const getListNotInBatch = filter(products, item => isEmpty(item.batch_of_goods))

            // const listBatchOfGoods = map(flatten(map(getListHasBatch, 'batch_of_goods')), item => ({ ...item, sku_id: item.sku_child_id }))
            const listBatchOfGoods = flatten(map(getListHasBatch, item => {

                return map(item.batch_of_goods, batch=>{

                    if(batch.id){
                        return {...batch,sku_id: batch.sku_child_id}
                    }else{
                        return {...batch,sku_id: item.sku_id}
                    }
                    
                })
               
            }))


            const data = { ...values, weight, package_items: [...listBatchOfGoods, ...getListNotInBatch] }
            setLoading(true)
          
            api.createPackage(data)
                .then(data => {
                    url.redirectTo('purchasing_packages.list')
                    notification.success(t('message.create_success', { attribute: t('product:label.import_package') }))
                })
                .catch(err => {
                    const status = getVar(err, 'response.status', false)
                    if (status === 400) {
                        renderError(getVar(err, 'response.data.data', {}))
                    } else {
                        notification.error(t('message.create_failed', { attribute: t('product:label.import_package') }))
                    }
                })
                .finally(() => setLoading(false))
        })
    }

    function renderError(errors) {
        const fields_enable = ['freight_bill_code', 'destination_warehouse_id', 'shipping_partner_id', 'weight']
        const error_messages = []
        Object.keys(errors).forEach(item => {
            let key_errors = []
            Object.keys(errors[item]).forEach(error => key_errors.push(t(`errorMessages.${error}`, { attribute: t(`product:label.${item}`) })))
            if (fields_enable.includes(item)) {
                error_messages.push({ name: item, errors: key_errors })
            } else {
                notification.error(key_errors.join(', '))
            }
        })
        form.setFields(error_messages)
    }

    return (
        <Form form={form}>
            <div className="site-content">
                <div className="d-flex justify-content-between mb-4">
                    <h3 className="text-fz-18">{t('packaging:btn.purchase_create')}</h3>
                    <Button
                        icon={<SaveOutlined />}
                        type="primary"
                        className="ml-3"
                        onClick={onCreatePackage}
                        loading={loading}
                    >
                        {t('btn.create')}
                    </Button>
                </div>
                <InfoCreate form={form} />
                <InfoProduct
                    products={products}
                    setProducts={setProducts}
                    form={form}
                />
            </div>
        </Form>
    )
}

export default PurchasingPackageCreate
