import { filter, get, isObject } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import ScanErrors from '@Modules/App/ScanErrors';
import withScanErrors from '@Modules/App/ScanErrors/withScanErrors';
import { getPageSizeScale } from '@Modules/App/services/helpers';
import renderErrorScan from '@Modules/ScanOrder/components/customerError';

import { scanBarcodeTypeStorage } from '../../App/services';
import ListOfLading from './ListOfLading';
import TotalProducts from './TotalProducts';
import { calculatorTotalSku } from './customerFunction';

import styles from './../screens/scan-order.module.scss';

function ListOrderInfo(props) {
    const {
        type,
        error,
        loading,
        dataSource,
        loadingSubmit,
        statusAllow,
        handleOk,
        handleCancel,
        showResetButton,
        removeScanOrderInfo,
        errorKey,
    } = props;

    const barcodeType = scanBarcodeTypeStorage.value;
    const [currentData, setCurrentData] = useState([]);
    const [filterData, setFilterData] = useState(null);
    const [pagination, setPagination] = useState({
        current_page: 1,
        page: 1,
        per_page: getPageSizeScale(),
    });

    useEffect(() => {
        const total = dataSource.length;
        const pageSize = get(pagination, 'per_page');
        setPagination({
            ...pagination,
            total,
            page_total: Math.ceil(total / pageSize),
        });

        // eslint-disable-next-line
    }, [dataSource]);

    useEffect(() => {
        let total = dataSource.length;
        if (filterData !== null) total = filterData.length;
        const pageSize = get(pagination, 'per_page');
        setPagination({
            ...pagination,
            total,
            page_total: Math.ceil(total / pageSize),
        });

        // eslint-disable-next-line
    }, [filterData]);

    useEffect(() => {
        const page = get(pagination, 'page');
        const pageSize = get(pagination, 'per_page');
        const offset = (page - 1) * pageSize;
        if (filterData !== null) {
            setCurrentData(filterData.slice(offset, offset + pageSize));
        } else {
            setCurrentData(dataSource.slice(offset, offset + pageSize));
        }

        // eslint-disable-next-line
    }, [pagination]);

    function handleChangePage(data) {
        let total = get(pagination, 'total');
        let page = get(data, 'page', get(pagination, 'page'));
        let per_page = get(data, 'per_page', get(pagination, 'per_page'));
        const page_total = Math.ceil(total / per_page);
        setPagination({
            ...pagination,
            ...data,
            page_total,
            current_page: page,
        });
    }

    function handleSearchTable(value) {
        if (value) {
            const data = filter(
                dataSource,
                item =>
                    item['freight_bill_code'].toString().toLowerCase().includes(value.toLowerCase()) ||
                    item['order_code'].toString().toLowerCase().includes(value.toLowerCase())
            );
            setFilterData(data);
        } else {
            setFilterData(null);
        }
    }

    function renderSkuData() {
        const skus = [];
        currentData.map(item => {
            return skus.push(...item.skus);
        });
        return calculatorTotalSku(skus);
    }

    const renderScanErrors = useCallback(errors => {
        if (isObject(errors)) {
            return renderErrorScan(errors);
        }

        return errors;
    }, []);

    const ScanErrorsWithErrors = withScanErrors(ScanErrors, errorKey);

    return (
        <>
            <ScanErrorsWithErrors errorMessageRender={renderScanErrors} />
            <ListOfLading
                totalData={filterData !== null ? filterData.length : dataSource.length}
                className={styles.main}
                type={type}
                error={error}
                loading={loading}
                pagination={pagination}
                dataSource={currentData}
                barcodeType={barcodeType}
                statusAllow={statusAllow}
                loadingSubmit={loadingSubmit}
                handleOk={handleOk}
                handleCancel={handleCancel}
                showResetButton={showResetButton}
                handleChangePage={handleChangePage}
                handleSearchTable={handleSearchTable}
                removeScanOrderInfo={removeScanOrderInfo}
            />
            <TotalProducts className={styles.aside} data={renderSkuData()} />
        </>
    );
}

export default ListOrderInfo;
