import { Button, Modal, Tabs, Tooltip } from 'antd'
import { get, isEmpty, map } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import api from '@Modules/ServicesManager/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'

import SellerServiceList from './SellerServiceList'
import SellerServiceLog from './SellerServiceLog'
import { WarningOutlined } from '@ant-design/icons'

interface IModalSellerServiceProps {
    servicePack: any
    handleHideModal: () => void
    countryId: number
    servicePackId: number
}

const { TabPane } = Tabs

const ModalSellerService: React.FC<IModalSellerServiceProps> = ({ servicePack, handleHideModal, countryId, servicePackId }) => {
    const [selectedKey, setSelectedKey] = useState('list')
    const [sellers, setSellers] = useState<any>([])
    const [loadingSave, setLoadingSave] = useState(false)
    const [loadingList, setLoadingList] = useState(false)
    const [sellersAdded, setSellersAdded] = useState([])

    const getDetails = useCallback(async () => {
        setLoadingList(true)
        try {
            const res = await api.getServicesPackDetail(servicePackId)
            setSellers(get(res, 'data.merchants'))
        } catch (error) {
            processResponseError(error)
        } finally {
            setLoadingList(false)
        }
    }, [])

    useEffect(() => {
        getDetails()
    }, [])

    const onChangeTab = key => {
        setSelectedKey(key)
    }

    const handleSave = () => {
        if (isEmpty(sellersAdded)) {
            return
        }

        const data = {
            seller_ids: map(sellersAdded, 'merchant.id'),
        }

        setLoadingSave(true)
        api.editSellerServicePack(servicePackId, data)
            .then(res => {
                getDetails()
                setSellersAdded([])
                notification.success(t('serviceManager:message.add_seller_success'))
            })
            .catch(res => {
                notification.error(t('serviceManager:message.add_seller_failed'))
            })
            .finally(() => setLoadingSave(false))
    }

    return (
        <Modal
            title={
                <div className="d-flex align-items-center">
                    <p>{`${t('label.service_pack')}: ${get(servicePack, 'name')}`}</p>
                    <Tooltip title={t('serviceManager:message.warning_change_seller')}>
                        <WarningOutlined className="ml-2 cursor-pointer text-warning" />
                    </Tooltip>
                </div>
            }
            visible={true}
            cancelText={t('btn.close')}
            onCancel={handleHideModal}
            width={800}
            maskClosable={false}
            bodyStyle={{ paddingTop: '0' }}
            footer={[
                <Button
                    key="back"
                    onClick={handleHideModal}
                >
                    {t('btn.close')}
                </Button>,
                auth.can(permissions.SERVICE_ADD) && (
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleSave}
                        loading={loadingSave}
                    >
                        {t('btn.save')}
                    </Button>
                ),
            ]}
        >
            <Tabs
                className="heading-fz-14 service-manager-tabs"
                type="card"
                onChange={onChangeTab}
                activeKey={selectedKey}
            >
                <TabPane
                    tab={t('heading.list-merchants')}
                    key="list"
                >
                    <SellerServiceList
                        countryId={countryId}
                        sellers={sellers}
                        sellersAdded={sellersAdded}
                        setSellerAdded={setSellersAdded}
                        loading={loadingList}
                        servicePackId={get(servicePack, 'id')}
                    />
                </TabPane>
                <TabPane
                    tab={t('label.history_change')}
                    key="log"
                >
                    <SellerServiceLog
                        servicePackId={get(servicePack, 'id')}
                        activeKey={selectedKey}
                    />
                </TabPane>
            </Tabs>
        </Modal>
    )
}

export default ModalSellerService
