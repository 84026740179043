import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';

export default function InlineEdit({ text, children, isEdit, can, setIsEdit, className }) {
    return isEdit && can !== false ? (
        children
    ) : (
        <span className={`a--cursor-poiter a--hover-edit ${className ? className : ''}`} onClick={() => can && setIsEdit(true)}>
            {text}
            {can && (
                <Tooltip placement="top" title={'Click để chỉnh sửa'}>
                    <EditOutlined className={'ml-1'} />
                </Tooltip>
            )}
        </span>
    );
}
