import React from "react";
import {Row, Col} from "antd";
import {t} from "../../../../../system/i18n";

export default function Inspection({stock, index}) {
    const {orderStock, warehouse, warehouseArea} = stock;
    return (
        <Row className={`pl-5 ${index > 0 ? "mt-2" : ""}`} key={index}>
            <Col span={6}>
                <span className="text-color-label">{t("order:label.export_warehouse")}</span>
                <p className="_warehouse-info">{warehouse.code} - {warehouse.name}</p>
            </Col>
            <Col span={10}>
                <span className="text-color-label">{t("label.warehouse_area")}</span>
                <p className="_warehouse-area-info">{warehouseArea.code === "default" ? t("order:label.default") : warehouseArea.code} - {warehouseArea.name === "default" ? t("order:label.default") : warehouseArea.name}</p>
            </Col>
            <Col span={4} className="text-right">
                <span className="text-color-label">{t("order:label.quantity")}</span>
                <p className="_quantity-info">{orderStock.quantity}</p>
            </Col>
        </Row>
    );
}
