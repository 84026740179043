import { Select } from 'antd';
import React, { useEffect, useState } from 'react';

import ServiceMerchantsAssigned from '@Modules/Merchant/services/ServiceMerchantsAssigned';

const { Option } = Select;

export default function SelectMerchantsAssigned({ loading, value, ...props }) {
    let [data, setData] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceMerchantsAssigned.list()
            .then(res => {
                setData(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <Select
            {...props}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            loading={isLoading || loading}
            optionFilterProp="label"
            showSearch
            value={data.length > 0 && value ? value : undefined}
        >
            {data.map(merchant => {
                return (
                    <Option key={merchant?.id} value={merchant?.id}>
                        {`${merchant?.code}${merchant?.name ? ` - ${merchant?.name}` : ''}`}
                    </Option>
                );
            })}
        </Select>
    );
}
