import { useQuery } from 'react-query';

import apiService from './../services/api';

const useMerchantItemsQuery = params =>
    useQuery(['merchant-items', params], () => apiService.getMerchantItems(params), {
        retry: false,
    });

export default useMerchantItemsQuery;
