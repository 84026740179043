import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useResyncMultiInvalidOrdersMutation = createUseMutation({
    namespace: 'order:resync_multi',
    mutation: data => apiService.resyncMultiInvalidOrders(data),
});

export default useResyncMultiInvalidOrdersMutation;
