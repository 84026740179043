import { ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { t } from '../../../../system/i18n';
import { warehouseAreaStorage, warehouseStorage } from '../../../App/services';
import SelectWarehouse from '../../../Warehouse/components/SelectWarehouse';
import SelectWarehouseArea from '../../../WarehouseArea/components/SelectWarehouseArea';
import SelectMerchantItems from "@Modules/Merchant/components/SelectMerchantItems";

const { Option } = Select;

export default function FormSearch({ filters, onSearch }) {
    const [input, setInput] = useState(filters);
    const { sku_code, sku_name, warehouse_id, warehouse_area_id, out_of_stock, merchant_id, sku_id } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        let params = {};
        if (name === 'warehouse_id') {
            params = { warehouse_area_id: undefined };
        }
        const filters = { ...input, ...params, [name]: value };
        setInput(filters);
        _onSubmit(filters);
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        setInput(prevState => {
            return { ...prevState, ...input, [name]: value };
        });
    }

    function _onSubmit(filters) {
        onSearch(filters);
    }

    function _onPressEnter(e) {
        const { name, value } = e.target;
        _onSubmit({ ...input, [name]: value });
    }

    function _onReset() {
        warehouseStorage.reset();
        warehouseAreaStorage.reset();
        onSearch({});
    }

    return (
        <div className="m-3">
            <Form layout="vertical">
                <Row gutter={10} className="pb-3">
                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item label={t('product:label.sku_id')}>
                            <Input
                                className="_inventory-search-sku-id"
                                name="sku_id"
                                value={sku_id}
                                placeholder={t('product:placeholder.sku_id')}
                                onChange={_onChangeInput}
                                onPressEnter={_onPressEnter}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item label={t('product:label.sku_code')}>
                            <Input
                                className="_inventory-search-sku-code"
                                name="sku_code"
                                value={sku_code}
                                placeholder={t('product:placeholder.sku_code')}
                                onChange={_onChangeInput}
                                onPressEnter={_onPressEnter}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item label={t('product:label.sku_name')}>
                            <Input
                                className="_inventory-search-sku-name"
                                name="sku_name"
                                value={sku_name}
                                placeholder={t('product:placeholder.sku_name')}
                                onChange={_onChangeInput}
                                onPressEnter={_onPressEnter}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item label={t('product:label.warehouse_name')}>
                            <SelectWarehouse
                                noSuggest={false}
                                className="_inventory-search-warehouse-name"
                                allowClear={true}
                                placeholder={t('product:placeholder.warehouse_name')}
                                value={warehouse_id ? parseInt(warehouse_id) : undefined}
                                onChange={value => _onChange('warehouse_id', value)}
                            />
                        </Form.Item>
                    </Col>
                    {warehouse_id && (
                        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                            <Form.Item label={t('product:label.warehouse_area_name')}>
                                <SelectWarehouseArea
                                    className="_inventory-search-warehouse-area-name"
                                    allowClear={true}
                                    placeholder={t('product:placeholder.warehouse_area_name')}
                                    warehouseid={warehouse_id ? parseInt(warehouse_id) : undefined}
                                    value={warehouse_area_id ? parseInt(warehouse_area_id) : undefined}
                                    onChange={value => _onChange('warehouse_area_id', value)}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item label={t('product:label.stock_status')}>
                            <Select
                                className="_inventory-search-stock-status"
                                placeholder={t('product:placeholder.stock_status')}
                                onChange={value => _onChange('out_of_stock', value)}
                                value={[0, 1].includes(parseInt(out_of_stock)) ? parseInt(out_of_stock) : undefined}
                                optionFilterProp="children"
                            >
                                <Option key="0" value={1}>
                                    {t('product:stock_status.OUT_OF_STOCK')}
                                </Option>
                                <Option key="1" value={0}>
                                    {t('product:stock_status.IN_STOCK')}
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item className="mb-3" label={t('label.seller_info')}>
                            <SelectMerchantItems
                                className={'_inventory-search-merchant-id'}
                                placeholder={t('placeholder.select_seller')}
                                allowClear={true}
                                value={merchant_id ? parseInt(merchant_id) : undefined}
                                onChange={value => _onChange('merchant_id', value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: warehouse_id ? 24 : 12 }}
                        className="pt-4 text-right"
                        style={{ marginTop: '6px' }}
                    >
                        <Button icon={<ReloadOutlined />} className="_inventory-search-btn-reset" onClick={_onReset}>
                            {t('common:btn.reset')}
                        </Button>
                        <Button
                            className="ml-3 _inventory-search-btn-ok"
                            type="primary"
                            icon={<SearchOutlined />}
                            onClick={() => _onSubmit(input)}
                        >
                            {t('common:btn.ok')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}
