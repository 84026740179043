import { Button, Modal, Table } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React from 'react';

import { t } from '@System/i18n';

import useCreateFreightBillMutation from '../Hooks/useCreateFreightBillMutation';

const ModalCreateFreightBill = ({ cancelVisibleModal, orderPackingsError, isSelectAllPage, ids, filters, pickupType }) => {
    const { isLoading: creating, mutate: createFreightBill } = useCreateFreightBillMutation();

    const columns = [
        {
            title: t('purchasingOrder:label.order'),
            key: 'order_code',
            dataIndex: 'code',
        },
        {
            title: t('sku:label.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>;
            },
        },
        {
            title: t('orderDetail:label.deliveried_date'),
            dataIndex: 'intended_delivery_at',
            key: 'intended_delivery_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>;
            },
        },
        {
            title: t('order:label.sku_missing'),
            key: 'missed_skus',
            dataIndex: 'missed_skus',
            render: text => {
                return text.map(item => (
                    <div key={item.sku}>
                        {t('order:label.missing_sku_show', { name: item.sku, quantity: item.quantity })}
                    </div>
                ));
            },
        },
    ];

    const handleCreateBill = key => {
        const params = {
            ...filters,
            pickup_type: pickupType,
            ids: isSelectAllPage ? [] : ids,
        };

        const orderPackingsErrorIds = map(orderPackingsError, 'id');

        if (key === 'continue') {
            createFreightBill(params);
        } else {
            createFreightBill({
                ...params,
                ignore_ids: orderPackingsErrorIds,
            });
        }
        cancelVisibleModal();
    };

    return (
        <>
            <Modal
                title={t('order:title.not_eligible_shipment')}
                className="_modal-create-freight__bill"
                visible={true}
                width={800}
                onCancel={cancelVisibleModal}
                footer={[
                    <Button key="back" onClick={() => handleCreateBill('continue')} loading={creating}>
                        {t('order:btn.keep_creating')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => handleCreateBill('pass')} loading={creating}>
                        {t('btn.pass')}
                    </Button>,
                ]}
            >
                <p className="mb-2">
                    {t('order:title.orders_not_eligible_freight_bill', { count: orderPackingsError?.length })}
                </p>
                <Table columns={columns} rowKey={record => record?.code} dataSource={orderPackingsError} />
            </Modal>
        </>
    );
};

export default ModalCreateFreightBill;
