import lodash from 'lodash'

import { t } from '../../../system/i18n'

const ERRORS = [
    'not_exist',
    'already_exist',
    'required',
    'sku_already_exist',
    'order_already_exist',
    'duplicated',
    'invalid',
    'gte',
    'gt',
    'greater',
    'numeric',
    'empty',
    'exists',
    'user_merchant',
    'in',
    'finished_without_freight_bill',
    'finished_with_freight_bill',
    'status_invalid',
    'fail_when_auto_inspection',
    'receiver_country_invalid',
    'receiver_province_invalid',
    'receiver_district_invalid',
    'receiver_ward_invalid',
    'not_to_access_order',
]

export function renderError(error) {
    const line = lodash.get(error, 'line', '')
    const content = lodash.get(error, 'errors', {})
    let messages = []
    Object.entries(content).forEach(entry => {
        let [key, errors] = entry
        ERRORS.forEach(errorKey => {
            if (errors.hasOwnProperty(errorKey)) {
                if (errorKey === 'invalid' && ['order_no_freight_bill', 'order_receiver_postal_code'].includes(key)) {
                    messages.push(t(`order:message.upload_file.${key}`))
                } else {
                    if (errorKey === 'duplicated') {
                        let message = t(`order:message.upload_file.${errorKey}`, {
                            attribute: t(`order:label.${key}`),
                            value: lodash.get(error, key, ''),
                        })
                        if (key === 'order_code') {
                            const merchant_code = lodash.get(error, 'merchant_code', '')
                            if (!lodash.isEmpty(merchant_code)) {
                                message = t(`order:message.upload_file.${errorKey}_order_code_merchant`, {
                                    attribute: t(`order:label.${key}`),
                                    value: lodash.get(error, key, ''),
                                    merchant: merchant_code,
                                })
                            }
                        }
                        messages.push(message)
                    } else {
                        messages.push(
                            t(`order:message.upload_file.${errorKey}`, {
                                attribute: t(`order:label.${key}`),
                            })
                        )
                    }
                }
            }
        })
    })

    if (messages.length > 0) return t('product:message.upload_file.line', { line: line, content: messages.join(' ') })
    return t('product:message.upload_file.unknown_error')
}

export function renderStatusError(error) {
    const line = lodash.get(error, 'line', '')
    const content = lodash.get(error, 'errors', {})
    const sheet = lodash.get(error, 'sheet', 'order_status')
    const sku_code = lodash.get(error, 'sku_code', '')
    let messages = []

    if (typeof content === 'string') {
        messages.push(t(`order:message.${sheet}.${content}`, { sku_code }))
    } else {
        Object.entries(content).forEach(entry => {
            let [key, errors] = entry
            ERRORS.forEach(errorKey => {
                if (errors.hasOwnProperty(errorKey)) {
                    if (key === 'order' && errorKey === 'already_exist') {
                        messages.push(t(`order:message.${sheet}.already_exist_order_code`))
                    } else if (key === 'cancel_note' && errorKey === 'required') {
                        messages.push(t(`order:message.${sheet}.required_cancel_note`))
                    } else {
                        messages.push(
                            t(`order:message.${sheet}.${errorKey}`, {
                                attribute: t(`order:label.${key}`),
                            })
                        )
                    }
                }
            })
        })
    }

    if (messages.length > 0) return t('order:message.line', { line: line, sheet: t(`order:sheet.${sheet}`), content: messages.join(' ') })

    return t('product:message.upload_file.unknown_error')
}
