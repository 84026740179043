import { useQuery } from 'react-query';

const createUseQuery = ({ name, query, settings }) =>
    // eslint-disable-next-line
    useQuery(name, query, {
        retry: false,
        ...settings,
    });

export default createUseQuery;
