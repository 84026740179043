import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Modal, Form, notification } from 'antd';
import i18next from 'i18next';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import React, { useCallback, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import UploadFile from '@Modules/App/Upload/UploadFileV2';
import { scanBarcodeTypeStorage, scanWarehouseStorage } from '@Modules/App/services';
import useImportPackingConfirmMutation from '@Modules/ScanOrder/Hooks/useImportPackingConfirmMutation';

import { t, trans } from '@System/i18n';
import { getVar } from '@System/support/helpers';

const ImportPackingConfirm = ({ btnType, onSuccessImport, form }) => {
    const [messages, setMessages] = useState([]);
    const [file, setFile] = useState();
    const [visible, setVisible] = useState(false);
    const { isLoading: importing, mutateAsync: importBarcodePackingConfirm } = useImportPackingConfirmMutation();

    const handleUpload = useCallback(file => {
        setFile(file);
    }, []);

    const handleCancel = usePersistFn(() => {
        setFile(undefined);
        setMessages([]);
        setVisible(false);
    });

    const handleOk = usePersistFn(async () => {
        const warehouse_id = scanWarehouseStorage.getValue();
        const barcode_type = scanBarcodeTypeStorage.getValue();
        const params = {
            file,
            warehouse_id,
            barcode_type,
        };
        const { data } = await importBarcodePackingConfirm(params);
        let messages = [];
        let errors = uniqWith(data?.errors, isEqual);
        const importPackingData = getVar(data, 'data', []);
        if (importPackingData.length > 0) {
            const result = onSuccessImport(importPackingData, { warehouse_id, barcode_type });
            if (result.length > 0) {
                messages = result;
            } else {
                notification.success({
                    message: t('order:import_packing_confirm.success'),
                });
            }
        }

        if (errors.length) {
            errors.map(item => {
                messages.push(renderError(item));
            });
        }
        if (messages.length > 0) {
            setMessages(messages);
        } else {
            handleCancel();
        }
    });

    function handleClick() {
        form.validateFields().then(() => {
            setVisible(true);
        });
    }

    function renderError(error) {
        const line = get(error, 'line', '');
        const content = get(error, 'errors', error);
        const warehouse_id = scanWarehouseStorage.getValue();
        const barcode_type = scanBarcodeTypeStorage.getValue();
        let message = '';
        Object.entries(content).forEach(entry => {
            let [key, errors] = entry;
            if (typeof errors === 'object') {
                const barcode = getVar(error, 'barcode', '');
                message = onSuccessImport(
                    [],
                    { warehouse_id, barcode_type, barcode },
                    { data: { data: content }, status: 400 }
                );
            } else {
                message = trans(`order:import_packing_confirm.errors.${errors}`, {
                    attribute: trans(`order:label.${key}`),
                });
            }
        });

        if (message) {
            return trans('product:message.upload_file.line', { line: line, content: message });
        }
        return trans('product:message.upload_file.unknown_error');
    }

    return (
        <>
            <Form.Item shouldUpdate className="submit mb-0">
                {() => (
                    <Button
                        type={btnType ? btnType : 'primary'}
                        onClick={handleClick}
                        icon={<CloudUploadOutlined />}
                        disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0}
                    >
                        {t('order:btn.import_barcode')}
                    </Button>
                )}
            </Form.Item>
            {visible && (
                <Modal
                    title={t('order:title.import_barcode_packing_confirm').toUpperCase()}
                    visible={visible}
                    footer={
                        <>
                            <Button onClick={handleCancel}>{t('btn.cancel')}</Button>

                            <Button loading={importing} type="primary" onClick={handleOk}>
                                {t('btn.ok')}
                            </Button>
                        </>
                    }
                    okText={t('btn.ok')}
                    onCancel={handleCancel}
                >
                    <p>
                        - {t('label.latest_at_update_sample')}: <strong>04/01/2022</strong>
                    </p>
                    <p>
                        - {t('label.download_sample_file')}:{' '}
                        <strong>
                            <a
                                href={
                                    process.env.PUBLIC_URL +
                                    `/template/${i18next.language}/file_barcode_packing_confirm_sample.xlsx`
                                }
                            >
                                file_barcode_packing_confirm_sample
                            </a>
                        </strong>
                        {/*}*/}
                    </p>
                    <p className="mb-3">
                        - {t('label.maximum_file_size')}: <strong>5MB</strong>
                    </p>
                    <UploadFile
                        onChange={handleUpload}
                        accept=".xls,.xlsx"
                        fileType={[
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                        ]}
                        name="file import đơn hàng"
                        maxFileSize={5}
                        showUploadList={true}
                    />
                    {messages.length > 0 && (
                        <div className="pt-3">
                            {messages.map((item, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {item}
                                    </p>
                                );
                            })}
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
};

export default ImportPackingConfirm;
