import { Form } from 'antd';
import _ from 'lodash';
import React, { useEffect, useReducer } from 'react';

import ScanErrors from '@Modules/App/ScanErrors';
import withScanErrors from '@Modules/App/ScanErrors/withScanErrors';
import { scanImportingSkuStorage, setDocumentTitle, setNavigator } from '@Modules/App/services';
import FormScan from '@Modules/Document/screens/Importing/Scan/FormScan';
import VariantList from '@Modules/Document/screens/Importing/Scan/VariantList';

import { t, trans } from '@System/i18n';

import reducer, { ACTIONS } from './reducer';

const ScanType = 'importBySKU';
const FormScanWithErrors = withScanErrors(FormScan, ScanType);
const ScanErrorsWithErrors = withScanErrors(ScanErrors, ScanType);

export default function DocumentImportingScan() {
    const [form] = Form.useForm();
    const initialState = scanImportingSkuStorage.value;
    const [storeData, dispatch] = useReducer(reducer, initialState);
    const storeActions = {
        addSku: sku => dispatch({ type: ACTIONS.ADD_SKU, payload: sku }),
        updateSku: sku => dispatch({ type: ACTIONS.UPDATE_SKU, payload: sku }),
        deleteSku: sku_id => dispatch({ type: ACTIONS.DELETE_SKU, payload: sku_id }),
        update: data => dispatch({ type: ACTIONS.UPDATE_DATA, payload: data }),
        reset: (data = {}) => dispatch({ type: ACTIONS.ADD_NEW_SCAN, payload: data }),
        isSaved: () => dispatch({ type: ACTIONS.IS_SAVED }),
    };

    useEffect(() => {
        setNavigator(t('common:menu.importing_scan'), [
            {
                name: t('common:menu.operation_warehouse'),
            },
            {
                name: t('common:menu.importing_scan'),
            },
        ]);
        setDocumentTitle(t('common:menu.importing_scan'));
    }, []);

    function errorMessageRender(error) {
        const listKeyException = ['has_many_in_merchant', 'warehouse_area'];
        let sku_exists = _.get(error, 'sku_exists', []);
        const message = [];
        Object.keys(error).forEach(item => {
            if (listKeyException.includes(item)) {
                message.push(trans(`document:errors.${item}`));
            } else {
                message.push(
                    trans(`document:errors.sku_exists_sku_code`, {
                        codes: `#${sku_exists.join(', ')}`,
                    })
                );
            }
        });
        return message;
    }

    return (
        <div className="site-content">
            <FormScanWithErrors storeActions={storeActions} storeData={storeData} form={form} />
            <ScanErrorsWithErrors className="mt-4" errorMessageRender={errorMessageRender} />
            <VariantList storeActions={storeActions} storeData={storeData} form={form} />
        </div>
    );
}
