import { Col, Row } from 'antd';
import lodash from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { updateCollectionItem } from '@Modules/App/services/helpers';
import useImportingDocumentSkusQuery from '@Modules/Document/Hooks/useImportingDocumentSkusQuery';
import useUpdateImportDocumentMutation from '@Modules/Document/Hooks/useUpdateImportingDocumentMutation';
import { STATUSES } from '@Modules/Document/constants';
import ProductList from '@Modules/Document/screens/Importing/ReturnOrder/Detail/ProductList';
import WaybillList from '@Modules/Document/screens/Importing/ReturnOrder/Detail/WaybillList';

function BodyInfo(
    {
        importingListBarcode,
        documentId,
        importingDocument,
        onSuccessUpdate,
        skusImporting,
        setSkusImporting,
        form,
        setDocumentSkusDelete,
        documentSkusDelete,
        setIsChanged,
        refactorDataSkusImporting,
        setDataImportingBarcode,
        dataImportingBarcode,
        errorAlert,
        setErrorAlert,
    },
    ref
) {
    const [pagination, setPagination] = useState({});

    const statusDocument = lodash.get(importingDocument, 'status', '');
    const {
        isLoading,
        data: skusImportingInfo,
        refetch: refetchImportingDocument,
    } = useImportingDocumentSkusQuery(documentId, { paginate: 0 });
    const { mutate: updateImportingDocument } = useUpdateImportDocumentMutation({
        id: importingDocument?.id,
        type: importingDocument?.type,
        onSuccess: data => {
            onSuccessUpdate(data);
        },
    });

    const onUpdateData = (name, value) => {
        updateImportingDocument({ [name]: value });
    };

    useImperativeHandle(
        ref,
        () => ({
            refetchImportingDocument,
        }),
        [refetchImportingDocument]
    );

    useEffect(() => {
        setSkusImporting(refactorDataSkusImporting(skusImportingInfo?.data?.document_sku_importings));
        setPagination(get(skusImportingInfo, 'data.pagination'));
    }, [skusImportingInfo]);

    function updateSkuImportingInfo(name, value, data) {
        const skuInfoByKey = find(skusImporting, ['key', data.key]);
        if (!isEmpty(skuInfoByKey) && skuInfoByKey[name] !== value) {
            const newSkusImporting = updateCollectionItem(skusImporting, data.key, { [name]: value }, 'key');
            setSkusImporting(newSkusImporting);
            setIsChanged(true);
        }
    }

    return (
        <Row gutter={10}>
            <Col span={24} className="mb-3">
                <WaybillList
                    dataSource={dataImportingBarcode}
                    setDataSource={setDataImportingBarcode}
                    data={importingListBarcode}
                    disabled={statusDocument !== STATUSES.DRAFT}
                    documentId={documentId}
                    form={form}
                    setSkusImporting={setSkusImporting}
                    skusImporting={skusImporting}
                    errorAlert={errorAlert}
                    setErrorAlert={setErrorAlert}
                />
            </Col>

            <Col span={24} className="mb-3">
                <ProductList
                    form={form}
                    loading={isLoading}
                    disabled={statusDocument !== STATUSES.DRAFT}
                    dataSource={skusImporting}
                    importingDocument={importingDocument}
                    updateSkuImportingInfo={updateSkuImportingInfo}
                    onUpdate={onUpdateData}
                    // handleAddRow={handleAddRow}
                    // handleDeleteRow={handleDeleteRow}
                    refetchImportingDocument={refetchImportingDocument}
                    pagination={pagination}
                />
            </Col>
        </Row>
    );
}

export default forwardRef(BodyInfo);
