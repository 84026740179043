import { Modal as BaseModal } from 'antd';
import React from 'react';

import confirm from './confirm';

const Modal = props => {
    return (
        <BaseModal
            centered
            {...props}
        >
            {props.content}
        </BaseModal>
    );
};

Modal.confirm = function confirmFn(props) {
    return confirm(props);
};

export default Modal;
