import { Button, Modal, Table } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { t } from '@System/i18n';
import notification from '@System/notification';

import api from '../services/api';

const ModalPriorityCreate = ({ setVisibleModal, orderPriority, isSelectAllPage, ids, filters }) => {
    const [loadingPriority, setLoadingPriority] = useState(false);

    const columns = [
        {
            title: t('purchasingOrder:label.order'),
            key: 'order_code',
            dataIndex: 'code',
        },
        {
            title: t('sku:label.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>;
            },
        },
        {
            title: t('orderDetail:label.deliveried_date'),
            dataIndex: 'intended_delivery_at',
            key: 'intended_delivery_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>;
            },
        },
    ];

    const handleCreateBill = key => {
        const params = {
            ...filters,
            ids: isSelectAllPage ? [] : ids,
        };

        const orderPriorityIds = map(orderPriority, 'id');

        if (key === 'continue') {
            handleRemove({ ...params });
        } else {
            handleRemove({
                ...params,
                ignore_ids: orderPriorityIds,
            });
        }
        setVisibleModal(false);
    };

    const handleRemove = params => {
        setLoadingPriority(true);
        api.removeWarehouseArea({ ...params })
            .then(() => {
                notification.success(
                    t('message.process_background', { attribute: t('order:btn.remove_warehouse_area') })
                );
            })
            .catch(() => {
                notification.error(t('message.create_failed', { attribute: t('order:btn.remove_warehouse_area') }));
            })
            .finally(() => setLoadingPriority(false));
    };

    return (
        <>
            <Modal
                title={t('order:title.remove_location_order')}
                className="_modal-create__priority"
                visible={true}
                width={800}
                onCancel={() => setVisibleModal(false)}
                footer={[
                    <Button
                        key="back"
                        className="_modal-create__priority-btn-keep__create"
                        onClick={() => handleCreateBill('continue')}
                        loading={loadingPriority}
                    >
                        {t('order:btn.continue')}
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        className="_modal-create__priority-btn-pass"
                        onClick={() => handleCreateBill('pass')}
                        loading={loadingPriority}
                    >
                        {t('btn.pass')}
                    </Button>,
                ]}
            >
                <h4 className="_modal-create__priority--message">{t('order:message.confirm_remove_order_priority')}</h4>
                <p className="mb-2">{t('order:title.orders_priority', { count: orderPriority?.length })}</p>
                <Table columns={columns} rowKey={record => record?.id} dataSource={orderPriority} pagination={true} />
            </Modal>
        </>
    );
};

export default ModalPriorityCreate;
