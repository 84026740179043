import {useEffect} from 'react';
import {t} from "../../../system/i18n";
import {auth} from '../services';
import {url} from '../../../system/routing';
import notification from '../../../system/notification';

export default function LoginCallback() {

    useEffect(loginCallback, []);

    function loginCallback() {
        auth.loginSuccess(url.getQuery('token'));
        notification.success(t('auth:message.auth_success'));
    }

    return null;
}
