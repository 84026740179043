import { Card, Form, Typography } from 'antd';
import clsx from 'clsx';
import { every, forEach, map, get, filter, find, findIndex, isEmpty } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Loading from '@Modules/App/Loading';
import usePackingOrderListQuery from '@Modules/Document/Hooks/usePackingOrderListQuery';
import useProductListOrderPacking from '@Modules/Document/Hooks/useProductListOrderPackingQuery';

import { Link, url } from '../../../../../system/routing';
import { t, trans } from './../../../../../system/i18n';
import notification from './../../../../../system/notification';
import { getVar, notifyErrorFromServer } from './../../../../../system/support/helpers';
import Page404 from './../../../../App/Error/404';
import Modal from './../../../../App/Modal';
import { setDocumentTitle, setNavigator } from './../../../../App/services';
import useCheckingExportWarningsMutation from './../../../Hooks/useCheckingExportWarningsMutation';
import useCreateExportingDocumentMutation from './../../../Hooks/useCreateExportingDocumentMutation';
import usePackingDocumentQuery from './../../../Hooks/usePackingDocumentQuery';
import { SCAN_TYPE } from './../../../constants';
import Body from './Body';
import Header from './Header';

const { Paragraph, Text } = Typography;

const Detail = ({ match }) => {
    const [suggestedPartner, setSuggestedPartner] = useState();
    const [filters, setFilters] = useState({});
    const [pagination, setPagination] = useState({});
    const [orderExportingIdToShippingPartners, setOrderExportingIdToShippingPartners] = useState({});
    const [products, setProducts] = useState([]);
    const [validOrderExportingIds, setValidOrderExportingIds] = useState([]);
    const [orderPackings, setOrderPackings] = useState([]);
    const [isExportForGoods, setIsExportForGoods] = useState(false);
    const [form] = Form.useForm();
    const { isError, isLoading, data: packingDocumentData, error } = usePackingDocumentQuery(match.params.id);
    const {
        data: orderPackingsData,
        refetch,
        isFetching: loadingOrderPackingList,
    } = usePackingOrderListQuery(match.params.id, filters);

    const {
        data: productListOrderPacking,
        isFetching: loadingProduct,
    } = useProductListOrderPacking(match.params.id, { order_exporting_status: isExportForGoods ? "new" : undefined });

    const { isLoading: creating, mutate: createExportingDocument } = useCreateExportingDocumentMutation({
        notifyWhenError: false,
        onSuccess: data => {
            url.redirectTo('documents.exporting.detail', {
                id: data?.document_exporting?.id,
            });
        },
        onError: error => {
            const barcodeType = packingDocumentData?.data.document_packing?.info?.barcode_type;

            if (error?.response?.data?.data?.order_packing_ids) {
                const orders = map(error.response.data.data.order_packing_ids?.order_packings, 'order').join(', ');
                const freightBills = map(error.response.data.data.order_packing_ids?.order_packings, 'freight_bill').join(', ');

                Modal.confirm({
                    cancelText: trans('document:create_exporting_document.cancel'),
                    centered: true,
                    content: trans('document:create_exporting_document.errors.order_packing_ids.content', {
                        type: (barcodeType === SCAN_TYPE.FREIGHT_BILL ? trans('freight_bill') : trans('order')).toLowerCase(),
                        items: barcodeType === SCAN_TYPE.FREIGHT_BILL ? freightBills : orders,
                    }),
                    icon: false,
                    maskClosable: true,
                    okButtonProps: {
                        className: 'd-none _modal-document-create-exporting-btn-ok',
                    },
                    cancelButtonProps: {
                        className: 'd-none _modal-document-create-exporting-btn-cancel',
                    },
                    title: (
                        <Text className="text-danger">
                            {trans('document:create_exporting_document.errors.order_packing_ids.title')}
                        </Text>
                    ),
                    width: 480,
                    onCancel: () => {
                        setIsExportForGoods(false);
                    },
                });
            } else {
                setIsExportForGoods(false);
                setOrderPackings(packingDocumentData?.data?.order_packings);
                notifyErrorFromServer(
                    error,
                    trans('document:create_exporting_document.failed'),
                    'document:create_exporting_document.errors'
                );
            }
        },
    });
    const { isLoading: checkingExportWarnings, mutate: checkExportWarnings } = useCheckingExportWarningsMutation({
        onSuccess: data => {
            setSuggestedPartner();
            const validOrderExportingIds = map(data?.valid_order_exportings, 'id');
            if (validOrderExportingIds.length > 0) setValidOrderExportingIds(validOrderExportingIds);
            if (data?.valid_order_exportings?.length) {
                const comparedShippingPartner = orderExportingIdToShippingPartners[data.valid_order_exportings[0].id];

                if (comparedShippingPartner) {
                    const isSameShippingPartner =
                        data.valid_order_exportings.length === 1 ||
                        every(data.valid_order_exportings.slice(1), validOrderExporting => {
                            const shipingPartnerId = orderExportingIdToShippingPartners[validOrderExporting.id]?.id;

                            if (shipingPartnerId !== comparedShippingPartner.id) {
                                return false;
                            }

                            return true;
                        });

                    if (isSameShippingPartner) {
                        setSuggestedPartner(comparedShippingPartner.name);
                    }
                }
            }

            if (data?.invalid_order_exportings?.length) {
                const type = (
                    packingDocumentData?.data.document_packing?.info?.barcode_type === SCAN_TYPE.FREIGHT_BILL
                        ? trans('freight_bill')
                        : trans('order')
                ).toLowerCase();

                Modal.confirm({
                    centered: true,
                    content: (
                        <div>
                            <Paragraph>
                                {trans('document:export_warehouse.confirm_modal.hints.1', {
                                    count: data.invalid_order_exportings.length,
                                    type,
                                    items: data.invalid_order_exportings.map((order, index) => (
                                        <Fragment key={order.order_id}>
                                            <Link isNewTab params={{ id: order.order_id }} to="orders.detail">
                                                {order?.order?.code}
                                            </Link>
                                            {index < data.invalid_order_exportings.length - 1 ? ', ' : ''}
                                        </Fragment>
                                    )),
                                })}
                            </Paragraph>

                            {!!data?.valid_order_exportings?.length && (
                                <Paragraph className="mb-0 text-fz-16 font-weight-bold">
                                    {trans('document:export_warehouse.confirm_modal.hints.2', {
                                        count: data?.valid_order_exportings?.length || 0,
                                        type,
                                    })}
                                </Paragraph>
                            )}
                        </div>
                    ),
                    icon: false,
                    maskClosable: true,
                    title: data?.valid_order_exportings?.length ? (
                        trans('document:confirm_export_warehouse')
                    ) : (
                        <Text className="text-danger">{trans('document:export_warehouse.error_title')}</Text>
                    ),
                    cancelButtonProps: {
                        className: '_modal-document-confirm-export-warehouse-btn-cancel',
                    },
                    okButtonProps: {
                        className: clsx(
                            {
                                'd-none': !data?.valid_order_exportings?.length,
                            },
                            '_modal-document-confirm-export-warehouse-btn-ok'
                        ),
                    },
                    okText: trans('document:export_warehouse.continue'),
                    cancelText: trans('btn.close'),
                    width: 480,
                    onOk: () => {
                        setIsExportForGoods(true);
                    },
                });
            } else {
                setIsExportForGoods(true);
            }

        },
        onError: () => {
            notification.error(trans('document:export_warehouse.failed'));
        },
    });

    useEffect(() => {
        let document_code = get(packingDocumentData, 'data.document_packing.code', '');
        setDocumentTitle(t('title_page.document_packing', { document_code: document_code }));
    }, [packingDocumentData]);

    useEffect(() => {
        refetch();
    }, [filters]);

    useEffect(() => {
        setOrderPackings(getVar(orderPackingsData, 'data.order_packings', []));
        setPagination(getVar(orderPackingsData, 'data.pagination', {}));
    }, [orderPackingsData]);

    useEffect(() => {
        setProducts(getVar(productListOrderPacking, 'data.skus', []));
    }, [productListOrderPacking]);

    const handleExport = useCallback(() => {
        setValidOrderExportingIds([]);
        const data = {
            document_packing: packingDocumentData?.data?.document_packing?.code,
            warehouse_id: packingDocumentData?.data?.document_packing?.warehouse_id,
        };
        checkExportWarnings(data);
    }, [orderPackings, packingDocumentData, checkExportWarnings]);

    const handleCreateExportingDocument = useCallback(() => {
        const values = form.getFieldsValue();
        const data = {
            ...values,
            document_packing: packingDocumentData?.data?.document_packing?.code,
            order_exporting_ids: validOrderExportingIds,
            warehouse_id: packingDocumentData?.data?.document_packing?.warehouse_id,
            barcode_type: packingDocumentData?.data.document_packing?.info?.barcode_type,
        };

        createExportingDocument(data);
    }, [form, orderPackings, packingDocumentData, createExportingDocument, validOrderExportingIds]);

    const handleDeleteOrderPackings = useCallback(
        data => {
            const order_packing_items = getVar(data, 'order_packing_items', []);
            const order_exporting_id = getVar(data, 'order_exporting_id', undefined);
            const orderExportingIds = [...validOrderExportingIds];
            const newOrderExportingIds = filter(orderExportingIds, (item, index) => item !== order_exporting_id);
            setValidOrderExportingIds(newOrderExportingIds);
            const newProducts = [...products];
            order_packing_items.map((item, index) => {
                const quantity = getVar(item, 'quantity', 0);
                const sku_id = getVar(item, 'sku_id', undefined);
                const productInfo = find(newProducts, ['sku_id', sku_id]);
                if (!isEmpty(productInfo)) {
                    const order_packing_quantity = getVar(productInfo, 'order_packing_quantity', 0);
                    const new_order_packing_quantity = order_packing_quantity - quantity;
                    const skuIndex = findIndex(newProducts, { sku_id });
                    newProducts.splice(skuIndex, 1, { ...productInfo, order_packing_quantity: new_order_packing_quantity });
                }
            });
            setProducts(newProducts);
        },
        [validOrderExportingIds, products]
    );

    useEffect(() => {
        const orderExportingIdToShippingPartners = {};
        forEach(
            packingDocumentData?.data?.order_packings,
            orderPacking => (orderExportingIdToShippingPartners[orderPacking.order_exporting_id] = orderPacking?.shipping_partner)
        );
        setOrderExportingIdToShippingPartners(orderExportingIdToShippingPartners);
    }, [packingDocumentData]);

    useEffect(() => {
        form.setFieldsValue({
            partner: suggestedPartner,
        });
    }, [form, suggestedPartner]);

    useEffect(() => {
        setNavigator(trans('document:types.packing'), [
            {
                name: trans('document:list.packing'),
                route: 'documents.packing.list',
            },
            {
                name: trans('document:detail.packing'),
            },
        ]);
    }, []);

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Page404 />;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <Card
            className="flex-grow-1 bg-white m-4"
            title={
                <Header
                    creating={checkingExportWarnings || creating}
                    exporting={checkingExportWarnings}
                    form={form}
                    isExportForGoods={isExportForGoods}
                    packingDocument={packingDocumentData?.data}
                    suggestedPartner={suggestedPartner}
                    onCreateExportingDocument={handleCreateExportingDocument}
                    onExport={handleExport}
                />
            }
        >
            <Body
                validOrderExportingIds={validOrderExportingIds}
                loadingOrderPackingList={loadingOrderPackingList}
                isExportForGoods={isExportForGoods}
                orderPackings={orderPackings}
                packingDocument={packingDocumentData?.data}
                onDelete={handleDeleteOrderPackings}
                pagination={pagination}
                setFilters={setFilters}
                filters={filters}
                products={products}
                loadingProduct={loadingProduct}
            />
        </Card>
    );
};

export default Detail;
