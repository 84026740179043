import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Form, Row, Col, Input, Select, notification, Spin, Typography } from 'antd'
import { each, filter, flatMap, head, last, trim, uniq } from 'lodash'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import some from 'lodash/some'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import useScanBarcode from '@Modules/App/Hooks/useScanBarcode'
import useScanSounds from '@Modules/App/Hooks/useScanSounds'
import useSize from '@Modules/App/Hooks/useSize'
import { startScanServicePackingPurchasingPackage } from '@Modules/App/services'
import ScanBarcode from '@Modules/ScanOrder/components/ScanBarcode'
import api from '@Modules/ScanOrder/services/api'
import { SCAN_PACKING_PURCHASING_PACKAGE_TYPE } from '@Modules/ScanOrder/services/constants'
import apiService from '@Modules/Services/services/api'
import { SERVICE_TYPE } from '@Modules/Services/services/constants'
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse'

import { addError, deleteErrorByKey } from '@State/scanErrors/actions'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

const { Option } = Select
const { Title } = Typography

function FormScan({
    getScanInfo,
    storeActions,
    storeData,
    setLoadingData,
    scanOrderListInfo,
    warehouses,
    errorKey,
    form,
    updateListOfLading,
    reloadData,
    page,
}) {
    const { isMobile } = useSize()
    let { isConfirm, confirmPackingIds, orderInfo, warehouse_id, barcode_type, barcode_type_scan_sku, countryId } = storeData
    const disableChangeBarcodeType = !isEmpty(orderInfo)
        ? ['SKU_CODE', 'SKU_ID'].includes(barcode_type_scan_sku) && some(get(orderInfo, 'order_packing_items', []), item => item.scanned > 0)
        : !isEmpty(confirmPackingIds) || isConfirm
    const disableChange = !isEmpty(confirmPackingIds) || isConfirm || !isEmpty(orderInfo)
    const { playSuccessSound, playErrorSound } = useScanSounds()
    const dispatch = useDispatch()
    const [scanLoading, setScanLoading] = useState(false)
    const [showOnMobile, setShowOnMobile] = useState(true)
    const [listBarcodeType, setListBarcodeType] = useState(SCAN_PACKING_PURCHASING_PACKAGE_TYPE)
    const [isScanService, setIsScanService] = useState(false)
    const [scanServiceStart, setScanServiceStart] = useState(startScanServicePackingPurchasingPackage.value)
    const [serviceList, setServiceList] = useState([])

    useEffect(() => {
        if (!isEmpty(orderInfo)) {
            if (get(orderInfo, 'usingSerial')) {
                setListBarcodeType({
                    SERIAL: 'SERIAL',
                })
                form.setFieldsValue({
                    barcode_type: 'SERIAL',
                })
            } else {
                setListBarcodeType({
                    SKU_CODE: 'SKU_CODE',
                    SKU_ID: 'SKU_ID',
                })
                form.setFieldsValue({
                    barcode_type: barcode_type_scan_sku ? barcode_type_scan_sku : undefined,
                })
            }
        } else {
            if (
                get(head(scanOrderListInfo), 'order_code') === get(startScanServicePackingPurchasingPackage.value, 'order_code') &&
                get(startScanServicePackingPurchasingPackage.value, 'started', false)
            ) {
                form.setFieldsValue({
                    barcode_type: 'SERVICE',
                })
                setListBarcodeType({ SERVICE: 'SERVICE' })
                setIsScanService(true)
            } else {
                setListBarcodeType(SCAN_PACKING_PURCHASING_PACKAGE_TYPE)
                form.setFieldsValue({
                    barcode_type: getVar(storeData, 'barcode_type', undefined),
                })
            }
        }
    }, [barcode_type_scan_sku, form, orderInfo, storeData, scanOrderListInfo, scanServiceStart])
    useEffect(() => {
        if (isScanService) {
            setScanLoading(true)
            apiService
                .list({ country_id: countryId, hidden_init_service: true, merchant_id: get(head(scanOrderListInfo), 'merchant_id'), type: SERVICE_TYPE.EXPORT })
                .then(res => {
                    let services = getVar(res, 'data.services', [])
                    setServiceList(services)
                })
                .finally(() => setScanLoading(false))
        }
    }, [isScanService])

    useEffect(() => {
        if (get(scanOrderListInfo, '0.order_code') !== get(scanServiceStart, 'order_code')) {
            setIsScanService(false)

            setScanServiceStart({
                order_code: '',
                started: false,
            })
            // startScanServicePackingPurchasingPackage.setValue({
            //     order_code: '',
            //     started: false,
            // })
        }
    }, [scanOrderListInfo])

    useEffect(() => {
        if (isMobile) {
            disableChange ? setShowOnMobile(false) : setShowOnMobile(true)
        }
    }, [disableChange, isMobile])

    const inputRef = useRef(null)
    const scan = useScanBarcode(getScanInfo, {
        onSuccess: (response, params) => {
            const data = get(response, 'data', {})
            const key = map(params, (value, key) => `${key}:${value}`).join('-')

            if (!isEmpty(data)) {
                let existsCode = !isEmpty(find(scanOrderListInfo, ['id', get(data, 'order_packing.id')]))
                if (existsCode) {
                    playErrorSound()
                    dispatch(
                        addError({
                            type: errorKey,
                            key,
                            message: t('order:message.scan_order.code_scanned', {
                                ...params,
                                barcode: `#${params?.barcode}`,
                                barcode_type: t(`order:barcode_type.${listBarcodeType[barcode_type]}`),
                                title: t(`order:title.barcode_type.${listBarcodeType[barcode_type]}`),
                            }),
                        })
                    )
                } else {
                    if (!countryId) {
                        storeActions.updateScanInfo({ countryId: getVar(data, 'order.receiver_country_id', undefined) })
                    }
                    storeActions.updateScanInfo({ orderInfo: data })
                    playSuccessSound()
                    dispatch(
                        deleteErrorByKey({
                            type: errorKey,
                            key,
                        })
                    )
                    notification.success({
                        type: errorKey,
                        message: t('order:message.scan_order.success', {
                            ...params,
                            barcode_type: t(`order:barcode_type.${listBarcodeType[barcode_type]}`),
                        }),
                    })
                }
            }
        },
        onError: (error, params) => {
            const key = map(params, (value, key) => `${key}:${value ? value : ''}`).join('-')
            playErrorSound()
            dispatch(
                addError({
                    type: errorKey,
                    key,
                    message: { error: error?.response, warehouses, params: params },
                })
            )
        },
        onSettled: () => {
            setScanLoading(false)
            setLoadingData(false)
            form.setFieldsValue({
                barcode: undefined,
            })
        },
    })

    function _onChange(name, value) {
        if (typeof value === 'string') {
            value.trim()
        }
        if (name === 'barcode') {
            form.setFieldsValue({
                [name]: value,
            })

            form.submit()
        }
        if (name === 'barcode_type') {
            if (SCAN_PACKING_PURCHASING_PACKAGE_TYPE[value]) {
                storeActions.updateScanInfo({ [name]: value })
            } else {
                storeActions.updateScanInfo({ barcode_type_scan_sku: value })
            }
        } else {
            storeActions.updateScanInfo({ [name]: value })
        }
    }

    function handleScanSku(data) {
        const { barcode, barcode_type } = data
        const barcode_type_scan_sku = form.getFieldValue('barcode_type')
        const key = map(data, (value, key) => `${key}:${value ? value : ''}`).join('-')
        if (barcode) {
            const skuList = getVar(orderInfo, 'order_stocks', [])
            const order = getVar(orderInfo, 'order', {})
            const skuListNew = []
            let existsSku = false
            let added = false
            let fullScanned = true
            let itemScanning = {}
            let usingSerial = false
            skuList.map(item => {
                const sku_code = barcode_type_scan_sku === 'SKU_CODE' ? getVar(item, 'sku.code', undefined) : getVar(item, 'sku.id', undefined)
                const quantity = getVar(item, 'quantity', 0)
                let scanned = getVar(item, 'scanned', 0)
                if (sku_code.toString() === barcode) {
                    existsSku = true
                    itemScanning = { ...item, is_serial_number: get(item, 'sku.is_serial_number') }
                    usingSerial = get(item, 'sku.is_serial_number')
                    if (Number(scanned) < Number(quantity) && !added) {
                        if (!usingSerial) {
                            scanned += 1
                            added = true
                        }
                    }
                }
                if (Number(quantity) !== Number(scanned) && !usingSerial) fullScanned = false
                skuListNew.push({ ...item, scanned })
            })
            if (existsSku) {
                // let barcode_type_scan_sku_new = barcode_type_scan_sku;
                let newOrderInfo = { ...orderInfo, order_stocks: skuListNew, itemScanning, usingSerial }
                if (fullScanned && !usingSerial) {
                    updateListOfLading([newOrderInfo])
                    newOrderInfo = {}
                }

                storeActions.updateScanInfo({ orderInfo: newOrderInfo })

                if (usingSerial) {
                    form.setFieldsValue({
                        barcode: undefined,
                    })
                    return
                }

                if (added) {
                    playSuccessSound()
                    dispatch(
                        deleteErrorByKey({
                            type: errorKey,
                            key,
                        })
                    )
                    notification.success({
                        type: errorKey,
                        message: t('order:message.scan_order.success', {
                            ...data,
                            barcode_type: t(`packing:scan_packing_purchasing_package.${listBarcodeType[barcode_type]}`),
                        }),
                    })
                } else {
                    playErrorSound()
                    dispatch(
                        addError({
                            type: errorKey,
                            key,
                            message: t('order:message.scan_order.code_scanned', {
                                barcode,
                                barcode_type: t(`packing:scan_packing_purchasing_package.${listBarcodeType[barcode_type]}`),
                                title: t('packing:title.order_info', { code: `#${getVar(order, 'code', '')}` }),
                            }),
                        })
                    )
                }
            } else {
                playErrorSound()
                dispatch(
                    addError({
                        type: errorKey,
                        key,
                        message: t('order:message.scan_order.exists', {
                            barcode,
                            barcode_type: t(`packing:scan_packing_purchasing_package.${listBarcodeType[barcode_type]}`),
                            title: t('packing:title.order_info', { code: `#${getVar(order, 'code', '')}` }),
                            content: t('order:label.in_packing_require'),
                        }),
                    })
                )
            }
        } else {
            playErrorSound()
            dispatch(
                addError({
                    type: errorKey,
                    key,
                    message: t('order:message.scan_order.required', { attribute: t('order:label.barcode') }),
                })
            )
        }
        form.setFieldsValue({
            barcode: undefined,
        })

        setScanLoading(false)
        setLoadingData(false)
        return true
    }

    async function handleScanSerial(data) {
        const { barcode, barcode_type } = data
        const key = map(data, (value, key) => `${key}:${value ? value : ''}`).join('-')
        if (barcode) {
            const skuList = getVar(orderInfo, 'order_stocks', [])
            const order = getVar(orderInfo, 'order', {})
            const itemScanning = getVar(orderInfo, 'itemScanning', {})
            const skuListNew = []
            let existsSku = false
            let added = false
            let fullScanned = true
            let skuIsFull = false

            const resSerial = await api.scanSerialNumber(itemScanning.sku_id, { serial: barcode, available: 1 })

            const dataSerial = get(resSerial, 'data.data', [])

            if (!isEmpty(dataSerial)) {
                skuList.forEach(item => {
                    let quantity = getVar(item, 'quantity', 0)
                    let scanned = getVar(item, 'scanned', 0)
                    let serialNumbers = getVar(item, 'serial_numbers', [])

                    const skuId = get(itemScanning, 'sku_id')
                    const skuCode = get(itemScanning, 'sku.code')

                    if (skuId === item?.sku_id || skuCode === item?.sku_id?.code) {
                        serialNumbers.push(get(dataSerial, '0.serial'))
                        let newSerial = uniq(serialNumbers)
                        // quantity = newSerial.length

                        item = { ...item, serial_numbers: newSerial }
                        existsSku = true
                        if (Number(scanned) < Number(quantity) && !added) {
                            scanned = newSerial.length
                            added = true
                        }

                        if (Number(scanned) === Number(quantity)) {
                            skuIsFull = true
                        }
                    }

                    if (Number(quantity) !== Number(scanned)) fullScanned = false
                    skuListNew.push({ ...item, scanned })
                })
                
                if (existsSku) {
                    // let barcode_type_scan_sku_new = barcode_type_scan_sku;
                    const fullQuantity = skuIsFull ? { itemScanning: {}, usingSerial: 0 } : {}
                    let newOrderInfo = { ...orderInfo, order_stocks: skuListNew, ...fullQuantity }

                    if (fullScanned) {
                        updateListOfLading([newOrderInfo])
                        newOrderInfo = {}
                    }
                    storeActions.updateScanInfo({ orderInfo: newOrderInfo })

                    // if(skuIsFull){
                    //     playErrorSound()
                    //     dispatch(
                    //         addError({
                    //             type: errorKey,
                    //             key,
                    //             message: t('packing:message.sku_full_quantity', {
                    //                 barcode,
                    //                 barcode_type: t(`packing:scan_packing_purchasing_package.${listBarcodeType[barcode_type]}`),
                    //             }),
                    //         })
                    //     )
                    //     return
                    // }

                    if (added) {
                        playSuccessSound()
                        dispatch(
                            deleteErrorByKey({
                                type: errorKey,
                                key,
                            })
                        )
                        notification.success({
                            type: errorKey,
                            message: t('order:message.scan_order.success', {
                                ...data,
                                barcode_type: t(`packing:scan_packing_purchasing_package.${listBarcodeType[barcode_type]}`),
                            }),
                        })
                    } else {
                        playErrorSound()
                        dispatch(
                            addError({
                                type: errorKey,
                                key,
                                message: t('order:message.scan_order.code_scanned', {
                                    barcode,
                                    barcode_type: t(`packing:scan_packing_purchasing_package.${listBarcodeType[barcode_type]}`),
                                    title: t('packing:title.order_info', { code: `#${getVar(order, 'code', '')}` }),
                                }),
                            })
                        )
                    }
                }
            } else {
                playErrorSound()
                form.setFieldsValue({
                    barcode: undefined,
                })
                notification.error({ message: t('packing:message.serial_invalid') })
                return
            }

            // else {
            //     playErrorSound()
            //     dispatch(
            //         addError({
            //             type: errorKey,
            //             key,
            //             message: t('order:message.scan_order.exists', {
            //                 barcode,
            //                 barcode_type: t(`packing:scan_packing_purchasing_package.${listBarcodeType[barcode_type]}`),
            //                 title: t('packing:title.order_info', { code: `#${getVar(order, 'code', '')}` }),
            //                 content: t('order:label.in_packing_require'),
            //             }),
            //         })
            //     )
            // }
        } else {
            playErrorSound()
            dispatch(
                addError({
                    type: errorKey,
                    key,
                    message: t('order:message.scan_order.required', { attribute: t('order:label.barcode') }),
                })
            )
        }
        form.setFieldsValue({
            barcode: undefined,
        })

        setScanLoading(false)
        setLoadingData(false)
        return true
    }

    function handleUpdateService(priceId) {
        setScanLoading(true)
        setLoadingData(true)
        return api
            .updateOrderPackingsServices({ order_packing_ids: [get(head(scanOrderListInfo), 'order_packing_id')], service_price_ids: priceId })
            .then(res => {
                setScanServiceStart({
                    order_code: get(res, 'data.0.order.code'),
                    started: true,
                })
                startScanServicePackingPurchasingPackage.setValue({
                    order_code: get(res, 'data.0.order.code'),
                    started: true,
                })

                reloadData()
            })
            .catch(error => {})
            .finally(() => {
                setScanLoading(false)
                setLoadingData(false)
            })
    }

    const onScanServicePrice = data => {
        // setScanLoading(true)

        return api.scanServicePriceCode(data)
    }

    const scanServicePriceCode = useScanBarcode(onScanServicePrice, {
        onSuccess: (response, params) => {
            handleUpdateService(get(response, 'data.service_price_id'))
            playSuccessSound()
        },
        onError: (error, params) => {
            const key = map(params, (value, key) => `${key}:${value}`).join('-')

            playErrorSound()

            dispatch(
                addError({
                    type: errorKey,
                    key,
                    message: renderErrorMsg({ error: error?.response, params: params }),
                })
            )
        },
        onSettled: () => {
            setScanLoading(false)
            setLoadingData(false)
            form.setFieldsValue({
                barcode: undefined,
            })
        },
    })

    const renderErrorMsg = data => {
        const { error, params } = data

        const status = get(error, 'status', null)
        switch (status) {
            case 400:
                let msg = ''
                const errors = get(error, 'data.data', {})

                Object.entries(errors).forEach(([key, val]) => {
                    Object.keys(val).forEach(__ => {
                        msg = t(`order:message.scan_service.${__}`, {
                            attribute: t(`order:message.scan_service.service_price`),
                        })
                    })
                })
                return msg

            default:
                break
        }
    }

    const onCheckSelectService = data => {
        const isStartSelectService = getVar(data, 'barcode', undefined) === 'CHONDICHVU'
        const isEndSelectService = getVar(data, 'barcode', undefined) === 'KETTHUCCHONDICHVU'

        if (isStartSelectService) {
            if (isScanService) {
                const key = map(data, (value, key) => `${key}:${value}`).join('-')
                dispatch(
                    addError({
                        type: errorKey,
                        key,
                        message: { error: { data: { data: { product_service: { invalid: [] } } }, status: 400 }, warehouses, params: data },
                    })
                )
                playErrorSound()
                form.setFieldsValue({
                    barcode: undefined,
                })
                return true
            } else {
                form.setFieldsValue({
                    barcode_type: 'SERVICE',
                    barcode: undefined,
                })
                setScanServiceStart({
                    order_code: get(head(scanOrderListInfo), 'order_code'),
                    started: false,
                })
                startScanServicePackingPurchasingPackage.setValue({
                    order_code: get(head(scanOrderListInfo), 'order_code'),
                    started: true,
                })

                setListBarcodeType({ SERVICE: 'SERVICE' })
                setIsScanService(true)

                return true
            }
        }

        if (isEndSelectService) {
            setListBarcodeType(SCAN_PACKING_PURCHASING_PACKAGE_TYPE)
            form.setFieldsValue({
                barcode_type: getVar(storeData, 'barcode_type', undefined),
                barcode: undefined,
            })
            setIsScanService(false)

            setScanServiceStart({
                order_code: '',
                started: false,
            })
            startScanServicePackingPurchasingPackage.setValue({
                order_code: '',
                started: false,
            })

            return true
        }
    }

    const checkPriceInOrder = values => {
        const barcodeType = get(values, 'barcode_type')

        if (barcodeType === 'SERVICE') {
            let servicePriceData = {}
            const barcode = get(values, 'barcode')
            serviceList.forEach(item => {
                map(item.servicePrices, valuePrice => {
                    if (valuePrice.service_price_code === barcode) {
                        servicePriceData = {
                            idService: get(item, 'service.id'),
                            idPrice: get(valuePrice, 'id'),
                        }
                    }
                })
            })

            if (get(servicePriceData, 'idService')) {
                //     'CHONDICHVU'
                //   'KETTHUCCHONDICHVU'
                if (get(scanServiceStart, 'started')) {
                    let listIds = []

                    each(get(head(scanOrderListInfo), 'services'), item => {
                        each(flatMap(serviceList, 'service'), val => {
                            if (item.service_id === val.id) {
                                listIds.push({ idService: item.service_id, idPrice: item.service_price_id })
                            }
                        })
                    })
                    const dataF = map(
                        filter(listIds, item => item.idService !== get(servicePriceData, 'idService')),
                        'service_price_id'
                    )

                    const dataListIds = listIds.filter(item => item.idService !== get(servicePriceData, 'idService'))

                    const newData = uniq([get(servicePriceData, 'idPrice'), ...dataF, ...map(dataListIds, 'idPrice')])

                    handleUpdateService(newData)
                } else {
                    // 8up89
                    let listIds = []

                    each(get(head(scanOrderListInfo), 'services'), item => {
                        each(flatMap(serviceList, 'service'), val => {
                            if (item.service_id === val.id && val.is_required) {
                                listIds.push({ idService: item.service_id, idPrice: item.service_price_id })
                            }
                        })
                    })

                    const newData = listIds.filter(item => item.idService !== get(servicePriceData, 'idService'))

                    handleUpdateService(uniq([get(servicePriceData, 'idPrice'), ...map(newData, 'idPrice')]))
                }
                playSuccessSound()
            } else {
                const key = map(values, (value, key) => `${key}:${value}`).join('-')
                dispatch(
                    addError({
                        type: errorKey,
                        key,
                        message: { error: { data: { data: { product_service: { invalid: [] } } }, status: 400 }, warehouses, params: values },
                    })
                )
                playErrorSound()
            }
            form.setFieldsValue({
                barcode: undefined,
            })
        }
    }

    function handleFinish(values) {
        if (onCheckSelectService(values)) {
            return
        }

        if (isScanService) {
            checkPriceInOrder(values)

            // scanServicePriceCode(data)
            return
        }

        // setScanLoading(true)
        // setLoadingData(true)

        if (!isEmpty(orderInfo)) {
            // if (handleCheckUsingSerial({ ...values })) {
            //     handleScanSerial({ ...values })
            // } else {
            if (get(orderInfo, 'usingSerial')) {
                handleScanSerial({ ...values })
            } else {
                handleScanSku({ ...values })
            }

            // }
        } else {
            scan({
                ...values,
                barcode: trim(get(values, 'barcode', '')),
                playSound: false,
                page: page,
            })
        }
        inputRef.current.focus({ cursor: 'start' })
    }

    function _onToggleMobile() {
        setShowOnMobile(!showOnMobile)
    }

    return (
        <Form
            className="bg-white"
            form={form}
            layout="vertical"
            onFinish={handleFinish}
        >
            <Title level={3}>{t('packing:title.packing_purchasing_package')}</Title>
            <Row gutter={20}>
                {isMobile && (
                    <Col span={24}>
                        <p
                            className="text-fz-13 text-center text-primary cursor-pointer mt-3"
                            onClick={_onToggleMobile}
                        >
                            {showOnMobile ? <DownOutlined /> : <UpOutlined />}
                        </p>
                    </Col>
                )}

                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                >
                    <Form.Item
                        className="mb-0"
                        initialValue={warehouse_id}
                        label={t('order:label.export_warehouse')}
                        name="warehouse_id"
                        hidden={!(showOnMobile || !isMobile)}
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('order:label.export_warehouse'),
                                }),
                            },
                        ]}
                    >
                        <SelectWarehouse
                            className="_scan-order-warehouse-id"
                            disabled={disableChange}
                            placeholder={t('order:placeholder.export_warehouse')}
                            onChange={value => _onChange('warehouse_id', value)}
                        />
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                >
                    <Form.Item
                        className="mb-0"
                        initialValue={barcode_type}
                        label={t('order:label.barcode_type')}
                        name="barcode_type"
                        hidden={!(showOnMobile || !isMobile)}
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('order:label.barcode_type'),
                                }),
                            },
                        ]}
                    >
                        <Select
                            className="_scan-order-barcode-type"
                            disabled={disableChangeBarcodeType}
                            showSearch
                            placeholder={t('order:placeholder.barcode_type')}
                            optionFilterProp="children"
                            onChange={value => _onChange('barcode_type', value)}
                        >
                            {Object.keys(listBarcodeType).map((opt, index) => {
                                return (
                                    <Option
                                        key={index}
                                        value={listBarcodeType[opt]}
                                    >
                                        {t(`packing:scan_packing_purchasing_package.${listBarcodeType[opt]}`)}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 10 }}
                >
                    <Form.Item
                        className="mb-0"
                        label={t('order:label.barcode')}
                    >
                        <Spin spinning={scanLoading ? scanLoading : false}>
                            <Form.Item
                                className="mb-0"
                                name="barcode"
                            >
                                <Input
                                    ref={inputRef}
                                    className="_scan-order-barcode"
                                    onPressEnter={form.submit}
                                    placeholder={t('order:placeholder.barcode')}
                                    suffix={isConfirm ? false : <ScanBarcode onUpdate={value => _onChange('barcode', value)} />}
                                    disabled={isConfirm}
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </Spin>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default FormScan
