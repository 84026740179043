import { Badge, Table } from 'antd';
import lodash from 'lodash';
import moment from 'moment';
import React from 'react';

import { t } from '../../../system/i18n';
import { Link } from '../../../system/routing';
import CustomizedPagination from '../../App/CustomizedPagination';
import TableEmpty from '../../App/Utilities/TableEmpty';
import { LIST_TYPE_IMPORTING_DOCUMENT, STATUS_COLORS, TYPES } from '../services/constants';

const expandStatus = [TYPES.TYPE_IMPORTING, TYPES.TYPE_EXPORTING];
export default function List({ documents, pagination, addFilter, filters, loading, type }) {
    let commonColumns = [
        {
            title: <span className="text-nowrap">{t(`document:label.document_code.${type}`)}</span>,
            className: '_document-list-code',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => {
                const type = lodash.get(record, 'type', '');
                return type === LIST_TYPE_IMPORTING_DOCUMENT.RETURN_GOODS ? (
                    <Link params={{ id: lodash.get(record, 'id') }} to={`documents.importing.detail-importing-return-order`}>
                        {lodash.get(record, 'code', '')}
                    </Link>
                ) : (
                    <Link
                        params={{
                            id: lodash.get(record, 'id'),
                        }}
                        to={`documents.${lodash.get(record, 'type', '').toLowerCase()}.detail`}
                    >
                        {lodash.get(record, 'code', '')}
                    </Link>
                );
            },
        },
        {
            className: 'text-nowrap _document-list-warehouse-code',
            title: t('document:label.warehouse_code'),
            dataIndex: 'warehouse_code',
            key: 'warehouse_code',
            render: (text, record) => (
                <p>
                    {lodash.get(record, 'warehouse_name', '')} ({text})
                </p>
            ),
        },
        {
            title: <span className="text-nowrap">{t('document:label.verifier_username')}</span>,
            className: '_document-list-verifier-username',
            dataIndex: 'verifier_username',
            key: 'verifier_username',
        },
        {
            className: 'text-nowrap _document-list-verified-at',
            title: t('document:label.verified_at'),
            dataIndex: 'verified_at',
            key: 'verified_at',
            render: text => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
        },
    ];

    let packingColumns = [
        // {
        //     title: t("document:label.creator_username"),
        //     dataIndex: "creator_username",
        //     key: 'creator_username'
        // },
        // {
        //     title: t("document:label.created_at"),
        //     dataIndex: "created_at",
        //     key: 'created_at',
        //     render: text => <p>{text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}</p>
        // }
    ];

    let exportColumns = [
        {
            className: '_document-list-creator-username',
            title: t('document:label.creator_username'),
            dataIndex: 'creator_username',
            key: 'creator_username',
        },
        {
            title: t('document:label.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'text-nowrap _document-list-created-at',
            render: text => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
        },
        {
            title: t('document:label.status'),
            dataIndex: 'status',
            key: 'status',
            className: 'text-nowrap _document-list-status',
            render: text =>
                text ? (
                    <Badge
                        color={STATUS_COLORS[text]}
                        text={t(
                            `document:${(expandStatus.includes(type) ? `statuses_type.${type}.` : 'packing_status.') + text}`
                        )}
                    />
                ) : null,
        },
    ];

    const inventoryColumns = [
        {
            title: <span className="text-nowrap">{t(`document:label.document_code.${type}`)}</span>,
            className: '_document-list-code',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => (
                <Link
                    params={{
                        id: lodash.get(record, 'id'),
                    }}
                    to={`documents.inventory.preview`}
                >
                    {lodash.get(record, 'code', '')}
                </Link>
            ),
        },
        {
            className: 'text-nowrap _document-list-warehouse-code',
            title: t('document:label.warehouse_code'),
            dataIndex: 'warehouse_code',
            key: 'warehouse_code',
            render: (text, record) => (
                <p>
                    {lodash.get(record, 'warehouse_name', '')} ({text})
                </p>
            ),
        },
        {
            title: t('document:label.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            className: 'text-nowrap _document-list-created-at',
            render: text => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
        },
        {
            title: t('document:label.status'),
            dataIndex: 'status',
            key: 'status',
            className: 'text-nowrap _document-list-status',
            render: text =>
                text ? <Badge color={STATUS_COLORS[text]} text={t(`document:statuses_type.${type}.${text}`)} /> : null,
        },
        {
            title: t('document:label.adjustment_date'),
            dataIndex: 'verified_at',
            key: 'verified_at',
            className: 'text-nowrap _document-list-adjustment-date',
            render: text => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
        },
        {
            title: t('note'),
            dataIndex: 'note',
            key: 'note',
            className: 'text-nowrap _document-list-note',
        },
    ];

    function tableColumns() {
        if (type === TYPES.TYPE_SKU_INVENTORY) {
            return inventoryColumns;
        }
        return commonColumns.concat(expandStatus.includes(type) ? exportColumns : packingColumns);
    }

    return (
        <div className="bg-white rounded-12 p-4 mt-3">
            <h3 className="text-fz-18">{t(`common:heading.documents.${type}`)}</h3>
            <Table
                className="list-products-table pb-3"
                dataSource={documents}
                rowKey={record => record.id}
                columns={tableColumns()}
                pagination={false}
                loading={loading}
                locale={{ emptyText: <TableEmpty loading={loading} /> }}
                scroll={{ x: true }}
            />
            <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
        </div>
    );
}
