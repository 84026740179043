import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";

export default class ServiceLocation {

    constructor() {
        this.data = null;
        this.promise = null;
        this.code = null;
        this.type = null;
    }

    list(type, code) {
        if (type === this.type && code === this.code) {
            if (this.promise)
                return this.promise;
            else
                return Promise.resolve(this.data);
        } else {
            this.type = type;
            this.code = code;
        }

        return this.fetchData();
    }

    fetchData() {
        return this.promise = this.runApi().then(data => this.data = data);
    }

    runApi() {
        return api.getLocations({type: this.type, parent_code: this.code, active: 1})
            .then(res => _.get(res, "data.locations", []))
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("merchant:label.country").toLowerCase()})});
                return Promise.reject(err);
            });
    }
}
