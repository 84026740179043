import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Row, Col, Input, Select } from 'antd';
import _, { get, isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import useScanBarcode from '@Modules/App/Hooks/useScanBarcode';
import useSize from '@Modules/App/Hooks/useSize';
import { warehouseStorage } from '@Modules/App/services';
import { catchErrors } from '@Modules/App/services/helpers';
import SelectMerchants from '@Modules/Merchant/components/SelectMerchants';
import ScanBarcode from '@Modules/ScanOrder/components/ScanBarcode';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';

import { t, trans } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

import api from '../../../services/api';
import { DOCUMENT_IMPORTING_SCAN_TYPE } from '../../../services/constants';
import UploadSkus from './UploadSkus';
import useGeneratorKey, { IdempotencyStorage } from '@System/hooks/useGeneratorKey';
import LeavePageBlocker from '../components/LeavePageBlocker';
import notification from '@System/notification';

const { Option } = Select;
const validatedRules = {
    barcode: [],
    warehouse_id: [
        {
            required: true,
            type: 'integer',
            message: t('order:message.required', { attribute: t('label.select_warehouses') }),
        },
    ],
    barcode_type: [
        {
            required: true,
            type: 'string',
            message: t('order:message.required', { attribute: t('label.scan_type') }),
        },
    ],
    merchant_id: [
        {
            required: true,
            type: 'integer',
            message: t('order:message.required', { attribute: t('label.select_seller') }),
        },
    ],
};

export default function FormScan({ storeActions, storeData, form, storeErrors }) {
    const { isMobile } = useSize();
    const { uniqueKey, generatorKey } = useGeneratorKey()

    const [dirty, setDirty] = useState(false)
    const [isScanning, setIsScanning] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [errors, setErrors] = useState([]);
    const { skus } = storeData;
    const isSaved = _.get(storeData, 'isSaved', false);
    const [visible, setVisible] = useState(false);
    const excelRef = useRef([]);
    const barcodeInputRef = useRef();
    const scan = useScanBarcode(api.scanImportingSku, {
        onSettled: () => {
            setIsScanning(false);
            form.setFieldsValue({
                barcode: undefined,
            });
            barcodeInputRef.current.focus({ cursor: 'start' });
        },
    });

    useEffect(() => {
        let attrs = Object.keys(_.omit(storeData, ['skus'])).map(item => ({ name: item, value: storeData[item] }));
        form.setFields(attrs);

        if (!storeData.warehouse_id && warehouseStorage.value) _onChange('warehouse_id', warehouseStorage.value);

        if (!storeData.barcode_type) _onChange('barcode_type', DOCUMENT_IMPORTING_SCAN_TYPE.SKU_CODE);
        if (!storeData.merchant_id) _onChange('merchant_id', 0);
    }, []);

    /**
     * Nếu là 1 chuỗi thì là quét mã = tay
     * Nếu là 1 mảng thì là import từ excel
     * Định dạng trả về [["ABC", 1]]
     */
    function _onScan(barcode) {
        let isImport = false;
        let barcodes = [];
        if (Array.isArray(barcode)) {
            isImport = true;
            barcodes = [...barcode];
        } else {
            let formatedBarcode = barcode.trim();
            if ([undefined, null, ''].includes(formatedBarcode)) return;
            barcodes.push([formatedBarcode, 1]);
        }

        scanData(barcodes, isImport);
    }

    /**
     * Quét các mã sku nếu tồn tại trên hệ thống thì cho vào DS sp với số lượng tương ứng
     */
    function scanData(barcodes, isImport = false) {
        setIsScanning(true);
        form.validateFields()
            .then(values => {
                const params = {
                    ..._.pick(values, ['warehouse_id', 'barcode_type', 'merchant_id']),
                    barcode: barcodes.map(item => item[0]),
                    playSound: !isImport,
                };
                const key = _.map(
                    _.pick(params, ['barcode', 'warehouse_id', 'barcode_type']),
                    (value, key) => `${key}:${value}`
                ).join('-');
                scan(params)
                    .then(res => {
                        form.setFields([{ name: 'barcode', value: '' }]);

                        let scanedSkus = _.get(res, 'data', []);
                        if (scanedSkus.length > 0) {
                            let newScanedSkus = barcodes.map(item => {
                                let sku = scanedSkus.find(scanedSku => {
                                    const barcode_type = form.getFieldValue('barcode_type');
                                    let scanValue =
                                        barcode_type === DOCUMENT_IMPORTING_SCAN_TYPE.SKU_REF
                                            ? scanedSku.ref
                                            : barcode_type === DOCUMENT_IMPORTING_SCAN_TYPE.SKU_CODE
                                            ? scanedSku.code
                                            : scanedSku.id;
                                    if (scanValue) scanValue = scanValue.toString();
                                    return scanValue === item[0];
                                });
                                return {
                                    sku_id: sku.id,
                                    sku_code: sku.code,
                                    ref_code: sku.ref,
                                    sku_name: sku.name,
                                    quantity: 0,
                                    "serial_numbers": [],
                                    is_serial_number: sku.is_serial_number
                                };
                            });

                            storeActions.addSku(newScanedSkus);
                        }

                        _onResetErrors();
                        setVisible(false);
                        if (isImport) {
                            notification.success(t('document:messages.import_barcode_successfully'));
                        } else {
                            storeErrors.removeAll(key);
                            notification.success(t('document:messages.scan_barcode_successfully'));
                        }
                    })
                    .catch(error => {
                        catchErrors(false, showErrors)(error);
                        // Thêm lượt quét lỗi
                        if (!isImport) {
                            storeErrors.add({
                                key,
                                message: _.get(error, 'response.data.data', {}),
                            });
                        }
                    });
            })
            .catch(() => setIsScanning(false));
    }

    function showErrors(errs) {
        let sku_exists = _.get(errs, 'sku_exists', []);

        if (sku_exists.length > 0 && !visible) {
            setErrors([
                trans(`document:errors.sku_exists_${form.getFieldValue('barcode_type').toLowerCase()}`, {
                    codes: <strong>{sku_exists.join(', ')}</strong>,
                }),
            ]);
        }

        // Nếu import excel thì tìm sku_code hiển thị lỗi theo số dòng
        if (visible) {
            let stt = 0;
            let errorMessages = excelRef.current.reduce((messages, row, index) => {
                let sku_code = row[0];
                if (sku_exists.length > 0) {
                    if (![undefined, null, ''].includes(sku_code) && sku_exists.includes(sku_code)) {
                        messages.push(
                            trans(
                                `document:errors.sku_exists_${form.getFieldValue('barcode_type').toLowerCase()}_row`,
                                {
                                    number: index,
                                    code: <strong>#{sku_code}</strong>,
                                }
                            )
                        );
                    }
                } else {
                    if (index > 0) {
                        const listKeyException = ['has_many_in_merchant'];
                        Object.keys(errs).forEach(item => {
                            if (listKeyException.includes(item) && stt < 1) {
                                const error_keys = Object.keys(errs[item]);
                                if (!_.isEmpty(error_keys)) {
                                    if (error_keys[0] === sku_code) {
                                        messages.push(
                                            trans('import_errors.line', {
                                                line: index,
                                                content: trans(`document:errors.${item}`, {
                                                    code: <strong>#{sku_code}</strong>,
                                                }),
                                            })
                                        );
                                        stt++;
                                    }
                                }
                            } else {
                                messages.push(trans(`document:errors.${item}`));
                            }
                        });
                    }
                }
                return messages;
            }, []);
            setErrors(errorMessages);
        }
    }

    function _onResetErrors() {
        setErrors([]);
        excelRef.current = [];
    }

    function _onChange(name, value) {
        if (name === 'warehouse_id') {
            warehouseStorage.setValue(value);
        }
        storeActions.update({ [name]: value });
    }

    /**
     * Reset form và localStorage về mặc định
     * Nếu kho được cache thì lấy giá trị cache nếu ko thì bỏ qua
     */
    function _onReset() {
        const merchant_id = getVar(storeData, 'merchant_id', 0);
        let defaultValues = { barcode_type: DOCUMENT_IMPORTING_SCAN_TYPE.SKU_CODE, merchant_id };
        if (warehouseStorage.value) defaultValues.warehouse_id = warehouseStorage.value;

        storeActions.reset(defaultValues);
        form.resetFields();
    }

    function _onSave() {
        const doSave = () => {
            setIsSaving(true);
            setDirty(true)
            let formatedSkus = skus.map(item => ({ id: item.sku_id, quantity: item.quantity,serial_numbers: item.serial_numbers }));
            api.createDocumentImportingSkus({ ...storeData, skus: formatedSkus })
                .then(res => {
                    const dataImportingDocument = getVar(res, 'data.document', {});
                    storeActions.isSaved();
                    storeErrors.clear();
                    setDirty(false)
                    notification.success(t('document:messages.scan_importing_skus_successfully'));
                    url.redirectTo('documents.importing.detail', {
                        id: dataImportingDocument?.id,
                    });
                })
                .catch((error) => {
                    const { response } = error
                    const code = get(response, 'data.code')
                    const errors = get(response, 'data.data')
                    if (code === 'REQUEST_PROCESSED') {
                        setDirty(true)
                        notification.warning(t('message.request_process'))
                    }else{
                        generatorKey()
                        setDirty(false)
                        const sku_serial_number_invalid = get(errors, 'sku_serial_number_invalid')

                        if(!isEmpty(sku_serial_number_invalid)){
                            notification.error(trans(`document:messages.sku_serial_number_invalid`,{
                                sku_code: <b>{get(sku_serial_number_invalid, 'sku_code', '')}</b>,
                                serial: <b>{get(sku_serial_number_invalid, 'serial', '')}</b>
                            }));
                        }else{
                            notification.error( t('document:messages.scan_importing_skus_fail'));
                        }


                        
                    }
                })
                .finally(() => setIsSaving(false));
        };

        if (storeErrors.hasError) {
            Modal.confirm({
                title: t('document:create_importing_document.scan_errors_confirm'),
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
                onOk: doSave,
            });
        } else {
            doSave();
        }
    }

    return (
        <>
        {dirty && <LeavePageBlocker when={dirty} />}

         <div className="bg-white rounded-12 p-4">
            <div className="search-box">
                <div className="search-box--title d-lg-flex justify-content-between pb-4">
                    <h3 className="text-fz-18">{t('title.importing_scan')}</h3>
                    <div>
                        <UploadSkus
                            onScan={_onScan}
                            onResetErrors={_onResetErrors}
                            visible={visible}
                            setVisible={setVisible}
                            errors={errors}
                            excelRef={excelRef}
                            form={form}
                        />
                        <span className="mr-2">
                            <Button className="_importing_scan--reset-btn rounded-4 mt-2 mt-md-0" onClick={_onReset}>
                                <ReloadOutlined /> {t('btn.reset')}
                            </Button>
                        </span>
                        <Button
                            className="_importing_scan--save-btn rounded-4 mt-2 mt-md-0"
                            type="primary"
                            disabled={!skus || skus.length === 0 || isSaved}
                            onClick={_onSave}
                            loading={isSaving}
                        >
                            <SaveOutlined /> {t('btn.save')}
                        </Button>
                    </div>
                </div>

                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{
                        warehouse_id: warehouseStorage.value,
                        barcode_type: DOCUMENT_IMPORTING_SCAN_TYPE.SKU_CODE,
                        merchant_id: 0,
                    }}
                >
                    <div className="search-box--body">
                        <Row gutter={20}>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <Form.Item
                                    className="mb-3"
                                    name="sender_name"
                                    label={t('document:label.delivered_person')}
                                >
                                    <Input
                                        className="rounded-4 _importing_scan--receiver_name"
                                        placeholder={t('document:placeholder.delivered_name')}
                                        onChange={e => _onChange('sender_name', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <Form.Item className="mb-3" name="sender_phone" label={isMobile ? '' : ' '}>
                                    <Input
                                        className="rounded-4 _importing_scan--receiver_phone"
                                        placeholder={t('document:placeholder.delivered_mobile')}
                                        onChange={e => _onChange('sender_phone', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <Form.Item className="mb-3" name="sender_license" label={isMobile ? '' : ' '}>
                                    <Input
                                        className="rounded-4 _importing_scan--receiver_license"
                                        placeholder={t('document:placeholder.delivered_vehicle_number')}
                                        onChange={e => _onChange('sender_license', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <Form.Item
                                    className="mb-3"
                                    name="sender_partner"
                                    label={t('document:label.delivered_company')}
                                >
                                    <Input
                                        className="rounded-4 _importing_scan--partner"
                                        placeholder={t('document:placeholder.delivered_company')}
                                        onChange={e => _onChange('sender_partner', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                                <Form.Item
                                    className="mb-0"
                                    name="warehouse_id"
                                    label={t('label.select_warehouses')}
                                    rules={validatedRules.warehouse_id}
                                >
                                    <SelectWarehouse
                                        className="_importing_scan--warehouse_id"
                                        allowClear={true}
                                        placeholder={t('product:placeholder.warehouse_name')}
                                        onChange={value => _onChange('warehouse_id', value)}
                                        noSuggest={false}
                                        disabled={skus && skus.length > 0 && warehouseStorage.value}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                                <Form.Item
                                    className="mb-0"
                                    name="barcode_type"
                                    label={t('label.scan_type')}
                                    rules={validatedRules.barcode_type}
                                >
                                    <Select
                                        className="_importing_scan--barcode_type"
                                        allowClear={true}
                                        placeholder={t('placeholder.scan_type')}
                                        onChange={value => _onChange('barcode_type', value)}
                                        disabled={skus && skus.length > 0}
                                    >
                                        {Object.values(DOCUMENT_IMPORTING_SCAN_TYPE).map(item => (
                                            <Option key={item} value={item}>
                                                {t(`document:type.${item}`)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                                <Form.Item
                                    className="mb-0"
                                    name="merchant_id"
                                    label={t('label.select_seller')}
                                    rules={validatedRules.merchant_id}
                                >
                                    <SelectMerchants
                                        placeholder={t('placeholder.select_seller')}
                                        alloption={1}
                                        className="_importing_scan--scan-seller-id"
                                        allowClear={true}
                                        onChange={value => _onChange('merchant_id', value)}
                                        disabled={skus && skus.length > 0}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                                <Form.Item
                                    className={`mb-0 ${isMobile ? 'mt-3' : ''}`}
                                    name="barcode"
                                    label={t('order:label.barcode')}
                                    rules={validatedRules.barcode}
                                >
                                    <Input
                                        className="_importing_scan--barcode"
                                        ref={barcodeInputRef}
                                        onPressEnter={e => _onScan(e.target.value)}
                                        placeholder={t('order:placeholder.barcode', { typeAction: 'sku' })}
                                        suffix={<ScanBarcode onUpdate={_onScan} disabled={isScanning || isSaved} />}
                                        disabled={isScanning || isSaved}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        </div>
        </>
       
    );
}
