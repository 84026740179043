import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useDeleteStoreMutation = createUseMutation({
    namespace: 'store:delete',
    mutation: id => apiService.delete(id),
});

export default useDeleteStoreMutation;
