import React from "react";
import _ from "lodash";
import {t} from "@System/i18n";
import {Avatar, Table, Row, Col} from "antd";
import TableEmpty from "../../../../App/Utilities/TableEmpty";
import Inspection from "./Inspection";
import {getVar} from "@System/support/helpers";

export default function InspectionProducts({orderDetail}) {
    const orderStocks = _.get(orderDetail, "orderStocks", []);

    const columns = [
        {
            title: (
                <Row>
                    <Col span={13}>{t("product:label.sku_code")}</Col>
                    <Col span={3}>{t("order:label.unit")}</Col>
                    <Col span={4} className="text-right">{t("product:label.quantity")}</Col>
                </Row>
            ),
            dataIndex: 'code',
            key: 'code',
            className: "text-nowrap _sku-code-column",
            render: (text, record) => {
                let {sku, product} = record;
                const image = getVar(product, "images.0", "");
                return (
                    <Row>
                        <Col span={13}>
                            <div className="d-flex align-items-center">
                                <Avatar src={image} shape="square" className="mr-2" size={36}/>
                                <div>
                                    <span className="text-primary">{sku.code}</span>
                                    <p>{sku.name}</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={3} className="_unit-name">{_.get(record, "unit.name", "")}</Col>
                        <Col span={4} className="text-right _quantity-quantity">{record.quantity}</Col>
                    </Row>
                );
            }
        }
    ];

    function renderStacks(orderStock, index) {
        return (
            <Inspection
                index={index}
                stock={orderStock}
            />
        );
    }

    if (orderStocks.length <= 0)
        return null;
    return (
        <div>
            <Table
                dataSource={orderStocks}
                rowKey={record => record?.orderStock?.id}
                columns={columns}
                pagination={false}
                locale={{emptyText: <TableEmpty/>}}
                expandable={{
                    defaultExpandAllRows: true,
                    rowExpandable: () => {
                        return true;
                    },
                    expandIcon: null,
                    expandedRowRender: renderStacks
                }}
                scroll={{x: true}}
                className="inspection-table"
            />
        </div>
    );
}
