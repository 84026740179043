import { PlusOutlined } from '@ant-design/icons';
import { Modal, Button, Checkbox, Row, Col, Form, Select, notification } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { t } from '@System/i18n';

import ServiceMerchant from '../../Merchant/services/ServiceMerchant';
import api from '../services/api';
import {catchErrors} from "@Modules/App/services/helpers";

const { Option } = Select;

function UpdateMerchantOfProduct({ productId, checkedMerchants, fetchVendor }) {
    const [merchants, setMerchants] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationId, setLocationId] = useState(undefined);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(false);

    useEffect(() => {
        setCheckedList(_.map(checkedMerchants, 'merchant.id'));
    }, [checkedMerchants]);

    useEffect(() => {
        let isMounted = true;
        setLoadingLocation(true);
        ServiceMerchant.list()
            .then(res => {
                const locations = _.uniqBy(
                    _.filter(_.map(res, 'location'), item => !_.isEmpty(item)),
                    'id'
                );
                if(isMounted ) {
                    setMerchants(res);
                    setLocations(locations);
                }
            })
            .finally(() => setLoadingLocation(false));
        return () => {
            isMounted = false;
        };
    }, []);

    const onChangeSelected = value => {
        setLocationId(value);
        const result = _.filter(merchants, ['location_id', value]);
        setSearchResult(result);
    };

    function onChangeChecked(e) {
        const checkedValues = checkedList.slice();
        const { value, checked } = e.target;
        if (checked) {
            setCheckedList([...checkedValues, value]);
        } else {
            _.remove(checkedValues, item => item === value);
            setCheckedList(checkedValues);
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setLoading(true);
        api.updateProductMerchants(productId, { merchant_ids: checkedList })
            .then(res => {
                handleCancel();
                fetchVendor();
                notification.success({
                    message: t('common:message.update_success', { attribute: t('merchant:title.update_vendor_product') }),
                });
            })
            .catch(catchErrors(t('common:message.fetch_fail')))
            .finally(() => setLoading(false));
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setLocationId(undefined);
        setCheckedList(_.map(checkedMerchants, 'merchant.id'));
    };

    return (
        <>
            <Button
                className="_update-merchant"
                type="primary"
                onClick={showModal}
                loading={loadingLocation}
            >
                <PlusOutlined />
                {t('merchant:title.list-merchants')}
            </Button>
            {locations.length > 0 && isModalVisible && (
                <Modal
                    title={t('merchant:title.list-merchants')}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>
                            {t('btn.reset')}
                        </Button>,
                        <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                            {t('btn.update')}
                        </Button>,
                    ]}
                    cancelButtonProps={{ className: '_modal-update-merchant-btn-cancel' }}
                    okButtonProps={{ className: '_modal-update-merchant-btn-ok' }}
                    width={650}
                    btn
                >
                    <Form.Item label={t("merchant:label.market")}>
                        <Select
                            allowClear={true}
                            showSearch
                            placeholder={t("merchant:placeholder.market")}
                            value={locationId}
                            onChange={onChangeSelected}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {
                                locations.map(item => <Option value={item.id} key={item.id}>{item.label}</Option>)
                            }
                        </Select>
                    </Form.Item>

                    <Checkbox.Group value={checkedList}>
                        <Row gutter={20}>
                            {(typeof locationId !== 'undefined' ? searchResult : merchants).map((item, index) => {
                                const name = _.get(item, 'name', '');
                                const code = _.get(item, 'code', '');
                                return (
                                    <Col span={24} key={index}>
                                        <Checkbox
                                            className={"_update-merchant-checkbox"}
                                            key={item.id}
                                            value={parseInt(item.id)}
                                            style={{ lineHeight: '32px' }}
                                            onChange={onChangeChecked}
                                        >
                                            {code + (name ? ' - ' + name : '')}
                                        </Checkbox>
                                    </Col>
                                );
                            })}
                        </Row>
                    </Checkbox.Group>
                </Modal>
            )}
        </>
    );
}

export default UpdateMerchantOfProduct;
