import { Modal, Table, Button } from 'antd'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { dateFormatter } from '@Modules/App/services'
import { LIST_TYPE_IMPORTING_DOCUMENT, TYPES } from '@Modules/Document/services/constants'
import useHistoryOfProductVariant from '@Modules/ProductVariants/Hooks/useHistoryOfProductVariantQuery'
import { HISTORY_OF_PRODUCT_VARIANT_COLOR } from '@Modules/ProductVariants/services/constants'

import { t } from '@System/i18n'
import { Link } from '@System/routing'
import { getVar } from '@System/support/helpers'

function HistoryOfProductVariant({ sku_id, visible, setVisible, setSkuId }) {
    const [dataSource, setDataSource] = useState([])
    const [pagination, setPagination] = useState({})
    const [filters, setFilters] = useState({})
    const { isLoading, data } = useHistoryOfProductVariant(sku_id, filters)
    useEffect(() => {
        setDataSource(getVar(data, 'data.stock_logs', []))
        setPagination(getVar(data, 'data.pagination', {}))
    }, [data])

    const columns = [
        {
            className: '_history-of-product-variant-time',
            title: t('sku:label.stock_log.time'),
            dataIndex: ['stock_log', 'created_at'],
            key: 'created_at',
            render: text => (text ? dateFormatter.full(text) : ''),
        },
        {
            className: '_history-of-product-variant-action',
            title: t('sku:label.stock_log.action'),
            dataIndex: ['stock_log', 'action'],
            key: 'action',
            // render: text => (text ? t(`sku:ACTION_HISTORY.${text}`) : ''),
            render: (text, record) => {
                return text ? <p>{t(`sku:ACTION_HISTORY.${text}`)}</p> : ''
            },
        },
        {
            className: '_history-of-product-variant-quantity',
            title: t('sku:label.stock_log.quantity'),
            dataIndex: ['stock_log', 'change'],
            key: 'change',
            render: (text, record) => {
                let real_quantity = getVar(record, 'stock_log.real_quantity', '')
                return text ? (
                    <p style={{ color: HISTORY_OF_PRODUCT_VARIANT_COLOR[text] }}>{`${
                        text === 'INCREASE' ? '+' : text === 'DECREASE' ? '-' : ''
                    }${real_quantity}`}</p>
                ) : (
                    ''
                )
            },
        },
        {
            className: '_history-of-product-variant-document',
            title: t('sku:label.stock_log.document'),
            dataIndex: ['stock_log', 'action'],
            key: 'action',
            render: (item, record) => {
                return renderLogsDocument(item, record)
            },
        },
    ]

    function handleCancel() {
        setVisible(false)
        setSkuId(undefined)
    }

    const renderLogsDocument = (action, record) => {
        const object_type = getVar(record, 'stock_log.object_type', '')
        const document = getVar(record, 'stock_log.payload.document', '')
        const documentType = getVar(document, 'type', '')
        const order = getVar(record, 'stock_log.payload.order', '')
        const warehouse_area_code = getVar(record, 'stock_log.payload.warehouse_area_code', '')

        if (warehouse_area_code) {
            return <p>{`${t('order:label.warehouse_area')} ${warehouse_area_code}`}</p>
        }

        if (action && object_type) {

            if(object_type === 'PICKING_SESSION_PIECE'){
                return ''
            }


            switch (documentType) {
                case 'CHANGE_POSITION_GOODS':
                    if (object_type === 'DOCUMENT_SKU_INVENTORY') {
                        return (
                            <p>
                                {t('sku:label.document_sku_inventory')}
                                {` #${getVar(document, 'code', '')}`}
                            </p>
                        )
                    }

                    return (
                        <p>
                            {t(`sku:DOCUMENT_ACTION_HISTORY.${documentType}`)}
                            <span></span>
                        </p>
                    )
                default:
                    return (
                        <p>
                            {object_type === 'DOCUMENT_SKU_INVENTORY'
                                ? t('sku:label.document_sku_inventory')
                                : t(`sku:DOCUMENT_ACTION_HISTORY.${documentType}`)}
                            <span>
                                {isEmpty(document) ? (
                                    <> {` #${order ? order.code : ''}`}</>
                                ) : (
                                    <Link
                                        isNewTab={true}
                                        params={{
                                            id: getVar(document, 'id'),
                                        }}
                                        to={renLinkRedirect(documentType)}
                                    >
                                        {` #${getVar(document, 'code', '')}`}
                                    </Link>
                                )}
                            </span>
                        </p>
                    )
            }
        }

        switch (documentType) {
            case 'IMPORTING_RETURN_GOODS':
                return (
                    <p>
                        {t(`sku:DOCUMENT_ACTION_HISTORY.${documentType}`)}
                        <span>
                            {isEmpty(document) ? (
                                <> {` #${order ? order.code : ''}`}</>
                            ) : (
                                <Link
                                    isNewTab={true}
                                    params={{
                                        id: getVar(document, 'id'),
                                    }}
                                    to={renLinkRedirect(documentType)}
                                >
                                    {` #${getVar(document, 'code', '')}`}
                                </Link>
                            )}
                        </span>
                    </p>
                )

            default:
                break
        }

        return ''
    }

    const renLinkRedirect = documentType => {
        switch (documentType) {
            case LIST_TYPE_IMPORTING_DOCUMENT.RETURN_GOODS:
                return 'documents.importing.detail-importing-return-order'
            case LIST_TYPE_IMPORTING_DOCUMENT.TYPE_SKU_INVENTORY:
                return 'documents.inventory.preview'

            default:
                return `documents.${documentType.toLowerCase()}.detail`
        }
    }

    return (
        <Modal
            title={`${t('label.history_change')} (${getVar(pagination, 'total', 0)})`}
            visible={visible}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="cancel"
                    onClick={handleCancel}
                >
                    {t('btn.cancel')}
                </Button>,
            ]}
            width={750}
        >
            <Table
                loading={isLoading}
                rowKey={record => record?.stock_log?.id}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                scroll={{ x: true }}
                locale={{
                    emptyText: (
                        <TableEmpty
                            className="_history-of-product-variant-no-data"
                            loading={isLoading}
                        />
                    ),
                }}
            />
            <CustomizedPagination
                pagination={pagination}
                filters={filters}
                addFilter={setFilters}
            />
        </Modal>
    )
}
export default HistoryOfProductVariant
