import React, {useEffect, useState} from 'react';
import {Form, Button, Row, Col, Input} from "antd";
import SelectMarket from "../../../Location/components/SelectMarket";
import {t} from "../../../../system/i18n";
import SelectMerchants from "../../../Merchant/components/SelectMerchants";
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";

function FormSearch({filters, addFilter}) {
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const {
        username,
        email,
        location_id,
        merchant_id,
    } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function onChange(name, value) {
        setInput({...input, [name]: value});
    }

    function onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        onChange(name, value);
    }

    function handleReset() {
        addFilter({});
    }

    function handleSearch() {
        addFilter(input);
    }
    return (
        <Form
            form={form}
            layout="vertical"
            className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray"
        >
            <Row gutter={20}>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        label={t("user:label.username")}
                    >
                        <Input
                            name="username"
                            value={username}
                            placeholder={t("user:placeholder.username")}
                            onChange={onChangeInput}
                            onPressEnter={handleSearch}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        label={t("user:label.email")}
                    >
                        <Input
                            name="email"
                            placeholder={t("user:placeholder.email")}
                            value={email}
                            onChange={onChangeInput}
                            onPressEnter={handleSearch}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-3"
                        label={t("merchant:label.market")}
                    >
                        <SelectMarket
                            allowClear={true}
                            placeholder={t("merchant:placeholder.market")}
                            onChange={(value) => onChange("location_id", value)}
                            value={location_id ? parseInt(location_id) : undefined}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        label={t("order:label.merchant_id")}
                    >
                        <SelectMerchants
                            allowClear={true}
                            placeholder={t("order:placeholder.merchant_id")}
                            onChange={(value) => onChange("merchant_id", value)}
                            value={merchant_id ? parseInt(merchant_id) : undefined}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{offset: 18, span: 6}} className="pt-4 text-right" style={{marginTop: "6px"}}>
                    <Button type="primary" onClick={handleSearch} icon={<SearchOutlined/>}>{t("btn.search")}</Button>
                    <Button onClick={handleReset} className="ml-2" icon={<UndoOutlined />}>{t("btn.reset")}</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;