import { api } from '@System/api';

const BASE_URL = 'stores';

export default {
    list: params =>
        api.get(BASE_URL, {
            params,
        }),
    delete: id => api.delete(`${BASE_URL}/${id}`),
};
