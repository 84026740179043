import { DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, Modal, Row, Table } from 'antd';
import lodash, { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import api from '@Modules/ProductVariants/services/api';
import { EVENTS } from '@Modules/ProductVariants/services/constants';

import { events } from '@System/events';
import notification from '@System/notification';

import { t } from '../../../../system/i18n';
import { url } from '../../../../system/routing';
import CustomizedPagination from '../../../App/CustomizedPagination';
import TableEmpty from '../../../App/Utilities/TableEmpty';
import { dateFormatter } from '../../../App/services';

export default function List({ skus, pagination, addFilter, filters, loading }) {
    const [checkedList, setCheckedList] = useState([]);
    const [statusFilter, setStatusFilter] = useState([]);

    let getColumns = [
        {
            className: "_variant-list-sku-id",
            title: t("product:label.sku_id"),
            dataIndex: ['sku', 'id'],
            key: 'sku.id',
            render: (text, record) => {
                const productId = lodash.get(record, "sku.product_id", undefined);
                return <strong style={{fontWeight: "500"}}>
                    <Link to={url.to("products.detail", {id: productId})}>{text}</Link>
                </strong>;
            },
        },
        {
            className: "_variant-list-sku-code",
            title: t("product:label.sku_code"),
            dataIndex: ['sku', 'code'],
            key: 'sku_code',
            render: (text, record) => {
                const productId = lodash.get(record, "sku.product_id", undefined);
                return <strong style={{fontWeight: "500"}}>
                    <Link to={url.to("products.detail", {id: productId})}>{text}</Link>
                </strong>;
            },
        },
        {
            className: '_variant-list-sku-name',
            title: t('product:label.sku_name'),
            dataIndex: 'sku.name',
            key: 'product.name',
            render: (text, record) => {
                let { sku } = record;
                return <p>{sku.name}</p>;
            },
        },
        {
            className: '_variant-list-category-id',
            title: t('product:label.category_id'),
            dataIndex: 'category.name',
            key: 'category.name',
            render: (text, record) => {
                let { category } = record;
                const name = lodash.get(category, 'name', null);
                return <p>{name}</p>;
            },
        },
        // {
        //     title: t("product:label.sku_stock"),
        //     dataIndex: "sku.stock",
        //     key: 'sku.stock',
        //     className: "text-center",
        //     render: (text, record) => {
        //         let {sku} = record;
        //         return <p>{sku.real_stock}</p>;
        //     },
        // },
        // {
        //     title: t("product:label.sku_stock_tmp"),
        //     dataIndex: "sku.stock_tmp",
        //     key: 'sku.stock',
        //     className: "text-center",
        //     render: (text, record) => {
        //         let {sku} = record;
        //         return <p>{sku.stock}</p>;
        //     },
        // },
        {
            className: '_variant-list-created-at',
            title: t('product:label.created_at'),
            dataIndex: 'sku.created_at',
            key: 'sku.created_at',
            render: (text, record) => {
                let { sku } = record;
                return <p>{dateFormatter.formatDate(sku.created_at, 'DD/MM/YYYY')}</p>;
            },
        },
    ];
    useEffect(() => {
        setStatusFilter(get(filters, 'status'));
    }, [filters]);

    const rowSelection = {
        selectedRowKeys:checkedList,
        onChange: (selectedRowKeys, selectedRows) => {
            setCheckedList(selectedRowKeys);
    
        },
    };

    const confirmModal = name => {
        Modal.confirm({
            title: t('product:label.change_status_sku'),
            icon: <ExclamationCircleOutlined />,
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
            onOk: () => handleChangeStatusSku(name),
        });
    };

    const selectedAction = (
        <Menu className="lading-dropdown-action">
            {statusFilter === 'ON_SELL' && (
                <Menu.Item
                    className="_scan-order-list-of-lading-btn-change-service"
                    onClick={() => confirmModal('STOP_SELLING')}
                >
                    {`${t('product:btn.change_status_selling')} ${t('product:label.stop_selling')}`}
                </Menu.Item>
            )}

            {statusFilter === 'STOP_SELLING' && (
                <Menu.Item
                    key={1}
                    className="_scan-order-list-of-lading-btn-remove-selected"
                    onClick={() => confirmModal('ON_SELL')}
                >
                    {`${t('product:btn.change_status_selling')} ${t('product:label.selling')}`}
                </Menu.Item>
            )}
        </Menu>
    );

    const handleChangeStatusSku = name => {
        const data = {
            ids: checkedList,
            status: '',
        };
        switch (name) {
            case 'ON_SELL':
                data.status = 'ON_SELL';
                break;
            case 'STOP_SELLING':
                data.status = 'STOP_SELLING';
                break;
            default:
                break;
        }
        api.updateStatusSku(data)
            .then(res => {
                notification.success(t('product:message.change_status_sku_success'));
                events.dispatch(EVENTS.RELOAD_PRODUCT_LIST, {});
                setCheckedList([]);
            })
            .catch(() => {
                notification.success(t('product:message.change_status_sku_failed'));
            });
    };

    return (
        <div className="m-3">
            <Row>
                <Col xs={24} md={{ span: 12 }} lg={{ span: 16 }}>
                    {checkedList.length !== 0 && (
                        <>
                            {t('table_selection.selected_all_current_page', { count: checkedList.length })}
                            {auth.can(permissions.SKU_UPDATE) && (
                                <Dropdown overlay={selectedAction} trigger={['click']} className="_dropdown-action">
                                    <a className="ant-dropdown-link ml-2 " onClick={e => e.preventDefault()}>
                                        {t('choose_action')} <DownOutlined />
                                    </a>
                                </Dropdown>
                            )}
                        </>
                    )}
                </Col>
            </Row>
            <Table
                className="list-products-table pb-3 _variant-list "
                dataSource={skus}
                rowKey={record => record.sku.id}
                columns={getColumns}
                rowSelection={{
                    ...rowSelection,
                }}
                pagination={false}
                loading={loading}
                scroll={{ x: true }}
                locale={{ emptyText: <TableEmpty className={'_variant-list-no-data'} loading={loading} /> }}
            />
            <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
        </div>
    );
}
