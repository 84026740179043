import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, notification } from 'antd';
import { get } from 'lodash';
import { useState } from 'react';
import React from 'react';

import { t } from '../../../system/i18n';
import api from '../services/api';
import MerchantWalletForm from './MerchantWalletForm';

export default function EditMerchantWallet({ sellerId, refetch }) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [form] = Form.useForm();

    const handleCheckbox = () => {
        setDisabled(!disabled);
        setIsConfirm(!isConfirm);
    };
    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setVisible(false);
    }

    function handleCancel() {
        setVisible(false);
        form.resetFields();
    }

    function handleSubmit(e) {
        form.validateFields().then(values => {
            setLoading(true);
            api.editMerchantsWallet(sellerId, values)
                .then(res => {
                    notification.success({ message: t('message.create_success') ,duration: 6});
                    refetch();
                    handleCancel();
                })
                .catch(err => {
                    let { response } = err;
                    let errors_balance = get(response, 'data.data.error.title', {});
                    if (response.status === 400) {
                        let error_messages = [];
                        let errors = get(response, 'data.data', {});
                        Object.keys(errors).forEach(item => {
                            let key_errors = [];
                            key_errors.push(t('merchant:message.value_invalid'));
                            error_messages.push({ name: item, errors: key_errors });
                        });
                        form.setFields(error_messages);
                    }
                    notification.error({
                        message: t(
                            `message.${
                                errors_balance === 'insufficient_balance' ? 'insufficient_balance' : 'create_transaction_failed'
                            }`
                        ),
                        duration: 6,
                    });
                })
                .finally(() => {
                    setLoading(false);
                    setIsConfirm(false);
                    setDisabled(true);
                });
        });
    }

    return (
        <>
            <Button type="primary" className="mt-2 mt-md-0 btn-create_transaction" onClick={showModal}>
                <PlusOutlined /> {t('common:btn.create_transaction')}
            </Button>
            <Modal
                title={t('common:btn.create_transaction').toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                width={600}
                footer={null}
                maskClosable={false}
            >
                <MerchantWalletForm
                    loading={loading}
                    handleSubmit={handleSubmit}
                    form={form}
                    isConfirm={isConfirm}
                    handleCheckbox={handleCheckbox}
                    disabled={disabled}
                />
            </Modal>
        </>
    );
}
