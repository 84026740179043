import { SearchOutlined, UndoOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, DatePicker } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React, { useState, useEffect } from 'react'

import SelectAccounts from '@Modules/Account/components/SelectAccounts'
import { IForwardInfoFilter } from '@Modules/Finance/Interfaces/interface'
import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner'

import { t } from '@System/i18n'

interface IFormSearchProps {
    filters: any
    addFilter: any
}

const { RangePicker } = DatePicker

const dateFormat = 'DD/MM/YYYY'

const initialFilter = {
    code: '',
    tracking_code: '',
    creator_id: '',
    shipping_partner_id: '',
}

const FormSearch: React.FC<IFormSearchProps> = ({ filters, addFilter }) => {
    const [input, setInput] = useState<IForwardInfoFilter>(initialFilter)
    const { creator_id, code, shipping_partner_id, tracking_code } = input || {}
    const created_at_from = get(input, 'created_at[from]')
    const created_at_to = get(input, 'created_at[to]')

    useEffect(() => {
        setInput(filters)
    }, [filters])

    const onChange = (name: string, value: string) => {
        if (['shipping_partner_id', 'creator_id'].includes(name)) {
            addFilter({ ...input, [name]: value })
            return
        }
        setInput({ ...input, [name]: value })
    }

    const onChangeRangePicker = (name: string, dates: any) => {
        const name_from = `${name}[from]`
        const name_to = `${name}[to]`

        const filters = {
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        }

        addFilter(filters)
    }

    const onSearch = () => {
        addFilter(input)
    }

    const onReset = () => {
        addFilter({})
    }

    return (
        <Form layout="vertical">
            <div className="bg-white rounded-12 p-4 mt-3">
                <div className="search-box">
                    <Row gutter={20}>
                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 4 }}>
                            <Form.Item label={t('document:label.code')}>
                                <Input
                                    value={code}
                                    placeholder={t('document:placeholder.code')}
                                    onChange={e => onChange('code', e.target.value)}
                                    onPressEnter={onSearch}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 4 }}>
                            <Form.Item label={t('label.freight_bill_code')}>
                                <Input
                                    value={tracking_code}
                                    placeholder={t('placeholder.freight_bill_code')}
                                    onChange={e => onChange('tracking_code', e.target.value)}
                                    onPressEnter={onSearch}
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 4 }}>
                            <Form.Item
                                className="mb-3"
                                label={t('document:label.creator_username')}>
                                <SelectAccounts
                                    className="_document-search-creator-id"
                                    allowClear={true}
                                    placeholder={t('document:placeholder.creator')}
                                    value={creator_id ? parseInt(creator_id) : undefined}
                                    onChange={(value: any) => onChange('creator_id', value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            lg={{ span: 4 }}>
                            <Form.Item
                                className="mb-3"
                                label={t('order:label.shipping_partner_id')}>
                                <SelectShippingPartner
                                    value={shipping_partner_id ? parseInt(shipping_partner_id) : undefined}
                                    className={'_prepare-order-search-shipping-partner-id'}
                                    placeholder={t('order:placeholder.shipping_partner_id')}
                                    allowClear={true}
                                    onChange={(value: any) => onChange('shipping_partner_id', value)}
                                    locationByUser
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 8 }}>
                            <Form.Item label={t('label.created_at')}>
                                <RangePicker
                                    className="_finance-search-created-at"
                                    format={dateFormat}
                                    value={created_at_from && created_at_to ? [moment(created_at_from), moment(created_at_to)] : undefined}
                                    placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                    onChange={value => onChangeRangePicker('created_at', value)}
                                    style={{ width: '100%' }}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>

                        <Col
                            xs={{ span: 24 }}
                            lg={{ offset: 16, span: 8 }}
                            className="text-right">
                            <Button
                                className="mr-2 _finance-search-btn-reset"
                                onClick={onReset}>
                                <UndoOutlined /> {t('common:btn.set_again')}
                            </Button>
                            <Button
                                type="primary"
                                className="_finance-search-btn-search"
                                onClick={onSearch}>
                                <SearchOutlined /> {t('common:btn.search')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
    )
}

export default FormSearch
