import lodash from 'lodash';

import { t } from '../../../system/i18n';

const ERRORS = ['package_status_invalid', 'package_code_invalid'];

export function renderError(error) {
    const line = lodash.get(error, 'line', '');
    const content = lodash.get(error, 'errors', {});
    let messages = [];

    if (typeof content === 'string') {
        messages.push(t(`product:purchasing_package_import.${content}`));
    } else {
        Object.entries(content).forEach(entry => {
            let [key, errors] = entry;
            ERRORS.forEach(errorKey => {
                if (errors.hasOwnProperty(errorKey)) {
                    messages.push(
                        t(`product:purchasing_package_import.${errorKey}`, {
                            attribute: t(`product:label.${key}`),
                        })
                    );
                }
            });
        });
    }

    if (messages.length > 0) return t('product:purchasing_package_import.line', { line: line, content: messages.join(' ') });

    return t('product:message.upload_file.unknown_error');
}
