import { Button, Typography } from 'antd';
import React, { useCallback, useMemo, useState } from 'react';

import { t } from './../../../system/i18n';
import useToggle from './useToggle';

const { Text } = Typography;

const useTableSelection = settings => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isSelectAllPage, { toggle: toggleIsSelectAllPage }] = useToggle();
    const isSelectAllCurrentPage = selectedRowKeys.length === settings?.totalInCurrentPage;

    const handleChangeRowSelection = (rowKeys, rows) => {
        setSelectedRowKeys(rowKeys);
        setSelectedRows(rows);
    };

    const rowSelection = useMemo(
        () => ({
            preserveSelectedRowKeys: true,
            selectedRowKeys,
            onChange: handleChangeRowSelection,
        }),
        [selectedRowKeys]
    );

    const clearSelection = useCallback(() => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        toggleIsSelectAllPage(false);
    }, [toggleIsSelectAllPage]);

    const handleToggleIsSelectAllPage = useCallback(() => {
        toggleIsSelectAllPage();
    }, [toggleIsSelectAllPage]);

    const render = useCallback(() => {
        return selectedRowKeys.length > 0 || isSelectAllPage ? (
            <div className="mb-3 ml-2">
                <Text>
                    {t(
                        isSelectAllPage
                            ? 'table_selection.selected_all_page'
                            : 'table_selection.selected_all_current_page',
                        {
                            count: isSelectAllPage ? settings?.totalInAllPage : selectedRowKeys.length,
                            type: settings?.type,
                        }
                    )}
                </Text>
                <Button className="text-wrap" type="link" onClick={isSelectAllPage ? clearSelection : handleToggleIsSelectAllPage}>
                    {t(
                        isSelectAllPage ? 'table_selection.clear_selection' : 'table_selection.request_select_all_page',
                        {
                            count: settings?.totalInAllPage,
                            type: settings?.type,
                        }
                    )}
                </Button>
            </div>
        ) : (
            ''
        );
    }, [isSelectAllPage, settings, clearSelection, handleToggleIsSelectAllPage, selectedRowKeys.length]);

    return {
        isSelectAllPage,
        rowSelection,
        selectedRowKeys,
        selectedRows,
        clearSelection,
        render,
        setSelectedRowKeys,
    };
};

export default useTableSelection;
