import { Row, Col } from 'antd'
import _, { isEmpty } from 'lodash'
import React from 'react'

import { auth } from '@Modules/Auth/services'
import PERMISSIONS from '@Modules/Auth/services/permissions'
import CancelOrder from '@Modules/Order/screens/OrderDetail/CancelOrder'
import HistoryOrder from '@Modules/Order/screens/OrderDetail/HistoryOrder'
import EditShippingPartner from '@Modules/Order/screens/OrderDetail/OrderInfo/EditShippingPartner'
import { ORDER_STATUS } from '@Modules/Order/services/constants'

import { t } from '../../../../../system/i18n'
import { dateFormatter } from '../../../../App/services'

export default function CustomerInfo({ orderDetail, setting }) {
    const { order, creator, merchant, orderFreightBills, shippingPartner } = orderDetail

    function renderFreightBillShippingPartner() {
        if (orderFreightBills && orderFreightBills.length > 0) {
            return orderFreightBills.map((item, index) => (
                <p key={index}>{_.get(item, 'freight_bill_code', '') + ' - ' + _.get(item, 'shipping_partner.name', '')}</p>
            ))
        }
        return t('noText')
    }

    return (
        <div className="bg-white rounded-12 pt-3 pr-4 pb-4 pl-4">
            <Row>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                >
                    <h3 className="text-fz-16 font-weight-bold">
                        {t('common:heading.order-detail')}: <span className="_order-detail-code">{order.code}</span>
                    </h3>
                    <p className={`order-status order-status--${order.status}`}>{order.status ? t(`order:status.${order.status}`) : t('noText')}</p>
                </Col>

                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    className="d-md-flex align-items-center justify-content-end mt-3 mt-md-0 mb-2 mb-md-0"
                >
                    <HistoryOrder orderInfo={order} />
                    <CancelOrder orderDetail={orderDetail} />
                </Col>
            </Row>

            <Row className="mt-2">
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                >
                    <Row>
                        <Col
                            xs={{ span: 8 }}
                            className="pt-1 pb-1 text-color-label"
                        >
                            {t('orderDetail:label.creator')}:
                        </Col>
                        <Col
                            xs={{ span: 16 }}
                            className="d-flex align-items-center _order-detail-creator"
                        >
                            <strong>{creator ? creator.name || creator.username : t('noText')}</strong>
                        </Col>

                        <Col
                            xs={{ span: 8 }}
                            className="pt-1 pb-1 text-color-label"
                        >
                            {t('order:label.merchant_id')}:
                        </Col>
                        <Col
                            xs={{ span: 16 }}
                            className="d-flex align-items-center _order-detail-merchant_id"
                        >
                            <strong>
                                {!isEmpty(merchant)
                                    ? !setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.ORDER_VIEW_DETAIL_ORIGINAL))
                                        ? merchant?.code + ' - ' + merchant?.name
                                        : '***'
                                    : t('noText')}
                            </strong>
                        </Col>

                        <Col
                            xs={{ span: 8 }}
                            className="pt-1 pb-1 text-color-label"
                        >
                            {t('order:label.campaign')}:
                        </Col>
                        <Col
                            xs={{ span: 16 }}
                            className="d-flex align-items-center _order-detail-campaign"
                        >
                            <strong>{order.campaign ? order.campaign : t('noText')}</strong>
                        </Col>

                        <Col
                            xs={{ span: 8 }}
                            className="pt-1 text-color-label"
                        >
                            {t('order:label.created_at_origin')}:
                        </Col>
                        <Col
                            xs={{ span: 16 }}
                            className="d-flex align-items-center _order-detail-created_at_origin"
                        >
                            <strong>{order.created_at_origin ? dateFormatter.formatDate(order.created_at_origin, 'DD/MM/YYYY') : t('noText')}</strong>
                        </Col>
                    </Row>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                >
                    <Row>
                        <Col
                            xs={{ span: 8 }}
                            className="pt-1 pb-1 text-color-label"
                        >
                            {t('order:label.shipping_partner_id')}:
                        </Col>
                        <Col
                            xs={{ span: 16 }}
                            className="d-flex align-items-center _order-detail-shipping_partner_id"
                        >
                            <strong>
                                <EditShippingPartner
                                    shippingPartner={shippingPartner}
                                    order={order}
                                    orderDetail={orderDetail}
                                />
                            </strong>
                        </Col>

                        <Col
                            xs={{ span: 8 }}
                            className="pt-1 pb-1 text-color-label"
                        >
                            {t('order:label.freight_bill')}:
                        </Col>
                        <Col
                            xs={{ span: 16 }}
                            className="d-flex align-items-center _order-detail-freight_bill"
                        >
                            <strong>{renderFreightBillShippingPartner()}</strong>
                        </Col>

                        <Col
                            xs={{ span: 8 }}
                            className="pt-1 text-color-label"
                        >
                            {t('orderDetail:label.note')}:
                        </Col>
                        <Col
                            xs={{ span: 16 }}
                            className="d-flex align-items-center _order-detail-note"
                        >
                            <strong>{order.description ? order.description : t('noText')}</strong>
                        </Col>

                        <Col
                            xs={{ span: 8 }}
                            className="pt-1 text-color-label _order-detail-status-detail-label"
                        >
                            {t('orderDetail:label.status_detail')}:
                        </Col>
                        <Col
                            xs={{ span: 16 }}
                            className="d-flex align-items-center _order-detail-status-detail-value _order-detail-status_detail"
                        >
                            {order.status === ORDER_STATUS.CANCELED
                                ? order.cancel_reason
                                    ? order.cancel_reason === 'OTHER'
                                        ? `${t(`order:cancel_reason.OTHER`)} (${order.cancel_note})`
                                        : t(`order:cancel_reason.${order?.cancel_reason}`)
                                    : t('noText')
                                : order.status
                                ? t(`order:status.${order.status}`)
                                : t('noText')}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
