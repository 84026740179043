import React, {useEffect, useState} from "react";
import {Button, Col, Form, notification, Row, Input, Table, Checkbox} from "antd";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import {setDocumentTitle, setNavigator} from "../../../App/services";
import {t} from "../../../../system/i18n";
import _ from "lodash";
import SelectWarehouse from "../../../Warehouse/components/SelectWarehouse";
import SkuList from "./SkuList";
import apiProduct from "../../../Product/services/api";
import api from "../../services/api";
import {Pipe, checkFull, checkGte, checkUnique} from "./CheckBugs";
import {url} from "../../../../system/routing";

// Validated rules khi tạo mới đơn hàng
const VALIDATED_RULES = {
    warehouse_id: [{required: true, type: "number"}],
    note: [{type: "string"}],
    skus: [{required: true, type: "object"}]
};

// initial value of form
const INIT_BILL = {
    warehouse_id: undefined,
    note: "",
    skus: []
};

// initial value of new ExportSku in form
const NEW_SKU = {
    sku_id: undefined,
    warehouse_area_id: undefined,
    quantity: undefined,
    stock: 0
};

export default function NewExportedBill({}) {
    const [form] = Form.useForm();
    const validateMessages = useValidatedMessages();
    const [loading, setLoading] = useState(false);
    const [exportedSkus, setExportedSkus] = useState([]);
    const [stocks, setStocks] = useState([]);
    const [errors, setErrors] = useState(null);

    useEffect(() => {
        setNavigator(t("title.new_exported_bill"), [
            { name: t("breadcrumb.manage_exporting"), route: "list-exported-bills" },
            { name: t("breadcrumb.new_exported_bill") }
        ]);
        setDocumentTitle(t("title.new_exported_bill"));
        addSku();
    }, []);

    useEffect(() => {
        setErrors(null);
    }, [exportedSkus]);

    function addSku() {
        setExportedSkus([...exportedSkus, NEW_SKU]);
    }

    function resetSku() {
        setExportedSkus([NEW_SKU]);
    }

    function removeSku(id) {
        setExportedSkus(exportedSkus.filter((item, index) => index !== id));
    }

    function setSku(id, data) {
        setExportedSkus(exportedSkus.map((item, index) => (index === id ? {...item, ...data} : item)));
    }

    function fetchSkus(warehouse_id) {
        resetSku();
        apiProduct.getStocks({out_of_stock: 0, for_delivery_note: 1, per_page: 1000, warehouse_id})
            .then(res => {
                let tmpStocks = _.get(res, "data.stocks");
                setStocks(tmpStocks.filter(item => item.sku));
            })
            .catch(err => {
                const status = _.get(err, "response.status", null);
                if (status === 403)
                    notification.error({message: t('common:message.403')});
                else if (status === 404)
                    notification.error({message: t('common:message.404')});
                else
                    notification.error({message: t('common:message.fetch_fail')});
            });
    }

    function checkErrors() {
        let billStatus = new Pipe(exportedSkus, [checkFull, checkUnique, checkGte]);
        setErrors(billStatus.errors);

        return billStatus.valid;
    }

    function showErrors() {
        if (errors === null)
            return null;

        return Object.keys(errors).reverse().map(item => {
            return (
                <div className="d-flex text-red text-fz-12">
                    <strong style={{fontWeight: "500"}} className="mr-2">{t(`manageExporting:label.line`)} {item.replace("line_", "")}:</strong>
                    <div>
                    {
                        errors[item].map(error => <p>{t(`manageExporting:message.${error}`)}</p>)
                    }
                    </div>
                </div>
            );
        })
    }

    function _onResetForm() {
        form.resetFields();
        setExportedSkus([NEW_SKU]);
    }

    function _onSubmit() {
        form.validateFields()
            .then(values => {
                if (!checkErrors())
                    return;

                setLoading(true);
                api.createExportedBill({...values, skus: [...exportedSkus]})
                    .then(res => {
                        notification.success({message: t('common:message.create_success', {attribute: t("title.detailed_bill")})});
                        let deliveryNote = _.get(res, "data.deliveryNote", null);
                        if (deliveryNote)
                            url.redirectTo('detailed-bill', {id: deliveryNote.id});
                    })
                    .catch(err => {
                        let {response} = err;
                        if (response.status === 400) {
                            notification.error({message: t('common:message.create_failed', {attribute: t("title.detailed_bill")})});
                        } else if (response.status === 403)
                            notification.error({message: t('common:message.403')});
                        else if (response.status === 404)
                            notification.error({message: t('common:message.404')});
                        else
                            notification.error({message: t('common:message.create_failed', {attribute: t("title.detailed_bill")})});
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
    }

    return (
        <div className="site-content bg-white">
            <Form
                initialValues={INIT_BILL}
                className={"form"}
                validateMessages={validateMessages}
                size="default"
                form={form}
                labelCol={{span: 6}}
                wrapperCol={{span: 18}}
            >
                <div className="title-page pb-3 d-flex justify-content-between align-items-center">
                    <h2 className="text-uppercase">{t("common:heading.new_exported_bill")}</h2>
                </div>
                <div className="border p-4">
                    <Row gutter={20}>
                        <Col span={15} offset={3}>
                            <Form.Item
                                className="mb-0"
                                name="warehouse_id"
                                label={t("manageExporting:label.warehouse_id")}
                                rules={VALIDATED_RULES.warehouse_id}
                            >
                                <SelectWarehouse
                                    name="warehouse_id"
                                    noSuggest={false}
                                    placeholder={t("manageExporting:placeholder.warehouse_id")}
                                    onSelect={fetchSkus}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={15} offset={3}>
                            <Form.Item
                                className="mb-0 mt-3"
                                name="note"
                                label={t("manageExporting:label.note")}
                                rules={VALIDATED_RULES.note}
                            >
                                <Input.TextArea
                                    name="note"
                                    placeholder={t("manageExporting:placeholder.note")}
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={20} offset={2}>
                            <SkuList
                                exportedSkus={exportedSkus}
                                setExportedSkus={setExportedSkus}
                                addSku={addSku}
                                removeSku={removeSku}
                                setSku={setSku}
                                stocks={stocks}
                                checkErrors={checkErrors}
                            />
                            <Row>
                                <Col span={16}>
                                    {showErrors()}
                                </Col>
                                <Col span={8} className="text-right">
                                    <Button
                                        className="pl-4 pr-4"
                                        onClick={_onResetForm}
                                    >{t("btn.cancel")}</Button>
                                    <Button
                                        className="pl-4 pr-4 ml-2"
                                        type="primary"
                                        onClick={_onSubmit}
                                    >{t("btn.add")}</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    );
}
