import { UploadOutlined } from '@ant-design/icons'
import { Button, Col, Input, Modal, Row, Spin, Typography } from 'antd'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import UploadFile from '@Modules/App/Upload/UploadFileV2'
import SelectWarehouseItems from '@Modules/Finance/components/DebtsShipping/SelectShippingPartner'
import api from '@Modules/Finance/services/api'
import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { convertBlobDataToExcel } from '@System/support/helpers'

const { Paragraph } = Typography

const UploadEstimatedShippingFee = props => {
    const [isVisible, setIsVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [messages, setMessages] = useState([])
    const [shippingPartner, setShippingPartner] = useState()
    const [warehouseId, setWarehouseId] = useState()
    const [suffixes, setSuffixes] = useState('')
    const [file, setFile] = useState({})

    const showModal = () => {
        setIsVisible(true)
    }

    const handleOk = () => {
        setIsVisible(false)
    }

    const handleCancel = () => {
        setIsVisible(false)
        setFile({})
        setShippingPartner(undefined)
        setMessages([])
        setWarehouseId(undefined)
    }

    const handleUpload = fileUpload => {
        setMessages([])
        setFile(fileUpload)
    }

    const handleChangeShippingPartner = (value, options) => {
        setSuffixes(get(options, 'code', ''))
        setShippingPartner({ id: value, countryId: get(options, 'countryId') })
    }

    const handleChangeWarehouse = value => {
        setWarehouseId(value)
    }

    const handleDownloadSampleFile = () => {
        if (!isEmpty(shippingPartner) && warehouseId) {
            setLoading(true)
            api.getExpectedTransportingTemplate(get(shippingPartner, 'id'), { warehouse_id: warehouseId })
                .then(res => {
                    convertBlobDataToExcel(res.data, `bang_phi_van_chuyen_${suffixes}`)
                })
                .catch(error => {
                    processResponseError(error)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }

    const handleUploadExpectedTransportingFile = async () => {
        setLoading(true)

        try {
            const fomData = new FormData()
            fomData.append('file', file)
            fomData.append('warehouse_id', warehouseId)

            const res = await api.uploadExpectedTransportingPrice(get(shippingPartner, 'id'), fomData)

            const { data } = res

            if (!isEmpty(get(data, 'errors'))) {
                setMessages(get(data, 'errors'))
            } else {
                handleCancel()
                notification.success(t('finance:message:update_expected_fee_success'))
            }
        } catch (error) {
            processResponseError(error)
        } finally {
            setLoading(false)
        }
    }

    function renderError(error) {
        const ERRORS = ['not_exist', 'already_exist', 'required', 'invalid', 'numeric', 'empty', 'exists', 'invalid_country']

        let line = get(error, 'line', '')
        const content = get(error, 'errors', error)
        let ErrorMessages = []
        if (typeof content === 'object') {
            Object.entries(content).forEach(entry => {
                let [key, errors] = entry
                ERRORS.forEach(errorKey => {
                    if (key === 'file' && errors === errorKey) {
                        line = 1
                        ErrorMessages.push(t(`finance:message.create_finance_control.file_empty`))
                    } else {
                        if (errors.hasOwnProperty(errorKey)) {
                            ErrorMessages.push(
                                t(`finance:message.create_finance_control.${errorKey}`, {
                                    attribute: t(`finance:finance_control.label.${key}`),
                                })
                            )
                        }
                    }
                })
            })
        } else {
            if (content === 'INVALID') {
                return t('finance:message.create_finance_control.fail')
            }
        }
        if (ErrorMessages.length > 0) return t('finance:message.create_finance_control.line', { line: line, content: ErrorMessages.join(' ') })
        return t('finance:message.create_finance_control.unknown_error')
    }

    return (
        <>
            <Button
                type="primary"
                className="ml-2"
                onClick={showModal}
            >
                <UploadOutlined /> {t('finance:btn.upload_estimated_shipping_fee')}
            </Button>
            {isVisible && (
                <Modal
                    title={t('finance:btn.upload_estimated_shipping_fee').toUpperCase()}
                    visible={true}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={
                        <>
                            <Button
                                className="_cancel-btn"
                                onClick={handleCancel}
                            >
                                {t('btn.close')}
                            </Button>
                            <Button
                                className="_ok-btn"
                                type="primary"
                                loading={loading}
                                disabled={isEmpty(file)}
                                onClick={handleUploadExpectedTransportingFile}
                            >
                                {t('btn.ok')}
                            </Button>
                        </>
                    }
                    maskClosable={false}
                    width={600}
                >
                    <Row className="mb-4">
                        <Col span={8}>
                            <span className="mr-2">{t('placeholder.shipping_partner')}: </span>
                        </Col>
                        <Col span={9}>
                            <SelectShippingPartner
                                className="_prepare-order-search-shipping-partner-id w-100"
                                placeholder={t('order:placeholder.shipping_partner_id')}
                                allowClear={true}
                                value={shippingPartner ? parseInt(shippingPartner) : undefined}
                                onChange={handleChangeShippingPartner}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col span={8}>
                            <span className="mr-2">{t('label.select_warehouse_global')}: </span>
                        </Col>
                        <Col span={9}>
                            <SelectWarehouseItems
                                className="_orders-search-warehouse-id w-100"
                                allowClear={true}
                                placeholder={t('product:placeholder.warehouse_name')}
                                value={warehouseId ? parseInt(warehouseId) : undefined}
                                onChange={handleChangeWarehouse}
                                noSuggest={false}
                                shippingPartner={shippingPartner}
                            />
                        </Col>
                    </Row>

                    {warehouseId && suffixes && (
                        <>
                            <p className="inline-block mb-2">
                                {t('sample_file')}: <strong>{`bang_phi_van_chuyen_${suffixes}.xlsx`}</strong>
                            </p>

                            <p className="inline-block mb-1">
                                {t('label.latest_at_update_sample')}: <strong>04/01/2023</strong>
                            </p>
                            <div className="d-flex justify-content-between align-items-center mb-5">
                                <p>
                                    {t('label.maximum_file_size')}: <strong>5MB</strong>
                                </p>

                                <Button onClick={handleDownloadSampleFile}>{t('download_sample_file')}</Button>
                            </div>
                        </>
                    )}
                    {warehouseId && suffixes && (
                        <Spin spinning={loading}>
                            <UploadFile
                                className="_prepare-order-update-return-order-upload-file"
                                onChange={handleUpload}
                                accept=".xls,.xlsx"
                                fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                                name={t('label.file_upload')}
                                maxFileSize={5}
                                loading={loading}
                            />
                            {!isEmpty(messages) && (
                                <div
                                    className="mt-3 pt-3 overflow-auto"
                                    style={{ maxHeight: 300 }}
                                >
                                    <Paragraph
                                        className="justify-content-end d-flex mr-4"
                                        copyable={{
                                            text: messages
                                                .map(item => {
                                                    return `  - ${renderError(item)}\n`
                                                })
                                                .join('')
                                                .replace(',', ''),
                                        }}
                                    >
                                        {t('label.copy_all')}
                                    </Paragraph>
                                    {messages.map((item, index) => {
                                        return (
                                            <p
                                                key={index}
                                                className="text-danger"
                                            >
                                                - {renderError(item)}
                                            </p>
                                        )
                                    })}
                                </div>
                            )}
                        </Spin>
                    )}
                </Modal>
            )}
        </>
    )
}

export default UploadEstimatedShippingFee
