import React, {useCallback, useEffect, useState} from "react";
import {Form, Select} from "antd";
import {t} from "../../../../system/i18n";
import ServiceCountries from "../../../Location/services/ServiceCountries";
import ServiceLocation from "../../../Location/services/ServiceLocation";
import {VIETNAME_CODE} from "../../../Location/services/constants";

const {Option} = Select;
const ProvincesServ = new ServiceLocation();
const DistrictServ = new ServiceLocation();
const WardServ = new ServiceLocation();

export default function Location({validatedRules, form}) {
    let [country, setCountry] = useState(null);
    let [countries, setCountries] = useState([]);
    let [loadingCountry, setLoadingCountry] = useState(false);

    let [provinces, setProvinces] = useState([]);
    let [loadingProvince, setLoadingProvince] = useState(false);

    let [districts, setDistricts] = useState([]);
    let [loadingDistrict, setLoadingDistrict] = useState(false);

    let [wards, setWards] = useState([]);
    let [loadingWard, setLoadingWard] = useState(false);

    const [countryId, setCountryId] = useState(null);
    const [provinceId, setProvinceId] = useState(null);
    const [districtId, setDistrictId] = useState(null);

    const fetchProvince = useCallback(() => {
        setLoadingProvince(true);
        let country = countries.find(item => item.id === countryId);
        ProvincesServ.list("PROVINCE", country.code)
            .then(res => {
                setProvinces(res);
            })
            .finally(() => {
                setLoadingProvince(false);
            })
    }, [countries, countryId]);

    const fetchDistrict = useCallback(() => {
        setLoadingDistrict(true);
        let province = provinces.find(item => item.id === provinceId);
        DistrictServ.list("DISTRICT", province.code)
            .then(res => {
                setDistricts(res);
            })
            .finally(() => {
                setLoadingDistrict(false);
            })
    }, [provinceId, provinces]);

    const fetchWard = useCallback(() => {
        setLoadingWard(true);
        let district = districts.find(item => item.id === districtId);
        WardServ.list("WARD", district.code)
            .then(res => {
                setWards(res);
            })
            .finally(() => {
                setLoadingWard(false);
            })
    }, [districtId, districts]);

    useEffect(() => {
        fetchCountry();
    }, []);

    /**
     * Tải lại dữ liệu nếu đất nước thay đổi
     */
    useEffect(() => {
        if (countryId)
            fetchProvince();
        else
            setProvinces([]);
    }, [countryId, fetchProvince]);

    /**
     * Tải quận huyện nếu tỉnh thành thay đổi
     */
    useEffect(() => {
        if (provinceId)
            fetchDistrict();
        else
            setDistricts([]);
    }, [provinceId, fetchDistrict]);

    /**
     * Tải lại phường xã nếu quận huyện thay đổi
     */
    useEffect(() => {
        if (districtId)
            fetchWard();
        else
            setWards([]);
    }, [districtId, fetchWard]);

    function fetchCountry() {
        setLoadingCountry(true);
        ServiceCountries.list()
            .then(res => {
                setCountries(res);
            })
            .finally(() => {
                setLoadingCountry(false);
            })
    }

    function selectCountry(value) {
        setCountryId(value);
        setCountry(countries.find(item => item.id === value));
        setProvinceId(null);
        setDistrictId(null);
        form.setFields([
            {name: "province_id", value: null},
            {name: "district_id", value: null},
            {name: "ward_id", value: null}
            ]);
    }

    function selectProvince(value) {
        setProvinceId(value);
        setDistrictId(null);
        form.setFields([
            {name: "district_id", value: null},
            {name: "ward_id", value: null}
        ]);
    }

    function selectDistrict(value) {
        setDistrictId(value);
        form.setFields([{name: "ward_id", value: null}]);
    }

    return (
        <>
            <Form.Item
                className="mb-3"
                name="country_id"
                label={t(`common:label.country_id`)}
                rules={validatedRules["country_id"]}
            >
                <Select
                    loading={loadingCountry}
                    name="country_id"
                    placeholder={t(`common:placeholder.country_id`)}
                    onChange={selectCountry}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        countries.length > 0 &&
                        countries.map(opt => {
                            return <Option key={opt.id} value={opt.id}>{opt.label}</Option>;
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="province_id"
                label={t(`warehouse:label.${!country || country.code === VIETNAME_CODE ? "province_id" : "other_province_id"}`)}
                rules={validatedRules["province_id"]}
            >
                <Select
                    loading={loadingProvince}
                    name="province_id"
                    placeholder={t(`warehouse:placeholder.${!country || country.code === VIETNAME_CODE ? "province_id" : "other_province_id"}`)}
                    onChange={selectProvince}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        provinces.length > 0 &&
                        provinces.map(opt => {
                            return <Option key={opt.id} value={opt.id}>{opt.label}</Option>;
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="district_id"
                label={t(`warehouse:label.${!country || country.code === VIETNAME_CODE ? "district_id" : "other_district_id"}`)}
                rules={validatedRules["district_id"]}
            >
                <Select
                    loading={loadingDistrict}
                    name="district_id"
                    placeholder={t(`warehouse:placeholder.${!country || country.code === VIETNAME_CODE ? "district_id" : "other_district_id"}`)}
                    onChange={selectDistrict}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        districts.length > 0 &&
                        districts.map(opt => {
                            return <Option key={opt.id} value={opt.id}>{opt.label}</Option>;
                        })
                    }
                </Select>
            </Form.Item>

            <Form.Item
                className="mb-3"
                name="ward_id"
                label={t(`warehouse:label.${!country || country.code === VIETNAME_CODE ? "ward_id" : "other_ward_id"}`)}
                rules={validatedRules["ward_id"]}
            >
                <Select
                    loading={loadingWard}
                    name="ward_id"
                    placeholder={t(`warehouse:placeholder.${!country || country.code === VIETNAME_CODE ? "ward_id" : "other_ward_id"}`)}
                    showSearch
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        wards.length > 0 &&
                        wards.map(opt => {
                            return <Option key={opt.id} value={opt.id}>{opt.label}</Option>;
                        })
                    }
                </Select>
            </Form.Item>
        </>
    );
}
