import React, {useState, useEffect} from "react";
import useFilter from "../../../App/Hooks/useFilter";
import { t } from "../../../../system/i18n";
import {setDocumentTitle, setNavigator} from "../../../App/services";
import MerchantList from "./MerchantList";
import FormSearch from "./FormSearch";
import CreateMerchant from "../../components/CreateMerchant";
import PERMISSIONS from "../../../Auth/services/permissions";
import {auth} from "../../../Auth/services";
import {events} from "../../../../system/events";
import {EVENTS} from "../../services/constants";
import useMerchantItemsQuery from '@Modules/Merchant/Hooks/useMerchantItemsQuery';

const initFilters = {
    code: "",
    name: "",
    page: "",
    location_id: "",
    per_page: "",
    status: 1,
    ref: ""
};

export default function ListMerchants({history, location}) {
    const {filters, addFilter} = useFilter(history, location, initFilters);
    const [merchants, setMerchants] = useState([]);
    const { isLoading: loading, data, refetch } = useMerchantItemsQuery(filters);

    useEffect(() => {
        setNavigator(t("common:title.list-merchants"), [
            {
                name: t("common:breadcrumb.merchants"),
            },
        ]);
        setDocumentTitle(t("title.list-merchants"));

        return events.listen(EVENTS.FETCH_LIST_MERCHANT_AGAIN, () => refetch());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setMerchants((data?.data?.merchants ?? []).map(item => {
            const merchant = item?.merchant ?? {};
            const location = item?.location ?? {};
            const user = item?.user ?? {};
            const currency = item?.currency ?? {};
            const accounts = data?.data?.accounts ?? []
            const balance = accounts.filter(val=>val.account === merchant.code)
            return {
                ...merchant,
                user,
                location,
                currency,
                balance:balance[0]
            };
        }));
    }, [data]);

    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{t("common:heading.list-merchants")}</h2>
                    <div className="pr-3">
                        {
                            auth.can(PERMISSIONS.MERCHANT_CREATE) &&
                            <CreateMerchant/>
                        }
                    </div>
                </div>
                <div>
                    <FormSearch
                        addFilter={addFilter}
                        filters={filters}
                        initFilters={initFilters}
                        loading={loading}
                    />
                    <MerchantList
                        merchants={merchants}
                        setMerchants={setMerchants}
                        pagination={data?.data?.pagination}
                        addFilter={addFilter}
                        filters={filters}
                        loading={loading}
                        fetchMerchants={refetch}
                    />
                </div>
            </div>
        </div>
    );
}
