import { Modal, Form, notification } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';

import { events } from '../../../system/events';
import { t } from '../../../system/i18n';
import { updateCollectionItem } from '../../App/services/helpers';
import api from '../services/api';
import { EVENTS } from '../services/constants';
import MerchantForm from './MerchantForm';

export default function UpdateMerchant({ children, merchant, merchants, setMerchants }) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setVisible(false);
    }

    function handleCancel() {
        form.resetFields();
        setVisible(false);
    }

    function handleSubmit(e) {
        form.validateFields().then(values => {
            setLoading(true);
            let data = {};
            for (const prop in values) {
                if (values[prop] !== undefined && values[prop] !== null) data[prop] = values[prop];
            }

            api.updateMerchant(merchant.id, data)
                .then(res => {
                    let newMerchant = _.get(res, 'data.merchant');
                    setMerchants(updateCollectionItem(merchants, merchant.id, newMerchant));
                    notification.success({
                        message: t('message.update_success_by_attribute', { attribute: t('label.merchant') }),
                    });
                    setVisible(false);
                    events.dispatch(EVENTS.FETCH_MERCHANT_AGAIN, {});
                    events.dispatch(EVENTS.FETCH_LIST_MERCHANT_AGAIN, {});
                })
                .catch(err => {
                    let { response } = err;
                    if (response.status === 400) {
                        let error_messages = [];
                        let errors = _.get(response, 'data.data', {});
                        Object.keys(errors).forEach(item => {
                            let key_errors = [];
                            Object.keys(errors[item]).forEach(error =>
                                key_errors.push(
                                    t(`merchant:message.${error}`, { attribute: t(`merchant:label.${item}`) })
                                )
                            );
                            error_messages.push({ name: item, errors: key_errors });
                        });
                        form.setFields(error_messages);
                    }
                    notification.error({
                        message: t('message.update_failed_by_attribute', { attribute: t('label.merchant') }),
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }

    function onReset() {
        form.resetFields();
    }

    return (
        <>
            <a href="#" onClick={showModal}>
                {children}
            </a>
            {visible && (
                <Modal
                    title={t('merchant:title.update_merchant').toUpperCase()}
                    visible={true}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={720}
                    footer={null}
                    maskClosable={false}
                >
                    <MerchantForm
                        loading={loading}
                        onReset={onReset}
                        handleSubmit={handleSubmit}
                        form={form}
                        merchant={merchant}
                    />
                </Modal>
            )}
        </>
    );
}
