import { Col, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';

import { t } from '../../../../system/i18n';
import ServiceCountries from '../../../Location/services/ServiceCountries';

const { Option } = Select;

export default function Location({ setInput, input }) {
    let [countries, setCountries] = useState([]);
    let [loadingCountry, setLoadingCountry] = useState(false);

    const { country_id } = input || {};

    useEffect(() => {
        fetchCountry();
    }, []);

    function fetchCountry() {
        setLoadingCountry(true);
        ServiceCountries.list()
            .then(res => {
                setCountries(res);
            })
            .finally(() => setLoadingCountry(false));
    }

    function selectCountry(value) {
        setInput({ ...input, country_id: value, province_id: '' });
    }

    return (
        <>
            <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                <Form.Item className="mb-3" label={t('warehouse:label.country_id')}>
                    <Select
                        loading={loadingCountry}
                        name="country_id"
                        value={country_id && !loadingCountry ? parseInt(country_id) : undefined}
                        placeholder={t('warehouse:placeholder.country_id')}
                        onChange={selectCountry}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {countries.length > 0 &&
                            countries.map(opt => {
                                return (
                                    <Option key={opt.id} value={opt.id}>
                                        {opt.label}
                                    </Option>
                                );
                            })}
                    </Select>
                </Form.Item>
            </Col>
        </>
    );
}
