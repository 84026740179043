import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import api from '../services/api';

const useListPurchasingPackageQuery = params => {
    return useQuery(['ListPurchasingPackageQuery', params], () => api.getPurchasingPackages(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useListPurchasingPackageQuery;
