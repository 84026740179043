import { Input } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';

import { events } from '@System/events';
import { t } from '@System/i18n';

import { EVENTS } from '../../../../services/constants';
import ValueItem from './ValueItem';

export default function OptionValues({ values, updateOption }) {
    let [newValue, setNewValue] = useState('');

    useEffect(() => {
        return events.listen(EVENTS.RESET_PRODUCT_FORM, () => setNewValue(''));
    }, []);

    function _onChange(e) {
        setNewValue(e.target.value);
    }

    function addValue() {
        if (newValue.trim() !== '') {
            updateOption({ values: [...values, { id: null, label: newValue }] });
            setNewValue('');
        }
    }

    function removeValue(index) {
        const newValues = values.filter((_, nth) => nth !== index);

        updateOption({ values: newValues });
    }

    return (
        <div className="option-values">
            {!_.isEmpty(values) &&
                values.map((value, index) => <ValueItem key={index} value={value} removeValue={() => removeValue(index)} />)}
            <Input
                className="_product-option-value mt-1 mb-1 mr-1"
                value={newValue}
                placeholder={t('product:placeholder.option_value')}
                style={{ width: '220px' }}
                onChange={_onChange}
                onPressEnter={addValue}
            />
        </div>
    );
}
