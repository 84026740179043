import React from "react";
import {Table, Checkbox, notification, Tooltip, Badge} from "antd";
import CustomizedPagination from "../../../App/CustomizedPagination";
import {t} from "../../../../system/i18n";
import {Link} from "react-router-dom";
import TableEmpty from "../../../App/Utilities/TableEmpty";
import {url} from "../../../../system/routing";
import api from "../../services/api";
import _ from "lodash";
import {CheckCircleTwoTone} from '@ant-design/icons'

export default function WarehouseList({warehouses, setWarehouses, pagination, addFilter, filters, loading}) {
    let getColumns = [
        {
            title: t("warehouse:label.code"),
            dataIndex: 'warehouse.code',
            key: 'warehouse.code',
            render: (text, record) => {
                let {warehouse} = record;
                return <Link to={url.to("warehouses.detail", {id: warehouse.id})}><strong
                    style={{fontWeight: "500"}}>{warehouse.code}</strong></Link>;
            },
        },
        {
            title: t("warehouse:label.name"),
            dataIndex: "warehouse.name",
            key: 'warehouse.name',
            render: (text, record) => {
                let {warehouse} = record;
                return warehouse.is_main ? <Badge
                    count={<Tooltip title={t("warehouse:label.is_main_warehouse")}>
                        <CheckCircleTwoTone twoToneColor="#52c41a" style={{color: '#52c41a', paddingLeft: "15px"}}/>
                    </Tooltip>}>
                    <p>{warehouse.name}</p>
                </Badge> : <p>{warehouse.name}</p>;
            },
        },
        {
            title: t("warehouse:label.country_id"),
            dataIndex: "country.label",
            key: 'country.label',
            render: (text, record) => {
                let {country} = record;
                return <p>{country ? country.label : ""}</p>;
            },
        },
        // {
        //     title: t("warehouse:label.province_id"),
        //     dataIndex: "province.label",
        //     key: 'province.label',
        //     render: (text, record) => {
        //         let {province} = record;
        //         return <p>{province ? province.label : ""}</p>;
        //     },
        // },
        {
            title: t("warehouse:label.address"),
            dataIndex: "warehouse.address",
            key: 'warehouse.address',
            render: (text, record) => {
                let {warehouse, district, ward, province} = record;
                let address = warehouse.address;

                if (address && district)
                    address = address + ', ' + district.label;
                else
                    address = district ? district.label : address;

                if (address && ward)
                    address = address + ', ' + ward.label;
                else
                    address = ward ? ward.label : address;

                if (address && province)
                    address = address + ', ' + province.label;
                else
                    address = province ? province.label : address;

                return <p>{address}</p>;
            },
        },
        {
            title: t("warehouse:label.status"),
            dataIndex: "country.label",
            key: 'country.label',
            className: 'text-center',
            render: (text, record) => {
                let {warehouse} = record;
                return <Checkbox checked={warehouse.status} onClick={() => toggleStatus(warehouse)}/>;
            },
        }
    ];

    function toggleStatus(warehouse) {
        api.toggleStatus(warehouse.id, {status: warehouse.status ? 0 : 1})
            .then((res) => {
                let newWarehouse = _.get(res, "data.warehouse", {});
                setWarehouses(warehouses.map(item => {
                    let oldWarehouse = _.get(item, "warehouse", {});
                    if (oldWarehouse.id === newWarehouse.id)
                        return {...item, warehouse: newWarehouse};
                    else
                        return item
                }));
                notification.success({message: t('warehouse:message.update_status_success', {attribute: t('label.warehouses')})});
            })
            .catch((err) => {
                const status = _.get(err, "response.status", null);
                if (status === 400) {
                    let errors = _.get(err, "response.data.data", {});
                    if (errors && errors.hasOwnProperty("stock"))
                        notification.error({message: t('warehouse:message.stock_invalid')});
                    else
                        notification.error({message: t('warehouse:message.update_status_failed', {attribute: t('label.warehouses')})});
                } else if (status === 403)
                    notification.error({message: t('common:message.403')});
                else if (status === 404)
                    notification.error({message: t('common:message.404')});
                else
                    notification.error({message: t('warehouse:message.update_status_failed', {attribute: t('label.warehouses')})});
            });
    }

    return (
        <div className="m-3">
            <Table
                className="list-products-table pb-3"
                dataSource={warehouses}
                rowKey={record => record.warehouse.id}
                columns={getColumns}
                pagination={false}
                loading={loading}
                locale={{emptyText: <TableEmpty loading={loading}/>}}
            />
            <CustomizedPagination
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
            />
        </div>
    );
}
