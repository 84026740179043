import { Col, Form, Row, Select, Typography } from 'antd';
import clsx from 'clsx';
import React, { useRef } from 'react';

import Box from '@Modules/App/Box';
import ScannableInput from '@Modules/App/Input/ScannableInput';
import StyledTag from '@Modules/App/StyledTag';
import { STATUSES } from '@Modules/Document/constants';
import { STATUS_COLORS } from '@Modules/Document/services/constants';
import SelectMerchants from '@Modules/Merchant/components/SelectMerchants';
import SelectWarehouseArea from '@Modules/WarehouseArea/components/SelectWarehouseArea';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

import styles from './detail.module.scss';

const { Option } = Select;
const { Title } = Typography;

const Header = ({ form, inventoryDocument, scanning, onScan }) => {
    const formRef = useRef();
    const warehouse_id = getVar(inventoryDocument, 'document_sku_inventory.warehouse_id', undefined);

    const handlePressEnter = () => {
        formRef.current.submit();
    };

    const _onChange = (name, value) => {
        form.setFields([{ name, value, error: '' }]);
    };

    return (
        <Box className={clsx(styles['header'])}>
            <Title level={3}>
                {t('document:types.inventory')}{' '}
                {inventoryDocument?.document_sku_inventory?.code ? `#${inventoryDocument?.document_sku_inventory?.code}` : ''}
                <StyledTag
                    className="ml-2"
                    type={inventoryDocument?.document_sku_inventory?.status === STATUSES.COMPLETED && 'success'}
                    color={
                        inventoryDocument?.document_sku_inventory?.status
                            ? STATUS_COLORS[inventoryDocument?.document_sku_inventory?.status]
                            : undefined
                    }
                >
                    {t(`document:inventory_statuses.${inventoryDocument?.document_sku_inventory?.status}`)}
                </StyledTag>
            </Title>
            <Form className="_scan-form" form={form} layout="vertical" ref={formRef} onFinish={onScan}>
                <Row gutter={16}>
                    <Col lg={{ span: 6 }} span={24}>
                        <Form.Item className="mb-0" initialValue="SKU_CODE" label={t('choose_how_to_scan')} name="barcode_type">
                            <Select className="_scan-type">
                                <Option value="SKU_CODE">SKU CODE</Option>
                                <Option value="SKU_REF">REF</Option>
                                <Option value="SKU_ID">ID</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item
                            className="mb-0"
                            name="merchant_id"
                            label={t('label.select_seller')}
                            initialValue={0}
                            rules={[
                                {
                                    required: true,
                                    type: 'integer',
                                    message: t('order:message.required', { attribute: t('label.select_seller') }),
                                },
                            ]}
                        >
                            <SelectMerchants
                                alloption={1}
                                placeholder={t('placeholder.select_seller')}
                                className="_import-by-purchase-package-scan-seller-id"
                                allowClear={true}
                                onChange={value => _onChange('merchant_id', value)}
                                disabled={scanning}
                            />
                        </Form.Item>
                    </Col>
                    {warehouse_id && (
                        <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                            <Form.Item
                                className="mb-0"
                                name="warehouse_area_id"
                                label={t('label.warehouse_area')}
                                rules={[
                                    {
                                        required: true,
                                        type: 'integer',
                                        message: t('order:message.required', { attribute: t('label.warehouse_area') }),
                                    },
                                ]}
                            >
                                <SelectWarehouseArea
                                    className="_orders-search-warehouse-area-id"
                                    allowClear={true}
                                    placeholder={t('product:placeholder.warehouse_area_name')}
                                    warehouseid={warehouse_id ? parseInt(warehouse_id) : undefined}
                                    onChange={value => _onChange('warehouse_area_id', value)}
                                    noSuggest={false}
                                    initDefault={true}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    <Col lg={{ span: 6 }} span={24}>
                        <Form.Item className="mb-0" label={t('input_or_scan_barcode')} name="barcode">
                            <ScannableInput
                                className="_barcode"
                                disabled={scanning}
                                onPressEnter={handlePressEnter}
                                placeholder={t('order:placeholder.barcode')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Box>
    );
};

export default Header;
