import InputNumber from '@Modules/App/Utilities/InputNumber';
import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

import { Form, Input } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isEmpty } from 'lodash';
import React from 'react'

const TableAdditionalProduct = ({ skusLackInfo, form }) => {
    const { xs } = useBreakpoint();

    return (
        <Form form={form}>
            <div
                className={`ant-table-wrapper list-products-table ant-table-scroll-horizontal 
                ${xs && 'ant-table-ping-right'}`}
            >
                <div className="ant-table-container">
                    <div className="ant-table-content" style={{ overflow: 'auto hidden' }}>
                        <table
                            style={{ width: '576px', minWidth: '100%', tableLayout: 'auto' }}
                            className="_list-products-table mt-3"
                        >
                            <thead className="ant-table-thead">
                                <tr>
                                    <th className="ant-table-cell _price_level-column">{t('product:label.sku_code')}</th>
                                    <th className="ant-table-cell _created_at-column">{t('product:label.product_name')}</th>
                                    <th className="ant-table-cell _created_at-column">{t('sku:label.weight')}</th>
                                    <th className="ant-table-cell _length-column">{t('label.length')}</th>
                                    <th className="ant-table-cell _width-column">{t('label.width')}</th>
                                    <th className="ant-table-cell _height-column">{t('label.height')}</th>
                                </tr>
                            </thead>
                            {skusLackInfo?.length > 0 && (
                                <tbody className="ant-table-tbody">
                                    {skusLackInfo?.map((item, index) => {
                                        const { sku_code, sku_name, sku_id } = item;
                                        return (
                                            <tr key={index} className="ant-table-row ant-table-row ant-table-row-level-0">
                                                <td className="ant-table-cell ant-table-sku_code-column text-left">{sku_code}</td>
                                                <td className="ant-table-cell sku_name-column">{sku_name}</td>
                                                {
                                                    ['weight', 'length', 'width', 'height'].map((val, ind) => {
                                                        let value = getVar(item, val, undefined);
                                                        return (
                                                            <td className={`ant-table-cell _${val}-column`} key={ind}>
                                                                <Form.Item
                                                                    name={['skus', index, val]}
                                                                    style={{ margin: '0' }}
                                                                    initialValue={value}
                                                                    rules={[
                                                                        { required: true, message: t('message.required') },
                                                                        { pattern: /^\+?([1-9]\d*)$/, message: t('validation:min.numeric', { min: 1 }) },
                                                                    ]}
                                                                >
                                                                    <InputNumber
                                                                        className={`text-right _product-variants-${val}-input`}
                                                                        style={{ width: '130px' }}
                                                                    />
                                                                </Form.Item>

                                                            </td>
                                                        );
                                                    })
                                                }
                                                <td style={{ display: 'none' }}>
                                                    <Form.Item
                                                        name={['skus', index, 'sku_id']}
                                                        initialValue={sku_id}
                                                        hidden
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            )}
                        </table>
                        {isEmpty(skusLackInfo) && (
                            <p className="text-center mt-4 mb-5">{t('common:label.no_data')}</p>
                        )}
                    </div>
                </div>
            </div>
        </Form>
    )
}

export default TableAdditionalProduct