import isFunction from 'lodash/isFunction';
import React, { useCallback, useState } from 'react';

import Modal from './index';

const ConfirmDialog = ({ close, onCancel, onOk, ...other }) => {
    const [confirmLoading, setConfirmLoading] = useState(false);
    const handleCancel = useCallback(() => {
        if (isFunction(onCancel)) {
            onCancel();
        }

        close({ triggerCancel: true });
    }, [close, onCancel]);

    const handleOk = useCallback(async () => {
        setConfirmLoading(true);

        if (!isFunction(onOk)) {
            close();
        };

        if (isFunction(onOk)) {
            try {
                await onOk();
                close();
            } catch (error) {
            }
        }

        setConfirmLoading(false);
    }, [close, onOk]);

    return (
        <Modal
            {...other}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            onOk={handleOk}
        />
    )
}

export default ConfirmDialog;
