import { CloudUploadOutlined } from '@ant-design/icons';
import { Button, Input, Typography } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import React, { useEffect, useMemo, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import useToggle from '@Modules/App/Hooks/useToggle';
import StyledTable from '@Modules/App/Table/StyledTable';
import TableCount from '@Modules/App/Table/TableCount';
import InputNumber from '@Modules/App/Utilities/InputNumber';
import { STATUSES } from '@Modules/Document/constants';
import ImportSkusModal from '@Modules/Document/screens/Inventory/components/ImportSkusModal';

import { t } from '@System/i18n';

import styles from './barcodes-table.module.scss';

const { Text, Title } = Typography;

const BarcodesTable = ({
    barcodes,
    canUpdate,
    inventoryDocument,
    pagination,
    onSuccessImportSkus,
    onUpdateSkuInventoryDocument,
    setPagination,
}) => {
    const [visible, { setLeft: closeModal, setRight: openModal }] = useToggle();
    const [internalBarcodes, setInternalBarcodes] = useState();

    const emitOnChange = usePersistFn((index, key, event) => {
        const value = event.target.value;

        onUpdateSkuInventoryDocument({
            sku_id: barcodes[index]?.sku_id,
            warehouse_area_id: barcodes[index]?.warehouse_area_id,
            quantity: key === 'quantity_checked' ? (isNil(value) ? '' : value) : barcodes[index]?.quantity_checked,
            explain: (key === 'explain' ? value : barcodes[index]?.explain) || '',
        });
    });

    const handleChange = usePersistFn((index, key, event) => {
        let value = key === 'quantity_checked' ? event : event.target.value;

        const newBarcodes = [
            ...internalBarcodes.slice(0, index),
            {
                ...internalBarcodes[index],
                [key]: value,
            },
            ...internalBarcodes.slice(index + 1),
        ];

        setInternalBarcodes(newBarcodes);
    });

    const handleChangePage = usePersistFn(
        params => {
            setPagination({
                ...pagination,
                ...params,
            });
        },
        [pagination]
    );

    const handleSuccessImport = usePersistFn(data => {
        onSuccessImportSkus(data);

        if (!data?.errors?.length) {
            closeModal();
        }
    });

    const inventoryDocumentStatus = get(inventoryDocument, 'document_sku_inventory.status', '');

    const columns = useMemo(
        () => [
            {
                className: '_warehouse-area-column',
                key: 'warehouse_area',
                title: t('product:label.warehouse_area_name'),
                dataIndex: ['warehouse_area', 'name'],
                render: (text, record) => {
                    const warehouse_area = get(record, 'warehouse_area', {});
                    if (isEmpty(warehouse_area)) {
                        return '';
                    } else {
                        const { name, code } = warehouse_area;
                        return (
                            (name === 'default' ? t('order:label.default') : name) +
                            (code ? ' (' + (code === 'default' ? t('order:label.default') : code) + ')' : '')
                        );
                    }
                },
            },
            {
                className: '_barcode-column',
                dataIndex: ['sku', 'code'],
                key: 'barcode',
                title: 'SKU',
            },
            {
                className: '_quantity-in-stock-column text-right',
                dataIndex: 'quantity_in_stock_before_balanced',
                key: 'quantity_in_stock_before_balanced',
                title: t('document:current_inventory'),
            },
            {
                className: '_quantity-checked-column text-right',
                dataIndex: 'quantity_checked',
                key: 'quantity-checked',
                title: t('document:inventory_after_checking'),
                render: (text, record, index) => {
                    return inventoryDocumentStatus === STATUSES.DRAFT && canUpdate ? (
                        <InputNumber
                            className="text-right _quantity-checked"
                            value={text}
                            onBlur={emitOnChange.bind(this, index, 'quantity_checked')}
                            onChange={handleChange.bind(this, index, 'quantity_checked')}
                        />
                    ) : isNil(text) || text === '' ? (
                        <Text className="text-grey">{t('document:unchecked')}</Text>
                    ) : (
                        text
                    );
                },
            },
            {
                className: '_deviated-column text-right',
                dataIndex: 'deviated',
                key: 'deviated',
                title: t('document:deviated'),
                render: (text, record) => {
                    let quantity_checked = get(record, 'quantity_checked', 0),
                        quantity_in_stock = get(record, 'quantity_in_stock', 0),
                        quantity_in_stock_before_balanced = get(record, 'quantity_in_stock_before_balanced', 0);
                    const deviated =
                        inventoryDocumentStatus === STATUSES.DRAFT
                            ? quantity_checked - quantity_in_stock
                            : quantity_checked - quantity_in_stock_before_balanced;

                    return isNil(quantity_checked) || quantity_checked === '' ? (
                        <Text className="text-grey">{t('document:unchecked')}</Text>
                    ) : (
                        <Text
                            className={clsx({
                                'text-red': deviated < 0,
                                'text-green': deviated > 0,
                            })}
                        >
                            {deviated > 0 && '+'}
                            {deviated}
                        </Text>
                    );
                },
            },
            {
                className: '_explain-column text-right',
                dataIndex: 'explain',
                key: 'reason',
                title: t('reason'),
                render: (text, record, index) => {
                    return inventoryDocumentStatus === STATUSES.DRAFT && canUpdate ? (
                        <Input
                            className="text-right _explain"
                            value={text}
                            onBlur={emitOnChange.bind(this, index, 'explain')}
                            onChange={handleChange.bind(this, index, 'explain')}
                        />
                    ) : (
                        text || t('not_have')
                    );
                },
            },
        ],
        [canUpdate, inventoryDocument, emitOnChange, handleChange]
    );

    useEffect(() => {
        setInternalBarcodes(barcodes);
    }, [barcodes]);

    return (
        <div className={styles['barcodes-table']}>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <Title level={3}>
                    {t('list_with_type', {
                        type: 'SKU',
                    })}
                    <TableCount className="ml-2" text={barcodes?.length ?? 0} />
                </Title>

                {canUpdate && (
                    <Button className="_import-skus-btn" type="primary" onClick={openModal}>
                        <CloudUploadOutlined />
                        {t('input_list_with_type', {
                            type: 'SKU',
                        })}
                    </Button>
                )}
            </div>

            <StyledTable
                columns={columns}
                dataSource={internalBarcodes}
                pagination={pagination}
                rowKey="id"
                onChange={handleChangePage}
            />

            <ImportSkusModal
                inventoryDocumentId={inventoryDocument?.document_sku_inventory?.id}
                visible={visible}
                onCancel={closeModal}
                onOk={handleSuccessImport}
            />
        </div>
    );
};

export default BarcodesTable;
