import { Button, Modal, Typography } from 'antd';
import identity from 'lodash/identity';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useEffect, useState } from 'react';
import xlsx from 'xlsx';
import i18next from "i18next";

import UploadFile from '@Modules/App/Upload/UploadFileV2';
import { dateFormatter } from '@Modules/App/services';

import { t } from '@System/i18n';

const { Paragraph } = Typography;

const ImportBarcodesFileModal = ({ visible, onCancel, onOk }) => {
    const [value, setValue] = useState();
    const [lastModified, setLastModified] = useState();

    const handleCancel = useCallback(() => {
        if (isFunction(onCancel)) {
            onCancel();
        }

        setValue([]);
    }, [onCancel]);

    const handleOk = useCallback(() => {
        if (isFunction(onOk)) {
            onOk(value);
        }

        setValue([]);
    }, [value, onOk]);

    const handleUpload = useCallback(file => {
        const reader = new FileReader();
        reader.onload = evt => {
            // evt = on_file_select event
            /* Parse data */
            const bstr = evt.target.result;
            const wb = xlsx.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = xlsx.utils.sheet_to_csv(ws, { header: 1 });
            /* Update state */
            const value = (data ? data.split('\n') : []).filter(identity);

            setValue(value);
        };

        reader.readAsBinaryString(file);
    }, []);

    const getLastModified = async () => {
        const data = await fetch(`${process.env.PUBLIC_URL}/template/${i18next.language}/file_mau_danh_sach_ma.xlsx`);
        const lastModified = data.headers.get('Last-Modified');

        setLastModified(lastModified);
    };

    useEffect(() => {
        getLastModified();
    }, []);

    return (
        visible && (
            <Modal
                cancelText={t('btn.close')}
                className="_import-barcodes-file-modal"
                maskClosable
                okText={t('import_list')}
                title={t('input_code_from_text')}
                visible={visible}
                onCancel={handleCancel}
                onOk={handleOk}
            >
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <div>
                        <Paragraph className="mb-0">
                            {t('sample_file')}: <strong>file_mau_danh_sach_ma.xlsx</strong>
                        </Paragraph>
                        {lastModified && (
                            <time className="text-fz-12 text-grey">
                                {t('update')} {dateFormatter.date(lastModified)}
                            </time>
                        )}
                    </div>

                    <Button>
                        <a href={`${process.env.PUBLIC_URL}/template/${i18next.language}/file_mau_danh_sach_ma.xlsx`}>{t('download_sample_file')}</a>
                    </Button>
                </div>

                <UploadFile
                    accept=".xls,.xlsx"
                    fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                    onChange={handleUpload}
                />
            </Modal>
        )
    );
};

export default ImportBarcodesFileModal;
