import { CheckOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React from 'react';

import StyledTag from '@Modules/App/StyledTag';
import { STATUSES } from '@Modules/Document/constants';

import { trans } from '@System/i18n';

const { Paragraph } = Typography;

const Header = ({ exportingDocument, onConfirm, onRedo, isConfirming }) => {
    return (
        <div className="bg-white mb-3 p-3">
            <div className="w-100">
                <div className="mb-3 d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                    <div className="d-flex align-items-center mb-lg-0">
                        <div className="d-flex-inline flex-column">
                            <StyledTag
                                className="mb-1"
                                type={exportingDocument?.document_exporting?.status === STATUSES.COMPLETED && 'success'}
                            >
                                {trans(`document:statuses.${exportingDocument?.document_exporting?.status}`)}
                            </StyledTag>
                            <Paragraph className="font-weight-bold text-fz-16 mb-0 mr-2">
                                {trans('document:types.exporting')}: #{exportingDocument?.document_exporting?.code}
                            </Paragraph>
                        </div>
                    </div>

                    <div>
                        <Button className="mr-2 mt-3 _redo-btn" onClick={onRedo}>
                            <RedoOutlined />
                            {trans('btn.redo')}
                        </Button>

                        <Button
                            className="mt-3 _confirm-btn"
                            type="primary"
                            onClick={onConfirm}
                            loading={isConfirming}
                        >
                            <CheckOutlined />
                            {trans('btn.confirm')}
                        </Button>
                    </div>
                </div>

                <Row>
                    <Col lg={{ span: 18 }} span={24}>
                        <Row gutter={16}>
                            <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                <Paragraph className="mb-0 text-grey">{trans('full_name')}</Paragraph>
                                <Paragraph className="mb-0">
                                    {exportingDocument?.document_exporting?.info?.receiver_name}
                                </Paragraph>
                            </Col>

                            <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                <Paragraph className="mb-0 text-grey">{trans('phone')}</Paragraph>
                                <Paragraph className="mb-0">
                                    {exportingDocument?.document_exporting?.info?.receiver_phone}
                                </Paragraph>
                            </Col>

                            <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                <Paragraph className="mb-0 text-grey">{trans('document:placeholder.license')}</Paragraph>
                                <Paragraph className="mb-0">
                                    {exportingDocument?.document_exporting?.info?.receiver_license}
                                </Paragraph>
                            </Col>

                            <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                <Paragraph className="mb-0 text-grey">{trans('receive_company')}</Paragraph>
                                <Paragraph className="mb-0">{exportingDocument?.document_exporting?.info?.partner}</Paragraph>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Header;
