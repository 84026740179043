import { api } from '../../../system/api'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getPurchasingPackages: params => api.get('/purchasing-packages', { params, singleRequest: true, loading: false }),
    exportPurchasingPackages: params => api.get('/purchasing-packages/export', { params, singleRequest: true, loading: false, responseType: 'blob' }),
    importConfirmFinanceStatus: data => api.post('/purchasing-packages/import-finance-status', data),
    createBatchOfGoods: (idSku, data) => api.post(`/skus/${idSku}/batch-of-goods`, data),
    getBatchOfGoods: (id) => api.get(`/skus/${id}/batch-of-goods`, { }),
    createPackage: ( data) => api.post(`purchasing-packages`, data),
}
