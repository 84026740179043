import React, { useEffect, useState } from 'react';
import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import {setDocumentTitle, setNavigator} from '@Modules/App/services';

import { t } from '@System/i18n';

import BarcodeList from '../components/BarcodeList';
import Summary from '../components/Summary';
import useSkuInventoryDocumentQuery from './../Hooks/useSkuInventoryDocumentQuery';

import styles from './preview.module.scss';
import Loading from "@Modules/App/Loading";
import { get } from 'lodash';

const Preview = ({
    match: {
        params: { id },
    },
}) => {
    const [skuInventories, setSkuInventories] = useState([]);
    const { error, isLoading, isError, data } = useSkuInventoryDocumentQuery(id);
    const [skuInventorydocumentData, setSkuInventoryDocumentData] = useState();

    const handleSuccessBalance = usePersistFn(data => {
        setSkuInventoryDocumentData(data);
    });

    useEffect(() => {
        setSkuInventories(skuInventorydocumentData?.sku_inventories);
    }, [skuInventorydocumentData]);

    useEffect(() => {
        setSkuInventoryDocumentData(data?.data);
        setDocumentTitle(t("title_page.inventory_document", {code: get(data, "data.document_sku_inventory.code", "")}));
    }, [data]);

    useEffect(() => {
        setNavigator(t('document:inventory_document'));
    }, []);

    if (isError) {
        const status = error?.response?.status;

        if (status === 403) {
            return <Error403 />;
        }

        return <Error404 />;
    }

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <div className={styles['inventory-preview']}>
            <BarcodeList barcodes={skuInventories} />
            <Summary inventoryDocument={skuInventorydocumentData} screen="preview" onSuccessBalance={handleSuccessBalance} />
        </div>
    );
};

export default Preview;
