import { CloseOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';

import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';

import api from '../services/api';
import { SERVICE_TYPE } from '../services/constants';

const ModalAddService = ({ setVisibleAdd, serviceType, countryId }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleHideModalAdd = () => {
        setVisibleAdd(false);
    };

    const handleSubmitModalAdd = () => {
        const data = {
            ...form.getFieldsValue(true),
            type: serviceType,
            country_id: countryId,
        };
        form.validateFields().then(() => {
            setLoading(true);
            api.createService(data)
                .then(res => {
                    notification.success(t('message.create_service_success'));
                    setVisibleAdd(false);

                    events.dispatch(`RELOAD_LIST_SERVICE_${serviceType}`, {});
                })
                .catch(err => {
                    let { response } = err;

                    if (response?.status === 400) {
                        let error_messages = [];
                        let errors = get(response, 'data.data', {});

                        Object.keys(errors).forEach(item => {
                            let key_errors = [];
                            Object.keys(errors[item]).forEach(error => {
                                if (error === 'duplicated') {
                                    key_errors.push({
                                        name: 'code',
                                        errors: [t('message.duplicated', { attribute: t(`label.service_code`) })],
                                    });
                                }
                            });
                            error_messages.push(...key_errors);
                        });

                        form.setFields(error_messages);
                    }
                    notification.error(t('message.create_service_failed'));
                    setLoading(false);
                });
        });
    };

    return (
        <Modal
            visible={true}
            title={t('btn.add_service')}
            closable={true}
            closeIcon={<CloseOutlined style={{ padding: '16px' }} onClick={handleHideModalAdd} />}
            destroyOnClose={true}
            footer={[
                <Button key="back" onClick={handleHideModalAdd}>
                    {t('btn.cancel')}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleSubmitModalAdd}
                    className={`btn-add-service__${serviceType.toLowerCase()}`}
                    loading={loading}
                >
                    {t('btn.ok')}
                </Button>,
            ]}
        >
            <Form form={form}>
                <Form.Item
                    name="code"
                    label={t('label.service_code')}
                    className={`input-add-service__${serviceType.toLowerCase()}-code`}
                    rules={[
                        {
                            required: true,
                            message: t('order:message.required', { attribute: t('label.service_code') }),
                        },
                    ]}
                >
                    <Input placeholder={t('placeholder.service_code')} />
                </Form.Item>
                <Form.Item
                    name="name"
                    label={t('label.service_name')}
                    className={`input-add-service__${serviceType.toLowerCase()}-name`}
                    rules={[
                        {
                            required: true,
                            message: t('order:message.required', { attribute: t('label.service_name') }),
                        },
                    ]}
                >
                    <Input placeholder={t('placeholder.service_name')} />
                </Form.Item>
                <Form.Item
                    name="auto_price_by"
                    label={t('label.logic_apply')}
                    className={`input-add-service__${serviceType.toLowerCase()}-name`}
                >
                    <Select placeholder={t('placeholder.service_logic')} allowClear>
                        {serviceType !== SERVICE_TYPE.EXTENT &&
                            <>
                                <Select.Option value="SIZE">Size</Select.Option>
                                <Select.Option value="VOLUME">CBM</Select.Option>
                            </>
                        }
                        <Select.Option value="SELLER">Seller</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddService;
