import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';

import Box from '@Modules/App/Box';
import { setNavigator } from '@Modules/App/services';
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse';

import { t } from '@System/i18n';
import { url } from '@System/routing';

import useAddSkuInventoryDocumentMutation from './../Hooks/useAddSkuInventoryDocumentMutation';

import styles from './create.module.scss';
import { get } from 'lodash';
import useGeneratorKey, { IdempotencyStorage } from '@System/hooks/useGeneratorKey';
import notification from '@System/notification';
import LeavePageBlocker from '../../Importing/components/LeavePageBlocker';

const Create = () => {
    const { uniqueKey, generatorKey } = useGeneratorKey()
    const [dirty, setDirty] = useState(false)

    const [form] = Form.useForm();
    const { isLoading, mutate } = useAddSkuInventoryDocumentMutation({
        onSuccess: data => {
            setDirty(false)
            IdempotencyStorage.reset()
            url.redirectTo('documents.inventory.detail', {
                id: data?.document_sku_inventory?.id,
            });
        },
        onError: error => {
            const { response } = error
            const code = get(response, 'data.code')
            if (code === 'REQUEST_PROCESSED') {
                setDirty(true)
                notification.warning(t('message.request_process'))
            }else{
                setDirty(false)
                generatorKey()
            }
        },
    });

    const handleFinish = values => {
        setDirty(true)
        mutate(values);
    };

    useEffect(() => {
        setNavigator(t('document:create_inventory_document'));
    }, []);

    return (
        <>
        {dirty && <LeavePageBlocker when={dirty} />}
         <Box className={styles['create-inventory']}>
            <Form form={form} onFinish={handleFinish}>
                <Form.Item
                    name="warehouse_id"
                    rules={[
                        {
                            required: true,
                            message: t('validation:required', {
                                attribute: t('warehouse'),
                            }),
                        },
                    ]}
                >
                    <SelectWarehouse
                        allowClear
                        className="_warehouse"
                        dropdownClassName="_warehouses-dropdown"
                        placeholder={t('select_warehouse')}
                    />
                </Form.Item>
                <Button className="_create-btn" htmlType="submit" loading={isLoading} type="primary">
                    {t('document:create_inventory_document')}
                </Button>
            </Form>
        </Box>
        </>
       
    );
};

export default Create;
