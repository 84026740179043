import { Form } from 'antd';
import omit from 'lodash/omit';
import React, { useEffect, useState } from 'react';

import useAntdTable from '@Modules/App/Hooks/useAntdTable';
import useDeepCompareEffect from '@Modules/App/Hooks/useDeepCompareEffect';
import useTableSelection from '@Modules/App/Hooks/useTableSelection';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import PERMISSIONS from '@Modules/Auth/services/permissions';
import useProductsQuery from '@Modules/Product/Hooks/useProductsQuery';
import ImportMapSkuWithSkuMarketPlace from '@Modules/Product/components/ImportMapSkuWithSkuMarketPlace';
import ImportRefSkus from '@Modules/Product/components/ImportRefSkus';
import UploadProductFile from '@Modules/Product/components/UploadProductFile';

import { events } from '@System/events';
import { t } from '@System/i18n';

import { EVENTS } from '../../services/constants';
import FormSearch from './FormSearch';
import List from './List';

export default function ListProducts() {
    const [form] = Form.useForm();
    const { filters, products, isLoading, pagination, search } = useAntdTable(useProductsQuery, {
        form,
    });

    const { clear, submit } = search;

    const {
        isSelectAllPage,
        rowSelection,
        selectedRowKeys,
        clearSelection,
        render: renderSelection,
    } = useTableSelection({
        totalInAllPage: pagination?.total || 0,
        totalInCurrentPage: products?.length || 0,
        totalPage: pagination?.page_total || 0,
        type: t('product').toLowerCase(),
    });

    const [activeKey, setActiveKey] = useState('product-list')

    const handleSearch = (key, value) => {
        if (key === 'pagination') {
            submit(value);
        } else {
            submit();
        }
    };

    useEffect(() => {
        setNavigator(t('common:title.list-products'), [
            {
                name: t('common:breadcrumb.products'),
                route: 'products',
            },
            {
                name: t('common:breadcrumb.list-products'),
            },
        ]);
        setDocumentTitle(t('title.list-products'));

        events.listen(EVENTS.RELOAD_PRODUCT_LIST, () => {
            submit();
        });

        return events.listen(EVENTS.IMPORT_PRODUCT_FROM_EXCEL, () => {
            clear();
        });

        // eslint-disable-next-line
    }, []);

    useDeepCompareEffect(() => {
        clearSelection();
    }, [omit(filters, 'page'), clearSelection]);

    const handleChangeTab = activeKey => {
        setActiveKey(activeKey)
    }

    const isCombo = activeKey === 'combo-list'

    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex flex-column flex-lg-row justify-content-lg-between align-items-lg-center">
                    <h2 className="p-3">{t('common:heading.list-products')}</h2>
                  {!isCombo &&  <div className="pr-3 pb-3 pb-lg-0 pl-3 pl-lg-0">
                        <ImportMapSkuWithSkuMarketPlace
                            marketplace="Shopee"
                            fileName={'import_map_sku_with_sku_shopee.xlsx'}
                        />
                        <ImportRefSkus
                            className="mr-2 mb-2 mb-lg-0"
                            filters={filters}
                            isSelectAllPage={isSelectAllPage}
                            selectedRowKeys={selectedRowKeys}
                            onSuccessImport={submit}
                        />
                        {auth.can(PERMISSIONS.PRODUCT_CREATE) && <UploadProductFile />}
                    </div>}
                </div>
                <div>
                    <FormSearch
                        filters={filters}
                        form={form}
                        loading={isLoading}
                        onReset={clear}
                        onSearch={handleSearch}
                        isCombo={isCombo}
                    />
                    {/* <ProductList
                        pagination={pagination}
                        products={products}
                        rowSelection={rowSelection}
                        selectedRowKeys={selectedRowKeys}
                        filters={filters}
                        loading={isLoading}
                        onSearch={handleSearch}
                        renderSelection={renderSelection}
                    /> */}
                    <List
                        pagination={pagination}
                        products={products}
                        rowSelection={rowSelection}
                        selectedRowKeys={selectedRowKeys}
                        filters={filters}
                        loading={isLoading}
                        onSearch={handleSearch}
                        renderSelection={renderSelection}
                        activeKey={activeKey}
                        handleChangeTab={handleChangeTab}
                    />
                </div>
            </div>
        </div>
    );
}
