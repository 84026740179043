import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import { t } from '@System/i18n';
import notification from '@System/notification';

import apiService from './../services/api';

const useOAuthUrlQuery = (params, settings) =>
    createUseQuery({
        name: ['marketplaces-oauth', params],
        query: () => apiService.oAuthUrl(params),
        settings: {
            enabled: false,
            onError: () => {
                notification.error(t('marketplaces:get_oauth_url.failed'));
            },
            ...settings,
        },
    });

export default useOAuthUrlQuery;
