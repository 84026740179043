export const BARCODE_TYPE = {
    FREIGHT_BILL: "FREIGHT_BILL",
    ORDER: "ORDER",
};

export const SCAN_PACKING_PURCHASING_PACKAGE_TYPE = {
    FREIGHT_BILL: "FREIGHT_BILL",
    ORDER: "ORDER"
};

export const ORDER_PACKING_EVENT = {
    UPDATE_SERVICES: 'ORDER_PACKING.UPDATE_SERVICES',
};

export const LOG_BARCODE_TYPE = {
    FREIGHT_BILL: "FREIGHT_BILL",
    ORDER: "ORDER",
    SERVICE: "SERVICE"
}