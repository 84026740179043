import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import api from '@Modules/Finance/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { notifyErrorFromServer } from '@System/support/helpers';

const useCreateControlDeliveryMutation = payload => {
    if (!payload) {
        payload = {};
    }

    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit(
        {
            ...payload,
            onSuccess: ({ data }) => {
                if (isFunction(payload?.onSuccess)) {
                    payload.onSuccess();
                }
            },
            onError: error => {
                if (isFunction(payload?.onError)) {
                    payload.onError(error);
                }

                if (payload.notifyWhenError) {
                    notifyErrorFromServer(
                        error,
                        t('order:confirm_order_info.failed'),
                        'order:confirm_order_info.errors'
                    );
                }
            },
        },
        ['notifyWhenError']
    );

    return useMutation(data => {
        const formData = new FormData();

        for (const key in data) {
            formData.append(key, data[key]);
        }

        return api.createControlDeliveryDocument(formData);
    }, formattedConfig);
};

export default useCreateControlDeliveryMutation;
