import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { get } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import useFilter from '@Modules/App/Hooks/useFilter';
import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import useQuotationQuery from '@Modules/Dropshipping/Hooks/useQuotationQuery';
import ModalCreateQuotation from '@Modules/Dropshipping/components/ModalCreateQuotation';

import { t } from '@System/i18n';

import Create from './Create';
import History from './History';
import ServiceBox from './ServiceBox';

const Services = ({
    productId,
    skus,
    productActivePriceDetails,
    currency,
    productActivePrice,
    history,
    location,
    refetch,
    creator,
    canCreate,
}) => {
    const { filters, addFilter } = useFilter(history, location, {});
    const [prices, setPrices] = useState([]);
    const [pagination, setPagination] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [showService, setShowService] = useState(false);
    const { data, isFetching, refetch: fetchPrice, isError, error } = useQuotationQuery(productId, filters);

    const showModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        fetchPrice();
    }, [fetchPrice]);

    useEffect(() => {
        setPrices(get(data, 'data.product_prices'));
        setPagination(get(data, 'data.pagination'));
    }, [data]);

    const reCallApi = useCallback(() => {
        refetch();
        fetchPrice();
    }, []);

    if (isError) {
        const status = error.response.status;

        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    const handleChangStatus = () => {
        setShowService(true);
    };
    return (
        <>
            {prices?.length !== 0 ? (
                <div className='p-4'>
                    {canCreate && auth.can(permissions.QUOTATION_CREATE) && (
                        <div className="text-right mr-2 mb-2">
                            <Button type="primary" onClick={showModal}>
                                <PlusOutlined /> {t('product:btn.create_quotation')}
                            </Button>
                        </div>
                    )}
                    {productActivePrice && (
                        <ServiceBox
                            productActivePriceDetails={productActivePriceDetails}
                            currency={currency}
                            productActivePrice={productActivePrice}
                            creator={creator}
                            reCallApi={reCallApi}
                        />
                    )}
                    <History
                        prices={prices}
                        currency={currency}
                        pagination={pagination}
                        addFilter={addFilter}
                        isFetching={isFetching}
                        fetchPrice={fetchPrice}
                        filters={filters}
                    />
                </div>
            ) : (
                !showService && (
                    <div className="d-flex justify-content-center p-4">
                        <div className="text-center p-4">
                            <div className="mb-2" style={{ fontSize: '16px' }}>
                                {t('product:label.blank_quotation')}
                            </div>
                            {canCreate && auth.can(permissions.QUOTATION_CREATE) && (
                                <Button type="primary" onClick={handleChangStatus}>
                                    {t('product:btn.create_quotation')}
                                </Button>
                            )}
                        </div>
                    </div>
                )
            )}

            {canCreate && showService && (
                <Create
                    productId={productId}
                    skus={skus}
                    currency={currency}
                    reCallApi={reCallApi}
                    setShowService={setShowService}
                />
            )}
            {isModalVisible && (
                <ModalCreateQuotation
                    setIsModalVisible={setIsModalVisible}
                    productId={productId}
                    skus={skus}
                    reCallApi={reCallApi}
                    currency={currency}
                />
            )}
        </>
    );
};

export default Services;
