export const ORDER_STATUSES = {
    NEW: {
        key: 'new',
        name: 'new',
        color: '#1890FF'
    },
    PROCESS: {
        key: 'process',
        name: 'process',
        color: '#87d068'
    },
    FINISHED: {
        key: 'finished',
        name: 'finished',
        color: '#ff4559'
    },
    CANCELED: {
        key: 'canceled',
        name: 'canceled',
        color: '#090a0a'
    },
    ERROR: {
        key: 'error',
        name: 'error',
        color: '#ff021b'
    }
};

export const STATUSES = {
    DRAFT: 'DRAFT',
    CANCELLED: 'CANCELLED',
    COMPLETED: 'COMPLETED'
};

export const SCAN_TYPE = {
    FREIGHT_BILL: 'FREIGHT_BILL',
    ORDER: 'ORDER'
}
