import { Modal, Select } from 'antd'
import { filter, isEmpty, map, uniq } from 'lodash'
import React, { useState } from 'react'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'

import { t, trans } from '@System/i18n'

const SelectSerial = ({ onChange, value, disabled }) => {
    const [options, setOptions] = useState(value || [])

    const handleChange = value => {
        const newData = [...options]
        newData.push(value)
        if (onChange) {
            onChange(map(uniq(newData), item => item.replace(/\s/g, '')).filter(Boolean))
        }
        setOptions(map(uniq(newData), item => item.replace(/\s/g, '')).filter(Boolean))
    }

    const handlePressEnter = e => {
        if (e.key === 'Enter') {
            const newData = [...options]
            newData.push(e.target.value)
            if (onChange) {
                onChange(map(uniq(newData), item => item.replace(/\s/g, '')).filter(Boolean))
            }
            setOptions(map(uniq(newData), item => item.replace(/\s/g, '')).filter(Boolean))
        }
    }

    const onDeselect = (value) => {
        Modal.confirm({
            title: trans("packing:message.confirm_remove_serial", {
                serial_code: <b>{value}</b>
            }),
            icon: false,
            content: '',
            onOk() {
                const newData = [...options]
                const filterData = filter(newData, item=>item !== value)
        
                if (onChange) {
                    onChange(map(uniq(filterData), item => item.replace(/\s/g, '')).filter(Boolean))
                }
                setOptions(map(uniq(filterData), item => item.replace(/\s/g, '')).filter(Boolean))
            },
            onCancel() {
            //   console.log('Cancel');
            },
          })
       
    }

    return (
        <Select
            mode="tags"
            style={{ width: '100%', minWidth: 140 }}
            placeholder={t('label.serial_number')}
            value={!isEmpty(value) ? value : options}
            maxTagCount={3}
            onDeselect={onDeselect}
            maxTagTextLength={140}
            disabled={disabled}
            onBlur={e => handleChange(e.target.value)}
            onKeyDown={handlePressEnter}
            notFoundContent={
                <div className="d-flex justify-content-center align-items-center flex-column">
                    <TableEmpty />
                </div>
            }
        />
    )
}

export default SelectSerial
