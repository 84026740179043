import React, {useEffect, useState} from "react";
import api from "../../../services/api";
import {notification} from "antd";
import {t} from "../../../../../system/i18n";
import _ from "lodash";
import ListStocks from "./ListStocks";
import FormSearch from "./FormSearch";

export default function VariantStocks({skuInfo}) {
    let [stocks, setStocks] = useState([]);
    let [realStocks, setRealStocks] = useState([]);
    let [loading, setLoading] = useState(false);
    let [filters, setFilters] = useState({});

    useEffect(() => {
        fetchStock();

        // eslint-disable-next-line
    }, [])

    function fetchStock() {
        setLoading(true);
        api.getSkuStocks(skuInfo.id)
            .then(res => {
                let tmpStocks = _.get(res, "data.stocks", []);
                setStocks(tmpStocks);
                setRealStocks(tmpStocks);
            })
            .catch(({response}) => {
                if (response.status === 403)
                    notification.error({message: t('common:message.403')});
                else if (response.status === 404)
                    notification.error({message: t('common:message.404')});
                else
                    notification.error({message: t('common:message.update_failed', {attribute: t("common:label.product_stocks").toLowerCase()})});
            })
            .finally(() => setLoading(false));
    }

    function applyFilters(defaultFilters = {}) {
        let filterKeys = Object.keys(defaultFilters);
        if (filterKeys.length > 0)
            setRealStocks(stocks.filter(({stock, merchant}) => {
                return filterKeys.every(key => {
                    if (key === "merchant_id")
                        return merchant.id === defaultFilters[key];
                    else
                        return stock[key] === defaultFilters[key];
                });
            }));
        else
            setRealStocks(stocks);
    }

    return (
        <div>
            <FormSearch
                stocks={stocks}
                applyFilters={applyFilters}
                filters={filters}
                setFilters={setFilters}
            />

            <ListStocks
                stocks={realStocks}
                loading={loading}
            />
        </div>
    );
}
