import { Col, Row } from 'antd';
import React from 'react';

import OrderPackingList from './OrderPackingList';
import ProductList from './ProductList';

const Body = ({
    validOrderExportingIds,
    isExportForGoods,
    products,
    orderPackings,
    packingDocument,
    onDelete,
    pagination,
    filters,
    setFilters,
    loadingOrderPackingList,
     loadingProduct
}) => {
    return (
        <Row gutter={48} type="flex">
            <Col className="mb-3 mb-lg-0" lg={{ span: 18 }} span={24}>
                <OrderPackingList
                    validOrderExportingIds={validOrderExportingIds}
                    isExportForGoods={isExportForGoods}
                    orderPackings={orderPackings}
                    packingDocument={packingDocument}
                    onDelete={onDelete}
                    pagination={pagination}
                    setFilters={setFilters}
                    filters={filters}
                    loadingOrderPackingList={loadingOrderPackingList}
                />
            </Col>
            <Col lg={{ span: 6 }} span={24}>
                <ProductList products={products} loading={loadingProduct}/>
            </Col>
        </Row>
    );
};

export default Body;
