import { Button, Dropdown, Menu, notification } from 'antd';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import {useReactToPrint} from "react-to-print";

import { TYPE_STAMP_SIZE } from '@Modules/Product/services/constants';
import RenderPrintWarehouseAreaBarcode from '@Modules/WarehouseArea/components/RenderPrintWarehouseAreaBarcode';

import { t } from '@System/i18n';

function PrintWarehouseAreaBarcode({ className, warehouseAreaIds, data = [] }) {
    const [stampSize, setStampSize] = useState({});

    const stampRef = useRef();
    const printStamp = useReactToPrint({
        content: () => stampRef.current
    });

    const handlePrintStampButtonClick = stampSizeInfo => {
        if (warehouseAreaIds.length) {
            setStampSize(stampSizeInfo);
            setTimeout(() => {
                printStamp();
            }, 1000);
        } else {
            notification.error({ message: t('warehouseArea:message.choose_print_required') });
        }
    };
    const selectedAction = (
        <Menu className="lading-dropdown-action">
            {TYPE_STAMP_SIZE.map((item, index) => {
                return (
                    <Menu.Item
                        key={index}
                        className="_scan-order-list-of-lading-btn-remove-selected mb-3 mb-xl-0"
                        onClick={() => handlePrintStampButtonClick(item)}
                    >
                        {t(`product:TYPE_STAMP_SIZE.${item.label}`, { count: item.item_per_page })}
                    </Menu.Item>
                );
            })}
        </Menu>
    );

    return (
        <>
            {
                warehouseAreaIds.length > 0 && !isEmpty(stampSize) &&
                <div className="d-none">
                    <RenderPrintWarehouseAreaBarcode ref={stampRef} data={data} stampSize={stampSize} warehouseAreaIds={warehouseAreaIds}/>
                </div>
            }
            <Dropdown overlay={selectedAction} trigger={['click']} className="_dropdown-action">
                <Button className={`${className} _print-stamp-btn`} type="primary" onClick={e => e.preventDefault()}>
                    {t(`warehouseArea:btn.print`)}
                </Button>
            </Dropdown>
        </>
    );
}

PrintWarehouseAreaBarcode.propTypes = {
    skuIds: PropTypes.array,
    className: PropTypes.string,
};

PrintWarehouseAreaBarcode.defaultProps = {
    skuIds: [],
    className: '',
};

export default PrintWarehouseAreaBarcode;
