import React, {useState} from 'react';
import {t} from '../../../system/i18n';
import {Button, Form, Input, Modal, Popconfirm, notification} from "antd";
import useValidatedMessages from "../../../system/hooks/useValidatedMessages";
import _ from 'lodash';
import {QuestionCircleOutlined} from '@ant-design/icons';
import api from "../services/api";
import {auth} from "../../Auth/services";
import PERMISSIONS from "../../Auth/services/permissions";
import {events} from "../../../system/events";
import {EVENTS} from "../services/constants";

const validatedRules = {
    shop_base_account: [{required: true, max: 255, type: "string"}],
    shop_base_app_key: [{required: true, max: 255, type: "string"}],
    shop_base_password: [{required: true, max: 255, type: "string"}],
    shop_base_secret: [{required: true, max: 255, type: "string"}],
};

const layout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

export default function ConnectShopbase(props) {
    const {visible, setVisible, dataConnect} = props;
    const [form] = Form.useForm();
    const validateMessages = useValidatedMessages();
    const [loading, setLoading] = useState(false);

    function handleOk() {
        form.validateFields()
            .then(values => {
                setLoading(true);
                api.connectShopbase(dataConnect.id, values)
                    .then((res) => {
                        events.dispatch(EVENTS.FETCH_LIST_MERCHANT_AGAIN, {});
                        notification.success({message: t("merchant:message.connect_shopbase_success")});
                        handleCancel();
                    }).catch(err => {
                    notification.error({message: t("merchant:message.connect_shopbase_fail")})
                }).finally(() => setLoading(false))
            });
    }

    function handleDisconnect() {
        setLoading(true);
        api.disConnectShopbase(dataConnect.id)
            .then((res) => {
                events.dispatch(EVENTS.FETCH_LIST_MERCHANT_AGAIN, {});
                notification.success({message: t("merchant:message.disconnect_shopbase_success")});
                handleCancel();
            }).catch(err => {
            notification.error({message: t("merchant:message.disconnect_shopbase_fail")})
        }).finally(() => setLoading(false))

    }

    function handleCancel() {
        setVisible(false);
    }

    return (
        auth.can(PERMISSIONS.MERCHANT_CONNECT_SHOP_BASE) &&
        <Modal
            title={t("merchant:title.shopbase_connect_info")}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>{t("btn.reset")}</Button>,
                _.isEmpty(_.get(dataConnect, "shop_base_webhook_id", null)) ?
                    <Button key="ok" type="primary" onClick={handleOk}>
                        {t("merchant:label.create_connect")}
                    </Button> :
                    <Button type="primary" onClick={handleDisconnect}
                            loading={loading}>{t("merchant:label.disconnect_shopbase")}</Button>
            ]}
            width="650px"
        >
            <Form
                {...layout}
                className={"form"}
                validateMessages={validateMessages}
                size="default"
                form={form}
                colon=":"
                initialValues={dataConnect ? dataConnect : {}}
            >
                <Form.Item
                    className="mb-3"
                    name="shop_base_account"
                    label={t('merchant:label.shop_base_account')}
                    rules={validatedRules.shop_base_account}
                >
                    <Input
                        placeholder={t('merchant:placeholder.shop_base_account')}
                    />
                </Form.Item>

                <Form.Item
                    className="mb-3"
                    name="shop_base_app_key"
                    label={t('merchant:label.shop_base_app_key')}
                    rules={validatedRules.shop_base_app_key}
                >
                    <Input
                        placeholder={t('merchant:placeholder.shop_base_app_key')}
                    />
                </Form.Item>

                <Form.Item
                    className="mb-3"
                    name="shop_base_secret"
                    label={t('merchant:label.shop_base_secret')}
                    rules={validatedRules.shop_base_secret}
                >
                    <Input
                        placeholder={t('merchant:placeholder.shop_base_secret')}
                    />
                </Form.Item>

                <Form.Item
                    className="mb-3"
                    name="shop_base_password"
                    label={t('merchant:label.shop_base_password')}
                    rules={validatedRules.shop_base_password}
                >
                    <Input.TextArea
                        autoSize={{minRows: 2, maxRows: 6}}
                        placeholder={t('merchant:placeholder.shop_base_password')}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}