import { Button } from 'antd'
import React from 'react'

import { t } from '@System/i18n'
import { classes } from '@System/support/helpers'

import ComboList from './ComboList'
import ProductList from './ProductList'

export default function List({
    pagination,
    onSearch,
    filters,
    loading,
    rowSelection,
    selectedRowKeys,
    products,
    renderSelection,
    activeKey,
    handleChangeTab,
    dataSkuCombo,
    loadingSkuCombo,
}) {
    const tabs = [
        {
            title: t('product_list'),
            key: 'product-list',
        },
        {
            title: t('product:label.sku_combo_list'),
            key: 'combo-list',
        },
    ]

    return (
        <div className="m-3">
            <div className="d-flex">
                {tabs.map(tab => (
                    <Button
                        className={classes(`tab-list-product mr-2`, {
                            active: tab.key === activeKey,
                        })}
                        key={tab.key}
                        onClick={() => handleChangeTab(tab.key)}
                        loading={tab.loading}
                    >
                        <span> {tab.title} </span>
                    </Button>
                ))}
            </div>

            <>
                {activeKey === 'product-list' && (
                    <ProductList
                        pagination={pagination}
                        products={products}
                        rowSelection={rowSelection}
                        selectedRowKeys={selectedRowKeys}
                        filters={filters}
                        loading={loading}
                        onSearch={onSearch}
                        renderSelection={renderSelection}
                    />
                )}

                {activeKey === 'combo-list' && (
                    <ComboList
                        data={dataSkuCombo}
                        loading={loadingSkuCombo}
                        onSearch={onSearch}
                        filters={filters}
                    />
                )}
            </>
        </div>
    )
}
