import React, {useState} from "react";
import {Button, Input, Modal, notification} from "antd";
import {t} from "@System/i18n";
import {ShoppingCartOutlined, CheckOutlined} from "@ant-design/icons";
import SelectShippingPartner from "@Modules/ShippingPartners/components/SelectShippingPartner";
import api from "@Modules/Order/services/api";
import {events} from "@System/events";
import {EVENTS} from "@Modules/Order/services/constants";
import omit from "lodash/omit";

export default function UpdateShippingPartner({ids, isSelectAllPage, setSelectedRowKeys, filters}) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [shippingPartnerId, setShippingPartnerId] = useState(null);
    const [updating, setUpdating] = useState(false);

    function showModal() {
        if (ids.length > 0 && filters.warehouse_id) {
            setIsModalVisible(true);
        } else {
            Modal.warning({
                title: filters.warehouse_id ? t("order:title.select_order_packing") : t("order:title.select_order_packing_warehouse"),
                content: filters.warehouse_id ? t("order:placeholder.select_order_packing") : t("order:placeholder.select_order_packing_warehouse"),
            });
        }
    }

    function handleOk() {
        let data = {
            updated_shipping_partner_id: shippingPartnerId,
            ...omit(filters, ["current_page", "page", "per_page"])
        };

        /**
         * Nếu chọn tất cả thì cập nhật đvvc cho tất cả các ycđh của kho hiện tại
         */
        if (isSelectAllPage)
            data.ids = [];
        else
            data.ids = [...ids];

        api.updateShippingPartnerForOrderPackings(data)
            .then(res => {
                notification.success({message: t("order:message.update_shipping_partner_order_packings.success")});
                setIsModalVisible(false);
                setSelectedRowKeys([]);
                events.dispatch(EVENTS.IMPORT_ORDER_FROM_EXCEL);
            })
            .catch(err => {
                notification.error({message: t("order:message.update_shipping_partner_order_packings.fail")});
            });
    }

    function handleCancel() {
        setIsModalVisible(false);
        setShippingPartnerId(null);
    }

    function onChange(value) {
        setShippingPartnerId(value);
    }

    return (
        <>
            <Button
               
                className="_prepare-order-btn-update-shipping-partner btn-menu-item"
                onClick={showModal}
            >{t("order:btn.select_shipping_partner")}</Button>
            <Modal
                title={t("order:title.select_shipping_partner")}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={<><CheckOutlined /> {t("btn.confirm")}</>}
                cancelText={t("btn.cancel")}
                width={420}
                okButtonProps={{
                    disabled: (shippingPartnerId === null || updating),
                    loading: updating
                }}
            >
                <SelectShippingPartner
                    onChange={onChange}
                    style={{width: "100%"}}
                    placeholder={t("order:label.order_shipping_partner")}
                    disabled={updating}
                    all
                />
            </Modal>
        </>
    );
}
