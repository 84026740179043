import { functionsIn, get, orderBy } from 'lodash'

import { scanPackingPurchasingPackageStorage } from '@Modules/App/services'

export const ACTION_SCAN_PACKING_PURCHASING_PACKAGE = {
    NEW_SCAN: 'ACTION_SCAN_PACKING_PURCHASING_PACKAGE.NEW_SCAN',
    UPDATE_SCAN_INFO: 'ACTION_SCAN_PACKING_PURCHASING_PACKAGE.UPDATE_SCAN_INFO',
    UPDATE_CONFIRM_PACKING_IDS: 'ACTION_SCAN_PACKING_PURCHASING_PACKAGE.UPDATE_CONFIRM_PACKING_IDS',
    IS_CONFIRM: 'ACTION_SCAN_PACKING_PURCHASING_PACKAGE.IS_CONFIRM',
    UPDATE_ORDER_STOCKS: 'UPDATE_ORDER_STOCKS',
}

export default function reducer(state, action) {
    let newState
    let { payload } = action
    switch (action.type) {
        case ACTION_SCAN_PACKING_PURCHASING_PACKAGE.NEW_SCAN:
            newState = { ...payload }
            break
        case ACTION_SCAN_PACKING_PURCHASING_PACKAGE.UPDATE_SCAN_INFO:
            newState = { ...state, ...action.payload }
            break
        case ACTION_SCAN_PACKING_PURCHASING_PACKAGE.UPDATE_CONFIRM_PACKING_IDS:
            newState = updateConfirmPackingIds(state, payload)
            break
        case ACTION_SCAN_PACKING_PURCHASING_PACKAGE.UPDATE_ORDER_STOCKS:
            newState = updateOrderStocks(state, payload)
            break
        default:
            newState = { ...state }
            break
    }

    scanPackingPurchasingPackageStorage.setValue(newState)

    return newState
}

function updateConfirmPackingIds(state, payload) {
    return { ...state, confirmPackingIds: payload }
}

function updateOrderStocks(state, payload) {
    return { ...state, orderStocks: payload }
}
