import { Form, Modal, notification } from 'antd'
import clsx from 'clsx'
import { get, isEmpty, filter, map, find, includes, flatMap, cloneDeep, groupBy, sumBy, uniq } from 'lodash'
import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch } from 'react-redux'

import Box from '@Modules/App/Box'
import withScanErrors from '@Modules/App/ScanErrors/withScanErrors'
import { SCAN_RETURN_ORDER_TYPE } from '@Modules/Document/services/constants'
import Body from '@Modules/ScanOrder/screens/Packing/PurchasingPackage/Body'
import FormScan from '@Modules/ScanOrder/screens/Packing/PurchasingPackage/components/FormScan'
import OrderInfo from '@Modules/ScanOrder/screens/Packing/PurchasingPackage/components/OrderInfo'
import reducer, { ACTION_SCAN_PACKING_PURCHASING_PACKAGE } from '@Modules/ScanOrder/screens/Packing/PurchasingPackage/reducer'
import ServiceWarehouses from '@Modules/Warehouse/services/ServiceWarehouses'

import { clearErrors } from '@State/scanErrors/actions'
import { useScanErrorsOfType } from '@State/scanErrors/hooks'

import { t, trans } from '@System/i18n'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

import {
    scanPackingPurchasingPackageStorage,
    setDocumentTitle,
    setNavigator,
    startScanServicePackingPurchasingPackage,
    warehouseStorage,
} from '../../../../App/services'
import { updateCollectionItem } from '../../../../App/services/helpers'
import { SCAN_TYPE } from '../../../../Document/constants'
import { ORDER_PACKING_STATUS } from '../../../../Order/services/constants'
import renderErrorScan from '../../../components/customerError'
import { convertDataOrderPacking, handleSortListByKey } from '../../../components/customerFunction'
import api from '../../../services/api'

import styles from './../PurchasingPackage/scan-packing-purchasing-package.module.scss'

const scanType = 'packingPurchasingPackage'
const ScanWithErrors = withScanErrors(FormScan, scanType)

function PackingPurchasingPackage({ page }) {
    const [form] = Form.useForm()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [warehouses, setWarehouses] = useState([])
    const [scanOrderPackingListInfo, setScanOrderPackingListInfo] = useState([])
    const [statusAllow, setStatusAllow] = useState([ORDER_PACKING_STATUS.WAITING_PICKING.key, ORDER_PACKING_STATUS.WAITING_PACKING.key])
    const dispatch = useDispatch()
    const scanErrors = useScanErrorsOfType(scanType)
    const initialState = scanPackingPurchasingPackageStorage.value
    const [storeData, dispatchStoreAction] = useReducer(reducer, initialState)
    const storeActions = {
        newScan: (data = {}) => dispatchStoreAction({ type: ACTION_SCAN_PACKING_PURCHASING_PACKAGE.NEW_SCAN, payload: data }),
        updateScanInfo: data => dispatchStoreAction({ type: ACTION_SCAN_PACKING_PURCHASING_PACKAGE.UPDATE_SCAN_INFO, payload: data }),
        updateConfirmPackingIds: data =>
            dispatchStoreAction({
                type: ACTION_SCAN_PACKING_PURCHASING_PACKAGE.UPDATE_CONFIRM_PACKING_IDS,
                payload: data,
            }),
        updateOrderStocks: data =>
            dispatchStoreAction({
                type: ACTION_SCAN_PACKING_PURCHASING_PACKAGE.UPDATE_ORDER_STOCKS,
                payload: data,
            }),
    }

    useEffect(() => {
        setNavigator(t('packing:title.packing_purchasing_package'), [
            {
                name: t('packing:title.packing_purchasing_package'),
            },
        ])
        setDocumentTitle(t('packing:title.packing_purchasing_package'))
    }, [])

    useEffect(() => {
        ServiceWarehouses.list()
            .then(res => {
                setWarehouses(res)
            })
            .catch(() => {
                setWarehouses([])
            })
    }, [])

    useEffect(() => {
        const barcodeType = storeData.barcode_type
        if (barcodeType === SCAN_TYPE.ORDER) {
            setStatusAllow([ORDER_PACKING_STATUS.WAITING_PACKING.key, ORDER_PACKING_STATUS.WAITING_PICKING.key])
        } else {
            setStatusAllow([ORDER_PACKING_STATUS.WAITING_PACKING.key, ORDER_PACKING_STATUS.WAITING_PICKING.key])
        }
    }, [storeData])

    useEffect(() => {
        let ids_exists = storeData.confirmPackingIds
        let ids_new = []
        scanOrderPackingListInfo.map(item => {
            const id_info = find(ids_exists, ['order_packing_id', item.id])
            if (isEmpty(id_info)) {
                return ids_new.push({ order_packing_id: item.id, scanned_at: item.scanned_at, order_stocks: item.order_stocks })
            } else {
                return ids_new.push({ ...id_info })
            }
        })
        storeActions.updateConfirmPackingIds(ids_new)
    }, [scanOrderPackingListInfo])

    useEffect(() => {
        const confirmPackingIds = storeData.confirmPackingIds
        if (!isEmpty(confirmPackingIds)) {
            getScanOrderPackingListByIds(map(confirmPackingIds, 'order_packing_id'))
        }
    }, [])

    function reloadData() {
        const confirmPackingIds = storeData.confirmPackingIds
        if (!isEmpty(confirmPackingIds)) {
            getScanOrderPackingListByIds(map(confirmPackingIds, 'order_packing_id'))
        }
    }

    function getScanOrderPackingListByIds(ids) {
        const params = { warehouse_id: storeData.warehouse_id, ids }
        setLoading(true)
        api.getScanOrderPackingList(params)
            .then(res => {
                const data = get(res, 'data.order_packings', [])
                if (!isEmpty(data)) {
                    const newData = convertDataOrderPacking(data)
                    if (newData.length > 0) {
                        setScanOrderPackingListInfo(handleSortListByKey(newData, statusAllow))
                    }
                }
            })
            .catch(err => {
                renderErrorScan({ err, params })
            })
            .finally(() => setLoading(false))
    }

    function updateListOfLading(data) {
        const new_list = [...scanOrderPackingListInfo]
        const newData = convertDataOrderPacking(data)
        if (newData.length > 0) {
            const dataSort = handleSortListByKey(new_list.concat(newData), statusAllow)
            setScanOrderPackingListInfo(dataSort)
            const dataSortStocks = handleSortListByKey(cloneDeep(get(storeData, 'orderStocks', [])).concat(newData), statusAllow)
            storeActions.updateOrderStocks(dataSortStocks)
        }
    }

    function handleCancelConfirm() {
        const barcode_type = getVar(storeData, 'barcode_type', SCAN_RETURN_ORDER_TYPE.FREIGHT_BILL)
        const warehouse_id = getVar(storeData, 'warehouse_id', warehouseStorage.value)
        let defaultValues = { barcode_type, warehouse_id }
        let formData = [
            { name: 'barcode_type', value: barcode_type },
            { name: 'warehouse_id', value: warehouse_id },
        ]
        storeActions.newScan(defaultValues)
        form.setFields(formData)
        setScanOrderPackingListInfo([])
        storeActions.updateOrderStocks([])
        startScanServicePackingPurchasingPackage.setValue({
            order_code: '',
            started: false,
        })
    }

    function removeAllOrderPackingNotProcess(data) {
        const new_data = data.filter(item => includes(statusAllow, item.status))
        setScanOrderPackingListInfo(new_data)
        storeActions.updateOrderStocks(new_data)
        setError(undefined)
    }

    const mergeDataSkusNotSerial = data => {
        let cloneData = cloneDeep(data)
        let newData = []

        cloneData.forEach(item => {
            let existing = newData.filter(function (v, i) {
                return v.sku_id === item.sku_id
            })
            if (existing.length) {
                let existingIndex = newData.indexOf(existing[0])
                newData[existingIndex].serial_numbers = uniq(newData[existingIndex]?.serial_numbers?.concat(item.serial_numbers))
            } else {
                if (typeof item.serial_numbers === 'string') item.serial_numbers = [item?.serial_numbers]
                newData.push(item)
            }
        })

        const unique = groupBy(data, i => i.sku_id)

        const result = Object.keys(unique).map(key => {
            const first = unique[key][0]
            return {
                ...first,
                quantity: sumBy(unique[key], i => i.quantity),
            }
        })

        return result
    }

    const mergeDataSkus = data => {
        let newData = []

        data.forEach(item => {
            let existing = newData.filter(function (v, i) {
                return v.sku_id === item.sku_id
            })
            if (existing.length) {
                let existingIndex = newData.indexOf(existing[0])
                newData[existingIndex].serial_numbers = uniq(newData[existingIndex]?.serial_numbers?.concat(item.serial_numbers))
            } else {
                if (typeof item.serial_numbers === 'string') item.serial_numbers = [item?.serial_numbers]
                newData.push(item)
            }
        })

        const unique = groupBy(data, i => i.sku_id)

        const result = Object.keys(unique).map(key => {
            const first = unique[key][0]
            return {
                ...first,
                quantity: sumBy(unique[key], i => i.quantity),
            }
        })

        return result
    }


    function handleConfirm() {
        const confirmPackingIds = storeData.confirmPackingIds

        const dataSkus = mergeDataSkus(cloneDeep(flatMap(get(storeData, 'orderStocks', []), item => item?.order_stocks)))
        const skus = map(dataSkus, item => ({ id: item.sku_id, serial_numbers: item.serial_numbers }))
        const newOrderPacking = map(confirmPackingIds, item => {
            const dataOrderStockPacking = filter(get(storeData, 'orderStocks', []), ['order_packing_id', item.order_packing_id])

            const dataMergeSKu = mergeDataSkusNotSerial(flatMap(dataOrderStockPacking, item => item?.order_stocks))

            return {
                ...item,
                skus: map(dataMergeSKu, item => ({ id: item.sku_id, serial_numbers: item.serial_numbers })),
            }
        })

        const doConfirm = () => {
            setError(undefined)
            if (!isEmpty(newOrderPacking)) {
                const data = {
                    warehouse_id: storeData.warehouse_id,
                    scan_type: storeData.barcode_type,
                    order_packings: newOrderPacking,
                    skus,
                }

                setLoadingSubmit(true)
                api.confirmOrderPacking(data)
                    .then(res => {
                        const dataPackingDocument = get(res, 'data.document', {})
                        storeActions.updateScanInfo({ isConfirm: true })
                        notification.success({ message: t('order:message.confirm_packing.success') })
                        const new_data = scanOrderPackingListInfo.map(item => {
                            return { ...item, status: ORDER_PACKING_STATUS.WAITING_DELIVERY.key }
                        })
                        dispatch(clearErrors('confirmPacking'))
                        setScanOrderPackingListInfo(new_data)
                        storeActions.updateOrderStocks(new_data)
                        url.redirectTo('documents.packing.detail', {
                            id: dataPackingDocument?.id,
                        })
                    })
                    .catch(err => {
                        storeActions.updateScanInfo({ isConfirm: false })
                        const status = get(err, 'response.status')
                        if (status === 400) {
                            let errors = get(err, 'response.data.data', {})
                            Object.keys(errors).forEach(item => {
                                if (item === 'exists_order_packing_not_process') {
                                    let orderPackingsInfo = [...scanOrderPackingListInfo]
                                    const orderPackings = errors[item]
                                    orderPackings.map(orderPacking => {
                                        return (orderPackingsInfo = updateCollectionItem(
                                            orderPackingsInfo,
                                            orderPacking.order_packing_id,
                                            { status: orderPacking.order_packing_status },
                                            'key'
                                        ))
                                    })
                                    const ordersParking = handleSortListByKey(orderPackingsInfo, statusAllow)
                                    setScanOrderPackingListInfo(ordersParking)
                                    storeActions.updateOrderStocks(ordersParking)
                                    setError(
                                        trans(`order:message.confirm_packing.exists_order_packing_not_process`, {
                                            action: (
                                                <a
                                                    className="ml-0"
                                                    onClick={() => removeAllOrderPackingNotProcess(ordersParking)}
                                                >
                                                    <b>{t('order:title.remove_all_order_packing_invalid')}</b>
                                                </a>
                                            ),
                                        })
                                    )
                                } else {
                                    let attribute = t(`order:label.${item}`)
                                    if (item === 'barcode') attribute = t(`order:label.barcode`)
                                    const error_messages = []
                                    const listError = [
                                        'sku_invalid_serial_number_exist_in_other_document_exporting',
                                        'sku_invalid_serial_number_exist_in_other_document_packing',
                                        'sku_serial_number_duplicate_scan',
                                    ]
                                    Object.entries(errors).forEach(([key, value]) => {
                                        if (listError.includes(key)) {
                                            return error_messages.push(t(`order:message.scan_order.${key}`, { ...data, attribute }))
                                        } else {
                                            return error_messages.push(t(`order:message.scan_order.${Object.keys(value)[0]}`, { ...data, attribute }))
                                        }
                                    })
                                    setError(error_messages.join(', '))
                                }
                            })
                        } else if (status === 403) notification.error({ message: t('common:message.403') })
                        else if (status === 404) notification.error({ message: t('common:message.404') })
                        else notification.error({ message: t('common:message.server_error') })
                    })
                    .finally(() => setLoadingSubmit(false))
            }
        }

        if (scanErrors?.length) {
            Modal.confirm({
                title: t('order:message.confirm_packing.scan_errors_confirm'),
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
                onOk: doConfirm,
            })
        } else {
            doConfirm()
        }
    }

    function removeScanOrderPackingInfo(ids) {
        setError(undefined)
        const data = filter(scanOrderPackingListInfo, item => {
            const id = get(item, 'id', '')
            return !ids.includes(id)
        })
        startScanServicePackingPurchasingPackage.setValue({
            order_code: '',
            started: false,
        })
        storeActions.updateOrderStocks(data)
        setScanOrderPackingListInfo(data)
    }

    function getOrderPackingScan(params) {
        return api.orderPackingScan(params)
    }

    function handlePass() {
        storeActions.updateScanInfo({ orderInfo: {}, barcode_type_scan_sku: undefined })
    }
    return (
        <div className={clsx(styles['scan-packing-purchasing-package'])}>
            <Box className={styles.header}>
                <ScanWithErrors
                    form={form}
                    storeActions={storeActions}
                    storeData={storeData}
                    warehouses={warehouses}
                    errorKey={scanType}
                    scanOrderListInfo={scanOrderPackingListInfo}
                    setLoadingData={setLoading}
                    getScanInfo={getOrderPackingScan}
                    updateListOfLading={updateListOfLading}
                    reloadData={reloadData}
                    page={page}
                />
            </Box>
            {!isEmpty(getVar(storeData, 'orderInfo.order', {})) && (
                <Box className={styles.order}>
                    <OrderInfo
                        handlePass={handlePass}
                        storeData={storeData}
                        reloadData={reloadData}
                    />
                </Box>
            )}

            <Body
                reloadData={reloadData}
                storeData={storeData}
                error={error}
                loading={loading}
                statusAllow={statusAllow}
                loadingSubmit={loadingSubmit}
                dataSource={scanOrderPackingListInfo}
                handleOk={handleConfirm}
                handleCancel={handleCancelConfirm}
                removeScanOrderInfo={removeScanOrderPackingInfo}
                errorKey={scanType}
            />
        </div>
    )
}

export default PackingPurchasingPackage
