import { Table } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';

import { t } from '@System/i18n';
import { url } from '@System/routing';

interface IListProps {
    pagination: any;
    addFilter: any;
    filters: any;
    data: any;
    loading: boolean;
}

const List: React.FC<IListProps> = ({ pagination, addFilter, filters, data, loading }) => {
    const columns = [
        {
            className: '_document-code',
            title: t('document:label.code'),
            dataIndex: ['document', 'code'],
            key: 'code',
            render: (text: any, record: any) => {
                const documentId = get(record, 'document.id', undefined);
                return (
                    <strong style={{ fontWeight: '500' }}>
                        <Link to={url.to('finances.control-delivery.detail', { id: documentId })}>{text}</Link>
                    </strong>
                );
            },
        },
        {
            className: '_creator-username text-nowrap',
            title: t('document:label.creator_username'),
            dataIndex: ['document', 'creator_username'],
            key: 'creator_username',
        },

        {
            className: '_created-at text-right',
            title: t('document:label.created_at'),
            dataIndex: ['document', 'created_at'],
            key: 'created_at',
            render: (text: string) => {
                return text ? <p>{moment(text).format('DD/MM/YYYY')}</p> : '---';
            },
        },
        {
            className: '_shipping-partner-name text-right',
            title: t('order:label.order_shipping_partner'),
            dataIndex: ['document', 'shipping_partner_name'],
            key: 'shipping_partner_name',
        },
    ];

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {/* {t('common:title.order_financial_control')} */}
                        {t('menu.finance_control_delivery')}
                        <span className="number-circle _forward-info-product-list-total">
                            {get(pagination, 'total', 0)}
                        </span>
                    </h3>
                </div>

                <div className="data-box--body">
                    <Table
                        className="order-list-table pb-3 _forward-info-list "
                        dataSource={data}
                        rowKey={record => {
                            return get(record, 'document.id', undefined);
                        }}
                        columns={columns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: <TableEmpty className={'_forward-info-list-no-data'} loading={loading} />,
                        }}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
};

export default List;
