import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from '../services/api';

const useUpdateSuppliersMutation = createUseMutation({
    namespace: 'user:update_suppliers',
    mutation: data => apiService.updateSuppliers(data),
});

export default useUpdateSuppliersMutation;
