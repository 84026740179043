import React, {useEffect, useState} from "react";
import {Select} from "antd";
import ServiceCategories from "../services/ServiceCategories";

const {Option} = Select;

export default function SelectCategories(props) {
    let [categories, setCategories] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceCategories.list()
            .then(res => {
                setCategories(res);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    return (
        <Select
            loading={isLoading}
            {...props}
            value={(categories.length > 0 && props.value) ? parseInt(props.value) : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                categories.length > 0 &&
                categories.map(opt => {
                    return <Option key={opt.id} value={opt.id}>{opt.name}</Option>;
                })
            }
        </Select>
    );
}
