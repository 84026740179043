import { Col, Row, Spin, Typography } from 'antd'
import { get, isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import useFilter from '@Modules/App/Hooks/useFilter'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import useDebtInformation from '@Modules/Supplier/hooks/useDebtInformation'
import useSupplierDetail from '@Modules/Supplier/hooks/useSupplierDetail'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'
import { formatCurrency } from '@System/support/numberFormat'

import TransactionList from './TransactionList'

const { Paragraph } = Typography

const SupplierDetail = ({ history, location, match }) => {
    const { filters, addFilter } = useFilter(history, location, {})

    const { id } = match.params
    const { data, isLoading, refetch: refetchInfo, isError, error } = useDebtInformation(id)
    const { data: dataDetail, refetch: refetchDetail, isError: isErrorDetail, error: errorDetail, isLoading: loadingDetailDebt } = useSupplierDetail(id)
    const interValRef = useRef()

    const [detail, setDetail] = useState({})
    const [debtInfo, setDebtInfo] = useState({})

    useEffect(() => {
        setNavigator(t('menu.supplier_detail'), [
            {
                name: t('product:label.supplier'),
                route: 'config.supplier.list',
            },
            {
                name: t('menu.supplier_detail'),
            },
        ])
        setDocumentTitle(t('menu.supplier_detail'))
    }, [])

    useEffect(() => {
        setDebtInfo(get(data, 'data'))
    }, [data])

    useEffect(() => {
        setDetail(prev => {
            if (!isEqual(prev, get(dataDetail, 'data.supplier'))) {
                clearInterval(interValRef.current)
                return get(dataDetail, 'data.supplier')
            }
            return prev
        })
    }, [dataDetail])
   

    const infoInventory = get(debtInfo, 'inventory.balance', 0)
    const infoSold = get(detail, 'total_sold_amount', 0) - get(detail, 'total_returned_amount', 0)
    const infoDebtTotal = infoInventory + infoSold

    const detailTotalSold = getVar(detail, 'total_sold_amount', 0) - getVar(detail, 'total_paid_amount', 0) - getVar(detail, 'total_returned_amount', 0)

    const detailDebtTotal = infoInventory + detailTotalSold

    const handleReFetch = () => {
        refetchInfo()
    }

    const reFetchDetailPay = () => {
        interValRef.current = setInterval(refetchDetail, 300)
    }

    if (isError || isErrorDetail) {
        const status = get(error, 'response.status')
        const statusDetail = get(error, 'response.status')

        if (status === 403 || statusDetail === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    return (
        <div className="site-content">
            <Spin spinning={isLoading}>
                <Row gutter={20}>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 12 }}
                    >
                        <div className="w-100 bg-white mb-3 p-3 rounded-12">
                            <div className="d-flex align-items-center">
                                <Paragraph className="font-weight-bold text-fz-20 mb-0 mr-2 _importing-document-code">
                                    {t('product:label.supplier_info')}
                                </Paragraph>
                            </div>

                            <Row className="mt-4">
                                <Col span={7}>
                                    <span className="font-weight-500 text-fz-16">{t('product:label.supplier_name')}: </span>
                                </Col>
                                <Col
                                    span={12}
                                    className="font-weight-500 text-fz-16"
                                >
                                    <span>{get(detail, 'name', '---')} </span>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col span={7}>
                                    <span className="font-weight-500 text-fz-16">{t('product:label.debt_total')}: </span>
                                </Col>
                                <Col span={12}>
                                    <span className="font-weight-500 text-fz-16">{formatCurrency(infoDebtTotal || 0, {})}</span>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col span={7}>
                                    <span className="font-weight-500 text-fz-16">{t('inventory:inventory')}: </span>
                                </Col>
                                <Col
                                    span={12}
                                    className="font-weight-500 text-fz-16"
                                >
                                    <span>{formatCurrency(infoInventory || 0, {})}</span>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col span={7}>
                                    <span className="font-weight-500 text-fz-16">{t('inventory:sold')}: </span>
                                </Col>
                                <Col
                                    span={12}
                                    className="font-weight-500 text-fz-16"
                                >
                                    <span>{formatCurrency(infoSold || 0, {})}</span>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col span={7}>
                                    <span className="font-weight-500 text-fz-16 text-white">: </span>
                                </Col>
                                <Col
                                    span={12}
                                    className="font-weight-500 text-fz-16 text-white"
                                >
                                    <span></span>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 12 }}
                    >
                        <Spin spinning={loadingDetailDebt}>
                            <div className="w-100 bg-white mb-3 p-3 rounded-12">
                                <Paragraph className="font-weight-bold text-fz-20 mb-0 mr-2 _importing-document-code">
                                    {t('product:label.debt_detail_supplier')}
                                </Paragraph>
                                <Row className="mt-4">
                                    <Col span={11}>
                                        <span className="font-weight-500  text-fz-16">{t('product:label.current_liabilities')}: </span>
                                    </Col>
                                    <Col
                                        span={12}
                                        className="font-weight-500  text-fz-16"
                                    >
                                        <span>{formatCurrency(detailDebtTotal || 0, {})}</span>
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col span={11}>
                                        <span className="font-weight-500  text-fz-16">{t('product:label.value_of_inventory')}: </span>
                                    </Col>
                                    <Col
                                        span={12}
                                        className="font-weight-500  text-fz-16"
                                    >
                                        <span>{formatCurrency(infoInventory || 0, {})}</span>
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col span={11}>
                                        <span className="font-weight-500  text-fz-16">{t('product:label.value_of_goods_sold')}: </span>
                                    </Col>
                                    <Col
                                        span={12}
                                        className="font-weight-500  text-fz-16"
                                    >
                                        <span>{formatCurrency(detailTotalSold || 0, {})}</span>
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col span={11}>
                                        <span className="font-weight-500  text-fz-16">{t('product:label.total_value_of_goods_purchased')}: </span>
                                    </Col>
                                    <Col
                                        span={12}
                                        className="font-weight-500  text-fz-16"
                                    >
                                        <span>{formatCurrency(detail?.total_purchased_amount || 0, {})}</span>
                                    </Col>
                                </Row>
                                <Row className="mt-1">
                                    <Col span={11}>
                                        <span className="font-weight-500  text-fz-16">{t('product:label.total_payments_made')}: </span>
                                    </Col>
                                    <Col
                                        span={12}
                                        className="font-weight-500 text-fz-16"
                                    >
                                        <span>{formatCurrency(detail?.total_paid_amount || 0, {})}</span>
                                    </Col>
                                </Row>
                            </div>
                        </Spin>
                    </Col>
                </Row>
            </Spin>
            <TransactionList
                idSupplier={id}
                refetchDetail={handleReFetch}
                addFilter={addFilter}
                filters={filters}
                reFetchDetailPay={reFetchDetailPay}
            />
        </div>
    )
}

export default SupplierDetail
