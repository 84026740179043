import {useQuery} from 'react-query';

import apiService from './../services/api';

const useImportingDocumentSkusQuery = (id, params) => useQuery(['importing-document', id, params],
    () => apiService.getSkusDocumentImporting(id, params), {
        retry: false,
        enabled: !!id
    });

export default useImportingDocumentSkusQuery;
