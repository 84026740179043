import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';

import api from '@Modules/Order/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';

const disablePagination = {
    page: undefined,
    per_page: undefined,
    current_page: undefined,
};
function AddWarehouseArea({ filters, ids, isSelectAllPage, className }) {
    const [loading, setLoading] = useState(false);
    function handleConfirm() {
        Modal.confirm({
            title: t('order:title.confirm_add_warehouse_area'),
            icon: <ExclamationCircleOutlined />,
            content: t('order:message.confirm_add_warehouse_area'),
            onOk: handleAdd,
            okText: t('btn.ok'),
            cancelText: t('btn.cancel'),
        });
    }
    function handleAdd() {
        setLoading(true);
        api.addWarehouseArea({ ...filters, ...disablePagination, ids: isSelectAllPage ? [] : ids })
            .then(() => {
                notification.success(t('message.process_background', { attribute: t('order:btn.add_warehouse_area') }));
            })
            .catch(() => {
                notification.error(t('message.create_failed', { attribute: t('order:btn.add_warehouse_area') }));
            })
            .finally(() => setLoading(false));
    }

    return (
        <Button className={className} loading={loading} onClick={handleConfirm}>
            {t('order:btn.add_warehouse_area')}
        </Button>
    );
}
export default AddWarehouseArea;
