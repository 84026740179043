import { FormOutlined } from '@ant-design/icons'
import { Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import { auth } from '@Modules/Auth/services'
import PERMISSONS from '@Modules/Auth/services/permissions'
import useUserMerchantsQuery from '@Modules/Users/Hooks/useUserMerchantsQuery'

import { events } from '@System/events'
import { t } from '@System/i18n'

import CustomizedPagination from '../../../App/CustomizedPagination'
import useFilter from '../../../App/Hooks/useFilter'
import TableEmpty from '../../../App/Utilities/TableEmpty'
import { setDocumentTitle, setNavigator } from '../../../App/services'
import { EVENTS } from '../../services/constants'
import FormSearch from './FormSearch'
import UpdateUser from './UpdateUser'

const { Paragraph } = Typography

export default function ListOperation(props) {
    const { history, location } = props
    const { filters, addFilter } = useFilter(history, location)
    const { isLoading: loading, data, refetch } = useUserMerchantsQuery(filters)
    const [visible, setVisible] = useState(false)
    const [userId, setUserId] = useState(-1)

    useEffect(() => {
        setNavigator(t('menu.user_operation'), [
            {
                name: t('menu.operation_management'),
                router: 'config.user-operation',
            },
        ])
        setDocumentTitle(t('menu.user_operation'))

        return events.listen(EVENTS.FETCH_USER_AGAIN, () => {
            addFilter({})
            refetch()
        })

        // eslint-disable-next-line
    }, [])

    function handleSelectUser(userId) {
        setVisible(true)
        setUserId(userId)
    }

    const columns = [
        {
            title: t('user:label.username'),
            dataIndex: ['user', 'username'],
            key: 'username',
            render: text => <strong style={{ fontWeight: 500 }}>{text}</strong>,
        },
        {
            title: t('user:label.full_name'),
            dataIndex: ['user', 'name'],
            key: 'full_name',
        },
        {
            title: t('user:label.email'),
            dataIndex: ['user', 'email'],
            key: 'email',
            width: '20%',
        },
        {
            title: t('order:label.merchant_id'),
            dataIndex: 'merchants',
            key: 'merchants',
            width: '30%',
            render: text => {
                let stt = 1
                return (
                    <Paragraph
                        ellipsis={{
                            rows: 3,
                            expandable: true,
                            symbol: t('btn.expand'),
                        }}
                    >
                        {text?.map(merchant => {
                            return (
                                <span
                                    key={merchant?.id}
                                    style={{ color: merchant?.status ? 'black' : 'gray' }}
                                >
                                    {merchant?.name}
                                    {stt++ < text?.length ? ', ' : ''}
                                </span>
                            )
                        })}
                    </Paragraph>
                )
            },
        },
        {
            title: t('warehouse'),
            dataIndex: 'warehouses',
            key: 'warehouses',
            width: '20%',
            render: text => {
                let stt = 1
                return (
                    <Paragraph
                        ellipsis={{
                            rows: 3,
                            expandable: true,
                            symbol: t('btn.expand'),
                        }}
                    >
                        {text?.map(warehouse => {
                            return (
                                <span
                                    key={warehouse?.id}
                                    style={{ color: warehouse?.status ? 'black' : 'gray' }}
                                >
                                    {warehouse?.name}
                                    {stt++ < text?.length ? ', ' : ''}
                                </span>
                            )
                        })}{' '}
                    </Paragraph>
                )
            },
        },
        {
            title: t('label.market'),
            dataIndex: 'countries',
            key: 'countries',
            width: '20%',
            render: text => {
                let stt = 1

                return (
                    <Paragraph
                        ellipsis={{
                            rows: 3,
                            expandable: true,
                            symbol: t('btn.expand'),
                        }}
                    >
                        {text?.map(country => {
                            return (
                                <span
                                    key={country?.id}
                                    style={{ color: country?.active ? 'black' : 'gray' }}
                                >
                                    {country?.label}
                                    {stt++ < text?.length ? ', ' : ''}
                                </span>
                            )
                        })}
                    </Paragraph>
                )
            },
        },
        {
            title: t('product:label.supplier'),
            dataIndex: 'suppliers',
            key: 'suppliers',
            width: '20%',
            render: text => {
                let stt = 1

                return (
                    <Paragraph
                        ellipsis={{
                            rows: 3,
                            expandable: true,
                            symbol: t('btn.expand'),
                        }}
                    >
                        {text?.map(supplier => {
                            return (
                                <span
                                    key={supplier?.id}
                                
                                >
                                    {supplier?.code}
                                    {stt++ < text?.length ? ', ' : ''}
                                </span>
                            )
                        })}
                    </Paragraph>
                )
            },
        },
        {
            key: 'action',
            dataIndex: 'user',
            render: text => {
                return auth.can(PERMISSONS.USER_MERCHANT_ADD) ? <FormOutlined onClick={() => handleSelectUser(text.id)} /> : ''
            },
        },
    ]
    return (
        <div className="site-content">
            <div className="bg-white">
                <FormSearch
                    filters={filters}
                    addFilter={addFilter}
                />
                <div className="m-3">
                    <Table
                        dataSource={data?.data?.users}
                        columns={columns}
                        loading={loading}
                        locale={{ emptyText: <TableEmpty loading={loading} /> }}
                        pagination={false}
                        rowKey={record => record.user.id}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination
                        pagination={data?.data?.pagination}
                        filters={filters}
                        addFilter={addFilter}
                    />
                </div>
                {visible && auth.can(PERMISSONS.USER_MERCHANT_ADD) && userId && (
                    <UpdateUser
                        setVisible={setVisible}
                        userId={userId}
                    />
                )}
            </div>
        </div>
    )
}
