import { Checkbox, Modal, Select, Table, Tooltip } from 'antd';
import lodash, { get, isEmpty } from 'lodash';
import filter from 'lodash/filter';
import React, { useEffect, useState } from 'react';

import apiService from '@Modules/Services/services/api';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

function UpdateServiceCreateDocument({
    serviceType,
    priceIds = [],
    visible,
    onClose,
    reloadData,
    handleUpdate,
    countryId,
    merchantId
}) {
    const [selectedServiceIds, setSelectedServiceIds] = useState([]); // Format: [<serviceId>, ...]
    const [selectedPriceIds, setSelectedPriceIds] = useState({}); // Format: {<serviceId>: <priceId>, ...}
    const [requiredServiceIds, setRequiredServiceIds] = useState([]);
    const [serviceOrderIds, setServiceOrderIds] = useState([]);
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currency, setCurrency] = useState({});

    useEffect(() => {
        setLoading(true);
        apiService
            .list({ country_id: countryId, hidden_init_service: true, "merchant_id": merchantId })
            .then(res => {
                let services = getVar(res, 'data.services', []);
                setCurrency(getVar(res, 'data.currency', {}));
                services = services.filter(({ service }) => service.type === serviceType);
                setServices(services);
                initSelectedServices(services);
            })
            .finally(() => setLoading(false));
    }, []);

    function initSelectedServices(services) {
        let selectedServiceIds = [];
        let selectedPriceIds = {};
        const requiredServiceIds = [];

        for (const { service, servicePrices } of services) {
            for (const price of servicePrices) {
                if (priceIds.includes(price.id)) {
                    if (!selectedServiceIds.includes(service.id)) {
                        selectedServiceIds.push(service.id);
                    }
                    selectedPriceIds[service.id] = price.id;
                } else {
                    if (service.is_required) {
                        if (!selectedServiceIds.includes(service.id)) {
                            selectedServiceIds.push(service.id);
                        }
                    }
                }
            }
            if (service.is_required) {
                requiredServiceIds.push(service.id);
            }
        }
        setRequiredServiceIds(requiredServiceIds);
        setSelectedServiceIds(selectedServiceIds);
        setSelectedPriceIds(selectedPriceIds);
    }
    useEffect(() => {
        function initSelectedServices() {
            let selectedServiceIds = [];

            for (const { service, servicePrices } of services) {
                for (const price of servicePrices) {
                    if (priceIds.includes(price.id)) {
                        if (!selectedServiceIds.includes(service.id)) {
                            selectedServiceIds.push(service.id);
                        }
                    }
                }
            }
            setServiceOrderIds(selectedServiceIds);
        }

        initSelectedServices();
    }, [priceIds, services]);
    function selectPrice(serviceId, priceId) {
        setSelectedPriceIds(selectedPrices => ({
            ...selectedPrices,
            [serviceId]: priceId,
        }));
    }

    function submit() {
        if (loading) {
            return;
        }

        // if (!selectedServiceIds.length) {
        //     notification.error(t('order:message.unchoose_service'));
        //     return;
        // }

        let priceIds = lodash.values(lodash.pick(selectedPriceIds, selectedServiceIds));
     
        if (!priceIds.length && priceIds.length !== selectedServiceIds.length) {
            notification.error(t('order:message.unchoose_price_level'));
            return;
        }
        setLoading(true);
        handleUpdate(priceIds)
            .then(() => {
                notification.success(t('message.update_success'));
                onClose();
                reloadData(services, priceIds);
            })
            .catch(error => {
                const servicePriceIds = get(error,'response.data.data.service_price_ids')
                const message = []
              
                Object.keys(servicePriceIds).map((key)=>{
                   
                    message.push(t(`order:message.${key}.${serviceType}`))
                })
                
                if(!isEmpty(message)){
                    notification.error(message.join(''))
                }else{
                    processResponseError(error)
                }

                
            })
            .finally(() => setLoading(false));
    }

    const rowSelection = {
        selectedRowKeys: selectedServiceIds,
        onChange: selectedRowKeys => {
            if (selectedRowKeys.length > 0) {
                setSelectedServiceIds(selectedRowKeys);
            } else {
                setSelectedServiceIds(requiredServiceIds);
            }
        },

        renderCell: (checked, record) => {
            const disabledRow = get(record, 'is_required');
            return (
                <>
                    {disabledRow ? (
                        <Tooltip title={t('message.service_required')}>
                            <Checkbox checked={disabledRow} disabled={disabledRow} />
                        </Tooltip>
                    ) : (
                        <Checkbox checked={checked} onChange={() => handleChangeCheckbox(checked, record)} />
                    )}
                </>
            );
        },
    };

    const handleChangeCheckbox = (checked, record) => {
        const { key } = record;
        let newSelectedServiceKeys = [...selectedServiceIds, key];
        if (checked) {
            newSelectedServiceKeys = filter(selectedServiceIds, item => item !== key);
        }
        setSelectedServiceIds(newSelectedServiceKeys);
    };

    let newService = services.filter(
        item => serviceOrderIds.includes(item.service.id) || item.service.status === 'ACTIVE'
    );
    return (
        <Modal
            visible={visible}
            title={t('order:title.select_service')}
            onOk={() => submit()}
            onCancel={() => {
                setSelectedPriceIds([]);
                setSelectedServiceIds([]);
                onClose();
            }}
            okText={t('order:btn.change_service')}
            cancelText={t('btn.cancel')}
            confirmLoading={loading}
            maskClosable={false}
            width={800}
            className={'_modal-update-order-packing-service'}
            cancelButtonProps={{ className: '_modal-update-order-packing-service-btn-cancel' }}
            okButtonProps={{ className: '_modal-update-order-packing-service-btn-ok' }}
        >
            <Table
                loading={loading}
                pagination={false}
                columns={[
                    {
                        className: '_service-name',
                        title: t('order:label.service'),
                        dataIndex: 'service',
                        key: 'service',
                    },
                    {
                        className: '_service-price-level',
                        title: t('label.price_level'),
                        dataIndex: 'prices',
                        key: 'prices',
                        width: '50%',
                    },
                    {
                        className: 'text-nowrap text-right _service-unit-price',
                        title: t('label.unit_price'),
                        dataIndex: 'price',
                        key: 'price',
                        render: text => {
                            return text ? formatCurrency(text, !lodash.isEmpty(currency) ? currency : null) : '';
                        },
                    },
                ]}
                dataSource={newService.map(({ service, servicePrices }) => {
                    let selectedPrice = lodash.find(servicePrices, { id: selectedPriceIds[service.id] });
                    return {
                        key: service.id,
                        service: service.name,
                        is_required: service.is_required,
                        prices: (
                            <Select
                                className="_service-select-service-price"
                                style={{ width: '100%' }}
                                showSearch
                                optionFilterProp="children"
                                disabled={!selectedServiceIds.includes(service.id)}
                                value={selectedPrice && selectedPrice.id}
                                onChange={priceId => selectPrice(service.id, Number(priceId))}
                            >
                                {servicePrices.map(price => (
                                    <Select.Option key={price.id} value={price.id}>
                                        {price.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        ),
                        price: selectedPrice && selectedPrice.price,
                    };
                })}
                rowSelection={rowSelection}
            />
        </Modal>
    );
}

export default UpdateServiceCreateDocument;
