import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useImportSkusMutation = createUseMutation({
    namespace: 'document:sku_inventories_document.import_skus',
    mutation: data => {
        const formData = new FormData();

        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        return apiService.importSkus(formData);
    },
});

export default useImportSkusMutation;
