import React from "react";
import {ExpandContext} from "./configs";

export default function MenuSub({children, name}) {
    return (
        <ExpandContext.Consumer>
            {({showName, showIcon}) => (
                <ul>
                    { name && <li className={`main-menu--list--title ${showIcon ? "text-center" : ""}`}>{ showName ? name : "..." }</li> }
                    { children }
                </ul>
            )}
        </ExpandContext.Consumer>
    );
}
