import { get } from 'lodash';
import React, { useEffect, useState } from 'react';

import useFilter from '@Modules/App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import EditMerchantWallet from '@Modules/Merchant/components/EditMerchantWallet';
import useSellerTransactionQuery from '@Modules/Merchant/services/ServiceSellerTransaction';

import { t } from '@System/i18n';

import List from './List';

const MerchantsDetails = ({ history, location, match }) => {
    const sellerId = match.params.id;
    const { filters, addFilter } = useFilter(history, location);

    const [currency, setCurrency] = useState([]);
    const [pagination, setPagination] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [seller,setSeller] = useState({})
    const { isFetching: loading, data, refetch } = useSellerTransactionQuery(sellerId,filters);

    useEffect(() => {
        setNavigator(t('merchant:title.finance_seller'), [
            {
                name: t('common:breadcrumb.merchants'),
                route: 'config.merchants.list',
            },
            {
                name: t('merchant:title.finance_seller'),
            },
        ]);
        setDocumentTitle(t('merchant:title.finance_seller'));
    }, []);

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setTransactions(get(data, 'data.transactions'));
        setPagination(get(data, 'data.pagination', {}));
        setCurrency(get(data, 'data.currency', {}));
        setSeller(get(data, 'data.merchant'))
    }, [data]);

    return (
        <div>
            <div className="site-content">
                <div className="d-flex justify-content-between">
                    <h3 className="text-fz-18">{t('merchant:title.finance_seller')} {seller && seller.name}</h3>
                    {auth.can(permissions.FINANCE_EDIT_SELLER_WALLET) && <EditMerchantWallet sellerId={sellerId} refetch={refetch}/>}
                </div>
                <List
                    transactions={transactions}
                    loading={loading}
                    currency={currency}
                    pagination={pagination}
                    addFilter={addFilter}
                    filters={filters}
                />
            </div>
        </div>
    );
};

export default MerchantsDetails;
