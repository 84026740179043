import { Form, Input } from 'antd';
import clsx from 'clsx';
import React from 'react';

import styles from './range-input.module.scss';

const RangeInput = ({ className, fromFormItemProps, fromInputProps, toFormItemProps, toInputProps }) => {
    return (
        <Input.Group className={clsx(styles['range-input'], className)} compact>
            <Form.Item {...fromFormItemProps}>
                <Input {...fromInputProps} />
            </Form.Item>
            <Input className="separator" disabled placeholder="→" />
            <Form.Item {...toFormItemProps}>
                <Input {...toInputProps} />
            </Form.Item>
        </Input.Group>
    );
};

export default RangeInput;
