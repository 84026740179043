import React, { useEffect, useRef, useState } from 'react';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { Col, Form, Row, Button, Select, notification, Spin } from 'antd';
import _ from 'lodash';
import { events } from '../../../../../system/events';
import { t } from '../../../../../system/i18n';
import CurrencyInput from '../../../../App/Utilities/CurrencyInput';
import UpdateMerchantOfProduct from '../../../components/UpdateMerchantOfProduct';
import api from '../../../services/api';
import { EVENTS } from '../../../services/constants';
import {catchErrors} from "@Modules/App/services/helpers";

const { Option } = Select;

export default function SkuPrices({ productInfo }) {
    const productId = _.get(productInfo, 'product.id', undefined);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [merchants, setMerchants] = useState([]);
    const [skusInfo, setSkusInfo] = useState([]);
    const [merchant_id, setMerchantId] = useState(undefined);
    const merchantIdRef = useRef(undefined);
    const [currency, setCurrency] = useState(null);
    const [loadingSkuPrices, setLoadingSkuPrices] = useState(false);
    const [loadingVendor, setLoadingVendor] = useState(false);

    useEffect(() => {
        fetchVendor();
        return events.listen(EVENTS.UPDATE_PRODUCT_SUCCESS, () => fetchSkusPrice());

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (merchant_id) fetchSkusPrice();

        // eslint-disable-next-line
    }, [merchant_id]);

    /**
     * Get all vendors sold this products
     */
    function fetchVendor() {
        setLoadingVendor(true);
        api.getVendorByProductId(productId)
            .then(res => {
                const merchants = _.get(res, 'data.merchants', []);
                setMerchants(merchants);
                if (!_.isEmpty(merchants)) {
                    if (!_.find(merchants, ['merchant.id', merchant_id])) {
                        const merchantId = _.get(_.head(merchants), 'merchant.id', undefined);
                        merchantIdRef.current = merchantId;
                        setMerchantId(merchantId);
                        setCurrency(_.get(_.head(merchants), 'currency', {}));
                        form.setFields([{ name: 'merchant_id', value: merchantId }]);
                    }
                }
            })
            .catch(catchErrors(t('common:message.fetch_fail', {attribute: t('merchant:label.info').toLowerCase()})))
            .finally(() => setLoadingVendor(false));
    }

    function fetchSkusPrice() {
        setCurrency(_.get(_.find(merchants, ['merchant.id', merchantIdRef.current]), 'currency', undefined));
        setLoadingSkuPrices(true);
        api.getSkusPriceByMerchantId(productId, { merchant_id: merchantIdRef.current })
            .then(res => {
                const info = _.get(res, 'data.skuPrices', []);
                setSkusInfo(info);
                form.setFields([{ name: 'sku_prices', value: getInitialValue(info) }]);
            })
            .catch(catchErrors(t('common:message.fetch_fail', {attribute: t('product:label.price').toLowerCase()})))
            .finally(() => setLoadingSkuPrices(false));
    }

    function onChangeMerchant(merchant_id) {
        form.setFields([{ name: 'sku_prices', value: [] }]);
        setMerchantId(merchant_id);
        merchantIdRef.current = merchant_id;
    }

    function getInitialValue(skusInfo) {
        let initialValues = [];
        if (skusInfo.length > 0) {
            skusInfo.forEach(sku => {
                initialValues.push({
                    sku_id: _.get(sku, 'id', ''),
                    cost_price: _.get(sku, 'cost_price', ''),
                    wholesale_price: _.get(sku, 'wholesale_price', ''),
                    retail_price: _.get(sku, 'retail_price', ''),
                });
            });
        }
        return initialValues;
    }

    function _onBlur(value, data) {
        form.setFields([{ name: data, value: value === 0 || value ? value : null, errors: '' }]);
    }

    const handleCancel = () => {
        form.setFields([{ name: 'sku_prices', value: getInitialValue(skusInfo) }]);
    };

    const handleUpdateSkus = () => {
        form.validateFields()
            .then(values => {
                setLoading(true);
                api.updateSkuPricesOfProduct(values)
                    .then(res => {
                        notification.success({
                            message: t('common:message.update_success', { attribute: t('common:label.products') }),
                        });
                    })
                    .catch(err => {
                        let { response } = err;
                        if (response.status === 400) {
                            notification.error({
                                message: t('common:message.update_failed', { attribute: t('common:label.products') }),
                            });
                            showErrors(response);
                        } else if (response.status === 403) notification.error({ message: t('common:message.403') });
                        else if (response.status === 404) notification.error({ message: t('common:message.404') });
                        else
                            notification.error({
                                message: t('common:message.update_failed', { attribute: t('common:label.products') }),
                            });
                    })
                    .finally(() => setLoading(false));
            })
            .catch(() => notification.error({ message: t('common:message.update_failed', { attribute: t('common:label.products') }) }));
    };

    function showErrors(response) {
        let errors = _.get(response, 'data.data', {});
        let error_messages = [];
        Object.entries(errors).forEach(entry => {
            const [key, error] = entry;
            for (let prop in error) {
                if (error.hasOwnProperty(prop)) {
                    error_messages.push({
                        name: key,
                        errors: [
                            t(`product:message.${prop.toLowerCase()}`, {
                                attribute: t(`product:label.${key}`),
                            }),
                        ],
                    });
                }
            }
        });
        form.setFields(error_messages);
    }

    if (loadingVendor) {
        return (
            <div className="m-4">
                <div className="form-table-price-product mt-4 mb-4 text-center"><Spin /></div>
            </div>
        );
    }

    return (
        <div className="m-4">
            <div className="form-table-price-product mt-4 mb-4 _price-list">
                {
                    merchants.length === 0
                        ? <div className="text-center pt-4 pb-4">
                            <p className="mb-2">{t("merchant:title.choose_merchant")}</p>
                            <UpdateMerchantOfProduct
                                productId={productId}
                                checkedMerchants={merchants}
                                fetchVendor={fetchVendor}
                            />
                        </div>
                        : <>
                            <Form form={form}>
                                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mb-4">
                                    <Form.Item
                                        className="mb-0 flex-grow-1"
                                        labelAlign="left"
                                        wrapperCol={{ span: 6 }}
                                        label={<strong style={{ weight: 500 }}>{t('product:label.merchant_code1')}</strong>}
                                        name="merchant_id"
                                    >
                                        <Select className="_price-list-merchant-id" showSearch placeholder="Chọn vendor" onChange={onChangeMerchant}>
                                            {merchants.map(item => {
                                                const name = _.get(item, 'merchant.name', '');
                                                const code = _.get(item, 'merchant.code', '');
                                                const id = _.get(item, 'merchant.id', '');
                                                return (
                                                    <Option key={id} value={id}>
                                                        {code + (name ? ' - ' + name : '')}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>

                                    <UpdateMerchantOfProduct
                                        productId={productId}
                                        checkedMerchants={merchants}
                                        fetchVendor={fetchVendor}
                                    />
                                </div>
                                { merchant_id && skusInfo.length > 0 && (
                                    <>
                                        <Row className="table-thead">
                                            <Col span={5}>{t('product:label.sku_code')}</Col>
                                            <Col span={7}>{t('product:label.sku_name')}</Col>
                                            <Col span={4}>{t('product:label.cost_price')}</Col>
                                            <Col span={4}>{t('product:label.wholesale_price')}</Col>
                                            <Col span={4}>{t('product:label.retail_price')}</Col>
                                        </Row>
                                        {loadingSkuPrices ? (
                                            <Row className="table-tbody mt-4" justify={'center'}>
                                                <LoadingOutlined style={{ fontSize: 32 }} />
                                            </Row>
                                        ) : (
                                            <Form.List name="sku_prices">
                                                {fields => (
                                                    <>
                                                        {fields.map((field, index) => {
                                                            return (
                                                                <Form.Item
                                                                    labelCol={{ offset: 0 }}
                                                                    wrapperCol={{ span: 24 }}
                                                                    required={false}
                                                                    key={field.key}
                                                                    className="mb-0"
                                                                >
                                                                    <Row className="table-tbody _price-list-row">
                                                                        <Col span={5} className="_price-list-code">{_.get(skusInfo, index + '.code', '')}</Col>
                                                                        <Col span={7} className="_price-list-name">{_.get(skusInfo, index + '.name', '')}</Col>
                                                                        <Col span={4}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[field.name, 'cost_price']}
                                                                                fieldKey={[field.fieldKey, 'cost_price']}
                                                                                className="mb-0"
                                                                            >
                                                                                <CurrencyInput
                                                                                    currency={currency}
                                                                                    placeholder={t('product:label.cost_price')}
                                                                                    style={{ width: '100%' }}
                                                                                    className="text-right _price-list-cost-price"
                                                                                    onBlur={value =>
                                                                                        _onBlur(value, [
                                                                                            'sku_prices',
                                                                                            index,
                                                                                            'cost_price',
                                                                                        ])
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[field.name, 'wholesale_price']}
                                                                                fieldKey={[field.fieldKey, 'wholesale_price']}
                                                                                className="mb-0"
                                                                            >
                                                                                <CurrencyInput
                                                                                    currency={currency}
                                                                                    placeholder={t('product:label.wholesale_price')}
                                                                                    style={{ width: '100%' }}
                                                                                    className="text-right _price-list-wholesale-price"
                                                                                    onBlur={value =>
                                                                                        _onBlur(value, [
                                                                                            'sku_prices',
                                                                                            index,
                                                                                            'wholesale_price',
                                                                                        ])
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span={4}>
                                                                            <Form.Item
                                                                                {...field}
                                                                                name={[field.name, 'retail_price']}
                                                                                fieldKey={[field.fieldKey, 'retail_price']}
                                                                                className="mb-0"
                                                                            >
                                                                                <CurrencyInput

                                                                                    currency={currency}
                                                                                    placeholder={t('product:label.retail_price')}
                                                                                    style={{ width: '100%' }}
                                                                                    className="text-right _price-list-retail-price"
                                                                                    onBlur={value =>
                                                                                        _onBlur(value, [
                                                                                            'sku_prices',
                                                                                            index,
                                                                                            'retail_price',
                                                                                        ])
                                                                                    }
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Item>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </Form.List>
                                        )}
                                    </>
                                )}
                            </Form>
                            {skusInfo.length > 0 && !loadingSkuPrices && (
                                <div className="d-flex justify-content-end mt-4">
                                    <Button onClick={handleCancel} className="_price-list-btn-reset">{t('btn.refresh')}</Button>
                                    <Button type="primary" className="ml-2 _price-list-btn-update" loading={loading} onClick={handleUpdateSkus}>
                                        <CheckOutlined />
                                        {t('btn.update')}
                                    </Button>
                                </div>
                            )}
                        </>
                }
            </div>
        </div>
    );
}
