import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from './../services/api';

const usePurchasingServicesQuery = () =>
    createUseQuery({
        name: 'purchasing-services',
        query: () => apiService.purchasingServices(),
    });

export default usePurchasingServicesQuery;
