import React, {useState} from 'react';
import {get,isEmpty} from 'lodash';
import {Button, Form, Modal, Input, notification} from "antd";

import api from "../../services/api";
import {t} from "../../../../system/i18n";
import {EVENTS} from "../../services/constants";
import {events} from "../../../../system/events";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 16},
};

const VALIDATE_UPDATE_WAREHOUSE_AREA = {
    code: [{required: true}],
    name: [{required: true}],
};

function UpdateWarehouseArea(props) {
    const {visible, setVisible, warehouseAreaInfo: {warehouseArea}, warehouseId} = props;
    const [loading, setLoading] = useState(false);
    const validateMessages = useValidatedMessages();
    const action = isEmpty(warehouseArea) ? "create" : "update";
    const [form] = Form.useForm();
    const initialValues = {
        code: get(warehouseArea, "code"),
        name: get(warehouseArea, "name"),
    }

    function handleOk() {
        if (action === "update") {
            return updateWarehouseArea();
        } else {
            return createWarehouseArea();
        }
    }

    function createWarehouseArea() {
        form.validateFields()
            .then(values => {
                setLoading(true);
                api.createWarehouseArea(warehouseId, values).then(res => {
                    notification.success({message: t('common:message.create_success', {attribute: t("common:label.warehouse_area")})});
                    handleCancel();
                    events.dispatch(EVENTS.FETCH_WAREHOUSE_AREAS_AGAIN, {});
                }).catch(err => {
                    let {response} = err;
                    if (response.status === 400) {
                        notification.error({message: t('common:message.create_failed', {attribute: t("common:label.warehouse_area")})});
                        showErrors(response);
                    } else if (response.status === 403)
                        notification.error({message: t('common:message.403')});
                    else if (response.status === 404)
                        notification.error({message: t('common:message.404')});
                    else
                        notification.error({message: t('common:message.create_failed', {attribute: t("common:label.warehouse_area")})});
                }).finally(() => setLoading(false))
            });
    }

    function updateWarehouseArea() {
        form.validateFields()
            .then(values => {
                setLoading(true);
                api.updateWarehouseArea(warehouseArea.id, values).then(res => {
                    notification.success({message: t('common:message.update_success', {attribute: t("common:label.warehouse_area")})});
                    handleCancel();
                    events.dispatch(EVENTS.FETCH_WAREHOUSE_AREAS_AGAIN, {});
                }).catch(err => {
                    let {response} = err;
                    if (response.status === 400) {
                        notification.error({message: t('common:message.update_failed', {attribute: t("common:label.warehouse_area")})});
                        showErrors(response);
                    } else if (response.status === 403)
                        notification.error({message: t('common:message.403')});
                    else if (response.status === 404)
                        notification.error({message: t('common:message.404')});
                    else
                        notification.error({message: t('common:message.update_failed', {attribute: t("common:label.warehouse_area")})});
                }).finally(() => setLoading(false))
            });
    }

    function showErrors(response) {
        let errors = get(response, "data.data", {});
        let error_messages = [];
        Object.entries(errors).forEach(entry => {
            const [key, error] = entry;
            for (let prop in error) {
                if (error.hasOwnProperty(prop)) {
                    error_messages.push({
                        name: key,
                        errors: [t(`warehouseArea:message.${prop.toLowerCase()}`, {
                            attribute: t(`warehouseArea:label.${key}`)
                        })]
                    });
                }
            }

        });

        form.setFields(error_messages);
    }

    function handleCancel() {
        setVisible(false);
    }

    return (
        <div>

            {
                visible &&
                <Modal
                    title={t("warehouseArea:title.warehouse_area_" + action,
                        {code: get(warehouseArea, "code", "")}
                    ).toUpperCase()}
                    visible={visible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                        <div key="create_package" className="d-flex justify-content-end">
                            <Button
                                className="text-uppercase"
                                type="primary"
                                loading={loading}
                                onClick={handleOk}
                            >
                                {t("common:btn." + action)}
                            </Button>
                        </div>
                    ]}
                >
                    <Form
                        {...layout}
                        labelAlign="left"
                        form={form}
                        validateMessages={validateMessages}
                        initialValues={initialValues}
                    >
                        <Form.Item
                            label={t("warehouseArea:label.code")}
                            name="code"
                            rules={VALIDATE_UPDATE_WAREHOUSE_AREA.code}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={t("warehouseArea:label.name")}
                            name="name"
                            rules={VALIDATE_UPDATE_WAREHOUSE_AREA.name}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={t("warehouseArea:label.note")}
                            name="description"
                            rules={VALIDATE_UPDATE_WAREHOUSE_AREA.description}
                        >
                            <Input.TextArea autoSize={{minRows: 2, maxRows: 4}}/>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </div>
    );
}

export default UpdateWarehouseArea;
