import React, {useState, useEffect} from 'react';
import {Form, Row, Col, Button, Input} from 'antd';
import {t} from "../../../../system/i18n";
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";

function FormSearchImportHistoryItems({addFilter, filters}) {
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const {
        sku_code,
        sku_name,
        freight_bill,
    } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({...input, [name]: value});
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }


    function _onSubmit() {
        addFilter(input);
    }

    function _onReset() {
        addFilter({});
    }

    return (
        <Form
            className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray"
            form={form}
            layout="vertical"
        >
            <Row gutter={20}>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-3"
                        label={t("product:label.sku_code")}
                    >
                        <Input
                            name="sku_code"
                            value={sku_code}
                            placeholder={t("product:placeholder.sku_code")}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-0"
                        label={t("product:label.sku_name")}
                    >
                        <Input
                            name="sku_name"
                            value={sku_name}
                            placeholder={t("product:placeholder.sku_name")}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item
                        className="mb-0"
                        label={t("product:label.freight_bill")}
                    >
                        <Input
                            name="freight_bill"
                            value={freight_bill}
                            placeholder={t("product:placeholder.freight_bill")}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}} className="pt-4 text-right" style={{marginTop: "6px"}}>
                    <Button type="primary" onClick={_onSubmit}><SearchOutlined/> {t("common:btn.search")}</Button>
                    <Button className="ml-2" onClick={_onReset}><UndoOutlined/> {t("common:btn.reset")}</Button>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearchImportHistoryItems;
