import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";
import axios from "axios";

class ServiceAccounts extends BaseServiceData {

    runApi() {
        return api.getUsers()
            .then(res => _.get(res, "data.users", []))
            .catch(err => {
                if (!axios.isCancel(err)) {
                    notification.error({message: t("common:message.fetch_fail", {attribute: t("label.market").toLowerCase()})});
                    return Promise.reject(err);
                }
            });
    }
}

export default new ServiceAccounts();
