import { notification } from 'antd';
import isFunction from 'lodash/isFunction';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import apiService from '../services/api';
import { t } from '../../../system/i18n';
import { notifyErrorFromServer } from '../../../system/support/helpers';
import {LIST_TYPE_IMPORTING_DOCUMENT} from "@Modules/Document/services/constants";

const useUpdateImportDocumentMutation = payload => {
    if (!payload) {
        payload = {};
    }

    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit({
        ...payload,
        onSuccess: ({ data }) => {
            if (isFunction(payload?.onSuccess)) {
                payload.onSuccess(data?.document);
            }
    
            notification.success({
                message: t('document:update.success'),
                duration: 6
            });
        },
        onError: error => {
            if (isFunction(payload?.onError)) {
                payload.onError(error);
            }
    
            if (payload.notifyWhenError) {
                notifyErrorFromServer(error, t('document:update.failed'), 'document:update.errors');
            }
        }
    }, ['id', 'notifyWhenError']);

    return useMutation(data => {
        return payload?.type === LIST_TYPE_IMPORTING_DOCUMENT.RETURN_GOODS ? apiService.updateImportingReturnOrderDocument(payload?.id, data)
            : apiService.updateImportingDocument(payload?.id, data)
    }, formattedConfig);
};

export default useUpdateImportDocumentMutation;
