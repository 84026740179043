import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import useFilter from '@Modules/App/Hooks/useFilter'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import useListPurchasingPackageQuery from '@Modules/PurchasingPackage/Hooks/useListPurchasingPackageQuery'
import ImportConfirmFinanceStatus from '@Modules/PurchasingPackage/components/ImportConfirmFinanceStatus'
import FormSearch from '@Modules/PurchasingPackage/screens/List/FormSearch'
import List from '@Modules/PurchasingPackage/screens/List/List'
import { auth } from '@Modules/Auth/services'
import { t } from '@System/i18n'
import { url } from '@System/routing'
import permissions from '@Modules/Auth/services/permissions'

export default function ListPurchasingPackage({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, {})
    const [pagination, setPagination] = useState({})
    const [purchasingPackages, setPurchasingPackages] = useState([])
    const { data, isFetching, refetch, isError, error } = useListPurchasingPackageQuery(filters)

    useEffect(() => {
        setNavigator(t('title.purchasing_packages'), [
            {
                name: t('breadcrumb.purchasing_packages'),
            },
        ])
        setDocumentTitle(t('breadcrumb.purchasing_packages'))
    }, [])

    useEffect(() => {
        refetch()
    }, [filters, refetch])

    useEffect(() => {
        setPurchasingPackages(get(data, 'data.purchasing_packages', []))
        setPagination(get(data, 'data.pagination', {}))
    }, [data])

    if (isError) {
        const status = error.response.status
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.purchasing_packages')}</h3>
                <div>
                    <ImportConfirmFinanceStatus onSuccessImport={refetch} />
                    {auth.can(permissions.ADMIN_PACKAGE_CREATE) && <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        onClick={() => url.redirectTo('purchasing_packages.create')}
                        className='ml-3'
                    >
                        {t('packaging:btn.purchase_create')}
                    </Button>}
                </div>
            </div>

            <FormSearch
                onSearch={addFilter}
                filters={filters}
                loading={isFetching}
            />
            <List
                purchasingPackages={purchasingPackages}
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
                loading={isFetching}
            />
        </div>
    )
}
