import { Col, List, Row, Space, Typography } from 'antd'
import { clone, cloneDeep, find, flatMap, get, groupBy, join, map, sumBy, uniq } from 'lodash'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import Box from '@Modules/App/Box'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'

import { t } from '@System/i18n'
import { url } from '@System/routing'

import styles from '../scan-packing-purchasing-package.module.scss'

const { Title } = Typography

export const mergeDataSkus = data => {
    let newData = []

    data.forEach(item => {
        let existing = newData.filter(function (v, i) {
            return v.sku_id === item.sku_id
        })
        if (existing.length) {
            let existingIndex = newData.indexOf(existing[0])
            newData[existingIndex].serial_numbers = uniq(newData[existingIndex]?.serial_numbers?.concat(item.serial_numbers))
        } else {
            if (typeof item.serial_numbers === 'string') item.serial_numbers = [item?.serial_numbers]
            newData.push(item)
        }
    })

    const unique = groupBy(data, i => i.sku_id)

    const result = Object.keys(unique).map(key => {
        const first = unique[key][0]
        return {
            ...first,
            quantity: sumBy(unique[key], i => i.quantity),
        }
    })

    return result
}

function ProductList({ data, storeData }) {
    const [orderStocks, setOrderStocks] = useState([])
    const [dataOrderSkus, setDataOrderSkus] = useState([])

    useEffect(() => {
        setOrderStocks(flatMap(get(storeData, 'orderStocks', []), item => item?.order_stocks))
    }, [storeData])

    useEffect(() => {

        const newData = cloneDeep(orderStocks)

        setDataOrderSkus(mergeDataSkus(newData))
    }, [orderStocks])

    return (
        <Box className={styles.aside}>
            <div className="d-flex justify-content-between border-bottom pb-3 align-items-center">
                <Space>
                    <Title level={3}>{t('order:title.list_products')}</Title>
                    <span className="number-circle">{data.length}</span>
                </Space>
            </div>
            <List
                className="ml-3 mr-3 _scan-order-products-info"
                header={
                    <Row className="d-flex justify-content-between text-secondary">
                        <Col span={8}>{t('order:label.sku_info')}</Col>
                        <Col
                            span={8}
                            className="text-right"
                        >
                            {t('label.serial_number')}
                        </Col>
                        <Col
                            span={8}
                            className="text-right"
                        >
                            {t('order:label.quantity')}
                        </Col>
                    </Row>
                }
                footer={
                    data.length > 0 ? (
                        <div className="d-flex justify-content-between">
                            <strong>Tổng</strong>
                            <strong className="_scan-order-products-total">{sumBy(data, 'quantity')}</strong>
                        </div>
                    ) : (
                        false
                    )
                }
                dataSource={dataOrderSkus}
                renderItem={item => {
                    // const serial = get(find(dataOrderSkus, ['sku_id', item.id]), 'serial_numbers')

                    return (
                        <List.Item key={item.sku_id}>
                            <Row className="w-100">
                                <Col span={8}>
                                    <Link to={url.to('products.detail', { id: item?.sku?.product_id })}>
                                        <h4 className="ant-list-item-meta-title font-weight-500"> {item?.sku?.code}</h4>
                                    </Link>
                                    <div className="ant-list-item-meta-description">{item?.sku?.name}</div>
                                </Col>
                                <Col
                                    span={8}
                                    className="text-right"
                                >
                                    <div>{join(item?.serial_numbers || [], ' ,')}</div>
                                </Col>
                                <Col
                                    span={8}
                                    className="text-right"
                                >
                                    <div className="_scan-order-product-quantity">{item.quantity}</div>
                                </Col>
                            </Row>
                        </List.Item>
                    )
                }}
                locale={{ emptyText: <TableEmpty className="_scan-order-products-no-data" /> }}
            />
        </Box>
    )
}

export default ProductList
