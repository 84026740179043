export const EVENTS = {
    IMPORT_PRODUCT_FROM_EXCEL: "PRODUCT.IMPORT_PRODUCT_FROM_EXCEL",
    RELOAD_PRODUCT_LIST: "PRODUCT.RELOAD_PRODUCT_LIST"
};
export const PACKAGE_STATUS =  {
    "WAITING_EXPORT": "WAITING_EXPORT",
    "SHIPPING" : "SHIPPING",
    "DELIVERED": "DELIVERED",
    "CANCELED": "CANCELED"
};
export const HISTORY_OF_PRODUCT_VARIANT_COLOR = {
    DECREASE: "#FF4559",
    INCREASE:"#09B2AA"
};

export const VARIAN_STATUS = {
    ON_SELL: 'ON_SELL',
    STOP_SELLING: 'STOP_SELLING',
};