import React from "react";
export default function FlagCountryComponent(icon) {
    const VietNam = () => (
        <svg t="1617960224643" className="icon" viewBox="0 0 1560 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="7693" width="24" height="24">
            <path
                d="M141.409524 0h1275.123809C1496.990476 0 1560.380952 63.390476 1560.380952 138.971429v746.057142c0 78.019048-63.390476 138.971429-141.409523 138.971429H141.409524C63.390476 1024 0 960.609524 0 885.028571V138.971429C0 63.390476 63.390476 0 141.409524 0z"
                fill="#CE1126" p-id="7694"></path>
            <path
                d="M780.190476 124.342857l95.085714 297.447619h312.076191l-251.12381 180.419048 95.085715 297.447619L780.190476 716.8l-251.123809 182.857143 95.085714-297.447619-251.12381-180.419048h312.076191z"
                fill="#FCD116" p-id="7695"></path>
        </svg>
    );

    const China = () => (
        <svg t="1617960534639" className="icon" viewBox="0 0 1560 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="8624" width="24" height="24">
            <path
                d="M141.409524 0h1275.123809C1496.990476 0 1560.380952 63.390476 1560.380952 138.971429v746.057142c0 78.019048-63.390476 138.971429-141.409523 138.971429H141.409524C63.390476 1024 0 960.609524 0 885.028571V138.971429C0 63.390476 63.390476 0 141.409524 0z"
                fill="#DE2910" p-id="8625"></path>
            <path d="M1560.380952 129.219048v755.809523V138.971429v-9.752381z" fill="#57A863" p-id="8626"></path>
            <path
                d="M275.504762 126.780952l29.257143 82.895238h87.771428l-73.142857 53.638096 29.257143 82.895238-73.142857-51.2-70.704762 51.2 26.819048-82.895238-70.704762-53.638096h87.771428zM472.990476 46.32381l4.876191 31.695238 31.695238 7.314285-29.257143 12.190477 2.438095 34.133333-21.942857-24.380953-29.257143 14.628572 14.628572-29.257143-21.942858-24.380952 31.695239 7.314285zM543.695238 151.161905l17.066667 26.819047 31.695238-4.87619-21.942857 24.380952 17.066666 26.819048-29.257142-12.190476-21.942858 24.380952 2.438096-34.133333-29.257143-12.190476 31.695238-7.314286zM533.942857 268.190476l9.752381 31.695238h31.695238l-26.819047 19.504762 9.752381 31.695238-24.380953-19.504762-26.819047 19.504762 9.75238-31.695238-24.380952-21.942857 31.695238 2.438095zM470.552381 360.838095l-4.876191 31.695238 31.695239 12.190477-31.695239 9.75238-2.438095 34.133334-17.066666-29.257143-31.695239 9.752381 21.942858-26.819048-17.066667-26.819047 29.257143 9.752381z"
                fill="#FFDE00" p-id="8627"></path>
        </svg>
    );

    const English = () => (
        <svg t="1632815011007" className="icon" viewBox="0 0 1560 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="1947" width="24" height="24">
            <path
                d="M1560.380952 877.714286V146.285714L926.47619 512zM1487.238095 19.504762c-19.504762-12.190476-43.885714-19.504762-70.704762-19.504762H141.409524c-24.380952 0-48.761905 7.314286-70.704762 19.504762L780.190476 426.666667 1487.238095 19.504762zM70.704762 1004.495238c21.942857 12.190476 46.32381 19.504762 70.704762 19.504762h1277.561905c24.380952 0 48.761905-7.314286 70.704761-17.066667L780.190476 597.333333 70.704762 1004.495238zM0 146.285714v731.428572l633.904762-365.714286z"
                fill="#00237A" p-id="1948"></path>
            <path
                d="M658.285714 497.371429L2.438095 119.466667c-2.438095 4.87619-2.438095 12.190476-2.438095 19.504762V146.285714l633.904762 365.714286 24.380952-14.628571zM902.095238 526.628571l655.847619 380.342858c0-7.314286 2.438095-12.190476 2.438095-19.504762V877.714286L926.47619 512l-24.380952 14.628571zM1543.314286 953.295238L853.333333 553.447619l-73.142857 41.447619 709.485714 409.6c21.942857-12.190476 41.447619-29.257143 53.638096-51.2zM17.066667 70.704762L707.047619 470.552381l73.142857-41.447619L70.704762 19.504762C48.761905 31.695238 31.695238 48.761905 17.066667 70.704762z"
                fill="#FFFFFF" p-id="1949"></path>
            <path
                d="M17.066667 70.704762c-7.314286 14.628571-12.190476 31.695238-14.628572 48.761905L658.285714 497.371429l48.761905-29.257143L17.066667 70.704762zM853.333333 553.447619L1543.314286 950.857143c7.314286-14.628571 12.190476-29.257143 14.628571-46.32381L902.095238 526.628571l-48.761905 26.819048z"
                fill="#CF142B" p-id="1950"></path>
            <path
                d="M755.809524 582.704762L48.761905 989.866667l21.942857 14.628571L780.190476 597.333333l-24.380952-14.628571zM17.066667 953.295238L707.047619 553.447619 633.904762 512 0 877.714286v7.314285c0 24.380952 7.314286 46.32381 17.066667 68.266667zM804.571429 441.295238L1511.619048 34.133333l-21.942858-14.628571L780.190476 426.666667l24.380953 14.628571zM1543.314286 73.142857L853.333333 470.552381l73.142857 41.447619L1560.380952 146.285714v-7.314285c0-24.380952-7.314286-46.32381-17.066666-65.828572z"
                fill="#FFFFFF" p-id="1951"></path>
            <path
                d="M17.066667 953.295238c7.314286 14.628571 19.504762 26.819048 31.695238 39.009524L755.809524 585.142857l-48.761905-29.257143L17.066667 953.295238zM780.190476 512l-24.380952 41.447619-24.380953-12.190476-24.380952 12.190476 48.761905 29.257143 48.761905-29.257143z"
                fill="#CF142B" p-id="1952"></path>
            <path
                d="M755.809524 553.447619l24.380952-41.447619-48.761905 29.257143zM1543.314286 73.142857c-7.314286-14.628571-19.504762-26.819048-31.695238-39.009524L804.571429 441.295238l48.761904 29.257143L1543.314286 73.142857z"
                fill="#CF142B" p-id="1953"></path>
            <path
                d="M780.190476 512l24.380953-41.447619 24.380952 12.190476 24.380952-12.190476-48.761904-29.257143-48.761905 29.257143z"
                fill="#CF142B" p-id="1954"></path>
            <path d="M780.190476 512l48.761905-29.257143-24.380952-12.190476z" fill="#CF142B" p-id="1955"></path>
            <path
                d="M1560.380952 390.095238H902.095238V0H658.285714v390.095238H0v243.809524h658.285714v390.095238h243.809524V633.904762h658.285714z"
                fill="#FFFFFF" p-id="1956"></path>
            <path
                d="M1560.380952 438.857143H853.333333V0h-146.285714v438.857143H0v146.285714h707.047619v438.857143h146.285714V585.142857h707.047619z"
                fill="#CF142B" p-id="1957"></path>
        </svg>
    )

    const Indonesia = () => (
        <svg t="1632815066003" className="icon" viewBox="0 0 1560 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="2208" width="24" height="24">
            <path
                d="M141.409524 0h1275.123809C1496.990476 0 1560.380952 63.390476 1560.380952 138.971429v746.057142c0 78.019048-63.390476 138.971429-141.409523 138.971429H141.409524C63.390476 1024 0 960.609524 0 885.028571V138.971429C0 63.390476 63.390476 0 141.409524 0z"
                fill="#CC1126" p-id="2209"></path>
            <path
                d="M0 885.028571C0 960.609524 63.390476 1024 141.409524 1024h1277.561905c78.019048 0 141.409524-60.952381 141.409523-138.971429V512H0v373.028571z"
                fill="#FFFFFF" p-id="2210"></path>
        </svg>
    )

    const Thailand = () => (
        <svg t="1632815096040" className="icon" viewBox="0 0 1560 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="2441" width="24" height="24">
            <path
                d="M141.409524 0h1275.123809C1496.990476 0 1560.380952 63.390476 1560.380952 138.971429v746.057142c0 78.019048-63.390476 138.971429-141.409523 138.971429H141.409524C63.390476 1024 0 960.609524 0 885.028571V138.971429C0 63.390476 63.390476 0 141.409524 0z"
                fill="#CF0F15" p-id="2442"></path>
            <path d="M0 151.161905h1560.380952v724.114285H0z" fill="#FFFFFF" p-id="2443"></path>
            <path d="M0 307.2h1560.380952v409.6H0z" fill="#00247D" p-id="2444"></path>
        </svg>
    )


    const iconComponent = {
        vi: VietNam,
        zh: China,
        en: English,
        th: Thailand,
        id: Indonesia
    };
    return iconComponent[icon]
}
