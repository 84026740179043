import Tracker from "@openreplay/tracker";
import trackerAssist from "@openreplay/tracker-assist";
import {trans} from "@System/i18n";

const tracker = new Tracker({
    projectKey: "HkoIBlIH02LFHeKFYhfb",
    ingestPoint: "https://replay.gobiz.vn/ingest",
});

tracker.use(trackerAssist({
    confirmText: trans('common:welcome')
}));

export {tracker};