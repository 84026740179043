import clsx from 'clsx';
import React from 'react';

import styles from './table-count.module.scss';

const TableCount = ({ className, text }) => {
    return <span className={clsx(styles['table-count'], className)}>{text}</span>;
};

export default TableCount;
