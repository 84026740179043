import { ExclamationCircleOutlined } from '@ant-design/icons'
import { t } from '@System/i18n'
import { url } from '@System/routing'
import { Modal } from 'antd'
import React from 'react'

const ModalContinuePickup = ({ setVisibleWarning, processPicking }) => {

    const handleContinue = () => {
        url.redirectTo('orders.picking-up', {
            sessionId: processPicking.id,
        });
    }

    return (
        <Modal
            title={<><ExclamationCircleOutlined className='mr-2' style={{ color: '#faad14' }} />{t('title.notice')}</>}
            visible={true}
            onCancel={() => setVisibleWarning(false)}
            okText={t('order:btn.pickup_continue')}
            closable={true}
            cancelText={t('btn.close')}
            onOk={handleContinue}
            className='modal-pickup-continue'
        >
            <h3>{t('order:message.pickup_continue_question')}</h3>
        </Modal>
    )
}

export default ModalContinuePickup