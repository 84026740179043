import { GiftOutlined, MoreOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, Modal, Space } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';

import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import useCancelFreightBillMutation from '@Modules/Order/Hooks/useCancelFreightBillMutation';
import AddStaffForOrder from '@Modules/Order/components/AddStaffForOrder';
import AddWarehouseArea from '@Modules/Order/components/AddWarehouseArea';
import ExportOrderPrepareByShippingPartner from '@Modules/Order/components/ExportData/ExportOrderPrepareByShippingPartner';
import ModalCreateFreightBill from '@Modules/Order/components/ModalCreateFreightBill';
import RemoveWarehouseArea from '@Modules/Order/components/RemoveWarehouseArea';
import ShippingStamp from '@Modules/Order/components/ShippingStamp';
import PackingSlipPrint from '@Modules/Order/screens/PrepareOrder/PackingSlipPrint';
import api from '@Modules/Order/services/api';
import { EVENTS, ORDER_PACKING_STATUS } from '@Modules/Order/services/constants';

import processResponseError from '@System/api/processResponseError';
import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { getVar } from '@System/support/helpers';

import useCreateFreightBillMutation from '../../Hooks/useCreateFreightBillMutation';
import useDownloadItemListQuery from '../../Hooks/useDownloadItemListQuery';
import ShippingPartnerPrint from './ShippingPartnerPrint';
import UpdateShippingPartner from './UpdateShippingPartner';

function PerformAction({
    className,
    filters,
    freightBills,
    ids,
    isSelectAllPage,
    orderPackings,
    pagination,
    setSelectedRowKeys,
}) {
    const { isLoading: creating, mutate: createFreightBill } = useCreateFreightBillMutation();
    const { isLoading: cancelling, mutate: cancelFreightBill } = useCancelFreightBillMutation();
    const { isLoading: downloading, refetch: downloadItemList } = useDownloadItemListQuery({
        ...filters,
        ids: isSelectAllPage ? [] : ids,
    });
    const shippingStampRef = useRef();
    const [visibleModal, setVisibleModal] = useState(false);
    const [loadingTracking, setLoadingTracking] = useState(false);
    const [loadingPriority, setLoadingPriority] = useState(false);
    const [orderPackingsError, setOrderPackingsError] = useState([]);
    const [hasShopeeProvider, setHasShopeeProvider] = useState(false);
    const [pickupType, setPickupType] = useState('pickup');
    const [visibleDropdown, setVisibleDropdown] = useState(true);

    const handleVisibleModalCreate = async () => {
        const params = {
            ...filters,
            ids: isSelectAllPage ? [] : ids,
        };

        let order_packings_error = [];
        setLoadingTracking(true);
        let hasShopeeProvider = false;
        try {
            const res = await api.getOrderPackingBeforeTrackingNo(params);
            let data = await get(res, 'data.order_packings');
            hasShopeeProvider = await getVar(res, 'data.has_shopee_provider', false);
            order_packings_error = [...data];
        } catch (error) {
            processResponseError(error);
        } finally {
            setLoadingTracking(false);
        }
        setHasShopeeProvider(hasShopeeProvider);
        setOrderPackingsError(order_packings_error);
        if (!hasShopeeProvider) {
            if (isEmpty(order_packings_error)) {
                createFreightBill({
                    ...filters,
                    ids: isSelectAllPage ? [] : ids,
                });
            } else {
                setVisibleModal(true);
            }
        } else {
            setVisibleDropdown(true);
        }
    };

    const handleCancelFreightBillButtonClick = () => {
        if (!auth.can(permissions.ORDER_CANCEL_FREIGHT_BILL)) {
            return;
        }

        if (ids.length) {
            Modal.confirm({
                title: t('order:title.confirm_cancel_freight_bill', {
                    count: isSelectAllPage
                        ? t('table_selection.selected_all_page', {
                              count: pagination?.total,
                              type: t('order:requirement_prepare_orders').toLowerCase(),
                          })
                        : ids.length + ' ' + t('order:requirement_prepare_orders').toLowerCase(),
                }),
                cancelText: t('btn.cancel'),
                okText: t('btn.ok'),
                onOk: () => {
                    cancelFreightBill({
                        ...filters,
                        ids: isSelectAllPage ? [] : ids,
                    });
                },
            });
        } else {
            notification.error(t('order:cancel_freight_bill.errors.ids.required'));
        }
    };

    const handleDownloadItemListButtonClick = () => {
        if (ids.length) {
            downloadItemList();
        } else {
            notification.error(t('order:export_product_list.errors.ids.required'));
        }
    };

    const handleMarkPriority = () => {
        setLoadingPriority(true);
        api.addPriority({
            ...filters,
            ids: isSelectAllPage ? [] : ids,
        })
            .then(res => {
                events.dispatch(EVENTS.RELOAD_ORDER_PREPARE_LIST, {});
                notification.success(t('order:message.order_priority_success'));
            })
            .catch(error => {
                notification.error(t('order:message.order_priority_fail'));
            })
            .finally(() => {
                setLoadingPriority(false);
            });
    };

    const selectedAction = (
        <Menu className="menu-dropdown-action _create-order">
            <Menu.Item key={1} className="mb-3 mb-xl-0">
                <Button
                    className="_prepare-order-btn-export-product btn-menu-item"
                    loading={downloading}
                    onClick={handleDownloadItemListButtonClick}
                >
                    {t('order:btn.export_product')}
                </Button>
            </Menu.Item>

            {(filters.order_status === ORDER_PACKING_STATUS.WAITING_PROCESSING.key || filters?.error_tracking) &&
                auth.can(permissions.ORDER_UPDATE_CARRIER) && (
                    <Menu.Item key={2} className="mb-3 mb-xl-0">
                        <UpdateShippingPartner
                            ids={ids}
                            isSelectAllPage={isSelectAllPage}
                            orderPackings={orderPackings}
                            filters={filters}
                            setSelectedRowKeys={setSelectedRowKeys}
                        />
                    </Menu.Item>
                )}
            {([ORDER_PACKING_STATUS.WAITING_PROCESSING.key, ORDER_PACKING_STATUS.WAITING_DELIVERY.key, ORDER_PACKING_STATUS.WAITING_PICKING.key].includes(
                filters?.order_status
            ) ||
                filters?.error_tracking) && (
                <Menu.Item key={3} className="mb-3 mb-xl-0">
                    <PackingSlipPrint orderPackings={orderPackings} />
                </Menu.Item>
            )}

            {!(
                [ORDER_PACKING_STATUS.WAITING_PROCESSING.key].includes(filters?.order_status) || filters?.error_tracking
            ) &&
                auth.can(permissions.OPERATION_PRINT_BILL) && (
                    <Menu.Item key={10} className="mb-3 mb-xl-0 4">
                        <ShippingPartnerPrint
                            shippingPartnerId={filters?.shipping_partner_id}
                            freightBills={freightBills}
                            orderPackings={orderPackings}
                            shippingStampRef={shippingStampRef}
                            storeId={filters?.store_id}
                        />
                    </Menu.Item>
                )}

            {[ORDER_PACKING_STATUS.WAITING_PICKING.key, ORDER_PACKING_STATUS.WAITING_PACKING.key].includes(
                filters?.order_status
            ) &&
                auth.can(permissions.ORDER_CANCEL_FREIGHT_BILL) && (
                    <Menu.Item key={6} className="mb-3 mb-xl-0">
                        <Button
                            loading={cancelling}
                            className="_prepare-order-btn-create-freight-bill btn-menu-item"
                            onClick={handleCancelFreightBillButtonClick}
                        >
                            {t('order:btn.cancel_freight_bill')}
                        </Button>
                    </Menu.Item>
                )}
            {[ORDER_PACKING_STATUS.WAITING_PICKING.key, ORDER_PACKING_STATUS.WAITING_PROCESSING.key].includes(
                filters?.order_status
            ) && (
                <>
                    <Menu.Item key={7} className="mb-3 mb-xl-0">
                        <RemoveWarehouseArea
                            filters={filters}
                            ids={ids}
                            isSelectAllPage={isSelectAllPage}
                            className="_prepare-order-btn-remove-warehouse-area btn-menu-item"
                        />
                    </Menu.Item>
                    <Menu.Item key={8} className="mb-3 mb-xl-0">
                        <AddWarehouseArea
                            filters={filters}
                            ids={ids}
                            isSelectAllPage={isSelectAllPage}
                            className="_prepare-order-btn-add-warehouse-area btn-menu-item"
                        />
                    </Menu.Item>
                </>
            )}
            {(filters.order_status === ORDER_PACKING_STATUS.WAITING_PROCESSING.key || filters?.error_tracking) &&
                filters?.shipping_partner_id && (
                    <Menu.Item key={5} className="mb-3 mb-xl-0">
                        <ExportOrderPrepareByShippingPartner
                            ids={ids}
                            isSelectAllPage={isSelectAllPage}
                            shippingPartnerId={filters?.shipping_partner_id}
                            pagination={pagination}
                            filters={filters}
                        />
                    </Menu.Item>
                )}
            {[ORDER_PACKING_STATUS.WAITING_PROCESSING.key, ORDER_PACKING_STATUS.WAITING_PICKING.key].includes(
                filters?.order_status
            ) && (
                <Menu.Item key={9} className="mb-3 mb-xl-0">
                    <Button
                        loading={loadingPriority}
                        className="_prepare-order-btn-create-mark_priority btn-menu-item"
                        onClick={handleMarkPriority}
                    >
                        {t('order:btn.mark_priority')}
                    </Button>
                </Menu.Item>
            )}
            {[ORDER_PACKING_STATUS.WAITING_PICKING.key].includes(filters?.order_status) && (
                <Menu.Item key={11} className="mb-3 mb-xl-0">
                    <AddStaffForOrder isSelectAllPage={isSelectAllPage} ids={ids} filters={filters} />
                </Menu.Item>
            )}
        </Menu>
    );

    function handleCreateFreightBillShopee(pickupType) {
        setPickupType(pickupType);
        if (isEmpty(orderPackingsError)) {
            createFreightBill({
                ...filters,
                pickup_type: pickupType,
                ids: isSelectAllPage ? [] : ids,
            });
            setVisibleDropdown(false);
            setHasShopeeProvider(false);
        } else {
            setVisibleModal(true);
        }
    }

    const createFreightBillShopee = (
        <Menu>
            <Menu.Item key={16}>
                <Button
                    loading={loadingPriority}
                    className="_prepare-order-btn-receive-goods-from-warehouse btn-menu-item"
                    onClick={() => handleCreateFreightBillShopee('pickup')}
                >
                    {t('order:btn.receive_goods_from_warehouse')}
                </Button>
            </Menu.Item>
            <Menu.Item key={18}>
                <Button
                    loading={loadingPriority}
                    className="_prepare-order-btn-delivery-goods-to-post-office btn-menu-item"
                    onClick={() => handleCreateFreightBillShopee('dropoff')}
                >
                    {t('order:btn.delivery_goods_to_post_office')}
                </Button>
            </Menu.Item>
        </Menu>
    );

    function cancelVisibleModal() {
        setVisibleModal(false);
        setHasShopeeProvider(false);
        setVisibleDropdown(false);
    }
    return (
        <div className={className}>
            <div className="d-none">
                <ShippingStamp ref={shippingStampRef} orderPackings={orderPackings} />
            </div>

            {(filters.order_status === ORDER_PACKING_STATUS.WAITING_PROCESSING.key || filters?.error_tracking) &&
                auth.can(permissions.ORDER_IMPORT_FREIGHT_BILL) &&
                (hasShopeeProvider ? (
                    <Dropdown overlay={createFreightBillShopee} visible={visibleDropdown}>
                        <Button
                            loading={loadingTracking || creating}
                            type="primary"
                            className="ml-2 _prepare-order-btn-create-freight-bill"
                        >
                            <Space>
                                <GiftOutlined /> {t('order:btn.create_freight_bill')}
                                <DownOutlined />
                            </Space>
                        </Button>
                    </Dropdown>
                ) : (
                    <Button
                        loading={loadingTracking || creating}
                        type="primary"
                        className="ml-2 _prepare-order-btn-create-freight-bill"
                        onClick={handleVisibleModalCreate}
                    >
                        <GiftOutlined /> {t('order:btn.create_freight_bill')}
                    </Button>
                ))}

            <Dropdown overlay={selectedAction} trigger={['click']} className="_dropdown-action ml-2">
                <Button icon={<MoreOutlined />} />
            </Dropdown>
            {visibleModal && (
                <ModalCreateFreightBill
                    pickupType={pickupType}
                    cancelVisibleModal={cancelVisibleModal}
                    orderPackingsError={orderPackingsError}
                    isSelectAllPage={isSelectAllPage}
                    ids={ids}
                    filters={filters}
                />
            )}
        </div>
    );
}

export default PerformAction;
