import { Table } from 'antd';
import React from 'react';



import { t } from '@System/i18n';


const ModalBody = ({ data }) => {

    const columns = [
        {
            title: t('product:label.sku'),
            dataIndex: ['sku', 'code'],
            key: 'sku_code',
            render: (text, record) => {
                return <p>{text}</p>;
            },
        },
        {
            title: t('warehouse:label.sku_stock'),
            dataIndex: ['stock', 'quantity'],
            key: 'quantity',
            render: (text, record) => {
                return <p>{text}</p>;
            },
        },
        {
            title: t('warehouse:label.current_position'),
            dataIndex: ['warehouse_area', 'name'],
            key: 'warehouse_area_name',
            render: (text, record) => {
                return <p>{text}</p>;
            },
        },
        {
            title: t('order:label.reason_failed'),
            dataIndex: 'message',
            key: 'message',
            render: (text, record) => {
                return <p>{text ? t(`warehouse:error_message.${text}`) : '---'}</p>;
            },
        },
    ];


    return <Table columns={columns} rowKey={(record) => record?.stock?.id} dataSource={data} />;
};

export default ModalBody;
