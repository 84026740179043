import {Row, Col} from 'antd';
import clsx from 'clsx';
import chunk from 'lodash/chunk';
import React from 'react';

import BarcodeWidget from '@Modules/Product/components/BarcodeWidget';
import queryString from "query-string";
import {getVar} from "@System/support/helpers";

function SkuStamp({ location }) {
    const currentFilters = queryString.parse(location.search, {arrayFormat: 'bracket'});
    const printInfos = [];
    let barcodeValue = getVar(currentFilters, "code", "ATP01-XanhDuong");
    let quantity = 3;
    for (let i = 0; i < quantity; i++) {
        printInfos.push(
            <Col span={8} key={i}>
                <div className="generate-item">
                    <p className="sku-name">{getVar(currentFilters, "name", "Áo xanh dương")}</p>
                    <BarcodeWidget
                        value={barcodeValue}
                        height={70}
                        width={208}
                        fontSize={12}
                    />
                </div>
            </Col>
        );
    }

    return (
        <div className="sku-info" style={{width: "208mm"}}>
            {chunk(printInfos, quantity).map((chunkInfo, index) => (
                <Row
                    gutter={10}
                    className={clsx('sheet h-100 align-items-center', {
                        'page-break': index !== 0,
                    })}
                    key={index}
                >
                    {chunkInfo}
                </Row>
            ))}
        </div>
    );
}

export default SkuStamp;
