import useSound from 'use-sound';

import errorSound from './../../../resources/sounds/error.mp3';
import successSound from './../../../resources/sounds/success.mp3';

const useScanSounds = () => {
    const [playErrorSound] = useSound(errorSound);
    const [playSuccessSound] = useSound(successSound);

    return {
        playErrorSound,
        playSuccessSound,
    };
};

export default useScanSounds;
