import { Tag } from 'antd';
import clsx from 'clsx';
import React from 'react';

const StyledTag = ({ children, className, type, color, ...props }) => {
    return (
        <Tag
            {...props}
            className={clsx(className, 'rounded-20', {
                'text-dark': type !== 'success',
            })}
            color={color ? color : type === 'success' ? '#09b8af' : '#eaeeff'}
        >
            {children}
        </Tag>
    );
};

export default StyledTag;
