import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Table, Checkbox, notification, Badge, Button, Tooltip } from 'antd';
import _, { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';

import permissions from '@Modules/Auth/services/permissions';

import { Link } from '@System/routing';
import { formatCurrency } from '@System/support/numberFormat';

import shopbaseIcon from '../../../../resources/img/shopbase.png';
import { t } from '../../../../system/i18n';
import CustomizedPagination from '../../../App/CustomizedPagination';
import TableEmpty from '../../../App/Utilities/TableEmpty';
import { auth } from '../../../Auth/services';
import PERMISSIONS from '../../../Auth/services/permissions';
import ConnectShopbase from '../../components/ConnectShopbase';
import UpdateMerchant from '../../components/UpdateMerchant';
import api from '../../services/api';
import moment from 'moment';

export default function MerchantList({ merchants, setMerchants, pagination, addFilter, filters, loading, fetchMerchants }) {
    const [visible, setVisible] = useState(false);
    const [dataConnect, setDataConnect] = useState({});
    const [permissionColumn, setPermissionColumn] = useState([]);

    useEffect(() => {
        setPermissionColumn(
            auth.can(permissions.FINANCE_VIEW_SELLER_WALLET)
                ? [
                      {
                          title: t('purchasingAccount:table.columns.balance'),
                          dataIndex: 'merchant.balance',
                          key: 'merchant.balance',
                          className: 'text-center cl-balance',
                          render: (text, record) => {
                              const balance = get(record, 'balance.balance');
                              const currency = get(record, 'currency');
                              return (
                                  <strong style={{ fontWeight: 500 }}>   
                                      { !isEmpty(currency) ? formatCurrency(balance || 0, currency) : balance}
                                  </strong>
                              );
                          },
                      },
                      {
                          title: t('purchasingAccount:table.columns.detail'),
                          dataIndex: 'merchant.balance',
                          key: 'merchant.balance',
                          className: 'text-center cl-detail-view',
                          render: (text, record) => {
                              return (
                                  <Link params={{ id: record?.id }} to="config.merchants.detail" style={{ fontWeight: 500 }}>
                                      {t('common:btn.detail_view')}
                                  </Link>
                              );
                          },
                      },
                  ]
                : []
        );
    }, []);

    function openConnectShopbase(record) {
        setVisible(true);
        setDataConnect(record);
    }

    let getColumns = [
        {
            title: t('merchant:label.code'),
            dataIndex: 'merchant.code',
            key: 'merchant.code',
            className:'cl-code',
            render: (text, record) => {
                return (
                    <UpdateMerchant merchant={record} merchants={merchants} setMerchants={setMerchants}>
                        <strong style={{ fontWeight: '500' }}>{record.code}</strong>
                    </UpdateMerchant>
                );
            },
        },
        {
            title: t('merchant:label.name'),
            dataIndex: 'merchant.name',
            key: 'merchant.name',
            className:'cl-name',
            render: (text, record) => {
                return record.name;
            },
        },
        {
            title: t('merchant:label.email'),
            dataIndex: ['user', 'email'],
            key: 'user.email',
            className:'cl-email',
        },
        {
            title: t('merchant:label.phone'),
            dataIndex: 'merchant.phone',
            key: 'merchant.phone',
            className:'cl-phone',
            render: (text, record) => {
                return record.phone;
            },
        },
        {
            title: t('merchant:label.address'),
            dataIndex: 'merchant.address',
            key: 'merchant.address',
            className:'cl-address',
            render: (text, record) => {
                return record.address;
            },
        },
        {
            title: t('merchant:label.created_at'),
            dataIndex: 'merchant.created_at',
            key: 'merchant.created_at',
            className:'cl-address',
            render: (text, record) => {
                return record.created_at ? moment(record.created_at).format('DD/MM/YYYY HH:mm') : '' ;
            },
        },
        {
            title: t('merchant:label.description'),
            dataIndex: 'merchant.description',
            key: 'merchant.description',
            className:'cl-description',
            render: (text, record) => {
                return record.description;
            },
        },
        {
            title: t('merchant:label.market'),
            dataIndex: 'merchant.location_id',
            key: 'merchant.location_id',
            className:'cl-location',
            render: (text, record) => {
                return _.get(record, 'location.label', '');
            },
        },
        {
            title: t('merchant:label.status'),
            dataIndex: 'merchant.label',
            key: 'merchant.label',
            className: 'text-center cl-label',
            render: (text, record) => {
                return <Checkbox checked={record.status} onClick={() => toggleStatus(record)} />;
            },
        },
        {
            title: t('merchant:label.connect_shopbase'),
            dataIndex: 'merchant.shop_base_webhook_id',
            key: 'merchant.shop_base_webhook_id',
            className: 'text-center cl-shop_base_webhook_id',
            render: (text, record) => {
                const status = _.get(record, 'shop_base_webhook_id', false);
                return (
                    <Tooltip title={status ? t('merchant:title.connected_shopbase') : t('merchant:title.disconnected_shopbase')}>
                        <Badge
                            count={
                                status ? (
                                    <CheckCircleOutlined style={{ color: '#52c41a' }} />
                                ) : (
                                    <CloseCircleOutlined style={{ color: '#f5222d' }} />
                                )
                            }
                        >
                            {auth.can(PERMISSIONS.MERCHANT_CONNECT_SHOP_BASE) ? (
                                <Button style={{ display: 'contents' }} className='btn-connect-shop-base' onClick={() => openConnectShopbase(record)}>
                                    <img src={shopbaseIcon} height={34} alt="icon shopbase" />
                                </Button>
                            ) : (
                                <img src={shopbaseIcon} height={34} alt="icon shopbase" />
                            )}
                        </Badge>
                    </Tooltip>
                );
            },
        },
        ...permissionColumn,
    ];

    function toggleStatus(merchant) {
        api.toggleStatus(merchant.id, { status: merchant.status ? 0 : 1 })
            .then(res => {
                fetchMerchants();
                notification.success({ message: t('message.update_status_success', { attribute: t('label.merchant') }) });
            })
            .catch(err => {
                const status = _.get(err, 'response.status', null);
                if (status === 400) {
                    let errors = _.get(err, 'response.data.data', {});
                    if (errors && errors.hasOwnProperty('stock'))
                        notification.error({ message: t('merchant:message.stock_invalid') });
                    else if (errors && errors.hasOwnProperty('order'))
                        notification.error({ message: t('merchant:message.order_invalid') });
                    else notification.error({ message: t('message.update_status_failed', { attribute: t('label.merchant') }) });
                } else if (status === 403) notification.error({ message: t('common:message.403') });
                else if (status === 404) notification.error({ message: t('common:message.404') });
                else notification.error({ message: t('message.update_status_failed', { attribute: t('label.merchant') }) });
            });
    }

    return (
        <div className="m-3">
            <Table
                className="list-products-table pb-3"
                dataSource={merchants}
                rowKey={'id'}
                columns={getColumns}
                pagination={false}
                loading={loading}
                locale={{ emptyText: <TableEmpty loading={loading} /> }}
                scroll={{ x: true }}
            />
            <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
            {visible && <ConnectShopbase visible={visible} setVisible={setVisible} dataConnect={dataConnect} />}
        </div>
    );
}
