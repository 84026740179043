import { Button, Form, notification } from 'antd';
import clsx from 'clsx';
import find from 'lodash/find';
import get from 'lodash/get';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import values from 'lodash/values';
import moment from 'moment';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { v4 } from 'uuid';

import useScanBarcode from '@Modules/App/Hooks/useScanBarcode';
import useScanSounds from '@Modules/App/Hooks/useScanSounds';
import ScanErrors from '@Modules/App/ScanErrors';
import withScanErrors from '@Modules/App/ScanErrors/withScanErrors';
import {
    scanImportingPurchasePackageStorage,
    setDocumentTitle,
    setNavigator,
    warehouseStorage,
} from '@Modules/App/services';
import PackageOrderInfo from '@Modules/Document/screens/Importing/ScanPurchasePackage/components/PackageOrderInfo';
import ServiceList from '@Modules/Document/screens/Importing/ScanPurchasePackage/components/ServiceList';
import { SCAN_PURCHASE_PACKAGE_TYPE } from '@Modules/Document/services/constants';

import { addError, deleteErrorByKey } from '@State/scanErrors/actions';

import { t, trans } from '@System/i18n';
import { Link } from '@System/routing';
import { getVar } from '@System/support/helpers';

import api from '../../../services/api';
import FormScan from './components/FormScan';
import PackageList from './components/PackageList';
import ProductList from './components/ProductList';
import reducer, { ACTIONS_IMPORT_BY_PURCHASE_PACKAGE } from './reducer';

import styles from './scan-purchase-package.module.scss';

const ScanType = 'importWarehouseByPurchasePackage';
const FormScanWithErrors = withScanErrors(FormScan, ScanType);
const ScanErrorsWithErrors = withScanErrors(ScanErrors, ScanType);

const validatedRules = {
    barcode: [],
    warehouse_id: [
        {
            required: true,
            type: 'integer',
            message: t('order:message.required', { attribute: t('label.select_warehouses') }),
        },
    ],
    merchant_id: [
        {
            required: true,
            type: 'integer',
            message: t('order:message.required', { attribute: t('label.select_seller') }),
        },
    ],
    barcode_type: [
        {
            required: true,
            type: 'string',
            message: t('order:message.required', { attribute: t('label.scan_type') }),
        },
    ],
};

export default function ImportingByPurchasePackage() {
    useEffect(() => {
        setNavigator(t('title.import_by_purchase_package'), [
            {
                name: t('menu.operation_warehouse'),
            },
            {
                name: t('menu.import_by_purchase_package'),
            },
        ]);
        setDocumentTitle(t('title.import_by_purchase_package'));
    }, []);
    const [isScanning, setIsScanning] = useState(false);
    const initialState = scanImportingPurchasePackageStorage.value;
    const [storeData, dispatchStoreAction] = useReducer(reducer, initialState);
    const [mapOrder, setMapOrder] = useState(null);
    const [dataScan, setDataScan] = useState({});
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const storeActions = {
        newScan: (data = {}) =>
            dispatchStoreAction({ type: ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.NEW_SCAN, payload: data }),
        updateScanInfo: data =>
            dispatchStoreAction({
                type: ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE_SCAN_INFO,
                payload: data,
            }),
        deletePurchasePackage: sku_id =>
            dispatchStoreAction({ type: ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.DELETE_PURCHASE_PACKAGE, payload: sku_id }),
        addPurchasePackage: data =>
            dispatchStoreAction({ type: ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.ADD_PURCHASE_PACKAGE, payload: data }),
        updateReceivedQuantity: data =>
            dispatchStoreAction({ type: ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE_RECEIVED_QUANTITY, payload: data }),
        isSaved: () => dispatchStoreAction({ type: ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.IS_SAVED }),
        updateServices: data =>
            dispatchStoreAction({ type: ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.UPDATE_SERVICE, payload: data }),
        addSku: data => dispatchStoreAction({ type: ACTIONS_IMPORT_BY_PURCHASE_PACKAGE.ADD_SKU, payload: data }),
    };
    const { playSuccessSound, playErrorSound } = useScanSounds();
    const barcodeInputRef = useRef();
    const scan = useScanBarcode(api.scanImportByPurchaseOrder, {
        onSuccess: (_, params) => {
            const key = map(params, (value, key) => `${key}:${value}`).join('-');

            dispatch(
                deleteErrorByKey({
                    type: ScanType,
                    key,
                })
            );
        },
        onError: (error, params) => {
            const key = map(params, (value, key) => `${key}:${value}`).join('-');

            dispatch(
                addError({
                    type: ScanType,
                    key,
                    message: {
                        errors: error?.response?.data?.data,
                        barcode: params?.barcode,
                        barcode_type: params?.barcode_type,
                    },
                })
            );

            playErrorSound();
            setDataScan({
                barcode: params?.barcode,
            });
        },
        onSettled: () => {
            form.setFieldsValue({
                barcode: undefined,
            });
            setIsScanning(false);
            barcodeInputRef.current.focus({ cursor: 'start' });
        },
    });

    function handleChooseOrderOfPackage(data) {
        scanData(data);
        setDataScan(data);
    }

    function convertData(data) {
        const items = values(getVar(data, 'items', {}));
        const skus = [];
        items.map(sku => {
            skus.push({
                received_quantity: parseInt(getVar(sku, 'received_quantity', 0)),
                id: getVar(sku, 'sku.id', ''),
                sku_id: getVar(sku, 'sku.id', ''),
                code: getVar(sku, 'sku.code', ''),
                name: getVar(sku, 'sku.name', ''),
                product_id: getVar(sku, 'sku.product_id', ''),
                is_serial_number: getVar(sku, 'sku.is_serial_number', ''),
            });
            return true;
        });

        let purchase_package_key = getVar(data, 'purchasing_package.purchasing_package.id', '');
        const purchasing_package_services = getVar(data, 'purchasing_package_services', []);

        const services = [];
        purchasing_package_services.map(item => {
            const skusOfPurchasingPackage = getVar(item, 'purchasing_package_service.skus', skus);
            services.push({
                sku_ids: map(skusOfPurchasingPackage, 'sku_id'),
                service: getVar(item, 'service', {}),
                service_price_id: getVar(item, 'purchasing_package_service.service_price_id', undefined),
                key: v4(),
                price: getVar(item, 'purchasing_package_service.price', undefined),
                amount: getVar(item, 'purchasing_package_service.amount', undefined),
                quantity: getVar(item, 'purchasing_package_service.quantity', 0),
            });
        });
        const purchase_package = getVar(data, 'purchasing_package.purchasing_package', {});
        const purchasing_order_items = getVar(data, 'purchasing_order_items', []);
        const purchasing_package_services_id = map(purchasing_package_services, 'service.id');
        return {
            ...purchase_package,
            purchase_package_key,
            purchasing_order_items,
            skus,
            purchasing_package_services_id,
            services: services.length > 0 ? services : undefined,
            scanned_at: Date.now(),
        };
    }

    function scanData(data) {
        const { barcode } = data;
        barcode.trim();
        setIsScanning(true);
        const purchasePackages = getVar(storeData, 'purchase_packages', []);
        if (purchasePackages.length === 0) {
            form.validateFields()
                .then(values => {
                    const params = {
                        ...pick(values, ['warehouse_id', 'barcode_type', 'merchant_id']),
                        ...data,
                        playSound: false,
                    };

                    const key = map(params, (value, key) => `${key}:${value}`).join('-');

                    scan(params).then(res => {
                        form.setFields([{ name: 'barcode', value: '' }]);
                        let scannedData = getVar(res, 'data', {});
                        if (!isEmpty(scannedData)) {
                            const data = convertData(scannedData);
                            const existPurchasePackage = find(purchasePackages, [
                                'purchase_package_key',
                                data.purchase_package_key,
                            ]);
                            if (isEmpty(existPurchasePackage)) {
                                const countryId = getVar(storeData, 'countryId', undefined);
                                if (!countryId) {
                                    storeActions.updateScanInfo({
                                        countryId: getVar(
                                            scannedData,
                                            'purchasing_package.merchant.location_id',
                                            undefined
                                        ),
                                    });
                                }
                                storeActions.addPurchasePackage(data);
                                notification.success({
                                    message: t('document:messages.scan_barcode_successfully'),
                                    duration: 6,
                                });
                                playSuccessSound();
                            } else {
                                dispatch(
                                    addError({
                                        type: ScanType,
                                        key,
                                        message: {
                                            errors: {
                                                [storeData.barcode_type.toLowerCase()]: { code_scanned: {} },
                                            },
                                            barcode: params?.barcode,
                                            barcode_type: params?.barcode_type,
                                        },
                                    })
                                );
                                playErrorSound();
                            }
                        }
                    });
                })
                .catch(() => setIsScanning(false));
        } else {
            notification.error({
                message: t('document:import_by_purchase_package.message.reset_required'),
                duration: 6,
            });
        }
    }

    function renderErrors(data) {
        if (!data) {
            return null;
        }

        const listKeyException = [
            'has_many_in_purchasing_order',
            'has_processing_importing',
            'has_finished_importing',
            'order_item_not_map_sku',
            'not_permission_map_sku',
            'has_many_in_merchant',
        ];
        const errorMessages = [];
        const errors = getVar(data, 'errors', undefined);
        if (!isEmpty(errors)) {
            const message = getVar(errors, 'message', '');
            if (message) {
                errorMessages.push(t('message.server_error'));
            } else {
                Object.keys(data?.errors).forEach(item => {
                    let orders = [];
                    if (item === 'has_many_in_purchasing_order') {
                        orders = data.errors[item];
                    }
                    const document_code = getVar(data.errors[item], 'document.code');
                    const document_id = getVar(data.errors[item], 'document.id');
                    const created_at = getVar(data.errors[item], 'document.created_at');
                    const date = created_at ? moment(created_at).format('DD/MM/YYYY') : '';
                    const id_map_sku = getVar(data.errors[item], 'purchasing_order_id', null);
                    if (listKeyException.includes(item)) {
                        errorMessages.push(
                            trans(`document:import_by_purchase_package.message.${item}`, {
                                barcode_type: t(`document:import_by_purchase_package.scan_type.${data?.barcode_type}`),
                                barcode: `#${data?.barcode}`,
                                document: (
                                    <Link
                                        params={{ id: document_id }}
                                        to="documents.importing.detail"
                                    >{`#${document_code}`}</Link>
                                ),
                                date: date,
                                orders: (
                                    <div className="pt-1">
                                        {orders.map((item, index) => {
                                            return (
                                                <Button
                                                    className="mr-1"
                                                    type="primary"
                                                    key={index}
                                                    onClick={() =>
                                                        handleChooseOrderOfPackage({
                                                            purchasing_order_id: item?.id,
                                                            barcode: data?.barcode,
                                                        })
                                                    }
                                                >
                                                    {item?.code}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                ),
                                map_sku: (
                                    <Button
                                        className="pl-0"
                                        type="link"
                                        onClick={() => setMapOrder({ id: id_map_sku })}
                                    >
                                        {t('document:import_by_purchase_package.title.map_sku')}
                                    </Button>
                                ),
                                document_importing_scan: (
                                    <Link to="documents.importing.scan">{t('common:menu.importing_scan')}</Link>
                                ),
                            })
                        );
                    } else {
                        Object.keys(data?.errors[item]).forEach(error =>
                            errorMessages.push(
                                trans(`document:import_by_purchase_package.message.${error}`, {
                                    barcode_type: t(
                                        `document:import_by_purchase_package.scan_type.${data?.barcode_type}`
                                    ),
                                    barcode: `#${data?.barcode}`,
                                    title: t(`document:import_by_purchase_package.title.${data?.barcode_type}`),
                                    product:
                                        item === 'package_items' || item === 'order_items'
                                            ? t(`document:import_by_purchase_package.title.product`)
                                            : '',
                                })
                            )
                        );
                    }
                });
            }
        } else {
            errorMessages.push(t('message.server_error'));
        }

        return errorMessages;
    }

    const purchasePackages = get(storeData, 'purchase_packages', []);
    return (
        <div className={clsx('site-content')}>
            <Form
                form={form}
                scrollToFirstError
                layout="vertical"
                initialValues={{
                    warehouse_id: warehouseStorage.value,
                    barcode_type: SCAN_PURCHASE_PACKAGE_TYPE.PACKAGE_CODE,
                    merchant_id: 0,
                }}
            >
                <div className={clsx('', styles['scan-purchase-package'])}>
                    <FormScanWithErrors
                        barcodeInputRef={barcodeInputRef}
                        dataScan={dataScan}
                        errorKey="importWarehouseByPurchasePackage"
                        isScanning={isScanning}
                        mapOrder={mapOrder}
                        storeActions={storeActions}
                        storeData={storeData}
                        form={form}
                        scanData={scanData}
                        setDataScan={setDataScan}
                        setMapOrder={setMapOrder}
                        validatedRules={validatedRules}
                    />
                    <ScanErrorsWithErrors errorMessageRender={renderErrors} />
                    <PackageList storeActions={storeActions} storeData={storeData} form={form} />
                    <ProductList storeActions={storeActions} storeData={storeData} form={form} />
                    {purchasePackages.length > 0 && (
                        <ServiceList storeActions={storeActions} storeData={storeData} form={form} />
                    )}
                    {!isEmpty(getVar(head(purchasePackages), 'purchasing_order_items')) && (
                        <PackageOrderInfo
                            orders={getVar(head(purchasePackages), 'purchasing_order_items')}
                            loading={isScanning}
                        />
                    )}
                </div>
            </Form>
        </div>
    );
}
