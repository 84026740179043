import { generatorHash, generatorHashKey } from '@System/support/helpers'

import { api } from '../../../system/api'
import moment from 'moment';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    getWarehouses: params =>
        api.get('/warehouses/items', {
            params: params,
            singleRequest: true,
            loading: false,
        }),
    createWarehouse: data => api.post('/warehouses', data),
    toggleStatus: (id, data) => api.put(`/warehouses/${id}/status`, data),
    getWarehouse: id => api.get(`/warehouses/${id}`, { singleRequest: true, loading: false }),
    updateWarehouse: (id, data) => api.put(`/warehouses/${id}`, data),
    getSkus: (id, params) =>
        api.get(`/warehouses/${id}/stocks`, {
            params: params,
            singleRequest: true,
            loading: false,
        }),
    getListWarehouses: params => api.get('/warehouses', { params, singleRequest: true, loading: false }),
    fetchWarehouseSearch: params => api.get('warehouses/suggest', { params, singleRequest: true, loading: false }),
    changePositionStocks: data => {
        const time =  moment(new Date()).startOf("hour").format("X");
        return api.post('/stocks/change-position-stocks', data, { idempotencyKey: generatorHash(data, time) })
    },
}
