import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import { parse, stringify } from 'query-string';
import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

// modified from https://ahooks.js.org/hooks/state

const parseConfig = {
    skipNull: true,
    skipEmptyString: true,
    parseNumbers: true,
    parseBooleans: true,
};

const useUrlState = (initialState, options) => {
    const { navigateMode = 'push' } = options || {};
    const location = useLocation();
    const history = useHistory();

    const initialStateRef = useRef(isFunction(initialState) ? initialState() : initialState || {});

    const formattedParseConfig = useMemo(
        () =>
            has(options, parseConfig)
                ? {
                      ...parseConfig,
                      ...options.parseConfig,
                  }
                : parseConfig,
        [options]
    );

    const queryFromUrl = useMemo(() => {
        return parse(location.search, formattedParseConfig);
    }, [formattedParseConfig, location.search]);

    const targetQuery = useMemo(
        () => ({
            ...initialStateRef.current,
            ...queryFromUrl,
        }),
        [queryFromUrl]
    );

    const setState = useCallback(
        s => {
            const newQuery = isFunction(s) ? s(targetQuery) : s;

            history[navigateMode]({
                hash: location.hash,
                search: stringify({ ...queryFromUrl, ...newQuery }, formattedParseConfig) || '?',
            });
        },
        [formattedParseConfig, history, location.hash, navigateMode, queryFromUrl, targetQuery]
    );

    const clearState = useCallback(() => {
        history[navigateMode]({
            hash: location.hash,
        });
    }, [history, location.hash, navigateMode]);

    return [targetQuery, setState, clearState];
};

export default useUrlState;
