import { CopyTwoTone } from '@ant-design/icons'
import { Modal, Table, notification } from 'antd'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import api from '@Modules/ServicesManager/services/api'

import { t } from '@System/i18n'
import moment from "moment/moment";

interface IListActiveCode {
    comboId: number
    setVisible: (number) => any
    visible: boolean
}
const ListActiveCode: React.FC<IListActiveCode> = ({ comboId, setVisible, visible }) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [pagination, setPagination] = useState({})

    useEffect(() => {
        handleGetListActiveCode()
    }, [])
    const handleGetListActiveCode = () => {
        setLoading(true)
        api.getListActiveCode(comboId, {})
            .then(res => {
                setData(get(res, 'data.data', []))
                setPagination(get(res, 'data.meta.pagination', {}))
            })
            .catch(() => {
                notification.error({ message: t('message.fetch_fail', { attribute: t('serviceManager:title.list_active_code') }) })
            })
            .finally(() => setLoading(false))
    }
    const columns = [
        {
            className: ` _column-code`,
            title: t('serviceManager:label.active_code'),
            dataIndex: 'code',
            key: 'code',
            render: text => {
                return (
                    <>
                        <span>{text}</span>
                        <CopyToClipboard
                            text={text}
                            onCopy={() => {
                                notification.success({ message: t('message.copied') })
                            }}
                        >
                            <CopyTwoTone className="ml-1" />
                        </CopyToClipboard>
                    </>
                )
            },
        },
        {
            className: ` _column-status`,
            title: t('label.status'),
            dataIndex: 'status',
            key: 'status',
            render: text => {
                return <p>{t(`serviceManager:status_active_code.${text}`)}</p>
            },
        },
        {
            className: `_manage-service-column-created-at text-center`,
            title: t('label.created_at'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : t('noText')}</p>
            },
        },
        {
            className: `_manage-service-column-expired-at text-center`,
            title: t('label.expired_at'),
            dataIndex: 'expired_at',
            key: 'expired_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : t('noText')}</p>
            },
        },
    ]

    return (
        <Modal
            width={800}
            visible={visible}
            title={t('serviceManager:title.list_active_code')}
            closable={true}
            footer={null}
            onCancel={() => {
                setVisible(0)
            }}
        >
            <Table
                // rowKey={record => record?.id}
                rowClassName={'service-pack-list-table'}
                dataSource={data}
                loading={loading}
                columns={columns}
                pagination={pagination}
                locale={{
                    emptyText: (
                        <TableEmpty
                            className="_product-list-empty"
                            loading={loading}
                        />
                    ),
                }}
            />
        </Modal>
    )
}

export default ListActiveCode
