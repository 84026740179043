import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch, Tooltip } from 'antd'
import React, { useState } from 'react'

import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'

import { t } from '@System/i18n'
import notification from '@System/notification'

import { ISettings } from '../interfaces'
import api from '../services/api'

interface ISetOrderFlow {
    getSettings: () => void
    setting: Pick<ISettings, "auto_create_freight_bill">
}

const SetOrderFlow: React.FC<ISetOrderFlow> = ({ getSettings, setting }) => {
    const [loadingSwitch, setLoadingSwitch] = useState(false)

    const handleChangeLogicBillLading = async (checked: boolean) => {
        setLoadingSwitch(true)
        try {
            await api.changeLogicBillLading({
                auto_create_freight_bill: checked,
            })
            getSettings()
            notification.success(t('message.config_success'))
        } catch (error) {
            notification.error(t('message.config_failed'))
        } finally {
            setLoadingSwitch(false)
        }
    }

    return (
        <Tooltip title={!auth.can(permissions.ADMIN_SET_ORDER_FLOW) && t('message.unauthorized')} className='mb-2'>
            <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={setting?.auto_create_freight_bill}
                onChange={handleChangeLogicBillLading}
                loading={loadingSwitch}
                disabled={!auth.can(permissions.ADMIN_SET_ORDER_FLOW)}
                className='__order_auto_generate_bill_lading'
            />
        </Tooltip>
    )
}

export default SetOrderFlow
