import { Typography, Row, Col, Input, Button, Spin } from 'antd';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import { dateFormatter } from '@Modules/App/services';

import { t, trans } from '@System/i18n';

interface IHeaderProps {
    shippingPartner: object;
    documentId: number;
    documentInfo: object;
    updateDocument: any;
    isLoading: boolean;
}

const { Paragraph } = Typography;

const Header: React.FC<IHeaderProps> = props => {
    const { documentInfo, shippingPartner, updateDocument, isLoading } = props;
    const [note, setNote] = useState('');
    useEffect(() => {
        setNote(get(documentInfo, 'note', ''));
    }, [documentInfo]);

    const onChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(e.target.value);
    };

    const onSubmit = () => {
        updateDocument({ note });
    };
    return (
        <div className="w-100 bg-white mb-3 p-3">
            <Spin spinning={isLoading}>
                <div className="mb-3">
                    <Row className="d-flex mb-3 mb-lg-0 flex-wrap">
                        <Col xs={24} lg={8} className="mb-3">
                            <div className="d-flex">
                                <Paragraph className="font-weight-bold text-fz-16 mb-0 mr-2 _importing-document-code">
                                    {t('title.finance_control_delivery_detail')}: #
                                    {get(documentInfo, 'code', undefined)}
                                </Paragraph>
                            </div>
                            <div className="_importing-document-created-by">
                                {trans('document:created_by', {
                                    date: dateFormatter.date(get(documentInfo, 'created_at', '')),
                                    confirmer: get(documentInfo, 'creator_username', '--'),
                                })}
                            </div>
                        </Col>
                        <Col xs={24} lg={8}>
                            {t('order:label.order_shipping_partner')}: {get(shippingPartner, 'name', t('noText'))}
                        </Col>
                        <Col xs={24} lg={8} className="text-right">
                            <Button type={'primary'} onClick={onSubmit}>
                                {t('btn.update')}
                            </Button>
                        </Col>
                    </Row>
                    <div>
                        <Input.TextArea
                            value={note}
                            autoSize={{ minRows: 3, maxRows: 6 }}
                            onChange={onChangeNote}
                            placeholder={t('placeholder.note')}
                        />
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default Header;
