import { Button } from 'antd';
import clsx from 'clsx';
import React from 'react';

import styles from './delete-button.module.scss';

const DeleteButton = ({ className, ...props }) => {
    return <Button className={clsx(styles['delete-button'], className)} {...props} />;
};

export default DeleteButton;
