import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useUpdateWarehousesMutation = createUseMutation({
    namespace: 'user:update_warehouse',
    mutation: data => apiService.updateWarehouses(data),
});

export default useUpdateWarehousesMutation;
