import React, {useEffect, useState} from "react";
import {Avatar, Select, Modal, Table, Divider} from "antd";
import {t} from "@System/i18n";
import TableEmpty from "@Modules/App/Utilities/TableEmpty";
import _ from "lodash";
import api from "@Modules/PurchasingOrder/services/api";
import {catchErrors} from "@Modules/App/services/helpers";
import {cnCurrency, formatCurrency} from "@Modules/PurchasingOrder/services/constants";
import MapSkuToVariant from "@Modules/PurchasingOrder/screens/ListPurchasingOrders/MapSkuToVariant";

export default function MapSkuModal({mapOrder, setMapOrder, fetchPurchasingOrders}) {
    const [loading, setLoading] = useState(false);
    const [orderDetail, setOrderDetail] = useState(null);
    const purchasing_order_items = _.get(orderDetail, "purchasing_order_items", []);

    useEffect(() => {
        if (mapOrder)
            fetchPurchasingOrder();
    }, [mapOrder]);

    function updateOrderDetail(purchasing_variant) {
        let new_purchasing_order_items = purchasing_order_items.map(item => {
            return (purchasing_variant.id === item?.purchasing_variant?.id) ? {...item, purchasing_variant} : item;
        });
        setOrderDetail({
            ...orderDetail,
            purchasing_order_items: new_purchasing_order_items
        });
    }

    function fetchPurchasingOrder() {
        setLoading(true);
        api.getPurchasingOrder(mapOrder.id)
            .then(res => {
                setOrderDetail(_.get(res, "data.purchasing_order", null));
            })
            .catch(catchErrors(t("common:message.fetch_fail", {attribute: t("label.purchasing_order_detail").toLowerCase()})))
            .finally(() => setLoading(false));
    }

    function handleCancel() {
        setMapOrder(null);
        setOrderDetail(null);
    }

    let getColumns = [
        {
            className: "_purchasing-orders-modal-m1-item",
            title: t("purchasingOrder:label.item_name"),
            dataIndex: "item_name",
            key: "item_name",
            render: (text, record) => {
                return (
                    <div className="d-flex">
                        <div style={{width: "50px"}}><Avatar shape="square" size={44} src={record.variant_image} /></div>
                        <a className="ml-2" href={record.product_url} target="_blank">{text}</a>
                    </div>
                );
            },
        },
        {
            className: "_purchasing-orders-modal-m1-properties",
            title: t("purchasingOrder:label.item_properties"),
            dataIndex: "properties",
            key: 'properties',
            render: (text, record) => {
                let properties = _.get(record, "purchasing_variant.properties", []);
                return (
                    <p className="text-capitalize">
                        { properties.map((item, index) => {
                            return index === 0
                                ? item.value
                                : (<><Divider className="ml-1 mr-1" type="vertical"/> {item.value}</>);
                        }) }
                    </p>
                );
            },
        },
        {
            className: "_purchasing-orders-modal-m1-price text-nowrap text-right",
            title: t("purchasingOrder:label.item_price"),
            dataIndex: "original_price",
            key: 'original_price',
            width: 100,
            render: (text, record) => {
                return (
                    <>
                        <span>{formatCurrency(record.price ? record.price : 0)}</span>
                        <p className="text-color-label text-fz-12">{formatCurrency(text ? text : 0, cnCurrency)}</p>
                    </>
                );
            },
        },
        {
            className: "_purchasing-orders-modal-m1-map-sku text-nowrap text-right",
            title: t("purchasingOrder:label.item_map_sku"),
            width: 200,
            render: (text, record) => (
                <MapSkuToVariant
                    orderDetail={orderDetail}
                    updateOrderDetail={updateOrderDetail}
                    purchasingOrderItem={record}
                    fetchPurchasingOrders={fetchPurchasingOrders}
                />
                )
        }
    ];

    return (
        <Modal
            title={t("purchasingOrder:label.list_skus")}
            visible={mapOrder !== null}
            onCancel={handleCancel}
            cancelText={t("btn.close")}
            width={1000}
            okButtonProps={{className: "d-none"}}
            cancelButtonProps={{className: "_purchasing-orders-modal-m1-cancel-btn"}}
        >
            <Table
                className="list-products-table pb-3"
                dataSource={purchasing_order_items}
                rowKey={(record) => record.id}
                columns={getColumns}
                pagination={false}
                loading={loading}
                locale={{emptyText: <TableEmpty loading={loading}/>}}
                scroll={{ x: true }}
            />
        </Modal>
    );
}
