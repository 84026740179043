import React from "react";
import {Select} from "antd";
import {t} from "../../../system/i18n";
import {PAYMENT_METHOD} from "../services/constants";

const {Option} = Select;

export default function SelectPaymentMethod(props) {
    return (
        <Select
            {...props}
            value={ props.value ||  undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                Object.keys(PAYMENT_METHOD).map((opt, index) => {
                    return <Option key={index} value={PAYMENT_METHOD[opt]}>{t(`order:payment_method.${PAYMENT_METHOD[opt]}`)}</Option>;
                })
            }
        </Select>
    );
}
