import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useBalanceMutation = createUseMutation({
    namespace: 'document:sku_inventories_document.balance',
    mutation: id => apiService.balanced(id),
});

export default useBalanceMutation;
