import get from 'lodash/get';
import React, { useState, useEffect } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import useFilter from '@Modules/App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useListFinanceOrderQuery from '@Modules/Finance/Hooks/useListFinanceOrderQuery';
import Summary from '@Modules/Finance/screens/List/Summary';

import { t } from '@System/i18n';

import FormSearch from './FormSearch';
import List from './List';

export default function FinanceStatistic({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, {});
    const [pagination, setPagination] = useState({});
    const [orders, setOrders] = useState([]);
    const { data, isFetching, refetch, isError, error } = useListFinanceOrderQuery(filters);

    useEffect(() => {
        setNavigator(t('menu.finances'), [
            {
                name: t('breadcrumb.order_financial_management'),
            },
        ]);
        setDocumentTitle(t('title.order_financial_management'));
    }, []);

    useEffect(() => {
        refetch();
    }, [filters, refetch]);

    useEffect(() => {
        setOrders(get(data, 'data.orders', []));
        setPagination(get(data, 'data.pagination', {}));
    }, [data]);

    if (isError) {
        const status = get(error,"response.status") ;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('breadcrumb.order_financial_management')}</h3>
            </div>
            <FormSearch onSearch={addFilter} filters={filters} loading={isFetching} />
            <Summary filters={filters} />
            <List orders={orders} pagination={pagination} addFilter={addFilter} filters={filters} loading={isFetching} />
        </div>
    );
}
