import React from 'react';
import ReactDOM from 'react-dom';

import { tracker } from '@Config/tracker';

import Providers from './Providers';
import './system/i18n';

import './resources/scss/main.scss';
import getTheme from "@System/support/helpers";
import i18next from 'i18next';

if (window?.APP_CONFIG?.TRACKING) {
    tracker.start();
}

let theme = getTheme();

if (theme && theme.code === "vela") {
    document.title = theme.title;
    document.body.classList.add("velaone");
    document.getElementById('favicon').setAttribute('href', process.env.PUBLIC_URL + '/favicon-vela.ico');
} else {
    document.title = 'Fulfillment by Ubox';
    document.getElementById('favicon').setAttribute('href', process.env.PUBLIC_URL + '/favicon.ico');
}
//Detect add html attribute lang
document.documentElement.lang = i18next.language;

ReactDOM.render(<Providers />, document.getElementById('root'));
