import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput';
import { SERVICE_TYPE } from '@Modules/ServicesManager/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';
import notification from '@System/notification';

import api from '../services/api';

const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
};
const ModalAddPrice = ({ setVisibleAddPrice, serviceType, idService, currency, getListPrice, data, rule }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [warning, setWarning] = useState(false);

    const handleSubmitModalAdd = async () => {
        await form.validateFields();
        setLoading(true);
        try {
            const deduct = serviceType === SERVICE_TYPE.EXTENT ? form.getFieldValue('deduct') / 100 : undefined
            await api.createPrice(idService, { ...form.getFieldsValue(true), deduct });

            notification.success(t('message.create_price_success'));
            getListPrice();
            events.dispatch(`RELOAD_LIST_SERVICE_${serviceType}`, {});
            setVisibleAddPrice(false);

        } catch (error) {
            let { response } = error;

            if (response?.status === 400) {
                let error_messages = [];
                let errors = get(response, 'data.data', {});

                Object.keys(errors).forEach(item => {
                    let key_errors = [];
                    Object.keys(errors[item]).forEach(error => {
                        if (error === 'duplicated') {
                            key_errors.push({
                                name: 'price',
                                errors: [t('message.duplicated', { attribute: t(`label.price`) })],
                            });
                        }
                    });
                    error_messages.push(...key_errors);
                });

                form.setFields(error_messages);
            }
            notification.error(t('message.create_price_failed'));

        } finally { setLoading(false); }

    };

    const onChangeInput = value => {
        const warningCheck = data.some(val => val.label.toLowerCase() === value.trim().toLowerCase());
        setWarning(warningCheck);
    };

    return (
        <Modal
            width={'700px'}
            visible={true}
            title={t('btn.add_price')}
            closable={true}
            closeIcon={<CloseOutlined style={{ padding: '16px' }} onClick={() => setVisibleAddPrice(false)} />}
            footer={[
                <Button
                    key="back"
                    onClick={() => setVisibleAddPrice(false)}
                    className={`_service-price-modal-btn-cancel-price__${serviceType.toLowerCase()}`}
                >
                    {t('btn.cancel')}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleSubmitModalAdd}
                    loading={loading}
                    className={`_service-price-modal-btn-add-price__${serviceType.toLowerCase()}`}
                >
                    {t('btn.ok')}
                </Button>,
            ]}
        >
            <Form form={form} {...layout}>
                <Form.Item
                    name="label"
                    label={t('label.price_level')}
                    rules={[
                        {
                            required: true,
                            message: t('order:message.required', { attribute: t('label.price_level') }),
                        },
                    ]}
                >
                    <Input
                        id={`_service-price-modal-input-price_level}`}
                        onChange={e => onChangeInput(e.target.value)}
                    />
                </Form.Item>
                <Row>
                    <Col xs={{ span: 24 }} sm={{ span: 19, offset: 5 }}>
                        {warning && (
                            <div
                                style={{ marginTop: '-22px', marginBottom: '12px', color: '#faad14', fontSize: '12px' }}
                            >
                                {t('message.price_duplicate')}
                            </div>
                        )}
                    </Col>
                </Row>

                <Form.Item
                    name="price"
                    label={t('label.price')}
                    rules={[
                        {
                            required: true,
                            message: t('order:message.required', { attribute: t('label.price') }),
                        },
                    ]}
                >
                    <CurrencyInput
                        className={`_service-price-modal-input-price`}
                        currency={currency ? currency : null}
                    />
                </Form.Item>

                {serviceType === SERVICE_TYPE.EXPORT && (
                    <Form.Item name="yield_price" label={t('label.additional_costs')} initialValue={"0"}>
                        <CurrencyInput
                            value={"0"}
                            className={`_service-price-modal-yield-price`}
                            name="yield_price"
                            style={{ width: '100%' }}
                            currency={currency ? currency : null}
                        />
                    </Form.Item>
                )}

                {serviceType === SERVICE_TYPE.EXTENT && (
                    <>
                        <Form.Item name="deduct" label={t('label.deduct_percent')}
                            rules={[
                                {
                                    required: true,
                                    message: t('order:message.required', { attribute: t('label.deduct_percent') }),
                                },
                            ]}
                        >
                            <InputNumber
                                className="w-100 ml-1 _service-price-modal-deduct_percent"
                                min={0}
                                max={100}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace('%', '')}
                            />
                        </Form.Item>

                    </>
                )}
                <Form.Item name="note" label={t('note')}>
                    <Input.TextArea id={`_service-price-modal-input-note`} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalAddPrice;
