import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Input, Checkbox } from 'antd';
import React from 'react';

import { auth } from '@Modules/Auth/services';
import PERMISSIONS from '@Modules/Auth/services/permissions';
import SelectMerchantsAssigned from '@Modules/Merchant/components/SelectMerchantsAssigned';

import { t } from '../../../../system/i18n';
import SelectAllMerchants from '../../../Merchant/components/SelectAllMerchants';
import SelectCategories from '../../../ProductCategories/components/SelectCategories';

function FormSearch({ form, loading, onReset, onSearch, isCombo }) {
    const handleChange = (field, event) => {
        if (field === 'merchant_id') {
            form.setFieldsValue({
                check_all: false,
            });
        }

        if (field === 'check_all' && event.target.checked) {
            form.setFieldsValue({
                merchant_id: undefined,
            });
        }
    };

    return (
        <Form className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray" form={form} layout="vertical">
            <Row gutter={20}>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('product:label.product_code')} name="code">
                        <Input
                            className="_search-code"
                            placeholder={t('product:placeholder.product_code')}
                            onPressEnter={onSearch}
                            allowClear
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('product:label.product_name')} name="name">
                        <Input
                            className="_search-name"
                            placeholder={t('product:placeholder.product_name')}
                            onPressEnter={onSearch}
                            allowClear
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-0" label={t('product:label.merchant_code1')} name="merchant_id">
                        {auth.can(PERMISSIONS.PRODUCT_MANAGE_ALL) ? (
                            <SelectAllMerchants
                                className="_search-merchant"
                                allowClear
                                placeholder={t('product:placeholder.select_merchant')}
                                onChange={handleChange.bind(this, 'merchant_id')}
                            />
                        ) : (
                            <SelectMerchantsAssigned
                                className="_search-merchant"
                                allowClear
                                placeholder={t('product:placeholder.select_merchant')}
                                onChange={handleChange.bind(this, 'merchant_id')}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-0" label={t('product:label.category_id')} name="category_id">
                        <SelectCategories
                            className="_search-category"
                            allowClear
                            placeholder={t('product:placeholder.category_id')}
                        />
                    </Form.Item>
                </Col>

                {
                  !isCombo &&  <>
                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item className="mb-0" label={t('product:label.ubox_product_code')} name="ubox_product_code">
                            <Input
                                className="_search-ubox-product-code"
                                placeholder={t('product:placeholder.ubox_product_code')}
                                onPressEnter={onSearch}
                                allowClear
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                        <Form.Item
                            className="mb-0 mt-4"
                            name="check_all"
                            valuePropName="checked"
                            onChange={handleChange.bind(this, 'check_all')}
                        >
                            <Checkbox className="mt-2 _search-checked-all">{t('product:label.check_all')}</Checkbox>
                        </Form.Item>
                    </Col>
                    </>
                }

                <Col span={24} className="text-right" >
                  
                    <Button className="mr-2 _search-btn-reset" onClick={onReset}>
                        <UndoOutlined /> {t('btn.reset')}
                    </Button>
                    <Button loading={loading} type="primary" onClick={onSearch} className="_search-btn-search">
                        <SearchOutlined /> {t('btn.search')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;
