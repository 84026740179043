import { DoubleRightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { url } from '../../../system/routing';
import { ExpandContext } from './configs';

export default function MenuItem({ icon, url: path, name, onClick, selected = false }) {
    return (
        <ExpandContext.Consumer>
            {({ showName, showIcon }) => (
                <li className={`main-menu--list--item ${showIcon ? 'text-center text-fz-18' : ''}`}>
                    {showIcon ? (
                        <Tooltip placement="right" title={name}>
                            <Link
                                to={url.to(path)}
                                onClick={onClick ? onClick : () => {}}
                                className={`${selected ? 'selected' : ''}`}
                            >
                                <span className={showName ? 'mr-2' : ''}>{icon ? icon : <DoubleRightOutlined />}</span>{' '}
                                {showName ? name : ''}
                            </Link>
                        </Tooltip>
                    ) : (
                        <Link
                            to={url.to(path)}
                            onClick={onClick ? onClick : () => {}}
                            className={`${selected ? 'selected' : ''}`}
                        >
                            <span className={showName ? 'mr-2' : ''}>{icon ? icon : <DoubleRightOutlined />}</span>{' '}
                            {showName ? name : ''}
                        </Link>
                    )}
                </li>
            )}
        </ExpandContext.Consumer>
    );
}
