import { Select } from 'antd';
import React from 'react';

import { t } from '../../../system/i18n';
import { STATUS, STATUS_FINANCE_CONTROL_DOCUMENT, TYPES } from '../services/constants';

const { Option } = Select;

export default function SelectFinanceControlStatus(props) {
    return (
        <Select
            {...props}
            value={props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {Object.values(STATUS_FINANCE_CONTROL_DOCUMENT).map(opt => {
                return (
                    <Option key={opt} value={opt}>
                        {t(`finance:finance_control.status.${opt}`)}
                    </Option>
                );
            })}
        </Select>
    );
}
