import { Button, Form, Input, Modal } from 'antd'
import { get, trim } from 'lodash'
import React, { useState, useEffect } from 'react'

import { INPUT_INVALID } from '@Modules/PurchasingPackage/services/constants'

import { t, trans } from '@System/i18n'
import notification from '@System/notification'

import apiService from '../services/api'

const EditSupplier = ({ supplier, refetch }) => {
    const [form] = Form.useForm()
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        form.setFieldsValue({ ...supplier })
    }, [supplier])

    const handleVisible = () => {
        setVisible(true)
    }

    const onCancel = () => {
        form.resetFields()
        setVisible(false)
    }

    const onEditSupplier = () => {
        form.validateFields().then(values => {
            setLoading(true)
            apiService
                .updateSupplier(supplier.id, { ...values })
                .then(result => {
                    onCancel()
                    refetch()
                    notification.success(t('config:supplier.message.update_supplier_success'))
                })
                .catch(err => {
                    const code = get(err, 'response.data.code')
                    const data = get(err, 'response.data.data')

                    if (code === INPUT_INVALID) {
                        Object.entries(data).forEach(([key, value]) => {
                            notification.error(
                                t(`config:supplier.message.${Object.keys(value)[0]}`, {
                                    attribute: t(`config:supplier.label.${key}`),
                                })
                            )
                        })
                    } else {
                        notification.error(t('config:supplier.message.update_supplier_fail'))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }

    return (
        <>
            <Button onClick={handleVisible}>{t('btn.edit')}</Button>
            {visible && (
                <Modal
                    title={trans('config:supplier.label.edit', { attribute: <b>{supplier.name}</b> })}
                    visible={true}
                    onOk={onEditSupplier}
                    onCancel={onCancel}
                    cancelText={t('btn.cancel')}
                    okText={t('btn.ok')}
                    okButtonProps={{
                        loading,
                    }}
                >
                    <Form
                        layout="vertical"
                        form={form}
                    >
                        <Form.Item
                            label={t('config:supplier.label.code')}
                            name="code"
                        >
                            <Input
                                placeholder={t('config:supplier.placeholder.code')}
                                disabled
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('config:supplier.label.name')}
                            name="name"
                            rules={[{ required: true, message: t('order:message.required', { attribute: t('config:supplier.label.name') }) }]}
                        >
                            <Input
                                placeholder={t('config:supplier.placeholder.name')}
                                onBlur={e => form.setFieldsValue({ name: e.target.value })}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('address')}
                            name="address"
                            rules={[
                                {
                                    max: 254,
                                    message: t('config:supplier.message.less_than_char', {
                                        attribute: t('address'),
                                        count: 254,
                                    }),
                                },
                            ]}
                        >
                            <Input placeholder={t('warehouse:placeholder.address')} showCount maxLength={254}/>
                        </Form.Item>
                        <Form.Item
                            label={t('contact')}
                            name="contact"
                            rules={[
                                {
                                    max: 254,
                                    message: t('config:supplier.message.less_than_char', {
                                        attribute: t('contact'),
                                        count: 254,
                                    }),
                                },
                            ]}
                        >
                            <Input placeholder={t('config:supplier.placeholder.contact')} />
                        </Form.Item>
                        <Form.Item
                            label={t('note')}
                            name="note"
                        >
                           <Input.TextArea placeholder={t('warehouse:placeholder.note')} rows={3}/>
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default EditSupplier
