import {Select} from "antd";
import React from "react";
import {t} from "@System/i18n";

const {Option} = Select;

export const PURCHASING_ORDER_STATUS = {
    STATUS_DEPOSITED: 'DEPOSITED', // Đã Đặt Cọc
    STATUS_ACCEPTED: 'ACCEPTED', // Đã tiếp nhận
    STATUS_PENDING: 'PENDING', // Chờ mua
    STATUS_PROCESSING: 'PROCESSING', //Đang xử lý
    STATUS_PURCHASED: 'PURCHASED', // Đã mua
    STATUS_MERCHANT_DELIVERING: 'MERCHANT_DELIVERING', // Người bán giao
    STATUS_PUTAWAY: 'PUTAWAY', // Hàng về kho
    STATUS_TRANSPORTING: 'TRANSPORTING', // Vận chuyển quốc tế
    STATUS_READY_FOR_DELIVERY: 'READY_FOR_DELIVERY', // Chờ giao
    STATUS_DELIVERING: 'DELIVERING', // Đang giao
    STATUS_DELIVERED: 'DELIVERED', // Khách đã nhận
    STATUS_CANCELLED: 'CANCELLED', // Hủy bỏ
    STATUS_OUT_OF_STOCK: 'OUT_OF_STOCK', // Hết hàng
    STATUS_MIA: 'MIA', // Thất lạc
    STATUS_DELIVERY_CANCELLED: 'DELIVERY_CANCELLED' // Khách không nhận
};

export default function SelectPurchasingOrderStatus(props) {
    return (
        <Select
            {...props}
            optionFilterProp="children"
            allowClear={true}
        >
            {
                Object.values(PURCHASING_ORDER_STATUS).map((opt, index) => {
                    return (
                        <Option
                            key={index}
                            value={opt}
                        >{t(`purchasingOrder:status.${opt}`)}</Option>
                    );
                })
            }
        </Select>
    );
}
