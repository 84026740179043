import { useQuery } from 'react-query';

import { catchErrors } from '@Modules/App/services/helpers';

import { t } from '@System/i18n';

import apiService from './../services/api';
import processResponseError from "@System/api/processResponseError";

const useSkusQuery = (params, options = {}) =>
    useQuery(['list-skus', params], () => apiService.getSkus(params), {
        ...options,
        retry: false,
        onError: (err) => {
           processResponseError(err)
        },
    });

export default useSkusQuery;
