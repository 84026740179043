import moment from 'moment';

export default class DateFormatter {
    date(input) {
        return this.formatDate(input, 'DD/MM/YYYY');
    }

    datedash(input) {
        return this.formatDate(input, 'DD-MM-YYYY');
    }

    monthOnly(input) {
        return this.formatDate(input, 'MM');
    }

    standa(input) {
        return this.formatDate(input, 'YYYY-MM-DD H:mm:ss');
    }

    time(input) {
        return this.formatDate(input, 'HH:mm:ss');
    }

    onlyTime(input) {
        return this.formatDate(input, 'HH:mm');
    }

    dateTime(input) {
        return this.formatDate(input, 'HH:mm DD/MM/YYYY');
    }

    shortTime(input) {
        return this.formatDate(input, 'DD/MM HH:mm');
    }

    full(input) {
        return this.formatDate(input, 'HH:mm:ss DD/MM/YYYY');
    }

    local(input) {
        return this.formatDate(input, 'YYYY-MM-DDTHH:mm:ssZ');
    }

    formatDate(input, format) {
        if (!input) {
            return null;
        }

        const date = moment(input);

        return date.isValid() ? date.format(format) : null;
    }
}
