import { Form, notification, Spin } from 'antd';
import clsx from 'clsx';
import isObject from 'lodash/isObject';
import React, { Fragment, useEffect, useState } from 'react';
import useSound from 'use-sound';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import Loading from '@Modules/App/Loading';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import { STATUSES } from '@Modules/Document/constants';

import { t, trans } from '@System/i18n';
import { getVar } from '@System/support/helpers';

import errorSound from './../../../../../resources/sounds/error.mp3';
import successSound from './../../../../../resources/sounds/success.mp3';
import useScanBarcodeMutation from './../Hooks/useScanBarcodeMutation';
import useScanHistoriesQuery from './../Hooks/useScanHistoriesQuery';
import useSkuInventoryDocumentQuery from './../Hooks/useSkuInventoryDocumentQuery';
import useUpdateSkuInventoryDocumentMutation from './../Hooks/useUpdateSkuInventoryDocumentMutation';
import Body from './Body';
import Header from './Header';

import styles from './detail.module.scss';

const Detail = ({
    match: {
        params: { id },
    },
}) => {
    const [skuInventories, setSkuInventories] = useState([]);
    const [playErrorSound] = useSound(errorSound);
    const [playSuccessSound] = useSound(successSound);
    const [canUpdate, setCanUpdate] = useState(false);
    const [form] = Form.useForm();
    const { error, isLoading, isError, data } = useSkuInventoryDocumentQuery(id);
    const { mutate: updateSkuInventoryDocument } = useUpdateSkuInventoryDocumentMutation({
        onSuccess: data => {
            setSkuInventories(data?.sku_inventories);
            setInventoryDocument(data);
        },
    });
    const { data: scanHistoriesData, refetch: getScanHistories } = useScanHistoriesQuery(id);
    const [inventoryDocument, setInventoryDocument] = useState();
    const { isLoading: scanning, mutate: scan } = useScanBarcodeMutation({
        notifyWhenSuccess: false,
        notifyWhenError: false,
        onSuccess: data => {
            playSuccessSound();
            form.setFieldsValue({
                barcode: undefined,
            });
            setSkuInventories(data?.sku_inventories);
            setInventoryDocument(data);
            getScanHistories();
        },
        onError: error => {
            let message = '';
            const listKeyException = ['has_many_in_merchant'];
            const code = error?.response?.data?.code;
            const errors = error?.response?.data?.data;
            if (code !== 'EXCEPTION' && isObject(errors) && errors && Object.keys(errors).length) {
                const firstErrorKey = Object.keys(errors)[0];
                let translatedErrors = [];
                if (listKeyException.includes(firstErrorKey)) {
                    translatedErrors.push(trans(`document:sku_inventories_document.scan.errors.${firstErrorKey}`));
                } else {
                    if (isObject(errors[firstErrorKey])) {
                        for (const error of Object.keys(errors[firstErrorKey])) {
                            translatedErrors.push(
                                trans(`document:sku_inventories_document.scan.errors.${firstErrorKey}.${error}`)
                            );
                        }
                    } else {
                        translatedErrors = [errors[firstErrorKey]];
                    }
                }

                message = translatedErrors.map((translatedError, index) => (
                    <Fragment key={index}>
                        {translatedError}
                        {index !== translatedErrors.length - 1 && <br />}
                    </Fragment>
                ));
            }
            notification.error({ message, duration: 6 });
            playErrorSound();
        },
    });

    const handleScan = values => {
        scan({
            id,
            ...values,
        });
    };

    const handleUpdateSkuInventoryDocument = usePersistFn(data => {
        updateSkuInventoryDocument({
            id,
            ...data,
        });
    });

    const handleSuccessImportSkus = usePersistFn(data => {
        setSkuInventories(data?.sku_inventories);
        setInventoryDocument(data);
        getScanHistories();
    });

    useEffect(() => {
        const inventoryDocument = getVar(data, 'data', {});
        const status = getVar(inventoryDocument, 'document_sku_inventory.status', undefined);
        setSkuInventories(data?.data?.sku_inventories);
        setInventoryDocument(inventoryDocument);
        setCanUpdate(status === STATUSES.DRAFT);
    }, [data]);

    useEffect(() => {
        setNavigator(t('document:create_inventory_document'));
        setDocumentTitle(t('document:create_inventory_document'));
    }, []);

    if (isError) {
        const status = error?.response?.status;

        if (status === 403) {
            return <Error403 />;
        }

        return <Error404 />;
    }

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className={clsx(styles['inventory-detail'], 'site-content')}>
            {canUpdate && <Header form={form} inventoryDocument={inventoryDocument} scanning={scanning} onScan={handleScan} />}
            <Body
                canUpdate={canUpdate}
                inventoryDocument={inventoryDocument}
                scanHistories={scanHistoriesData?.data?.inventory_scan_histories}
                skuInventories={skuInventories}
                onSuccessImportSkus={handleSuccessImportSkus}
                onUpdateSkuInventoryDocument={handleUpdateSkuInventoryDocument}
            />
        </div>
    );
};

export default Detail;
