import React, {useState, useEffect} from "react";
import {notification} from "antd";
import useFilter from "../../../App/Hooks/useFilter";
import _ from "lodash";
import { t } from "../../../../system/i18n";
import {setNavigator} from "../../../App/services";
import FormSearch from "./FormSearch";
import api from "../../services/api";
import BillList from "./BillList";
import useLocalStorageState from "../../../App/Hooks/useLocalStorageState";
import {warehouseStorage} from "../../../App/services";

const initFilters = {
    id: "",
    warehouse_id: "",
    creator_id: "",
    created_at_from: "",
    created_at_to: "",
    page: "",
    per_page: ""
};

export default function ListBills({history, location}) {
    const {filters, addFilter} = useFilter(history, location, {
        ...initFilters,
        warehouse_id: warehouseStorage.value || ""
    });
    const [loading, setLoading] = useState(false);
    const [bills, setBills] = useState([]);
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        setNavigator(t("common:title.list_exported_bills"), [
            { name: t("breadcrumb.manage_exporting"), route: "list-exported-bills" },
            { name: t("common:breadcrumb.list_exported_bills") }
        ]);
    }, []);

    useEffect(() => {
        fetchExportedBills();
    }, [filters]);

    function fetchExportedBills() {
        setLoading(true);
        api.getExportedBills(filters)
            .then((res) => {
                setBills(_.get(res, "data.deliveryNotes", []).map(item => {
                    let deliveryNote = _.get(item, 'deliveryNote', {});
                    let warehouse = _.get(item, 'warehouse', {});
                    let creator = _.get(item, 'creator', {});
                    return {...deliveryNote, warehouse, creator}
                }));
                setPagination(_.get(res, 'data.pagination', {}));
                if (res) setLoading(false);
            })
            .catch((err) => {
                const status = _.get(err, "response.status", null);
                if (status === 403)
                    notification.error({message: t('common:message.403')});
                else if (status === 404)
                    notification.error({message: t('common:message.404')});
                else
                    notification.error({message: t('common:message.fetch_fail')});
                setLoading(false);
            });
    }

    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{t("common:heading.list_exported_bills")}</h2>
                    <div className="pr-3">
                    </div>
                </div>
                <div>
                    <FormSearch
                        onSearch={addFilter}
                        filters={filters}
                        initFilters={initFilters}
                        loading={loading}
                    />
                    <BillList
                        bills={bills}
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}
