import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from "antd";
import { get, isNil } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import DocumentControlStatus from '@Modules/Finance/components/DocumentControlStatus';
import RangPickerLimit from '@Modules/Finance/components/RangPickerLimit';
import SelectStatuses from '@Modules/Order/components/SelectStatuses';
import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner';

import { t } from '@System/i18n';
import SelectFinanceShippingStatus from '@Modules/Finance/components/DebtsShipping/SelectFinanceShippingStatus';

interface IFormSearchProps {
    onSearch: (filters: object) => void;
    filters: object;
    loading: boolean;
    defaultValue: object;
}

const formatDate = 'DD/MM/YYYY';
const nowDay = new Date();
const ninetyDayAgo = moment().subtract(3, 'months');

const FormSearch: React.FC<IFormSearchProps> = ({ onSearch, filters, loading, defaultValue }) => {
    const [input, setInput] = useState<object>({});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setInput(filters);
        validateSearch(filters);
    }, [filters]);

    function _onChangeSelect(name: string, value: any) {

        let params = {};
        if (name === 'shipping_partner_id' && isNil(value)) {
            setErrors({ ...errors, shipping_partner_id: undefined });
        }

        const filters = { ...input, ...params, [name]: value };
        setInput(filters);
        _onSubmit(filters);
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        setInput(prevState => {
            return { ...prevState, ...input, [name]: value };
        });
    }

    function _onPressEnter(e) {
        const { name, value } = e.target;
        _onSubmit({ ...input, [name]: value });
    }

    function _onChangeDate(name: string, dates: any[]) {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;
        const filters = {
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        };
        setInput(filters);
        _onSubmit(filters);
    }

    function _onSubmit(params: object) {
        const validate = validateSearch(params);
        if (validate) {
            onSearch(params);
        }
    }
    function validateSearch(filters: object) {
        const shippingPartnerId = get(filters, 'shipping_partner_id', undefined);
        if (!shippingPartnerId) {
            setErrors({
                ...errors,
                shippingPartnerId: t('validation:required', { attribute: t('order:label.order_shipping_partner') }),
            });
            return false;
        }
        setErrors({});
        return true;
    }

    function _onReset() {
        onSearch(defaultValue);
    }

    const createdAtFrom = get(input, 'inventory_freight_bill_created_at[from]', '');
    const createdAtTo = get(input, 'inventory_freight_bill_created_at[to]', '');
    const shippingPartnerId = get(input, 'shipping_partner_id');
    const inventoryOrderStatus = get(input, 'inventory_order_status');
    const shipping_financial_status = get(input, 'shipping_financial_status');
    const freight_bill = get(input, 'freight_bill');
    const inventoryDocumentStatus = get(input, 'inventory_document_status');

    return (
        <Form layout="vertical">
            <div className="bg-white rounded-12 p-4 mt-3">
                <div className="search-box">
                    <Row gutter={20}>
                        <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                            <Form.Item
                                required={true}
                                className="mb-3"
                                label={t('order:label.order_shipping_partner')}
                                validateStatus={isEmpty(get(errors, 'shippingPartnerId')) ? '' : 'error'}
                                help={get(errors, 'shippingPartnerId', undefined)}
                            >
                                <SelectShippingPartner
                                    className="_form-search-shipping-partner-id"
                                    placeholder={t('order:placeholder.shipping_partner_id')}
                                    allowClear={true}
                                    value={shippingPartnerId ? parseInt(shippingPartnerId) : undefined}
                                    onChange={(value: any) => _onChangeSelect('shipping_partner_id', value)}
                                    locationByUser
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                            <Form.Item className="mb-0" label={t('label.order_status')}>
                                <SelectStatuses
                                    value={inventoryOrderStatus ? inventoryOrderStatus : undefined}
                                    className="_form-search-status"
                                    placeholder={t('order:placeholder.status')}
                                    mode={false}
                                    allowClear
                                    onChange={(value: any) => _onChangeSelect('inventory_order_status', value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ span: 9 }}>
                            <Form.Item label={t('finance:label.bill_lading_date')}>
                                <RangPickerLimit
                                    value={
                                        createdAtFrom && createdAtTo
                                            ? [moment(createdAtFrom), moment(createdAtTo)]
                                            : undefined
                                    }
                                    format={formatDate}
                                    limit={90}
                                    onChange={date => _onChangeDate('inventory_freight_bill_created_at', date)}
                                    defaultValue={[moment(ninetyDayAgo), moment(nowDay)]}
                                    allowClear={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                            <Form.Item className="mb-3" label={t('finance:label.documents_control_status')}>
                                <DocumentControlStatus
                                    value={inventoryDocumentStatus ? inventoryDocumentStatus : undefined}
                                    placeholder={t('select_status')}
                                    onChange={value => _onChangeSelect('inventory_document_status', value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                            <Form.Item className="mb-3" label={t('finance:label.finance_shipping_status')}>
                                <SelectFinanceShippingStatus
                                    value={shipping_financial_status ? shipping_financial_status : undefined}
                                    onChange={value => _onChangeSelect('shipping_financial_status', value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                            <Form.Item className="mb-3" label={t('order:label.freight_bill')}>
                                <Input
                                    className={'_prepare-order-search-order-code'}
                                    name="freight_bill"
                                    value={freight_bill}
                                    placeholder={t('order:placeholder.freight_bill')}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onPressEnter}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="text-right">
                            <Button className="mr-2 _finance-search-btn-reset" onClick={_onReset}>
                                <UndoOutlined /> {t('common:btn.set_again')}
                            </Button>
                            <Button
                                type="primary"
                                className="_finance-search-btn-search"
                                onClick={() => _onSubmit(input)}
                                loading={loading}
                            >
                                <SearchOutlined /> {t('common:btn.search')}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
    );
};

export default FormSearch;
