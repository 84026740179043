import { Table, Tooltip } from 'antd'
import get from 'lodash/get'
import React from 'react'
import { Link } from 'react-router-dom'

import DynamicTable from '@Modules/App/DynamicTable'

import { t } from '../../../../system/i18n'
import { url } from '../../../../system/routing'
import CustomizedPagination from '../../../App/CustomizedPagination'
import TableEmpty from '../../../App/Utilities/TableEmpty'
import { dateFormatter } from '../../../App/services'

export default function ProductList({ pagination, onSearch, filters, loading, rowSelection, selectedRowKeys, products, renderSelection }) {
    let getColumns = [
        {
            className: '_product-list-category',
            title: t('product:label.category'),
            dataIndex: ['category', 'name'],
            key: 'category',
        },
        {
            className: '_product-list-product-code',
            title: t('product:label.product_code'),
            dataIndex: 'product',
            key: 'product_code',
            render: text => (
                <>
                    <strong style={{ fontWeight: '500' }}>
                        <Link to={url.to('products.detail', { id: text?.id })}>{text?.code}</Link>
                    </strong>
                    <Tooltip title={t('product:label.ubox_product_code')}>
                        <p style={{ fontSize: '11px', color: 'gray' }}>{get(text, 'ubox_product_code', '')}</p>
                    </Tooltip>
                </>
            ),
            disabled: true
        },
        {
            className: '_product-list-product-name',
            title: t('product:label.product_name'),
            dataIndex: ['product', 'name'],
            key: 'product_name',
        },
        {
            className: '_product-list-supplier-name',
            title: t('product:label.supplier'),
            dataIndex: ['supplier', 'name'],
            key: 'supplier_name',
            render: value => {
                return value ? value : '---'
            },
        },
        {
            className: '_product-list-merchant',
            title: t('product:label.merchant_code1'),
            dataIndex: 'merchants',
            key: 'merchant_id',
            render: text =>
                text.map(item => {
                    const name = get(item, 'name', '')
                    const code = get(item, 'code', '')
                    return <p key={item.id}>{code + (name ? ' - ' + name : '')}</p>
                }),
        },
        {
            className: '_product-list-creator',
            title: t('product:label.creator'),
            dataIndex: ['creator', 'name'],
            key: 'creator',
        },
        {
            className: '_product-list-created-at',
            title: t('product:label.created_at'),
            dataIndex: ['product', 'created_at'],
            key: 'sku.created_at',
            render: text => dateFormatter.formatDate(text, 'DD/MM/YYYY HH:mm'),
        },
    ]

    return (
        <div className="m-3">
            {renderSelection()}

            {/* <Table
                className="list-products-table pb-3"
                dataSource={products}
                rowKey={record => record.product.id}
                columns={getColumns}
                pagination={false}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
                loading={loading}
                scroll={{ x: 576 }}
                locale={{
                    emptyText: (
                        <TableEmpty
                            className="_product-list-empty"
                            loading={loading}
                        />
                    ),
                }}
            /> */}
            <DynamicTable
                initColumns={getColumns}
                dataSource={products}
                rowKey={record => record.product.id}
                className="list-products-table pb-3"
                loading={loading}
                rowSelection={rowSelection}
                selectedRowKeys={selectedRowKeys}
            />
            <CustomizedPagination
                addFilter={val => onSearch('pagination', val)}
                filters={filters}
                pagination={pagination}
            />
        </div>
    )
}
