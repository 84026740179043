import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useUpdateOrderMutation = createUseMutation({
    namespace: 'order:update',
    mutation: data => apiService.update(data),
});

export default useUpdateOrderMutation;
