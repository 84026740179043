import { useQuery } from 'react-query'

import processResponseError from '@System/api/processResponseError'
import apiService from '../services/api'


const useSupplierQuery = params => {
    return useQuery(['supplier-manage', params], () =>apiService.getSuppliers(params), {
        retry: false,
        onError: err => {
            processResponseError(err)
        },
    })
}

export default useSupplierQuery
