import { Input, Space, Table, Typography } from 'antd';
import { find, isEmpty } from 'lodash';
import { sumBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import Box from '@Modules/App/Box';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';

import { t } from '@System/i18n';
import { Link, url } from '@System/routing';
import { getVar } from '@System/support/helpers';

const { Text, Title } = Typography;

const EditableContext = React.createContext(null);

function ProductContainer({ storeActions, storeData }) {
    const dataSource = getVar(storeData, 'skus', []);

    const [note, setNote] = useState();
    useEffect(() => {
        setNote(getVar(dataSource, 'note', undefined));
    }, [dataSource]);

    const columns = [
        {
            className: '_product-list-sku-info',
            title: t('order:label.sku_info'),
            dataIndex: 'code',
            render: (_, record) => {
                return (
                    <>
                        <p>
                            <Link to={url.to('products.detail', { id: record?.product_id })}>{record?.code}</Link>
                        </p>
                        <Text type="secondary">{record?.name}</Text>
                    </>
                );
            },
        },
        {
            className: 'text-right text-nowrap _product-list-received-quantity',
            title: t('product:label.real_receiver_quantity'),
            dataIndex: 'receiverQuantity',
            width: '30%',
            // editable: true,
            render: (value, record) => {
                const { quantity } = record;

                return (
                    <div className="d-flex align-items-center">
                        <Input value={value ? value : 0} disabled />
                        <div className="ml-2">/{quantity ? quantity : 0}</div>
                    </div>
                );
            },
        },
    ];

    const disabled = getVar(storeData, 'isSaved', false);
    const onUpdate = (name, value) => storeActions.updateScanInfo({ [name]: value });

    return (
        <Box className="aside">
            <div className="d-flex justify-content-between border-bottom align-items-center">
                <Space className="mb-3">
                    <Title level={3}>{t('order:title.list_products')}</Title>
                    <span className="number-circle">{dataSource.length}</span>
                </Space>
            </div>
            <Table
                rowKey={record => record?.code}
                className="_product-list-info"
                footer={() => {
                    return dataSource.length > 0 ? (
                        <div className="d-flex justify-content-between">
                            <strong>{t('sum')}</strong>
                            <strong className="_product-list-total">
                                {sumBy(dataSource, 'quantity')
                                    ? `${+sumBy(dataSource, 'receiverQuantity')}/${+sumBy(dataSource, 'quantity')}`
                                    : `${sumBy(dataSource, 'receiverQuantity')}`}
                            </strong>
                        </div>
                    ) : null;
                }}
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                locale={{ emptyText: <TableEmpty className="_product-list-no-data" /> }}
            />
            {!isEmpty(dataSource) && (
                <div className="mt-2">
                    <Input.TextArea
                        className="_product-list-note"
                        placeholder={t('note')}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        value={note}
                        onBlur={e => onUpdate('note', e.target.value.trim())}
                        onChange={e => setNote(e.target.value)}
                        disabled={disabled}
                    />
                </div>
            )}
        </Box>
    );
}

export default ProductContainer;
