import { Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { t } from '../../../system/i18n';
import { warehouseAreaStorage } from '../../App/services';
import ServiceWarehouseAreas from '../services/ServiceWarehouseAreas';

const { Option } = Select;

export default function SelectWarehouseArea(props) {
    const { warehouseid, onChange, initDefault } = props;
    let [data, setData] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceWarehouseAreas.list(warehouseid)
            .then(res => {
                setData(res);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [warehouseid]);

    useEffect(() => {
        data.map(warehouseArea => {
            if (warehouseArea.code === 'default' && initDefault && props.value === undefined) {
                onChange(warehouseArea.id);
            }
        });
    }, [data]);

    function _onChange(value, option) {
        if (!props.noSuggest) warehouseAreaStorage.setValue(value);

        if (typeof onChange === 'function') onChange(value, option);
    }

    return (
        <Select
            loading={isLoading}
            {...props}
            onChange={_onChange}
            value={data.length > 0 && props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {data.map(warehouseArea => (
                <Option key={warehouseArea.id} value={warehouseArea.id}>
                    {(warehouseArea.name === 'default' ? t('order:label.default') : warehouseArea.name) +
                        (warehouseArea.code
                            ? ' (' + (warehouseArea.code === 'default' ? t('order:label.default') : warehouseArea.code) + ')'
                            : '')}
                </Option>
            ))}
        </Select>
    );
}

SelectWarehouseArea.propTypes = {
    noSuggest: PropTypes.bool,
    initDefault: PropTypes.bool,
};

SelectWarehouseArea.defaultProps = {
    noSuggest: true,
    initDefault: false,
};
