import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { get, omit, map } from 'lodash';
import React, { useState, useEffect } from 'react';

import { events } from '../../../../system/events';
import { t } from '../../../../system/i18n';
import { url } from '../../../../system/routing';
import useFilter from '../../../App/Hooks/useFilter';
import useLocalStorageState from '../../../App/Hooks/useLocalStorageState';
import { setDocumentTitle, setNavigator } from '../../../App/services';
import { auth } from '../../../Auth/services';
import PERMISSIONS from '../../../Auth/services/permissions';
import useListPrepareOrderQuery from '../../Hooks/useListPrepareOrderQuery';
import UploadFreightBillFile from '../../components/ImportData/UploadFreightBillFile';
import { EVENTS, ORDER_PACKING_STATUS } from '../../services/constants';
import useDeepCompareEffect from './../../../App/Hooks/useDeepCompareEffect';
import useTableSelection from './../../../App/Hooks/useTableSelection';
import FormSearch from './FormSearch';
import List from './List';
import { useSettingConfigQuery } from '@Modules/OperationConfig/hooks/useSettingConfigQuery';

export default function ListPrepareOrder({ history, location }) {
    const [warehouseId, setWarehouseId] = useLocalStorageState('warehouse');
    const { filters, addFilter } = useFilter(history, location, {
        order_status: ORDER_PACKING_STATUS.WAITING_PROCESSING.key,
        warehouse_id: warehouseId,
    });
    const [pagination, setPagination] = useState({});
    const { data, isLoading, refetch } = useListPrepareOrderQuery(filters);
    const {data: setting} = useSettingConfigQuery() 
    const {
        isSelectAllPage,
        rowSelection,
        selectedRowKeys,
        selectedRows,
        clearSelection,
        render: renderSelection,
        setSelectedRowKeys,
    } = useTableSelection({
        totalInAllPage: pagination?.total || 0,
        totalInCurrentPage: get(data, 'data.order_packings.length', 0),
        totalPage: pagination?.page_total || 0,
        type: t('order:requirement_prepare_orders').toLowerCase(),
    });
    

    useEffect(() => {
        setNavigator(t('title.requirement_prepare_orders'), [
            {
                name: t('breadcrumb.requirement_prepare_orders'),
            },
        ]);
        setDocumentTitle(t('title.requirement_prepare_orders'));
        return events.listen(EVENTS.IMPORT_ORDER_FROM_EXCEL, () => refetch());

        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        return events.listen(EVENTS.RELOAD_ORDER_PREPARE_LIST, () =>refetch());
    }, [refetch]);

    useEffect(() => {
        refetch();
    }, [filters, refetch]);

    useDeepCompareEffect(() => {
        clearSelection();
    }, [omit(filters, 'page'), clearSelection]);

    useEffect(() => {
        const pagination = get(data, 'data.pagination', {});
        setPagination(pagination);
    }, [data]);

    function handleSearch(filters) {
        addFilter(filters);
        setWarehouseId(filters.warehouse_id);
    }

    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{t('common:heading.requirement_prepare_orders')}</h2>
                    <div className="pr-3 text-right">
                        <UploadFreightBillFile />
                        {auth.can(PERMISSIONS.ORDER_CREATE) && (
                            <>
                                <Button
                                    type="primary"
                                    className="ml-2 mt-2 _order-btn-create-order"
                                    onClick={() => url.redirectTo('orders.create')}
                                >
                                    <PlusOutlined /> {t('order:btn.create_order')}
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <FormSearch addFilter={handleSearch} filters={filters} loading={isLoading} pagination={pagination} setting={setting?.data} />
            </div>
            <div className="bg-white pt-2">
                <List
                    orderPackingsData={data?.data}
                    addFilter={addFilter}
                    filters={filters}
                    loading={isLoading}
                    rowSelection={rowSelection}
                    renderSelection={renderSelection}
                    isSelectAllPage={isSelectAllPage}
                    ids={selectedRowKeys}
                    orderPackings={selectedRows}
                    setSelectedRowKeys={setSelectedRowKeys}
                    pagination={pagination}
                    freightBills={map(selectedRows, 'freight_bill')}
                    setting={setting?.data}
                />
            </div>
        </div>
    );
}
