import { Button, DatePicker, Form, Input, Modal } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'

import InputNotSpace from '@Modules/App/Utilities/InputNotSpace'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import { INPUT_INVALID } from '@Modules/PurchasingPackage/services/constants'

import { t } from '@System/i18n'
import notification from '@System/notification'

import apiService from '../services/api'
import InputNumberCurrency from './InputNumberCurrency'

const CreateTransaction = ({ idSupplier, refetchDetail, refetch, reFetchDetailPay }) => {
    const [form] = Form.useForm()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleCreateTransaction = () => {
        // setIsModalOpen(false)
        form.validateFields().then(values => {
            setLoading(true)

            const data = { ...values, payment_time: moment(values.payment_time).format('YYYY-MM-DD') }
            apiService
                .createTransactionSupplier(idSupplier, data)
                .then(result => {
                    handleCancel()
                    refetch()
                    refetchDetail()
                    reFetchDetailPay()
                    notification.success(t('config:supplier.message.create_transaction_success'))
                })
                .catch(err => {
                    const code = get(err, 'response.data.code')
                    const data = get(err, 'response.data.data')

                    if (code === 'REQUEST_PROCESSED') {
                        return notification.warning(t('message.request_process'))
                    }

                    if (code === INPUT_INVALID) {
                        Object.entries(data).forEach(([key, value]) => {
                            notification.error(
                                t(`config:supplier.message.${Object.keys(value)[0]}`, {
                                    attribute: t(`config:supplier.label.${key}`),
                                })
                            )
                        })
                    } else {
                        notification.error(t('config:supplier.message.create_transaction_fail'))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields()
    }
    //
    return (
        <>
            {auth.can(permissions.FINANCE_CREATE_SUPPLIER_TRANSACTION) && (
                <Button
                    type="primary"
                    onClick={showModal}
                >
                    {t('config:supplier.btn.transaction_create')}
                </Button>
            )}
            {isModalOpen && (
                <Modal
                    title={t('config:supplier.btn.transaction_create')}
                    visible={true}
                    onOk={handleCreateTransaction}
                    onCancel={handleCancel}
                    okButtonProps={{ loading }}
                    cancelText={t('btn.cancel')}
                    okText={t('btn.ok')}
                >
                    <Form
                        layout="vertical"
                        form={form}
                    >
                        <Form.Item
                            label={t('config:supplier.label.cost')}
                            name="amount"
                            rules={[
                                {
                                    required: true,
                                    message: t('order:message.required', { attribute: t('config:supplier.label.cost') }),
                                },
                            ]}
                        >
                            <InputNumberCurrency
                                placeholder={t('config:supplier.placeholder.cost')}
                                className="w-100"
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('config:supplier.label.payment_time')}
                            name="payment_time"
                            rules={[{ required: true, message: t('order:message.required', { attribute: t('config:supplier.label.payment_time') }) }]}
                        >
                            <DatePicker
                                placeholder={t('config:supplier.placeholder.select_time')}
                                style={{ width: '100%' }}
                                format={'DD/MM/YYYY'}
                                allowClear={false}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('config:supplier.label.transaction_code')}
                            name="transaction_code"
                            rules={[{ required: true, message: t('order:message.required', { attribute: t('config:supplier.label.transaction_code') }) }]}
                        >
                            <InputNotSpace placeholder={t('config:supplier.placeholder.transaction_code')} />
                        </Form.Item>
                        <Form.Item
                            label={t('note')}
                            name="note"
                            rules={[{ required: true, message: t('order:message.required', { attribute: t('note') }) }]}
                        >
                            <Input.TextArea
                                placeholder={t('warehouse:placeholder.note')}
                                rows={3}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default CreateTransaction
