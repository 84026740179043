import { generatorHashKey } from '@System/support/helpers';

const { api } = require('@System/api');

const BASE_URL = 'document-sku-inventories';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    add: data => api.post(BASE_URL, data, { idempotencyKey: generatorHashKey(data) }), // Tạo chứng từ kiểm kê
    detail: id => api.get(`${BASE_URL}/${id}`), // Chi tiết chứng từ kiểm kê
    update: data => api.put(`${BASE_URL}/${data.id}`, data), // Cập nhật thông tin chứng từ kiểm kê kho
    scan: data => api.post(`${BASE_URL}/${data.id}/scan`, data), // Quét kiểm kê 1 sản phẩm trong kho
    scanHistories: id => api.get(`${BASE_URL}/${id}/scan-histories`), // Lịch sử quét kiểm kê của 1 chứng từ kiểm kê
    importSkus: data => api.post(`${BASE_URL}/${data.get('id')}/importing-skus`, data), // Nhập vào danh sách các sản phẩm kiểm kê kho
    balanced: id => api.post(`${BASE_URL}/${id}/balanced`), // Cân bằng số lượng sku sau kiểm kê
};
