import PackList from '@Modules/ServicesManager/components/ServicePack/PackList'
import React from 'react'

interface IServicePackProps {
    countryId: number
}

const ServicePack: React.FC<IServicePackProps> = ({ countryId }) => {
    return <PackList countryId={countryId} />
}

export default ServicePack
