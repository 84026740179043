import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import apiService from './../services/api';

const useProductListOrderPacking = (id, params) => {
    return useQuery(
        [
            'product-list-order-packing',
            {
                ...id,
                params,
            },
        ],
        () => apiService.getProductListOrderPacking(id, params),
        {
            retry: false,
            onError: err => {
                processResponseError(err);
            },
        }
    );
};

export default useProductListOrderPacking;
