import React, {useState} from "react";
import SelectSku from "@Modules/Product/components/SelectSku";
import api from "@Modules/PurchasingOrder/services/api";
import {catchErrors} from "@Modules/App/services/helpers";
import {t} from "@System/i18n";
import _ from "lodash";
import {EditOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {Modal} from "antd";
import {events} from "@System/events";
import {EVENTS} from "@Modules/PurchasingOrder/services/constants";

export default function MapSkuToVariant({orderDetail, updateOrderDetail, purchasingOrderItem, fetchPurchasingOrders}) {
    const purchasing_variant = _.get(purchasingOrderItem, "purchasing_variant", null);
    const sku = _.get(purchasingOrderItem, "purchasing_variant.sku", null);
    const [loading, setLoading] = useState(false);
    const [isEdited, setIsEdit] = useState(false);

    function _onChange(sku_id) {
        if (!sku_id)
            return;

        setLoading(true);
        api.mapSkuToVariant(orderDetail.id, purchasing_variant.id, {sku_id})
            .then(res => {
                let purchasing_variant = _.get(res, "data.purchasing_variant", {});
                updateOrderDetail(purchasing_variant);
                setIsEdit(false);
                fetchPurchasingOrders();
                events.dispatch(EVENTS.FETCH_PURCHASING_ORDER_AGAIN, {});
            })
            .catch(catchErrors(t("purchasingOrder:message.map_sku_fail")))
            .finally(() => setLoading(false));
    }

    function _onBlur() {
        if (sku && isEdited)
            setIsEdit(false);
    }

    function _onClick() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            cancelText: t('btn.cancel'),
            className: '_confirm-delete-purchasing-account-modal',
            okText: t('btn.confirm'),
            title: t('purchasingOrder:message.map_sku_warm_title'),
            content: t('purchasingOrder:message.map_sku_warm_content'),
            onOk: () => setIsEdit(true),
            width: 480
        });
    }

    return ( sku && !isEdited
        ? <>
            <span>({sku.code}) - {sku.name}</span>
            <span
                className="cursor-pointer ml-2 text-primary"
                onClick={_onClick}
            ><EditOutlined /></span>
        </>
        : <SelectSku
            onChange={_onChange}
            onBlur={_onBlur}
            style={{width: "200px"}}
            loading={loading}
            placeholder={t("purchasingOrder:placeholder.sku_code")}
        />
    );
}
