import React from 'react';

import { t } from '@System/i18n';

import TableCustomize from './TableCustomize';

const List = ({ input, warehouseAreaList, childrenReset }) => {
    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">{t('product_list')}</h3>
                </div>

                <div className="data-box--body">
                    <TableCustomize input={input} warehouseAreaList={warehouseAreaList} childrenReset={childrenReset}/>
                </div>
            </div>
        </div>
    );
};

export default List;
