import { api } from '@System/api';

export default {
    getProductsDropship: params =>
        api.get('/product-drop-ship', {
            params,
            singleRequest: true,
            loading: false,
        }),
    getQuotation: (id, params) =>
        api.get(`/products/${id}/prices`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    createQuotation: (id, data) => api.post(`products/${id}/prices`, data),
    changeStatusQuotation: productPriceId => api.put(`product-prices/${productPriceId}/cancel`, {}),
    getDetailQuotation: id =>
        api.get(`product-prices/${id}`, {
            singleRequest: true,
            loading: false,
        }),
};
