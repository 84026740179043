import { Button, Modal, Space, Table, Tooltip } from 'antd'
import { WarningOutlined } from '@ant-design/icons'
import { capitalize, get, isEmpty, isEqual, map } from 'lodash'
import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react'

import ModalConfirm from '@Modules/App/Modal/ModalConfirm'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import { ServicePackGroup } from '@Modules/ServicesManager/Interfaces/interfaces'
import api from '@Modules/ServicesManager/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { formatCurrency } from '@System/support/numberFormat'

import ModalAddService from './ModalAddService'

interface IServicePackDetailProps {
    countryId: number
    handleHideModal: () => void
    servicePackId: number
    handleCreateServiceCombo: (data) => void
}

const ServicePackDetail: React.FC<IServicePackDetailProps> = ({ handleHideModal, servicePackId, countryId, handleCreateServiceCombo }) => {
    const [serviceGroup, setServiceGroup] = useState<ServicePackGroup[]>([])
    const [serviceDetail, setServiceDetail] = useState<ServicePackGroup>({} as ServicePackGroup)
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false)
    const [saveData, setSaveData] = useState([])
    const [visibleModalAdd, setVisibleModalAdd] = useState(false)
    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const [currency, setCurrency] = useState({} as any)

    const getDetails = useCallback(async () => {
        setLoading(true)
        try {
            const res = await api.getServicesPackDetail(servicePackId)
            setData(get(res, 'data'))
            setServiceGroup(get(res, 'data.service_pack_prices'))
            setCurrency(get(res, 'data.country.currency'))
            setSaveData(get(res, 'data.service_pack_prices'))
            setServiceDetail(get(res, 'data.service_pack'))
        } catch (error) {
            processResponseError(error)
        } finally {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        getDetails()
    }, [servicePackId])

    const columns = [
        {
            className: ` _manage-service-column-type`,
            title: t('label.service_groups'),
            dataIndex: ['service', 'type'],
            key: 'type',
            render: text => <span>{t(`service_manage_status.${text}`)}</span>,
        },
        {
            className: ` _manage-service-column-name`,
            title: t('label.service_name'),
            dataIndex: ['service', 'name'],
            key: 'name',
        },
        {
            className: ` _manage-service-column-service_code`,
            title: t('label.service_code'),
            dataIndex: ['service', 'code'],
            key: 'code',
        },
        {
            className: `_manage-service-column-price_level`,
            title: t('label.price_level'),
            dataIndex: ['service_price', 'label'],
            key: 'label',
            render: (text, record) => {
                const price = get(record, 'service_price.price')
                return <span>{text ? `${text} - ${isEmpty(currency) ? price : formatCurrency(price || 0, currency)}` : t('noText')}</span>
            },
        },
        {
            className: `_manage-service-column-additional_costs`,
            title: t('label.additional_costs'),
            dataIndex: ['service_price', 'yield_price'],
            key: 'yield_price',
            render: text => {
                return <span>{isEmpty(currency) ? text : formatCurrency(text || 0, currency)}</span>
            },
        },
        {
            className: `_manage-service-column-extra_day`,
            title: t('label.extra_day'),
            dataIndex: ['service_pack_price', 'created_at'],
            key: 'created_at',
            render: text => {
                return <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : t('noText')}</p>
            },
        },
        {
            className: `_manage-service-column-note`,
            title: t('note'),
            dataIndex: ['service_price', 'note'],
            key: 'note',
            render: text => {
                return <span>{text ? text : t('noText')}</span>
            },
        },
        {
            className: `_manage-service-column-func`,
            dataIndex: ['service', 'id'],
            key: 'func',
            render: text => {
                return (
                    auth.can(permissions.SERVICE_ADD) && (
                        <Button
                            danger
                            onClick={() => handleRemoveRow(text)}
                        >
                            {t('btn.delete')}
                        </Button>
                    )
                )
            },
        },
    ]

    const handleRemoveRow = id => {
        setServiceGroup(prev => prev.filter(item => item.service.id !== id))
    }

    const handleUndoData = () => {
        setServiceGroup(saveData)
    }

    const handleShowModalAddService = () => {
        setVisibleModalAdd(true)
    }

    const handleHideModalAddService = () => {
        setVisibleModalAdd(false)
    }

    const handleSavaChange = () => {
        if (isEmpty(serviceGroup)) {
            notification.error(t('message.need_select_service'))
            return
        }

        const data = {
            service_price_ids: map(serviceGroup, 'service_price.id'),
        }

        setLoading(true)
        api.editServicePack(servicePackId, data)
            .then(res => {
                notification.success(t('message.edit_service_pack_success'))
                setVisibleConfirm(false)
                getDetails()
            })
            .catch(error => {
                const {
                    response: { data },
                } = error
                renderError(data)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderError = error => {
        const { data } = error
        if (!isEmpty(data)) {
            Object.keys(data).forEach(key => {
                Object.keys(data[key]).forEach(error => {
                    if (key === 'code') {
                        notification.error(t(`message.${error}`, { attribute: t(`label.service_code`) }))
                        return
                    }

                    if (error === 'missing') {
                        notification.error(t(`message.missing`, { attribute: `4 ${capitalize(t(`label.service_groups`))}` }))
                        return
                    }
                })
            })
        } else {
            notification.error(t('message.edit_service_pack_failed'))
        }
    }

    const handleConfirmSave = () => {
        if (isEqual(serviceGroup, saveData)) {
            return
        }
        setVisibleConfirm(true)
    }

    return (
        <>
            <Modal
                title={
                    <div className="d-flex align-items-center">
                        <p>{t('label.pack_service_detail')}</p>
                        <Tooltip title={t('serviceManager:message.warning_change_service_seller')}>
                            <WarningOutlined className="ml-2 cursor-pointer text-warning"/>
                        </Tooltip>
                    </div>
                }
                visible={true}
                cancelText={t('btn.close')}
                onCancel={handleHideModal}
                width={1000}
                maskClosable={false}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <div>
                    <div className="mb-2">
                        <span className="mr-3 text-fz-15">{t('label.service_name')}:</span>
                        <span className="text-fz-15">{get(serviceDetail, 'name')}</span>
                    </div>
                    <div>
                        <span className="mr-3 text-fz-15">{t('label.service_code')}:</span>
                        <span className="text-fz-15">{get(serviceDetail, 'code')}</span>
                    </div>
                </div>
                {/* <Form
                    wrapperCol={{
                        span: 8,
                    }}
                >
                    <Form.Item label={t('label.service_name')}>
                        <Input
                            disabled
                            value={get(serviceDetail, 'name')}
                        />
                    </Form.Item>
                    <Form.Item label={t('label.service_code')}>
                        <Input
                            value={get(serviceDetail, 'code')}
                            disabled
                        />
                    </Form.Item>
                </Form> */}

                <div className="d-flex justify-content-end">
                    <Space>
                        {auth.can(permissions.SERVICE_ADD) && (
                            <>
                                <Button
                                    type="primary"
                                    onClick={() => handleCreateServiceCombo(servicePackId)}
                                    disabled={loading}
                                >
                                    {t('serviceManager:btn.create_service_combo')}
                                </Button>
                                <Button
                                    onClick={handleUndoData}
                                    disabled={loading}
                                >
                                    {t('label.undo')}
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={handleConfirmSave}
                                    loading={loading}
                                >
                                    {t('label.save_change')}
                                </Button>
                            </>
                        )}
                    </Space>
                </div>

                <Table
                    rowKey={(record: any) => record?.service?.id}
                    rowClassName={'service-list-table'}
                    dataSource={serviceGroup}
                    scroll={{ x: 768 }}
                    columns={columns}
                    loading={loading}
                    pagination={false}
                    locale={{
                        emptyText: (
                            <TableEmpty
                                className="_product-list-empty"
                                loading={loading}
                            />
                        ),
                    }}
                />
                {auth.can(permissions.SERVICE_ADD) && (
                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            type="primary"
                            disabled={loading}
                            onClick={handleShowModalAddService}
                        >
                            {t('btn.add_service')}
                        </Button>
                    </div>
                )}
            </Modal>
            {visibleModalAdd && (
                <ModalAddService
                    countryId={countryId}
                    serviceGroup={serviceGroup}
                    setServiceGroup={setServiceGroup}
                    handleHideModal={handleHideModalAddService}
                />
            )}
            {visibleConfirm && (
                <ModalConfirm
                    content={t('message.confirm_save_edit')}
                    handleHideModal={() => setVisibleConfirm(false)}
                    visible={visibleConfirm}
                    cancelButtonProps={{ className: '_modal-confirm-delete-exporting-document-btn-cancel' }}
                    okButtonProps={{
                        className: '_modal-confirm-delete-exporting-document-btn-ok',
                        loading: loading,
                        onClick: handleSavaChange,
                    }}
                />
            )}
        </>
    )
}

export default ServicePackDetail
