import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';
import api from "@Modules/Finance/services/api";

const useListFinanceDeliveryControlQuery = params => {
    return useQuery(['finance-delivery-control', params], () => api.getListControlDeliveryDocument(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useListFinanceDeliveryControlQuery;
