import React, {useEffect, useState} from "react";
import {Select} from "antd";
import PropTypes from "prop-types";
import {t} from "../../../system/i18n";
import findIndex from "lodash/findIndex";
import ServiceWarehouses from "../services/ServiceWarehouses";
import {warehouseStorage, warehouseAreaStorage} from "../../App/services";

const {Option} = Select;

export default function SelectWarehouse(props) {
    let [data, setData] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    let {onChange} = props;

    useEffect(() => {
        setIsLoading(true);
        ServiceWarehouses.list()
            .then(res => {
                setData(res);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    useEffect(() => {
        if (data.length > 0 && props.value && !isNaN(Number(props.value)) && (findIndex(data, ["id", Number(props.value)]) === -1)) {
            _onChange(undefined)
        }
    }, [data]);

    function _onChange(value, option) {
        if (!props.noSuggest) {
            warehouseStorage.setValue(value);
            warehouseAreaStorage.reset();
        }

        if (typeof onChange === "function")
            onChange(value, option);
    }

    return (
        <Select
            loading={isLoading}
            {...props}
            onChange={_onChange}
            value={(data.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                data.map(warehouse => (
                    <Option key={warehouse.id} value={warehouse.id}>
                        {
                            warehouse.name + (warehouse.code ? " (" + (warehouse.code === "default" ? t("order:label.default") : warehouse.code) + ")" : "")
                        }
                    </Option>
                ))
            }
        </Select>
    );
}

SelectWarehouse.propTypes = {
    noSuggest: PropTypes.bool
};

SelectWarehouse.defaultProps = {
    noSuggest: true
};
