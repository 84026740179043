import { ReloadOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Modal, Row, Col, Input, Select, Typography } from 'antd'
import { omit, map, isEmpty, get, filter } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import Box from '@Modules/App/Box'
import useSize from '@Modules/App/Hooks/useSize'
import { warehouseStorage } from '@Modules/App/services'
import SelectMerchants from '@Modules/Merchant/components/SelectMerchants'
import MapSkuModal from '@Modules/PurchasingOrder/screens/ListPurchasingOrders/MapSkuModal'
import { EVENTS } from '@Modules/PurchasingOrder/services/constants'
import ScanBarcode from '@Modules/ScanOrder/components/ScanBarcode'
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse'

import { clearErrors } from '@State/scanErrors/actions'
import { useScanErrorsOfType } from '@State/scanErrors/hooks'

import { events } from '@System/events'
import useGeneratorKey, { IdempotencyStorage } from '@System/hooks/useGeneratorKey'
import { t, trans } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

import api from '../../../../services/api'
import { SCAN_PURCHASE_PACKAGE_TYPE } from '../../../../services/constants'
import LeavePageBlocker from '../../components/LeavePageBlocker'

import styles from '../scan-purchase-package.module.scss'

const { Option } = Select
const { Title } = Typography

export default function FormScan({
    barcodeInputRef,
    dataScan,
    errorKey,
    isScanning,
    mapOrder,
    storeActions,
    storeData,
    form,
    setMapOrder,
    scanData,
    validatedRules,
}) {
    const { uniqueKey, generatorKey } = useGeneratorKey()
    const [dirty, setDirty] = useState(false)

    const { isMobile } = useSize()
    const [loading, setLoading] = useState(false)
    const purchasePackages = getVar(storeData, 'purchase_packages', [])
    const isSaved = getVar(storeData, 'isSaved', false)
    const [mapSkuSuccess, setMapSkuSuccess] = useState(false)
    const scanErrors = useScanErrorsOfType(errorKey)
    const dispatch = useDispatch()

    useEffect(() => {
        let attrs = Object.keys(omit(storeData, ['purchase_packages'])).map(item => ({
            name: item,
            value: storeData[item],
        }))
        form.setFields(attrs)

        if (!storeData.warehouse_id && warehouseStorage.value) _onChange('warehouse_id', warehouseStorage.value)

        if (!storeData.barcode_type) _onChange('barcode_type', SCAN_PURCHASE_PACKAGE_TYPE.PACKAGE_CODE)

        if (!storeData.merchant_id) _onChange('merchant_id', 0)
    }, [])

    useEffect(() => {
        return events.listen(EVENTS.FETCH_PURCHASING_ORDER_AGAIN, () => setMapSkuSuccess(true))
    }, [])

    function closeModalMapSku() {
        setMapOrder(null)
        if (mapSkuSuccess) {
            scanData(dataScan)
            setMapSkuSuccess(false)
        }
    }

    function _onChange(name, value) {
        storeActions.updateScanInfo({ [name]: value })
    }

    /**
     * Reset form và localStorage về mặc định
     * Nếu kho được cache thì lấy giá trị cache nếu ko thì bỏ qua
     */
    function _onReset() {
        const barcode_type = getVar(storeData, 'barcode_type', SCAN_PURCHASE_PACKAGE_TYPE.PACKAGE_CODE)
        const warehouse_id = getVar(storeData, 'warehouse_id', warehouseStorage.value)
        const merchant_id = getVar(storeData, 'merchant_id', 0)
        let defaultValues = { barcode_type, merchant_id, warehouse_id }

        let formData = [
            { name: 'barcode_type', value: barcode_type },
            { name: 'merchant_id', value: merchant_id },
            { name: 'warehouse_id', value: warehouse_id },
        ]
        storeActions.newScan(defaultValues)
        form.setFields(formData)
    }

    function _onSave() {
        const doSave = () => {
            const object_ids = map(purchasePackages, 'id')

            const data = omit(storeData, ['purchase_packages', 'hasService'])

            const serviceData = filter(get(storeData, 'services', []), item => !isEmpty(item?.sku_ids))

            const newData = { ...data, services: serviceData }

            form.validateFields().then(() => {
                setLoading(true)
                setDirty(true)
                api.createDocumentImportingByPurchaseOrder({ ...newData, object_ids })
                    .then(res => {
                        setDirty(false)
                        const dataImportingDocument = getVar(res, 'data.document', {})
                        storeActions.isSaved()
                        notification.success(t('document:messages.scan_importing_skus_successfully'))
                        dispatch(clearErrors(errorKey))
                        url.redirectTo('documents.importing.detail', {
                            id: dataImportingDocument?.id,
                        })
                    })
                    .catch(error => {
                        const { response } = error
                        const code = get(response, 'data.code')
                        const errors = get(response, 'data.data')

                        if (code === 'REQUEST_PROCESSED') {
                            setDirty(true)
                            notification.warning(t('message.request_process'))
                        } else {
                            const sku_serial_number_invalid = get(errors, 'sku_serial_number_invalid')
                            generatorKey()
                            setDirty(false)

                            if (!isEmpty(sku_serial_number_invalid)) {
                                notification.error(
                                    trans(`document:messages.sku_serial_number_invalid`, {
                                        sku_code: <b>{get(sku_serial_number_invalid, 'sku_code', '')}</b>,
                                        serial: <b>{get(sku_serial_number_invalid, 'serial', '')}</b>,
                                    })
                                )
                            } else {
                                notification.error(t('document:messages.scan_importing_skus_fail'))
                            }
                        }
                    })
                    .finally(() => setLoading(false))
            })
        }

        if (scanErrors?.length) {
            Modal.confirm({
                title: t('document:create_importing_document.scan_errors_confirm'),
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
                onOk: doSave,
            })
        } else {
            doSave()
        }
    }

    return (
        <>
            {dirty && <LeavePageBlocker when={dirty} />}
            <Box className={styles.header}>
                <div className="search-box">
                    <div className="search-box--title d-lg-flex justify-content-between pb-4">
                        <Title level={3}>{t('title.import_by_purchase_package')}</Title>
                        <div>
                            <span className="mr-2">
                                <Button
                                    className="_import-by-purchase-package-scan-reset-btn rounded-4 mt-2 mt-md-0"
                                    onClick={_onReset}
                                >
                                    <ReloadOutlined /> {t('btn.reset')}
                                </Button>
                            </span>
                            <Button
                                className="_import-by-purchase-package-scan-save-btn rounded-4 mt-2 mt-md-0"
                                type="primary"
                                disabled={!purchasePackages || purchasePackages.length === 0 || isSaved}
                                onClick={_onSave}
                                loading={loading}
                            >
                                <SaveOutlined /> {t('btn.save')}
                            </Button>
                        </div>
                    </div>

                    <div className="search-box--body">
                        <Row gutter={20}>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                            >
                                <Form.Item
                                    className="mb-3"
                                    name="sender_name"
                                    label={t('document:label.delivered_person')}
                                >
                                    <Input
                                        className="rounded-4 _import-by-purchase-package-scan-receiver-name"
                                        placeholder={t('document:placeholder.delivered_name')}
                                        onChange={e => _onChange('sender_name', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                            >
                                <Form.Item
                                    className="mb-3"
                                    name="sender_phone"
                                    label={isMobile ? '' : ' '}
                                >
                                    <Input
                                        className="rounded-4 _import-by-purchase-package-scan-receiver-phone"
                                        placeholder={t('document:placeholder.delivered_mobile')}
                                        onChange={e => _onChange('sender_phone', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                            >
                                <Form.Item
                                    className="mb-3"
                                    name="sender_license"
                                    label={isMobile ? '' : ' '}
                                >
                                    <Input
                                        className="rounded-4 _import-by-purchase-package-scan-receiver-license"
                                        placeholder={t('document:placeholder.delivered_vehicle_number')}
                                        onChange={e => _onChange('sender_license', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                            >
                                <Form.Item
                                    className="mb-3"
                                    name="sender_partner"
                                    label={t('document:label.delivered_company')}
                                >
                                    <Input
                                        className="rounded-4 _import-by-purchase-package-scan-partner"
                                        placeholder={t('document:placeholder.delivered_company')}
                                        onChange={e => _onChange('sender_partner', e.target.value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 6 }}
                            >
                                <Form.Item
                                    className="mb-0"
                                    name="warehouse_id"
                                    label={t('label.select_warehouses')}
                                    rules={validatedRules.warehouse_id}
                                >
                                    <SelectWarehouse
                                        className="_import-by-purchase-package-scan-warehouse-id"
                                        allowClear={true}
                                        placeholder={t('product:placeholder.warehouse_name')}
                                        onChange={value => _onChange('warehouse_id', value)}
                                        noSuggest={false}
                                        disabled={purchasePackages && purchasePackages.length > 0}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 6 }}
                            >
                                <Form.Item
                                    className="mb-0"
                                    name="barcode_type"
                                    label={t('label.scan_type')}
                                    rules={validatedRules.barcode_type}
                                >
                                    <Select
                                        className="_import-by-purchase-package-scan-barcode-type"
                                        allowClear={true}
                                        placeholder={t('placeholder.scan_type')}
                                        onChange={value => _onChange('barcode_type', value)}
                                        disabled={purchasePackages && purchasePackages.length > 0}
                                    >
                                        {Object.values(SCAN_PURCHASE_PACKAGE_TYPE).map(item => (
                                            <Option
                                                key={item}
                                                value={item}
                                            >
                                                {t(`document:import_by_purchase_package.scan_type.${item}`)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col
                                xs={{ span: 12 }}
                                lg={{ span: 6 }}
                            >
                                <Form.Item
                                    className="mb-0"
                                    name="merchant_id"
                                    label={t('label.select_seller')}
                                    rules={validatedRules.merchant_id}
                                >
                                    <SelectMerchants
                                        alloption={1}
                                        className="_import-by-purchase-package-scan-seller-id"
                                        allowClear={true}
                                        onChange={value => _onChange('merchant_id', value)}
                                        disabled={purchasePackages && purchasePackages.length > 0}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{ span: 24 }}
                                lg={{ span: 6 }}
                            >
                                <Form.Item
                                    className={`mb-0 ${isMobile ? 'mt-3' : ''}`}
                                    name="barcode"
                                    label={t('order:label.barcode')}
                                    rules={validatedRules.barcode}
                                >
                                    <Input
                                        className="_import-by-purchase-package-scan-barcode"
                                        onPressEnter={e => scanData({ barcode: e.target.value })}
                                        placeholder={t('order:placeholder.barcode', {
                                            typeAction: storeData.barcode_type
                                                ? t(`document:import_by_purchase_package.scan_type.${storeData.barcode_type}`)
                                                : '',
                                        })}
                                        ref={barcodeInputRef}
                                        suffix={
                                            <ScanBarcode
                                                onUpdate={value => scanData({ barcode: value })}
                                                disabled={isScanning || isSaved}
                                            />
                                        }
                                        disabled={(purchasePackages && purchasePackages.length > 0) || isScanning || isSaved}
                                        autoComplete="off"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>

                    {mapOrder && (
                        <MapSkuModal
                            mapOrder={mapOrder}
                            setMapOrder={closeModalMapSku}
                            fetchPurchasingOrders={() => {}}
                        />
                    )}
                </div>
            </Box>
        </>
    )
}
