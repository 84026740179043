import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';

import { SHIPPING_PARTNER_CODE_ALLOW_EXPORT_FILE_CREATE_FREIGHT_BILL } from '@Modules/Order/services/constants';
import ServiceShippingPartners from '@Modules/ShippingPartners/services/ServiceShippingPartners';

import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { convertBlobDataToExcel, getVar } from '@System/support/helpers';

import api from '../../services/api';

export default function ExportOrderPrepareByShippingPartner({
    shippingPartnerId,
    ids,
    isSelectAllPage,
    pagination,
    filters,
}) {
    const [shippingPartners, setShippingPartners] = useState([]);
    const [shippingPartnerCode, setShippingPartnerCode] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let isMounted = true;
        ServiceShippingPartners.list().then(res => {
            if (isMounted) {
                setShippingPartners(res);
            }
        });
        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        let shippingPartnerCode = undefined;
        if (shippingPartnerId) {
            const shippingPartnerInfo = find(shippingPartners, ['id', Number(shippingPartnerId)]);
            const code = getVar(shippingPartnerInfo, 'code', '');
            shippingPartnerCode = SHIPPING_PARTNER_CODE_ALLOW_EXPORT_FILE_CREATE_FREIGHT_BILL.includes(code)
                ? code
                : undefined;
        }
        setShippingPartnerCode(shippingPartnerCode);
    }, [shippingPartners, shippingPartnerId]);

    function handleExport() {
        if (ids.length) {
            setLoading(true);
            api.downloadFileCreateFreightBill({
                ...filters,
                ids: isSelectAllPage ? [] : ids,
            })
                .then(response => {
                    convertBlobDataToExcel(response.data, 'export-file-create-freight-bill');
                    notification.success(t('order:export_excel.success'));
                })
                .catch(err => {
                    processResponseError(err);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
            notification.error(t('order:create_freight_bill.errors.ids.required'));
        }
    }

    return (
        <>
            {shippingPartnerCode && (
                <Button
                    className="_export-file-create-freight-bill mb-2 mb-lg-0 btn-menu-item"
                    loading={loading}
                    onClick={handleExport}
                >
                    {t('btn.export_file_create_freight_bill')}
                </Button>
            )}
        </>
    );
}
