import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useAddPurchasingAccountMutation = createUseMutation({
    namespace: 'purchasingAccount:add',
    mutation: data => apiService.add(data),
});

export default useAddPurchasingAccountMutation;
