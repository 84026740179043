import { Tabs } from 'antd'
import { get, isEmpty } from 'lodash'
import React, { useState, useEffect } from 'react'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import Loading from '@Modules/App/Loading'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import useDetailComboQuery from '@Modules/Product/Hooks/useDetailComboQuery'

import { t } from '@System/i18n'

import Detail from './Detail'
import ProductHistory from './ProductHistory'

const { TabPane } = Tabs

const ComboDetail = ({ match }) => {
    const { id } = match.params
    const [activeKey, setActiveKey] = useState('1')
    const [detail, setDetail] = useState({})

    const { data, isError, error, refetch, isLoading } = useDetailComboQuery(id)

    const handleChangeTab = activeKey => {
        setActiveKey(activeKey)
    }

    useEffect(() => {
        setNavigator(t('common:title.product-detail'), [
            {
                name: t('common:breadcrumb.products'),
                route: 'products.list',
            },
            {
                name: t('common:breadcrumb.product-detail'),
            },
        ])
    }, [])

    useEffect(() => {
        setDetail(get(data, 'data.data'))
        setDocumentTitle(t('title_page.product', { code: get(data, 'data.data.code', '') }))
    }, [data])

    if (isError) {
        const status = get(error, 'response.status', '')
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    if (isLoading) {
        return <Loading />
    }

    return (
        <div className="site-content bg-white order-detail _product-detail">
            <Tabs
                className="customize-ant-tabs _product-detail-tabs"
                defaultActiveKey={activeKey}
                onChange={handleChangeTab}
                type="card"
            >
                <TabPane
                    tab={t('product:title.common_info')}
                    key="1"
                    className="_product-detail-tab-common"
                >
                    <Detail detail={detail}/>
                </TabPane>

                <TabPane
                    tab={t('label.history_change')}
                    key="4"
                    className="_product-detail-tab-history"
                >
                    <ProductHistory
                        comboId={id}
                        activeKey={activeKey}
                    />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default ComboDetail
