import { Select } from 'antd';
import { get, map } from 'lodash';
import React, { useEffect, useState } from 'react';

import api from '@Modules/Users/services/api';
import { t } from '@System/i18n';

const { Option } = Select;

export default function SelectStaff({ loading, value, ...props }) {
    let [data, setData] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        api.getUserMerchants({ per_page: 1000 })
            .then(res => {
                const user = map(get(res, 'data.users'), 'user');
                setData(user);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <Select
            {...props}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            loading={isLoading}
            optionFilterProp="label"
            showSearch
            style={{ width: '100%' }}
            value={data.length > 0 && value ? value : undefined}
        >
            <Option key={"not_available"} value={"0"}>
                {t('label.not_available')}
            </Option>
            {data.map(user => {
                return (
                    <Option key={user?.id} value={user?.id}>
                        {`${user?.username} - ${user?.name}`}
                    </Option>
                );
            })}
        </Select>
    );
}
