import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useState } from 'react';



import api from '@Modules/Finance/services/api';



import processResponseError from '@System/api/processResponseError';
import { t } from '@System/i18n';
import notification from '@System/notification';
import { convertBlobDataToExcel } from '@System/support/helpers';


export default function ExportFinanceFreightBill({ documentId, exportName, btnName,  filters }) {
    const [loading, setLoading] = useState(false);

    function onDownloadExcel() {
        setLoading(true);
        api.exportFreightBill(documentId, filters)
            .then(response => {
                convertBlobDataToExcel(response.data, exportName);
                notification.success(t('finance:message.export_excel_success', { attribute: exportName }));
            })
            .catch(err => {
                processResponseError(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <Button className="_export-finance-freight-bill ml-2" loading={loading} type="primary" onClick={onDownloadExcel}>
            <DownloadOutlined /> {btnName || t('common:btn.export_excel')}
        </Button>
    );
}