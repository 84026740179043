import lodash from 'lodash';
import React, {useState} from "react";
import {Button, Modal, notification} from "antd";
import {CloudUploadOutlined} from "@ant-design/icons";

import api from "../services/api";
import {t} from "../../../system/i18n";
import UploadFile from "../../App/Upload/UploadFile";
import i18next from "i18next";

export default function ImportMapSkuWithSkuMarketPlace({marketplace, fileName}) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);

    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setMessages([]);
        setVisible(false);
    }

    function handleCancel() {
        setMessages([]);
        setVisible(false);
    }

    function handleUpload(file) {
        setLoading(true);
        setMessages([]);
        const filename = lodash.get(file, "name", "");
        let formData = new FormData();

        formData.append("file", file);
        api.importSkuMapSkuMarketplace(formData)
            .then(res => {
                const messages = lodash.uniqWith(lodash.get(res, "data.errors", []), lodash.isEqual);
                const hasInvalid = lodash.findIndex(messages, ['errors', "INVALID"]);
                if (hasInvalid === -1) {
                    setMessages(messages);
                    if (messages.length === 0) {
                        notification.success({message: t("product:message.upload_file.success", {filename})});
                        setVisible(false);
                    }
                } else {
                    notification.error({message: t("product:message.upload_file.fail", {filename})});
                }
            })
            .catch(err => {
                notification.error({message: t("common:message.server_error")})
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function renderError(error) {
        const ERRORS = {
            SHOP_NOT_CONNECT: "shop_not_connect",
            ALREADY_EXIST: "already_exist",
            REQUIRED: "required",
            EXISTS: "exists",
        };
        const line = lodash.get(error, "line", "");
        const shop_id = lodash.get(error, "shop_id", "");
        const code = lodash.get(error, "code", "");
        const keys = lodash.get(error, "keys", []);
        const keyError = lodash.get(error, "errors", "");

        const attributes = keys.map(columnKey => {
            return t(`product:label.import_map_sku_store.${columnKey}`, {marketplace})
        });
        let messages = [];
        if (keyError === ERRORS.SHOP_NOT_CONNECT) {
            messages.push(t("product:message.upload_file.line", {
                line: line, content: t(`product:message.upload_file.shop_not_connect`, {
                    shop_id,
                    domain: window.location.hostname,
                })
            }));
        }
        if (keyError === ERRORS.ALREADY_EXIST) {
            messages.push(t(`product:message.upload_file.${keyError}`, {
                attribute: `${t(`product:label.import_map_sku_store.marketplace_sku_code`)} ${code}`
            }));
        }

        if (keyError === ERRORS.REQUIRED) {
            messages.push(t(`product:message.upload_file.${keyError}`, {
                attribute: attributes.join(", ")
            }));
        }

        if (keyError === ERRORS.EXISTS) {
            messages.push(t(`product:message.upload_file.${keyError}`, {
                attribute: `${t(`product:label.import_map_sku_store.m28_sku_code`)} ${code}`
            }));
        }

        if (messages.length > 0) {
            return t("product:message.upload_file.line", {line: line, content: messages.join(" ")});
        }

        return t("product:message.upload_file.unknown_error");
    }

    return (
        <>
            <Button
                className="mr-2 mb-2 mb-lg-0"
                type="primary"
                onClick={showModal}
            ><CloudUploadOutlined/> {t("listProducts:btn.import_sku_map_marketplace", marketplace)}</Button>
            <Modal
                title={t("listProducts:label.import_sku_map_marketplace", {marketplace}).toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
            >
                <p>- {t("label.latest_at_update_sample")}: <strong>06/05/2021</strong></p>
                <p>- {t("label.download_sample_file")}:
                    <strong>
                        <a
                            href={`${process.env.PUBLIC_URL}/template/${i18next.language}/${fileName ? fileName : "import_map_sku_with_sku_marketplace.xlsx"}`}
                        >
                            {fileName ? fileName : "import_map_sku_with_sku_marketplace.xlsx"}
                        </a>
                    </strong>
                </p>
                <p className="mb-3">- {t("label.maximum_file_size")}: <strong>5MB</strong></p>
                <UploadFile
                    onChange={handleUpload}
                    accept=".xls,.xlsx"
                    fileType={[
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.ms-excel",
                    ]}
                    name="file tao san pham"
                    maxFileSize={5}
                    showUploadList={true}
                    loading={loading}
                />
                {
                    messages.length > 0 &&
                    <div className="pt-3">
                        {
                            messages.map((item, index) => {
                                return <p key={index} className="text-danger">- {renderError(item)}</p>
                            })
                        }
                    </div>
                }
            </Modal>
        </>
    );
}
