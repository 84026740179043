import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, Col, Button, Input, Select } from 'antd';
import React, { useState, useEffect } from 'react';

import useSize from '@Modules/App/Hooks/useSize';

import { t } from '../../../../system/i18n';
import SelectMarket from '../../../Location/components/SelectMarket';

const { Option } = Select;

function FormSearch({ addFilter, filters, initFilters }) {
    const { isMobile, isTablet } = useSize();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const [reset, setReset] = useState(0);
    const { code, name, status, location_id, ref } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({ ...input, [name]: value });
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onSubmit() {
        addFilter(input);
    }

    function _onReset() {
        setReset(reset + 1);
        addFilter(initFilters);
    }

    return (
        <Form className="pl-4 pr-4 pt-3 pb-2 border-bottom bg-light-gray" form={form} layout="vertical">
            <Row gutter={15}>
                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('merchant:label.code')}>
                        <Input
                            name="code"
                            value={code}
                            placeholder={t('merchant:placeholder.code')}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            className="ip-code"
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('merchant:label.ref')}>
                        <Input
                            name="ref"
                            value={ref}
                            placeholder={t('merchant:placeholder.ref')}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            className="_merchant-ref"
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('merchant:label.name')}>
                        <Input
                            name="name"
                            value={name}
                            placeholder={t('merchant:placeholder.name')}
                            onChange={_onChangeInput}
                            onPressEnter={_onSubmit}
                            className="ip-name"
                            allowClear={true}
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('merchant:label.market')}>
                        <SelectMarket
                            placeholder={t('merchant:placeholder.market')}
                            onChange={value => setInput({ ...input, location_id: value })}
                            value={location_id ? parseInt(location_id) : undefined}
                            className="sl-market"
                        />
                    </Form.Item>
                </Col>

                <Col xs={{ span: 12 }} lg={{ span: 6 }}>
                    <Form.Item className="mb-3" label={t('merchant:label.status')}>
                        <Select
                            name="status"
                            value={[0, 1].includes(parseInt(status)) ? parseInt(status) : undefined}
                            placeholder={t('merchant:placeholder.status')}
                            onChange={value => setInput({ ...input, status: value })}
                            showSearch
                            optionFilterProp="label"
                            className="ip-status"
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            <Option value={1}>{t('merchant:label.active')}</Option>
                            <Option value={0}>{t('merchant:label.inactive')}</Option>
                        </Select>
                    </Form.Item>
                </Col>

                <Col xs={{ span: 24 }} lg={{ span: 18 }} className="text-right">
                    <Form.Item className="mb-0" label={isMobile || isTablet ? '' : ' '}>
                        <Button type="primary" onClick={_onSubmit} className="mr-2 btn-search">
                            <SearchOutlined /> {t('common:btn.search')}
                        </Button>

                        <Button onClick={_onReset} className="btn-reset">
                            <UndoOutlined /> {t('common:btn.reset')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;
