import React, {useState} from 'react';
import {Form, Input, Row, Col, Button} from "antd";
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";
import {t} from "../../../../system/i18n";

export default function FormSearch({filters, addFilter}) {
    const [input, setInput] = useState(filters);

    function onChange(e) {
        const {value, name} = e.target;
        setInput({...filters, [name]: value})
    }

    function onReset() {
        setInput({});
        addFilter({})
    }

    function onSubmit() {
        addFilter(input);
    }

    return (
        <Form
            layout={"vertical"}
            className="pl-4 pr-4 pt-3 pb-4 border-bottom bg-light-gray"
        >
            <Row gutter={24}>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item label={t("product:label.category_code")}>
                        <Input
                            name="code"
                            placeholder={t("product:placeholder.category_code")}
                            value={input.code}
                            onChange={onChange}
                            onPressEnter={onSubmit}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 6}}>
                    <Form.Item label={t("product:label.category_name")}>
                        <Input
                            name="name"
                            placeholder={t("product:placeholder.category_name")}
                            value={input.name}
                            onChange={onChange}
                            onPressEnter={onSubmit}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{span: 12}} lg={{span: 12}} className="pt-4 text-right" style={{marginTop: "6px"}}>
                    <Button type="primary" icon={<SearchOutlined/>} onClick={onSubmit}>{t("common:btn.search")}</Button>
                    <Button icon={<UndoOutlined/>} onClick={onReset} className="ml-3">{t("common:btn.reset")}</Button>
                </Col>
            </Row>
        </Form>
    );
}