function CurrencyFormat() {
    let currency = {
        code: "NONE",
        decimal_separator: ".",
        format: "{amount}",
        precision: 2,
        thousands_separator: ","
    }

    function setCurrency(newCurrency) {
        currency = newCurrency ? newCurrency : currency;
    }

    function getCurrency() {
        return currency;
    }

    return {
        currency: getCurrency,
        setCurrency: setCurrency
    }
}

export default CurrencyFormat();
