import { EditOutlined } from '@ant-design/icons'
import { Button, Input, Spin } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { dateFormatter } from '@Modules/App/services'
import { auth } from '@Modules/Auth/services'
import PERMISSIONS from '@Modules/Auth/services/permissions'
import useUpdateOrderMutation from '@Modules/Order/Hooks/useUpdateOrderMutation'

import { t } from '@System/i18n'

import EditAddressModal from './EditAddressModal'

export default function OrderInfo({ orderDetail, onSuccessUpdate, setting }) {
    const [editAddressModalVisible, setEditAddressModalVisible] = useState(false)
    const [receiverAddress, setReceiverAddress] = useState()
    const [editPostCode, setEditPostCode] = useState(false)
    const [warningPostCode, setWarningPostCode] = useState(false)
    const { isLoading, mutateAsync } = useUpdateOrderMutation()
    const { order } = orderDetail
    const countryName = _.get(orderDetail, 'receiverCountry.label', '')
    const provinceName = _.get(orderDetail, 'receiverProvince.label', '')
    const districtDame = _.get(orderDetail, 'receiverDistrict.label', '')
    const wardName = _.get(orderDetail, 'receiverWard.label', '')
    const canUpdatePostCode = _.get(orderDetail, 'can_update_receiver_postal_code', false)

    const openEditModal = () => {
        setEditAddressModalVisible(true)
    }

    const closeEditModal = () => {
        setEditAddressModalVisible(false)
    }

    const handleSuccessUpdate = () => {
        setEditAddressModalVisible(false)
        onSuccessUpdate()
    }

    const openEditPostCode = () => {
        setEditPostCode(true)
    }

    useEffect(() => {
        const { order } = orderDetail

        setReceiverAddress(
            `${order.receiver_address || t('noText')}${wardName ? ', ' + wardName : ''}${districtDame ? ', ' + districtDame : ''}${
                provinceName ? ', ' + provinceName : ''
            }${countryName ? ', ' + countryName : ''}`
        )
        const requiredPostCode = _.get(orderDetail, 'shippingPartner.required_postcode', 0)
        const postCode = _.get(orderDetail, 'order.receiver_postal_code', '')
        setWarningPostCode(!!requiredPostCode && !postCode)
    }, [countryName, provinceName, districtDame, wardName, orderDetail])

    const handleUpdatePostCode = async e => {
        try {
            await mutateAsync({
                receiver_postal_code: e,
                id: orderDetail?.order?.id,
            })
            setEditPostCode(false)
            handleSuccessUpdate()
        } catch (error) {}
    }

    return (
        <div>
            <EditAddressModal
                orderDetail={orderDetail}
                visible={editAddressModalVisible}
                onCancel={closeEditModal}
                onSuccessUpdate={handleSuccessUpdate}
            />

            <h3 className="text-fz-16 font-weight-bold mb-3">{t('order:label.receiver_info')}</h3>

            <div className="mb-2">
                <span className="text-color-label">{t('order:label.receiver_name_2')}</span>
                <p className="_receiver_name_2">
                    <strong>{order.receiver_name}</strong>
                </p>
            </div>

            {!!orderDetail?.can_view_customer && (
                <div className="mb-2">
                    <span className="text-color-label">{t('order:label.customer_phone')}</span>
                    <p className="_order-detail-info-customer_phone">
                        <strong>
                            <a href={`tel:${order.receiver_phone}`}>{order.receiver_phone}</a>
                        </strong>
                    </p>
                </div>
            )}

            <div className="mb-2">
                <span className={warningPostCode ? 'text-red' : 'text-color-label'}>{t('label.postcode')}</span>
                {!editPostCode && (
                    <p>
                        <strong className="_order-detail-info-postcode">{_.get(orderDetail, 'order.receiver_postal_code', '') || '---'}</strong>
                        {canUpdatePostCode && (
                            <EditOutlined
                                className="ml-1"
                                onClick={openEditPostCode}
                            />
                        )}
                    </p>
                )}
                {canUpdatePostCode && editPostCode && (
                    <Spin spinning={isLoading}>
                        <Input
                            defaultValue={_.get(orderDetail, 'order.receiver_postal_code', '')}
                            onBlur={() => {
                                setEditPostCode(false)
                            }}
                            onPressEnter={e => handleUpdatePostCode(e.target.value)}
                            placeholder={t('placeholder.postcode')}
                            className="_order-detail-info-receiver_postal_code"
                        />
                    </Spin>
                )}
            </div>

            {!!orderDetail?.can_view_customer && (
                <div className="_receiver-address mb-2">
                    <span className="text-color-label">{t('orderDetail:label.deliveried_address')}</span>
                    <p className="_order-detail-info-deliveried_address">
                        {!setting?.encode_info || (setting?.encode_info && auth.can(PERMISSIONS.ORDER_VIEW_DETAIL_ORIGINAL)) ? receiverAddress : '***'}
                        {orderDetail?.can_update_order && (
                            <Button
                                className="_edit-address-btn p-0"
                                type="link"
                                onClick={openEditModal}
                            >
                                <EditOutlined />
                            </Button>
                        )}
                    </p>
                </div>
            )}

            <div>
                <span className="text-color-label">{t('orderDetail:label.deliveried_date')}</span>
                <p className="mt-1 _order-detail-info-deliveried_date">
                    <strong>{order.intended_delivery_at ? dateFormatter.formatDate(order.intended_delivery_at, 'DD/MM/YYYY') : t('noText')}</strong>
                </p>
            </div>
        </div>
    )
}
