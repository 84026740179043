import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';

const deepCompareEquals = (a, b) => isEqual(a, b);

const useDeepCompareMemoize = value => {
    const ref = useRef() 
  
    if (!deepCompareEquals(value, ref.current)) {
        ref.current = value;
    }
  
    return ref.current;
};

const useDeepCompareEffect = (callback, dependencies) => {
    useEffect(callback, dependencies.map(useDeepCompareMemoize));
};

export default useDeepCompareEffect;
