import { Select } from 'antd';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';

import useShippingPartnersQuery from '@Modules/ShippingPartners/Hooks/useShippingPartnersQuery';

import { getVar } from '@System/support/helpers';
import useStoresQuery from '@Modules/Store/Hooks/useStoresQuery';

const { Option } = Select;

export default function SelectStore(props) {
    const { store_id } = props;
    let [stores, setStores] = useState([]);
    const { data, refetch, isFetching } = useStoresQuery({ marketplace_code: 'SHOPEE', per_page: 200 });
    useEffect(() => {
        refetch();
    }, [store_id]);

    useEffect(() => {
        setStores(getVar(data, 'data.stores', []));
    }, [data]);

    return (
        <Select
            loading={isFetching}
            {...props}
            value={stores.length > 0 && props.value ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
            {stores.length > 0 &&
                stores.map(opt => {
                    const name = lodash.get(opt, 'merchant.name', '');
                    const marketPlaceStoreId = lodash.get(opt, 'store.marketplace_store_id', '');
                    const id = lodash.get(opt, 'store.id', '');

                    return (
                        <Option key={id} value={id}>
                            {`${name} - ${marketPlaceStoreId}`}
                        </Option>
                    );
                })}
        </Select>
    );
}
