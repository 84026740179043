import { useQuery } from 'react-query';

import processResponseError from '@System/api/processResponseError';

import apiService from './../services/api';

const usePackingOrderListQuery = (id, params) =>
    useQuery(['packing-order-list', params], () => apiService.getPackingOrderList(id, params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });

export default usePackingOrderListQuery;
