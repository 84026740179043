import { Select } from 'antd';
import React from 'react';

import { t } from '@System/i18n';

import { DOCUMENT_CONTROL_STATUS } from '../services/constants';

interface IDocumentControlStatusProps {
    value: string;
    onChange?: (value: any) => void;
    placeholder: string;
}

const { Option } = Select;

const DocumentControlStatus: React.FC<IDocumentControlStatusProps> = ({ value, onChange, placeholder }) => {
    
    return (
        <Select
            value={value || undefined}
            optionFilterProp="children"
            showSearch
            allowClear
            filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
            onChange={onChange}
            placeholder={placeholder}
        >
            {Object.entries(DOCUMENT_CONTROL_STATUS).map(([key, value]) => {
                return (
                    <Option key={key} value={value}>
                        {t(`finance:DOCUMENT_CONTROL_STATUS.${key}`)}
                    </Option>
                );
            })}
        </Select>
    );
};

export default DocumentControlStatus;
