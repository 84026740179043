import { Table } from 'antd'
import { get } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import CreateTransaction from '@Modules/Supplier/components/CreateTransaction'
import useTransactionHistory from '@Modules/Supplier/hooks/useTransactionHistory'

import { t } from '@System/i18n'
import { formatCurrency } from '@System/support/numberFormat'

const TransactionList = ({ idSupplier, refetchDetail, filters, addFilter,reFetchDetailPay }) => {
    const { data, isLoading, refetch, isError, error } = useTransactionHistory(idSupplier, filters)

    const [history, setHistory] = useState([])
    const [pagination, setPagination] = useState({})

    useEffect(() => {
        setHistory(get(data, 'data.supplier_transactions', []))
        setPagination(get(data, 'data.pagination', []))
    }, [data])

    const columns = [
        {
            title: t('document:label.type'),
            dataIndex: 'type',
            key: 'type',
            render: value => t(`config:supplier.transaction_type.${value}`),
        },
        {
            title: t('finance:label.amount'),
            dataIndex: 'amount',
            key: 'amount',
            render: (value, record) => {
                let currency = {}
                const paymentType = get(record, 'type')

                return <span>{formatCurrency(value || 0, currency)}</span>
            },
        },
        {
            title: t('label.time'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: text => <p>{text ? moment(text).format('DD/MM/YYYY HH:mm') : ''}</p>,
        },
        {
            title: t('note'),
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: t('config:supplier.label.reference'),
            key: 'reference',
            dataIndex: 'reference',
        },
    ]
    return (
        <div className="w-100 bg-white p-3 rounded-12">
            <div className="d-flex justify-content-between">
                <h3>{t('title.transaction_list')}</h3>
                {auth.can(permissions.FINANCE_CREATE_SUPPLIER_TRANSACTION) && (
                    <CreateTransaction
                        idSupplier={idSupplier}
                        refetchDetail={refetchDetail}
                        refetch={refetch}
                        reFetchDetailPay={reFetchDetailPay}
                    />
                )}
            </div>
            <Table
                columns={columns}
                dataSource={history}
                loading={isLoading}
                rowKey={record => record?.id}
                pagination={false}
            />
            <CustomizedPagination
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
            />
        </div>
    )
}

export default TransactionList
