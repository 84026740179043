import isUndefined from 'lodash/isUndefined';
import { useMemo, useState } from 'react';

// modified from https://ahooks.js.org/hooks/state/use-toggle

const useToggle = (defaultValue = false, reverseValue) => {
    const [state, setState] = useState(defaultValue);

    const actions = useMemo(() => {
        const reverseValueOrigin = isUndefined(reverseValue) ? !defaultValue: reverseValue;

        const toggle = value => {
            if (!isUndefined(value)) {
                setState(value);

                return;
            }

            setState(s => s === defaultValue ? reverseValueOrigin : defaultValue);
        };
        const setLeft = () => setState(defaultValue);
        const setRight = () => setState(reverseValueOrigin);

        return {
            setLeft,
            setRight,
            toggle
        };
    }, [defaultValue, reverseValue]);

    return [state, actions];
};

export default useToggle;
