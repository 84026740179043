import React from 'react'

import List from './List'
import TableViewBatch from './TableViewBatch'

const ListProductContainer = ({ pagination, data, filters, addFilter, loading, onChangeViewBatch, viewStockBatch }) => {
    return (
        <>
            {viewStockBatch ? (
                <TableViewBatch
                    filters={filters}
                    addFilter={addFilter}
                    loading={loading}
                    viewStockBatch={viewStockBatch}
                    onChangeViewBatch={onChangeViewBatch}
                />
            ) : (
                <List
                    data={data}
                    filters={filters}
                    addFilter={addFilter}
                    loading={loading}
                    pagination={pagination}
                    onChangeViewBatch={onChangeViewBatch}
                    viewStockBatch={viewStockBatch}
                />
            )}
        </>
    )
}

export default ListProductContainer
