/*render -> render từng row -> có thể truyền bất kì nội dung nào để thay nội dung trong row, nếu k truyền mặc định là lấy data theo dataIndex
const columns = [
    {
        key: '',
        title: ',
        className: '',
        dataIndex: '',
        render: (data, record, index) => {
            return data
        }
    }
]
*/
import { Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { t } from '@System/i18n';
import { classes } from '@System/support/helpers';

const TableCustomize = props => {
    const { dataSource, columns, loading, className } = props;

    const { xs } = useBreakpoint();

    const renderCol = (record, index) => {
        return columns.map((col, idx) => {
            let data = record[col?.dataIndex];
            return (
                <td className={`ant-table-cell ant-table-label-column ${col?.className}`} key={col?.key ?? idx}>
                    {typeof col.render === 'function' ? col.render(data, record, index) : data}
                </td>
            );
        });
    };

    return (
        <Spin spinning={loading}>
            <div
                className={classes('ant-table-wrapper ant-table-scroll-horizontal', {
                    'ant-table-ping-right': xs,
                    [className]: true,
                })}
            >
                <div className="ant-table-container">
                    <div className="ant-table-content" style={{ overflow: 'auto hidden' }}>
                        <table style={{ width: '576px', minWidth: '100%', tableLayout: 'auto' }}>
                            <thead className="ant-table-thead">
                                <tr>
                                    {columns?.map((item, index) => {
                                        return (
                                            <th className="ant-table-cell" key={index}>
                                                {item?.title}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {!isEmpty(dataSource) &&
                                    dataSource.map((item, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                className="ant-table-row ant-table-row ant-table-row-level-0"
                                            >
                                                {renderCol(item, index)}
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                        {isEmpty(dataSource) && <p className="text-center mt-4 mb-5">{t('common:label.no_data')}</p>}
                    </div>
                </div>
            </div>
        </Spin>
    );
};

TableCustomize.propTypes = {
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    loading: PropTypes.bool,
    className: PropTypes.string,
};

export default TableCustomize;
