import get from 'lodash/get';
import React, { useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import useFinanceControlDeliveryDetailQuery from '@Modules/Finance/Hooks/useFinanceControlDeliveryDetailQuery';
import useUpdateFinanceControlDeliveryMutation from '@Modules/Finance/Hooks/useUpdateFinanceControlDeliveryMutation';
import Body from '@Modules/Finance/screens/ControlDelivery/Detail/Body';

import { t, trans } from '@System/i18n';
import notification from '@System/notification';

import Header from './Header';

interface IForwardInfoDetailProps {
    match: any;
}

const ControlDeliveryDetail: React.FC<IForwardInfoDetailProps> = props => {
    const { match } = props;
    const documentId = match.params.id;
    const [documentInfo, setDocumentInfo] = useState({});
    const [shippingPartner, setShippingPartner] = useState({});
    const [currency, setCurrency] = useState({});
    const [freightBillInventories, setFreightBillInventories] = useState([]);
    const { error, isError, isLoading, data, refetch } = useFinanceControlDeliveryDetailQuery(documentId);
    const { mutate: updateDocument } = useUpdateFinanceControlDeliveryMutation({
        id: documentId,
        onSuccess: () => {
            notification.success(
                t('message.update_success_by_attribute', { attribute: trans('title.finance_control_delivery_detail') })
            );
        },
        onError: () => {
            refetch();
            notification.error(
                t('message.update_failed_by_attribute', { attribute: trans('title.finance_control_delivery_detail') })
            );
        },
    });

    useEffect(() => {
        setNavigator(trans('title.finance_control_delivery_detail'), [
            {
                name: trans('finance:label.finance_control_delivery'),
                route: 'finances.control-delivery.list',
            },
        ]);
    }, []);

    useEffect(() => {
        setDocumentInfo(get(data, 'data.document', {}));
        setShippingPartner(get(data, 'data.shipping_partner', {}));
        setCurrency(get(data, 'data.shipping_partner.currency', {}));
        setFreightBillInventories(get(data, 'data.document_delivery_comparisons', []));
    }, [data]);

    useEffect(() => {
        setDocumentTitle(
            t('title_page.document_finance_control_delivery', { document_code: get(documentInfo, 'code', '') })
        );
    }, [documentInfo]);

    if (isError) {
        const status = get(error, 'response.status', undefined);
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    return (
        <div className="site-content control-delivery-detail">
            <Header
              documentInfo={documentInfo}
              shippingPartner={shippingPartner}
              documentId={documentId}
              updateDocument={updateDocument}
              isLoading={isLoading}
            />
            {documentId && (
                <Body
                    loading={isLoading}
                    documentId={documentId}
                    documentInfo={documentInfo}
                    freightBillInventories={freightBillInventories}
                    currency={currency}
                />
            )}
        </div>
    );
};

export default ControlDeliveryDetail;
