import { LoadingOutlined, PlusOutlined, SearchOutlined, SaveFilled, CheckOutlined } from '@ant-design/icons';
import { Button, Modal, AutoComplete, Input, Table, Avatar } from 'antd';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React, { useEffect, useState, useCallback } from 'react';

import CustomizedPagination from '@Modules/App/CustomizedPagination';
import { SKU_STATUS } from '@Modules/App/services/constants';
import useAddItemPurchasePackageMutation from '@Modules/Product/Hooks/useAddItemPurchasePackageMutation';
import useSkusQuery from '@Modules/Product/Hooks/useSkusQuery';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { getVar } from '@System/support/helpers';

function AddSku({ storeData, addProduct, className }) {
    const { skus: existsData, purchase_packages } = storeData;
    const merchant_id = getVar(purchase_packages, '0.merchant_id', '');
    const purchasePackageId = getVar(purchase_packages, '0.id', undefined);
    const initFilters = {
        status: SKU_STATUS.ON_SELL,
        merchant_id: merchant_id ? merchant_id : undefined,
        page: 1,
    };
    const [visible, setVisible] = useState(false);
    const [filters, setFilters] = useState(initFilters);
    const [options, setOptions] = useState([]);
    const [skuInfo, setSkuInfo] = useState();
    const [keyword, setKeyword] = useState();
    const [page, setPage] = useState({});
    const { data, isFetching, refetch } = useSkusQuery(filters, {
        enabled: false,
    });
    const { isLoading, mutateAsync: addItemPurchasePackage } = useAddItemPurchasePackageMutation({
        id: purchasePackageId,
        notifyWhenError: false,
        onSuccess: () => {
            addProduct({ sku: omit(skuInfo, ['image']), purchasePackageId });
            setSkuInfo(undefined);
        },
        onError: () => {
            notification.error(t('message.create_failed', { attribute: t('sku:title.add_sku') }));
            setSkuInfo(undefined);
        },
    });

    function handleCancel() {
        setVisible(false);
        setFilters(initFilters);
        setKeyword(undefined);
    }

    useEffect(() => {
        if(visible) {
            refetch(filters);
        }
    }, [filters, visible]);

    useEffect(() => {
        setOptions(get(data, 'data.skus', []));
        setPage(get(data, 'data.pagination', {}));
    }, [data]);

    const _onSearch = useCallback(
        debounce(value => {
            if (value !== '' && value !== null && value !== undefined) {
                setFilters({ ...initFilters, keyword: value });
            } else {
                setFilters({ ...initFilters });
            }
        }, 300),
        []
    );

    const getOptions = option => {
        let { sku } = option;
        const image = getVar(sku, 'images.0', '');
        return {
            name: sku.name,
            id: sku.id,
            sku_id: sku.id,
            product_id: sku.id,
            code: sku.code,
            received_quantity: 0,
            old_received_quantity: 0,
            image,
        };
    };

    function _onChange(value) {
        setKeyword(value ? value : undefined);
    }

    const onSearch = page => {
        setFilters({ ...initFilters, ...page, keyword });
    };

    const handleOpenModal = () => {
        setVisible(true);
    };

    const handelAddSku = async sku => {
        await setSkuInfo(sku);
        addItemPurchasePackage({ package_items: [{ sku_id: sku.id, quantity: 0 }] });
    };

    let getColumns = [
        {
            className: '_list-product-name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                const image = getVar(record, 'image', '');
                return (
                    <div className="d-flex align-items-center">
                        <div>
                            <Avatar src={image} shape="square" className="mr-2" size={36} />
                        </div>
                        <div>
                            <span>
                                <strong style={{ fontWeight: 500, whiteSpace: 'break-spaces' }}>{text}</strong>
                            </span>
                        </div>
                    </div>
                );
            },
        },
        {
            className: '_list-product-sku-code',
            dataIndex: 'code',
            key: 'code',
        },
        {
            className: 'text-right _list-product-action',
            key: 'action',
            fixed: 'right',
            width: '140px',
            render: (text, record) => {
                const existsSku = find(existsData, ['sku_id', record.sku_id]);
                if (existsSku) {
                    return (
                        <Button
                            className="_list-product-action-disabled-added"
                            type="primary"
                            disabled
                            icon={<CheckOutlined />}
                        >
                            {t('btn.added')}
                        </Button>
                    );
                } else {
                    return (
                        <Button
                            loading={isLoading && !isEmpty(skuInfo) && skuInfo.id === record.id}
                            className="_list-product-action-added"
                            type="primary"
                            onClick={() => handelAddSku(record)}
                            icon={<PlusOutlined />}
                        >
                            {t('btn.plus')}
                        </Button>
                    );
                }
            },
        },
    ];
    return (
        <>
            <Button
                type={'primary'}
                className={`${className} _btn-add-sku`}
                onClick={handleOpenModal}
                icon={<PlusOutlined />}
            >
                {t('sku:btn.add_sku')}
            </Button>
            {visible && (
                <Modal
                    className="_modal-add-sku"
                    title={t('sku:title.add_sku')}
                    visible={visible}
                    onCancel={handleCancel}
                    width={800}
                    footer={[
                        <Button
                            className="_modal-btn-finished"
                            type={'primary'}
                            key={'ok'}
                            onClick={handleCancel}
                            icon={<SaveFilled />}
                        >
                            {t('btn.finished')}
                        </Button>,
                    ]}
                >
                    <AutoComplete
                        className="_search-sku-autocomplete"
                        options={false}
                        style={{ width: '100%' }}
                        onSearch={_onSearch}
                        onChange={_onChange}
                        value={keyword}
                    >
                        <Input
                            className="_input-search-sku-"
                            size={'large'}
                            placeholder={t('product:placeholder.search_product_by_name_code')}
                            prefix={
                                isFetching ? (
                                    <LoadingOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                ) : (
                                    <SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                )
                            }
                            allowClear={true}
                        />
                    </AutoComplete>
                    <Table
                        showHeader={false}
                        className="add-sku-import-package pb-2 mt-3"
                        dataSource={options.map(getOptions)}
                        rowKey={record => record.sku_id}
                        columns={getColumns}
                        loading={isFetching}
                        pagination={false}
                        scroll={{ x: 576 }}
                        locale={{
                            emptyText: <p>{t('message.no_data_product')}</p>,
                        }}
                    />
                    <CustomizedPagination pagination={page} addFilter={onSearch} filters={filters} />
                </Modal>
            )}
        </>
    );
}

export default AddSku;
