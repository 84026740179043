import React, {useState} from "react";
import {DatePicker, Form, Input, Select} from "antd";
import {t} from "../../../../../system/i18n";
import moment from "moment";
import Location from "./Location";

export default function ReceivedInfo({validatedRules, form, currentSeller}) {

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().subtract(1, "days").endOf('day');
    }

    return (
        <div className="border box-shadow bg-white pt-3 pr-4 pl-4 pb-4">
            <h2 className="title-box mb-3">{t("order:label.receive_info")}</h2>
            <div>
                <Form.Item
                    className="mb-3 receiver_name"
                    name="receiver_name"
                    label={t("order:label.receiver_name")}
                    rules={validatedRules.receiver_name}
                >
                    <Input
                        name="receiver_name"
                        placeholder={t("order:placeholder.receiver_name")}
                    />
                </Form.Item>

                <Form.Item
                    className="mb-3 receiver_phone"
                    name="receiver_phone"
                    label={t("order:label.receiver_phone")}
                    rules={validatedRules.receiver_phone}
                >
                    <Input
                        name="receiver_phone"
                        placeholder={t("order:placeholder.receiver_phone")}
                    />
                </Form.Item>

                <Form.Item
                    className="mb-3 receiver_address"
                    name="receiver_address"
                    label={t("order:label.receiver_address")}
                    rules={validatedRules.receiver_address}
                >
                    <Input
                        name="receiver_address"
                        placeholder={t("order:placeholder.receiver_address")}
                    />
                </Form.Item>

                <Location
                    validatedRules={validatedRules}
                    form={form}
                    currentSeller={currentSeller}
                />

                <Form.Item
                    className="mb-0 intended_delivery_at"
                    name="intended_delivery_at"
                    label={t("order:label.intended_delivery_at")}
                    rules={validatedRules.intended_delivery_at}
                >
                    <DatePicker
                        name="intended_delivery_at"
                        placeholder={t("order:placeholder.intended_delivery_at")}
                        style={{width: "100%"}}
                        format={"DD/MM/YYYY"}
                        // disabledDate={disabledDate}
                    />
                </Form.Item>
            </div>
        </div>
    );
}
