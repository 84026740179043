import React from "react";
import {Button, notification} from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import get from "lodash/get";
import {t, trans} from "@System/i18n";
import {convertBlobDataToExcel} from "@System/support/helpers";
import api from "@Modules/Document/services/api";

export default function ExportDocumentReturnOrder({documentId, className = undefined}) {

    function onDownloadExcel() {
        api.exportSkusDocumentImportingReturnOrder(documentId)
            .then(response => {
                convertBlobDataToExcel(response.data, 'export-skus');
                notification.success({message: t("order:export_excel.success"), duration: 6})
            })
            .catch((err) => {
                const status = get(err, "response.status", null);
                if (status === 403)
                    notification.error({message: t('common:message.403'), duration: 6});
                else if (status === 404)
                    notification.error({message: t('common:message.404'), duration: 6});
                else
                    notification.error({message: t('common:message.fetch_fail'), duration: 6});
            });
    }

    return (
        <Button
            className={`_importing-document-export ${className ? className: ""}`}
            type="primary"
            icon={<DownloadOutlined/>}
            onClick={onDownloadExcel}
        >
            {trans("document:label.export_document")}
        </Button>
    );
}
