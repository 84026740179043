import React from "react";
import { Timeline } from "antd";

import {t, trans} from "../../../../../system/i18n";
import {dateFormatter} from "../../../../App/services";
import {PAYMENT_METHOD} from "../../../services/constants";
import ConfirmPayment from "../ConfirmPayment";
import {formatCurrency, formatCurrencyStandard} from "../../../../../system/support/numberFormat";

export default function PaymentInfo({orderDetail}) {
    const {order, orderTransactions} = orderDetail;
    return (
        <div className="payment-info-order">
            <div className="d-flex align-items-center justify-content-between mb-3 create-order">
                <h3 className="text-fz-16 font-weight-bold">{t("orderDetail:label.payment_information")}</h3>
                {
                    orderDetail.canPaymentConfirm && <ConfirmPayment orderId={order.id} orderDetail={orderDetail}/>
                }
            </div>

            {
                order.debit_amount === 0 &&
                <div>{t("orderDetail:message.paid_total_money")}</div>
            }

            <div className="mb-2">
                <span className="text-color-label">{t("orderDetail:label.total_order")}</span>
                <p className="_payment-info-total_order"><strong>{formatCurrencyStandard(order.order_amount ? order.order_amount : 0)}</strong></p>
            </div>

            <div className="mb-2">
                <span className="text-color-label">{t("orderDetail:label.paid_money")}</span>
                <p className="_payment-info-paid_money"><strong>{formatCurrencyStandard(order.paid_amount ? order.paid_amount : 0)}</strong></p>
            </div>

            <div className="mb-2">
                <span className="text-color-label">{t("orderDetail:label.received_money")}</span>
                <p className="_payment-info-received_money"><strong className="text-red">{formatCurrencyStandard(order.debit_amount ? order.debit_amount : 0)}</strong></p>
            </div>
            <div className="mb-2">
                    <span className="text-color-label">{t("order:label.cod")}</span>
                    <p className="_payment-info-cod"><strong>{formatCurrencyStandard(order.cod ? order.cod : 0)}</strong></p>
            </div>
            <div className="mb-2">
                <span className="text-color-label">{t("orderDetail:label.payment_type")}</span>
                <p className="_payment-info-payment_type"><strong>{order.payment_type ? t(`order:payment_type.${order.payment_type}`) : t("noText")}</strong></p>
            </div>
           
            {
                orderTransactions && orderTransactions.length > 0 &&
                <Timeline>
                    {
                        orderTransactions.map((item, index) => {
                            if (item.amount === 0) return null;
                            return (
                                <Timeline.Item key={index}>
                                    {trans(item.method ? "orderDetail:message.paid_message" : "orderDetail:message.paid_message_not_method", {
                                        amount: <strong
                                            className="text-primary">{formatCurrency(item.amount ? item.amount : 0)}</strong>,
                                        time: <strong
                                            className="text-primary">{dateFormatter.formatDate(item.payment_time, "DD/MM/YYYY")}</strong>,
                                        method: <strong
                                            className="text-primary">{t(`order:payment_method.${item.method}`)}</strong>,
                                        bank: (item.method === PAYMENT_METHOD.METHOD_BANK_TRANSFER ? trans("orderDetail:message.bank_info", {
                                            bank_name: <strong className="text-primary">{item.bank_name}</strong>,
                                            bank_account: <strong className="text-primary">{item.bank_account}</strong>
                                        }) : ""),
                                        note: item.note ? trans("orderDetail:message.bank_note", {
                                            note: <strong className="text-primary">{item.note}</strong>
                                        }) : ""
                                    })}
                                </Timeline.Item>
                            );
                        })
                    }
                </Timeline>
            }
        </div>
    );
}
