import React, {useEffect, useState} from "react";
import {Select} from "antd";
import ServiceUnits from "../services/ServiceUnits";

const {Option} = Select;

export default function SelectUnits(props) {
    let [units, setUnits] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceUnits.list()
            .then(res => {
                setUnits(res);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    return (
        <Select
            loading={isLoading}
            {...props}
            value={(units.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                units.length > 0 &&
                units.map(opt => {
                    return <Option key={opt.id} value={opt.id}>{opt.name}</Option>;
                })
            }
        </Select>
    );
}
