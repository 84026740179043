/* eslint-disable import/no-anonymous-default-export */
import { api } from '../../../system/api'

export default {
    getServices: params => api.get(`/services`, { params }),
    createService: data => api.post(`/services`, data),
    getListPrice: id => api.get(`/services/${id}/service-prices`),
    createPrice: (id, data) => api.post(`/services/${id}/service-prices`, data),
    changeStatusDefaultPrice: (idService, idPrice, data) => api.put(`/services/${idService}/service-prices/${idPrice}/is-default`, data),
    changeLogicService: (idService, data) => api.put(`/services/${idService}`, data),
    changeLogicServicePrice: (idService, idPrice, data) => api.put(`/services/${idService}/service-prices/${idPrice}`, data),
    changeCompulsory: (idService, data) => api.put(`/services/${idService}/is-required`, data),
    changeProviding: (idService, data) => api.put(`services/${idService}/status`, data),
    updateAllServiceProduct: data => api.post(`/services/update-all-merchants`, data),
    createServicePack: data => api.post(`/service-packs`, data),
    getServicesPackList: params => api.get(`/service-packs`, { params }),
    getServicesPackDetail: id => api.get(`/service-packs/${id}`),
    editServicePack: (id, data) => api.put(`/service-packs/${id}`, data),
    editSellerServicePack: (id, data) => api.post(`/service-packs/${id}/add-sellers`, data),
    getSellerServicesPackLogs: id => api.get(`/service-packs/${id}/seller-histories`),
    createServiceCombo: (data) => api.post(`/service-combos`, data),
    createActiveCode: (params) => api.post(`/active-code/create`, {}, {params}),
    getListActiveCode: (comboId, params) => api.get(`/active-code/${comboId}`, {params, singleRequest: true, loading: false})
}
