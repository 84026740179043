import { useQuery } from 'react-query';


import processResponseError from '@System/api/processResponseError';
import api from "@Modules/Finance/services/api";

const useListFinanceControlQuery = params => {
    return useQuery(['document-freight-bill-inventories', params], () => api.getFinanceControl(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useListFinanceControlQuery;
