import { Input } from 'antd';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';

export default function InputNotSpace(props) {
    const [value, setValue] = useState();

    function handleOnChange(e) {
        const { value } = e.target;
        let newValue = '';
        if (value) {
            newValue = value.replace(/ /g, '');
           
        }
        setValue(newValue);
        props.onChange(newValue);
    }

    return (
        <Input
            {...props}
            value={isNil(props.value) || props.value === '' ? value : props.value}
            onChange={handleOnChange}
            style={props.style ? props.style : { width: '100%' }}
        />
    );
}
