import React, {useEffect, useState} from 'react';
import {Table, notification} from "antd";
import {dateFormatter, setDocumentTitle, setNavigator} from "../../../App/services";
import FormSearchImportHistoryItems from "./FormSearchImportHistoryItems";
import api from "../../services/api";
import _ from 'lodash';
import TableEmpty from "../../../App/Utilities/TableEmpty";
import {Link} from "react-router-dom";
import {url} from "../../../../system/routing";
import {t} from "../../../../system/i18n";

export default function HistoryImportDetail(props) {
    const {match} = props;
    const {id} = match.params;
    const [detailInfo, setDetailInfo] = useState({});
    const [importHistoriesItems, setImportHistoriesItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, addFilter] = useState([]);

    useEffect(() => {
        setNavigator(t("common:title.import-history-detail"), [
            {
                name: t("common:breadcrumb.import-history"),
                route: "import-history"
            },
            {
                name: t("common:breadcrumb.import-history-detail")
            }
        ]);
        fetchImportHistoriesDetail();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchImportHistoriesItems();

        // eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        setDocumentTitle(t("title_page.imported_history", {code: _.get(detailInfo, "importHistory.code", "")}));
    }, [detailInfo]);

    function fetchImportHistoriesDetail() {
        api.getImportHistoriesDetail(id)
            .then(res => {
                setDetailInfo(_.get(res, 'data', []));
            }).catch(err => {
            const status = _.get(err, "response.status", null);
            if (status === 403)
                notification.error({message: t('common:message.403')});
            else if (status === 404)
                notification.error({message: t('common:message.404')});
            else
                notification.error({message: t('common:message.fetch_fail')});
        })
    }

    function fetchImportHistoriesItems() {
        setLoading(true);
        api.getImportHistoriesItems(id, filters)
            .then(res => {
                setImportHistoriesItems(_.get(res, 'data.items', []));
            }).catch(err => {
            const status = _.get(err, "response.status", null);
            if (status === 403)
                notification.error({message: t('common:message.403')});
            else if (status === 404)
                notification.error({message: t('common:message.404')});
            else
                notification.error({message: t('common:message.fetch_fail')});
        }).finally(() => setLoading(false))
    }

    const columns = [
        {
            title: t("product:label.sku_code"),
            dataIndex: 'sku_code',
            key: 'sku_code',
            render: (text, record) => {
                const sku_code = _.get(record, "sku.code", "");
                const sku_id = _.get(record, "sku.id", "");
                return <strong style={{fontWeight: "500"}}>
                    <Link to={url.to("products", {id: sku_id})}>{sku_code}</Link>
                </strong>;
            }
        },
        {
            title: t("product:label.sku_name"),
            dataIndex: 'sku_name',
            key: 'sku_name',
            render: (text, record) => {
                const sku_name = _.get(record, "sku.name", "");
                return <p>{sku_name}</p>;
            }
        },
        {
            title: t("product:label.warehouse_name"),
            dataIndex: 'warehouse_name',
            key: 'warehouse_name',
            render: (text, record) => {
                const warehouseName = _.get(record, "warehouse.name", "");
                const warehouseCode = _.get(record, "warehouse.code", "");
                return <p>{warehouseName + (warehouseCode ? " (" + warehouseCode + ")" : "")}</p>
            }
        },
        {
            title: t("product:label.warehouse_area_name"),
            dataIndex: 'warehouse_area_name',
            key: 'warehouse_area_name',
            render: (text, record) => {
                let warehouseAreaName = _.get(record, "warehouseArea.name", "");
                let warehouseAreaCode = _.get(record, "warehouseArea.code", "");
                 if( warehouseAreaCode === "default") warehouseAreaCode = t("order:label.default");
                 if (warehouseAreaName === "default") warehouseAreaName = t("order:label.default");
                return <p>{warehouseAreaName + (warehouseAreaCode ? " (" + warehouseAreaCode + ")" : "")}</p>
            }
        },
        {
            title: t("product:label.quantity"),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => {
                const quantity = _.get(record, "importHistoryItem.stock", "");
                return <p>{quantity}</p>
            }
        },
        {
            title: t("product:label.freight_bill"),
            dataIndex: 'freight_bill',
            key: 'freight_bill',
            render: (text, record) => {
                const freight_bill = _.get(record, "importHistoryItem.freight_bill", "");
                return <p>{freight_bill}</p>
            }
        },
        {
            title: t("product:label.package_code"),
            dataIndex: 'package_code',
            key: 'package_code',
            render: (text, record) => {
                const package_code = _.get(record, "importHistoryItem.package_code", "");
                return <p>{package_code}</p>
            }
        },
        {
            title: t("product:label.note"),
            dataIndex: 'note',
            key: 'note',
            render: (text, record) => {
                const note = _.get(record, "importHistoryItem.note", "");
                return <p>{note}</p>;
            }
        },
    ];
    const importHistory = _.get(detailInfo, "importHistory", {});
    const creator = _.get(detailInfo, "creator", {});
    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{importHistory.code} - {t("common:heading.import-history")}</h2>
                </div>
                <div className="content-history-import-detail">
                    <div className="d-flex p-4 import-detail-info">
                        <p>
                            <strong>{t("product:label.creator_at")}: {dateFormatter.formatDate(importHistory.created_at, "DD/MM/YYYY HH:mm")}</strong>
                        </p>
                        <p>
                            <strong>{t("product:label.creator")}: {creator.name || creator.username || creator.email}</strong>
                        </p>
                    </div>
                    <FormSearchImportHistoryItems
                        addFilter={addFilter}
                        filters={filters}
                        loading={loading}
                    />
                    <Table
                        className="m-3"
                        dataSource={importHistoriesItems}
                        columns={columns}
                        rowKey={record => record.id}
                        pagination={false}
                        loading={loading}
                        locale={{emptyText: <TableEmpty loading={loading}/>}}
                    />
                </div>
            </div>
        </div>
    );
}
