import { useQuery } from 'react-query';

import apiService from './../services/api';

const usePackingDocumentQuery = id => useQuery(['packing-document', id], () => apiService.packingDocument(id), {
    retry: false,
    enabled: !!id
});

export default usePackingDocumentQuery;
