import Icon, { CheckOutlined, DownOutlined, RedoOutlined } from '@ant-design/icons';
import { Form, Button, notification, Typography, Dropdown, Menu, Modal } from 'antd';
import _, { uniq, map, filter, remove, xorWith, isEmpty, isEqual, compact } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import { catchErrors } from '@Modules/App/services/helpers';
import useConfirmWeightVolumeMutation from '@Modules/Product/Hooks/useConfirmWeightVolumeMutation';
import PrintSkusBarcode from '@Modules/Product/components/PrintSkusBarcode';
import ProductImages from '@Modules/Product/screens/OriginalProduct/BasicProduct/ProductImages';

import { events } from '@System/events';
import useValidatedMessages from '@System/hooks/useValidatedMessages';
import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

import api from '../../../services/api';
import { EVENTS } from '../../../services/constants';
import ProductOptions from '../BasicProduct/ProductOptions';
import ProductForm from './ProductForm';
import ProductVariations from './ProductVariations';

const { Title } = Typography;

export default function BasicProduct({ product, options, skus, setOriginalProduct,fetchData }) {
    const [form] = Form.useForm();
    const prevLocalOptions = useRef([]);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const validateMessages = useValidatedMessages();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [preVarians, setPreVarians] = useState([]);

    const { isLoading: confirmLoading, mutateAsync: confirmWeightVolumeMutate } = useConfirmWeightVolumeMutation({
        onSuccess: () => {
            setVisible(false);
            events.dispatch(EVENTS.UPDATE_PRODUCT_SUCCESS, {});
            notification.success({ message: t('sku:message.confirm_weight_volume.success'), duration: 6 });
        },
        onError: () => {
            setVisible(false);
            notification.error({ message: t('sku:message.confirm_weight_volume.error'), duration: 6 });
        },
    });

    const Exclamation = () => (
        <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="exclamation-circle"
            width="1.2em"
            height="1.2em"
            fill="#faad14"
            aria-hidden="true"
        >
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
            <path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path>
        </svg>
    );
    const ExclamationIcon = props => <Icon component={Exclamation} {...props} />;

    /**
     * Format localOptions thuộc tính sản phẩm
     * [{id: null, label: "", values: []}]
     */
    const [localOptions, setLocalOptions] = useState([]);

    /**
     * Format localVariations biến thể sản phẩm
     * [{
     *       id: null,
     *       option_values: [{id: null, code: "", label: "", option_label: ""}]
     * }]
     */
    const [localVariations, setLocalVariations] = useState([]);

    useEffect(() => {
        if (localVariations.length === 0) {
            setSelectedRowKeys([]);
        }
    }, [localVariations]);

    useEffect(() => {
        if (options) initLocalOptions();

        // eslint-disable-next-line
    }, [options]);

    useEffect(() => {
        if (skus) initLocalVariations();

        // eslint-disable-next-line
    }, [skus]);

    // Tạo các thuộc tính đã có lưu vào localOptions khi options được load
    function initLocalOptions() {
        let tmpOpts = [];
        options.forEach(opt => {
            let productOpt = _.get(opt, 'productOption', {}),
                values = _.get(opt, 'options', []);
            tmpOpts.push({
                id: productOpt.id,
                label: productOpt.label,
                values: values.map(value => ({ id: value.id, label: value.label })),
            });
        });
        setLocalOptions(tmpOpts);
    }

    // Tạo các biến thể đã có lưu vào localVariations sau khi skus được tải
    function initLocalVariations() {
        let tmpVariations = [];
        skus.forEach(sku => {
            let optValues = _.get(sku, 'optionValues', {}),
                optSku = _.get(sku, 'sku', {});
            const weight = optSku.weight ? Number(optSku.weight) * 1000 : optSku.weight;
            const height = optSku.height ? Number(optSku.height) * 1000 : optSku.height;
            const length = optSku.length ? Number(optSku.length) * 1000 : optSku.length;
            const width = optSku.width ? Number(optSku.width) * 1000 : optSku.width;
            tmpVariations.push({
                key: optSku.id,
                id: optSku.id,
                code: optSku.code,
                ref: optSku.ref,
                confirm_weight_volume: getVar(optSku, 'confirm_weight_volume', null),
                status: _.get(optSku, 'status', null),
                option_values: optValues.map(optValue => ({
                    id: optValue.id,
                    label: optValue.label,
                    option_label: '',
                })),
                weight,
                height,
                length,
                width,
                is_batch: optSku.is_batch,
                logic_batch: optSku.logic_batch,
                sku_parent_id: optSku.sku_parent_id,
                is_serial_number: optSku.is_serial_number,
                using_serial: optSku.using_serial
            });
        });
        setPreVarians(tmpVariations);
        setLocalVariations(tmpVariations);
    }

    function checkOptions() {
        return localOptions.every(option => !_.isEmpty(option.label) && option.values.length > 0);
    }

    const compactObject = val => {
        const data = Array.isArray(val) ? val.filter(Boolean) : val;
        return Object.keys(data).reduce(
            (acc, key) => {
                const value = data[key];
                if (Boolean(value)) acc[key] = typeof value === 'object' ? compactObject(value) : value;
                return acc;
            },
            Array.isArray(val) ? [] : {}
        );
    };

    const isArrayEqual = (x, y) => {
        return isEmpty(xorWith(x, y, isEqual));
    };

    const confirmUseLogic = () => {
        Modal.confirm({
            title: t('message.auto_price'),
            icon: false,
            content: '',
            okText: t('btn.ok'),
            cancelText: t('btn.no'),
            onOk: () => {
                updateProduct({ auto_price: true });
            },
            onCancel: () => {
                updateProduct({ auto_price: false });
            },
        });
    };
    const handleUpdateProduct = () => {
        const removeFalsyPre = preVarians.map(item => compactObject(item));
        const removeFalsyLocalVariations = localVariations.map(item => compactObject(item));
        const checkValueVariationsChange = isArrayEqual(removeFalsyPre, removeFalsyLocalVariations);

        if (checkValueVariationsChange) {
            updateProduct();
        } else {
            confirmUseLogic();
        }
    };

    function updateProduct(logicAuto = {}) {
        form.validateFields().then(values => {
            let isValid = true;
            if (!checkOptions()) {
                form.setFields([
                    {
                        name: 'options',
                        errors: [t('sku:message.option_and_option_value_required')],
                    },
                ]);
                isValid = false;
            }
            const inValidWeightOrSize = localVariations.some(function (sku) {
                let weight = Number(getVar(sku, 'weight', undefined));
                let height = Number(getVar(sku, 'height', undefined));
                let length = Number(getVar(sku, 'length', undefined));
                let width = Number(getVar(sku, 'width', undefined));
                const isValidWeight = isNaN(weight) || !Number.isInteger(weight) || weight < 0;
                const isValidLength = isNaN(height) || !Number.isInteger(height) || height < 0;
                const isValidHeight = isNaN(length) || !Number.isInteger(length) || length < 0;
                const isValidWidth = isNaN(width) || !Number.isInteger(width) || width < 0;
                return isValidWeight || isValidLength || isValidHeight || isValidWidth;
            });
            if (inValidWeightOrSize) {
                notification.error({ message: t('sku:message.weight_or_size_sku_invalid') });
                isValid = false;
            }
            if (isValid) {
                const newLocalVariations = localVariations.map(item => {
                    let { weight, height, length, width } = item;
                    if (weight) weight = weight / 1000;
                    if (height) height = height / 1000;
                    if (length) length = length / 1000;
                    if (width) width = width / 1000;
                    return { ...item, weight, height, length, width };
                });

                setLoading(true);
                api.editProduct(product.id, {
                    ...values,
                    ...logicAuto,
                    options: localOptions,
                    skus: newLocalVariations,
                })
                    .then(res => {
                        notification.success({
                            message: t('common:message.update_success_by_attribute', {
                                attribute: t('label.products'),
                            }),
                        });
                        prevLocalOptions.current = [];
                        setTimeout(() => {
                            events.dispatch(EVENTS.UPDATE_PRODUCT_SUCCESS, {});
                        }, 1000);
                    })
                    .catch(
                        catchErrors(
                            t('common:message.update_failed_by_attribute', { attribute: t('label.products') }),
                            showErrors
                        )
                    )
                    .finally(() => setLoading(false));
            }
        });
    }

    function showErrors(errors) {
        let error_messages = [];
        Object.entries(errors).forEach(entry => {
            const [key, error] = entry;
            for (let prop in error) {
                if (error.hasOwnProperty(prop)) {
                    error_messages.push({
                        name: key,
                        errors: [
                            t(`errorMessages.${prop}`, {
                                attribute: t(`product:label.${key}`),
                            }),
                        ],
                    });
                }
            }
        });

        form.setFields(error_messages);
    }

    function cancelUpdate() {
        initLocalOptions();
        initLocalVariations();
        form.resetFields();
        events.dispatch(EVENTS.RESET_PRODUCT_FORM, {});
    }

    function handleConfirmSizeWeight() {
        const skuConfirmedIds = map(
            filter(localVariations, item => !!item.confirm_weight_volume),
            'id'
        );
        return confirmWeightVolumeMutate({
            productId: product.id,
            sku_ids: uniq([...selectedRowKeys, ...skuConfirmedIds]),
        });
    }

    const onChangeCheck = (skuId, e) => {
        const newSelectedRowKeys = [...selectedRowKeys];
        e.target.checked ? newSelectedRowKeys.push(skuId) : remove(newSelectedRowKeys, item => item === skuId);
        setIndeterminate(!!newSelectedRowKeys.length && newSelectedRowKeys.length < localVariations.length);
        setCheckAll(newSelectedRowKeys.length === localVariations.length);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const onCheckAllChange = e => {
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        setSelectedRowKeys(e.target.checked ? uniq(filter(map(localVariations, 'id'), item => !!item)) : []);
    };

    const menuActionOfVariant = (
        <Menu className="lading-dropdown-action">
            <Menu.Item
                key={1}
                className="_scan-order-list-of-lading-btn-remove-selected mb-3 mb-xl-0"
                onClick={() => setVisible(true)}
            >
                {t('sku:btn.confirm_size_weight')}
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="p-4">
            <Form form={form} validateMessages={validateMessages} layout="vertical" initialValues={product}>
                <ProductForm product={product} form={form} />
            </Form>

            <ProductImages product={product} />
            <Form form={form} validateMessages={validateMessages} layout="vertical" initialValues={product}>
                <ProductOptions
                    product={product}
                    localOptions={localOptions}
                    setLocalOptions={setLocalOptions}
                    checkOptions={checkOptions}
                    form={form}
                />
            </Form>
            <div className="mt-4">
                <Title className="mb-4" level={5}>
                    {t('product:title.variations_list')}
                </Title>
                <div className="d-flex justify-content-start">
                    {selectedRowKeys.length > 0 && (
                        <>
                            {t('sku:title.variant_selected', { count: selectedRowKeys.length })}
                            <Dropdown overlay={menuActionOfVariant} trigger={['click']} className="_dropdown-action">
                                <a className="ant-dropdown-link ml-2 " onClick={e => e.preventDefault()}>
                                    {t('choose_action')} <DownOutlined />
                                </a>
                            </Dropdown>
                        </>
                    )}
                </div>

                <ProductVariations
                    prevLocalOptions={prevLocalOptions}
                    product={product}
                    localOptions={localOptions}
                    localVariations={localVariations}
                    setLocalVariations={setLocalVariations}
                    skus={skus}
                    checkAll={checkAll}
                    indeterminate={indeterminate}
                    onChangeCheck={onChangeCheck}
                    onCheckAllChange={onCheckAllChange}
                    selectedRowKeys={selectedRowKeys}
                    fetchData={fetchData}
                />
            </div>
            <Form form={form} validateMessages={validateMessages} layout="vertical" initialValues={product}>
                <div className="text-right mt-4">
                    <PrintSkusBarcode className="mr-3" skuIds={selectedRowKeys} />
                    <Button className="mr-3 _basic-product-btn-cancel" onClick={cancelUpdate}>
                        <RedoOutlined />
                        {t('btn.cancel')}
                    </Button>
                    <Button
                        type="primary"
                        className="_basic-product-btn-update"
                        onClick={handleUpdateProduct}
                        loading={loading}
                        icon={<CheckOutlined />}
                    >
                        {t('btn.update')}
                    </Button>
                </div>
                {visible && (
                    <Modal
                        title={
                            <>
                                <ExclamationIcon twoToneColor="#faad14" className="mr-2" />
                                {t('sku:btn.confirm_size_weight')}
                            </>
                        }
                        visible={visible}
                        onOk={handleConfirmSizeWeight}
                        confirmLoading={confirmLoading}
                        onCancel={() => setVisible(false)}
                        okText={t('btn.confirm')}
                        cancelText={t('btn.cancel')}
                    >
                        <p>{t('sku:message.confirm_weight_volume.content')}</p>
                    </Modal>
                )}
            </Form>
        </div>
    );
}
