import { Row, Col, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';

import ExportFinanceFreightBill from '@Modules/Finance/components/ExportFinanceFreightBill';
import ListOfLading from '@Modules/Finance/components/ListOfLading';
import SummaryDocumentDetail from '@Modules/Finance/components/SummaryDocumentDetail';
import { STATUS_FREIGHT_BIll_INVENTORY } from '@Modules/Finance/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

const { TabPane } = Tabs;
function Body({ freightBillInventories, loading, documentId, currency, form, documentInfo, canUpdate, handleUpdate }) {
    const [data, setData] = useState([]);
    useEffect(() => {
        const dataSource = {};
        Object.keys(STATUS_FREIGHT_BIll_INVENTORY).map((item, index) => {
            if (STATUS_FREIGHT_BIll_INVENTORY[item] === STATUS_FREIGHT_BIll_INVENTORY.ALL) {
                dataSource[item] = [...freightBillInventories];
            } else {
                dataSource[item] = freightBillInventories.filter(info => info.status === STATUS_FREIGHT_BIll_INVENTORY[item]);
            }
        });
        dataSource['warning'] = freightBillInventories.filter(info => info.warning);
        setData(dataSource);
    }, [freightBillInventories]);

    return (
        <div className="mt-3">
            <Row gutter={16} type="flex">
                <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                    <Tabs
                        className="heading-fz-14 customize-order-ant-tabs"
                        defaultActiveKey={STATUS_FREIGHT_BIll_INVENTORY.ALL}
                        type="card"
                        tabBarExtraContent={
                            <ExportFinanceFreightBill
                                documentId={documentId}
                                filters={{ status: STATUS_FREIGHT_BIll_INVENTORY.INCORRECT }}
                                btnName={t('finance:btn.export_incorrect_order')}
                                exportName={t('finance:btn.export_incorrect_order')}
                            />
                        }
                    >
                        {Object.keys(STATUS_FREIGHT_BIll_INVENTORY).map((item, index) => {
                            return (
                                <TabPane
                                    tab={t(
                                        `finance:finance_control.status_freight_bill_inventory.${STATUS_FREIGHT_BIll_INVENTORY[item]}`
                                    )}
                                    key={index + 1}
                                >
                                    <ListOfLading dataSource={getVar(data, item, [])} loading={loading} />
                                </TabPane>
                            );
                        })}
                        <TabPane tab={t(`finance:finance_control.label.warning`)} key={'warning'}>
                            <ListOfLading dataSource={getVar(data, 'warning', [])} loading={loading} />
                        </TabPane>
                    </Tabs>
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 6 }} className="_summary-document-control-detail">
                    <SummaryDocumentDetail
                        form={form}
                        loading={loading}
                        currency={currency}
                        canUpdate={canUpdate}
                        handleUpdate={handleUpdate}
                        data={freightBillInventories}
                    />
                </Col>
            </Row>
        </div>
    );
}
export default Body;
