import { Row } from 'antd'
import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import {useListOrderQuery} from '@Modules/Order/Hooks/useListOrderQuery'
import MenuOrderAction from '@Modules/Order/components/MenuOrderAction'

import { events } from '@System/events'
import { t } from '@System/i18n'
import useFilter from '../../../App/Hooks/useFilter'
import { setDocumentTitle, setNavigator } from '../../../App/services'
import { EVENTS } from '../../services/constants'
import FormSearch from './FormSearch'
import List from './List'
import { useSettingConfigQuery } from '@Modules/OperationConfig/hooks/useSettingConfigQuery'


export default function ListOrder({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, {})

    const [orders, setOrders] = useState([])
    const [pagination, setPagination] = useState({})
    const [canViewCustomer, setCanViewCustomer] = useState(false)
    const { data, isFetching: loading, refetch } = useListOrderQuery(filters)
    const {data: setting} = useSettingConfigQuery()

    useEffect(() => {
        setNavigator(t('title.list-orders'), [
            {
                name: t('breadcrumb.list-orders'),
            },
        ])
        setDocumentTitle(t('order_list'))
        return events.listen(EVENTS.IMPORT_ORDER_FROM_EXCEL, () => refetch())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setOrders(get(data, 'data.orders', []))
        setPagination(get(data, 'data.pagination', {}))
        setCanViewCustomer(get(data, 'data.can_view_customer', false))
    }, [data])

    return (
        <div className="site-content">
            <Row className="text-xl-right ant-row-space-between mt-3 mb-3">
                <div className="search-box--title d-lg-flex justify-content-between">
                    <h3 className="text-fz-18">{t('common:heading.list-orders')}</h3>
                </div>
                <MenuOrderAction fetchOrders={refetch} />
            </Row>
            <FormSearch
                onSearch={addFilter}
                filters={filters}
                initFilters={{}}
                loading={loading}
                setting={setting?.data || {}}
            />
            <List
                canViewCustomer={canViewCustomer}
                orders={orders}
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
                loading={loading}
                setting={setting?.data || {}}
            />
        </div>
    )
}
