import React, {useEffect, useState} from "react";
import {setDocumentTitle, setNavigator, warehouseStorage} from "../../../../App/services";
import {t} from "../../../../../system/i18n";
import _ from "lodash";
import useFilter from "../../../../App/Hooks/useFilter";
import FormSearch from "../../../components/FormSearch";
import List from "../../../components/List";
import {TYPES} from "@Modules/Document/services/constants";
import usePackingDocumentListQuery from "@Modules/Document/Hooks/usePackingDocumentListQuery";

const initFilters = {
    id: "",
    warehouse_id: "",
    creator_id: "",
    page: "",
    per_page: ""
};

export default function DocumentPackings({history, location}) {
    const {filters, addFilter} = useFilter(history, location, {
        ...initFilters,
        warehouse_id: warehouseStorage.value || ""
    });
    const [documents, setDocuments] = useState([]);
    const [pagination, setPagination] = useState({});
    const {data, isFetching:loading} = usePackingDocumentListQuery(filters);

    useEffect(() => {
        setNavigator(t("title.document-packings"), [
            {
                name: t("breadcrumb.document-packings")
            }
        ]);
        setDocumentTitle(t("title.document-packings"));
    }, []);

    useEffect(() => {
        setDocuments(_.get(data, 'data.document_packings', []));
        setPagination(_.get(data, 'data.pagination', {}));
    }, [data]);

    return (
        <div className="site-content mh-100">
            <FormSearch
                onSearch={addFilter}
                filters={filters}
                initFilters={initFilters}
                loading={loading}
                type={TYPES.TYPE_PACKING}
            />
            <List
                documents={documents}
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
                loading={loading}
                type={TYPES.TYPE_PACKING}
            />
        </div>
    );
}
