import React, {useState} from "react";
import {CloudUploadOutlined} from "@ant-design/icons";
import {Button, Modal, notification} from "antd";
import UploadFile from "../../App/Upload/UploadFile";
import api from "../services/api";
import lodash from 'lodash';
import {t} from "../../../system/i18n";
import {events} from "../../../system/events";
import {EVENTS} from "../services/constants";
import {renderError} from "./errors";
import i18next from "i18next";

export default function UploadProductFile() {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);

    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setMessages([]);
        setVisible(false);
    }

    function handleCancel() {
        setMessages([]);
        setVisible(false);
    }

    function handleUpload(file) {
        setLoading(true);
        setMessages([]);
        const filename = lodash.get(file, "name", "");
        let formData = new FormData();

        formData.append("file", file);
        api.importProducts(formData)
            .then(res => {
                const messages = lodash.uniqWith(lodash.get(res, "data.errors", []), lodash.isEqual);
                const hasInvalid = lodash.findIndex(messages, ['errors', "INVALID"]);
                if (hasInvalid === -1) {
                    setMessages(messages);
                    if (messages.length === 0) {
                        notification.success({message: t("product:message.upload_file.success", {filename})});
                        setVisible(false);
                    }
                } else {
                    notification.error({message: t("product:message.upload_file.fail", {filename})});
                }
                events.dispatch(EVENTS.IMPORT_PRODUCT_FROM_EXCEL, {});
            })
            .catch(err => {
                notification.error({message: t("common:message.server_error")})
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <Button
                className="mb-2 mb-lg-0"
                type="primary"
                onClick={showModal}
            ><CloudUploadOutlined/> {t("listProducts:btn.import_product")}</Button>
            <Modal
                title={t("listProducts:label.import_product").toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
            >
                <p>- {t("label.latest_at_update_sample")}: <strong>06/05/2021</strong></p>
                <p>- {t("label.download_sample_file")}:
                    <strong><a href={process.env.PUBLIC_URL + `/template/${i18next.language}/file_mau_tao_san_pham.xlsx`}>file_mau_tao_san_pham.xlsx</a></strong>
                </p>
                <p className="mb-3">- {t("label.maximum_file_size")}: <strong>5MB</strong></p>
                <UploadFile
                    onChange={handleUpload}
                    accept=".xls,.xlsx"
                    fileType={[
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        "application/vnd.ms-excel",
                    ]}
                    name="file tao san pham"
                    maxFileSize={5}
                    showUploadList={true}
                    loading={loading}
                />
                {
                    messages.length > 0 &&
                    <div className="pt-3">
                        {
                            messages.map((item, index) => {
                                return <p key={index} className="text-danger">- {renderError(item)}</p>
                            })
                        }
                    </div>
                }
            </Modal>
        </>
    );
}
