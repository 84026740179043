import { Button, Modal } from 'antd';
import clsx from 'clsx';
import lodash from 'lodash';
import isEqual from 'lodash/isEqual';
import uniqWith from 'lodash/uniqWith';
import React, { useCallback, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import useToggle from '@Modules/App/Hooks/useToggle';
import UploadFile from '@Modules/App/Upload/UploadFileV2';
import useImportConfirmStatusMutation from '@Modules/PurchasingPackage/Hooks/useImportConfirmStatusMutation';

import { t } from '@System/i18n';
import {renderError} from "@Modules/PurchasingPackage/components/errors";

const ImportConfirmFinanceStatus = ({ className, onSuccessImport }) => {
    const [errors, setErrors] = useState([]);
    const [file, setFile] = useState();
    const [visible, { setLeft, setRight }] = useToggle(false);
    const { isLoading: importing, mutateAsync: importForConfirm } = useImportConfirmStatusMutation();

    const handleUpload = useCallback(file => {
        setFile(file);
    }, []);

    const handleCancel = usePersistFn(() => {
        setLeft();
        setFile();
        setErrors([]);
    });

    const handleOk = usePersistFn(async () => {
        const { data } = await importForConfirm({
            file,
        });

        const errors = uniqWith(data?.errors, isEqual);

        if (errors.length) {
            setErrors(errors);
        } else {
            handleCancel();
            onSuccessImport();
        }
    });

    return (
        <>
            <Button className={clsx('_confirm-finance-status-btn', className)} onClick={setRight}>
                {t('product:confirm_finance_status.title')}
            </Button>

            {visible && (
                <Modal
                    cancelText={t('btn.cancel')}
                    className="_confirm-finance-status-modal"
                    footer={
                        <>
                            <Button onClick={handleCancel}>{t('btn.cancel')}</Button>

                            <Button loading={importing} type="primary" onClick={handleOk}>
                                {t('btn.ok')}
                            </Button>
                        </>
                    }
                    cancelButtonProps={{ className: '_confirm-finance-status-modal-btn-cancel' }}
                    okButtonProps={{ className: '_confirm-finance-status-modal-btn-ok' }}
                    okText={t('btn.ok')}
                    title={t('product:confirm_finance_status.title')}
                    visible={visible}
                    onCancel={handleCancel}
                >
                    <UploadFile
                        accept=".xls,.xlsx"
                        fileType={[
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                            'application/vnd.ms-excel',
                        ]}
                        maxFileSize={5}
                        showUploadList
                        onChange={handleUpload}
                    />
                    {errors && errors.length > 0 && (
                        <div className="pt-3">
                            {errors.map((error, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {renderError(error)}
                                    </p>
                                );
                            })}
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
};

export default ImportConfirmFinanceStatus;
