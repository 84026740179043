import React, {useEffect, useState} from "react";
import {Layout} from "antd";
import Navigator from "../Navigator";
import {events} from "../../../system/events";
import {EVENTS} from "../../../layouts/constants";
import UserInfo from "./UserInfo";

const {Header} = Layout;

function PageHeader({_onExpand, isShown, setIsShown}) {
    const [data, setData] = useState({});

    useEffect(() => {
        return events.listen(EVENTS.HEADER_BREADCRUMB, (event, payload) => setData(payload));
    }, []);

    return (
        <Header className="page-header">
            <div className="align-items-center justify-content-center d-none d-md-flex">
                <h2>{data.title || ""}</h2>
                <Navigator data={data.navigator}/>
            </div>

            <div className="menu-mobile-btn d-md-none" onClick={_onExpand}>
                <span></span>
            </div>

            <UserInfo isShown={isShown} setIsShown={setIsShown}/>
        </Header>
    );
}

export default PageHeader;
