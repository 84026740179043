import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';

import scanErrors from './scanErrors/reducer';

const PERSISTED_KEYS = ['scanErrors'];

const store = configureStore({
    reducer: {
        scanErrors,
    },
    middleware: [save({ states: PERSISTED_KEYS })],
    preloadedState: load({ states: PERSISTED_KEYS }),
});

export default store;
