import { Col, Row, Table } from 'antd';
import clsx from 'clsx';
import map from 'lodash/map';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import { currencyFormatter } from '@Modules/App/services';
import BarcodeWidget from '@Modules/Product/components/BarcodeWidget';
import { PATTERN_CHECK_IS_CODE_39 } from '@Modules/Product/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

const PackingSlipStamp = forwardRef(({ orderPackings }, ref) => {
    const stampSize = {
        width: 220,
    };

    return (
        <div className="_packing-slip-stamp" ref={ref}>
            {orderPackings?.length &&
                map(orderPackings, (orderPacking, index) => {
                    return (
                        <Row
                            className={clsx('sheet stamp _packing-slip-stamp p-2', {
                                'page-break': index !== 0,
                            })}
                            key={orderPacking?.id}
                        >
                            <div className="shipping-info">
                                <div className=" text-center">
                                    <p className="mb-0">
                                        <b>{t('order:label.shipping_stamp').toUpperCase()}</b>
                                    </p>
                                </div>

                                <div className="pt-3 pb-3 text-center">
                                    {!!orderPacking?.freight_bill && (
                                        <BarcodeWidget
                                            value={orderPacking?.freight_bill}
                                            height={60}
                                            width={stampSize.width}
                                            fontSize={16}
                                            // format={
                                            //     PATTERN_CHECK_IS_CODE_39.test(orderPacking?.freight_bill) ? 'CODE39' : 'CODE128'
                                            // }
                                        />
                                    )}
                                </div>

                                <div className="pb-4 text-center">
                                    <p className="mb-0">{`${t('shipping_unit')}: ${getVar(
                                        orderPacking,
                                        'shipping_partner.name',
                                        ''
                                    )}`}</p>
                                </div>

                                <div className="pb-2">
                                    <Row gutter={20}>
                                        <Col span={8}>
                                            <strong>{t('sender')}:</strong>
                                        </Col>
                                        <Col span={16}>
                                            <p className="mb-0">{orderPacking?.warehouse?.name}</p>
                                            {!!orderPacking?.warehouse?.address && (
                                                <p className="mb-0">{orderPacking?.warehouse?.address}</p>
                                            )}
                                        </Col>

                                        <Col span={8}>
                                            <strong>{t('receiver')}:</strong>
                                        </Col>
                                        <Col span={16}>
                                            <p className="mb-0">
                                                {[orderPacking?.order?.receiver_name, orderPacking?.order?.receiver_phone]
                                                    .filter(item => item)
                                                    .join(' - ')}
                                            </p>
                                            <p className="mb-0">{orderPacking?.order?.receiver_address}</p>
                                        </Col>

                                        <Col span={8}>
                                            <strong>{t('order:label.packing_slip.total_receipt_receiver')}:</strong>
                                        </Col>
                                        <Col span={16}>
                                            <p className="mb-0">
                                                {currencyFormatter.toLocaleStringCurrency(orderPacking?.order?.cod)} VNĐ
                                            </p>
                                        </Col>

                                        <Col span={8}>
                                            <strong>{t('order:label.packing_slip.note_when_delivery')}:</strong>
                                        </Col>
                                        <Col span={16}>
                                            <p className="mb-0">{orderPacking?.order?.receiver_note}</p>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="pb-2 text-center tile">
                                    <p className="mb-0">{t('label.orders').toUpperCase()}</p>
                                </div>

                                <Row className="pb-2">
                                    <Col span={12}>
                                        <p>
                                            {`${t('weight')}: ${
                                                parseFloat(sumBy(orderPacking?.order?.order_skus, 'weight')).toFixed(2) || 0
                                            } kg`}
                                            :{' '}
                                        </p>
                                    </Col>

                                    <Col span={12}>
                                        <p>{`${t('total_product')} : ${orderPacking?.total_quantity || 0}`}</p>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Table
                                    className={'packing-slip-table'}
                                    columns={[
                                        {
                                            title: t('table.#'),
                                            render: (_, __, index) => index + 1,
                                        },
                                        {
                                            title: t('order:label.warehouse_area'),
                                            dataIndex: 'warehouse_area',
                                        },
                                        {
                                            title: t('order:label.packing_slip.product_code'),
                                            dataIndex: 'sku_code',
                                        },
                                        {
                                            className: 'sku_name',
                                            title: t('order:label.packing_slip.product_name'),
                                            dataIndex: 'sku_name',
                                        },
                                        {
                                            title: t('order:label.packing_slip.quantity'),
                                            dataIndex: 'quantity',
                                        },
                                    ]}
                                    dataSource={orderPacking?.order_packing_items}
                                    bordered
                                    pagination={false}
                                    rowKey={row => row?.id}
                                />
                            </div>
                        </Row>
                    );
                })}
        </div>
    );
});

PackingSlipStamp.propTypes = {
    orderPackings: PropTypes.array,
};

PackingSlipStamp.defaultProps = {
    orderPackings: [],
};

export default PackingSlipStamp;
