import { get } from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import ReactBarcode from 'react-barcode';

function numberOfBars(value) {
    return 11 * value.length + 35;
}

export default function BarcodeWidget(props) {
    const [barWidth, setBarWidth] = useState(props.width / numberOfBars(props.value));
    const barcodeEl = useRef(null);

    useEffect(() => {
        if (!barcodeEl || !barcodeEl.current || props.width == null || props.height == null) {
            return;
        }
        const widthString = get(barcodeEl, 'current.renderElementRef.current')?.getAttribute('width');
        const trueWidth = widthString ? parseInt(widthString.split('px')[0], 10) : 0;
        const scale = props.width / trueWidth;
        setBarWidth(barWidth * scale);
    }, [props.width, props.height, barcodeEl?.current]);

    return (
        <ReactBarcode
            ref={barcodeEl}
            value={props.value}
            width={barWidth}
            height={props.height}
            margin={5}
            displayValue={true}
            fontSize={props.fontSize}
            textMargin={0}
            // format={props.format || 'CODE128'}
        />
    );
}
