import { Button, Form, Modal, Select } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { isEmpty, omit } from 'lodash';
import React, { useEffect, useState } from 'react';

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput';
import api from '@Modules/Dropshipping/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { formatCurrency } from '@System/support/numberFormat';

const { Option } = Select;

const ModalCreateQuotation = ({ setIsModalVisible, productId, skus, currency, reCallApi }) => {
    const { xs } = useBreakpoint();
    const [form] = Form.useForm();
    const [typeSelect, setTypeSelect] = useState('COMBO');
    const [formCombo, setFormCombo] = useState({
        type: 'COMBO',
        prices: [
            {
                combo: 1,
                cost_price: undefined,
                service_packing_price: undefined,
                service_shipping_price: undefined,
            },
        ],
    });

    const [formSku, setFormSku] = useState({
        type: 'SKU',
        prices: [],
    });

    const [count, setCount] = useState(2);

    const [loadingButton, setLoadingButton] = useState(false);

    useEffect(() => {
        const refactorData = () => {
            const newArr =
                skus &&
                skus
                    .filter(item => item.sku.status !== 'STOP_SELLING')
                    .map((val, index) => ({
                        sku_id: val.sku.id,
                        sku_code: val.sku.code,
                        cost_price: '',
                        service_packing_price: '',
                        service_shipping_price: '',
                    }));

            setFormSku(prev => {
                prev.prices.push(...newArr);
                return prev;
            });
        };

        refactorData();
    }, [skus]);

    const handleAdd = () => {
        setFormCombo(prevState => {
            prevState.prices.push({
                combo: count,
                cost_price: '',
                service_packing_price: '',
                service_shipping_price: '',
            });

            return { ...prevState };
        });

        setCount(count + 1);
    };

    const saveRow = (index, field, value) => {
        let newForm = typeSelect === 'COMBO' ? formCombo : formSku;
        newForm.prices[index][field] = value;

        typeSelect === 'COMBO' ? setFormCombo({ ...newForm }) : setFormSku({ ...newForm });
    };

    const handleChangeSelect = value => {
        setTypeSelect(value);
    };

    const handleOk = async () => {
        await form.validateFields();

        setLoadingButton(true);

        try {
            await api.createQuotation(productId, typeSelect === 'COMBO' ? formCombo : formSku);
            notification.success(t('product:message.create_quotation_success'));
            reCallApi();
            setIsModalVisible(false);
        } catch (error) {
            notification.error(t('product:message.create_quotation_failed'));
        } finally {
            setLoadingButton(false);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const sumValues = obj =>
        Object.values(obj).reduce((a, b) => {
            return b !== undefined ? Number(a) + Number(b) : 0;
        }, 0);
    return (
        <Modal
            okText={t('product:btn.save_quotation')}
            cancelText={t('btn.cancel')}
            title={t('product:btn.create_quotation')}
            className="modal-dropshipping-detail-create_price"
            visible={true}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1400}
            cancelButtonProps={{ className: '_modal-confirm-dropship-btn-cancel' }}
            okButtonProps={{ className: '_dropship-service_btn-save', loading: loadingButton }}
        >
            <Form form={form}>
                <div className="p-4 m-4" style={{ border: '1px solid #e5e5e5' }}>
                    <div className={`d-flex justify-content-between ${xs && 'mb-3'}`}>
                        <Form.Item
                            className="mb-0 flex-grow-1 _dropship-service_type"
                            labelAlign="left"
                            wrapperCol={{ span: 4 }}
                            label={<strong style={{ weight: 500 }}>{t('product:label.type_quotation')}</strong>}
                            name="type"
                            initialValue={'COMBO'}
                        >
                            <Select
                                placeholder={t('product:placeholder.type_quotation')}
                                className="_dropship-select_service-type"
                                onChange={handleChangeSelect}
                            >
                                <Option value="COMBO">{t('product:label.combo_quotation')}</Option>
                                <Option value="SKU">{t('product:label.varian_quotation')}</Option>
                            </Select>
                        </Form.Item>
                    </div>
                    <div className={`ant-table ant-table-scroll-horizontal ${xs && 'ant-table-ping-right'}`}>
                        <div className="ant-table-container">
                            <div className="ant-table-content" style={{ overflow: 'auto hidden' }}>
                                <table
                                    style={{ width: 'auto', minWidth: '100%', tableLayout: 'auto' }}
                                    className="_dropship-service-list_combo-table"
                                >
                                    <thead className="ant-table-thead">
                                        <tr>
                                            <th
                                                className={`ant-table-cell _quote-create-service-box-table-${
                                                    typeSelect === 'COMBO' ? 'combo' : 'code'
                                                }`}
                                            >
                                                {typeSelect === 'COMBO' ? t('product:label.combo') : t('product:label.varians')}
                                            </th>
                                            <th className="ant-table-cell _quote-create-service-box-table-cost_price">
                                                {t('product:label.cost_package')}
                                            </th>
                                            <th className="ant-table-cell _quote-create-service-box-table-packing_service">
                                                {t('product:label.packing_service_price')}
                                            </th>
                                            <th className="ant-table-cell _quote-create-service-box-table-transport_service">
                                                {t('product:label.transport_service_price')}
                                            </th>
                                            <th className="ant-table-cell _quote-create-service-box-table-sum">
                                                {t('common:sum')}
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="ant-table-tbody">
                                        {(typeSelect === 'COMBO' ? formCombo : formSku).prices.map((item, index) => (
                                            <tr key={index} className="ant-table-row ant-table-row ant-table-row-level-0">
                                                {Object.keys(omit(item, ['sku_id'])).map((field, idx) => (
                                                    <td key={idx} className={`ant-table-cell _quote-create-table-${field}`}>
                                                        {field === 'combo' || field === 'sku_code' ? (
                                                            <p>{typeSelect === 'COMBO' ? item.combo : item.sku_code}</p>
                                                        ) : (
                                                            <Form.Item
                                                                name={[
                                                                    `prices-${typeSelect === 'COMBO' ? 'combo' : 'sku'}`,
                                                                    index,
                                                                    field,
                                                                ]}
                                                                className="mb-0 _dropship-service_input"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: t('product:message.required_not_attribute'),
                                                                    },
                                                                ]}
                                                            >
                                                                <CurrencyInput
                                                                    style={{ width: `${xs ? '200px' : '100%'}` }}
                                                                    value={item.field}
                                                                    currency={currency}
                                                                    className={`_quote-create-table-${field} text-right`}
                                                                    onBlur={value => saveRow(index, field, value)}
                                                                    onPressEnter={value => saveRow(index, field, value)}
                                                                />
                                                            </Form.Item>
                                                        )}
                                                    </td>
                                                ))}

                                                <td className="_quote-create-service-box-table-sum text-right">
                                                    {
                                                        <p>
                                                            {!isEmpty(currency) &&
                                                                formatCurrency(
                                                                    sumValues(
                                                                        typeSelect === 'COMBO'
                                                                            ? omit(formCombo.prices[index], ['combo'])
                                                                            : omit(formSku.prices[index], ['sku_id', 'sku_code'])
                                                                    ),
                                                                    currency
                                                                )}
                                                        </p>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {typeSelect === 'COMBO' && (
                        <Button onClick={handleAdd} type="primary" className="_dropship-service_btn-add-row mt-2">
                            {t('product:btn.add_row_combo')}
                        </Button>
                    )}
                </div>
            </Form>
        </Modal>
    );
};

export default ModalCreateQuotation;
