import moment from 'moment'

import { api } from '@System/api'
import { generatorHash } from '@System/support/helpers'

// eslint-disable-next-line import/no-anonymous-default-export

const apiService = {
    getSuppliers: params =>
        api.get('/suppliers', {
            params,
        }),
    createSupplier: data => api.post(`/suppliers`, data),
    updateSupplier: (id, data) => api.put(`/suppliers/${id}`, data),
    createTransactionSupplier: (id, data) => {
        const time = moment(new Date()).startOf('minutes').format('X')
        return api.post(`/document-supplier-transactions/${id}`, data, { idempotencyKey: generatorHash(data, time) })
    },
    getSuppliersDebtInformation: id => api.get(`/suppliers/${id}/wallets`, {}),
    getSuppliersTransactionHistory: (id,params) => api.get(`/suppliers/${id}/transactions`, { params }),
    getSupplierDetail: id => api.get(`/suppliers/${id}`, {}),
}

export default apiService
