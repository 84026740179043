import { Col, Form, Input, Row, Spin, Typography, Upload } from 'antd'
import { get, isEmpty, map } from 'lodash'
import React, { useState, useEffect } from 'react'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

const { Paragraph } = Typography

const Info = ({ detail, loading }) => {
    const [images, setImages] = useState([])

    useEffect(() => {
        setImages(refactorImage(get(detail, 'image')))
    }, [detail])
    const refactorImage = images => {
        const newImages = []
        map(images, (item, index) => {
            return newImages.push({ url: item, uid: index + 1 })
        })
        return newImages
    }
    return (
        <Spin spinning={false}>
            <div>

                <Row
                    gutter={20}
                    className="mt-3"
                >
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 4 }}
                    >
                        <div className="font-weight-500 mb-2">{t('product:label.code')}:</div>
                        <b>
                            <span className="font-weight-500 text-dark">
                                <Paragraph
                                    copyable={{
                                        text: get(detail, 'code', t('noText')),
                                    }}
                                >
                                    <span className="font-weight-500 text-dark">{getVar(detail, 'code', t('noText'))}</span>
                                </Paragraph>
                            </span>
                        </b>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 4 }}
                    >
                        <div className="font-weight-500 mb-2">{t('product:label.name')}:</div>

                        <div>
                            <span>{getVar(detail, 'name', t('noText'))}</span>
                        </div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 4 }}
                    >
                        <div className="font-weight-500 mb-2"> {t('product:label.category_id')}:</div>

                        <div>
                            <span>{getVar(detail, 'category.data.name', t('noText'))}</span>
                        </div>
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        lg={{ span: 4 }}
                    >
                        <div className="font-weight-500 mb-2">{t('product:label.product_source')}:</div>
                        <div>
                            <span>{getVar(detail, 'source', t('noText'))}</span>
                        </div>
                    </Col>
                </Row>

                <div className="d-flex align-items-center mt-3">
                    <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                    <span className="ml-2 number-circle _product-detail-picture-total">{!isEmpty(images) ? images.length : 0}</span>
                    <i className="ml-2">{t('product:description.picture')}</i>
                </div>
                <div className="my-2">
                    {isEmpty(images) ? (
                        <span>{t('product:label.no_images_yet')}</span>
                    ) : (
                        <Upload
                            className="_product-detail-list-product"
                            listType="picture-card"
                            fileList={images}
                            disabled={true}
                        />
                    )}
                </div>
            </div>
        </Spin>
    )
}

export default Info
