import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal } from 'antd';
import i18next from 'i18next';
import lodash from 'lodash';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import React, { useCallback, useState } from 'react';

import UploadFile from '@Modules/App/Upload/UploadFileV2';
import useCheckingCreateControlDeliveryMutation from '@Modules/Finance/Hooks/useCheckingCreateControlDeliveryMutation';
import useCreateControlDeliveryMutation from '@Modules/Finance/Hooks/useCreateControlDeliveryMutation';
import SelectShippingPartner from '@Modules/ShippingPartners/components/SelectShippingPartner';

import { t } from '@System/i18n';
import notification from '@System/notification';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';
import isEmpty from "lodash/isEmpty";

interface ICreateFinanceControlDeliveryProps {}

const CreateFinanceControlDelivery: React.FC<ICreateFinanceControlDeliveryProps> = () => {
    const [form] = Form.useForm();
    const [errors, setErrors] = useState<string[]>([]);
    const [visible, setVisible] = useState(false);
    const [processedRows, setProcessedRows] = useState(0);
    const { isLoading, mutateAsync: createDocument } = useCreateControlDeliveryMutation();
    const { isLoading: isChecking, mutateAsync: checkingData } = useCheckingCreateControlDeliveryMutation();

    const handleUpload = useCallback(file => {
        setErrors([]);
        form.setFields([{ name: 'file', value: file }]);
    }, []);

    const handleCancel = () => {
        setVisible(false);
        setErrors([]);
        form.resetFields();
    };

    const handleOk = () => {
        form.validateFields().then(async values => {
            let formData = { ...values };
            const { data } = await createDocument(formData);
            const documentId = getVar(data, 'document.id', undefined);
            if (documentId) {
                notification.success(t('order:confirm_order_info.success'));
                handleCancel();
                url.redirectTo('finances.control-delivery.detail', {
                    id: documentId,
                });
            } else {
                notification.error(t('finance:message.create_finance_control.document_not_created'));
            }
        });
    };

    const handleChecking = () => {
        form.validateFields().then(async values => {
            let formData = { ...values };
            const { data } = await checkingData(formData);
            const errors: string[] = get(data, 'errors', []);
            setProcessedRows(get(data, 'processed_rows', 0));
            if (errors.length) {
                setErrors(flatten(errors));
            } else {
                handleOk();
            }
        });
    };

    function renderError(error: string) {
        const ERRORS = [
            'not_exist',
            'already_exist',
            'required',
            'invalid',
            'numeric',
            'empty',
            'exists',
            'INVALID_COLUMN',
        ];
        const columns = [
            "cod_total",
          "weight_total",
          "skus_count",
          "freight_bill_code"
        ];
        let line = lodash.get(error, 'line', '');
        const content = lodash.get(error, 'errors', error);
        let messages: string[] = [];
        if (!isEmpty(content)) {
            Object.entries(content).forEach(entry => {
                let [key, errors] = entry;
                ERRORS.forEach(errorKey => {
                    if (key === 'file' && errors === errorKey) {
                        line = 1;
                        messages.push(t(`finance:message.create_finance_control.file_empty`));
                    } else {
                        columns.map(column => {
                            if (get(errors, column) === errorKey) {
                                messages.push(
                                  t(`finance:message.create_finance_control.${errorKey}`, {
                                      attribute: t(`finance:finance_control.label.${column}`),
                                  })
                                );
                            }
                        })
                    }
                });
            });
        } else {
            if (content === 'INVALID' || content === 'INVALID_COLUMN') {
                return t('finance:message.create_finance_control.fail');
            }
        }

        if (messages.length > 0)
            return t('finance:message.create_finance_control.line', { line: line, content: messages.join(' ') });
        return t('finance:message.create_finance_control.unknown_error');
    }
    return (
        <>
            <Button type="primary" className="_btn-create-finance-control" onClick={() => setVisible(true)}>
                <PlusOutlined /> {t('common:btn.create_document_control')}
            </Button>

            {visible && (
                <Modal
                    cancelText={t('btn.cancel')}
                    className="_create-finance-control-modal"
                    footer={
                        errors && errors.length > 0 && !!processedRows
                            ? [
                                  <Button
                                      key={'handle_cancel'}
                                      className="_btn-cancel-create-finance-control"
                                      onClick={handleCancel}
                                  >
                                      {t('common:btn.cancel')}
                                  </Button>,

                                  <Button
                                      key={'handle_continue'}
                                      loading={isLoading}
                                      type="primary"
                                      className="ml-2 _btn-confirm-create-finance-control"
                                      onClick={() => handleOk()}
                                  >
                                      {t('common:btn.continue')}
                                  </Button>,
                              ]
                            : [
                                  <Button
                                      key={'handle_cancel'}
                                      className="_btn-cancel-create-finance-control"
                                      onClick={handleCancel}
                                  >
                                      {t('common:btn.cancel')}
                                  </Button>,
                                  <Button
                                      key={'handle_ok'}
                                      loading={isChecking}
                                      type="primary"
                                      className="ml-2 _btn-confirm-create-finance-control"
                                      onClick={() => handleChecking()}
                                  >
                                      {t('common:btn.ok')}
                                  </Button>,
                              ]
                    }
                    okText={t('btn.ok')}
                    title={t('finance:finance_control.title.create_finance_control_delivery')}
                    visible={visible}
                    onCancel={handleCancel}
                    width={650}
                >
                    <Form form={form}>
                        <p>
                            - {t('label.latest_at_update_sample')}: <strong>21/05/2021</strong>
                        </p>
                        <p>
                            - {t('label.download_sample_file')}:
                            <strong>
                                <a
                                    href={
                                        process.env.PUBLIC_URL +
                                        `/template/${i18next.language}/file_mau_doi_soat_giao_nhan.xlsx`
                                    }
                                >
                                    file_mau_doi_soat_giao_nhan.xlsx
                                </a>
                            </strong>
                        </p>
                        <p className="mb-3">
                            - {t('label.maximum_file_size')}: <strong>5MB</strong>
                        </p>
                        <Form.Item
                            className="mb-3"
                            label={t('order:label.order_shipping_partner')}
                            name="shipping_partner_id"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', {
                                        attribute: t('order:label.order_shipping_partner'),
                                    }),
                                },
                            ]}
                        >
                            <SelectShippingPartner
                                className={'_prepare-order-search-shipping-partner-id'}
                                placeholder={t('order:placeholder.shipping_partner_id')}
                                allowClear={true}
                                onChange={(value: any) =>
                                    form.setFields([{ name: 'shipping_partner_id', value, errors: [] }])
                                }
                                locationByUser
                            />
                        </Form.Item>
                        <Form.Item
                            className="mb-3"
                            name="file"
                            required
                            rules={[{ required: true, message: t('finance:message.required_file') }]}
                        >
                            <UploadFile
                                accept=".xls,.xlsx"
                                fileType={[
                                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                    'application/vnd.ms-excel',
                                ]}
                                maxFileSize={5}
                                onChange={handleUpload}
                                name={undefined}
                                loading={undefined}
                            />
                        </Form.Item>
                    </Form>
                    {errors && errors.length > 0 && (
                        <div className="pt-3">
                            {errors.map((error, index) => {
                                return (
                                    <p key={index} className="text-danger">
                                        - {renderError(error)}
                                    </p>
                                );
                            })}
                            {!!processedRows && (
                                <p key={'confirm_create_finance_control'} className="mt-4">
                                    {t('finance:finance_control.title.confirm_create_finance_control')}
                                </p>
                            )}
                        </div>
                    )}
                </Modal>
            )}
        </>
    );
};
export default CreateFinanceControlDelivery;
