import { get } from 'lodash'
import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'

import useFilter from '@Modules/App/Hooks/useFilter'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import useDebtsShippingListQuery from '@Modules/Finance/Hooks/useDebtsShippingListQuery'
import useDebtsShippingStatsQuery from '@Modules/Finance/Hooks/useDebtsShippingStatsQuery'
import { IDebtsShippingStats } from '@Modules/Finance/Interfaces/interfaces'
import UploadEstimatedShippingFee from '@Modules/Finance/components/DebtsShipping/UploadEstimatedShippingFee'

import { t } from '@System/i18n'

import FormSearch from './FormSearch'
import List from './List'
import Summary from './Summary'

interface IDebtsShippingProps {
    history: object
    location: object
}

const nowDay = new Date()
const ninetyDayAgo = moment().subtract(3, 'months')

const defaultValue = {
    'inventory_freight_bill_created_at[from]': moment(ninetyDayAgo).format('YYYY-MM-DD'),
    'inventory_freight_bill_created_at[to]': moment(nowDay).format('YYYY-MM-DD'),
    inventory_document_status: undefined,
    inventory_order_status: undefined,
    shipping_partner_id: undefined,
    freight_bill: undefined,
    shipping_financial_status:undefined
}

const initStats = {
    currency: {},
    debts_paid: t('noText'),
    expected: t('noText'),
    unpaid_debt: t('noText'),
}

const DebtsShipping: React.FC<IDebtsShippingProps> = ({ history, location }) => {
    const { filters, addFilter } = useFilter(history, location, defaultValue)
    const [orders, setOrders] = useState([])
    const [currency, setCurrency] = useState({})

    const [pagination, setPagination] = useState({})
    const [stats, setStats] = useState<IDebtsShippingStats>(initStats)
    const { data: dataStats, isFetching: loadingStats } = useDebtsShippingStatsQuery(filters)
    const { data: dataList, isFetching: loadingList } = useDebtsShippingListQuery(filters)

    useEffect(() => {
        setNavigator(t('menu.finances'), [
            {
                name: t('menu.debts_shipping_unit'),
            },
        ])
        setDocumentTitle(t('menu.debts_shipping_unit'))
    }, [])

    useEffect(() => {
        setStats(get(dataStats, 'data', initStats))
        setOrders(get(dataList, 'data.orders', []))
        setCurrency(get(dataList, 'data.currency', {}))
        setPagination(get(dataList, 'data.pagination', {}))
    }, [dataList, dataStats])
    // auth.can(permissions.FINANCE_SHIPPING_PARTNER_EXPECTED_TRANSPORTING_PRICES_CONFIG)

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('menu.debts_shipping_unit')}</h3>

                {auth.can(permissions.FINANCE_SHIPPING_PARTNER_EXPECTED_TRANSPORTING_PRICES_CONFIG) && <UploadEstimatedShippingFee />}
            </div>
            <FormSearch
                onSearch={addFilter}
                filters={filters}
                loading={loadingList}
                defaultValue={defaultValue}
            />
            <Summary
                data={stats}
                isLoading={loadingStats}
            />
            <List
                filters={filters}
                orders={orders}
                loading={loadingList}
                pagination={pagination}
                addFilter={addFilter}
                currency={currency}
            />
        </div>
    )
}

export default DebtsShipping
