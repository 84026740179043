import { Row, Col } from 'antd';
import clsx from 'clsx';
import chunk from 'lodash/chunk';
import forEach from 'lodash/forEach';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

import BarcodeWidget from '@Modules/Product/components/BarcodeWidget';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';

const RenderPrintWarehouseAreaBarcode = forwardRef(({ data, stampSize, warehouseAreaIds = [] }, ref) => {
    const warehouseAreaList = [];
    forEach(data, warehouseAreaInfo => {
        const warehouseAreaId = getVar(warehouseAreaInfo, 'warehouseArea.id', '');
        const warehouseAreaName = getVar(warehouseAreaInfo, 'warehouseArea.name', '');
        if (warehouseAreaIds.includes(warehouseAreaId)) {
            let barcodeValue = getVar(warehouseAreaInfo, 'warehouseArea.code', '');
            if (barcodeValue) barcodeValue = barcodeValue.toString();
            warehouseAreaList.push(
                <Col span={stampSize.span} key={warehouseAreaId}>
                    <div className="generate-item">
                        <p className="sku-name">
                            {warehouseAreaName === 'default' ? t('order:label.default') : warehouseAreaName}
                        </p>
                        <BarcodeWidget value={barcodeValue} height={25} width={stampSize.width} fontSize={12} />
                    </div>
                </Col>
            );
        }
    });

    return (
        <div className="sku-info" ref={ref}>
            {chunk(warehouseAreaList, stampSize.item_per_page).map((chunkInfo, index) => (
                <Row
                    gutter={10}
                    className={clsx('sheet h-100 align-items-center', {
                        'page-break': index !== 0,
                    })}
                    key={index}
                >
                    {chunkInfo}
                </Row>
            ))}
        </div>
    );
});

RenderPrintWarehouseAreaBarcode.propTypes = {
    data: PropTypes.array,
    warehouseAreaIds: PropTypes.array,
};

RenderPrintWarehouseAreaBarcode.defaultProps = {
    data: [],
    warehouseAreaIds: [],
};

export default RenderPrintWarehouseAreaBarcode;
