import { Tabs, Badge, Table } from 'antd'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import ExportFinanceControlDelivery from '@Modules/Finance/components/ExportFinanceControlDelivery'
import { STATUS_FREIGHT_BIll_INVENTORY, STATUS_FREIGHT_BIll_INVENTORY_COLORS } from '@Modules/Finance/services/constants'

import { t } from '@System/i18n'
import { url } from '@System/routing'
import { formatCurrency } from '@System/support/numberFormat'

const { TabPane } = Tabs
interface IBodyProps {
    freightBillInventories: object[]
    loading: boolean
    documentId: number
    documentInfo: object
    currency: any
}
const Body: React.FC<IBodyProps> = ({ freightBillInventories, loading, documentId, currency }) => {
    const [data, setData] = useState<object>({})
    useEffect(() => {
        let dataSource: object = {}
        Object.keys(STATUS_FREIGHT_BIll_INVENTORY).map((item, index) => {
            const keyStatus = get(STATUS_FREIGHT_BIll_INVENTORY, item, '')
            if (keyStatus === STATUS_FREIGHT_BIll_INVENTORY.ALL) {
                dataSource = {
                    ...dataSource,
                    [item]: [...freightBillInventories],
                }
            } else {
                dataSource = {
                    ...dataSource,
                    [item]: freightBillInventories.filter(info => get(info, 'status', undefined) === keyStatus),
                }
            }
        })
        setData(dataSource)
    }, [freightBillInventories])

    const renderTableInfo = (data: object[]) => {
        const columns = [
            {
                className: '_column-freight-bill-code',
                title: t('label.freight_bill_code'),
                dataIndex: 'freight_bill_code',
                key: 'freight_bill_code',
                render: (text: string | number, record: object) => {
                    const orderId = get(record, 'order_id', undefined)
                    return orderId ? <Link to={url.to('orders.detail', { id: orderId })}>{text}</Link> : text
                },
            },
            {
                className: '_column-quantity-by-freight-bill',
                title: t('finance:label.quantity_by_freight_bill'),
                dataIndex: 'skus_count_order',
                key: 'quantity_by_freight_bill',
            },
            {
                className: '_column-quantity-by-shipping-partner',
                title: t('finance:label.quantity_by_shipping_partner'),
                dataIndex: 'skus_count_carrier',
                key: 'quantity_by_shipping_partner',
                render: (text: string | number) => {
                    return text ? text : t('noText')
                },
            },
            {
                className: '_column-cod-by-freight-bill',
                title: t('finance:label.cod_by_freight_bill'),
                dataIndex: 'cod_total_order',
                key: 'cod_by_freight_bill',
                render: (text: string | number) => {
                    return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : ''
                },
            },
            {
                className: '_column-cod-by-shipping-partner',
                title: t('finance:label.cod_by_shipping_partner'),
                dataIndex: 'cod_total_carrier',
                key: 'cod_by_shipping_partner',
                render: (text: string | number) => {
                    return text ? (!isEmpty(currency) ? formatCurrency(text, currency) : text) : ''
                },
            },
            // {
            //     className: '_column-total-weight',
            //     title: t('finance:label.total_weight'),
            //     dataIndex: 'weight_total_order',
            //     key: 'total_weight',
            //     render: (text: string | number) => {
            //         return text ? t('finance:label.kg', { value: text }) : t('noText')
            //     },
            // },
            // {
            //     className: '_column-total-weight-by-shipping-partner',
            //     title: t('finance:label.total_weight_by_shipping_partner'),
            //     dataIndex: 'weight_total_carrier',
            //     key: 'total_weight_by_shipping_partner',
            //     render: (text: string | number) => (text ? t('finance:label.kg', { value: text }) : t('noText')),
            // },
            {
                className: '_column-total-status',
                title: t('label.status'),
                dataIndex: 'status',
                key: 'status',
                render: text =>
                    text ? (
                        <Badge
                            color={STATUS_FREIGHT_BIll_INVENTORY_COLORS[text]}
                            text={t(`finance:finance_control.status_freight_bill_inventory.${text}`)}
                        />
                    ) : null,
            },
            {
                className: '_column-warning text-wrap',
                title: t('finance:label.warning'),
                dataIndex: 'errors',
                key: 'warning',
                render: (text: object[]) => {
                    const columns = ['cod_total', 'weight_total', 'skus_count', 'freight_bill_code']
                    const messages: string[] = []
                    if (!isEmpty(text)) {
                        text.map(item => {
                            columns.map(column => {
                                const error = get(item, column)
                                if (error) {
                                    messages.push(
                                        t(`finance:message.wrong_order`, {
                                            label: t(`finance:finance_control.label.${column}`),
                                        })
                                    )
                                }
                            })
                        })

                        return messages.length > 0 ? messages.join(', ') : ''
                    }
                    return ''
                },
            },
        ]
        return (
            <Table
                scroll={{ x: 576 }}
                loading={loading}
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey={record => get(record, 'freight_bill_code')}
                locale={{
                    emptyText: (
                        <TableEmpty
                            loading={loading}
                            className={undefined}
                        />
                    ),
                }}
            />
        )
    }

    return (
        <div className="mt-3">
            <Tabs
                className="heading-fz-14 customize-order-ant-tabs"
                defaultActiveKey={STATUS_FREIGHT_BIll_INVENTORY.ALL}
                type="card"
                tabBarExtraContent={
                    <ExportFinanceControlDelivery
                        documentId={documentId}
                        filters={{ status: STATUS_FREIGHT_BIll_INVENTORY.INCORRECT }}
                    />
                }>
                {Object.keys(STATUS_FREIGHT_BIll_INVENTORY).map((item, index) => {
                    const tabKey = get(STATUS_FREIGHT_BIll_INVENTORY, item, '')
                    return (
                        <TabPane
                            tab={t(`finance:finance_control.status_freight_bill_inventory.${tabKey}`)}
                            key={index + 1}>
                            {renderTableInfo(get(data, item, []))}
                        </TabPane>
                    )
                })}
            </Tabs>
        </div>
    )
}
export default Body
