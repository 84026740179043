import { useQuery } from 'react-query';

import { catchErrors } from '@Modules/App/services/helpers';

import { t } from '@System/i18n';

import apiService from './../services/api';
import processResponseError from "@System/api/processResponseError";

const useProductQuery = id =>
    useQuery(['product', id], () => apiService.getProduct(id), {
        enabled: !!id,
        retry: false,
        onError: (err) => {
           processResponseError(err)
        },
    });

export default useProductQuery;
