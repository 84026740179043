import { Table } from 'antd';
import isFunction from 'lodash/isFunction';
import React, { useCallback, useEffect, useState } from 'react';

import CustomizedPagination from '../CustomizedPagination';
import TableEmpty from '../Utilities/TableEmpty';

const StyledTable = ({ dataSource, pagination, serverPagination, onChange, ...props }) => {
    const [formattedPagination, setFormattedPagination] = useState({
        current_page: pagination?.current_page || 1,
        per_page: pagination?.per_page || 50,
    });
    const [filteredDatasource, setFilteredDatasource] = useState([]);

    const handleChange = useCallback(
        params => {
            if (isFunction(onChange)) {
                onChange(params);
            }
        },
        [onChange]
    );

    useEffect(() => {
        const total = dataSource?.length || 0;

        setFormattedPagination({
            ...formattedPagination,
            total,
            page_total: Math.ceil(total / formattedPagination.per_page),
        });

        // eslint-disable-next-line
    }, [dataSource]);

    useEffect(() => {
        const currentPage = formattedPagination?.current_page || 0;
        const perPage = formattedPagination?.per_page || 0;
        const offset = (currentPage - 1) * perPage;

        if (serverPagination) {
            setFilteredDatasource(dataSource);
        } else {
            setFilteredDatasource(dataSource?.slice(offset, offset + perPage));
        }
    }, [dataSource, formattedPagination, serverPagination]);

    useEffect(() => {
        setFormattedPagination({
            ...formattedPagination,
            ...pagination,
        });

        // eslint-disable-next-line
    }, [pagination]);

    return (
        <>
            <Table
                dataSource={filteredDatasource}
                locale={{ emptyText: <TableEmpty loading={props.loading} /> }}
                pagination={false}
                {...props}
            />

            {!!pagination && (
                <CustomizedPagination filters={{}} pagination={formattedPagination} addFilter={handleChange} />
            )}
        </>
    );
};

export default StyledTable;
