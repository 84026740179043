export const EVENTS = {
    IMPORT_PRODUCT_FROM_EXCEL: 'PRODUCT.IMPORT_PRODUCT_FROM_EXCEL',
    RELOAD_PRODUCT_LIST: 'PRODUCT.RELOAD_PRODUCT_LIST',
    CREATE_VARIATIONS_AGAIN: 'PRODUCT.CREATE_VARIATIONS_AGAIN',
    RELOAD_VENDOR_BY_PRODUCT: 'PRODUCT.RELOAD_VENDOR_BY_PRODUCT',
    UPDATE_PRODUCT_SUCCESS: 'PRODUCT.UPDATE_PRODUCT_SUCCESS',
    RESET_PRODUCT_FORM: 'PRODUCT.RESET_PRODUCT_FORM',
    IMPORT_VARIANT_INVENTORY: 'PRODUCT.IMPORT_VARIANT_INVENTORY',
    RELOAD_PRODUCT_SERVICE: 'PRODUCT.RELOAD_PRODUCT_SERVICE',
};
export const PACKAGE_STATUS = {
    WAITING_EXPORT: 'WAITING_EXPORT',
    SHIPPING: 'SHIPPING',
    DELIVERED: 'DELIVERED',
    CANCELED: 'CANCELED',
};

export const TYPE_STAMP_SIZE = [
    { span: 8, item_per_page: 3, label: '104_22mm', width: 120, height: 22, withStamp: 104, heightStamp: 22, widthPx: 393.07, heightPx: 83.15},
    { span: 12, item_per_page: 2, label: '70_22mm', width: 170, height: 22, withStamp: 70, heightStamp: 22, widthPx: 264.57,  heightPx: 83.15},
];

export const LENGTH_PRODUCT_CODE_WARNING = 10;

export const PATTERN_CHECK_HAS_SPECIAL_CHARACTER = /^[A-Za-z0-9\-\.\$\/\+\%]*$/;
export const PATTERN_CHECK_IS_CODE_39 = /^[A-Z0-9\-\.\$\/\+\%]*$/;
export const PRINT_SKU_TYPE = {
    SKU_ID: 'SKU_ID',
    SKU_CODE: 'SKU_CODE',
};
export const PRODUCT_STATUS = {
    NEW: 'NEW',
    WAITING_FOR_QUOTE: 'WAITING_FOR_QUOTE',
    WAITING_CONFIRM: 'WAITING_CONFIRM',
    ON_SELL: 'ON_SELL',
    STOP_SELLING: 'STOP_SELLING',
};

export const PRODUCT_STATUS_COLORS = {
    NEW: '#cc8700',
    WAITING_FOR_QUOTE: '#f5222d',
    WAITING_CONFIRM: '#108ee9',
    ON_SELL: '#87d068',
    STOP_SELLING: '#bdbdbd',
};

export const PRODUCT_LOG_KEYS = {
    PRODUCT_CREATE: 'PRODUCT.CREATE',
    PRODUCT_UPDATE: 'PRODUCT.UPDATE',
    PRODUCT_UPDATE_STATUS: 'PRODUCT.UPDATE_STATUS',
    PRODUCT_SKU_UPDATE_STATUS: 'PRODUCT.SKU_UPDATE_STATUS',
    PRODUCT_SKU_UPDATE_PRICE: 'PRODUCT.SKU_UPDATE_PRICE',
    PRODUCT_SKU_CREATE: 'PRODUCT.SKU_CREATE',
    PRODUCT_SKU_DELETE: 'PRODUCT.SKU_DELETE',
    PRODUCT_SKU_UPDATE_REF: 'PRODUCT.SKU_UPDATE_REF',
    PRODUCT_SKU_UPDATE: "PRODUCT.SKU_UPDATE",
    PRODUCT_PRICE_UPDATE: "PRODUCT.UPDATE_PRODUCT_PRICE_STATUS",
    CONFIRM_WEIGHT_VOLUME_FOR_SKUS: "PRODUCT.CONFIRM_WEIGHT_VOLUME_FOR_SKUS",
    PRODUCT_REMOVE_SERVICE : "PRODUCT.REMOVE_SERVICE"
};

export const SKU_COMBO_LOG_KEYS = {
    SKU_COMBO_CREATE: 'SKU_COMBO.CREATE',
    SKU_COMBO_UPDATE: 'SKU_COMBO.UPDATE',
    SKU_COMBO_UPDATE_SKU_CHILD: 'SKU_COMBO_SKU.UPDATE',

    SKU_COMBO_SKU_ADD_SKU: 'SKU_COMBO.ADD_SKU',
    SKU_COMBO_SKU_REMOVE_SKU: 'SKU_COMBO.REMOVE_SKU',
    SKU_COMBO_SKU_UPDATE: "SKU_COMBO_SKU.UPDATE",
};

export const LOGIC_BATCH = {
    FIFO:"FIFO", 
    LIFO:"LIFO", 
    FEFO:"FEFO"
}