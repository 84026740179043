import { Button, Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';

import InputVnd from '@Modules/App/Utilities/InputVnd';

import useValidatedMessages from '../../../system/hooks/useValidatedMessages';
import { t } from '../../../system/i18n';
import SelectTypeTransaction from './SelectTypeTransaction';

const { TextArea } = Input;
const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
};

const validatedRules = {
    type: [{ required: true }],
    amount: [{ required: true }],
    description: [{ required: true }],
    confirm: [{ required: true }],
};

export default function MerchantWalletForm({ handleSubmit, form, loading, isConfirm, disabled, handleCheckbox }) {
    const validateMessages = useValidatedMessages();

    return (
        <Form
            className={'form'}
            validateMessages={validateMessages}
            size="default"
            form={form}
            labelCol={formItemLayout.labelCol}
            wrapperCol={formItemLayout.wrapperCol}
            colon=":"
        >
            <Form.Item
                className="mb-3 input-modal-type"
                name="type"
                label={t('merchant:label.type_transaction')}
                rules={validatedRules.type}
            >
                <SelectTypeTransaction placeholder={t('merchant:placeholder.type_transaction')} />
            </Form.Item>
            <Form.Item
                className="mb-3 input-modal-amount"
                name="amount"
                label={t('finance:label.amount')}
                rules={validatedRules.amount}
            >
                <InputNumber
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder={t('merchant:placeholder.payment_amount')}
                    className="w-100"
                />
            </Form.Item>
            <Form.Item
                className="mb-3 input-modal-description"
                name="description"
                label={t('merchant:label.content')}
                rules={validatedRules.description}
            >
                <TextArea placeholder={t('merchant:placeholder.content')} style={{ height: 100 }} />
            </Form.Item>
            <Form.Item className="mb-3 input-modal-confirm" wrapperCol={{ offset: 6, span: 18 }}>
                <Checkbox onChange={handleCheckbox} checked={isConfirm} className="checkbox-modal-confirm">
                    {t('common:label.agree_info')}
                </Checkbox>
            </Form.Item>

            <Row className="mt-3">
                <Col span={18} offset={6}>
                    <Button
                        htmlType={'submit'}
                        type="primary"
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={disabled}
                        className="btn-modal-create_transaction"
                    >
                        {t('common:btn.create_transaction')}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}
