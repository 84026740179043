import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";

class ServiceCountries extends BaseServiceData {

    runApi() {
        return api.getLocations({type: "COUNTRY"})
            .then(res => _.get(res, "data.locations", []))
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("order:label.country").toLowerCase()})});
                return Promise.reject(err);
            });
    }
}

export default new ServiceCountries();
