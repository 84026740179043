import { Badge as BaseBadge } from 'antd';
import clsx from 'clsx';
import React from 'react';

const Badge = ({ className, color, count, ...other }) => (
    <BaseBadge
        className={clsx(className, {
            'ant-badge-count--primary': !!(count && color === 'primary')
        })}
        color={count && color ? undefined : color}
        count={count}
        {...other}
    />
);

export default Badge;
