import reduce from 'lodash/reduce';
import values from 'lodash/values';

export const accumulateByKeys = (array, identity, accumulateKey) => values(reduce(array, (result, item) => ({
    ...result,
    [item[identity]]: {
        ...item,
        quantity: ((result[item[identity]] && result[item[identity]][accumulateKey]) || 0) + item[accumulateKey]
    }
}), {}));
