import { PrinterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import PackingSlipStamp from '@Modules/Order/components/PackingSlipStamp';

import { t } from '@System/i18n';
import notification from '@System/notification';

const PackingSlipPrint = ({ orderPackings }) => {
    const packingStampRef = useRef();

    const printStamp = useReactToPrint({
        content: () => packingStampRef.current,

    });

    const handlePrint = () => {
        if (orderPackings.length > 0) {
            setTimeout(() => {
                printStamp();
            }, 1000);
        } else {
            notification.error(t('order:print_shipping_stamp.errors.ids.required'));
        }
    };
    return (
        <>
            <Button className="_print-stamp-btn btn-menu-item" onClick={handlePrint}>
                {t('btn.print_packing_slip')}
            </Button>
            <div className="d-none">
                <PackingSlipStamp ref={packingStampRef} orderPackings={orderPackings} />
            </div>
        </>
    );
};

export default PackingSlipPrint;
