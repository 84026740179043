import React, {useEffect, useState} from "react";
import {Select} from "antd";
import get from "lodash/get";
import ServiceAssignedPurchasingAccount from "@Modules/PurchasingManager/services/ServiceAssignedPurchasingAccount";

const {Option} = Select;

export default function SelectAssignedPurchasingAccount(props) {
    let [statuses, setStatuses] = useState([]);
    let [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        ServiceAssignedPurchasingAccount.list()
            .then(res => {
                setStatuses(res);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, []);

    return (
        <Select
            loading={isLoading}
            {...props}
            value={(statuses.length > 0 && props.value) ? props.value : undefined}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                statuses.length > 0 &&
                statuses.map((opt, index) => {
                    const purchasing_service_name = get(opt, "purchasing_service.name", "");
                    return <Option
                        key={index}
                        value={opt.id}
                    >
                        {opt.username + (purchasing_service_name ? " - " + purchasing_service_name : "")}
                    </Option>
                })
            }
        </Select>
    );
}
