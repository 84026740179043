import lodash from 'lodash';

class Dispatcher {
    /**
     * @param {boolean} debug
     */
    constructor(debug = false) {
        this.listeners = [];
        this.incrementId = 1;
        this.debug = debug;
    }

    /**
     * Register an event listener with the dispatcher
     * Example:
     *      events.listen('event_1', listener)
     *      events.listen(['event_1', 'event_2', ...], listener)
     *      events.listen({
     *          event_1: listener_1,
     *          event_2: listener_2,
     *          ...
     *      })
     *
     * @param {String|Array|Object} events
     * @param {Function} listener
     * @return {Function} Remove listener
     */
    listen(events, listener = null) {
        let listenerIds = [];

        if (lodash.isPlainObject(events)) {
            lodash.forIn(events, (listener, event) => listenerIds.push(this.addListener(event, listener)));
        } else {
            listenerIds.push(this.addListener(events, listener));
        }

        return () => {
            this.listeners = this.listeners.filter(listener => !listenerIds.includes(listener.id));
        };
    }

    /**
     * Add listener
     *
     * @param {String|Array} events
     * @param {Function} listener
     * @return {Number}
     */
    addListener(events, listener) {
        let id = this.incrementId;

        this.listeners.push({
            id,
            listener,
            events: Array.isArray(events) ? events : [events],
        });

        this.incrementId++;

        return id;
    }

    /**
     * Dispatch an event and call the listeners
     *
     * @param {String} event
     * @param {Object} payload
     */
    dispatch(event, payload = {}) {
        this.getListeners(event).forEach(listener => this.findListenerById(listener.id) && listener.listener(event, payload));
        this.debug && console.log('events.dispatch', event, payload);
    }

    /**
     * Get listeners of an event
     *
     * @param {String} event
     * @return {Array}
     */
    getListeners(event) {
        return this.listeners.filter(listener => this.matchEvent(event, listener.events));
    }

    findListenerById(listenerId) {
        return this.listeners.find(listener => listener.id === listenerId);
    }

    /**
     * Determine if an event match given patterns
     *
     * @param {String} event
     * @param {Array} patterns
     * @return {boolean}
     */
    matchEvent(event, patterns) {
        return !!lodash.find(patterns, pattern => {
            if (pattern === event) {
                return true;
            }

            pattern = '^' + lodash.escapeRegExp(pattern).replace('\\*', '.*');

            return event.match(new RegExp(pattern, 'u'));
        });
    }
}

export default Dispatcher;