import React, {useState} from 'react';
import {Button, Form, Input, Modal, notification} from "antd";
import {t} from "../../../../system/i18n";
import lodash from 'lodash';
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import {events} from "../../../../system/events";
import api from "../../services/api";
import {EVENTS} from "../../services/constants";
import {auth} from "../../../Auth/services";
import PERMISSIONS from "../../../Auth/services/permissions";

export default function UpdateCategory(props) {
    const {visible, setVisible, categoryInfo} = props;
    const categoryId = lodash.get(categoryInfo, "id", undefined);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const action = lodash.isEmpty(categoryInfo) ? "create" : "update";
    const validateMessages = useValidatedMessages();

    function onCancel() {
        setVisible(false);
        form.resetFields();
    }

    function onSubmit() {
        form.validateFields()
            .then(values => {
                setLoading(true);
                const result = action === "create" ? api.addCategory(values) : api.updateCategory(categoryId, values);
                result.then(res => {
                    notification.success({message: t("common:message." + action + "_success", {attribute: t("common:label.product_category")})});
                    onCancel();
                    events.dispatch(EVENTS.RELOAD_CATEGORIES, {})
                }).catch(err => {
                    let {response} = err;
                    if (response.status === 400) {
                        notification.error({message: t("common:message." + action + "_failed", {attribute: t("common:label.product_category")})});
                        showErrors(response);
                    } else if (response.status === 403)
                        notification.error({message: t('common:message.403')});
                    else if (response.status === 404)
                        notification.error({message: t('common:message.404')});
                    else
                        notification.error({message: t('common:message.create_failed', {attribute: t("common:label.product_category")})});
                }).finally(() => setLoading(false))
            });
    };

    function showErrors(response) {
        let errors = lodash.get(response, "data.data", {});
        let error_messages = [];
        Object.entries(errors).forEach(entry => {
            const [key, error] = entry;
            for (let prop in error) {
                if (error.hasOwnProperty(prop)) {
                    error_messages.push({
                        name: key,
                        errors: [t(`product:message.${prop.toLowerCase()}`, {
                            attribute: t(`product:label.category_${key}`)
                        })]
                    });
                }
            }

        });

        form.setFields(error_messages);
    }

    return (
        <Modal
            title={t("product:btn." + action + "_category")}
            visible={visible}
            onCancel={onCancel}
            footer={
                auth.can(PERMISSIONS.CONFIG_CATEGORIES_UPDATE) ? [
                    <Button key="back" onClick={onCancel}>{t("common:btn.cancel")}</Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={onSubmit}>
                        {t("common:btn." + action)}
                    </Button>
                ] : false}
            width={600}
        >
            <Form
                form={form}
                initialValues={categoryInfo}
                validateMessages={validateMessages}
                layout="vertical"
            >
                <Form.Item
                    label={t("product:label.category_code")}
                    name={action === "create" ? "code" : ""}
                    rules={action === "create" ? [{required: true}] : undefined}
                >
                    {
                        action === "create" ?
                            <Input placeholder={t("product:placeholder.category_code")}/> :
                            <strong>{categoryInfo.code}</strong>
                    }
                </Form.Item>

                <Form.Item
                    label={t("product:label.category_name")}
                    name="name"
                    rules={[{required: true}]}
                >
                    <Input placeholder={t("product:placeholder.category_name")}/>
                </Form.Item>

                <Form.Item
                    label={t("product:label.note")}
                    name="note"
                >
                    <Input.TextArea
                        placeholder={t("product:placeholder.note")}
                        autoSize={{minRows: 2, maxRows: 6}}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
}