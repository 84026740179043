import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import { t } from '@System/i18n';
import notification from '@System/notification';

import apiService from './../services/api';

const useMarketplacesQuery = (params, settings) =>
    createUseQuery({
        name: ['marketplaces', params],
        query: () => apiService.list(params),
        settings: {
            onError: () => {
                notification.error(t('marketplace:get_list.failed'));
            },
            ...settings,
        },
    });

export default useMarketplacesQuery;
