import {get} from "lodash";
import {notification} from "antd";
import {useQuery} from 'react-query';

import {t} from "../../../system/i18n";
import api from "../services/api";
import processResponseError from "@System/api/processResponseError";

const usePackingDocumentListQuery = (params) =>  {
    return useQuery(['PackingDocumentListQuery', params],
        () => api.getDocumentPackings(params), {
            retry: false,
            onError: (err) => {
                processResponseError(err);
            }
        });
};

export default usePackingDocumentListQuery;
