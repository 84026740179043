import React, {useEffect} from "react";
import FormSearch from "./FormSearch";
import {Table} from "antd";
import useFilter from "../../../App/Hooks/useFilter";
import {t} from "../../../../system/i18n";
import {
    dateFormatter,
    setDocumentTitle,
    setNavigator,
    warehouseAreaStorage,
    warehouseStorage
} from "../../../App/services";
import CustomizedPagination from "../../../App/CustomizedPagination";
import TableEmpty from "../../../App/Utilities/TableEmpty";
import {Link} from "react-router-dom";
import {url} from "../../../../system/routing";
import useImportHistoriesQuery from '@Modules/Product/Hooks/useImportHistoriesQuery';

export default function HistoryImport(props) {
    const {history, location} = props;
    const {filters, addFilter} = useFilter(history, location, {
        warehouse_id: warehouseStorage.value || "",
        warehouse_area_id: warehouseAreaStorage.value || ""
    });
    const { isLoading: loading, data } = useImportHistoriesQuery(filters);

    useEffect(() => {
        setNavigator(t("common:title.import-history"), [
            {
                name: t("common:breadcrumb.products"),
                route: "products.list"
            },
            {
                name: t("common:breadcrumb.import-history")
            }
        ]);
        setDocumentTitle(t("title.import-history"));
    }, []);

    const columns = [
        {
            title: t("product:label.import_code"),
            dataIndex: 'import_code',
            key: 'import_code',
            render: (text, record) => {
                const {importHistory} = record;
                return <strong style={{fontWeight: "500"}}>
                    <Link to={url.to("import-history-detail", {id: importHistory.id})}>{importHistory.code}</Link>
                </strong>;
            }
        },
        {
            title: t("product:label.product_quantity"),
            dataIndex: 'product_quantity',
            key: 'product_quantity',
            render: (text, record) => {
                const {importHistory} = record;
                return <p>{importHistory.stock}</p>;
            }
        },
        {
            title: t("product:label.creator"),
            dataIndex: 'creator',
            key: 'creator',
            render: (text, record) => {
                const {creator} = record;
                return <p>{creator.name || creator.username || creator.email}</p>;
            }
        },
        {
            title: t("product:label.creator_at"),
            dataIndex: 'creator_at',
            key: 'creator_at',
            render: (text, record) => {
                const {importHistory} = record;
                return <p>{dateFormatter.formatDate(importHistory.created_at, "DD/MM/YYYY HH:mm")}</p>;
            }
        },
    ];
    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{t("common:heading.import-history")}</h2>
                </div>
                <div>
                    <FormSearch
                        onSearch={addFilter}
                        filters={filters}
                        initFilters={{}}
                        loading={loading}
                    />
                    <div className="m-3">
                        <Table
                            rowKey={record => record.importHistory.id}
                            dataSource={data?.data?.importHistories}
                            columns={columns}
                            pagination={false}
                            loading={loading}
                            locale={{ emptyText: <TableEmpty loading={loading} /> }}
                        />
                        <CustomizedPagination
                            pagination={data?.data?.pagination}
                            addFilter={addFilter}
                            filters={filters}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
