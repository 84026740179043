import createUseQuery from "@Modules/App/Hooks/createUseQuery";
import apiService from "@Modules/Document/services/api";

const useInventoryDocumentsQuery = (params, settings) =>
    createUseQuery({
        name: ['inventory-documents', params],
        query: () => apiService.getInventoryDocuments(params),
        settings,
    });

export default useInventoryDocumentsQuery;