import { Divider, Spin } from 'antd'
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint'
import { get, map } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useCallback } from 'react'

import { t } from '@System/i18n'
import { classes } from '@System/support/helpers'

import SetOrderFlow from '../components/SetOrderFlow'
import { ISettings } from '../interfaces'
import api from '../services/api'
import CompulsorySizePurchasePackage from '../components/CompulsorySizePurchasePackage'
import HiddenPost from '../components/HiddenPost'

const Configuration: React.FC = () => {
    const { xs, sm, xl } = useBreakpoint()
    const [loading, setLoading] = useState(false)
    const [setting, setSetting] = useState<ISettings>({} as ISettings)

    const getSettings = useCallback(() => {
        setLoading(true)
        api.getLogicBillLading()
            .then(res => {
                setSetting(get(res, 'data', {}))
            })
            .catch(res => {})
            .finally(() => {
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        getSettings()
    }, [getSettings])

    const configs = [
        {
            label: t('order:label.order_auto_generate_bill_lading'),
            node: (
                <SetOrderFlow
                    setting={setting}
                    getSettings={getSettings}
                />
            ),
        },
        {
            label: t('config:label.import_by_purchase_package_compulsory_size'),
            node: (
                <CompulsorySizePurchasePackage
                    setting={setting}
                    getSettings={getSettings}
                />
            ),
        },
        {
            label: t('order:label.hiding_pos'),
            node: (
                <HiddenPost
                    setting={setting}
                    getSettings={getSettings}
                />
            ),
        },
    ]

    return (
        <div className="w-100">
            <div
                className={classes('bg-white rounded-lg p-4 h-100', {
                    'ml-3': sm,
                    'mt-3': xs,
                    'w-75': xl,
                })}
            >
                <Spin spinning={loading}>
                    <h3>{t('config')}</h3>
                    <Divider />
                    <ul>
                        {map(configs, (item, index) => (
                            <li
                                className="d-flex align-items-center justify-content-between"
                                key={index}
                            >
                                <span className="mr-3">{item.label}</span>
                                {item.node}
                            </li>
                        ))}
                    </ul>
                </Spin>
            </div>
        </div>
    )
}

export default Configuration
