import { Button } from 'antd';
import clsx from 'clsx';
import filter from 'lodash/filter';
import React, { useCallback, useEffect, useState } from 'react';

import Box from '@Modules/App/Box';
import useSize from '@Modules/App/Hooks/useSize';

import { t } from '@System/i18n';

import { isUncheckedBarcode } from '../../services/helper';
import BarcodesTable from './BarcodesTable';

import styles from './barcode-list.module.scss';

const BarcodeList = ({ barcodes, inventoryDocument, canUpdate, onSuccessImportSkus, onUpdateSkuInventoryDocument }) => {
    const [type, setType] = useState('all');
    const [filteredBarcodes, setFilteredBarcodes] = useState([]);
    const { isLarge } = useSize();
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: isLarge ? 250 : 10,
    });

    const filterBarcodes = useCallback((type, barcodes) => {
        let filteredBarcodes = barcodes;

        if (type === 'unchecked') {
            filteredBarcodes = getUncheckedBarcodes(barcodes);
        }

        if (type === 'excess') {
            filteredBarcodes = getExcessBarcodes(barcodes);
        }

        if (type === 'lack') {
            filteredBarcodes = getLackBarcodes(barcodes);
        }

        setFilteredBarcodes(filteredBarcodes);
        setPagination({
            ...pagination,
            current_page: 1,
        });

        // eslint-disable-next-line
    }, [pagination]);

    const getUncheckedBarcodes = barcodes => {
        return filter(barcodes, isUncheckedBarcode);
    };

    const getExcessBarcodes = barcodes => {
        return filter(
            barcodes,
            barcode => !isUncheckedBarcode(barcode) && barcode?.quantity_checked - barcode?.quantity_in_stock_before_balanced > 0
        );
    };

    const getLackBarcodes = barcodes => {
        return filter(
            barcodes,
            barcode => !isUncheckedBarcode(barcode) && barcode?.quantity_checked - barcode?.quantity_in_stock_before_balanced < 0
        );
    };

    useEffect(() => {
        filterBarcodes(type, barcodes);

        // eslint-disable-next-line
    }, [barcodes, type]);

    return (
        <Box className="inventory-document-barcode-list">
            <div className={clsx(styles['barcode-filter'], 'mb-4')}>
                <Button
                    className="_all-type-btn"
                    type={type === 'all' ? 'primary' : undefined}
                    onClick={setType.bind(this, 'all')}
                >
                    {t('all')}
                </Button>
                <Button
                    className="_unchecked-type-btn"
                    type={type === 'unchecked' ? 'primary' : undefined}
                    onClick={setType.bind(this, 'unchecked')}
                >
                    {t('document:unchecked')}
                </Button>
                <Button
                    className="_excess-type-btn"
                    type={type === 'excess' ? 'primary' : undefined}
                    onClick={setType.bind(this, 'excess')}
                >
                    {t('excess')}
                </Button>
                <Button
                    className="_lack-type-btn"
                    type={type === 'lack' ? 'primary' : undefined}
                    onClick={setType.bind(this, 'lack')}
                >
                    {t('lack')}
                </Button>
            </div>

            <BarcodesTable
                barcodes={filteredBarcodes}
                canUpdate={canUpdate}
                inventoryDocument={inventoryDocument}
                pagination={pagination}
                onSuccessImportSkus={onSuccessImportSkus}
                onUpdateSkuInventoryDocument={onUpdateSkuInventoryDocument}
                setPagination={setPagination}
            />
        </Box>
    );
};

export default BarcodeList;
