import { RedoOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';

import SelectMerchants from '@Modules/Merchant/components/SelectMerchants';
import StatusesSelect from '@Modules/PurchasingAccount/components/StatusesSelect';

import { t } from '@System/i18n';

const { Title } = Typography;

const Header = ({ className, form, loading, onClear, onSubmit }) => (
    <Card className={clsx('rounded-12', className)}>
        <Title className="font-weight-bold text-fz-16 mb-4" level={3}>
            {t('search_account')}
        </Title>

        <Form className="_search-form" form={form} layout="vertical" onFinish={onSubmit}>
            <div className="d-flex flex-column flex-lg-row align-items-lg-end justify-content-lg-between">
                <Row className="flex-grow-1 row-gap-16 mb-3 mb-lg-0" gutter={16}>
                    <Col lg={{ span: 6 }}>
                        <Form.Item className="mb-0" label={t('username')} name="username">
                            <Input className="_search-form-username" placeholder={t('input_username')} />
                        </Form.Item>
                    </Col>
                    <Col lg={{ span: 6 }}>
                        <Form.Item className="mb-0" label={t('vendor_info')} name="merchant_id">
                            <SelectMerchants
                                className="_vendor"
                                dropdownClassName="_vendor-dropdown"
                                placeholder={t('input_vendor_info')}
                            />
                        </Form.Item>
                    </Col>

                    <Col lg={{ span: 6 }}>
                        <Form.Item className="mb-0" label={t('status')} name="status">
                            <StatusesSelect
                                className="_status"
                                dropdownClassName="_status-dropdown"
                                placeholder={t('select_status')}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <div className="d-flex flex-wrap gap-16">
                    <Button loading={loading} onClick={onClear}>
                        <RedoOutlined />
                        {t('btn.set_again')}
                    </Button>

                    <Button htmlType="submit" loading={loading} type="primary">
                        <SearchOutlined />
                        {t('btn.search')}
                    </Button>
                </div>
            </div>
        </Form>
    </Card>
);

export default Header;
