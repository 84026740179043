import { Modal, Typography } from 'antd';
import React, { useMemo } from 'react';

import StyledTable from '@Modules/App/Table/StyledTable';
import useOrderPackingsQuery from '@Modules/Sku/Hooks/useOrderPackingsQuery';

import { t } from '@System/i18n';
import { Link } from '@System/routing';

const { Paragraph } = Typography;

const OrderPackingsModal = ({ sku, visible, onCancel }) => {
    const { data, isLoading } = useOrderPackingsQuery(sku?.id);
    const columns = useMemo(
        () => [
            {
                dataIndex: ['order_packing', 'order', 'code'],
                title: t('order'),
                render: (text, record) => <Link isNewTab params={{ id: record?.order?.id }} to="orders.detail">{text}</Link>,
            },
            {
                dataIndex: ['order_packing', 'total_quantity'],
                title: t('sku_quantity'),
            },
        ],
        []
    );

    return (
        <Modal
            cancelText={t('btn.close')}
            okButtonProps={{ className: 'd-none' }}
            title={t('order_packing_list')}
            visible={visible}
            onCancel={onCancel}
        >
            <Paragraph>
                {t('inventory:order_packings_modal.count', {
                    count: data?.data?.length ?? 0,
                    sku: sku?.code,
                })}
            </Paragraph>

            <StyledTable
                columns={columns}
                dataSource={data?.data ?? []}
                loading={isLoading}
                pagination={false}
                rowKey={record => record?.order_packing?.id}
            />
        </Modal>
    );
};

export default OrderPackingsModal;
