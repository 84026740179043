import React, {useState} from "react";
import {Button, notification} from "antd";
import {t} from "@System/i18n";
import {DownloadOutlined} from "@ant-design/icons";
import api from "../../services/api"
import _ from "lodash";
import { convertBlobDataToExcel } from "@System/support/helpers";

export default function ExportExcel({filters}) {
    const [loading, setLoading] = useState(false);

    function onDownloadExcel() {
        setLoading(true);
        api.exportOrder(filters)
            .then(response => {
                convertBlobDataToExcel(response.data, 'order-list');
                notification.success({message: t("order:export_excel.success")})
            })
            .catch((err) => {
                const status = _.get(err, "response.status", null);
                if (status === 403)
                    notification.error({message: t('common:message.403')});
                else if (status === 404)
                    notification.error({message: t('common:message.404')});
                else
                    notification.error({message: t('common:message.fetch_fail')});
            }).finally(() => {
            setLoading(false)
        });
    }

    return (
        <Button
            className="_order-export-excel ml-2"
            loading={loading}
            type="primary"
            onClick={onDownloadExcel}
        >
            <DownloadOutlined/> {t("common:btn.export_excel")}
        </Button>
    );
}
