import React, {useState} from "react";
import { CloudUploadOutlined } from '@ant-design/icons';
import {Button, Modal, notification} from 'antd';
import UploadFile from "./UploadFile";
import {t} from "../../../system/i18n";
import _ from "lodash";
import {catchErrors} from "../services/helpers";
import i18next from "i18next";

export default function ImportExcel({modal, file, renderError, messages, setMessages, apiService, dispatch}) {
    const [visible, setVisible] = useState(false);

    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setMessages([]);
        setVisible(false);
    }

    function handleCancel() {
        setMessages([]);
        setVisible(false);
    }

    function handleUpload(file) {
        setMessages([]);
        const filename = _.get(file, 'name', '');
        let formData = new FormData();
        formData.append('file', file);
        apiService(formData)
            .then((res) => {
                let errors = _.get(res, 'data.errors', []);
                if (errors.length === 0) {
                    notification.success({message: t('synchronousProduct:message.success', {filename})});
                    setVisible(false);
                } else {
                    setMessages(errors);
                    // notification.error({message: t('synchronousProduct:message.fail', {filename})});
                }

                if (typeof dispatch === 'function')
                    dispatch();
            })
            .catch(catchErrors(t('common:message.server_error')));
    }

    return (
        <>
            <Button type='primary' className='ml-2' onClick={showModal}>
                <CloudUploadOutlined /> {modal.buttonName || ""}
            </Button>
            <Modal
                title={(modal.title || "").toUpperCase()}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                maskClosable={false}
            >
                <p>
                    - {t('label.latest_at_update_sample')}:{' '}
                    <strong>{file.updatedAt || ""}</strong>
                </p>
                <p>
                    - {t('label.download_sample_file')}:
                    <strong className="ml-1">
                        <a href={process.env.PUBLIC_URL + `/template/${i18next.language}/${file.name || ""}.xlsx`}>{file.name || ""}.xlsx</a>
                    </strong>
                </p>
                <p className='mb-3'>
                    - {t('label.maximum_file_size')}: <strong>5MB</strong>
                </p>
                <UploadFile
                    onChange={handleUpload}
                    accept='.xls,.xlsx'
                    fileType={[
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.ms-excel',
                    ]}
                    name='file import đơn hàng'
                    maxFileSize={5}
                    showUploadList={true}
                />
                {
                    messages.length > 0 &&
                    <div className='pt-3'>
                        {messages.map((item, index) => {
                            return (
                                <p key={index} className='text-danger'>
                                    - {renderError(item)}
                                </p>
                            );
                        })}
                    </div>
                }
            </Modal>
        </>
    );
}
