import React, {useState} from "react";
import {Button, Modal, Input, notification, Select, Form} from "antd";
import {t} from "../../../../../system/i18n";
import api from "../../../services/api";
import {events} from "../../../../../system/events";
import {EVENTS} from "../../../services/constants";
import _ from "lodash";

const {Option} = Select;
/**
 * @return {null}
 * @return {null}
 */

export default function CancelOrder({orderDetail}) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [otherReason, setOtherReason] = useState(false);
    const {canCancel, order, cancel_reasons} = orderDetail || {};
    const [form] = Form.useForm();

    function showModal() {
        setIsModalVisible(true);
    }

    function handleOk() {
        form.validateFields().then(values => {
            api.cancelOrder(order.id, values)
                .then(res => {
                    events.dispatch(EVENTS.FETCH_ORDER_DETAIL_AGAIN, {});
                    handleCancel();
                    notification.success({message: t("order:message.cancel_order_success")})
                })
                .catch(err => {
                    const status = _.get(err, "response.status", null);
                    if (status === 400) {
                        // setErrors(_.get(err, "response.data.data", {}));
                        notification.error({message: t("order:message.cancel_order_fail")})
                    } else if (status === 403)
                        notification.error({message: t('common:message.403')});
                    else if (status === 404)
                        notification.error({message: t('common:message.404')});
                    else
                        notification.error({message: t("order:message.cancel_order_fail")})
                });
        });
    }

    function handleCancel() {
        setIsModalVisible(false);
        setOtherReason(false);
    }

    function onValuesChange(changedValues) {
        if (changedValues["cancel_reason"]) {
            (changedValues["cancel_reason"] === "OTHER") ? setOtherReason(true) : setOtherReason(false)
        }
    }

    if (!canCancel)
        return null;

    return (
        <>
            <Button
                danger={true}
                onClick={showModal}
            >
                {t("order:btn.cancel_order")}
            </Button>
            <Modal
                title={t("order:label.cancel_order").toUpperCase()}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={t("btn.confirm")}
                cancelText={t("btn.cancel")}
            >
                <Form layout="vertical" form={form} onValuesChange={onValuesChange}>
                    <Form.Item
                        label={t("order:label.reason_of_canceling_order")}
                        name="cancel_reason"
                        rules={[{
                            required: true,
                            message: t("validation:required", {attribute: t("order:label.reason_of_canceling_order")})
                        }]}
                        className="_cancel_order-reason_of_canceling_order"
                    >
                        <Select style={{width: "100%"}} placeholder={t("order:placeholder.reason_of_canceling_order")}>
                            {
                                cancel_reasons.map((item, index) => {
                                    return <Option value={item} key={index}>{t(`order:cancel_reason.${item}`)}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {
                        otherReason &&
                        <Form.Item
                            name="cancel_note"
                            rules={[{required: true, message: ""}]}
                            className="_cancel_order-reason_other_cancel_order"
                        >
                            <Input.TextArea
                                style={{height: "100px"}}
                                placeholder={t("order:placeholder.reason_other_cancel_order")}
                            />
                        </Form.Item>
                    }

                </Form>
            </Modal>
        </>
    );
}
