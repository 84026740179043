import React, {useState} from "react";
import {SearchOutlined, LoadingOutlined} from "@ant-design/icons";
import {Input, AutoComplete, notification, Form, Modal} from "antd";
import _ from "lodash";
import api from "../../../../Product/services/api";
import {t} from "../../../../../system/i18n";
import {updateCollectionItem} from "../../../../App/services/helpers";
import processResponseError from "@System/api/processResponseError";

export default function AddProduct({orderSkus, setOrderSkus, form, currentSeller}) {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState(false);

    const _onSearch = _.debounce((value) => {
        setOptions([]);

        if (value !== "" && value !== null && value !== undefined && currentSeller?.id) {
            setLoading(true);
            api.searchSkus({keyword: value, merchant_id: currentSeller.id})
                .then(res => {
                    setOptions(_.get(res, "data.skus", []));
                })
                .catch((error) => {
                   processResponseError(error);
                })
                .finally(() => {
                    setLoading(false)
                });
        }
    }, 300);

    const getOptions = (option) => {
        let {sku} = option;
        return {label: sku.name, value: sku.id, sku_id: sku.id};
    }

    function _onChange(value) {
        setKeyword(value);
    }

    function _onSelect(value, option) {
        addSku(option.sku_id);
        setOptions([]);
        setKeyword("");
    }

    function addSku(sku_id) {
        let currentOrder = orderSkus.find(item => item.sku_id === sku_id);
        if (currentOrder) {
            // Nếu sku đã có trong danh sách thì thêm số lượng
            let {quantity} = currentOrder;
            setOrderSkus(updateCollectionItem(orderSkus, sku_id, {...currentOrder, quantity: quantity+1}, "sku_id"));
        } else {
            // Nếu chưa có thì thêm vào danh sách (Lưu cả danh sách giá sp theo từng seller)
            let newSku = options.find(item => item.sku.id === sku_id);
            if (newSku) {
                let sku = _.get(newSku, "sku", {});
                let unit = _.get(newSku, "unit.name", "");
                let prices = _.get(newSku, "prices", []);
                let price = "";
                let merchant_id = form.getFieldValue("merchant_id");
                if (merchant_id && prices.length > 0) {
                    let priceByMerchant = prices.find(item => item.merchant_id === merchant_id);
                    price = priceByMerchant ? priceByMerchant.retail_price : "";
                }
                setOrderSkus([...orderSkus, {sku_id, code: sku.code, name: sku.name, unit, price, quantity: 1, tax: sku.tax, discount_amount: 0, prices}]);
            }
        }
    }

    function checkVender() {
        if (currentSeller === null) {
            Modal.error({
                title: t("order:message.not_vendor"),
                content: t("order:message.chose_vendor")
            });
        }
    }

    return (
        <>
            <AutoComplete
                options={options.map(getOptions)}
                style={{width: "100%"}}
                onSearch={_onSearch}
                onSelect={_onSelect}
                onChange={_onChange}
                value={keyword}
                className="add-product-auto-complete"
            >
                <Input
                    size={"large"}
                    placeholder={t("product:placeholder.search_product_by")}
                    prefix={loading ? <LoadingOutlined style={{color: 'rgba(0,0,0,.45)'}} /> : <SearchOutlined style={{color: 'rgba(0,0,0,.45)'}} />}
                    allowClear={true}
                    onClick={checkVender}
                    className="add-product-input"
                />
            </AutoComplete>
        </>
    );
}
