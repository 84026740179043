import { Select } from 'antd';
import React from 'react';

import { t } from '@System/i18n';

import useMerchantsQuery from '../Hooks/useMerchantsQuery';

const { Option } = Select;

export default function SelectMerchants({ loading, value, ...props }) {
    const { data, isLoading } = useMerchantsQuery();
    const merchants = data?.data?.merchants || [];

    return (
        <Select
            {...props}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            loading={isLoading || loading}
            optionFilterProp="label"
            showSearch
            value={merchants.length > 0 && (value === undefined || value === null) ? undefined : value}
        >
            {props?.alloption && <Option value={0}>{t('label.all')}</Option>}
            {merchants.map(merchant => {
                return (
                    <Option key={merchant?.merchant?.id} value={merchant?.merchant?.id}>
                        {`${merchant?.merchant?.code}${merchant?.merchant?.name ? ` - ${merchant?.merchant?.name}` : ''}`}
                    </Option>
                );
            })}
        </Select>
    );
}
