import React, {useState} from "react";
import {Input, notification} from "antd";
import {EditOutlined} from "@ant-design/icons";
import api from "../../services/api";
import _ from "lodash";
import {catchErrors, updateCollectionItem} from "../../../App/services/helpers";
import {t} from "../../../../system/i18n";

export default function UpdateFobizSku({storeSku, storeSkus, setStoreSkus, canUpdate}) {
    const [edit, setEdit] = useState(false);

    function _onClick() {
        setEdit(true);
    }

    function _onBlur() {
        setEdit(false);
    }

    function _onPressEnter(e) {
        let fobizSku = _.get(e, "target.value").trim();
        if (!_.isEmpty(fobizSku)) {
            api.updateSkuFobiz(storeSku.store_sku_id, {code: fobizSku})
                .then(res => {
                    let newStoreSku = _.get(res, "data.storeSku", {});
                    if (!_.isEmpty(newStoreSku)) {
                        setStoreSkus(updateCollectionItem(storeSkus, newStoreSku.id, {store_sku_code: newStoreSku.code}, "store_sku_id"));
                    }
                    setEdit(false);
                    notification.success({message: t('message.update_success_by_attribute', {attribute: t('synchronousProduct:label.fobiz_code')})});
                })
                .catch(catchErrors(false, showErrors));
        }
    }

    function showErrors(data) {
        let messages = [];
        Object.keys(data).forEach(field => {
            messages.push(t(`errorMessages.${data[field]}`, {attribute: t(`synchronousProduct:label.${field}`)}));
        });

        notification.error({message: messages.join(" ")});
    }

    return (
        !edit ?
            <>
                {storeSku.store_sku_code}
                {
                    canUpdate ? <span className="ml-1 cursor-pointer text-primary"
                                      onClick={_onClick}><EditOutlined/></span> : ""
                }
            </> :
            <Input
                defaultValue={storeSku.store_sku_code}
                onPressEnter={_onPressEnter}
                onBlur={_onBlur}
            />
    );
}
