import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';
import React from 'react';

import WarehouseStocksTable from '@Modules/Inventory/components/WarehouseStocksTable';

import { t } from '@System/i18n';

const { Title } = Typography;

const Body = ({ loading, pagination, tableSelectionData, warehouseStocks, onChangePage }) => {
    return (
        <Card className="rounded-12">
            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mb-4">
                <Title className="font-weight-bold text-fz-16 mb-0" level={3}>
                    {t('inventory:out_of_stock_product_list')}
                </Title>

                <Button className="_add-btn" type="primary">
                    <PlusOutlined />
                    {t('inventory:receive_more_goods')}
                </Button>
            </div>

            <WarehouseStocksTable
                loading={loading}
                pagination={pagination}
                tableSelectionData={tableSelectionData}
                warehouseStocks={warehouseStocks}
                onChange={onChangePage}
            />
        </Card>
    );
};

export default Body;
