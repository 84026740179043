import React, {useState, useEffect} from 'react';
import {Form, Row, Col, Button, Input, DatePicker} from 'antd';
import {SearchOutlined, UndoOutlined} from "@ant-design/icons";
import SelectWarehouse from "../../../Warehouse/components/SelectWarehouse";
import SelectAccounts from "../../../Account/components/SelectAccounts";
import moment from "moment";
import {t} from '../../../../system/i18n';
import {warehouseStorage} from "../../../App/services";

const {RangePicker} = DatePicker;
const dateFormat = 'DD/MM/YYYY';

function FormSearch({onSearch, filters, initFilters}) {
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const [reset, setReset] = useState(0);
    const {
        id,
        warehouse_id,
        created_at_from,
        created_at_to,
        creator_id
    } = input || {};

    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({...input, [name]: value});
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function _onSubmit() {
        onSearch(input);
    }

    function _onReset() {
        setReset(reset+1);
        warehouseStorage.reset();
        onSearch(initFilters);
    }

    function handleChangeDate(dates) {
        setInput({
            ...input,
            created_at_from: dates ? dates[0].format("YYYY-MM-DD") : "",
            created_at_to: dates ? dates[1].format("YYYY-MM-DD") : ""
        });
    }

    return (
        <Form
            className="pl-4 pr-4 pt-3 pb-2 border-bottom bg-light-gray"
            form={form}
            layout="vertical"
        >
            <Row gutter={15}>
                <Col xs={{span: 19}} lg={{span: 19}}>
                    <Row gutter={15}>
                        <Col xs={{span: 12}} lg={{span: 5}}>
                            <Form.Item
                                className="mb-3"
                                label={t("manageExporting:label.id")}
                            >
                                <Input
                                    name="id"
                                    value={id}
                                    placeholder={t("manageExporting:placeholder.id")}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                    allowClear={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{span: 12}} lg={{span: 5}}>
                            <Form.Item
                                className="mb-3"
                                label={t("manageExporting:label.warehouse_id")}
                            >
                                <SelectWarehouse
                                    placeholder={t("manageExporting:placeholder.warehouse_id")}
                                    allowClear={true}
                                    value={warehouse_id ? parseInt(warehouse_id) : undefined}
                                    onChange={value => _onChange("warehouse_id", value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{span: 12}} lg={{span: 5}}>
                            <Form.Item
                                className="mb-3"
                                label={t("manageExporting:label.creator")}
                            >
                                <SelectAccounts
                                    placeholder={t("manageExporting:placeholder.creator")}
                                    allowClear={true}
                                    value={creator_id ? parseInt(creator_id) : undefined}
                                    onChange={value => _onChange("creator_id", value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{span: 12}} lg={{span: 9}}>
                            <Form.Item
                                className="mb-3"
                                label={t("manageExporting:label.created_at")}
                            >
                                <RangePicker
                                    value={(created_at_from && created_at_to) ? [moment(created_at_from), moment(created_at_to)] : undefined}
                                    format={dateFormat}
                                    placeholder={[t("common:label.time_start"), t("common:label.time_end")]}
                                    onChange={handleChangeDate}
                                    style={{width: "100%"}}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <Col xs={{span: 5}} lg={{span: 5}} className="text-right">
                    <Row gutter={15} className="mt-4 pt-1">
                        <Col xs={{span: 12}}>
                            <Button type="primary" onClick={_onSubmit} style={{width: "100%"}}><SearchOutlined/>{t("btn.search")}</Button>
                        </Col>
                        <Col xs={{span: 12}}>
                            <Button onClick={_onReset} style={{width: "100%"}}><UndoOutlined/> {t("common:btn.reset")}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
}

export default FormSearch;
