import common from './common.json';
import validation from './validation.json';
import auth from './auth.json';
import product from './product.json';
import listProducts from './listProducts';
import order from './order';
import sales from './sales';
import merchant from './merchant';
import orderDetail from './orderDetail';
import packaging from './packaging';
import payment from './payment';
import warehouse from './warehouse';
import warehouseArea from './warehouseArea';
import user from './user';
import manageExporting from './manageExporting';
import synchronousProduct from './synchronousProduct';
import document from './document'
import sku from './sku';
import purchasingAccount from './purchasingAccount';
import connectedAccount from './connectedAccount';
import marketplace from './marketplace';
import store from './store';
import purchasingOrder from "./purchasingOrder";
import inventory from './inventory';
import finance from './finance';
import packing from './packing';
import serviceManager from './serviceManager.json';
import config from './config.json';

export default {
    common,
    validation,
    auth,
    product,
    listProducts,
    order,
    sales,
    merchant,
    orderDetail,
    packaging,
    payment,
    warehouse,
    warehouseArea,
    user,
    manageExporting,
    synchronousProduct,
    document,
    sku,
    purchasingAccount,
    connectedAccount,
    marketplace,
    store,
    purchasingOrder,
    inventory,
    finance,
    packing,
    serviceManager,
    config
};
