import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";
import {events} from "../../../system/events";
import {EVENTS} from "./constants";

class ServiceMerchant extends BaseServiceData {

    constructor() {
        super();
        events.listen(EVENTS.FETCH_MERCHANT_AGAIN, () => this.fetchData());
    }

    runApi() {
        return api.getMerchants()
            .then(res => {
                let tmpMerchants = _.get(res, "data.merchants", []);
                return tmpMerchants.map(item => {
                    let merchant = _.get(item, "merchant", {});
                    let location = _.get(item, "location", {});
                    let currency = _.get(item, "currency", {});
                    return {...merchant, location, currency};
                });
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("merchant:label.info").toLowerCase()})});
                return Promise.reject(err);
            });
    }
}

export default new ServiceMerchant();
