import { Spin, Input, Form } from 'antd';
import isEmpty from 'lodash/isEmpty';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useState } from 'react';

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput';

import { t } from '@System/i18n';
import { formatCurrency } from '@System/support/numberFormat';

function SummaryDocumentDetail({ form, data, loading, currency, canUpdate, handleUpdate }) {
    const [otherFee, setOtherFee] = useState(0);
    useEffect(() => {
        const otherFee = sumBy(data, 'other_fee');
        setOtherFee(otherFee || 0);
        form.setFields([{ name: 'other_fee', value: otherFee || 0, errors: null }]);
    }, [data]);
    const cod_total_amount = sumBy(data, 'cod_total_amount');
    const cod_paid_amount = sumBy(data, 'cod_paid_amount');
    const shipping_amount = sumBy(data, 'shipping_amount');
    const cod_fee_amount = sumBy(data, 'cod_fee_amount');
    const total_amount = sumBy(data, 'total_amount');
    const sum_total_amount = Number(total_amount) - Number(otherFee);

    function handleChange(value) {
        setOtherFee(value);
        form.setFields([{ name: 'other_fee', value, errors: null }]);
        handleUpdate();
    }

    return (
        <div className="bg-white rounded-12 p-4 mt-3 mt-lg-0">
            <Spin spinning={loading}>
                <div className="mb-2">
                    <span className="text-color-label">{t('finance:finance_control.label.sum_cod_total_amount')}</span>
                    <p>
                        <strong>{isEmpty(currency) ? cod_total_amount : formatCurrency(cod_total_amount || 0, currency)}</strong>
                    </p>
                </div>

                <div className="mb-2">
                    <span className="text-color-label">{t('finance:finance_control.label.sum_cod_paid_amount')}</span>
                    <p>
                        <strong>{isEmpty(currency) ? cod_paid_amount : formatCurrency(cod_paid_amount || 0, currency)}</strong>
                    </p>
                </div>

                <div className="mb-2">
                    <span className="text-color-label">{t('finance:finance_control.label.total_shipping_amount')}</span>
                    <p>
                        <strong>{isEmpty(currency) ? shipping_amount : formatCurrency(shipping_amount || 0, currency)}</strong>
                    </p>
                </div>

                <div className="mb-2">
                    <span className="text-color-label">{t('finance:finance_control.label.total_cod_fee_amount')}</span>
                    <p>
                        <strong>{isEmpty(currency) ? cod_fee_amount : formatCurrency(cod_fee_amount || 0, currency)}</strong>
                    </p>
                </div>

                <div className="mb-2">
                    <span className="text-color-label">{t('finance:finance_control.label.other_fee')}</span>
                    {canUpdate ? (
                        <Form.Item className="mb-0" name="other_fee">
                            <CurrencyInput
                                value={otherFee}
                                name="other_fee"
                                style={{ width: '100%' }}
                                onPressEnter={value => handleChange(value || 0)}
                                onBlur={value => handleChange(value || 0)}
                            />
                        </Form.Item>
                    ) : (
                        <p>{isEmpty(currency) ? otherFee : formatCurrency(otherFee || 0, currency)}</p>
                    )}
                </div>

                <div className="mb-2">
                    <span className="text-color-label">
                        <strong>{t('finance:finance_control.label.sum_total_amount')}</strong>
                    </span>
                    <p>
                        <strong>{isEmpty(currency) ? sum_total_amount : formatCurrency(sum_total_amount || 0, currency)}</strong>
                    </p>
                </div>
            </Spin>
        </div>
    );
}

export default SummaryDocumentDetail;
