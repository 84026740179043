
import React, { useCallback, useEffect, useState } from 'react';

import { setDocumentTitle, setNavigator } from '@Modules/App/services';

import { t } from '@System/i18n';

import FormSearch from './FormSearch';
import List from './List';
import ServiceWarehouseAreas from '@Modules/WarehouseArea/services/ServiceWarehouseAreas';
const MoveProducts = () => {
    const [input, setInput] = useState({});
    const [warehouseAreaList, setWarehouseAreaList] = useState([]);
    const [childrenReset, setChildrenReset] = useState('')

    const { warehouse_id, warehouse_area_id } = input || {};


    useEffect(() => {
        setNavigator(t('common:menu.move_products'), [
            {
                route: 'move_products',
                name: t('common:breadcrumb.move_products'),
            },
        ]);
        setDocumentTitle(t('menu.move_products'));
    }, []);


    const _onChange = useCallback(
        (name, value) => {
            let inputTemp;

            switch (name) {
                case 'warehouse_id':
                    inputTemp = { ...input, [name]: value, warehouse_area_id: undefined };
                    break;
                default:
                    inputTemp = { ...input, [name]: value };
                    break;
            }

            setInput(inputTemp);
        },
        [input]
    );

    useEffect(() => {
        if (!!warehouse_id) {
            ServiceWarehouseAreas.list(warehouse_id)
                .then(res => {
                    setWarehouseAreaList(res);
                })
        }

    }, [warehouse_id]);



    return (
        <div className="site-content mh-100">
            <FormSearch warehouse_id={warehouse_id} warehouse_area_id={warehouse_area_id} _onChange={_onChange} warehouseAreaList={warehouseAreaList} setChildrenReset={setChildrenReset} setInput={setInput} />
            <List input={input} warehouseAreaList={warehouseAreaList} childrenReset={childrenReset} />
        </div>
    );
};

export default MoveProducts;
