import React from "react";
import {get, isEmpty, find} from "lodash";
import moment from "moment";
import {notification} from "antd";
import {Link} from "react-router-dom";

import {t, trans} from "../../../system/i18n";
import {url} from "../../../system/routing";
import {LOG_BARCODE_TYPE} from "../services/constants";

const listKeyException = [
    "order_finished",
    "oder_code_invalid",
    "warehouse_invalid",
    "has_order_packing_in_other_warehouses",
    "has_many_unprocessed_order_packings",
    "has_finished_order_packing",
    "not_found_order_packing",
    "order_inspected",
    "sku_is_serial_number",
    "sku_invalid_serial_number_exist_in_other_document_exporting",
    "sku_invalid_serial_number_exist_in_other_document_packing",
];

export default function renderErrorScan(data) {

    const {error, typeAction, params, warehouses} = data;
    const status = get(error, "status", null);
    const warehouseInfo = find(warehouses, ["id", parseInt(params.warehouse_id)]);
    const warehouse_name = get(warehouseInfo, "name");
    const warehouse_code = get(warehouseInfo, "code");
    let message = "";  

    switch (status) {
        case 400:
            let errors = get(error, "data.data", {});

            Object.keys(errors).forEach(item => {
                const document_code = get(errors[item], "document.code");
                const document_id = get(errors[item], "document.id");
                const created_at = get(errors[item], "document.created_at");
                const date = created_at ? moment(created_at).format("DD/MM/YYYY") : "";

                if(item === 'warehouse'){
                    const listMessage = errors[item]
                    ?.map(order => order + ',')
                    .join('')
                    .slice(0, -1)

                    message = ( trans('order:message.warehouse_not_select', {
                        order_code: <b>{listMessage}</b>,
                    }));
                }
                else if (listKeyException.includes(item)) {
                        message = (trans(`order:message.scan_order.${item}`, {
                            barcode_type: t(`order:barcode_type.${LOG_BARCODE_TYPE[params.barcode_type]}`),
                            barcode: `#${params.barcode}`,
                            document: <Link to={url.to("documents.packing.detail", {id: document_id})}>{`#${document_code}`}</Link>,
                            date: date,
                            warehouse_name: `${warehouse_name} (${warehouse_code})`,
                            typeAction: t(`order:label.scan_order.${typeAction}`)
                        }));
                   
                   
                } else {
                    const document_exporting = get(errors[item], "document_exporting", {});
                    const orders = get(errors[item], "orders", []);
                    let attribute = trans(`order:label.${item}`);
                 
                    if (isEmpty(document_exporting) && isEmpty(orders)) {
                        const error_messages = [];
                        Object.keys(errors[item]).forEach(error => error_messages.push(
                            trans(`order:message.scan_order.${error}`, {
                                barcode: `#${params.barcode}`,
                                barcode_type: t(`order:barcode_type.${LOG_BARCODE_TYPE[params.barcode_type]}`),
                                title: t(`order:title.barcode_type.${LOG_BARCODE_TYPE[params.barcode_type]}`),
                                attribute,
                                typeAction: t(`order:label.scan_order.${typeAction}`),
                                warehouse_name: `${warehouse_name} (${warehouse_code})`,
                            })
                        ));
                        message = error_messages;
                    } else {
                       
                        if (!isEmpty(document_exporting)) {
                            const document_exporting_code = get(document_exporting, "code");
                            const document_exporting_id = get(document_exporting, "id");
                            const export_created_at = get(document_exporting, "created_at");
                            const verified_at = get(document_exporting, "verified_at");
                            let date_export = export_created_at ? moment(export_created_at).format("DD/MM/YYYY") : "";
                            if (verified_at)
                                date_export = moment(verified_at).format("DD/MM/YYYY");
                            const attribute = {
                                barcode_type: t(`order:barcode_type.${LOG_BARCODE_TYPE[params.barcode_type]}`),
                                barcode: `#${params.barcode}`,
                                document: <Link to={url.to("documents.exporting.detail", {id: document_exporting_id})}>{`#${document_exporting_code}`}</Link>,
                                date: date_export,
                                warehouse_name: `${warehouse_name} (${warehouse_code})`,
                            };
                            if (verified_at) {
                                message = (trans(`order:message.scan_order.export_order_success`, attribute));
                            } else {
                                message = (trans(`order:message.scan_order.export_order_created`, attribute));
                            }
                        }
                        if (!isEmpty(orders)) {
                            message = (trans(`order:message.scan_order.orders_already_exists`, {
                                barcode: `#${params.barcode}`,
                                barcode_type: t(`order:barcode_type.${LOG_BARCODE_TYPE[params.barcode_type]}`)
                            }));
                        }
                    }
                }
            });
            break;
        case 403:
            notification.error({message: trans('common:message.403')});
            break;
        case 404:
            notification.error({message: trans('common:message.404')});
            break;
        default:
            notification.error({message: trans('common:message.fetch_fail')});
            break;
    }
    return message;
}
