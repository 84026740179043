import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useUpdateMerchantsMutation = createUseMutation({
    namespace: 'user:update_merchant',
    mutation: data => apiService.addSellerForUser(data),
});

export default useUpdateMerchantsMutation;
