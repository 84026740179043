import { SearchOutlined, UndoOutlined } from '@ant-design/icons';
import { Form, Row, DatePicker, Col, Button, Input } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import SelectStatusDropship from '@Modules/Dropshipping/components/SelectStatusDropship';
import SelectAllMerchants from '@Modules/Merchant/components/SelectAllMerchants';
import SelectMerchantItems from '@Modules/Merchant/components/SelectMerchantItems';
import SelectMerchantsAssigned from '@Modules/Merchant/components/SelectMerchantsAssigned';

import { t } from '@System/i18n';

const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

function FormSearch({ addFilter, filters }) {
    const { xs, md } = useBreakpoint();
    const [form] = Form.useForm();
    const [input, setInput] = useState(filters);
    const { code, name, status, merchant_id } = input || {};
    useEffect(() => {
        setInput(filters);
    }, [filters]);

    function _onChange(name, value) {
        setInput({ ...input, [name]: value });
    }

    function _onChangeInput(e) {
        const name = e.target.name;
        const value = e.target.value;
        _onChange(name, value);
    }

    function handleChangeRangePicker(name, dates) {
        const name_from = `${name}[from]`;
        const name_to = `${name}[to]`;

        const filters = {
            ...input,
            [name_from]: dates ? dates[0].format('YYYY-MM-DD') : '',
            [name_to]: dates ? dates[1].format('YYYY-MM-DD') : '',
        };

        setInput(filters);
    }

    function _onSubmit() {
        addFilter(input);
    }

    function _onReset() {
        addFilter({});
    }

    return (
        <Form form={form} layout="vertical" onFinish={_onSubmit}>
            <div className="bg-white rounded-12 p-4 mt-3">
                <div className="search-box">
                    <Row gutter={20}>
                        <Col xs={{ span: 12 }} lg={{ span: 4 }}>
                            <Form.Item className={xs || md ? 'mb-3' : 'mb-0'} label={t('product:label.name')}>
                                <Input
                                    className="_variant-search-code"
                                    name="name"
                                    value={name}
                                    placeholder={t('product:placeholder.product_name')}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                    allowClear={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                            <Form.Item className="mb-3" label={t('product:label.code')}>
                                <Input
                                    className="_variant-search-code"
                                    name="code"
                                    value={code}
                                    placeholder={t('product:placeholder.product_code')}
                                    onChange={_onChangeInput}
                                    onPressEnter={_onSubmit}
                                    allowClear={true}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                            <Form.Item className="mb-3" label={t('common:label.merchant')}>
                                {auth.can(permissions.PRODUCT_MANAGE_ALL) ? (
                                    <SelectAllMerchants
                                        className="_search-merchant"
                                        allowClear
                                        value={merchant_id ? parseInt(merchant_id) : undefined}
                                        placeholder={t('placeholder.select_seller')}
                                        onChange={value => _onChange('merchant_id', value)}
                                    />
                                ) : (
                                    <SelectMerchantsAssigned
                                        className="_search-merchant"
                                        allowClear
                                        value={merchant_id ? parseInt(merchant_id) : undefined}
                                        placeholder={t('placeholder.select_seller')}
                                        onChange={value => _onChange('merchant_id', value)}
                                    />
                                )}
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 12 }} lg={{ span: 5 }}>
                            <Form.Item className="mb-3" label={t('label.status')}>
                                <SelectStatusDropship
                                    className="_variant-search-status"
                                    value={status}
                                    placeholder={t('product:placeholder.status')}
                                    onChange={value => _onChange('status', value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={{ span: 24 }} lg={{ span: 5 }}>
                            <Form.Item className={xs || md ? 'mb-3' : 'mb-0'} label={t('document:label.created_at')}>
                                <RangePicker
                                    className="_variant-search-created-at"
                                    value={
                                        input['created_at[from]'] && input['created_at[to]']
                                            ? [moment(input['created_at[from]']), moment(input['created_at[to]'])]
                                            : undefined
                                    }
                                    format={dateFormat}
                                    placeholder={[t('common:label.time_start'), t('common:label.time_end')]}
                                    onChange={value => handleChangeRangePicker('created_at', value)}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={{ span: 24 }} lg={{ offset: 16, span: 8 }} className="text-right">
                            <Form.Item className="mb-0" label={' '}>
                                <Button className="mr-2 _finance-search-btn-reset" onClick={_onReset}>
                                    <UndoOutlined /> {t('common:btn.set_again')}
                                </Button>
                                <Button type="primary" htmlType="submit" className="_finance-search-btn-search">
                                    <SearchOutlined /> {t('common:btn.search')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        </Form>
    );
}

export default FormSearch;
