import { Button, Modal, Typography } from 'antd';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import uniqWith from 'lodash/uniqWith';
import React, { useCallback, useState } from 'react';

import usePersistFn from '@Modules/App/Hooks/usePersistFn';
import UploadFile from '@Modules/App/Upload/UploadFileV2';

import { t } from '@System/i18n';

import useDownloadRefSkusQuery from '../Hooks/useDownloadRefSkusQuery';
import useImportRefSkusMutation from '../Hooks/useImportRefSkusMutation';
import { renderError } from './errors';

const { Paragraph } = Typography;

const ImportRefSkusModal = ({ filters, isSelectAllPage, selectedRowKeys, visible, onCancel, onOk }) => {
    const [errors, setErrors] = useState([]);
    const [file, setFile] = useState();
    const { isLoading: downloading, refetch: downloadRefSkus } = useDownloadRefSkusQuery({
        ...filters,
        ids: isSelectAllPage ? [] : selectedRowKeys,
    });
    const { isLoading: importing, mutateAsync: importRefSkus } = useImportRefSkusMutation();

    const handleCancel = usePersistFn(() => {
        if (isFunction(onCancel)) {
            onCancel();
        }
    }, []);

    const handleOk = usePersistFn(async () => {
        try {
            const { data } = await importRefSkus({
                file,
            });

            const errors = uniqWith(data?.errors, isEqual);

            if (errors.length) {
                setErrors(errors);
            } else if (isFunction(onOk)) {
                onOk();
            }
        } catch (error) {}
    }, []);

    const handleUpload = useCallback(file => {
        setFile(file);
    }, []);

    return (
        visible && (
            <Modal
                cancelText={t('btn.close')}
                className="_import-ref-skus-modal"
                maskClosable
                okButtonProps={{
                    loading: importing,
                }}
                okText={t('btn.ok')}
                title={t('product:update_product_type_code')}
                visible={visible}
                onCancel={handleCancel}
                onOk={handleOk}
            >
                <Paragraph className="mb-0">
                    - {t('product:import_ref_skus_modal.descriptions.1')}:
                    <Button className="p-0" loading={downloading} type="link" onClick={downloadRefSkus}>
                        {t('product:import_ref_skus_modal.sample_file_name')}
                    </Button>
                </Paragraph>

                <Paragraph>- {t('product:import_ref_skus_modal.descriptions.2')}</Paragraph>
                <UploadFile
                    accept=".xls,.xlsx"
                    fileType={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']}
                    onChange={handleUpload}
                />
                {errors && errors.length > 0 && (
                    <div className="pt-3">
                        {errors.map((error, index) => {
                            return (
                                <p key={index} className="text-danger">
                                    - {renderError(error)}
                                </p>
                            );
                        })}
                    </div>
                )}
            </Modal>
        )
    );
};

export default ImportRefSkusModal;
