import { ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Table, Tooltip, Typography } from 'antd';
import { each, mapKeys, sumBy } from 'lodash';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@Modules/App/Box';
import useSize from '@Modules/App/Hooks/useSize';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import ModalAdditionalSku from '@Modules/Document/components/ModalAdditionalSku';
import SelectWarehouseArea from '@Modules/WarehouseArea/components/SelectWarehouseArea';

import { t } from '@System/i18n';
import { url } from '@System/routing';
import { getVar } from '@System/support/helpers';

const { Title } = Typography;

function ProductList({
    disabled,
    dataSource,
    importingDocument,
    onUpdate,
    handleAddRow,
    handleDeleteRow,
    form,
    updateSkuImportingInfo,
    loading = false,
    refetchImportingDocument,
    pagination,
}) {
    const { isXSmall, isSmall } = useSize();

    const [visibleModalAdditional, setVisibleModalAdditional] = useState(false);
    const [skusLackInfo, setSkusLackInfo] = useState([]);

    useEffect(() => {
        const refactorData = () => {
            //Lấy ra những item có lack_of_information false là những skus thiếu thông tin cân nặng hoặc kích thước
            const newData = dataSource.filter(item => item.lack_of_information === false);

            // Danh sách keys mới cần thay đổi.
            // Thay đổi vì chức năng trước đã có muốn tái sử dụng component ModalAdditionalSku nên sửa lại keys cho có thể dùng chung dc

            const newKeys = {
                id: 'sku_id',
                code: 'sku_code',
                name: 'sku_name',
            };

            const dataNewKeys = newData.map(item => renameKeys(item, newKeys));

            setSkusLackInfo(dataNewKeys);
        };

        refactorData();
    }, [dataSource]);

    //Change keys name object
    function renameKeys(obj, newKeys) {
        const keyValues = Object.keys(obj).map(key => {
            const newKey = newKeys[key] || key;
            return { [newKey]: obj[key] };
        });
        return Object.assign({}, ...keyValues);
    }

    const columns = [
        {
            className: '_importing-document-product-list-product-name',
            title: t('product:label.product_name'),
            dataIndex: 'name',
            render: (text, record) => {
                return {
                    children: <p>{text}</p>,
                    props: {
                        rowSpan: record.rowSpan,
                    },
                };
            },
        },
        {
            className: '_importing-document-product-list-sku-code',
            title: t('product:label.sku_code'),
            dataIndex: 'code',
            width: '22%',
            render: (text, record) => {
                const product_id = get(record, 'product_id', '');
                const sku_code = get(record, 'code', '');
                const lack_of_information = get(record, 'lack_of_information', '');

                return {
                    children: (
                        <strong style={{ fontWeight: '500' }}>
                            <Link target="_blank" to={url.to('products.detail', { id: product_id })}>
                                {sku_code}
                            </Link>
                            {!disabled && lack_of_information === false && (
                                <Tooltip title={t('document:messages.skus_lack_of_info')} className="ml-1">
                                    <ExclamationCircleOutlined style={{ color: '#faad14' }} />
                                </Tooltip>
                            )}
                        </strong>
                    ),

                    props: {
                        rowSpan: record.rowSpan,
                    },
                };
            },
        },

        {
            className: '_importing-document-product-list-warehouse-area-name',
            title: t('product:label.warehouse_area_name'),
            dataIndex: 'warehouse_area_id',
            editable: !disabled,
            width: '25%',
            render: (text, record) => {
                const warehouse_area = get(record, 'warehouse_area', {});
                if (isEmpty(warehouse_area)) {
                    return '';
                } else {
                    const { name, code } = warehouse_area;
                    return (
                        (name === 'default' ? t('order:label.default') : name) +
                        (code ? ' (' + (code === 'default' ? t('order:label.default') : code) + ')' : '')
                    );
                }
            },
        },
        {
            className: 'text-right text-nowrap _product-list-quantity',
            title: t('document:label.actual_amount_received'),
            dataIndex: 'real_quantity',
            editable: !disabled,
            width: '100px',
            render: (text, record) => {
                return {
                    children: <p>{text}</p>,
                    props: {
                        rowSpan: record.rowSpan,
                    },
                };
            },
        },
        // {
        //     className: 'text-nowrap _product-list-received-quantity',
        //     title: t("product:label.real_receiver_quantity"),
        //     dataIndex: 'real_quantity',
        //     editable: !disabled,
        //     width: (isXSmall || isSmall) ? "150px" : "200px",
        // },
        // {
        //     className: "text-right _importing-document-product-action",
        //     width: "50px",
        //     dataIndex: 'product-action',
        //     render: ((text, record) => {
        //         const isParent = get(record, "isParent", false);
        //         return isParent ? <span
        //             className="_span--add cursor-pointer _importing-document-product-action-add"
        //             onClick={() => handleAddRow(record)}
        //         >
        //             <PlusOutlined />
        //         </span> :
        //             <span
        //                 className="_span--delete cursor-pointer _importing-document-product-action-delete"
        //                 onClick={() => handleDeleteRow(record)}
        //             >
        //                 <MinusOutlined />
        //             </span>;
        //     })
        // },
    ];

    function checkWarehouseAreaDuplicate(data, value) {
        const skuImporting = find(dataSource, { id: data.id, warehouse_area_id: value });
        return !isEmpty(skuImporting) && skuImporting.key !== data.key;
    }

    const EditableCell = ({
        editable,
        children,
        dataIndex,
        record,
        updateSkuImportingInfo,
        checkWarehouseAreaDuplicate,
        index,
        ...restProps
    }) => {
        useEffect(() => {
            if (editable) {
                form.setFields([{ name: [index, dataIndex], value: record[dataIndex] }]);
            }
        }, [editable]);

        const handleUpdateSkuImportingInfo = (dataIndex, value) => {
            updateSkuImportingInfo(dataIndex, value, record);
        };

        const onChangeWarehouseArea = (name, value) => {
            form.setFields([{ name: [index, name], value: value }]);
            if (!checkWarehouseAreaDuplicate(record, value)) {
                form.setFields([{ name: [index, name], errors: '' }]);
                handleUpdateSkuImportingInfo(name, value);
            }
        };

        let childNode = children;
        if (editable) {
            if (dataIndex === 'warehouse_area_id') {
                childNode = (
                    <>
                        <Form.Item
                            className="mb-0"
                            name={[index, dataIndex]}
                            rules={[
                                { required: true, message: t('product:message.required') },
                                () => ({
                                    validator(rule, value) {
                                        if (checkWarehouseAreaDuplicate(record, value)) {
                                            return Promise.reject(t('product:message.not_duplicate'));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <SelectWarehouseArea
                                allowClear={true}
                                placeholder={t('product:placeholder.warehouse_area_name')}
                                warehouseid={
                                    importingDocument?.warehouse_id
                                        ? parseInt(importingDocument?.warehouse_id)
                                        : undefined
                                }
                                value={record[dataIndex] ? parseInt(record[dataIndex]) : undefined}
                                onChange={value => onChangeWarehouseArea(dataIndex, value)}
                                noSuggest={false}
                            />
                        </Form.Item>
                    </>
                );
            } else {
                //   value={record[dataIndex] ? parseInt(record[dataIndex]) : undefined}
                form.setFields([{ name: [index, 'sku_id'], value: record['id'] }]);
            
                if (record['documentSkuId']) {
                    form.setFields([{ name: [index, 'sku_importing_id'], value: record['documentSkuId'] }]);
                } else {
                    form.setFields([{ name: [index, 'sku_importing_id'], value: undefined }]);
                }

                childNode = (
                    <>
                        <p>{parseInt(record[dataIndex])}</p>
                        
                        <Form.Item className="mb-0" hidden name={[index, dataIndex]}>
                            <Input />
                        </Form.Item>
                        <Form.Item className="mb-0" hidden={true} name={[index, 'sku_id']}>
                            <Input />
                        </Form.Item>
                        <Form.Item className="mb-0" hidden={true} name={[index, 'sku_importing_id']}>
                            <Input />
                        </Form.Item>
                    </>
                );
            }
        }
        return <td {...restProps}>{childNode}</td>;
    };

    const tableColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record, index) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                index,
                checkWarehouseAreaDuplicate: checkWarehouseAreaDuplicate,
                updateSkuImportingInfo: updateSkuImportingInfo,
            }),
        };
    });

    const handleOpenModalAdditional = () => {
        setVisibleModalAdditional(true);
    };

    return (
        <Box className="aside">
            <div className="d-flex justify-content-between border-bottom align-items-center">
                <Space className="mb-3">
                    <Title level={3}>{t('order:title.list_products')}</Title>
                    <span className="number-circle">{pagination?.total ?? 0}</span>
                    {!disabled && !isEmpty(skusLackInfo) && (
                        <div className="d-flex justify-content-center align-items-center">
                            <p className="mb-0 font-weight-500" style={{ color: '#ff4d4f' }}>
                                {t('document:messages.please_add_product_size')}
                            </p>
                            {auth.can(permissions.PRODUCT_UPDATE) && (
                                <Button type="primary" className="ml-2" onClick={handleOpenModalAdditional}>
                                    {t('document:btn.additional')}
                                </Button>
                            )}
                        </div>
                    )}
                </Space>
            </div>
            <Form form={form} component={false}>
                <Table
                    bordered
                    loading={loading}
                    className="_product-list-info"
                    dataSource={dataSource}
                    columns={disabled ? tableColumns.filter(col => col.dataIndex !== 'product-action') : tableColumns}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    // footer={() => {
                    //     return dataSource.length > 0 ? (
                    //         <div className="d-flex justify-content-between">
                    //             <strong>{t('sum')}</strong>
                    //             <strong className="_product-list-total">
                    //                 {
                    //                     `${sumBy(dataSource, 'real_quantity')}/${sumBy(dataSource, 'quantity')}`
                    //                 }
                    //             </strong>
                    //         </div>
                    //     ) : null;
                    // }}
                    pagination={false}
                    locale={{ emptyText: <TableEmpty className="_product-list-no-data" /> }}
                    scroll={{ x: 576 }}
                />
            </Form>
            {/* {
                dataSource.length > 0 &&
                <div className="mt-2">
                    <Input.TextArea
                        className="_product-list-note"
                        placeholder={t("note")}
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        value={note}
                        onBlur={(e) => onUpdate("note", e.target.value.trim())}
                        onChange={(e) => setNote(e.target.value)}
                        disabled={disabled}
                    />
                </div>
            } */}
            {visibleModalAdditional && (
                <ModalAdditionalSku
                    setVisible={setVisibleModalAdditional}
                    skusLackInfo={skusLackInfo}
                    refetchImportingDocument={refetchImportingDocument}
                />
            )}
        </Box>
    );
}

export default ProductList;
