import { useQuery } from 'react-query';

import { t } from '@System/i18n';
import notification from '@System/notification';

import apiService from './../services/api';
import {convertBlobDataToExcel} from "@System/support/helpers";

const useExportProductInventory = params =>
    useQuery(['products', params], () => apiService.exportProductInventory(params), {
        retry: false,
        enabled: false,
        onSuccess: (res) => {
            convertBlobDataToExcel(res.data, "stocks-export");
            notification.success({message: t("order:export_excel.success")})
        },
        onError: error => {
            const status = error?.response?.status;
            if (status === 403) {
                notification.error(t('message.403'));
            } else if (status === 404) {
                notification.error(t('message.404'));
            } else {
                notification.error(t('message.fetch_fail'));
            }
        },
    });

export default useExportProductInventory;
