/* eslint-disable import/no-anonymous-default-export */
import { api } from '../../../system/api';

export default {
    importProducts: params => api.post('/products/import', params),
    importInventoryProducts: data => api.post('/stocks/import', data),
    importPrice: data => api.post('/skus/import-price', data),
    searchSkus: params => api.get('/skus/suggest', { params, singleRequest: true, loading: false }),
    createProduct: data => api.post('/skus', data),
    updateProduct: (skuId, data) => api.put(`/skus/${skuId}`, data),
    getSkuDetail: (skuId, params) => api.get(`/skus/${skuId}`, { params, singleRequest: true, loading: false }),
    getSkuStocks: skuId => api.get(`/skus/${skuId}/stocks`, { singleRequest: true, loading: false }),
    getImportHistories: params => api.get(`/import-histories`, { params, singleRequest: true, loading: false }),
    getImportHistoriesDetail: historyId =>
        api.get(`/import-histories/${historyId}`, {
            singleRequest: true,
            loading: false,
        }),
    getImportHistoriesItems: (historyId, params) =>
        api.get(`/import-histories/${historyId}/items`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    getStocks: params => api.get(`/stocks`, { params, singleRequest: true, loading: false }),
    getProducts: params =>
        api.get('/products', {
            params,
            singleRequest: true,
            loading: false,
        }),
    getProduct: id => api.get(`/products/${id}`, { singleRequest: true, loading: false }),
    editProduct: (id, data) => api.post(`/products/${id}`, data),
    checkDeleteValue: data => api.post('/options/check-delete-value', data),
    getProductDetail: productId => api.get(`/products/${productId}`, { singleRequest: true, loading: false }),
    getVendorByProductId: productId =>
        api.get(`/products/${productId}/merchants`, {
            singleRequest: true,
            loading: false,
        }),
    getSkusPriceByMerchantId: (productId, params) =>
        api.get(`/products/${productId}/sku-prices`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    updateProductMerchants: (productId, data) => api.post(`/products/${productId}/merchants`, data),
    updateSkuPricesOfProduct: data => api.put(`/skus/prices`, data),
    downloadRefSkus: params =>
        api.get('/products/download-ref-skus', {
            params,
            responseType: 'blob',
        }),
    importRefSkus: data => api.post('/products/import-ref-skus', data),
    importSkuMapSkuMarketplace: data => api.post(`/stores/import`, data),
    getInfoSkuByIds: params => api.get(`/skus/selected-skus`, { params, singleRequest: true, loading: true }),
    exportProductInventory: data => api.post(`stocks/export`, data, { responseType: 'blob' }),
    getProductHistory: productId => api.get(`products/${productId}/logs`, { singleRequest: true, loading: true }),
    getStorageFee: (id, params) =>
        api.get(`/stocks/${id}/storage-fee-daily`, {
            params,
            singleRequest: true,
            loading: false,
        }),
    confirmWeightVolume: (productId, data) => api.put(`products/${productId}/confirm-weight-volume-for-skus`, data),
    downloadStampPdf: data =>
        api.post(`/skus/barcode`, data, { singleRequest: true, loading: true, responseType: 'blob' }),
    getSkus: (params) => api.get(`/skus`, { singleRequest: true, loading: true, params }),
    addItemOfPurchasePackage: (id, data) => api.put(`/purchasing-packages/${id}/add-items`, data),
    exportStorageFee: params => api.get(`stocks/export-storage-fees`, { params, responseType: 'blob' }),
    getComboList: (params) => api.get(`/sku-combo-operate`, { singleRequest: true, params }),
    getSkuComboDetail: id => api.get(`/sku-combo-operate/${id}`, { singleRequest: true }),
    getSkuComboHistory: id => api.get(`/sku-combo-operate/${id}/logs`, { singleRequest: true }),
    changeLogicBatch: (id,data) => api.post(`/skus/${id}/is-goods-batch`, data),
    getGroupBatchQuery: (params) => api.get(`/stocks/group-by-batch`, { singleRequest: true, params }),
    changeLogicSerial: (id,data) => api.post(`/skus/${id}/is-serial-number`, data),
};
