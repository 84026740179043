export const STATUSES = {
    ACTIVE: {
        value: 'active',
        color: '#09b8af'
    },
    DEACTIVE: {
        value: 'deactive',
        color: '#d9dbe9'
    },
    FAILED: {
        value: 'failed',
        color: '#ff4559'
    }
};
