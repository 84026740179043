import { PlusOutlined } from '@ant-design/icons';
import { Modal, Form, Button, notification } from 'antd';
import _ from 'lodash';
import React, { useState } from 'react';

import { events } from '../../../system/events';
import { t } from '../../../system/i18n';
import api from '../services/api';
import { EVENTS } from '../services/constants';
import MerchantForm from './MerchantForm';

export default function CreateMerchant() {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    form.setFields([{ name: 'status', value: true }]);

    function showModal(e) {
        e.preventDefault();
        setVisible(true);
    }

    function handleOk() {
        setVisible(false);
    }

    function handleCancel() {
        form.resetFields();
        setVisible(false);
    }

    function handleSubmit(e) {
        form.validateFields().then(values => {
            setLoading(true);
            let data = {};
            for (const prop in values) {
                if (values[prop] !== undefined && values[prop] !== null) data[prop] = values[prop];
            }

            api.createMerchant(data)
                .then(res => {
                    notification.success({ message: t('message.create_success', { attribute: t('label.merchant') }) });
                    form.resetFields();
                    setVisible(false);
                    events.dispatch(EVENTS.FETCH_MERCHANT_AGAIN, {});
                    events.dispatch(EVENTS.FETCH_LIST_MERCHANT_AGAIN, {});
                })
                .catch(err => {
                    let { response } = err;
                    if (response.status === 400) {
                        let error_messages = [];
                        let errors = _.get(response, 'data.data', {});
                        Object.keys(errors).forEach(item => {
                            if (item === 'error' && errors[item]['key'] === 'account_existed') {
                                error_messages.push({
                                    name: 'code',
                                    errors: [
                                        t('merchant:message.already_exist', { attribute: t(`merchant:label.code`) }),
                                    ],
                                });
                            } else {
                                let key_errors = [];
                                Object.keys(errors[item]).forEach(error =>
                                    key_errors.push(
                                        t(`merchant:message.${error}`, { attribute: t(`merchant:label.${item}`) })
                                    )
                                );
                                error_messages.push({ name: item, errors: key_errors });
                            }
                        });
                        form.setFields(error_messages);
                    }
                    notification.error({ message: t('message.create_failed', { attribute: t('label.merchant') }) });
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }

    function onReset() {
        form.resetFields();
    }

    return (
        <>
            <Button type="primary" className="mt-2 mt-md-0" onClick={showModal}>
                <PlusOutlined /> {t('merchant:label.create')}
            </Button>
            {visible && (
                <Modal
                    title={t('merchant:title.create_merchant').toUpperCase()}
                    visible={true}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    width={700}
                    footer={null}
                    maskClosable={false}
                >
                    <MerchantForm loading={loading} onReset={onReset} handleSubmit={handleSubmit} form={form} />
                </Modal>
            )}
        </>
    );
}
