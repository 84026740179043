import { Button, Col, Form, Input, Row, Typography } from 'antd';
import React, { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

import StyledTag from '@Modules/App/StyledTag';
import useUpdateExportingDocumentMutation from '@Modules/Document/Hooks/useUpdateExportingDocumentMutation';
import { STATUSES } from '@Modules/Document/constants';
import { STATUS_COLORS } from '@Modules/Document/services/constants';

import { trans } from '@System/i18n';
import { Link } from '@System/routing';

import { dateFormatter } from '../../../../App/services';
import ExportingDetailToPrint from '../components/ExportingDetailToPrint';

const { Paragraph } = Typography;

const Header = ({ form, exportingDocument, onDelete, onExport, onSuccessUpdate, isExporting }) => {
    const [editing, setEditing] = useState(false);
    const exportingRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => exportingRef.current,
        pageStyle: () => {
            return `@page {
                  size: A4;
                } 
                @media all {
                    .page-break {
                         display: none;
                    }
                }

                @media print {
                    .page-break {
                        page-break-before: always;
                    }
                }
            `;
        },
    });
    const { isLoading: updating, mutate: updateExportingDocument } = useUpdateExportingDocumentMutation({
        id: exportingDocument?.document_exporting?.id,
        onSuccess: data => {
            setEditing(false);
            onSuccessUpdate(data);
        },
    });

    const handleUpdateButtonClick = () => {
        updateExportingDocument(form.getFieldsValue());
    };

    return (
        <>
            <div className="d-none">
                <ExportingDetailToPrint ref={exportingRef} exportingDocument={exportingDocument} />
            </div>
            <Form className="bg-white mb-3 p-3" form={form}>
                <div className="w-100">
                    <div className="mb-3 d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-between">
                        <div className="d-flex align-items-center mb-3 mb-lg-0">
                            <div className="d-flex-inline flex-column">
                                <StyledTag
                                    className="mb-1"
                                    type={
                                        exportingDocument?.document_exporting?.status === STATUSES.COMPLETED &&
                                        'success'
                                    }
                                    color={
                                        exportingDocument?.document_exporting?.status
                                            ? STATUS_COLORS[exportingDocument?.document_exporting?.status]
                                            : undefined
                                    }
                                >
                                    {trans(`document:statuses.${exportingDocument?.document_exporting?.status}`)}
                                </StyledTag>
                                <Paragraph className="font-weight-bold text-fz-16 mb-0 mr-2">
                                    {trans('document:types.exporting')}: #{exportingDocument?.document_exporting?.code}
                                </Paragraph>
                                <div>
                                    {trans('document:created_by', {
                                        date: dateFormatter.date(exportingDocument?.document_exporting.created_at),
                                        confirmer: exportingDocument?.document_exporting?.creator_username || '--',
                                    })}
                                </div>
                            </div>

                            {exportingDocument?.document_exporting?.status === STATUSES.DRAFT && (
                                <Button danger onClick={onDelete}>
                                    {trans('document:delete.title')}
                                </Button>
                            )}
                        </div>

                        <div>
                            {editing ? (
                                <Button loading={updating} type="primary" onClick={handleUpdateButtonClick}>
                                    {trans('document:save_export_warehouse_info')}
                                </Button>
                            ) : (
                                <>
                                    {exportingDocument?.document_exporting?.status === STATUSES.DRAFT && (
                                        <>
                                            <Button
                                                className="mr-3 _btn-edit-receiver-info"
                                                onClick={setEditing.bind(this, true)}
                                            >
                                                {trans('edit_receiver_info')}
                                            </Button>
                                            <Button
                                                className="mr-3 _btn-print-document"
                                                type="primary"
                                                onClick={handlePrint}
                                            >
                                                {trans('btn.print_document')}
                                            </Button>
                                            <Button
                                                type="primary _btn-export-warehouse"
                                                onClick={onExport}
                                                loading={isExporting}
                                            >
                                                {trans('export_warehouse')}
                                            </Button>
                                        </>
                                    )}

                                    {exportingDocument?.document_exporting?.status === STATUSES.COMPLETED && (
                                        <>
                                            <Button
                                                type="primary"
                                                className="mr-3 order-2 _btn-print-document"
                                                onClick={handlePrint}
                                            >
                                                {trans('btn.print_document')}
                                            </Button>
                                            <Button type="primary _btn-for-control">
                                                <Link
                                                    params={{
                                                        id: exportingDocument?.document_exporting?.id,
                                                    }}
                                                    to="documents.exporting.for-control"
                                                >
                                                    {trans('for_control')}
                                                </Link>
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>

                    {editing ? (
                        <Row gutter={72}>
                            <Col className="mb-3 mb-lg-0" lg={{ span: 15 }} span={24}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <div className="mb-2 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                                            {trans('receiver_info')}

                                            {/* <Form.Item
                                            className="mb-0"
                                            initialValue={true}
                                            name="save_receiver_info"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>
                                                {trans('save_receiver_info')}
                                            </Checkbox>
                                        </Form.Item> */}
                                        </div>
                                    </Col>

                                    <Col className="mb-3 mb-lg-0" md={{ span: 8 }} span={24}>
                                        <Form.Item className="mb-0" name="receiver_name">
                                            <Input placeholder={trans('receiver')} tabIndex={1} />
                                        </Form.Item>
                                    </Col>

                                    <Col className="mb-3 mb-lg-0" md={{ span: 8 }} span={24}>
                                        <Form.Item className="mb-0" name="receiver_phone">
                                            <Input placeholder={trans('receiver_phone')} tabIndex={2} />
                                        </Form.Item>
                                    </Col>

                                    <Col md={{ span: 8 }} span={24}>
                                        <Form.Item className="mb-0" name="receiver_license">
                                            <Input placeholder={trans('document:placeholder.license')} tabIndex={3} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={{ span: 9 }} span={24}>
                                <div className="mb-2 d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
                                    {trans('receive_company')}

                                    {/* <Form.Item
                                    className="mb-0"
                                    initialValue={true}
                                    name="save_receiver_company_info"
                                    valuePropName="checked"
                                >
                                    <Checkbox>
                                        {trans('save_receive_company_info')}
                                    </Checkbox>
                                </Form.Item> */}
                                </div>

                                <Form.Item className="mb-0" name="partner">
                                    <Input tabIndex={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col lg={{ span: 18 }} span={24}>
                                <Row gutter={16}>
                                    <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                        <Paragraph className="mb-0 text-grey">{trans('full_name')}</Paragraph>
                                        <Paragraph className="mb-0">
                                            {exportingDocument?.document_exporting?.info?.receiver_name}
                                        </Paragraph>
                                    </Col>

                                    <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                        <Paragraph className="mb-0 text-grey">{trans('phone')}</Paragraph>
                                        <Paragraph className="mb-0">
                                            {exportingDocument?.document_exporting?.info?.receiver_phone}
                                        </Paragraph>
                                    </Col>

                                    <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                        <Paragraph className="mb-0 text-grey">
                                            {trans('document:placeholder.license')}
                                        </Paragraph>
                                        <Paragraph className="mb-0">
                                            {exportingDocument?.document_exporting?.info?.receiver_license}
                                        </Paragraph>
                                    </Col>

                                    <Col className="mb-3 mb-lg-0" md={{ span: 6 }} span={24}>
                                        <Paragraph className="mb-0 text-grey">{trans('receive_company')}</Paragraph>
                                        <Paragraph className="mb-0">
                                            {exportingDocument?.document_exporting?.info?.partner}
                                        </Paragraph>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                </div>
            </Form>
        </>
    );
};

export default Header;
