import { useQuery } from 'react-query';

import apiService from './../services/api';

const useImportingReturnOrderDocumentQuery = id => useQuery(['importing-return-order-document', id], () => apiService.importingReturnOrderDocument(id), {
    retry: false,
    enabled: !!id
});

export default useImportingReturnOrderDocumentQuery;
