import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import { t } from '@System/i18n';
import { notifyErrorFromServer } from '@System/support/helpers';

import apiService from './../services/api';

const usePurchasingAccountBalanceQuery = (id, settings) =>
    createUseQuery({
        name: ['purchasing-account-balance', id],
        query: () => apiService.balance(id),
        settings: {
            enabled: false,
            onError: error => {
                notifyErrorFromServer(
                    error,
                    t('purchasingAccount:checking_balance.failed'),
                    'purchasingAccount:checking_balance.errors'
                );
            },
            ...settings
        },
    });

export default usePurchasingAccountBalanceQuery;
