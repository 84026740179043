import createUseMutation from '@Modules/App/Hooks/createUseMutation';

import apiService from './../services/api';

const useUpdatePurchasingAccountMutation = createUseMutation({
    namespace: 'purchasingAccount:update',
    mutation: data => apiService.update(data),
});

export default useUpdatePurchasingAccountMutation;
