import debounce from 'lodash/debounce';
import { useRef } from 'react';

import useCreation from './useCreation';
import useUnmount from './useUnmount';

// modified from https://ahooks.js.org/hooks/side-effect/use-debounce-fn

function useDebounceFn(fn, options) {
    const fnRef = useRef(fn);
    fnRef.current = fn;

    const wait = options?.wait ?? 1000;

    const debounced = useCreation(
        () =>
            debounce(
                (...args) => {
                    return fnRef.current(...args);
                },
                wait,
                options
            ),
        []
    );

    useUnmount(() => {
        debounced.cancel();
    });

    return {
        run: debounced,
        cancel: debounced.cancel,
        flush: debounced.flush,
    };
}

export default useDebounceFn;
