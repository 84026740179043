import {Avatar, Tooltip, Typography} from 'antd';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, {useCallback, useMemo, useState} from 'react';

import Badge from '@Modules/App/Badge';
import useSize from '@Modules/App/Hooks/useSize';
import Space from '@Modules/App/Space';
import {dateFormatter} from '@Modules/App/services';
import {ORDER_STATUSES, SCAN_TYPE} from '@Modules/Document/constants';

import {t} from '@System/i18n';
import {Link} from '@System/routing';

import OrderExportingTable from '../../components/OrderExportingTable';
import {SHIPPING_PARTNER_INFO} from "../../../../../App/services/constants";

const {Paragraph, Title} = Typography;

const OrderExportingList = ({exportingDocument, orderExportings}) => {
    const {isLarge} = useSize();
    const [pagination, setPagination] = useState({
        current_page: 1,
        per_page: isLarge ? 250 : 10,
    });

    const columns = useMemo(() => {
        const columns = [
            {
                className: '_index-column',
                title: t('table.#'),
                key: '#',
                render: (_, __, index) => (pagination?.current_page - 1) * pagination?.per_page + index + 1,
            },
            {
                className: '_freight-bill-column',
                title: t('freight_bill_code'),
                key: 'freight_bill',
                dataIndex: 'freight_bill',
            },
            {
                className: '_shipping-partner-column',
                key: 'shipping_partner',
                dataIndex: 'shipping_partner',
                width: '0px',
                render: text => {
                    const code = get(text, "code", "");
                    const shipping_partner_info = get(SHIPPING_PARTNER_INFO, code, {});
                    return <Tooltip title={text?.name}>
                        <Avatar
                            style={{backgroundColor: `${get(shipping_partner_info, "color", "")}`}}
                            className="flex-shrink-0" size={20}>{get(shipping_partner_info, "wildcard", "")}</Avatar>
                    </Tooltip>
                },
            },
            {
                className: '_order-column',
                title: t('order_code'),
                key: 'order_code',
                dataIndex: ['order', 'code'],
                render: (text, record) => (
                    <Link
                        isNewTab
                        params={{
                            id: record.order_id,
                        }}
                        to="orders.detail"
                    >
                        {text}
                    </Link>
                ),
            },
            {
                className: '_sku-column',
                title: t('SKU'),
                key: 'SKU',
                dataIndex: 'order_exporting_items',
                render: text =>
                    map(unionBy(text, 'sku_id'), orderExportingItem => (
                        <Paragraph className="mb-0" key={orderExportingItem.id}>
                            <Link
                                isNewTab
                                params={{
                                    id: orderExportingItem?.sku?.product_id,
                                }}
                                to="products.detail"
                            >
                                {orderExportingItem?.sku?.code}
                            </Link>
                        </Paragraph>
                    )),
            },
            {
                className: '_quantity-column',
                title: t('quantity'),
                key: 'quantity',
                dataIndex: 'order_exporting_items',
                render: items =>
                    map(items, orderExportingItems => (
                        <Paragraph className="mb-0" key={orderExportingItems.id}>
                            {orderExportingItems.quantity}
                        </Paragraph>
                    )),
            },
            // {
            //     className: '_quantity-column',
            //     title: t('quantity'),
            //     key: 'quantity',
            //     dataIndex: 'order_exporting_items',
            //     render: text =>
            //         map(groupBy(text, 'sku_id'), orderExportingItems => (
            //             <Paragraph className="mb-0" key={orderExportingItems[0].id}>
            //                 {sumBy(orderExportingItems, 'quantity')}
            //             </Paragraph>
            //         )),
            // },
            {
                className: '_scan-time-column',
                title: t('scan_time'),
                key: 'scan_time',
                dataIndex: 'scan_created_at',
                render: text => dateFormatter.full(text),
            },
            {
                className: '_status-order-exporting-column',
                title: t('export_request'),
                key: 'status',
                dataIndex: 'status',
                render: text => (
                    <Badge color={ORDER_STATUSES[text?.toUpperCase()]?.color}
                           text={t(`document:order_statuses.${text}`)}/>
                ),
            },
        ];
        
        
        if (exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER) {
            columns.splice(1, 1);
        }

        return columns;
    }, [exportingDocument, pagination]);

    const handleChangePage = useCallback(
        params => {
            setPagination({
                ...pagination,
                ...params,
            });
        },
        [pagination]
    );

    function renderTitle() {
        if (exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.FREIGHT_BILL) {
            return t('freight_bill_list');
        }

        if (exportingDocument?.document_exporting?.info?.barcode_type === SCAN_TYPE.ORDER) {
            return t('order_list');
        }

        return '';
    }

    return (
        <div>
            <Space className="mb-3" type="flex">
                <Title className="mb-0" level={5}>
                    {renderTitle()}
                </Title>
                <span className="number-circle">{orderExportings.length}</span>
            </Space>

            <OrderExportingTable
                columns={columns}
                dataSource={orderExportings}
                exportingDocument={exportingDocument}
                pagination={pagination}
                onChangePage={handleChangePage}
            />
        </div>
    );
};

OrderExportingList.propTypes = {
    orderExportings: PropTypes.array,
};

OrderExportingList.defaultProps = {
    orderExportings: [],
};

export default OrderExportingList;
