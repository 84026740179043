import { Form } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';

import ScanErrors from '@Modules/App/ScanErrors';
import withScanErrors from '@Modules/App/ScanErrors/withScanErrors';
import { scanReturnOrderStorage, setDocumentTitle, setNavigator } from '@Modules/App/services';
import ProductContainer from '@Modules/Document/screens/Importing/ReturnOrder/Scan/ProductContainer';
import WaybillsContainer from '@Modules/Document/screens/Importing/ReturnOrder/Scan/WaybillsContainer';
import { SCAN_RETURN_ORDER_TYPE } from '@Modules/Document/services/constants';

import { t, trans } from '@System/i18n';
import { Link } from '@System/routing';
import { getVar } from '@System/support/helpers';

import FormScan from './FormScan';
import reducer, { ACTIONS_SCAN_RETURN_ORDER } from './reducer';

import './scan_return_order.scss';

const errorKey = 'importByReturnOrder';
const FormScanWithErrors = withScanErrors(FormScan, errorKey);
const ShowListWithErrors = withScanErrors(ScanErrors, errorKey);

export default function ImportingByReturnOrder() {
    useEffect(() => {
        setNavigator(t('title.scan_return_order'), [
            {
                name: t('menu.operation_warehouse'),
            },
            {
                name: t('menu.scan_return_order'),
            },
        ]);
        setDocumentTitle(t('title.scan_return_order'));
    }, []);

    const initialState = scanReturnOrderStorage.value;
    const [storeData, dispatchStoreAction] = useReducer(reducer, initialState);
    const [form] = Form.useForm();

    const storeActions = {
        newScan: (data = {}) => dispatchStoreAction({ type: ACTIONS_SCAN_RETURN_ORDER.NEW_SCAN, payload: data }),
        updateScanInfo: data =>
            dispatchStoreAction({ type: ACTIONS_SCAN_RETURN_ORDER.UPDATE_SCAN_INFO, payload: data }),
        deleteReturnOrders: freightBillCodes =>
            dispatchStoreAction({ type: ACTIONS_SCAN_RETURN_ORDER.DELETE_RETURN_ORDERS, payload: freightBillCodes }),
        addReturnOrder: data =>
            dispatchStoreAction({ type: ACTIONS_SCAN_RETURN_ORDER.ADD_RETURN_ORDER, payload: data }),
        updateReceivedQuantity: data =>
            dispatchStoreAction({ type: ACTIONS_SCAN_RETURN_ORDER.UPDATE_RECEIVED_QUANTITY, payload: data }),
        isSaved: () => dispatchStoreAction({ type: ACTIONS_SCAN_RETURN_ORDER.IS_SAVED }),
        updateServices: data => dispatchStoreAction({ type: ACTIONS_SCAN_RETURN_ORDER.UPDATE_SERVICES, payload: data }),
        updateServiceOnStart: data => dispatchStoreAction({ type: ACTIONS_SCAN_RETURN_ORDER.UPDATE_SERVICES_ON_START, payload: data }),
    };

    function renderErrors(data) {
        if (!data) {
            return null;
        }
        const errorMessages = [];
        const errors = getVar(data, 'errors', undefined);
        const listKeyException = ['has_processing_importing', 'has_finished_importing', 'status_invalid'];
        if (!isEmpty(errors)) {
            Object.keys(data?.errors).forEach(item => {
                const document_code = getVar(data.errors[item], 'document.code');
                const document_id = getVar(data.errors[item], 'document.id');
                const created_at = getVar(data.errors[item], 'document.created_at');
                const message = getVar(data.errors[item], 'message', '');
                const date = created_at ? moment(created_at).format('DD/MM/YYYY') : '';
                const status = getVar(data.errors[item], 'status', '');
                if (listKeyException.includes(message)) {      
                    errorMessages.push(
                        trans(`document:scan_return_order.message.${message}`, {
                            barcode_type: t(`document:scan_return_order.scan_type.${data?.barcode_type}`),
                            barcode: `#${data?.barcode}`,
                            document: (
                                <Link
                                    params={{ id: document_id }}
                                    to="documents.importing.detail-importing-return-order"
                                >
                                    {`#${document_code}`}
                                </Link>
                            ),
                            date,
                            status: t(`order:FREIGHT_BILL_STATUS.${status}`),
                        })
                    );
                } else {
                    Object.keys(data?.errors[item]).forEach(error => {
                        if (
                            data?.barcode_type === SCAN_RETURN_ORDER_TYPE.ORDER_CODE &&
                            item === 'freight_bill' &&
                            error === 'exists'
                        ) {
                            // errorMessages.push(
                            //     trans(`document:scan_return_order.message.exists`, {
                            //         barcode_type: t(`document:scan_return_order.scan_type.FREIGHT_BILL`),
                            //     })
                            // );
                        } else {
                            errorMessages.push(
                                trans(`document:scan_return_order.message.${item}.${error}`, {
                                    barcode_type: t(`document:scan_return_order.scan_type.${data?.barcode_type}`),
                                    barcode: `#${data?.barcode}`,
                                    title: t(`document:scan_return_order.title.FREIGHT_BILL`),
                                })
                            );
                            // errorMessages.push(
                            //     trans(`document:scan_return_order.message.${error}`, {
                            //         barcode_type: t(`document:scan_return_order.scan_type.${data?.barcode_type}`),
                            //         barcode: `#${data?.barcode}`,
                            //         title: t(`document:scan_return_order.title.FREIGHT_BILL`),
                            //     })
                            // );
                        }
                    });
                }
            });
        } else {
            errorMessages.push(trans(`message.server_error`));
        }

        return errorMessages;
    }

    return (
        <div className="site-content scan-return-order">
            <FormScanWithErrors errorKey={errorKey} storeActions={storeActions} storeData={storeData} form={form} />
            <ShowListWithErrors errorMessageRender={renderErrors} />
            <WaybillsContainer storeActions={storeActions} storeData={storeData} />
            <ProductContainer storeActions={storeActions} storeData={storeData} />
        </div>
    );
}
