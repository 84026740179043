import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { Button, Modal, Switch, Table } from 'antd'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { dateFormatter } from '@Modules/App/services'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'
import useServiceListQuery from '@Modules/ServicesManager/Hook/useServiceListQuery'
import ModalAddService from '@Modules/ServicesManager/components/ModalAddService'
import ModalPriceList from '@Modules/ServicesManager/components/ModalPriceList'
import api from '@Modules/ServicesManager/services/api'

import { events } from '@System/events'
import { t } from '@System/i18n'
import notification from '@System/notification'
import { formatCurrency } from '@System/support/numberFormat'

const { confirm } = Modal

const ServiceBox = ({ serviceType, countryId }) => {
    const [data, setData] = useState([])
    const [visibleAdd, setVisibleAdd] = useState(false)
    const [currency, setCurrency] = useState({})
    const [switchInfo, setSwitchInfo] = useState('')

    const { data: dataRaw, isFetching, refetch, isError, error } = useServiceListQuery({ type: serviceType, country_id: countryId })

    useEffect(() => {
        return events.listen(`RELOAD_LIST_SERVICE_${serviceType}`, () => refetch())
    }, [serviceType, refetch])

    useEffect(() => {
        setData(get(dataRaw, 'data.services', []))
        setCurrency(get(dataRaw, 'data.currency', {}))
    }, [dataRaw])

    if (isError) {
        const status = error.response.status
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    const columnsRequiredService = auth.can(permissions.SERVICE_ADD)
        ? {
              className: `_manage-service-${serviceType.toLowerCase()}-table-compulsory-cl text-center _manage-service-column-switch`,
              title: t('label.is_required'),
              dataIndex: ['service', 'is_required'],
              key: 'is_required',
              render: (text, record) => {
                  const id = get(record, 'service.id')
                  return (
                      <Switch
                          checked={text}
                          onChange={checked => handleChangeCompulsory(id, checked)}
                          loading={switchInfo === id ? true : false}
                          className={`_manage--btn-compulsory-switch`}
                      />
                  )
              },
          }
        : {}

    const columnsStopService = auth.can(permissions.SERVICE_STOP)
        ? {
              className: `_manage-service-${serviceType.toLowerCase()}-table-providing-cl text-center _manage-service-column-switch-stop_providing`,
              title: t('label.providing'),
              dataIndex: ['service', 'status'],
              key: 'status',
              render: (text, record) => {
                  const id = get(record, 'service.id')
                  const status = get(record, 'service.status')
                  const statusChange = text === 'INACTIVE' ? 'ACTIVE' : 'INACTIVE'

                  return (
                      <Switch
                          checked={text === 'ACTIVE'}
                          onChange={checked =>
                              status === 'INACTIVE' ? handleChangeProviding(id, false, statusChange) : modalConfirmStopService(id, statusChange)
                          }
                          loading={switchInfo === id}
                          className={`_manage--btn-compulsory-switch`}
                      />
                  )
              },
          }
        : {}
    const columns = [
        {
            className: `_manage-service-${serviceType.toLowerCase()}__table-service-cl _manage-service-column-name`,
            title: t('label.service'),
            dataIndex: ['service', 'name'],
            key: 'name',
            render: (text, record) => {
                const id = get(record, 'service.id')
                const name = get(record, 'service.name')
                const auto_price_by = get(record, 'service.auto_price_by')

                return (
                    <Button
                        type="link"
                        className="p-0 font-weight-500"
                        onClick={() => showModalAddPrice(id, name, auto_price_by)}
                    >
                        {text}
                    </Button>
                )
            },
        },
        {
            className: `_manage-service-${serviceType.toLowerCase()}-table-service_code-cl _manage-service-column-code`,
            title: t('label.service_code'),
            dataIndex: ['service', 'code'],
            key: 'code',
        },
        {
            className: `_manage-service-${serviceType.toLowerCase()}-table-created_at-cl _manage-service-column-created_at`,
            title: t('label.created_at'),
            dataIndex: ['service', 'created_at'],
            key: 'created_at',
            render: text => {
                return text ? dateFormatter.full(text) : '---'
            },
        },
        {
            className: `_manage-service${serviceType.toLowerCase()}-table-price-cl text-right _manage-service-column-price`,
            title: t('label.price'),
            dataIndex: 'servicePrices',
            key: 'price',
            render: (text, record) => {
                let min = text
                    ? Math.min.apply(
                          null,
                          text.map(item => item.price)
                      )
                    : 0

                let max = text
                    ? Math.max.apply(
                          null,
                          text.map(item => item.price)
                      )
                    : 0
                return (
                    <p>
                        {text.length !== 0
                            ? !isEmpty(currency)
                                ? ` ${formatCurrency(min, currency)} - ${formatCurrency(max, currency)}`
                                : `${formatCurrency(min, null)} - ${formatCurrency(max, null)}`
                            : ' ---'}
                    </p>
                )
            },
        },
        columnsRequiredService,
        columnsStopService,
    ]

    const handleShowModalAdd = () => {
        setVisibleAdd(true)
    }

    const handleChangeCompulsory = async (id, value) => {
        const data = {
            is_required: value,
        }
        setSwitchInfo(id)
        try {
            await api.changeCompulsory(id, data)
            refetch()
            notification.success(t('message.update_service_success'))
        } catch (error) {
            notification.error(t('message.update_service_failed'))
        } finally {
            setSwitchInfo('')
        }
    }

    const handleChangeProviding = async (id, confirm, status) => {
        const data = {
            status,
            confirm,
        }

        setSwitchInfo(id)
        try {
            await api.changeProviding(id, data)
            refetch()
            notification.success(t('message.update_service_success'))
        } catch (error) {
            notification.error(t('message.update_service_failed'))
        } finally {
            setSwitchInfo('')
        }
    }

    const showModalAddPrice = (idService, label, auto_price_by) => {
        confirm({
            title: `${t('label.service')} ${label}`,
            icon: false,
            destroyOnClose: true,
            closable: true,
            width: 1200,
            content: (
                <ModalPriceList
                    serviceType={serviceType}
                    idService={idService}
                    currency={currency}
                    autoPriceBy={auto_price_by}
                />
            ),
            cancelText: t('btn.close'),
            okButtonProps: { style: { display: 'none' } },
        })
    }

    const modalConfirmStopService = (id, status) => {
        Modal.confirm({
            title: (
                <div className="d-flex justify-content-between">
                    {t('message.confirm_stop_service')}
                    <CloseOutlined onClick={() => Modal.destroyAll()} />
                </div>
            ),
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: t('btn.ok'),
            cancelText: t('btn.no'),
            onOk: () => handleChangeProviding(id, true, status),
            onCancel: () => handleChangeProviding(id, false, status),
        })
    }

    return (
        <div className="mt-4">
            <div className="d-flex justify-content-between">
                <h5 className="text-fz-16 mb-4">{t(`service_manage_status.${serviceType}`)}</h5>
                {auth.can(permissions.SERVICE_ADD) && (
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        className={`_manage-service-add-btn__${serviceType.toLowerCase()}`}
                        loading={isFetching}
                        onClick={handleShowModalAdd}
                    >
                        {t('btn.add_service')}
                    </Button>
                )}
            </div>
            <div
                className={`p-3 _manage-service-list__${serviceType.toLowerCase()}`}
                style={{ border: '2px solid #e5e5e5' }}
            >
                <Table
                    className={`_service-manage-table__${serviceType.toLowerCase()} pb-3 mt-4`}
                    rowKey={record => record?.service?.id}
                    rowClassName={`_service-manage-table__row`}
                    dataSource={data || []}
                    scroll={{ x: true }}
                    columns={columns}
                    loading={isFetching}
                    pagination={false}
                    locale={{ emptyText: <TableEmpty className="_product-list-empty" /> }}
                />
            </div>
            {visibleAdd && (
                <ModalAddService
                    refetch={refetch}
                    setVisibleAdd={setVisibleAdd}
                    serviceType={serviceType}
                    countryId={countryId}
                />
            )}
        </div>
    )
}

export default ServiceBox
