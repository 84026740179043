import get from 'lodash/get';
import React from 'react';

import WaybillList from '@Modules/Document/screens/Importing/ReturnOrder/components/WaybillList';

const WaybillsContainer = ({ storeActions, storeData, error, setError }) => {
    const dataSource = get(storeData, 'return_orders', []);
    const errors = get(storeData, 'errors', []);
    const onDelete = freightBillCodes => {
        storeActions.deleteReturnOrders(freightBillCodes);
    };

    const updateService = data => {
        storeActions.updateServices(data);
    };

    const updateServiceOnStart= (data) =>{
        storeActions.updateServiceOnStart(data);
    }

    return (
        <WaybillList
            action={{ onDelete, updateService, updateServiceOnStart }}
            dataSource={dataSource}
            data={storeData}
            storeActions={storeActions}
            errors={errors}
        />
    );
};

export default WaybillsContainer;
