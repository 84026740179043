import { Form, Typography, Upload } from 'antd'
import { get, isEmpty, map } from 'lodash'
import React, { useEffect, useState } from 'react'

import { t } from '@System/i18n'

import ChildSku from './ChildSku'
import Info from './Info'

const { Title } = Typography

const Detail = ({ detail }) => {

    return (
        <>
            <div className="p-4">
                <Title
                    className="mb-4"
                    level={5}
                >
                    {t('product_detail')} <span className="combo-badge ml-2">Combo</span>
                </Title>

                <Info detail={detail} />
               
                <ChildSku detail={detail}/>
            </div>
        </>
    )
}

export default Detail
