import { EVENTS } from '../../../layouts/constants';
import { events } from '../../../system/events';
import LocalStorageApi, { RETURNED_TYPE } from '../../../system/support/LocalStorageApi';
import CurrencyFormatter from './CurrencyFormatter';
import DateFormatter from './DateFormatter';

export const dateFormatter = new DateFormatter();
export const currencyFormatter = new CurrencyFormatter();

export function setNavigator(title, navigator) {
    events.dispatch(EVENTS.HEADER_BREADCRUMB, { title, navigator });
}

export const setDocumentTitle = title => (document.title = `${title} - M28`);

export const warehouseStorage = new LocalStorageApi('warehouse', RETURNED_TYPE.INT);
export const warehouseAreaStorage = new LocalStorageApi('warehouse_area', RETURNED_TYPE.INT);

//localStore cho màn xác nhận đóng hàng và xuất hàng
export const scanConfirmPacking = new LocalStorageApi('scan_confirm_packing', RETURNED_TYPE.JSON);
export const scanExportOrder = new LocalStorageApi('scan_export_order', RETURNED_TYPE.JSON);
export const scanWarehouseStorage = new LocalStorageApi('scan_warehouse', RETURNED_TYPE.INT);
export const scanBarcodeTypeStorage = new LocalStorageApi('barcode_type');
export const showResetConfirmPackingStorage = new LocalStorageApi('reset_confirm_packing', RETURNED_TYPE.INT);
export const showResetExportOrderStorage = new LocalStorageApi('reset_export_order', RETURNED_TYPE.INT);
export const confirmPackingIdsStorage = new LocalStorageApi('confirm_packing_ids', RETURNED_TYPE.JSON);
export const exportOrderIdsStorage = new LocalStorageApi('export_order_ids', RETURNED_TYPE.JSON);
export const startScanServiceConfirmPacking = new LocalStorageApi('scan_service_confirm_packing', RETURNED_TYPE.JSON);
export const startScanServicePackingPurchasingPackage = new LocalStorageApi('scan_service_packing_purchasing_package', RETURNED_TYPE.JSON);

// localStorage cho màn nhập hàng
export const scanImportingSkuStorage = new LocalStorageApi('scan_importing_sku', RETURNED_TYPE.JSON);
export const scanImportingPackageOrderStorage = new LocalStorageApi('scan_importing_package_order', RETURNED_TYPE.JSON);
export const scanReturnOrderStorage = new LocalStorageApi('scan_return_order', RETURNED_TYPE.JSON);
export const scanImportingPurchasePackageStorage = new LocalStorageApi('scan_importing_purchase_package', RETURNED_TYPE.JSON);
export const scanPackingPurchasingPackageStorage = new LocalStorageApi("scan_packing_purchasing_package", RETURNED_TYPE.JSON);

//localStorage cho man nhat hang
export const pickingSessionPiecesPassStorage = new LocalStorageApi("pickup_session_pieces_pass", RETURNED_TYPE.JSON);


