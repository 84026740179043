import { Button, Checkbox, Col, Divider, Form, Modal, Row, Tabs, Spin } from 'antd';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';

import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import ServiceMarkets from '@Modules/Location/services/ServiceMarkets';
import useMerchantItemsQuery from '@Modules/Merchant/Hooks/useMerchantItemsQuery';
import useUpdateMarketsMutation from '@Modules/Users/Hooks/useUpdateMarketsMutation';
import useUpdateMerchantsMutation from '@Modules/Users/Hooks/useUpdateMerchantsMutation';
import useUpdateWarehousesMutation from '@Modules/Users/Hooks/useUpdateWarehousesMutation';
import useUserDetailQuery from '@Modules/Users/Hooks/useUserDetailQuery';
import useWarehouseItemsQuery from '@Modules/Warehouse/Hooks/useWarehouseItemsQuery';

import { events } from '@System/events';
import { t } from '@System/i18n';

import { EVENTS } from '../../services/constants';

import styles from './../../user.module.scss';
import { filter, get, isEmpty } from 'lodash';
import useSupplierQuery from '@Modules/Supplier/hooks/useSupplierQuery';
import useUpdateSuppliersMutation from '@Modules/Users/Hooks/useUpdateSuppliersMutation';

const { TabPane } = Tabs;

function UpdateUser({  setVisible, userId }) {
    const [form] = Form.useForm();
    const [activeTabKey, setActiveTabKey] = useState(
        auth.can(permissions.USER_MERCHANT_VIEW) ? 'merchants' : 'warehouses'
    );
    const {
        data: merchantsData,
        refetch: getMerchantItems,
        isFetching: loadingMerchants,
    } = useMerchantItemsQuery({ per_page: 1000 });

    const { data: userInfoData, refetch: getUserInfo, isFetching: loadingUserInfo } = useUserDetailQuery(userId);

    const { data: warehousesData, isFetching: loadingWarehouses } = useWarehouseItemsQuery({
        per_page: 1000,
    });
    

    const { isLoading: updatingWarehouses, mutate: updateWarehouses } = useUpdateWarehousesMutation({
        onSuccess: () => {
            handleCancel();
            events.dispatch(EVENTS.FETCH_USER_AGAIN, {});
        },
    });
    const { isLoading: updatingMarkets, mutate: updateMarkets } = useUpdateMarketsMutation({
        onSuccess: () => {
            handleCancel();
            events.dispatch(EVENTS.FETCH_USER_AGAIN, {});
        },
    });

    const { isLoading: updatingMerchants, mutate: updateMerchants } = useUpdateMerchantsMutation({
        onSuccess: () => {
            getMerchantItems();
            handleCancel();
            events.dispatch(EVENTS.FETCH_USER_AGAIN, {});
        },
    });

    const { isLoading: updatingSuppliers, mutate: updateSuppliers } = useUpdateSuppliersMutation({
        onSuccess: () => {
            handleCancel();
            events.dispatch(EVENTS.FETCH_USER_AGAIN, {});
        },
    });
    

    const { data, isLoading: loadingSuppliers, refetch, isError, error } = useSupplierQuery({ per_page: 1000})


    const [indeterminate, setIndeterminate] = useState({
        merchant: false,
        warehouse: false,
        market: false,
        supplier: false
    });
    const [marketsData, setMarketsData] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [selected, setSelected] = useState({});
    const [isSelectAllMerchants, setIsSelectAllMerchants] = useState(false);
    const [isSelectAllWarehouses, setIsSelectAllWarehouses] = useState(false);
    const [isSelectAllMarket, setIsSelectAllMarket] = useState(false);
    const [isSelectAllSupplier, setIsSelectAllSupplier] = useState(false);
    const [suppliers, setSuppliers] = useState([])

    useEffect(() => {
        setSuppliers(get(data,'data.suppliers'))
    }, [data])
    

    useEffect(() => {
        ServiceMarkets.list().then(res => {
            setMarketsData(res);
        });
    }, []);

    useEffect(() => {
        const userInfo = userInfoData?.data;
        const merchants = map(userInfo?.merchants, 'id');
        const warehouses = map(userInfo?.warehouses, 'id');
        const markets = map(userInfo?.countries, 'id');
        const suppliers = map(userInfo?.suppliers, 'id');
        setUserInfo(userInfo);
        setSelected({ merchants, warehouses, markets,suppliers });

    }, [userInfoData]);

    useEffect(() => {
        let merchants = filter(userInfo?.merchants, (item) => item.status)
        const warehouses = map(userInfo?.warehouses, 'id');
        const markets = map(userInfo?.countries, 'id');

        setIsSelectAllMerchants(merchants?.length === merchantsData?.data?.merchants?.length);
        setIsSelectAllWarehouses(warehouses?.length === warehousesData?.data?.warehouses?.length);
        setIsSelectAllMarket(markets?.length === marketsData?.length);
        setIsSelectAllSupplier(selected?.suppliers?.length === suppliers?.length)
    }, [merchantsData?.data, warehousesData?.data, marketsData, userInfo,suppliers,selected])


    const handleChangeMerchants = ids => {
        setSelected({
            ...selected,
            merchants: ids,
        });
        setIsSelectAllMerchants(ids?.length === merchantsData?.data?.merchants?.length);
    };

    const handleSelectAllMerchants = event => {
        const { checked } = event.target;
        const merchantIds = map(merchantsData?.data?.merchants, 'merchant.id');

        setSelected({
            ...selected,
            merchants: checked ? merchantIds : [],
        });
        form.setFieldsValue({
            merchant_ids: checked ? merchantIds : [],
        });
        setIsSelectAllMerchants(checked);
    };

    const handleChangeWarehouses = ids => {
        setSelected({
            ...selected,
            warehouses: ids,
        });

        setIsSelectAllWarehouses(ids?.length === warehousesData?.data?.warehouses?.length);
    };

    const handleChangeMarkets = ids => {
        setSelected({
            ...selected,
            markets: ids,
        });
        setIsSelectAllMarket(ids?.length === marketsData?.length);
    };

    const handleChangeSuppliers = ids => {
        setSelected({
            ...selected,
            suppliers: ids,
        });
        setIsSelectAllSupplier(ids?.length === suppliers?.length);
    };

    const handleSelectAllWarehouses = event => {
        const { checked } = event.target;
        const warehouseIds = map(warehousesData?.data?.warehouses, 'warehouse.id');

        setSelected({
            ...selected,
            warehouses: checked ? warehouseIds : [],
        });
        form.setFieldsValue({
            warehouse_ids: checked ? warehouseIds : [],
        });
        setIsSelectAllWarehouses(checked);
    };

    const handleSelectAllMarket = e => {
        const { checked } = e.target;
        const marketIds = map(marketsData, 'id');

        setSelected({
            ...selected,
            markets: checked ? marketIds : [],
        });
        form.setFieldsValue({
            market_ids: checked ? marketIds : [],
        });
        setIsSelectAllMarket(checked);
    };

    const handleSelectAllSupplier = e => {
        const { checked } = e.target;
        const supplierIds = map(suppliers, 'supplier.id');

        setSelected({
            ...selected,
            suppliers: checked ? supplierIds : [],
        });
        form.setFieldsValue({
            supplier_ids: checked ? supplierIds : [],
        });
        setIndeterminate({...indeterminate, supplier:false})
        setIsSelectAllSupplier(checked);
    };

    function handleCancel() {
        setVisible(false);
    }

    const handleFinish = values => {
        switch (activeTabKey) {
            case 'merchants':
                return updateMerchants({
                    user_id: userId,
                    merchant_ids: values.merchant_ids,
                });
            case 'warehouses':
                return updateWarehouses({
                    user_id: userId,
                    warehouse_ids: values.warehouse_ids,
                });
            case 'markets':
                return updateMarkets({
                    user_id: userId,
                    country_ids: values.market_ids,
                });
            case 'suppliers':
                    return updateSuppliers({
                        user_id: userId,
                        supplier_ids: values.supplier_ids,
                    });   
            default:
                return false;
        }
    };

    useEffect(() => {
        const indeterminate = {
            merchant: !!(
                selected?.merchants?.length && selected?.merchants?.length < merchantsData?.data?.merchants?.length
            ),
            warehouse: !!(
                selected?.warehouses?.length && selected?.warehouses?.length < warehousesData?.data?.warehouses?.length
            ),
            market: !!(selected?.markets?.length && selected?.markets?.length < marketsData?.length),
            supplier: !isEmpty(selected?.suppliers) && selected?.suppliers?.length  < suppliers?.length
        };
        setIndeterminate(indeterminate);
    }, [merchantsData, selected, warehousesData, marketsData,suppliers]);

    useEffect(() => {
        form.setFieldsValue({
            merchant_ids: map(userInfo?.merchants, 'id'),
            warehouse_ids: map(userInfo?.warehouses, 'id'),
            market_ids: map(userInfo?.countries, 'id'),
            supplier_ids: map(userInfo?.suppliers, 'id'),
        });
    }, [form, userInfo]);

    function renderTitleModal() {
        let message = '';
        switch (activeTabKey) {
            case 'merchants':
                message = t(`user:title.update_seller`);
                break;
            case 'warehouses':
                message = t(`user:title.update_warehouse`);
                break;
            case 'markets':
                message = t(`user:title.update_market`);
                break;
            case 'suppliers':
                    message = t(`user:title.update_supplier`);
                    break;
            default:
                message = t(`user:title.update_seller`);
        }
        return message;
    }

    return (
        <Modal
            title={renderTitleModal()}
            visible={true}
            width={800}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    {t('btn.cancel')}
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={updatingMerchants || updatingWarehouses || updatingMarkets || updatingSuppliers}
                    onClick={form.submit}
                >
                    {t('btn.update')}
                </Button>,
            ]}
            onCancel={handleCancel}
        >
            <Form form={form} onFinish={handleFinish}>
                <Spin spinning={loadingMerchants}>
                    <Form.Item className="mb-1">
                        <Row>
                            <Col span={8}>
                                <p style={{ fontWeight: 500 }}>{t('user:label.username')}:</p>
                            </Col>
                            <Col span={12}>
                                <p style={{ fontWeight: 500 }}>{userInfo?.user?.username}</p>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item className="mb-1">
                        <Row>
                            <Col span={8}>
                                <p style={{ fontWeight: 500 }}>{t('user:label.full_name')}:</p>
                            </Col>
                            <Col span={12}>
                                <p style={{ fontWeight: 500 }}>{userInfo?.user?.name}</p>
                            </Col>
                        </Row>
                    </Form.Item>
                    <Form.Item>
                        <Row>
                            <Col span={8}>
                                <p style={{ fontWeight: 500 }}>{t('user:label.email')}:</p>
                            </Col>
                            <Col span={12}>
                                <p style={{ fontWeight: 500 }}>{userInfo?.user?.email}</p>
                            </Col>
                        </Row>
                    </Form.Item>
                </Spin>


                <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
                    <TabPane className={styles['item-list']} tab={t('vendor')} key="merchants">
                        <Spin spinning={loadingMerchants}>
                            <Form.Item>
                                <Checkbox
                                    indeterminate={indeterminate.merchant}
                                    onChange={handleSelectAllMerchants}
                                    checked={isSelectAllMerchants}
                                >
                                    {t('btn.check_all')}
                                </Checkbox>
                                <Divider className="my-2" />
                                <Form.Item name="merchant_ids">
                                    <Checkbox.Group onChange={handleChangeMerchants}>
                                        <Row gutter={20}>
                                            {merchantsData?.data?.merchants.map(merchant => {
                                                const name = merchant?.merchant?.name;
                                                const code = merchant?.merchant?.code;

                                                return (
                                                    <Col
                                                        key={merchant?.merchant?.id}
                                                        xs={{ span: 24 }}
                                                        md={{ span: 12 }}
                                                    >
                                                        <Checkbox
                                                            value={parseInt(merchant?.merchant?.id)}
                                                            style={{ lineHeight: '32px' }}
                                                            checked
                                                        >
                                                            {`${code}${name ? ` - ${name}` : ''}`}
                                                        </Checkbox>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Form.Item>
                        </Spin>
                    </TabPane>
                    <TabPane className={styles['item-list']} tab={t('warehouse')} key="warehouses">
                        <Spin spinning={loadingWarehouses}>
                            <Form.Item>
                                <Checkbox
                                    indeterminate={indeterminate.warehouse}
                                    onChange={handleSelectAllWarehouses}
                                    checked={isSelectAllWarehouses}
                                >
                                    {t('btn.check_all')}
                                </Checkbox>
                                <Divider className="my-2" />
                                <Form.Item name="warehouse_ids">
                                    <Checkbox.Group onChange={handleChangeWarehouses}>
                                        <Row gutter={20}>
                                            {warehousesData?.data?.warehouses.map(warehouse => {
                                                const name = warehouse?.warehouse?.name;
                                                const code = warehouse?.warehouse?.code;

                                                return (
                                                    <Col
                                                        key={warehouse?.warehouse?.id}
                                                        xs={{ span: 24 }}
                                                        md={{ span: 12 }}
                                                    >
                                                        <Checkbox
                                                            value={parseInt(warehouse?.warehouse?.id)}
                                                            style={{ lineHeight: '32px' }}
                                                            checked={true}
                                                        >
                                                            {`${code}${name ? ` - ${name}` : ''}`}
                                                        </Checkbox>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Form.Item>
                        </Spin>
                    </TabPane>
                    <TabPane className={styles['item-list']} tab={t('label.market')} key="markets">
                        <Form.Item>
                            <Checkbox
                                indeterminate={indeterminate.market}
                                onChange={handleSelectAllMarket}
                                checked={isSelectAllMarket}
                            >
                                {t('btn.check_all')}
                            </Checkbox>
                            <Divider className="my-2" />
                            <Form.Item name="market_ids">
                                <Checkbox.Group onChange={handleChangeMarkets}>
                                    <Row gutter={20}>
                                        {marketsData?.map(market => {
                                            return (
                                                <Col key={market?.id} xs={{ span: 24 }} md={{ span: 12 }}>
                                                    <Checkbox
                                                        value={parseInt(market?.id)}
                                                        style={{ lineHeight: '32px' }}
                                                        checked={true}
                                                    >
                                                        {market?.label}
                                                    </Checkbox>
                                                </Col>
                                            );
                                        })}
                                    </Row>
                                </Checkbox.Group>
                            </Form.Item>
                        </Form.Item>
                    </TabPane>
                    {auth.can(permissions.ADMIN_ASSIGN_SUPPLIER) && <TabPane className={styles['item-list']} tab={t('product:label.supplier')} key="suppliers">
                        <Spin spinning={loadingSuppliers}> 
                            <Form.Item>
                                <Checkbox
                                    indeterminate={indeterminate.supplier}
                                    onChange={handleSelectAllSupplier}
                                    checked={isSelectAllSupplier}
                                >
                                    {t('btn.check_all')}
                                </Checkbox>
                                <Divider className="my-2" />
                                <Form.Item name="supplier_ids">
                                    <Checkbox.Group onChange={handleChangeSuppliers} className="w-100">
                                        <Row gutter={20}>
                                            {suppliers?.map(supplier => {
                                                return (
                                                    <Col key={supplier?.supplier?.id} xs={{ span: 24 }} md={{ span: 12 }}>
                                                        <Checkbox
                                                            value={parseInt(supplier?.supplier?.id)}
                                                            style={{ lineHeight: '32px' }}
                                                            checked={true}
                                                        >
                                                         
                                                            {`${supplier?.supplier?.name} - ${supplier?.supplier?.code}`}
                                                        </Checkbox>
                                                    </Col>
                                                );
                                            })}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                        </Form.Item>
                        </Spin>
                       
                    </TabPane>}
                </Tabs>
            </Form>
        </Modal>
    );
}

export default UpdateUser;
