import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Modal } from 'antd'
import { get, trim } from 'lodash'
import React, { useState } from 'react'

import InputNotSpace from '@Modules/App/Utilities/InputNotSpace'
import { INPUT_INVALID } from '@Modules/PurchasingPackage/services/constants'

import { t } from '@System/i18n'
import notification from '@System/notification'

import apiService from '../services/api'

const AddSupplier = ({ refetch }) => {
    const [form] = Form.useForm()
    const [visibleAdd, setVisibleAdd] = useState(false)

    const [loading, setLoading] = useState(false)

    const onCreateSupplier = () => {
        form.validateFields().then(values => {
            setLoading(true)
            apiService
                .createSupplier({ ...values })
                .then(result => {
                    onCancel()
                    refetch()
                    notification.success(t('config:supplier.message.create_supplier_success'))
                })
                .catch(err => {
                    const code = get(err, 'response.data.code')
                    const data = get(err, 'response.data.data')

                    if (code === INPUT_INVALID) {
                        Object.entries(data).forEach(([key, value]) => {
                            notification.error(
                                t(`config:supplier.message.${Object.keys(value)[0]}`, {
                                    attribute: t(`config:supplier.label.${key}`),
                                })
                            )
                        })
                    } else {
                        notification.error(t('config:supplier.message.create_supplier_fail'))
                    }
                })
                .finally(() => {
                    setLoading(false)
                })
        })
    }
    const handleVisibleAdd = () => {
        setVisibleAdd(true)
    }

    const onCancel = () => {
        form.resetFields()
        setVisibleAdd(false)
    }

    return (
        <>
            <Button
                className="_add-btn"
                type="primary"
                onClick={handleVisibleAdd}
            >
                <PlusOutlined />
                {t('btn.create')}
            </Button>
            {visibleAdd && (
                <Modal
                    title={t('config:supplier.add')}
                    visible={true}
                    onOk={onCreateSupplier}
                    onCancel={onCancel}
                    cancelText={t('btn.cancel')}
                    okText={t('btn.ok')}
                    okButtonProps={{
                        loading,
                    }}
                >
                    <Form
                        layout="vertical"
                        form={form}
                    >
                        <Form.Item
                            label={t('config:supplier.label.code')}
                            name="code"
                            rules={[{ required: true, message: t('order:message.required', { attribute: t('config:supplier.label.code') }) }]}
                        >
                            <InputNotSpace placeholder={t('config:supplier.placeholder.code')} />
                        </Form.Item>
                        <Form.Item
                            label={t('config:supplier.label.name')}
                            name="name"
                            rules={[{ required: true, message: t('order:message.required', { attribute: t('config:supplier.label.name') }) }]}
                        >
                            <Input
                                placeholder={t('config:supplier.placeholder.name')}
                                onBlur={e => form.setFieldsValue({ name: trim(e.target.value) })}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t('address')}
                            name="address"
                            rules={[
                                {
                                    max: 254,
                                    message: t('config:supplier.message.less_than_char', {
                                        attribute: t('address'),
                                        count: 254,
                                    }),
                                },
                            ]}
                        >
                            <Input placeholder={t('warehouse:placeholder.address')} />
                        </Form.Item>
                        <Form.Item
                            label={t('contact')}
                            name="contact"
                            rules={[
                                {
                                    max: 254,
                                    message: t('config:supplier.message.less_than_char', {
                                        attribute: t('contact'),
                                        count: 254,
                                    }),
                                },
                            ]}
                        >
                            <Input placeholder={t('config:supplier.placeholder.contact')} />
                        </Form.Item>
                        <Form.Item
                            label={t('note')}
                            name="note"
                        >
                            <Input.TextArea
                                placeholder={t('warehouse:placeholder.note')}
                                rows={3}
                                showCount
                                maxLength={254}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            )}
        </>
    )
}

export default AddSupplier
