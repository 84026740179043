import { Col, Row, Input, DatePicker, Modal } from 'antd';
import { Form } from 'antd';
import moment from 'moment';
import React  from 'react';

import { getVar } from '@System/support/helpers';

import { t } from '../../../../../system/i18n';
import CurrencyFormat from '../../../../../system/support/CurrencyFormat';
import SelectMerchants from '../../../../Merchant/components/SelectMerchants';
import SelectShippingPartner from '../../../../ShippingPartners/components/SelectShippingPartner';
import InputNotSpace from '@Modules/App/Utilities/InputNotSpace';

export default function OrderInfo({ validatedRules, setCurrentSeller, sellers, currentSeller, form, setOrderSkus }) {
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current > moment().endOf('day');
    }

    /**
     * Thay đổi seller thì bắn sự kiện cập nhật lại giá sku trong đơn theo seller
     */
    function changeSeller(value) {
        if (currentSeller) {
            Modal.confirm({
                content: t('order:message.confirm_price_all_sku'),
                okText: t('btn.confirm'),
                cancelText: t('btn.cancel'),
                onOk: () => resetSeller(value),
                onCancel: () => form.setFields([{ name: 'merchant_id', value: currentSeller.id }]),
            });
        } else {
            resetSeller(value);
        }
    }

    /**
     * Cập nhật lại seller hiện tại khi có thay đổi
     */
    function resetSeller(value) {
        let newSeller = sellers.find(item => item.id === parseInt(value));
        CurrencyFormat.setCurrency(newSeller.currency);
        setCurrentSeller(newSeller);
        setOrderSkus([]);
    }
    return (
        <div className="border box-shadow bg-white pt-3 pr-4 pl-4 pb-4">
            <h2 className="title-box mb-2">{t('order:label.order_info')}</h2>
            <Row gutter={20}>
                <Col xs={{ span: 8 }}>
                    <Form.Item
                        className="mb-0 order-create-merchant_id"
                        name="merchant_id"
                        label={t('order:label.merchant_id')}
                        rules={validatedRules.merchant_id}
                    >
                        <SelectMerchants
                            name="merchant_id"
                            placeholder={t('order:placeholder.merchant_id')}
                            onSelect={changeSeller}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 8 }}>
                    <Form.Item
                        className="mb-0 order-create-order_code"
                        name="code"
                        label={t('order:label.order_code')}
                        rules={validatedRules.code}
                    >
                        <InputNotSpace name="code" placeholder={t('order:placeholder.order_code')} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 8 }}>
                    <Form.Item className="mb-0 order-create-shipping_partner_id" name="shipping_partner_id" label={t('order:label.shipping_partner_id')}>
                        <SelectShippingPartner
                            name="shipping_partner_id"
                            placeholder={t('order:placeholder.shipping_partner_id')}
                            location_id={getVar(currentSeller, 'location_id', "not_found")}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 8 }}>
                    <Form.Item className="mb-0 mt-2 order-create-campaign" name="campaign" label={t('order:label.campaign')}>
                        <Input name="campaign" placeholder={t('order:placeholder.campaign')} />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 8 }}>
                    <Form.Item
                        className="mb-0 mt-2 order-create-created_at_origin"
                        name="created_at_origin"
                        label={t('order:label.created_at_origin')}
                    >
                        <DatePicker
                            name="created_at_origin order-create-created_at_origin"
                            placeholder={t('order:placeholder.created_at_origin')}
                            style={{ width: '100%' }}
                            format={'DD/MM/YYYY'}
                            disabledDate={disabledDate}
                        />
                    </Form.Item>
                </Col>
                <Col xs={{ span: 8 }}>
                    <Form.Item className="mb-0 mt-2 order-create-created_at_origin" name="description" label={t('order:label.description')}>
                        <Input name="description" placeholder={t('order:placeholder.description')} />
                    </Form.Item>
                </Col>
                {/*<Col xs={{span: 6}}>*/}
                {/*    <p className="miss-label order-status p-1 text-uppercase">{t(`order:status.DRAFT`)}</p>*/}
                {/*</Col>*/}
            </Row>
        </div>
    );
}
