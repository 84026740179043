import React from 'react';
import { Provider } from 'react-redux';

import store from '@State';

import RoutingProvider from './system/routing/RoutingProvider';

const Providers = () => {
    return (
        <Provider store={store}>
            <RoutingProvider />
        </Provider>
    );
};

export default Providers;
