export const API = {
    REQUEST: 'API.REQUEST',
    SUCCESS: 'API.SUCCESS',
    ERROR: 'API.ERROR',
};

export const HTTP_STATUS = {
    BAD_REQUEST: 400,
    UNAUTHENTICATED: 401,
    UNAUTHORIZED: 403,
};

export const PREFIX_URL = {
    PROJECT: "/projects",
    TICKET: "/tickets",
    USER: "/users",
};

export const COUNTRY_TELEPHONE_CODE = [
    {code: "+84", name: "VietNam", default: true},
    {code: "+86", name: "China"},
    {code: "+852", name: "HongKong"},
    {code: "+853", name: "Macao"},
    {code: "+886", name: "Taiwan"}
];

export const LIST_FIELD_IS_FORMAT_DATE  = [
    "expired_at"
];
