export const STATISTIC_CODE = ['cod', 'service_amount', 'shipping_amount', 'remaining_amount', 'return_amount']

export const STATUS_FREIGHT_BIll_INVENTORY = {
    ALL: 'all',
    CORRECT: 'correct',
    INCORRECT: 'incorrect',
}

export const STATUS_FINANCE_CONTROL_DOCUMENT = {
    DRAFT: 'DRAFT',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
}

export const STATUS_FINANCE_CONTROL_DOCUMENT_COLORS = {
    DRAFT: '#ffa634',
    COMPLETED: '#09b8af',
    CANCELLED: '#bdbdbd',
}

export const STATUS_FREIGHT_BIll_INVENTORY_COLORS = {
    correct: '#09b8af',
    incorrect: '#bd0313',
}

export const DOCUMENT_CONTROL_STATUS = {
    DRAFT: 'DRAFT',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    NONE: 'NONE',
}

export const KEY_SUMMARY_DEBIT_SHIPPING = ['debts_paid', 'expected', 'unpaid_debt']

export const currencyInit = {
    code: 'NONE',
    date: '',
    decimal_separator: '.',
    format: '{amount}',
    precision: 2,
    thousands_separator: ',',
    id: 0,
    updated_at: '',
}

export const FINANCE_SHIPPING_STATUS = {
    INIT: 'INIT',
    WAITING_COLLECT: 'WAITING_COLLECT',
    RECONCILIATION: 'RECONCILIATION',
    COLLECTED: 'COLLECTED',
}

export const FINANCE_SHIPPING_STATUS_COLOR = {
    INIT: "blue",
    WAITING_COLLECT: '#ffa634',
    RECONCILIATION: '#09b8af',
    COLLECTED: 'purple',
}