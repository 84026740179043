import { useQuery } from 'react-query';

import api from '@Modules/Finance/services/api';

import processResponseError from '@System/api/processResponseError';

const useListFinanceOrderQuery = params => {
    return useQuery(['list-finance-order-query', params], () => api.getOrderFinance(params), {
        retry: false,
        onError: err => {
            processResponseError(err);
        },
    });
};

export default useListFinanceOrderQuery;
