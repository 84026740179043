import { DeleteOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Modal, Table, notification } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import { dateFormatter } from '@Modules/App/services';
import { auth } from '@Modules/Auth/services';
import permissions from '@Modules/Auth/services/permissions';
import api from '@Modules/Order/services/api';

import { t } from '@System/i18n';

import CustomizedPagination from '../../../App/CustomizedPagination';
import TableEmpty from '../../../App/Utilities/TableEmpty';

export default function List({
    deleteInvalidOrder,
    filters,
    loading,
    orders,
    pagination,
    resyncingMulti,
    addFilter,
    fetchFailedOrders,
    onResyncMulti,
}) {
    const [reSyncing, setReSyncing] = useState(null);

    const handleSync = useCallback(
        id => {
            setReSyncing(id);
            api.reSyncOrderError(id)
                .then(res => {
                    const order = res?.data?.order;

                    fetchFailedOrders();

                    if (!order) {
                        notification.error({ message: t('order:message.handle_error_order.resync_order_faild') });
                    } else {
                        notification.success({ message: t('order:message.handle_error_order.resync_order_success') });
                    }
                })
                .catch(err => {
                    const status = get(err, 'response.status', undefined);
                    if (status === 403) {
                        notification.error({ message: t('common:message.403') });
                    } else if (status === 404) {
                        notification.error({ message: t('common:message.404') });
                    } else {
                        notification.error({ message: t('common:message.fetch_fail') });
                    }
                })
                .finally(() => setReSyncing(null));
        },
        [fetchFailedOrders]
    );

    const handleDelete = useCallback(
        id => {
            Modal.confirm({
                title: t('order:delete.confirm'),
                cancelText: t('btn.cancel'),
                okText: t('btn.ok'),
                onOk: deleteInvalidOrder.bind(undefined, id),
            });
        },
        [deleteInvalidOrder]
    );

    const getColumns = useMemo(
        () => [
            {
                className: '_order-code text-nowrap',
                title: t('order:label.order_code'),
                dataIndex: 'code',
                key: 'code',
            },
            {
                className: '_shop-id text-nowrap',
                title: t('order:label.shop_id'),
                dataIndex: ['payload', 'shop_id'],
                key: 'shop_id',
            },
            {
                className: '_created-at text-nowrap',
                title: t('order:label.created_at'),
                dataIndex: 'created_at',
                key: 'created_at',
                render: text => (text ? dateFormatter.full(text) : ''),
            },
            {
                className: '_reason-failed',
                title: t('order:label.reason_failed'),
                dataIndex: 'error_code',
                key: 'error_code',
                render: text => t(`order:ERROR_ORDER_CODE.${text}`),
            },
            {
                className: '_reason-failed-merchant',
                title: t('order:label.vendor_code'),
                dataIndex: ['payload', 'merchant'],
                key: 'payload',
            },
            {
                className: '_reason-failed',
                title: t('order:label.error_content'),
                dataIndex: 'errors',
                key: 'errors',
                render: (_, record) => {
                    const errors = record?.errors;
                    const error_messages = [];
                    Object.keys(errors).forEach(item => {
                        Object.keys(errors[item]).forEach(error => error_messages.push(`${item} ${error}`));
                    });
                    return error_messages.join(<br />);
                },
            },
            {
                className: '_action-sync text-nowrap text-right',
                dataIndex: 'id',
                key: 'id',
                width: '100px',
                render: text => {
                    return (
                        <>
                            <Button
                                type="primary"
                                icon={<SyncOutlined />}
                                loading={reSyncing === text}
                                onClick={handleSync.bind(this, text)}
                            >
                                {t('order:btn.resync_order')}
                            </Button>

                            {auth.can(permissions.ORDER_REMOVE_FAILED_ORDER) && (
                                <Button
                                    className="ml-3"
                                    icon={<DeleteOutlined />}
                                    type="danger"
                                    onClick={handleDelete.bind(this, text)}
                                >
                                    {t('btn.delete')}
                                </Button>
                            )}
                        </>
                    );
                },
            },
        ],
        [reSyncing, handleDelete, handleSync]
    );

    return (
        <div className="bg-white rounded-12 p-4 mt-4">
            <div className="data-box">
                <div className="data-box--title d-flex justify-content-between align-items-center mb-3">
                    <h3 className="text-fz-18">
                        {t('title.list-orders')} ({isEmpty(pagination) ? 0 : pagination.total})
                    </h3>
                    <Button disabled={!orders?.length} loading={resyncingMulti} type="primary" icon={<SyncOutlined />} onClick={onResyncMulti}>
                        {t('order:btn.resync_all_orders_in_page')}
                    </Button>
                </div>
                <div className="data-box--body">
                    <Table
                        className="_list-order-failed-table pb-3"
                        dataSource={orders}
                        rowKey={record => record.id}
                        columns={getColumns}
                        pagination={false}
                        loading={loading}
                        locale={{ emptyText: <TableEmpty className="_data-empty" loading={loading} /> }}
                        scroll={{ x: true }}
                    />
                    <CustomizedPagination pagination={pagination} addFilter={addFilter} filters={filters} />
                </div>
            </div>
        </div>
    );
}
