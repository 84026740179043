import { api } from '@System/api';

const BASE_URL = 'warehouse-stocks';

export default {
    warehouseStocks: params =>
        api.get(BASE_URL, {
            params,
        }),
};
