export const DROPSHIPPING_STATUS = {
    WAITING_FOR_QUOTE: 'WAITING_FOR_QUOTE',
    WAITING_CONFIRM: 'WAITING_CONFIRM',
    ON_SELL: 'ON_SELL',
    STOP_SELLING: 'STOP_SELLING',
};

export const PRODUCT_STATUS_COLORS = {
    NEW: '#cc8700',
    WAITING_FOR_QUOTE: '#f5222d',
    WAITING_CONFIRM: '#108ee9',
    ON_SELL: '#87d068',
    STOP_SELLING: '#bdbdbd',
};

export const SERVICES_PRICE_COLORS = {
    WAITING_CONFIRM: '#108ee9',
    ACTIVE: '#87d068',
    CANCELED: '#bdbdbd',
};

export const EVENTS = {
    RELOAD_DETAIL_PRODUCT: "RELOAD_DETAIL_PRODUCT",
};
