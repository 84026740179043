import { Select } from 'antd'
import React from 'react'

import { FINANCE_SHIPPING_STATUS } from '@Modules/Finance/services/constants'

import { t } from '@System/i18n'

const { Option } = Select

const SelectFinanceShippingStatus = ({ onChange, value }) => {
    return (
        <Select
            mode="multiple"
            allowClear
            value={value || undefined}
            placeholder={t('finance:placeholder.select_status')}
            onChange={onChange}
        >
            {Object.entries(FINANCE_SHIPPING_STATUS).map(([key, value]) => {
                return (
                    <Option
                        key={key}
                        value={value}
                    >
                        {t(`finance:FINANCE_SHIPPING_STATUS.${value}`)}
                    </Option>
                )
            })}
        </Select>
    )
}

export default SelectFinanceShippingStatus
