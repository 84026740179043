import { useQuery } from 'react-query'


import processResponseError from '@System/api/processResponseError'

import apiService from './../services/api'

const useDetailComboQuery = id =>
    useQuery(['combo-detail', id], () => apiService.getSkuComboDetail(id), {
        enabled: !!id,
        retry: false,
        onError: err => {
            processResponseError(err)
        },
    })

export default useDetailComboQuery
