import { Button, Col, Input, Modal, Row } from 'antd';
import React, { useEffect, useRef } from 'react';

import { catchErrors } from '@Modules/App/services/helpers';

import { events } from '@System/events';
import { t } from '@System/i18n';

import api from '../../../../services/api';
import { EVENTS } from '../../../../services/constants';
import OptionValues from './OptionValues';

export default function OptionItem({ option, index, updateOptions, removeOption }) {
    let { values } = option;
    const inputRef = useRef(null);

    useEffect(() => {
        return events.listen(EVENTS.RESET_PRODUCT_FORM, () => {
            inputRef.current.state.value = option.label;
        });

        // eslint-disable-next-line
    }, []);

    const updateOption = data => updateOptions(index, { ...option, ...data });

    function deleteOption() {
        if (option.id) {
            checkDeleteOption();
        } else {
            removeOption(index);
        }
    }

    function checkDeleteOption() {
        let valueIds = values.filter(value => value.id);
        api.checkDeleteValue({ optionsValueIds: valueIds.map(value => value.id) })
            .then(() => removeOption(index))
            .catch(catchErrors(t('message.delete_failed', { attribute: t('product:label.properties_value') }), showErrors));
    }

    function showErrors(errors) {
        if (errors.delete !== undefined && !errors.delete) {
            Modal.error({
                title: t('product:message.cant_delete'),
                content: t('product:message.delete_content'),
            });
        }
    }

    return (
        <Row gutter={15} className="d-flex align-items-center mt-3 mb-3">
            <Col lg={{ span: 4 }} span={24}>
                <Input
                    className="_product-option-item-name"
                    defaultValue={option.label}
                    onPressEnter={e => updateOption({ label: e.target.value })}
                    onBlur={e => updateOption({ label: e.target.value })}
                    placeholder={t('product:placeholder.option')}
                    ref={inputRef}
                />
            </Col>
            <Col lg={{ span: 20 }} span={24} className="d-flex justify-content-between align-items-center">
                <OptionValues values={values} updateOption={updateOption} />
                <Button className="_product-option-item-btn-delete" onClick={deleteOption}>{t('btn.delete')}</Button>
            </Col>
        </Row>
    );
}
