import { CheckOutlined, DownOutlined, EditOutlined, UndoOutlined } from '@ant-design/icons'
import { Table, Button, Space, Badge, Input, Row, Col, Avatar, Typography, Tooltip, Menu, Alert, Dropdown } from 'antd'
import { get, filter, isEmpty, includes, map, find, isArray } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Box from '@Modules/App/Box'
import CustomizedPagination from '@Modules/App/CustomizedPagination'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import { SHIPPING_PARTNER_INFO } from '@Modules/App/services/constants'
import { ORDER_PACKING_STATUS } from '@Modules/Order/services/constants'
import api from '@Modules/ScanOrder/services/api'
import { SCAN_PACKING_PURCHASING_PACKAGE_TYPE } from '@Modules/ScanOrder/services/constants'
import UpdateServiceCreateDocument from '@Modules/Services/components/UpdateServiceCreateDocument'
import apiService from '@Modules/Services/services/api'
import { SERVICE_TYPE } from '@Modules/Services/services/constants'

import { t } from '@System/i18n'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

import styles from '../scan-packing-purchasing-package.module.scss'

const { Title } = Typography

function OrderList(props) {
    const { storeData } = props
    const { barcode_type, isConfirm, countryId } = storeData
    const [inputSearch, setInputSearch] = useState()
    // const [showService, setShowService] = useState(false);
    const [services, setServices] = useState([])
    const [merchantRowId, setMerchantRowId] = useState()
    const [showFormUpdateServices, setShowFormUpdateServices] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const hasSelected = selectedRowKeys.length > 0
    const rowSelection = { selectedRowKeys, onChange: selectedRowKeys => setSelectedRowKeys(selectedRowKeys) }
    const {
        error,
        loading,
        pagination,
        dataSource,
        statusAllow,
        loadingSubmit,
        handleOk,
        handleCancel,
        handleChangePage,
        handleSearchTable,
        removeScanOrderInfo,
        reloadData,
    } = props

    // useEffect(() => {
    //     if (countryId) {
    //         apiService.list({ country_id: countryId, hidden_init_service: true }).then(res => {
    //             let services = getVar(res, 'data.services', []);
    //             services = services.filter(item => {
    //                 const type = getVar(item, 'service.type', '');
    //                 return type === SERVICE_TYPE.EXPORT;
    //             });
    //             if (!isEmpty(services)) setShowService(true);
    //             setServices(services);
    //         });
    //     } else {
    //         setServices([]);
    //     }
    // }, [countryId]);

    function handleRemoveOrderPackingInfoSelected() {
        removeScanOrderInfo(selectedRowKeys)
        setSelectedRowKeys([])
    }

    function renderStatusInfo(status) {
        const isAllow = includes(statusAllow, status)
        if (status) status = status.toUpperCase()
        const className = isAllow || isConfirm ? '' : 'text-danger'
        const statusInfo = ORDER_PACKING_STATUS[status]
        return { className, ...statusInfo }
    }

    const titleAction = {
        start_new_turn: t('order:btn.start_new_confirm'),
        cancel: t('order:btn.reset_confirm'),
        ok: t('order:btn.confirm'),
    }

    function handleChangeServiceByRow(packingId, merchantId) {
        setMerchantRowId(merchantId)
        setSelectedRowKeys([packingId])
        setShowFormUpdateServices(true)
    }
    const columns = [
        {
            className: '_scan-order-list-of-lading-numerical-order',
            title: t('order:label.numerical_order'),
            dataIndex: 'stt',
            key: 'stt',
            responsive: ['xl'],
            render: (text, record, index) => {
                const pageSize = get(pagination, 'per_page')
                const page = get(pagination, 'page')
                return (page - 1) * pageSize + index + 1
            },
        },
        {
            className: 'avatar-shipping-partner _scan-order-list-of-lading-shipping-partner',
            dataIndex: 'shipping_partner',
            key: 'shipping_partner',
            width: '0px',
            render: text => {
                const code = get(text, 'code', '')
                const shipping_partner_info = get(SHIPPING_PARTNER_INFO, code, {})
                return (
                    <Tooltip title={text?.name}>
                        <Avatar
                            style={{ backgroundColor: `${get(shipping_partner_info, 'color', '')}` }}
                            className="flex-shrink-0"
                            size={20}
                        >
                            {get(shipping_partner_info, 'wildcard', '')}
                        </Avatar>
                    </Tooltip>
                )
            },
        },
        {
            className: '_scan-order-list-of-lading-freight-bill-code',
            title: t('order:label.freight_bill'),
            dataIndex: 'freight_bill_code',
            key: 'freight_bill_code',
            render: (text, record) => {
                const statusInfo = renderStatusInfo(record.status)
                return (
                    <Link
                        className={statusInfo.className}
                        to={url.to('orders.detail', { id: record.order_id })}
                    >
                        {text}
                    </Link>
                )
            },
        },
        {
            className: '_scan-order-list-of-lading-order-code',
            title: t('order:label.order_code'),
            dataIndex: 'order_code',
            key: 'order_code',
            render: (text, record) => {
                const statusInfo = renderStatusInfo(record.status)
                return (
                    <Link
                        className={statusInfo.className}
                        to={url.to('orders.detail', { id: record.order_id })}
                    >
                        {text}
                    </Link>
                )
            },
        },
        {
            className: '_scan-order-list-of-lading-sku-info',
            title: t('order:label.sku_info'),
            dataIndex: 'skus',
            key: 'skus',
            render: (text, record) => {
                const statusInfo = renderStatusInfo(record.status)
                const skus = record.skus
                return skus.map((item, index) => {
                    return (
                        <p key={index}>
                            <Link
                                className={statusInfo.className}
                                to={url.to('products.detail', { id: get(item, 'product_id', 0) })}
                            >
                                {get(item, 'code', '')}
                            </Link>
                        </p>
                    )
                })
            },
        },
        {
            className: '_scan-order-list-of-lading-quantity',
            title: t('order:label.quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => {
                const skus = record.skus
                return skus.map((item, index) => {
                    return <p key={index}>{get(item, 'quantity', 0)}</p>
                })
            },
        },

        {
            className: '_scan-order-list-of-lading-service',
            title: t('order:label.service'),
            dataIndex: 'services',
            key: 'services',
            render: (text, record) => {
                const edited = (
                    <>
                        {!isConfirm && (
                            <Tooltip title={t('order:btn.select_service')}>
                                <EditOutlined
                                    className="mx-2"
                                    onClick={() => handleChangeServiceByRow(record.key, record?.merchant_id)}
                                />
                            </Tooltip>
                        )}
                    </>
                )

                if (isArray(text) && text.length > 0) {
                    return map(text, (item, index) => {
                        const service_name = getVar(item, 'service_name', '')
                        const service_price_label = getVar(item, 'service_price_label', '')
                        return (
                            <p
                                key={index}
                                className="whitespace-nowrap"
                            >
                                <Badge status="default" />
                                {`${service_name ? `${service_name} - ` : ''} ${service_price_label}`}
                                {edited}
                            </p>
                        )
                    })
                }

                return edited
            },
        },
        {
            className: 'text-right',
            dataIndex: 'id',
            key: 'action',
            render: text => {
                return (
                    <Button
                        className="_scan-order-list-of-lading-btn-action-remove-info"
                        style={{ backgroundColor: '#EAEEFF', color: '#4551BB', borderRadius: '4px' }}
                        onClick={() => removeScanOrderInfo([text])}
                    >
                        {t('btn.delete')}
                    </Button>
                )
            },
        },
    ]

    const renderColumn = () => {
        let tableColumns = columns
        if (isConfirm) {
            tableColumns = filter(columns, item => item.key !== 'action')
        }
        if (barcode_type === SCAN_PACKING_PURCHASING_PACKAGE_TYPE.FREIGHT_BILL) {
            return tableColumns
        } else {
            return filter(tableColumns, item => item.key !== 'freight_bill_code')
        }
    }

    function handleReset() {
        handleCancel()
        handleSearchTable(undefined)
        setInputSearch(undefined)
    }

    const selectedAction = (
        <Menu className="lading-dropdown-action">
            {/* {showService && (
                <Menu.Item
                    className="mb-3 mb-xl-0 _scan-order-list-of-lading-btn-change-service"
                    onClick={() => setShowFormUpdateServices(true)}
                >
                    {t('order:btn.select_service')}
                </Menu.Item>
            )} */}
            <Menu.Item
                key={1}
                className="_scan-order-list-of-lading-btn-remove-selected mb-3 mb-xl-0"
                onClick={handleRemoveOrderPackingInfoSelected}
            >
                {t('order:btn.remove_selected')}
            </Menu.Item>
        </Menu>
    )

    function makeServicePriceIdsForSelectedOrderPackings() {
        return dataSource
            .filter(orderPacking => selectedRowKeys.includes(orderPacking.key))
            .reduce((prices, orderPacking) => [...prices, ...orderPacking.services], [])
            .map(price => price.service_price_id)
    }

    function handleUpdateService(priceIds) {
        return api.updateOrderPackingsServices({ order_packing_ids: selectedRowKeys, service_price_ids: priceIds })
    }

    return (
        <Box className={styles.main}>
            <div className="d-flex flex-wrap justify-content-between pb-3 align-items-center">
                <Space className="order-0 mb-3 mb-xl-0">
                    <Title level={3}>
                        {barcode_type
                            ? t(`packing:title.barcode_type.${SCAN_PACKING_PURCHASING_PACKAGE_TYPE[barcode_type]}`)
                            : t(`packing:title.barcode_type.FREIGHT_BILL`)}
                    </Title>
                    <span className="number-circle _scan-order-list-of-lading-total">{props.totalData}</span>
                </Space>

                <div className="order-1 order-lg-2">
                    {isConfirm && (
                        <Button
                            type="primary"
                            onClick={handleCancel}
                            icon={<UndoOutlined />}
                            className="mr-2 mr-xs-0 mb-3 mb-xl-0 _scan-order-list-of-lading-btn-start-new-turn"
                        >
                            {titleAction.start_new_turn}
                        </Button>
                    )}
                    {!!(dataSource.length > 0 && !isConfirm) && (
                        <>
                            <Button
                                onClick={handleReset}
                                icon={<UndoOutlined />}
                                className="mr-2 mr-xs-0 mb-3 mb-xl-0 _scan-order-list-of-lading-btn-cancel"
                            >
                                {titleAction.cancel}
                            </Button>
                            <Button
                                onClick={handleOk}
                                loading={loadingSubmit}
                                type="primary"
                                icon={<CheckOutlined />}
                                className="_scan-order-list-of-lading-btn-ok"
                            >
                                {titleAction.ok}
                            </Button>
                        </>
                    )}
                </div>
            </div>

            {error && (
                <div className="p-2 pb-3 pt-0">
                    <Alert
                        className="p-2 pb-3 pt-0"
                        description={error}
                        type="error"
                        closable
                    />
                </div>
            )}

            <div className="border-top">
                <Row className={'justify-content-end p-2'}>
                    <Col
                        xs={24}
                        md={{ span: 12 }}
                        lg={{ span: 16 }}
                    >
                        {hasSelected && dataSource.length > 0 && (
                            <>
                                {t(`packing:title.selected.${SCAN_PACKING_PURCHASING_PACKAGE_TYPE[barcode_type]}`, {
                                    count: selectedRowKeys.length,
                                })}
                                <Dropdown
                                    overlay={selectedAction}
                                    trigger={['click']}
                                    className="_dropdown-action"
                                >
                                    <a
                                        className="ant-dropdown-link ml-2 "
                                        onClick={e => e.preventDefault()}
                                    >
                                        {t('choose_action')} <DownOutlined />
                                    </a>
                                </Dropdown>
                            </>
                        )}
                    </Col>
                    <Col
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                    >
                        <Input.Search
                            value={inputSearch}
                            onChange={e => setInputSearch(e.target.value)}
                            placeholder={t(`order:placeholder.search_code`)}
                            onSearch={() => handleSearchTable(inputSearch)}
                            enterButton
                            autoComplete="off"
                            className="_scan-order-list-of-lading-btn-search"
                        />
                    </Col>
                </Row>
                <Table
                    loading={loading}
                    className="table-list-of-lading _scan-order-list-of-lading"
                    rowClassName={record => {
                        const statusInfo = renderStatusInfo(record.status)
                        return `align-top ${statusInfo.className}`
                    }}
                    rowSelection={isConfirm ? false : rowSelection}
                    dataSource={dataSource}
                    columns={renderColumn()}
                    pagination={false}
                    scroll={{ x: 576 }}
                    locale={{
                        emptyText: (
                            <TableEmpty
                                className="_scan-order-list-of-lading-no-data"
                                loading={loading}
                            />
                        ),
                    }}
                />
                <CustomizedPagination
                    pagination={pagination}
                    filters={pagination}
                    addFilter={handleChangePage}
                />
            </div>
            {showFormUpdateServices && (
                <UpdateServiceCreateDocument
                    visible={true}
                    orderPackingIds={selectedRowKeys}
                    priceIds={makeServicePriceIdsForSelectedOrderPackings()}
                    onClose={() => setShowFormUpdateServices(false)}
                    reloadData={reloadData}
                    handleUpdate={handleUpdateService}
                    serviceType={SERVICE_TYPE.EXPORT}
                    countryId={countryId}
                    merchantId={merchantRowId}
                />
            )}
        </Box>
    )
}

export default OrderList
