import { Tabs } from 'antd';
import _, { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';
import Services from '@Modules/Dropshipping/screens/Detail/Services';
import useProductQuery from '@Modules/Product/Hooks/useProductQuery';
import BasicProduct from '@Modules/Product/screens/OriginalProduct/BasicProduct';
import ProductHistory from '@Modules/Product/screens/OriginalProduct/History';
import ProductServiceInfo from '@Modules/Product/screens/OriginalProduct/ProductServiceInfo';
import SkuPrices from '@Modules/Product/screens/OriginalProduct/SkuPrices';
import { EVENTS } from '@Modules/Product/services/constants';

import { events } from '@System/events';
import { t } from '@System/i18n';

const { TabPane } = Tabs;

export default function DropshippingDetail({ match, history, location }) {
    const { params } = match;
    const [activeKey, setActiveKey] = useState('1');
    const [originalProduct, setOriginalProduct] = useState({});
    const { product, options, skus } = originalProduct;
    const { data: productData, isError, error, refetch, isFetched } = useProductQuery(params.id);

    const [currency, setCurrency] = useState({});
    const [productActivePriceDetails, setProductActivePriceDetails] = useState([]);
    const [productActivePrice, setProductActivePrice] = useState({});
    const [canCreate, setCanCreate] = useState(true);
    const [creator, setCreator] = useState();
    const handleChangeTab = useCallback(activeKey => {
        setActiveKey(activeKey);
    }, []);

    useEffect(() => {
        setNavigator(t('common:title.dropshipping-detail'), [
            {
                name: t('common:breadcrumb.product_dropshipping_list'),
                route: 'dropshipping.list',
            },
            {
                name: t('common:breadcrumb.dropshipping-detail'),
            },
        ]);

        return events.listen(EVENTS.UPDATE_PRODUCT_SUCCESS, () => refetch());
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOriginalProduct(productData?.data || {});
        setProductActivePriceDetails(get(productData, 'data.product_price_active_details'));
        setProductActivePrice(get(productData, 'data.product_price_active'));
        setCurrency(get(productData, 'data.currency'));
        setCreator(get(productData, 'data.product_price_active_creator'));
        setCanCreate(get(productData, 'data.can_create_price'));
        setDocumentTitle(t('title_page.product_dropship', { code: get(productData, 'data.product.id', '') }));
    }, [productData]);

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    if (isEmpty(originalProduct)) {
        return null;
    }

    if (isFetched) {
        if (product && product.status === 'NEW') {
            return <Error404 />;
        }
    }

    return (
        <div className="site-content bg-white order-detail _product-detail">
            <Tabs
                className="customize-ant-tabs _product-detail-tabs"
                defaultActiveKey={activeKey}
                onChange={handleChangeTab}
                type="card"
            >
                <TabPane tab={t('product:title.common_info')} key="1" className="_product-detail-tab-common">
                    <BasicProduct product={product} options={options} skus={skus} setOriginalProduct={setOriginalProduct} />
                </TabPane>

                <TabPane tab={t('product:label.dropshipping_service')} key="4">
                    <Services
                        productId={params.id}
                        refetch={refetch}
                        skus={skus}
                        productActivePriceDetails={productActivePriceDetails}
                        productActivePrice={productActivePrice}
                        currency={currency}
                        history={history}
                        location={location}
                        creator={creator}
                        canCreate={canCreate}
                    />
                </TabPane>

                <TabPane tab={t('label.history_change')} key="5" className="_product-detail-tab-history">
                    <ProductHistory productId={params.id} activeKey={activeKey} />
                </TabPane>
            </Tabs>
        </div>
    );
}
