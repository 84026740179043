import { notification } from 'antd';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import { t } from '@System/i18n';
import { notifyErrorFromServer } from '@System/support/helpers';

import apiService from '../services/api';

const useCreateExportingInventoryDocumentMutation = payload => {
    if (!payload) {
        payload = {};
    }

    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit(
        {
            ...payload,
            onSuccess: ({ data }) => {
                if (isFunction(payload?.onSuccess)) {
                    payload.onSuccess(data);
                }

                notification.success({
                    message: t('document:create_exporting_inventory_document.success'),
                    duration: 6
                });
            },
            onError: error => {
                if (isFunction(payload?.onError)) {
                    payload.onError(error);
                }

                if (payload.notifyWhenError) {
                    notifyErrorFromServer(
                        error,
                        t('document:create_exporting_inventory_document.failed'),
                        'document:create_exporting_inventory_document.errors'
                    );
                }
            },
        },
        ['notifyWhenError']
    );

    return useMutation(data => apiService.createExportingInventoryDocument(data), formattedConfig);
};

export default useCreateExportingInventoryDocumentMutation;
