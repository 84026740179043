import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useWarehouseItemsQuery = (params, settings) =>
    createUseQuery({
        name: ['warehouse-items', params],
        query: () => apiService.getWarehouses(params),
        settings,
    });

export default useWarehouseItemsQuery;
