import { DownloadOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { useState } from 'react'

import api from '@Modules/Finance/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import { convertBlobDataToExcel } from '@System/support/helpers'
import { omit } from 'lodash'

interface IExportDebtsShippingProps {
    filters: object
}
const ExportDebtsShipping: React.FC<IExportDebtsShippingProps> = props => {
    const { filters } = props
    const [loading, setLoading] = useState(false)
    const handleOnClick = () => {
        setLoading(true)
        api.downloadDebtsShipping(omit(filters, ['page', 'per_page', 'current_page']))
            .then(res => {
                convertBlobDataToExcel(res.data, 'export-debit-shipping')
            })
            .catch(err => {
                processResponseError(err)
            })
            .finally(() => setLoading(false))
    }
    return (
        <Button
            type={'primary'}
            loading={loading}
            onClick={handleOnClick}
            icon={<DownloadOutlined />}>
            {t('btn.export_excel')}
        </Button>
    )
}
export default ExportDebtsShipping
