import api from "./api";
import {notification} from "antd";
import _ from "lodash";
import {t} from "../../../system/i18n";
import BaseServiceData from "../../App/services/BaseServiceData";

class ServiceWarehouseArea extends BaseServiceData {
    constructor() {
        super();
        this.warehouseId = null;
    }

    list(warehouseId) {
        if (this.warehouseId === warehouseId) {
            if (this.data)
                return Promise.resolve(this.data);

            if (this.promise)
                return this.promise;
        }
        this.warehouseId = warehouseId;
        return this.fetchData();
    }
    runApi() {
        return api.getWarehouseAreas(this.warehouseId)
            .then(res => {
                const warehouseAreas = (_.get(res, "data.areas", []));
                return (warehouseAreas.map(item => {
                    return item.warehouseArea;
                }));
            })
            .catch(err => {
                notification.error({message: t("common:message.fetch_fail", {attribute: t("product:label.warehouse_name").toLowerCase()})});
                return Promise.reject(err);
            });
    }
}

export default new ServiceWarehouseArea();
