import React, {useState} from "react";
import {DatePicker, Divider, Row, Col, Input, Form} from "antd";
import SelectPaymentMethod from "../../../components/SelectPaymentMethod";
import {t} from "../../../../../system/i18n";
import RadioPaymentType from "../../../components/RadioPaymentType";
import {INIT_ORDER, PAYMENT_METHOD, PAYMENT_TYPE} from "../../../services/constants";
import CurrencyInput from "../../../../App/Utilities/CurrencyInput";

export default function PaymentInfo({validatedRules, paidTotal, form}) {
    const [paymentType, setPaymentType] = useState(INIT_ORDER.payment_type);
    const [paymentMethod, setPaymentMethod] = useState("");

    const paymentValidator = (field, relatedFields) => {
        return ({getFieldValue}) => ({
            validator(rule, value) {
                if (value === "" && relatedFields.some(item => getFieldValue(item) !== ""))
                    return Promise.reject(t("order:message.required", {attribute: t(`order:label.${field}`)}));

                if (field === "payment_amount" && parseFloat(value) > paidTotal) {
                    return Promise.reject(t("order:message.gte_paid_total"));
                }

                return Promise.resolve();
            },
        });
    }

    return (
        <div className="border box-shadow bg-white pt-3 pr-4 pl-4 pb-4 mt-4">
            <h2 className="title-box mb-3">{t("order:label.payment_information")}</h2>
            <div>
                <Form.Item
                    className="mb-1 payment_type"
                    name="payment_type"
                >
                    <RadioPaymentType
                        name="payment_type"
                        onChange={e => setPaymentType(e.target.value)}
                    />
                </Form.Item>
                {
                    paymentType === PAYMENT_TYPE.PAYMENT_TYPE_ADVANCE_PAYMENT &&
                    <>
                        <Divider className="mb-2 mt-2" />

                        <Form.Item
                            className="mb-1 payment_method"
                            name="payment_method"
                            label={t("order:label.method")}
                            rules={[paymentValidator("payment_method", ["payment_time", "payment_amount"])]}
                        >
                            <SelectPaymentMethod
                                name="payment_method"
                                placeholder={t("order:placeholder.method")}
                                onChange={value => setPaymentMethod(value)}
                            />
                        </Form.Item>

                        <Form.Item
                            className="mb-1 payment_time"
                            name="payment_time"
                            label={t("order:label.payment_time")}
                            rules={[paymentValidator("payment_time", ["payment_method", "payment_amount"])]}
                        >
                            <DatePicker
                                name="bank_name"
                                placeholder={t("order:placeholder.payment_time")}
                                style={{width: "100%"}}
                                format={"DD/MM/YYYY"}
                            />
                        </Form.Item>

                        <Form.Item
                            className="mb-1 payment_amount"
                            name="payment_amount"
                            label={t("order:label.payment_amount")}
                            rules={[paymentValidator("payment_amount", ["payment_method", "payment_time"])]}
                        >
                            <CurrencyInput
                                placeholder={t("order:placeholder.payment_amount")}
                                name="payment_amount"
                                style={{width:"100%"}}
                                onBlur={value => form.setFields([{name: "payment_amount", value}])}
                            />
                        </Form.Item>
                        {
                            paymentMethod === PAYMENT_METHOD.METHOD_BANK_TRANSFER &&
                            <>
                                <Form.Item
                                    className="mb-1 bank_name"
                                    name="bank_name"
                                    label={t("order:label.bank_name")}
                                    rules={[paymentValidator("bank_name", ["bank_account"])]}
                                >
                                    <Input
                                        name="bank_name"
                                        placeholder={t("order:placeholder.bank_name")}
                                    />
                                </Form.Item>

                                <Form.Item
                                    className="mb-1 bank_account"
                                    name="bank_account"
                                    label={t("order:label.bank_account")}
                                    rules={[paymentValidator("bank_account", ["bank_name"])]}
                                >
                                    <Input
                                        name="bank_account"
                                        placeholder={t("order:placeholder.bank_account")}
                                    />
                                </Form.Item>
                            </>
                        }
                        <Form.Item
                            className="mb-1 payment_note"
                            name="payment_note"
                            label={t("order:label.payment_note")}
                            rules={validatedRules.payment_note}
                        >
                            <Input
                                name="payment_note"
                                placeholder={t("order:placeholder.payment_note")}
                            />
                        </Form.Item>
                    </>
                }
            </div>
        </div>
    );
}
