import { get } from 'lodash';
import React, { useState, useEffect } from 'react';

import Error403 from '@Modules/App/Error/403';
import Error404 from '@Modules/App/Error/404';
import useProductDropshipQuery from '@Modules/Dropshipping/Hooks/useProductDropshipQuery';



import FormSearch from './FormSearch';
import List from './List';
import useFilter from '@Modules/App/Hooks/useFilter';
import { t } from '@System/i18n';
import { setDocumentTitle, setNavigator } from '@Modules/App/services';

export default function ListDropshipping({ history, location }) {
    const { filters, addFilter } = useFilter(history, location);
    const { data, isFetching, refetch, isError, error } = useProductDropshipQuery(filters);

    const [products, setProducts] = useState([]);
    const [pagination, setPagination] = useState({});

    useEffect(() => {
        setNavigator(t('title.product_dropshipping_list'), [
            {
                name: t('common:breadcrumb.product_dropshipping_list'),
            },
        ]);
        setDocumentTitle(t('title.product_dropshipping_list'));

    }, []);

    useEffect(() => {
        refetch();
    }, [refetch, filters]);

    useEffect(() => {
        setProducts(get(data, 'data.products', []));
        setPagination(get(data, 'data.pagination', {}));
    }, [data]);

    if (isError) {
        const status = error.response.status;
        if (status === 403) {
            return <Error403 />;
        }
        return <Error404 />;
    }

    return (
        <div className="site-content">
            <div className="d-flex justify-content-between">
                <h3 className="text-fz-18">{t('title.product_dropshipping_list')}</h3>
            </div>
            <FormSearch addFilter={addFilter} filters={filters} loading={isFetching} />
            <List data={products} pagination={pagination} addFilter={addFilter} filters={filters} loading={isFetching} />
        </div>
    );
}
