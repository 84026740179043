import isFunction from 'lodash/isFunction';
import has from 'lodash/has';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import apiService from './../services/api';

const useCheckingExportWarningsMutation = payload => {
    if (!payload) {
        payload = {};
    }

    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit({
        ...payload,
        onSuccess: ({ data }) => {
            if (isFunction(payload?.onSuccess)) {
                payload.onSuccess(data);
            }
        },
        onError: error => {
            if (isFunction(payload?.onError)) {
                payload.onError(error);

            }
        }
    }, ['notifyWhenError']);

    return useMutation(data => apiService.checkingExportWarnings(data), formattedConfig);
};

export default useCheckingExportWarningsMutation;
