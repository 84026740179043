import { Table, Row, Col } from 'antd';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';
import without from 'lodash/without';
import React, { useEffect, useState } from 'react';

import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import useProductHistoryQuery from '@Modules/Product/Hooks/useProductHistoryQuery';
import { PRODUCT_LOG_KEYS } from '@Modules/Product/services/constants';

import { t, trans } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

function ProductHistory({ productId, activeKey }) {
    const { data, isFetching, refetch } = useProductHistoryQuery(productId);
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        if (activeKey === '4') {
            refetch();
        }
    }, [activeKey]);

    useEffect(() => {
        setDataSource(getVar(data, 'data.logs', []));
    }, [data]);

    const columns = [
        {
            className: '_product-history-user-changed',
            title: t('label.user_changed'),
            dataIndex: 'creator',
            key: 'creator',
            width: '25%',
            render: text => {
                const name = getVar(text, 'name', '');
                const username = getVar(text, 'username', '');
                return <p>{`${name} (${username})`}</p>;
            },
        },
        {
            className: '_product-history-action',
            title: t('label.action'),
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return renderLogsInfo(record);
            },
        },
        {
            className: '_product-history-action-time',
            title: t('label.time'),
            dataIndex: 'created_at',
            key: 'created_at',
            width: '20%',
        },
    ];

    function renderLogsInfo(log) {
        const action = getVar(log, 'action', '');
        const payload = getVar(log, 'payload', {});
        const sku_code = getVar(payload, 'sku.code', {});
        const product_price_id = getVar(payload, 'product_price_id', {});
        const options = getVar(payload, 'options', {});
        let payload_keys = keys(payload);
        if (!isEmpty(options)) {
            payload_keys = without(payload_keys, 'option_values');
        }
        const oldStatus = getVar(payload, 'from', '');
        const newStatus = getVar(payload, 'to', '');
        switch (action) {
            case PRODUCT_LOG_KEYS.PRODUCT_CREATE:
                return trans('product:log.PRODUCT_CREATE');
                break;
            case PRODUCT_LOG_KEYS.PRODUCT_UPDATE:
                let message_update = [];
                payload_keys.forEach(item => {
                    if (item === 'sku_change_code') item = 'code';
                    const values = getVar(payload, item, {});
                    const addedInfo = getVar(values, 'added', []);
                    const removedInfo = getVar(values, 'removed', []);
                    switch (item) {
                        case 'weight':
                        case 'length':
                        case 'height':
                        case 'width':
                            const oldValues = getVar(values, 'old', '');
                            const newValues = getVar(values, 'new', '');
                            message_update.push(
                                trans('product:log.PRODUCT_UPDATE', {
                                    field: <b>{trans('sku:label.' + item)}</b>,
                                    old_value: <b>{oldValues ? Number(oldValues) * 1000 : t('noText')}</b>,
                                    new_value: <b>{newValues ? Number(newValues) * 1000 : t('noText')}</b>,
                                    new_line: <br />,
                                })
                            );
                            break;
                        case 'dropship':
                            message_update.push(
                                trans('product:log.PRODUCT_UPDATE', {
                                    field: <b>{trans('label.product')}</b>,
                                    old_value: <b>{values.old ? t('label.product_dropship') : t('label.product_normal')}</b>,
                                    new_value: <b>{values.new ? t('label.product_dropship') : t('label.product_normal')}</b>,
                                    new_line: <br />,
                                })
                            );
                            break;
                        case 'images':
                            if (addedInfo.length > 0) {
                                message_update.push(
                                    trans('product:log.PRODUCT_ADDED_IMAGES', {
                                        new_line: <br />,
                                    })
                                );
                            }
                            if (removedInfo.length > 0) {
                                message_update.push(
                                    trans('product:log.PRODUCT_REMOVED_IMAGES', {
                                        new_line: <br />,
                                    })
                                );
                            }
                            break;
                        case 'services':
                        case 'service_prices':
                            if (addedInfo.length > 0) {
                                const servicesAdded = addedInfo.map(item => {
                                    const service_name = getVar(item, 'service.name', '');
                                    const service_price_name = getVar(item, 'service_price.label', '');
                                    return `${service_name} - ${service_price_name}`;
                                });
                                message_update.push(
                                    trans('product:log.PRODUCT_ADDED_SERVICE', {
                                        service: <b>{servicesAdded.join(', ')}</b>,
                                        new_line: <br />,
                                    })
                                );
                            }
                            if (removedInfo.length > 0) {
                                const servicesRemoved = removedInfo.map(item => {
                                    const service_name = getVar(item, 'service.name', '');
                                    const service_price_name = getVar(item, 'service_price.label', '');
                                    return `${service_name} - ${service_price_name}`;
                                });
                                message_update.push(
                                    trans('product:log.PRODUCT_REMOVED_SERVICE', {
                                        service: <b>{servicesRemoved.join(', ')}</b>,
                                        new_line: <br />,
                                    })
                                );
                            }
                            break;
                        case 'options':
                            if (addedInfo.length > 0) {
                                const optionsAdded = addedInfo.map(item => {
                                    const option = getVar(item, 'option.label', '');
                                    return `${option}: ${map(getVar(item, 'values', []), 'label').join(', ')}`;
                                });
                                message_update.push(
                                    trans('product:log.PRODUCT_ADDED_OPTIONS', {
                                        option: <b>{optionsAdded.join(', ')}</b>,
                                        new_line: <br />,
                                    })
                                );
                            }
                            if (removedInfo.length > 0) {
                                const optionsRemoved = removedInfo.map(item => {
                                    const option = getVar(item, 'option.label', '');
                                    const option_label = getVar(item, 'value.label', '');
                                    return option_label ? `${option}: ${option_label}` : option;
                                });
                                message_update.push(
                                    trans('product:log.PRODUCT_REMOVED_OPTIONS', {
                                        option: <b>{optionsRemoved.join(', ')}</b>,
                                        new_line: <br />,
                                    })
                                );
                            }
                            break;
                        case 'option_values':
                            if (addedInfo.length > 0) {
                                const optionValuesAdded = addedInfo.map(
                                    item => `${getVar(item, 'option.label', '')}: ${getVar(item, 'value.label', [])}`
                                );
                                message_update.push(
                                    trans('product:log.PRODUCT_ADDED_OPTIONS', {
                                        option: <b>{optionValuesAdded.join(', ')}</b>,
                                        new_line: <br />,
                                    })
                                );
                            }
                            if (removedInfo.length > 0) {
                                const optionValuesRemoved = removedInfo.map(
                                    item => `${getVar(item, 'option.label', '')}: ${getVar(item, 'value.label', [])}`
                                );
                                message_update.push(
                                    trans('product:log.PRODUCT_REMOVED_OPTIONS', {
                                        option: <b>{optionValuesRemoved.join(', ')}</b>,
                                        new_line: <br />,
                                    })
                                );
                            }
                            break;
                        case 'status':

                            message_update.push(
                                trans('product:log.PRODUCT_UPDATE', {
                                    field: <b>{trans('product:label.status')}</b>,
                                    old_value: <b>{values.old ? t(`product:PRODUCT_STATUS.${values.old}`) : t('noText')}</b>,
                                    new_value: <b>{t(`product:PRODUCT_STATUS.${values.new}`)}</b>,
                                    new_line: <br />,
                                })
                            );

                            break
                        default:
                            message_update.push(
                                trans('product:log.PRODUCT_UPDATE', {
                                    field: <b>{trans('product:label.' + item)}</b>,
                                    old_value: <b>{values.old ? values.old : t('noText')}</b>,
                                    new_value: <b>{values.new}</b>,
                                    new_line: <br />,
                                })
                            );
                    }
                });
                return message_update.length > 0 ? message_update : trans('product:log.PRODUCT_UPDATE_DEFAULT');
            case PRODUCT_LOG_KEYS.PRODUCT_UPDATE_STATUS:
                return trans('product:log.PRODUCT_UPDATE_STATUS', {
                    old_status: <b>{t(`product:status.${oldStatus}`)}</b>,
                    new_status: <b>{t(`product:status.${newStatus}`)}</b>,
                    new_line: <br />,
                });
            case PRODUCT_LOG_KEYS.PRODUCT_SKU_UPDATE_STATUS:
                return trans('product:log.PRODUCT_SKU_UPDATE_STATUS', {
                    sku_code: <b>#{sku_code}</b>,
                    old_status: <b>{t(`product:status.${oldStatus}`)}</b>,
                    new_status: <b>{t(`product:status.${newStatus}`)}</b>,
                });
            case PRODUCT_LOG_KEYS.PRODUCT_PRICE_UPDATE:
                return trans('product:log.PRODUCT_QUOTATION_UPDATE_STATUS', {
                    code: <b>#{product_price_id}</b>,
                    old_status: <b>{t(`product:status_quotation.${oldStatus}`)}</b>,
                    new_status: <b>{t(`product:status_quotation.${newStatus}`)}</b>,
                    new_line: <br />,
                });
            case PRODUCT_LOG_KEYS.PRODUCT_SKU_UPDATE_PRICE:
                const merchant = getVar(payload, 'merchant.name', '');
                const currency = getVar(payload, 'currency', {});
                const fromValues = getVar(payload, 'from', []);
                const toValues = getVar(payload, 'to', []);
                const priceMessages = [];
                ['cost_price', 'wholesale_price', 'retail_price'].map(item => {
                    const fromPrice = getVar(fromValues, item, null);
                    const toPrice = getVar(toValues, item, null);
                    if (fromPrice !== toPrice) {
                        priceMessages.push(
                            trans('product:log.PRODUCT_SKU_UPDATE_PRICE', {
                                field: <b>{t(`product:label.${item}`)}</b>,
                                merchant: <b>{merchant}</b>,
                                sku_code: <b>#{sku_code}</b>,
                                old_value: (
                                    <b>
                                        {fromPrice
                                            ? !isEmpty(currency)
                                                ? formatCurrency(fromPrice || 0, currency)
                                                : fromPrice
                                            : t('noText')}
                                    </b>
                                ),
                                new_value: (
                                    <b>
                                        {toPrice
                                            ? !isEmpty(currency)
                                                ? formatCurrency(toPrice || 0, currency)
                                                : toPrice
                                            : t('noText')}
                                    </b>
                                ),
                                new_line: <br />,
                            })
                        );
                    }
                });

                return priceMessages;
            case PRODUCT_LOG_KEYS.PRODUCT_SKU_CREATE:
                return trans('product:log.PRODUCT_SKU_CREATE', { sku_code: <b>#{sku_code}</b> });
            case PRODUCT_LOG_KEYS.PRODUCT_SKU_DELETE:
                return trans('product:log.PRODUCT_SKU_DELETE', { sku_code: <b>#{sku_code}</b> });
            case PRODUCT_LOG_KEYS.PRODUCT_SKU_UPDATE_REF:
                const oldValue = getVar(payload, 'from', '');
                const newValue = getVar(payload, 'to', '');
                return trans('product:log.SKU_UPDATE_REF', {
                    sku_code: <b>#{sku_code}</b>,
                    old_value: <b>{oldValue ? oldValue : t('noText')}</b>,
                    new_value: <b>{newValue}</b>,
                    new_line: <br />,
                });
            case PRODUCT_LOG_KEYS.PRODUCT_SKU_UPDATE:
                let messages = [];
                const data = getVar(payload, 'data', {});
                Object.keys(data).map(item => {
                    if (item === 'weight' || item === 'length' || item === 'width' || item === 'height') {
                        const oldValues = getVar(data[item], 'old', '');
                        const newValues = getVar(data[item], 'new', '');
                        messages.push(
                            trans('product:log.SKU_UPDATE', {
                                field: <b>{trans('sku:label.' + item)}</b>,
                                sku_code: <b>#{sku_code}</b>,
                                old_value: <b>{oldValues ? Number(oldValues) * 1000 : t('noText')}</b>,
                                new_value: <b>{newValues ? Number(newValues) * 1000 : t('noText')}</b>,
                                new_line: <br />,
                            })
                        );
                    } else {
                        messages.push(
                            trans('product:log.SKU_UPDATE', {
                                field: <b>{trans('sku:label.' + item)}</b>,
                                sku_code: <b>#{sku_code}</b>,
                                new_line: <br />,
                            })
                        );
                    }
                });
                return messages;
            case PRODUCT_LOG_KEYS.CONFIRM_WEIGHT_VOLUME_FOR_SKUS:
                const skus = getVar(payload, "skus", []);
                return trans('product:log.CONFIRM_WEIGHT_VOLUME_FOR_SKUS', {
                    skus: <b>{skus.join(", ")}</b>,
                });
            case PRODUCT_LOG_KEYS.PRODUCT_REMOVE_SERVICE:
                const service = getVar(payload, "service", []);
                return trans('product:log.PRODUCT_REMOVE_SERVICE', {
                    service_name: <b>{service.name}</b>,
                });
                
            default:
                return trans('order:log.ORDER_UPDATE');
        }
    }

    return (
        <Row className="p-4">
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Table
                    rowKey={record => record.id}
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                    loading={isFetching}
                    locale={{ emptyText: <TableEmpty loading={isFetching} /> }}
                    scroll={{ x: 576 }}
                />
            </Col>
        </Row>
    );
}
export default ProductHistory;
