import { Form, Input, Modal, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import get from 'lodash/get';

import ServiceLocation from '@Modules/Location/services/ServiceLocation';
import { VIETNAME_CODE } from '@Modules/Location/services/constants';
import useUpdateOrderMutation from '@Modules/Order/Hooks/useUpdateOrderMutation';
import { VALIDATED_RULES } from '@Modules/Order/services/constants';

import { t } from '@System/i18n';

const { Option } = Select;

const ProvincesServ = new ServiceLocation();
const DistrictServ = new ServiceLocation();
const WardServ = new ServiceLocation();

const EditAddressModal = ({ orderDetail, visible, onCancel, onSuccessUpdate }) => {
    const [form] = Form.useForm();
    const { isLoading, mutateAsync } = useUpdateOrderMutation();

    let [provinces, setProvinces] = useState([]);
    let [loadingProvince, setLoadingProvince] = useState(false);

    let [districts, setDistricts] = useState([]);
    let [loadingDistrict, setLoadingDistrict] = useState(false);

    let [wards, setWards] = useState([]);
    let [loadingWard, setLoadingWard] = useState(false);

    const [provinceId, setProvinceId] = useState(null);
    const [districtId, setDistrictId] = useState(null);

    const fetchProvince = useCallback(orderDetail => {
        setLoadingProvince(true);

        if (!orderDetail?.receiverCountry?.code) {
            return;
        }

        ProvincesServ.list('PROVINCE', orderDetail?.receiverCountry?.code)
            .then(res => {
                setProvinces(res);
            })
            .finally(() => {
                setLoadingProvince(false);
            });
    }, []);

    const fetchDistrict = useCallback(provinceId => {
        const province = provinces.find(item => item.id === provinceId);

        if (!province) {
            return;
        }

        setLoadingDistrict(true);

        DistrictServ.list('DISTRICT', province?.code)
            .then(res => {
                setDistricts(res);
            })
            .finally(() => {
                setLoadingDistrict(false);
            });
    }, [provinces]);

    const fetchWard = useCallback(districtId => {
        const district = districts.find(item => item.id === districtId);

        if (!district) {
            return;
        }

        setLoadingWard(true);

        WardServ.list('WARD', district?.code)
            .then(res => {
                setWards(res);
            })
            .finally(() => {
                setLoadingWard(false);
            });
    }, [districts]);

    function selectProvince(value) {
        setProvinceId(value);
        setDistrictId(null);
        form.setFields([
            { name: 'receiver_district_id', value: null },
            { name: 'receiver_ward_id', value: null },
        ]);
    }

    function selectDistrict(value) {
        setDistrictId(value);
        form.setFields([{ name: 'receiver_ward_id', value: null }]);
    }

    const handleFinish = async values => {
        try {
            await mutateAsync({
                ...values,
                id: orderDetail?.order?.id,
            });

            if (onSuccessUpdate) {
                onSuccessUpdate();
            }
        } catch (error) {}
    };

    /**
     * Tải quận huyện nếu tỉnh thành thay đổi
     */
    useEffect(() => {
        if (provinceId) {
            fetchDistrict(provinceId);
        } else {
            setDistricts([]);
        }
    }, [orderDetail, provinceId, fetchDistrict]);

    /**
     * Tải lại phường xã nếu quận huyện thay đổi
     */
    useEffect(() => {
        if (districtId) {
            fetchWard(districtId);
        } else setWards([]);
    }, [districtId, fetchWard]);

    useEffect(() => {
        setProvinceId(orderDetail?.receiverProvince?.id);
        setDistrictId(orderDetail?.receiverDistrict?.id);

        form.setFieldsValue({
            receiver_address: orderDetail?.order?.receiver_address,
            receiver_province_id: orderDetail?.receiverProvince?.id,
            receiver_district_id: orderDetail?.receiverDistrict?.id,
            receiver_ward_id: orderDetail?.receiverWard?.id,
        });
    }, [form, orderDetail]);

    useEffect(() => {
        fetchProvince(orderDetail);
        form.setFields([{ name: 'receiver_postal_code', value: get(orderDetail, "order.receiver_postal_code") }]);
    }, [fetchProvince, orderDetail]);

    return (
        <Modal
            className="_edit-order-address-modal"
            visible={visible}
            okText={t('btn.ok')}
            cancelText={t('btn.cancel')}
            okButtonProps={{
                loading: isLoading,
            }}
            onCancel={onCancel}
            onOk={form.submit}
        >
            <Form className="mt-4" form={form} layout="vertical" onFinish={handleFinish}>

                <Form.Item
                    className="mb-3"
                    name="receiver_address"
                    label={t('order:label.receiver_address')}
                    rules={VALIDATED_RULES.receiver_address}
                >
                    <Input
                        className="_receiver-address"
                        name="receiver_address"
                        placeholder={t('order:placeholder.receiver_address')}
                    />
                </Form.Item>
                <Form.Item
                    className="mb-3"
                    name="receiver_province_id"
                    label={t(
                        `warehouse:label.${
                            orderDetail?.receiverCountry?.code === VIETNAME_CODE ? 'province_id' : 'other_province_id'
                        }`
                    )}
                    rules={VALIDATED_RULES['receiver_province_id']}
                >
                    <Select
                        className="_receiver-province-id"
                        dropdownClassName="_provinces-dropdown"
                        loading={loadingProvince}
                        name="receiver_province_id"
                        placeholder={t(
                            `warehouse:placeholder.${
                                orderDetail?.receiverCountry?.code === VIETNAME_CODE ? 'province_id' : 'other_province_id'
                            }`
                        )}
                        onChange={selectProvince}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {provinces.length > 0 &&
                            provinces.map(opt => {
                                return (
                                    <Option key={opt.id} value={opt.id}>
                                        {opt.label}
                                    </Option>
                                );
                            })}
                    </Select>
                </Form.Item>

                <Form.Item
                    className="mb-3"
                    name="receiver_district_id"
                    label={t(
                        `warehouse:label.${
                            orderDetail?.receiverCountry?.code === VIETNAME_CODE ? 'district_id' : 'other_district_id'
                        }`
                    )}
                    rules={VALIDATED_RULES['receiver_district_id']}
                >
                    <Select
                        className="_receiver-district-id"
                        dropdownClassName="_districts-dropdown"
                        loading={loadingDistrict}
                        name="receiver_district_id"
                        placeholder={t(
                            `warehouse:placeholder.${
                                orderDetail?.receiverCountry?.code === VIETNAME_CODE ? 'district_id' : 'other_district_id'
                            }`
                        )}
                        onChange={selectDistrict}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {districts.length > 0 &&
                            districts.map(opt => {
                                return (
                                    <Option key={opt.id} value={opt.id}>
                                        {opt.label}
                                    </Option>
                                );
                            })}
                    </Select>
                </Form.Item>

                <Form.Item
                    className="mb-3"
                    name="receiver_ward_id"
                    label={t(
                        `warehouse:label.${orderDetail?.receiverCountry?.code === VIETNAME_CODE ? 'ward_id' : 'other_ward_id'}`
                    )}
                    rules={VALIDATED_RULES['receiver_ward_id']}
                >
                    <Select
                        className="_receiver-ward-id"
                        dropdownClassName="_wards-dropdown"
                        loading={loadingWard}
                        name="receiver_ward_id"
                        placeholder={t(
                            `warehouse:placeholder.${
                                orderDetail?.receiverCountry?.code === VIETNAME_CODE ? 'ward_id' : 'other_ward_id'
                            }`
                        )}
                        showSearch
                        optionFilterProp="label"
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {wards.length > 0 &&
                            wards.map(opt => {
                                return (
                                    <Option key={opt.id} value={opt.id}>
                                        {opt.label}
                                    </Option>
                                );
                            })}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditAddressModal;
