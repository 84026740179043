import React, {useCallback, useState, useEffect} from "react";
import {notification} from "antd";
import useFilter from "../../../App/Hooks/useFilter";
import api from "../../services/api";
import _ from "lodash";
import {t} from "../../../../system/i18n";
import {setDocumentTitle, setNavigator} from "../../../App/services";
import WarehouseList from "./WarehouseList";
import FormSearch from "./FormSearch";
import CreateWarehouse from "../../components/CreateWarehouse";
import PERMISSIONS from "../../../Auth/services/permissions"
import {auth} from "../../../Auth/services";
import useWarehouseItemsQuery from "@Modules/Warehouse/Hooks/useWarehouseItemsQuery";
import {getVar} from "@System/support/helpers";

const initFilters = {
    code: "",
    name: "",
    country_id: "",
    province_id: "",
    page: "",
    per_page: "",
    status: 1
};

export default function ListWarehouses({history, location}) {
    const {filters, addFilter} = useFilter(history, location, initFilters);
    const [warehouses, setWarehouses] = useState([]);
    const [pagination, setPagination] = useState({});
    useEffect(() => {
        setNavigator(t("common:title.list-warehouses"), [
            {
                name: t("common:breadcrumb.warehouses"),
                route: "warehouses.list"
            },
            {
                name: t("common:breadcrumb.list-warehouses")
            }
        ]);
        setDocumentTitle(t("title.list-warehouses"));
    }, []);

    const {data, isFetching:loading, refetch} = useWarehouseItemsQuery(filters);

    useEffect(() => {
        setWarehouses(getVar(data, "data.warehouses", []));
        setPagination(getVar(data, "data.pagination", {}))
    }, [data]);

    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex justify-content-between align-items-center">
                    <h2 className="p-3">{t("common:heading.list-warehouses")}</h2>
                    <div className="pr-3">
                        {
                            auth.can(PERMISSIONS.WAREHOUSE_CREATE) &&
                            <CreateWarehouse
                                fetchWarehouses={refetch}
                            />
                        }
                    </div>
                </div>
                <div>
                    <FormSearch
                        addFilter={addFilter}
                        filters={filters}
                        initFilters={initFilters}
                        loading={loading}
                    />
                    <WarehouseList
                        warehouses={warehouses}
                        setWarehouses={setWarehouses}
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}
