import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import Error403 from '@Modules/App/Error/403'
import Error404 from '@Modules/App/Error/404'
import useFilter from '@Modules/App/Hooks/useFilter'
import { setDocumentTitle, setNavigator } from '@Modules/App/services'

import { t } from '@System/i18n'

import useSupplierQuery from '../../hooks/useSupplierQuery'
import Body from './Body'

const Supplier = ({ history, location }) => {
    const { filters, addFilter } = useFilter(history, location, {})

    const { data, isFetching, refetch, isError, error } = useSupplierQuery(filters)

    const [suppliers, setSuppliers] = useState([])
    const [pagination, setPagination] = useState({})

    useEffect(() => {
        setNavigator(t('menu.supplier_list'), [
            {
                name: t('menu.supplier_list'),
            },
        ])
        setDocumentTitle(t('menu.supplier_list'))
    }, [])

    useEffect(() => {
        refetch()
    }, [refetch])

    useEffect(() => {
        setSuppliers(get(data, 'data.suppliers', []))
        setPagination(get(data, 'data.pagination', {}))
    }, [data])

    if (isError) {
        const status = get(error, 'response.status')
        if (status === 403) {
            return <Error403 />
        }
        return <Error404 />
    }

    return (
        <div className="purchasing-account-page px-4 py-px-32">
            {/* <Filter
                className="mb-4"
                form={form}
                loading={isFetching}
                onClear={search.clear}
                onSubmit={search.submit}
            /> */}
            <Body
                loading={isFetching}
                filters={filters}
                addFilter={addFilter}
                data={suppliers}
                pagination={pagination}
                refetch={refetch}
            />
        </div>
    )
}

export default Supplier
