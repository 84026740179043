import React, {useState} from "react";
import {t} from "../../../../system/i18n";
import {
    Form,
    Input,
    Row,
    Col,
    Button,
    notification, Checkbox
} from "antd";
import api from "../../services/api";
import Location from "../Detail/Location";
import useValidatedMessages from "../../../../system/hooks/useValidatedMessages";
import _ from "lodash";
import PERMISSIONS from "../../../Auth/services/permissions";
import {auth} from "../../../Auth/services";

const {TextArea} = Input;
const formItemLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18}
};

const tailLayout = {
    wrapperCol: {offset: 6, span: 18},
};

// list of rules for every input
const validatedRules = {
    code: [{required: true, max: 255, type: "string"}],
    name: [{required: true, max: 255, type: "string"}],
    country_id: [{type: "integer"}],
    province_id: [{type: "integer"}],
    district_id: [{type: "integer"}],
    ward_id: [{type: "integer"}],
    address: [],
    description: []
};

export default function UpdateForm({data, setData}) {
    const validateMessages = useValidatedMessages();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const {warehouse, country, province, district} = data;
    const [reset, setReset] = useState(0);

    function handleSubmit(e) {
        form.validateFields()
            .then(values => {
                setLoading(true);
                let formData = {};
                for (const prop in values) {
                    if (values[prop] !== undefined)
                        if (prop === "is_main") {
                            formData[prop] = values[prop] ? 1 : 0;
                        } else {
                            formData[prop] = values[prop];
                        }
                }

                api.updateWarehouse(warehouse.id, formData)
                    .then(res => {
                        notification.success({message: t("message.update_success_by_attribute", {attribute: t("label.warehouses")})});
                        setData(_.get(res, "data", {}));
                        form.resetFields();
                    })
                    .catch(err => {
                        let {response} = err;
                        if (response.status === 400) {
                            let error_messages = [];
                            let errors = _.get(response, "data.data", {});
                            Object.keys(errors).forEach(item => {
                                let key_errors = [];
                                Object.keys(errors[item]).forEach(error => key_errors.push(t(`warehouse:message.${error}`, {attribute: t(`warehouse:label.${item}`)})));
                                error_messages.push({name: item, errors: key_errors})
                            });
                            form.setFields(error_messages);
                        }
                        notification.error({message: t("message.update_failed_by_attribute", {attribute: t("label.warehouses")})});
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
    }

    function onReset() {
        setReset(reset + 1);

        form.resetFields();
    }

    return (
        <Form
            initialValues={warehouse}
            className="form p-5"
            validateMessages={validateMessages}
            size="default"
            form={form}
            labelCol={formItemLayout.labelCol}
            wrapperCol={formItemLayout.wrapperCol}
            colon=":"
        >
            <Row gutter={40}>
                <Col span={12}>
                    <Form.Item
                        className="mb-3"
                        name="code"
                        label={t('warehouse:label.code')}
                        rules={validatedRules.code}
                    >
                        <Input
                            name="code"
                            placeholder={t('warehouse:placeholder.code')}
                        />
                    </Form.Item>

                    <Form.Item
                        className="mb-3"
                        name="name"
                        label={t('warehouse:label.name')}
                        rules={validatedRules.name}
                    >
                        <Input
                            name="subject"
                            placeholder={t('warehouse:placeholder.name')}
                        />
                    </Form.Item>

                    <Form.Item
                        className="mb-3"
                        name="description"
                        label={t('warehouse:label.note')}
                        rules={validatedRules.description}
                    >
                        <TextArea
                            name="description"
                            placeholder={t('warehouse:placeholder.note')}
                            style={{height: 130}}
                        />
                    </Form.Item>

                    <Form.Item
                        {...tailLayout}
                        name="is_main"
                        valuePropName="checked"
                    >
                        <Checkbox>{t("warehouse:label.is_main_warehouse")}</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Location
                        validatedRules={validatedRules}
                        form={form}
                        defaultCountry={false}
                        country={country}
                        province={province}
                        district={district}
                        reset={reset}
                        setReset={setReset}
                    />

                    <Form.Item
                        className="mb-3"
                        name="address"
                        label={t('warehouse:label.address')}
                        rules={validatedRules.address}
                    >
                        <Input
                            name="address"
                            placeholder={t('warehouse:placeholder.address')}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {
                auth.can(PERMISSIONS.WAREHOUSE_UPDATE) &&
                <div className="d-flex justify-content-center mt-3">
                    <Button
                        htmlType={"submit"}
                        type="primary"
                        onClick={handleSubmit}
                        loading={loading}
                        style={{textTransform: "uppercase"}}
                    >{t("common:btn.update")}</Button>
                    <Button
                        htmlType={"reset"}
                        className="ml-2"
                        onClick={onReset}
                        style={{textTransform: "uppercase"}}
                    >{t("common:btn.reset_form")}</Button>
                </div>
            }
        </Form>
    );
}
