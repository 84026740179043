import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useImportingDocumentsQuery = (params, settings) =>
    createUseQuery({
        name: ['importing-documents', params],
        query: () => apiService.getImportingDocuments(params),
        settings,
    });

export default useImportingDocumentsQuery;
