import { CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import useDeleteStoreMutation from '@Modules/Store/Hooks/useDeleteStoreMutation';

const DeleteConnection = ({ id, onSuccessDelete }) => {
    const { isLoading, mutate: deleteConnection } = useDeleteStoreMutation({
        onSuccess: () => onSuccessDelete(),
    });

    return (
        <>
            <Button className="p-0 text-grey" disabled={isLoading} type="link" onClick={deleteConnection.bind(this, id)}>
                {isLoading ? <LoadingOutlined /> : <CloseCircleFilled />}
            </Button>
        </>
    );
};

export default DeleteConnection;
