import { useQuery } from 'react-query'

import processResponseError from '@System/api/processResponseError'
import apiService from '../services/api'


const useDebtInformation = id => {
    return useQuery(['supplier-debt-information', id], () =>apiService.getSuppliersDebtInformation(id), {
        retry: false,
        onError: err => {
            processResponseError(err)
        },
    })
}

export default useDebtInformation
