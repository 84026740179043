import Icon, { PlusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import { Button, Menu, Dropdown } from 'antd';
import React from 'react';

import { auth } from '@Modules/Auth/services';
import PERMISSIONS from '@Modules/Auth/services/permissions';
import ImportConfirmOrdersFile from '@Modules/Order/components/ImportData/ImportConfirmOrdersFile';
import ImportUpdateOrdersFile from '@Modules/Order/components/ImportData/ImportUpdateOrdersFile';
import UploadOrderFile from '@Modules/Order/components/ImportData/UploadOrderFile';
import UploadChangeFreightBillStatus from '@Modules/Order/components/ImportData/UploadChangeFreightBillStatus';

import { t } from '@System/i18n';
import { url } from '@System/routing';
import ImportChangeFinancialStatus from "@Modules/Order/components/ImportData/ImportChangeFinancialStatus";

export default function MenuOrderAction({ fetchOrders }) {

    const moreMenu = () => (
        <svg t="1640598872073" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
             p-id="4104" width="16" height="16">
            <path
                d="M437.333 224c0-40.533 34.134-74.667 74.667-74.667s74.667 34.134 74.667 74.667-34.134 74.667-74.667 74.667-74.667-34.134-74.667-74.667z m2.134 288c0-40.533 34.133-74.667 74.666-74.667S588.8 471.467 588.8 512s-34.133 74.667-74.667 74.667-74.666-34.134-74.666-74.667z m-2.134 288c0-40.533 34.134-74.667 74.667-74.667s74.667 34.134 74.667 74.667-34.134 74.667-74.667 74.667-74.667-34.134-74.667-74.667z"
                p-id="4105" fill="#2c2c2c"></path>
        </svg>
    );

    const selectedAction = (
        <Menu className="menu-dropdown-action _create-order">
            <Menu.Item key={1} className="mb-3 mb-xl-0">
                <Button className="_btn-create-order-manual" type="link" onClick={() => url.redirectTo('orders.create')}>
                    <PlusCircleOutlined /> {t('order:btn.create_order_manual')}
                </Button>
            </Menu.Item>
            {auth.can(PERMISSIONS.ORDER_CREATE) && (
                <Menu.Item key={2} className="_btn-create-order-by-import mb-3 mb-xl-0">
                    <UploadOrderFile />
                </Menu.Item>
            )}
        </Menu>
    );

    return (
        <div className="d-flex justify-content-end">
            {auth.can(PERMISSIONS.ORDER_CREATE) && (
                <Dropdown overlay={selectedAction} trigger={['click']} className="_dropdown-action">
                    <Button icon={<PlusOutlined />} type="primary">
                        {t('order:btn.create_order')}
                    </Button>
                </Dropdown>
            )}
            <Dropdown
                overlay={
                    <Menu className="menu-dropdown-action _order-more-action">
                        {auth.can(PERMISSIONS.ORDER_UPDATE) && (
                            <>
                                <Menu.Item key={3} className="_btn-update-order mb-3 mb-xl-0">
                                    <ImportUpdateOrdersFile btnType="link" onSuccessImport={fetchOrders} />
                                </Menu.Item>
                                <Menu.Item key={4} className="_btn-confirm-order mb-3 mb-xl-0">
                                    <ImportConfirmOrdersFile btnType="link" onSuccessImport={fetchOrders} />
                                </Menu.Item>
                                <Menu.Item key={5} className="_btn-return-order mb-3 mb-xl-0">
                                    <UploadChangeFreightBillStatus btnType="link" onSuccessImport={fetchOrders} />
                                </Menu.Item>
                            </>
                        )}
                        {auth.can(PERMISSIONS.ORDER_CHANGE_FINANCIAL_STATUS) && (
                            <Menu.Item key={6} className="_btn-update-order mb-3 mb-xl-0">
                                <ImportChangeFinancialStatus btnType="link" onSuccessImport={fetchOrders} />
                            </Menu.Item>
                        )}
                    </Menu>
                }
                trigger={['click']}
                className="_dropdown-action"
            >
                <Button className="ml-3" icon={<Icon component={moreMenu} />} />
            </Dropdown>
        </div>
    );
}
