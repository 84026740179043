import { ReloadOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Modal, Row, Col, Input, Select, Typography } from 'antd'
import { omit, map, pick, isEmpty, find, get, every, flatMapDeep, mapKeys } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import Box from '@Modules/App/Box'
import useScanBarcode from '@Modules/App/Hooks/useScanBarcode'
import useScanSounds from '@Modules/App/Hooks/useScanSounds'
import useSize from '@Modules/App/Hooks/useSize'
import { warehouseStorage } from '@Modules/App/services'
import { INPUT_INVALID } from '@Modules/PurchasingPackage/services/constants'
import ScanBarcode from '@Modules/ScanOrder/components/ScanBarcode'
import SelectWarehouse from '@Modules/Warehouse/components/SelectWarehouse'

import { addError, clearErrors, deleteErrorByKey } from '@State/scanErrors/actions'
import { useScanErrorsOfType } from '@State/scanErrors/hooks'

import useGeneratorKey, { IdempotencyStorage } from '@System/hooks/useGeneratorKey'
import { t, trans } from '@System/i18n'
import notification from '@System/notification'
import { url } from '@System/routing'
import { getVar } from '@System/support/helpers'

import api from '../../../../services/api'
import { SCAN_RETURN_ORDER_TYPE } from '../../../../services/constants'
import LeavePageBlocker from '../../components/LeavePageBlocker'

const { Option } = Select
const { Title } = Typography

const validatedRules = {
    barcode: [],
    warehouse_id: [
        {
            required: true,
            type: 'integer',
            message: t('order:message.required', { attribute: t('label.select_warehouses') }),
        },
    ],
    barcode_type: [
        {
            required: true,
            type: 'string',
            message: t('order:message.required', { attribute: t('label.scan_type') }),
        },
    ],
}

export default function FormScan({ storeData, errorKey, form, storeActions }) {
    const { isMobile } = useSize()
    const [isScanning, setIsScanning] = useState(false)
    const [loading, setLoading] = useState(false)
    const returnOrders = getVar(storeData, 'return_orders', [])
    const isSaved = getVar(storeData, 'isSaved', false)
    const { uniqueKey, generatorKey } = useGeneratorKey()

    const [dirty, setDirty] = useState(false)
    const scanErrors = useScanErrorsOfType(errorKey)
    const dispatch = useDispatch()
    const { playSuccessSound, playErrorSound } = useScanSounds()
    const barcodeInputRef = useRef()
    useEffect(() => {
        let attrs = Object.keys(omit(storeData, ['return_orders'])).map(item => ({
            name: item,
            value: storeData[item],
        }))
        form.setFields(attrs)

        if (!storeData.warehouse_id && warehouseStorage.value) _onChange('warehouse_id', warehouseStorage.value)

        if (!storeData.barcode_type) _onChange('barcode_type', SCAN_RETURN_ORDER_TYPE.FREIGHT_BILL)
    }, [])

    const scan = useScanBarcode(api.scanImportByReturnOrder, {
        onSuccess: (_, params) => {
            const key = map(params, (value, key) => `${key}:${value}`).join('-')
            dispatch(deleteErrorByKey({ type: errorKey, key }))
        },
        onError: (error, params) => {
            const key = map(params, (value, key) => `${key}:${value}`).join('-')
            dispatch(
                addError({
                    type: errorKey,
                    key,
                    message: {
                        errors: error?.response?.data?.data,
                        barcode: params?.barcode,
                        barcode_type: params?.barcode_type,
                    },
                })
            )
            playErrorSound()
        },
        onSettled: () => {
            form.setFieldsValue({ barcode: undefined })
            setIsScanning(false)
            barcodeInputRef.current.focus({ cursor: 'start' })
        },
    })

    function _onChange(name, value) {
        storeActions.updateScanInfo({ [name]: value })
    }

    function _onReset() {
        const barcode_type = getVar(storeData, 'barcode_type', SCAN_RETURN_ORDER_TYPE.FREIGHT_BILL)
        const warehouse_id = getVar(storeData, 'warehouse_id', warehouseStorage.value)
        storeActions.newScan({ barcode_type, warehouse_id })
        form.setFields([
            { name: 'barcode_type', value: barcode_type },
            { name: 'warehouse_id', value: warehouse_id },
        ])
    }

    function _onSave() {
        if (!checkError()) {
            return
        }

        const doSave = () => {
            const data = omit(storeData, ['return_orders'])

            form.validateFields().then(() => {
                setLoading(true)
                const { skus, warehouse_id, note } = data

                // const receivedQuantityInvalid = skus.some(function (sku) {
                //     const received_quantity = get(sku, "received_quantity", 0);
                //     return !received_quantity;
                // });
                // if (receivedQuantityInvalid) {
                //     notification.error({message: t('document:messages.received_quantity_invalid'), duration: 6});
                // } else {

                const orderItem = map(returnOrders, item => {
                    const newSkus = map(get(item, 'skus.skus'), skus => ({ ...skus, quantity: skus.receiverQuantity }))

                    return {
                        id: item.order.id,
                        skus: newSkus,
                    }
                })

                const newData = {
                    warehouse_id,
                    note,
                    order_items: orderItem,
                }
                setDirty(true)
                api.createDocumentImportingByReturnOrder(newData)
                    .then(res => {
                        setDirty(false)
                        const dataImportingDocument = getVar(res, 'data.document', {})
                        storeActions.isSaved()
                        IdempotencyStorage.reset()
                        notification.success(t('document:messages.scan_importing_skus_successfully'))
                        dispatch(clearErrors(errorKey))
                        url.redirectTo('documents.importing.detail-importing-return-order', {
                            id: dataImportingDocument?.id,
                        })
                    })
                    .catch(err => {
                        const { response } = err
                        const code = get(response, 'data.code')
                        const data = get(response, 'data.data')
                        if (code === 'REQUEST_PROCESSED') {
                            setDirty(true)
                            notification.warning(t('message.request_process'))
                        } else {
                            if (code === INPUT_INVALID) {
                                let errorSku = []

                                let errorSerial = []
                                mapKeys(data, (value, key) => {
                                    if (key === 'sku') {
                                        errorSku.push(Object.keys(value)[0])
                                    }
                                    if (key === 'serial_number') {
                                        errorSerial.push(Object.keys(value)[0])
                                    }
                                })


                                if (!isEmpty(errorSerial) && !isEmpty(errorSku)) {
                                    notification.error(
                                        trans(`packing:message.serial_number.sku_invalid_serial`, {
                                            sku_code: <b>{errorSku[0]}</b>,
                                            serial_number: <b>{errorSerial[0]}</b>,
                                        })
                                    )
                                }
                                const listKeyException = ['serial_number_not_exist', 'serial_number_not_export']

                                Object.entries(data).forEach(([key, value]) => {
                                    if (listKeyException.includes(key)) {
                                        notification.error(trans(`document:messages.${key}`,{
                                            serial: <b>{Object.keys(value)[0]}</b>
                                        }))
                                    }
                                    
                                })
                            } else {
                                notification.error(t('document:messages.scan_importing_skus_fail'))
                            }
                            generatorKey()
                            setDirty(false)
                        }
                    })
                    .finally(() => setLoading(false))
                // }
            })
        }

        if (scanErrors?.length) {
            Modal.confirm({
                title: t('document:create_importing_document.scan_errors_confirm'),
                okText: t('btn.ok'),
                cancelText: t('btn.cancel'),
                onOk: doSave,
            })
        } else {
            doSave()
        }
    }

    const checkError = () => {
        const isError = every(flatMapDeep(getVar(storeData, 'errors', [])), ['alert', false])
        return isError
    }

    function scanData(data) {
        const { barcode } = data
        barcode.trim()
        setIsScanning(true)
        form.validateFields()
            .then(values => {
                const params = {
                    ...pick(values, ['warehouse_id', 'barcode_type']),
                    ...data,
                    playSound: false,
                }

                const key = map(params, (value, key) => `${key}:${value}`).join('-')

                scan(params).then(res => {
                    form.setFields([{ name: 'barcode', value: '' }])
                    let scannedData = getVar(res, 'data.document_importing_return_goods', {})
                    if (!isEmpty(scannedData)) {
                        const countryId = getVar(storeData, 'countryId', undefined)
                        if (!countryId) {
                            storeActions.updateScanInfo({
                                countryId: getVar(scannedData, 'order.receiver_country_id', undefined),
                            })
                        }
                        const freight_bill_code = getVar(scannedData, 'freight_bill.freight_bill_code', undefined)
                        const existReturnOrder = find(returnOrders, ['freight_bill.freight_bill_code', freight_bill_code])
                        if (isEmpty(existReturnOrder)) {
                            storeActions.addReturnOrder({ ...scannedData, scanned_at: Date.now() })
                            notification.success(t('document:messages.scan_barcode_successfully'))
                            playSuccessSound()
                        } else {
                            dispatch(
                                addError({
                                    type: errorKey,
                                    key,
                                    message: {
                                        errors: {
                                            [storeData.barcode_type.toLowerCase()]: { code_scanned: {} },
                                        },
                                        barcode: params?.barcode,
                                        barcode_type: params?.barcode_type,
                                    },
                                })
                            )
                            playErrorSound()
                        }
                    }
                })
            })
            .catch(() => {
                setIsScanning(false)
            })
    }

    return (
        <>
            {dirty && <LeavePageBlocker when={dirty} />}
            <Box className="header">
                <div className="search-box">
                    <div className="search-box--title d-lg-flex justify-content-between pb-4">
                        <Title level={3}>{t('title.scan_return_order')}</Title>
                        <div>
                            <span className="mr-2">
                                <Button
                                    className="_scan-return-order-scan-reset-btn rounded-4 mt-2 mt-md-0"
                                    onClick={_onReset}
                                >
                                    <ReloadOutlined /> {t('btn.reset')}
                                </Button>
                            </span>
                            <Button
                                className="_scan-return-order-scan-save-btn rounded-4 mt-2 mt-md-0"
                                type="primary"
                                disabled={!returnOrders || returnOrders.length === 0 || isSaved}
                                onClick={_onSave}
                                loading={loading}
                            >
                                <SaveOutlined /> {t('btn.save')}
                            </Button>
                        </div>
                    </div>

                    <Form
                        form={form}
                        layout="vertical"
                        initialValues={{
                            warehouse_id: warehouseStorage.value,
                            barcode_type: SCAN_RETURN_ORDER_TYPE.FREIGHT_BILL,
                        }}
                    >
                        <div className="search-box--body">
                            <Row gutter={20}>
                                <Col
                                    xs={{ span: 12 }}
                                    lg={{ span: 6 }}
                                >
                                    <Form.Item
                                        className="mb-0"
                                        name="warehouse_id"
                                        label={t('label.select_warehouses')}
                                        rules={validatedRules.warehouse_id}
                                    >
                                        <SelectWarehouse
                                            className="_scan-return-order-scan-warehouse-id"
                                            allowClear={true}
                                            placeholder={t('product:placeholder.warehouse_name')}
                                            onChange={value => _onChange('warehouse_id', value)}
                                            noSuggest={false}
                                            disabled={returnOrders && returnOrders.length > 0}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 12 }}
                                    lg={{ span: 6 }}
                                >
                                    <Form.Item
                                        className="mb-0"
                                        name="barcode_type"
                                        label={t('label.scan_type')}
                                        rules={validatedRules.barcode_type}
                                    >
                                        <Select
                                            className="_scan-return-order-scan-barcode-type"
                                            allowClear={true}
                                            placeholder={t('placeholder.scan_type')}
                                            onChange={value => _onChange('barcode_type', value)}
                                            disabled={returnOrders && returnOrders.length > 0}
                                        >
                                            {Object.values(SCAN_RETURN_ORDER_TYPE).map(item => (
                                                <Option
                                                    key={item}
                                                    value={item}
                                                >
                                                    {t(`document:scan_return_order.scan_type.${item}`)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col
                                    xs={{ span: 24 }}
                                    lg={{ span: 12 }}
                                >
                                    <Form.Item
                                        className={`mb-0 ${isMobile ? 'mt-3' : ''}`}
                                        name="barcode"
                                        label={t('order:label.barcode')}
                                        rules={validatedRules.barcode}
                                    >
                                        <Input
                                            className="_scan-return-order-scan-barcode"
                                            onPressEnter={e => scanData({ barcode: e.target.value.trim() })}
                                            placeholder={t('order:placeholder.barcode')}
                                            ref={barcodeInputRef}
                                            suffix={
                                                <ScanBarcode
                                                    onUpdate={value => scanData({ barcode: value.trim() })}
                                                    disabled={isScanning || isSaved}
                                                />
                                            }
                                            disabled={isScanning || isSaved}
                                            autoComplete="off"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </div>
            </Box>
        </>
    )
}
