import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import api from '@Modules/Finance/services/api';

import { t } from '@System/i18n';
import notification from '@System/notification';

import { notifyErrorFromServer } from './../../../system/support/helpers';

const useConfirmFinanceControlDocumentMutation = payload => {
    if (!payload) {
        payload = {};
    }

    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit(
        {
            ...payload,
            onSuccess: ({ data }) => {
                if (isFunction(payload?.onSuccess)) {
                    payload.onSuccess(data?.document);
                }

                notification.success(t('document:import_confirm.success'));
            },
            onError: error => {
                if (isFunction(payload?.onError)) {
                    payload.onError(error);
                }

                if (payload.notifyWhenError) {
                    notifyErrorFromServer(error, t('document:import_confirm.failed'), 'document:import_confirm.errors');
                }
            },
        },
        ['id', 'notifyWhenError']
    );

    return useMutation((data) => {
        return api.confirmFinanceControlDocument(data?.documentId, data);
    }, formattedConfig);
};

export default useConfirmFinanceControlDocumentMutation;
