import React, {useState, useEffect} from "react";
import {notification, Tabs} from "antd";
import api from "../../services/api";
import _ from "lodash";
import {t} from "../../../../system/i18n";
import {setDocumentTitle, setNavigator} from "../../../App/services";
import UpdateForm from "./UpdateForm";
import ListWarehouseAreaByWarehouse from "../../../WarehouseArea/screens/ListWarehouseAreaByWarehouse";

const {TabPane} = Tabs;

export default function WarehouseDetail({match}) {
    const {params} = match;
    const [data, setData] = useState(null);
    const warehouseId = params.id;
    const [activeKey, setActiveKey] = useState("1");

    useEffect(() => {
        setNavigator(t("common:title.warehouse"), [
            {
                name: t("common:breadcrumb.warehouses"),
                route: "warehouses.list"
            },
            {
                name: t("common:breadcrumb.warehouse")
            }
        ]);
    }, []);

    useEffect(() => {
        fetchWarehouse();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setDocumentTitle(t("title_page.warehouse", {
            code: _.get(data, "warehouse.code", ""),
            name: _.get(data, "warehouse.name", "")
        }));
    }, [data]);

    function fetchWarehouse() {
        api.getWarehouse(params.id)
            .then((res) => {
                setData(_.get(res, 'data', null));
            })
            .catch((err) => {
                const status = _.get(err, "response.status", null);
                if (status === 403)
                    notification.error({message: t('common:message.403')});
                else if (status === 404)
                    notification.error({message: t('common:message.404')});
                else
                    notification.error({message: t('common:message.fetch_fail')});
            });
    }

    return (
        <div className="site-content bg-white order-detail">
            <div className="title-page d-flex justify-content-between align-items-center pb-3">
                <h2 className="text-uppercase">[{_.get(data, 'warehouse.name', "")}]
                    - {t("common:heading.warehouse")}</h2>
            </div>
            <div>

                <Tabs
                    className="customize-ant-tabs"
                    defaultActiveKey={activeKey}
                    onChange={(activeKey) => setActiveKey(activeKey)}
                    type="card"
                >
                    <TabPane tab={t("warehouse:label.common_info")} key="1">
                        {
                            data ? <UpdateForm data={data} setData={setData}/> : null
                        }
                    </TabPane>
                    <TabPane tab={t("warehouse:label.list_warehouse_area")} key="2">
                        {
                            activeKey === "2" &&
                            <ListWarehouseAreaByWarehouse warehouseId={warehouseId}/>
                        }
                    </TabPane>
                    {/*<TabPane tab={t("warehouse:label.list_skus")} key="3">*/}
                        {/*{*/}
                            {/*activeKey === "3" && data &&*/}
                            {/*<ListStocks warehouse={data.warehouse} />*/}
                        {/*}*/}
                    {/*</TabPane>*/}
                </Tabs>
            </div>
        </div>
    );
}
