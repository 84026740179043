import React, {useEffect, useState} from "react";
import {setDocumentTitle, setNavigator} from "@Modules/App/services";
import {t} from "@System/i18n";
import useFilter from "@Modules/App/Hooks/useFilter";
import FormSearch from "@Modules/PurchasingOrder/screens/ListPurchasingOrders/FormSearch";
import List from "@Modules/PurchasingOrder/screens/ListPurchasingOrders/List";
import _ from "lodash";
import useListPurchasingOrderQuery from "@Modules/PurchasingOrder/Hooks/useListPurchasingOrderQuery";

const initFilters = {
    tab_vendor: 1,
    code: "",
    purchasing_account_id: "",
    marketplace: "",
    supplier: "",
    status: "",
    sku_code: "",
    merchant_id: "",
    "total_value[from]": "",
    "total_value[to]": "",
    "ordered_at[from]": "",
    "ordered_at[to]": "",
    page: "",
    per_page: "",
    current_page: ""
};

export default function ListPurchasingOrders({history, location}) {
    const {filters, addFilter} = useFilter(history, location, initFilters);
    const [purchasingOrders, setPurchasingOrders] = useState([]);
    const [pagination, setPagination] = useState({});
    const {data, isFetching:loading, refetch} = useListPurchasingOrderQuery(filters);
    useEffect(() => {
        setNavigator(t("title.purchasing_orders"), [
            {
                name: t("breadcrumb.list_purchasing_orders_by_vendor")
            }
        ]);
        setDocumentTitle(t("breadcrumb.list_purchasing_orders_by_vendor"));
    }, []);

    useEffect(() => {
        setPurchasingOrders(_.get(data, 'data.purchasing_orders', []));
        setPagination(_.get(data, 'data.pagination', {}));
    }, [data]);

    return (
        <div className="site-content">
            <FormSearch
                onSearch={addFilter}
                filters={filters}
                initFilters={initFilters}
                loading={loading}
            />
            <List
                purchasingOrders={purchasingOrders}
                pagination={pagination}
                addFilter={addFilter}
                filters={filters}
                loading={loading}
                fetchPurchasingOrders={refetch}
            />
        </div>
    );
}
