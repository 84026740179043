import { CheckOutlined, ExclamationCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Space, Table, Form, Typography, Select, Tooltip, Checkbox, Modal } from 'antd';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findLastIndex from 'lodash/findLastIndex';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import pick from 'lodash/pick';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';

import Box from '@Modules/App/Box';
import TableEmpty from '@Modules/App/Utilities/TableEmpty';
import { updateCollectionItem } from '@Modules/App/services/helpers';
import styles from '@Modules/Document/screens/Importing/ScanPurchasePackage/scan-purchase-package.module.scss';
import apiService from '@Modules/Services/services/api';
import { SERVICE_TYPE } from '@Modules/Services/services/constants';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import { formatCurrency } from '@System/support/numberFormat';

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

function ServiceList({ storeActions, storeData, form }) {
    const isSaved = getVar(storeData, 'isSaved', false);
    const countryId = getVar(storeData, 'countryId', false);
    const serviceInPackage = get(storeData, 'purchase_packages.0.purchasing_package_services_id', []);
    const [skus, setSkus] = useState([]);
    const [currency, setCurrency] = useState({});
    const [dataSource, setDataSource] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [services, setServices] = useState(getVar(storeData, 'services', []));
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            let newServices = [];
            const services = getVar(storeData, 'services', []);
            let newSelectedRowKeys = [];
            if (selectedRows.length > 0) {
                newSelectedRowKeys = selectedRowKeys;
                selectedRows.map(item => {
                    newServices.push(formatService(item));
                });
            } else {
                newServices = filter(services, item => item.service.is_required);
            }
            setSelectedRowKeys(newSelectedRowKeys);
            storeActions.updateServices(newServices);
        },
        renderCell: (checked, record) => {
            const disabledRow = get(record, 'service.is_required');
            return (
                <>
                    {disabledRow ? (
                        <Tooltip title={t('message.service_required')}>
                            <Checkbox checked={disabledRow} disabled={disabledRow} />
                        </Tooltip>
                    ) : (
                        <Checkbox checked={checked} onChange={() => handleChangeCheckbox(checked, record)} />
                    )}
                </>
            );
        },
    };

    function formatService(info) {
        const service = pick(info, ['service', 'amount', 'price', 'service_price_id', 'sku_ids', 'quantity', 'key']);
        let price = getVar(info, 'price', 0);
        let quantity = getVar(info, 'quantity', '');
        let amount = getVar(info, 'amount', '');
        let sku_ids = getVar(info, 'sku_ids', []);
        if (sku_ids.length === 0) {
            sku_ids = map(skus, 'id');
            quantity = sumBy(skus, 'received_quantity');
            amount = price * quantity;
        }
        return { ...service, quantity, amount, sku_ids };
    }

    const handleChangeCheckbox = (checked, record) => {
        const { key } = record;
        let newSelectedServiceKeys = [...selectedRowKeys, key];
        let newServices = [...services, formatService(record)];
        if (checked) {
            newSelectedServiceKeys = filter(selectedRowKeys, item => item !== key);
            newServices = filter(services, item => item.key !== key);
        }
        storeActions.updateServices(newServices);
        setSelectedRowKeys(newSelectedServiceKeys);
    };

    useEffect(() => {
        setSkus(getVar(storeData, 'skus', []));
    }, [storeData]);

    useEffect(() => {
        const services = getVar(storeData, 'services', []);
        const servicesSelected = map(services, 'key');
        setSelectedRowKeys(servicesSelected);
        const data = [];
        map(services, item => {
            let price = getVar(item, 'price', 0);
            let quantity = getVar(item, 'quantity', '');
            let amount = getVar(item, 'amount', '');
            const sku_ids = getVar(item, 'sku_ids', []);
            if (sku_ids.length > 0) {
                quantity = sumBy(skus, item => {
                    if (sku_ids.includes(item.id)) {
                        return Number(getVar(item, 'received_quantity', 0));
                    }
                    return 0;
                });
                amount = price * quantity;
            }
            data.push({ ...item, quantity, amount });
        });
        setServices(data);
    }, [storeData, skus]);

    useEffect(() => {
        const data = [];
        dataSource.map(item => {
            const serviceInfo = find(services, ['key', item.key]);
            if (!isEmpty(serviceInfo)) {
                data.push({
                    ...item,
                    sku_ids: getVar(serviceInfo, 'sku_ids', item?.sku_ids),
                    quantity: getVar(serviceInfo, 'quantity', item?.quantity),
                    amount: getVar(serviceInfo, 'amount', item?.amount),
                });
            } else {
                data.push(item);
            }
        });
        setDataSource(data);
    }, [services]);

    useEffect(() => {
        if (countryId) {
            apiService.list({ country_id: countryId, hidden_init_service: true }).then(res => {
                let services = getVar(res, 'data.services', []);
                setCurrency(getVar(res, 'data.currency', {}));
                services = services.filter(item => {
                    const type = getVar(item, 'service.type', '');
                    return type === SERVICE_TYPE.IMPORT;
                });
                const { newServices, newData } = refactorServiceSource(services);
                setDataSource(newData);
                storeActions.updateServices(newServices);
            });
        }
    }, [countryId]);

    function refactorServiceSource(data) {
        const newData = [];
        const newServices = getVar(storeData, 'services', []);
        data.map(item => {
            const isRequired = getVar(item, 'service.is_required', false);
            const isUnExists = isEmpty(find(services, ['service.id', getVar(item, 'service.id')]));
            const servicePrices = getVar(item, 'servicePrices', []);
            const servicePriceDefaultInfo = find(servicePrices, ['is_default', true]);
            const service_price_id = getVar(servicePriceDefaultInfo, 'id', undefined);
            const price = getVar(servicePriceDefaultInfo, 'price', 0);
            let info = {
                ...item,
                key: v4(),
                service_price_id,
                price,
                sku_ids: [],
                quantity: '',
                amount: '',
                isParent: 1,
            };
            if (services.length > 0) {
                let hasServicePrice = false;
                let stt = 1;
                servicePrices.map(servicePrice => {
                    const servicesInfo = filter(services, ['service_price_id', servicePrice.id]);
                    servicesInfo.map(serviceInfo => {
                        hasServicePrice = true;
                        newData.push({
                            ...item,
                            sku_ids: getVar(serviceInfo, 'sku_ids', []),
                            key: serviceInfo.key,
                            service_price_id: servicePrice.id,
                            price: getVar(serviceInfo, 'price', 0),
                            quantity: getVar(serviceInfo, 'quantity', ''),
                            amount: getVar(serviceInfo, 'amount', 0),
                            isParent: stt === 1 ? 1 : undefined,
                        });
                        stt++;
                    });
                });
                if (!hasServicePrice) newData.push(info);
            } else {
                newData.push(info);
            }
            if (isRequired && isUnExists) {
                newServices.push(formatService(info));
            }
        });
        return { newData, newServices };
    }
    const columns = [
        {
            className: '_service-list-name text-nowrap',
            title: t('label.service'),
            dataIndex: ['service', 'name'],
            key: 'name',
        },
        {
            className: '_service-price-level text-nowrap',
            title: t('label.price_level'),
            dataIndex: 'service_price_id',
            editable: !isSaved,
            render: (text, record) => {
                const servicePrices = getVar(record, 'servicePrices', []);
                const servicePricesInfo = find(servicePrices, ['id', text]);
                return !isEmpty(servicePricesInfo) ? getVar(servicePricesInfo, 'label', '') : t('label.not_available');
            },
        },
        {
            className: '_service-list-unit-price',
            title: t('label.unit_price'),
            dataIndex: 'price',
            width: '150px',
            render: (text, record) => {
                const service_price_id = getVar(record, 'service_price_id', undefined);
                const price = getVar(record, 'price', 0);
                return service_price_id
                    ? price
                        ? formatCurrency(price, isEmpty(currency) ? null : currency)
                        : t('label.price_free')
                    : t('label.not_available');
            },
        },
        {
            className: '_service-list-sku-apply',
            title: t('label.sku_apply'),
            dataIndex: 'sku_ids',
            editable: !isSaved,
            width: '420px',
            render: (text, record) => {
                const sku_ids = getVar(record, 'sku_ids', []);
                const skus_code = filter(
                    map(skus, item => {
                        if (sku_ids.includes(item.id)) {
                            return item.code;
                        }
                        return undefined;
                    }),
                    item => !!item
                );
                return skus_code.length > 0 ? skus_code.join(', ') : '';
            },
        },
        {
            className: '_service-list-quantity text-right text-nowrap',
            title: t('label.quantity'),
            dataIndex: 'quantity',
            width: '100px',
            render: (text, record) => {
                return getVar(record, 'quantity', t('label.not_available'));
            },
        },
        {
            className: '_service-list-sum-total-money',
            title: t('label.sum_total_money'),
            dataIndex: 'amount',
            width: '150px',
            render: (text, record) => {
                const amount = formatCurrency(getVar(record, 'amount', 0), isEmpty(currency) ? null : currency);
                const price = formatCurrency(getVar(record, 'price', 0), isEmpty(currency) ? null : currency);
                const service_price_id = getVar(record, 'service_price_id', undefined);
                return service_price_id
                    ? amount
                        ? amount
                        : price
                        ? t('label.not_available')
                        : t('label.price_free')
                    : t('label.not_available');
            },
        },
        {
            className: 'text-right _importing-document-product-action',
            width: '50px',
            dataIndex: 'product-action',
            render: (text, record) => {
                const isParent = get(record, 'isParent', false);
                const renderAction = isParent ? (
                    <span
                        className="_span--add cursor-pointer _importing-document-product-action-add"
                        onClick={() => handleAddRow(record)}
                    >
                        <PlusOutlined />
                    </span>
                ) : (
                    <span
                        className="_span--delete cursor-pointer _importing-document-product-action-delete"
                        onClick={() => handleDeleteRow(record)}
                    >
                        <MinusOutlined />
                    </span>
                );
                return isSaved ? null : renderAction;
            },
        },
    ];

    const handleAddRow = data => {
        const newServices = [...dataSource];
        const lastIndex = findLastIndex(newServices, ['service.id', data.service.id]);
        const payload = {
            ...data,
            service_price_id: undefined,
            price: '',
            sku_ids: [],
            quantity: '',
            amount: '',
            key: v4(),
            isParent: 0,
        };
        newServices.splice(lastIndex + 1, 0, payload);
        storeActions.updateServices([...services, pick(payload, ['key'])]);
        setDataSource(newServices);
    };

    const handleDeleteRow = data => {
        const newDataSource = [...dataSource];
        const newServices = [...services];
        const lastSourceIndex = findLastIndex(newDataSource, ['key', data.key]);
        const lastIndex = findLastIndex(newServices, ['key', data.key]);
        newDataSource.splice(lastSourceIndex, 1);
        newServices.splice(lastIndex, 1);
        setDataSource(newDataSource);
        storeActions.updateServices(newServices);
    };

    function checkServiceDuplicate(data, value, index, dataIndex) {
        const sku_ids_of_service = filter(
            flattenDeep(
                map(dataSource, item => {
                    if (item.service.id === data.service.id && item.key !== data.key) {
                        return getVar(item, 'sku_ids', []);
                    }
                })
            ),
            item => !!item
        );

        let validate = false;
        let scroll = false;
        if (dataIndex === 'service_price_id') {
            if (!value) scroll = true;
            const servicesInfo = filter(dataSource, item => item.service_price_id === value);
            validate = servicesInfo.length > 1;
        } else {
            if (isEmpty(value)) scroll = true;
            validate = value.some(r => sku_ids_of_service.includes(r));
        }

        if (validate || scroll) {
            const input = document.querySelector(`input[id=services_${index}_${dataIndex}]`);
            input.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start',
            });
        }
        return validate;
    }

    function updateServiceInfo(name, value, data) {
        const serviceInfo = find(dataSource, ['key', data.key]);
        if (!isEmpty(serviceInfo) && serviceInfo[name] !== value) {
            const servicePrices = getVar(data, 'servicePrices', []);
            let service_price_id = getVar(data, 'service_price_id', undefined);
            let quantity = getVar(data, 'quantity', 0);
            let sku_ids = getVar(data, 'sku_ids', map(skus, 'id'));
            if (name === 'service_price_id') service_price_id = value;
            if (name === 'sku_ids') {
                sku_ids = value;
                quantity = sumBy(skus, item => {
                    if (sku_ids.includes(item.id)) {
                        return Number(getVar(item, 'received_quantity', 0));
                    }
                    return 0;
                });
            }
            const servicePriceInfo = find(servicePrices, ['id', service_price_id]);
            const price = getVar(servicePriceInfo, 'price', 0);
            const amount = !price || !quantity ? '' : price * quantity;
            const payload = { [name]: value, price, quantity, amount, service_price_id, sku_ids };
            const newDataSources = updateCollectionItem(dataSource, data.key, { ...payload }, 'key');
            const newServices = updateCollectionItem(services, data.key, { ...payload }, 'key');
            setDataSource(newDataSources);
            storeActions.updateServices(newServices);
        }
    }

    const tableColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record, index) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                index,
                checkServiceDuplicate,
                updateServiceInfo,
                selectedRowKeys,
            }),
        };
    });

    const EditableCell = ({
        editable,
        children,
        dataIndex,
        index,
        record,
        checkServiceDuplicate,
        updateServiceInfo,
        selectedRowKeys,
        ...restProps
    }) => {
        const servicePrices = getVar(record, 'servicePrices', []);
        useEffect(() => {
            if (editable) {
                form.setFields([{ name: ['services', index, dataIndex], value: record[dataIndex] }]);
            }
        }, [editable]);

        const onChangeWithCheckDuplicate = (name, value) => {
            form.setFields([{ name: ['services', index, name], value: value, errors: '' }]);
            updateServiceInfo(name, value, record);
        };

        const showConfirm = (name, value, record) => {
            confirm({
                title: t('document:messages.confirm_remove_sku'),
                icon: <ExclamationCircleOutlined />,
                onOk: () => {
                    form.setFields([{ name: ['services', index, name], value: value, errors: '' }]);
                    updateServiceInfo(name, value, record);
                },
                okText: t('btn.ok'),
                cancelText: t('btn.close'),
            });
        };
        const onChangeSkuApply = (name, value, record) => {
            setSkus(prev => [...prev]);
            const currentValue = get(record, 'sku_ids');

            if (value?.length < currentValue.length) {
                showConfirm(name, value, record);
            } else {
                const ids_sku = map(skus, 'id');

                form.setFields([
                    { name: ['services', index, name], value: value.includes('all') ? ids_sku : value, errors: '' },
                ]);
                updateServiceInfo(name, value.includes('all') ? ids_sku : value, record);
            }
        };

        let childNode = children;
        if (editable && selectedRowKeys.includes(record.key)) {
            if (dataIndex === 'service_price_id') {
                childNode = (
                    <Form.Item
                        className="mb-0"
                        name={['services', index, dataIndex]}
                        rules={[
                            { required: true, message: t('product:message.required_not_attribute') },
                            () => ({
                                validator(rule, value) {
                                    if (checkServiceDuplicate(record, value, index, dataIndex)) {
                                        return Promise.reject(t('product:message.duplicate_not_attribute'));
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select
                            placeholder={t('placeholder.choose_price_level')}
                            allowClear={true}
                            onChange={value => onChangeWithCheckDuplicate(dataIndex, value)}
                            value={record[dataIndex] ? parseInt(record[dataIndex]) : undefined}
                            showSearch
                            optionFilterProp="label"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {servicePrices.map(item => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                );
            } else {
                childNode = (
                    <Form.Item
                        className="mb-0"
                        name={['services', index, dataIndex]}
                        rules={[
                            { required: true, message: t('product:message.required_not_attribute') },
                            () => ({
                                validator(rule, value) {
                                    if (checkServiceDuplicate(record, value, index, dataIndex)) {
                                        return Promise.reject(t('product:message.sku_in_service_price_not_duplicate'));
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Select
                            className="_select-sku-apply"
                            dropdownClassName="_select-sku-apply-dropdown"
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder={t('placeholder.sku_apply')}
                            onChange={value => onChangeSkuApply(dataIndex, value, record)}
                        >
                            {map(skus, 'id').length !== get(record, 'sku_ids').length && (
                                <Option
                                    value={'all'}
                                    key={'all'}
                                    className={
                                        map(skus, 'id').length === get(record, 'sku_ids').length
                                            ? 'document-select-all-multi'
                                            : ''
                                    }
                                >
                                    {t('label.all')}
                                    {map(skus, 'id').length === get(record, 'sku_ids').length && <CheckOutlined />}
                                </Option>
                            )}

                            {skus.map(item => {
                                return (
                                    <Option value={item.id} key={item.id}>
                                        {item.code}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                );
            }
        }

        return <td {...restProps}>{childNode}</td>;
    };

    if (dataSource.length === 0) return null;

    let newService = dataSource.filter(
        item => serviceInPackage.includes(item.service.id) || item.service.status === 'ACTIVE'
        
    );
    return (
        <Box className={styles.service}>
            <Table
                scroll={{ x: 576 }}
                dataSource={newService}
                columns={tableColumns}
                pagination={false}
                rowSelection={isSaved ? null : rowSelection}
                className="_service-list-info"
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                title={() => (
                    <div className="d-flex justify-content-between border-bottom align-items-center p-0">
                        <Space className="mb-3">
                            <Title level={5}>{t('title.service_list')}</Title>
                        </Space>
                    </div>
                )}
                footer={() => {
                    return services.length > 0 ? (
                        <div className="d-flex justify-content-between">
                            <strong>{t('label.total_cost')}</strong>
                            <strong className="_service-list-total">{`${formatCurrency(
                                sumBy(services, item => {
                                    const amount = getVar(item, 'amount', 0);
                                    if (amount) {
                                        return Number(amount);
                                    }
                                    return 0;
                                }),
                                isEmpty(currency) ? null : currency
                            )}`}</strong>
                        </div>
                    ) : (
                        false
                    );
                }}
                locale={{ emptyText: <TableEmpty className="_service-list-no-data" /> }}
            />
        </Box>
    );
}
export default ServiceList;
