import { Table } from 'antd'
import { get, map } from 'lodash'
import React from 'react'

import { t } from '@System/i18n'

const TableChildBatch = ({ data }) => {
    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            width: '30px',
        },
        {
            title: t('product:label.batch_sku_code'),
            className: '_inventory-list-warehouse-name td-border-right',
            dataIndex: 'code',
            key: 'code',
            width: '16.666667%',
        },
        {
            className: '_inventory-list-warehouse-name td-border-many',
            title: t('product:label.warehouse_name'),
            dataIndex: 'stocks',
            key: 'stocks',
            width: '16.666667%',
            render: (text, record) => {
                const warehouses = get(text, 'data')

                return map(warehouses, item => {
                    let warehouse_code = get(item, 'warehouse.data.code', '')
                    let warehouse_name = get(item, 'warehouse.data.name', '')
                    if (warehouse_code === 'default') warehouse_code = t('order:label.default')
                    if (warehouse_name === 'default') warehouse_name = t('order:label.default')
                    return <div className="text-nowrap child-item">{warehouse_name + (warehouse_code ? ' (' + warehouse_code + ')' : '')}</div>
                })
            },
        },

        {
            className: '_inventory-list-warehouse-area-name td-border-many',
            title: t('product:label.warehouse_area_name'),
            dataIndex: 'stocks',
            key: 'stock',
            width: '16.666667%',
            render: (text, record) => {
                const warehouses = get(text, 'data')
                return map(warehouses, item => {
                    let warehouseArea_code = get(item, 'warehouse_area.data.code', '')
                    let warehouseArea_name = get(item, 'warehouse_area.data.name', '')
                    if (warehouseArea_code === 'default') warehouseArea_code = t('order:label.default')
                    if (warehouseArea_name === 'default') warehouseArea_name = t('order:label.default')
                    return <div className="text-nowrap child-item">{warehouseArea_name + (warehouseArea_code ? ' (' + warehouseArea_code + ')' : '---')}</div>
                })
            },
        },
        {
            title: t('product:label.batch_code'),
            dataIndex: 'stocks',
            className: 'td-border-many',
            key: 'batch_code',
            width: '16.8%',
            render: (text, record) => {
                const warehouses = get(text, 'data')
                return map(warehouses, item => {
                    const batchCode = get(item, 'batch_code')
                    return <div className="text-nowrap child-item">{batchCode ? batchCode : '---'}</div>
                })
            },
        },
        {
            className: '_inventory-list-sku-stock td-border-many',
            title: t('inventory:actual_inventory'),
            dataIndex: 'stocks',
            key: 'actual_inventory',
            width: '16.8%',
            render: (text, record) => {
                const warehouses = get(text, 'data')
                return map(warehouses, item => {
                    const realQuantity = get(item, 'real_quantity')
                    return <div className="text-nowrap child-item">{realQuantity ? realQuantity : 0}</div>
                })
            },
        },
        {
            className: '_inventory-list-sku-stock-tmp td-border-many',
            title: t('inventory:label.temporary_inventory'),
            dataIndex: 'stocks',
            key: 'temporary_inventory',
            width: '16.666667%',
            render: (text, record) => {
                const warehouses = get(text, 'data')
                return map(warehouses, item => {
                    const quantity = get(item, 'quantity')
                    return <div className="text-nowrap child-item">{quantity ? quantity : 0}</div>
                })
            },
        },
    ]

    return (
        <div className="">
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={record => record.id}
                // bordered
            />
        </div>
    )
}

export default TableChildBatch
