import React, {useEffect, useState} from "react";
import {Button, Col, notification, Row, Table} from "antd";
import {setDocumentTitle, setNavigator} from "../../../App/services";
import {t} from "../../../../system/i18n";
import _ from "lodash";
import api from "../../services/api";
import {dateFormatter} from "../../../App/services";
import {url} from "../../../../system/routing";
import {PlusOutlined} from "@ant-design/icons";
import {can} from "../../../Auth/middleware/authorize";
import PERMISSIONS from "../../../Auth/services/permissions";
import processResponseError from "@System/api/processResponseError";

export default function DetailedBill({match}) {
    const {params} = match;
    const [detailedBill, setDetailedBill] = useState(null);
    let {deliveryNote, creator, skus, warehouse} = detailedBill || {};

    useEffect(() => {
        setNavigator(t("title.detailed_bill"), [
            { name: t("breadcrumb.manage_exporting"), route: "list-exported-bills" },
            { name: t("breadcrumb.detailed_bill") }
        ]);
        fetchExportedBill();
    }, []);

    useEffect(() => {
        setDocumentTitle(t("title_page.imported_history", {code: _.get(detailedBill, "importHistory.code", "")}));
    }, [detailedBill]);

    function fetchExportedBill() {
        api.getExportedBill(params.id)
            .then(res => {
                setDetailedBill(_.get(res, "data.deliveryNote", null));
            })
            .catch(err => {
                processResponseError(err)
            });
    }

    let getColumns = [
        {
            title: t("manageExporting:label.code"),
            dataIndex: "sku_id",
            key: 'sku_id',
            width: "40%",
            render: (text, record) => {
                let {sku} = record;
                return sku.code + " - " + sku.name;
            },
        },
        {
            title: t("manageExporting:label.warehouse_area_id"),
            dataIndex: 'warehouse_area_id',
            key: 'warehouse_area_id',
            width: "25%",
            render: (text, record) => {
                let {warehouseArea : {name}, merchant} = record;
                if (name === "default") name = t("order:label.default");
                return name + " - " + merchant.name;
            },
        },
        {
            title: t("manageExporting:label.exported_quantity"),
            dataIndex: 'quantity',
            key: 'quantity',
            className: 'text-center',
            width: "15%",
        }
    ];

    if (!detailedBill)
        return null;

    return (
        <div className="site-content bg-white">
            <div className="title-page pb-3 d-flex justify-content-between align-items-center">
                <h2 className="text-uppercase">[#{deliveryNote ? deliveryNote.id : ""}] - {t("common:heading.new_exported_bill")}</h2>
                {
                    can(PERMISSIONS.DELIVERY_NOTE_CREATE) &&
                    <div>
                        <Button type="primary" onClick={() => url.redirectTo('new-exported-bill')}><PlusOutlined /> {t("manageExporting:label.create_bill_btn")}</Button>
                    </div>
                }
            </div>
            <div className="border p-4">
                <Row gutter={20}>
                    <Col span={10} offset={2}>
                        <p className="mt-2 mb-2">{t("manageExporting:label.created_at")}: <strong style={{fontWeight: '500'}}>{dateFormatter.formatDate(deliveryNote.created_at, "DD/MM/YYYY HH:mm")}</strong></p>
                        <p className="mt-2 mb-2">{t("manageExporting:label.warehouse_id")}: <strong style={{fontWeight: '500'}}>{warehouse.name}</strong></p>
                    </Col>

                    <Col span={10}>
                        <p className="mt-2 mb-2">{t("manageExporting:label.creator")}: <strong style={{fontWeight: '500'}}>{creator ? creator.name : ""}</strong></p>
                        <p className="mt-2 mb-2">{t("manageExporting:label.note")}: <strong style={{fontWeight: '500'}}>{deliveryNote.note ? deliveryNote.note : "---"}</strong></p>
                    </Col>

                    <Col span={20} offset={2}>
                        <Table
                            className="list-products-table pb-3 pt-4"
                            dataSource={skus}
                            rowKey={"id"}
                            columns={getColumns}
                            pagination={false}
                            bordered={true}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}
