import { Button, Col, DatePicker, Form, Input, Row, Typography } from 'antd'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import React, { useEffect } from 'react'

import StyledTag from '@Modules/App/StyledTag'
import InputNumber from '@Modules/App/Utilities/InputNumber'
import { dateFormatter } from '@Modules/App/services'
import { auth } from '@Modules/Auth/services'
import PERMISSIONS from '@Modules/Auth/services/permissions'
import api from '@Modules/Finance/services/api'
import { STATUS_FINANCE_CONTROL_DOCUMENT, STATUS_FINANCE_CONTROL_DOCUMENT_COLORS } from '@Modules/Finance/services/constants'

import { trans, t } from '@System/i18n'
import notification from '@System/notification'
import { getVar } from '@System/support/helpers'

const { Paragraph } = Typography
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY HH:mm'

const Header = ({ documentInfo, handleDelete, handleConfirm, shippingPartner, documentId, form }) => {
    const status = documentInfo?.status
    useEffect(() => {
        form.setFieldsValue({
            received_date: documentInfo?.received_date ? moment(documentInfo?.received_date) : undefined,
            payment_slip: documentInfo?.info?.payment_slip,
            received_pay_date: documentInfo?.received_pay_date ? moment(documentInfo?.received_pay_date) : undefined,
            note: documentInfo?.note,
        })
    }, [form, documentInfo])

    const onEdit = async (value, key) => {
        if (isEmpty(value)) {
            return
        }

        try {
            const data = { [key]: value }

            await api.updateFreightBill(documentId, data)
            notification.success(t('message.update_success_by_attribute', { attribute: trans('title.finance_control_detail') }))
        } catch (error) {
            notification.error(t('message.update_failed_by_attribute', { attribute: trans('title.finance_control_detail') }))
        }
    }

    return (
        <div className="w-100 bg-white mb-3 p-3">
            <Row className="mb-3 mb-lg-0 flex justify-content-between flex-wrap">
                <Col
                    span={24}
                    lg={{ span: 20 }}
                >
                    <div className="d-flex align-items-center">
                        <StyledTag
                            className="mb-1 _importing-document-status"
                            type={documentInfo?.status === STATUS_FINANCE_CONTROL_DOCUMENT.COMPLETED && 'success'}
                            color={documentInfo?.status ? STATUS_FINANCE_CONTROL_DOCUMENT_COLORS[documentInfo?.status] : undefined}
                        >
                            {trans(`finance:finance_control.status.${documentInfo?.status}`)}
                        </StyledTag>
                        <Paragraph className="font-weight-bold text-fz-16 mb-0 mr-2 _importing-document-code">
                            {trans('title.finance_control_detail')}: #{documentInfo?.code}
                        </Paragraph>
                        <div className="ml-3">
                            {t('order:label.order_shipping_partner')}: {getVar(shippingPartner, 'name', t('noText'))}
                        </div>
                    </div>
                    <div className="_importing-document-created-by">
                        {trans('document:created_by', {
                            date: dateFormatter.date(documentInfo?.created_at),
                            confirmer: documentInfo?.creator_username || '--',
                        })}
                    </div>
                    <Row
                        gutter={20}
                        className="mt-4"
                    >
                        <Col
                            xs={{ span: 24 }}
                            md={{ span: 8 }}
                            lg={{ span: 6 }}
                        >
                            <Form.Item
                                label={t('finance:label.date_receipt_documents')}
                                name="received_date"
                            >
                                <DatePicker
                                    placeholder={t('finance:placeholder.select_date')}
                                    style={{ width: '100%' }}
                                    format={dateFormat}
                                    showTime
                                    onChange={value => onEdit(value ? value.format(dateFormat) : undefined, 'received_date')}
                                    disabled={status !== STATUS_FINANCE_CONTROL_DOCUMENT.DRAFT}
                                    allowClear={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            md={{ span: 7 }}
                            lg={{ span: 5 }}
                        >
                            <Form.Item
                                label={t('finance:label.unc')}
                                name="payment_slip"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: t('order:message.required', { attribute: t('finance:label.unc') }),
                                //     },
                                // ]}
                            >
                                <Input
                                    placeholder={t('finance:placeholder.unc')}
                                    onBlur={e => onEdit(e.target.value, 'payment_slip')}
                                    onPressEnter={e => onEdit(e.target.value, 'payment_slip')}
                                    disabled={status !== STATUS_FINANCE_CONTROL_DOCUMENT.DRAFT}
                                />
                            </Form.Item>
                        </Col>
                        <Col
                            xs={{ span: 12 }}
                            md={{ span: 8 }}
                            lg={{ span: 5 }}
                        >
                            <Form.Item
                                label={t('finance:label.payment_receipt_date')}
                                name="received_pay_date"
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: t('order:message.required', {
                                //             attribute: t('finance:label.payment_receipt_date'),
                                //         }),
                                //     },
                                // ]}
                            >
                                <DatePicker
                                    placeholder={t('finance:placeholder.select_date')}
                                    style={{ width: '100%' }}
                                    format={dateFormat}
                                    showTime
                                    onChange={value => onEdit(value ? value.format(dateFormat) : undefined, 'received_pay_date')}
                                    allowClear={false}
                                    disabled={status !== STATUS_FINANCE_CONTROL_DOCUMENT.DRAFT}
                                />
                            </Form.Item>
                        </Col>
                        <Col lg={{ offset: 4 }}></Col>
                        <Col
                            xs={{ span: 24 }}
                            lg={{ span: 9 }}
                        >
                            <Form.Item
                                label={t('note')}
                                name="note"
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={t('placeholder.note')}
                                    onBlur={e => onEdit(e.target.value, 'note')}
                                    disabled={status !== STATUS_FINANCE_CONTROL_DOCUMENT.DRAFT}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>

                <div className="d-flex flex-wrap">
                    {documentInfo?.status === STATUS_FINANCE_CONTROL_DOCUMENT.DRAFT && (
                        <>
                            <Button
                                className="_importing-document-btn-save"
                                onClick={handleDelete}
                            >
                                {trans('btn.cancel')}
                            </Button>
                            {auth.can(PERMISSIONS.FINANCE_CONFIRM_STATEMENT) && (
                                <Button
                                    className="ml-3 _importing-document-btn-confirm"
                                    type="primary"
                                    onClick={handleConfirm}
                                >
                                    {trans('btn.confirm')}
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </Row>
        </div>
    )
}

export default Header
