import _, { omit } from 'lodash';
import React, { useState, useEffect } from 'react';

import useTableSelection from '@Modules/App/Hooks/useTableSelection';
import useListVariantsQuery from '@Modules/ProductVariants/Hooks/useListVariantsQuery';

import { events } from '../../../../system/events';
import { t } from '../../../../system/i18n';
import useFilter from '../../../App/Hooks/useFilter';
import { setDocumentTitle, setNavigator } from '../../../App/services';
import { auth } from '../../../Auth/services';
import PERMISSIONS from '../../../Auth/services/permissions';
import UploadVariantPrice from '../../components/UploadVariantPrice';
import { EVENTS } from '../../services/constants';
import FormSearch from './FormSearch';
import List from './List';
import useDeepCompareEffect from '@Modules/App/Hooks/useDeepCompareEffect';

const initFilters = {
    code: '',
    category_id: '',
    status: 'ON_SELL',
    created_at_from: '',
    created_at_to: '',
    page: '',
    per_page: '',
    inventory: '',
    id: '',
};

export default function ListVariant({ history, location }) {
    const { filters, addFilter } = useFilter(history, location, initFilters);
    const [skus, setSkus] = useState([]);
    const [pagination, setPagination] = useState({});

    const { data, isFetching: loading, refetch } = useListVariantsQuery(filters);
 
    useEffect(() => {
        setNavigator(t('common:title.list-variants'), [
            {
                name: t('common:breadcrumb.products'),
                route: 'products.list',
            },
            {
                name: t('common:breadcrumb.list-variants'),
            },
        ]);
        setDocumentTitle(t('title.list-variants'));

        events.listen(EVENTS.RELOAD_PRODUCT_LIST, () => {
            refetch();
        });
        return events.listen(EVENTS.IMPORT_PRODUCT_FROM_EXCEL, () => {
            addFilter(initFilters);
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setSkus(_.get(data, 'data.skus', []));
        setPagination(_.get(data, 'data.pagination', {}));
    }, [data]);


    return (
        <div className="site-content">
            <div className="bg-white">
                <div className="title-page border-bottom d-flex flex-wrap align-items-center _variant-list">
                    <h2 className="mr-auto p-3">{t('common:heading.list-variants')}</h2>
                    <div className="p-3">
                        {/*{*/}
                        {/*auth.can(PERMISSIONS.SKU_CREATE) &&*/}
                        {/*<UploadVariants/>*/}
                        {/*}*/}
                        {auth.can(PERMISSIONS.SKU_IMPORT_PRICE) && <UploadVariantPrice />}
                        {/*{*/}
                        {/*auth.can(PERMISSIONS.SKU_CREATE) &&*/}
                        {/*<CreateVariant/>*/}
                        {/*}*/}
                    </div>
                </div>
                <div>
                    <FormSearch addFilter={addFilter} filters={filters} initFilters={initFilters} loading={loading} />
                    <List
                        skus={skus}
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
}
