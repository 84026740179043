import createUseQuery from '@Modules/App/Hooks/createUseQuery';

import apiService from '../services/api';

const useImportHistoriesQuery = (params, settings) =>
    createUseQuery({
        name: ['import-histories', params],
        query: () => apiService.getImportHistories(params),
        settings,
    });

export default useImportHistoriesQuery;
