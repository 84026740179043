import React from 'react';

import BarcodeList from './../../components/BarcodeList';
import Summary from './../../components/Summary';
import ScanHistories from './ScanHistories';

const Body = ({
    canUpdate,
    inventoryDocument,
    scanHistories,
    skuInventories,
    onSuccessImportSkus,
    onUpdateSkuInventoryDocument,
}) => {
    return (
        <>
            <BarcodeList
                barcodes={skuInventories}
                canUpdate={canUpdate}
                inventoryDocument={inventoryDocument}
                onSuccessImportSkus={onSuccessImportSkus}
                onUpdateSkuInventoryDocument={onUpdateSkuInventoryDocument}
            />
            <div>
                {canUpdate && <ScanHistories scanHistories={scanHistories} />}
                <Summary
                    canUpdate={canUpdate}
                    inventoryDocument={inventoryDocument}
                    onUpdateSkuInventoryDocument={onUpdateSkuInventoryDocument}
                />
            </div>
        </>
    );
};

export default Body;
