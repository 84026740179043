import { CloudUploadOutlined } from '@ant-design/icons';
import { Form, notification, Upload } from 'antd';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import React, { useState, useEffect } from 'react';

import UploadMulti from '@Modules/App/Upload/UploadMulti';
import api from '@Modules/Product/services/api';

import { t } from '@System/i18n';
import { getVar } from '@System/support/helpers';
import {events} from "@System/events";
import {EVENTS} from "@Modules/Product/services/constants";

const fileType = ['image/jpeg', 'image/png'];

function ProductImages(props) {
    const [form] = Form.useForm();
    const { product } = props;
    const maxFileSize = 5;
    const images = getVar(product, 'images', []);
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        const newImages = refactorImage(images);
        if (!isEqual(fileList, newImages)) {
            setFileList(newImages);
        }
    }, [images]);

    function refactorImage(images) {
        const newImages = [];
        map(images, (item, index) => {
            return newImages.push({ url: item, uid: index + 1 });
        });
        return newImages;
    }

    function beforeUpload(file) {
        if (fileType) {
            if (fileType.indexOf(file.type.toLowerCase()) < 0) {
                return false;
            }
        }

        if (maxFileSize) {
            const validMaxSize = file.size / 1024 / 1024 > maxFileSize;
            if (validMaxSize) {
                return false;
            }
        }
        return true;
    }

    function uploadFile(value) {
        let formData = new FormData();
        let status = 'added';
        if (isArray(value)) {
            map(value, image => {
                formData.append('files[]', image);
            });
        } else {
            status = 'removed';
            const remove_file = getVar(value, 'url', '');
            if (remove_file) {
                formData.append('removed_files[]', remove_file);
            }
        }
        api.editProduct(product.id, formData)
            .then(res => {
                notification.success({
                    message:
                        status === 'added'
                            ? t('message.create_success', {
                                  attribute: t(`product:label.image`),
                              })
                            : t('message.delete_success', {
                                  attribute: t(`product:label.image`),
                              }),
                });
                events.dispatch(EVENTS.UPDATE_PRODUCT_SUCCESS, {});
            })
            .catch(err => {
                notification.error({
                    message: t('message.update_failed_by_attribute', { attribute: t(`product:title.picture`) }),
                });
            });
    }

    function handleUpload(files) {
        let listFile = [];
        let error = false;
        files.forEach(file => {
            const validFile = beforeUpload(file);
            if (validFile) {
                listFile.push(file);
            } else {
                error = true;
            }
        });
        if (!error) {
            if (listFile.length > 0) {
                form.setFields([{ name: 'files', value: listFile }]);
            }
            form.validateFields().then(values => {
                uploadFile(values['files']);
                form.resetFields();
            });
        } else {
            form.resetFields();
        }
    }

    return (
        <div className="mt-4">
            <div className="d-flex align-items-center">
                <h3 className="text-fz-16">{t('product:title.picture')}</h3>
                <span className="ml-2 number-circle _product-detail-picture-total">{images.length}</span>
                <i className="ml-2">{t('product:description.picture')}</i>
            </div>
            <div className="d-flex text-wrap align-items-left mt-4">
                <div style={{ cursor: 'pointer' }}>
                    <Form size="default" form={form}>
                        <Form.Item name="files" className="mb-0">
                            <UploadMulti
                                className="_product-images-upload"
                                listType="picture-card"
                                onChange={handleUpload}
                                accept="image/jpeg,image/png"
                                fileType={fileType}
                                maxFileSize={maxFileSize}
                                showUploadList={false}
                            >
                                <div className="_product-image-upload">
                                    <CloudUploadOutlined />
                                    <div className="mt-3">{t('label.upload_image')}</div>
                                </div>
                            </UploadMulti>
                        </Form.Item>
                    </Form>
                </div>
                <Upload
                    className="_product-detail-list-product"
                    listType="picture-card"
                    fileList={fileList}
                    openFileDialogOnClick={false}
                    onChange={e => uploadFile(e.file)}
                />
            </div>
        </div>
    );
}

export default ProductImages;
