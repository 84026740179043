import { Space as BaseSpace } from 'antd';
import clsx from 'clsx';
import React from 'react';

const Space = ({ className, justifyContent, type, ...other }) => (
    <BaseSpace
        className={clsx(className, {
            'd-flex': type === 'flex',
            [`justify-content-${justifyContent}`]: !!justifyContent
        })}
        {...other}
    />
);

export default Space;
