import { Button } from 'antd';
import isNil from 'lodash/isNil';
import React from 'react';

import usePurchasingAccountBalanceQuery from '@Modules/PurchasingAccount/Hooks/usePurchasingAccountBalanceQuery';

import { t } from '@System/i18n';
import { formatCurrency } from '@System/support/numberFormat';

const CheckingBalance = ({ disabled, id }) => {
    const { isLoading, data, refetch } = usePurchasingAccountBalanceQuery(id);

    return isNil(data?.data?.balance) ? (
        <Button disabled={disabled} loading={isLoading} onClick={refetch}>
            {t('check_balance')}
        </Button>
    ) : (
        formatCurrency(data?.data?.balance)
    );
};

export default CheckingBalance;
