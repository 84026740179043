import { useQuery } from 'react-query';

import apiService from './../services/api';

const useImportingDocumentQuery = id => useQuery(['importing-document', id], () => apiService.importingDocument(id), {
    retry: false,
    enabled: !!id
});

export default useImportingDocumentQuery;
