import { t } from '@System/i18n';
import notification from '@System/notification';
import { convertBlobDataToExcel } from '@System/support/helpers';
import { useQuery } from 'react-query';
import api from '../services/api';

const useExportStorageFee = (params) =>
    useQuery(['export-storage-fees', params], () => api.exportStorageFee(params), {
        retry: false,
        enabled: false,
        onSuccess: (res) => {
            convertBlobDataToExcel(res.data, "storage-fee-export");
        },
        onError: error => {
            const status = error?.response?.status;
            if (status === 403) {
                notification.error(t('message.403'));
            } else if (status === 404) {
                notification.error(t('message.404'));
            } else {
                notification.error(t('message.fetch_fail'));
            }
        },
    });


export default useExportStorageFee