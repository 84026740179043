import { Form, Input, InputNumber, Space, Table, Typography } from 'antd'
import { sumBy, isEmpty, find, get } from 'lodash'
import React, { useEffect } from 'react'

import Box from '@Modules/App/Box'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import AddSku from '@Modules/Document/screens/Importing/ScanPurchasePackage/components/AddSku'

import { t } from '@System/i18n'
import { Link, url } from '@System/routing'
import { getVar } from '@System/support/helpers'

import SelectSerial from '../../components/SelectSerial'

import styles from '../scan-purchase-package.module.scss'

const { Text, Title } = Typography

function ProductList({ storeActions, storeData, form }) {
    const dataSource = getVar(storeData, 'skus', [])
    const purchasePackages = getVar(storeData, 'purchase_packages', [])
    const isSaved = getVar(storeData, 'isSaved', false)

    function updateSerial(serials, data) {
        const { id } = data
        const skuInfo = find(dataSource, ['id', id])

        const receivedQuantity = serials.length

        if (!isEmpty(skuInfo)) {
            const old_received_quantity = getVar(skuInfo, 'received_quantity', 0)
            if (receivedQuantity !== old_received_quantity) {
                const isValid = Number.isInteger(parseInt(receivedQuantity))
                if (isValid) {
                    storeActions.updateReceivedQuantity({ id, sku_id: id, received_quantity: parseInt(receivedQuantity), serial_numbers: serials })
                }
            }
        }
    }

    function updateQuantity(receivedQuantity, data) {
        const { id } = data
        const skuInfo = find(dataSource, ['id', id])

        if (!isEmpty(skuInfo)) {
            const old_received_quantity = getVar(skuInfo, 'received_quantity', 0)
            if (receivedQuantity !== old_received_quantity) {
                const isValid = Number.isInteger(parseInt(receivedQuantity))
                if (isValid) {
                    storeActions.updateReceivedQuantity({ id, sku_id: id, received_quantity: parseInt(receivedQuantity) })
                }
            }
        }
    }

    const columns = [
        {
            className: '_product-list-sku-info',
            title: t('order:label.sku_info'),
            dataIndex: 'code',
            render: (_, record) => {
                return (
                    <>
                        <p>
                            <Link to={url.to('products.detail', { id: record?.product_id })}>{record?.code}</Link>
                        </p>
                        <Text type="secondary">{record?.name}</Text>
                    </>
                )
            },
        },
        {
            className: 'text-right text-nowrap _product-list-received-quantity',
            title: t('product:label.real_receiver_quantity'),
            dataIndex: 'serial_numbers',
            width: '240px',
            editable: true,
        },
    ]

    const EditableCell = ({ title, editable, children, dataIndex, record, index, updateQuantity, updateSerial, ...restProps }) => {

        const quantity = get(record, 'received_quantity', [])
        useEffect(() => {
            if (editable) {
                form.setFields([{ name: [index, dataIndex], value: record[dataIndex] }])
            }
        }, [editable])

        let childNode = children
        const isSerial = get(record, 'is_serial_number')
        const serialNumbers= get(record, 'serial_numbers', [])

        if (editable) {
            childNode = (
                <div className="d-flex align-items-center justify-content-between">
                    <Form.Item
                        // name={[index, isSerial ? dataIndex : "quantity"]}
                        className="mb-0 w-100"
                        // rules={[
                        //     { required: true, message: t('product:message.required') },
                        //     { pattern: /^\+?([0-9]\d*)$/, message: t('validation:min.numeric', { min: 0 }) },
                        // ]}
                    >
                        {isSerial ? (
                            <SelectSerial
                                className="text-right  _product-list-received-quantity-input"
                                disabled={isSaved}
                                value={serialNumbers}
                                onChange={value => updateSerial(value, record)}
                            />
                        ) : (
                            <InputNumber
                                className="text-right  _product-list-received-quantity-input w-100"
                                onPressEnter={e =>
                                    updateQuantity(
                                        Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : '',
                                        record
                                    )
                                }
                                onBlur={e =>
                                    updateQuantity(
                                        Number.isInteger(parseInt(e.target.value)) ? parseInt(e.target.value) : '',
                                        record
                                    )
                                }
                                min={0}
                                disabled={isSaved}
                                value={quantity}
                            />
                        )}
                    </Form.Item>
                    <div className="_product-list-old-received-quantity text-right ml-2">
                        { quantity }/{get(record, 'old_received_quantity')}
                    </div>
                </div>
            )
        }
        return <td {...restProps}>{childNode}</td>
    }

    const tableColumns = columns.map(col => {
        if (!col.editable) {
            return col
        }

        return {
            ...col,
            onCell: (record, index) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                index,
                updateSerial,
                updateQuantity,
            }),
        }
    })

    return (
        <Box className={styles.aside}>
            <Table
                rowKey={record => record.id}
                className="_product-list-info"
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                title={() => (
                    <div className="d-flex justify-content-between border-bottom align-items-center">
                        <Space className="mb-3">
                            <Title level={3}>{t('order:title.list_products')}</Title>
                            <span className="number-circle">{dataSource.length}</span>
                        </Space>
                        {purchasePackages.length > 0 && (
                            <AddSku
                                storeData={storeData}
                                addProduct={storeActions.addSku}
                            />
                        )}
                    </div>
                )}
                footer={() => {
                    return dataSource.length > 0 ? (
                        <div className="d-flex justify-content-between">
                            <strong>{t('sum')}</strong>
                            <strong className="_product-list-total">
                                {`${sumBy(dataSource, 'received_quantity')}/${sumBy(dataSource, 'old_received_quantity')}`}
                            </strong>
                        </div>
                    ) : (
                        false
                    )
                }}
                dataSource={dataSource}
                columns={tableColumns}
                pagination={false}
                locale={{ emptyText: <TableEmpty className="_product-list-no-data" /> }}
            />
        </Box>
    )
}

export default ProductList
