import React from "react";
import {t} from "@System/i18n";

import {Select} from "antd";

const {Option} = Select;

export const ORIGIN_TYPE = [
    {name: t("common:label.deposit"), value: "DEPOSIT"},
    {name: t("common:label.withdraw"), value: "WITHDRAW"},
    {name: t("common:label.collect"), value: "COLLECT"},
];

export default function SelectTypeTransaction(props) {
    return (
        <Select
            {...props}
            optionFilterProp="children"
            allowClear={true}
        >
            {
                ORIGIN_TYPE.map((opt, index) => {
                    return (
                        <Option
                            key={index}
                            value={opt.value}
                        >{opt.name}</Option>
                    );
                })
            }
        </Select>
    );
}
