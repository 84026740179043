import React from "react";
import {t} from "../../../../../system/i18n";
import {Link} from "react-router-dom";
import {url} from "../../../../../system/routing";
import {Table} from "antd";
import TableEmpty from "../../../../App/Utilities/TableEmpty";

export default function ListStocks({stocks, loading}) {

    let getColumns = [
        {
            title: t("product:label.warehouse_code"),
            dataIndex: 'warehouse.code',
            key: 'warehouse.code',
            render: (text, {warehouse}) => {
                return <strong style={{fontWeight: "500"}}>
                    <Link to={url.to("warehouses.detail", {id: warehouse.id})}>{warehouse.code}</Link>
                </strong>;
            },
        },
        {
            title: t("product:label.warehouse_area_code"),
            dataIndex: "warehouseArea.code",
            key: 'warehouseArea.code',
            render: (text, {warehouseArea}) => {
                return <p>{warehouseArea.code === 'default' ? t("label.default") : warehouseArea.code}</p>;
            },
        },
        {
            title: t("product:label.merchant_code1"),
            dataIndex: "merchant.name",
            key: 'merchant.name',
            render: (text, {merchant}) => {
                return <p>{merchant.code + " - " + merchant.name}</p>;
            },
        },
        {
            title: t("product:label.quantity1"),
            dataIndex: "stock.real_quantity",
            key: 'stock.real_quantity',
            className: 'text-center',
            render: (text, {stock}) => {
                return <p>{stock.quantity}</p>;
            },
        },
        {
            title: t("product:label.real_quantity1"),
            dataIndex: "stock.quantity",
            key: 'stock.quantity',
            className: 'text-center',
            render: (text, {stock}) => {
                return <p>{stock.real_quantity}</p>;
            }
        }
    ];

    return (
        <div className="m-3">
            <Table
                className="list-products-table pb-3"
                dataSource={stocks}
                rowKey={record => record.stock.id}
                columns={getColumns}
                pagination={false}
                loading={loading}
                locale={{ emptyText: <TableEmpty loading={loading} /> }}
            />
        </div>
    );
}
