import React from 'react'
import { Redirect } from 'react-router-dom'

import ConnectedAccountList from '@Modules/ConnectedAccount/screens/List'
import ImportingReturnOrderDetail from '@Modules/Document/screens/Importing/ReturnOrder/Detail'
import ImportingByReturnOrder from '@Modules/Document/screens/Importing/ReturnOrder/Scan'
import DocumentImportingScan from '@Modules/Document/screens/Importing/Scan'
import ImportingByPurchasePackage from '@Modules/Document/screens/Importing/ScanPurchasePackage'
import CreateInventoryDocument from '@Modules/Document/screens/Inventory/Create'
import InventoryDocumentDetail from '@Modules/Document/screens/Inventory/Detail'
import DocumentInventory from '@Modules/Document/screens/Inventory/List'
import InventoryDocumentPreview from '@Modules/Document/screens/Inventory/Preview'
import DropshippingDetail from '@Modules/Dropshipping/screens/Detail'
import ListDropshipping from '@Modules/Dropshipping/screens/List'
import FinanceControlDetail from '@Modules/Finance/screens/Control/Detail'
import FinanceControl from '@Modules/Finance/screens/Control/List'
import ControlDeliveryDetail from '@Modules/Finance/screens/ControlDelivery/Detail'
import ControlDelivery from '@Modules/Finance/screens/ControlDelivery/List'
import DebtsShipping from '@Modules/Finance/screens/DebtsShipping'
import FinanceStatistic from '@Modules/Finance/screens/List'
import WarehouseStocks from '@Modules/Inventory/screens/WarehouseStocks'
import MerchantsDetails from '@Modules/Merchant/screens/Details'
import ShippingStamp from '@Modules/Order/components/ShippingStamp'
import SkuStamp from '@Modules/Order/components/ShippingStamp/SkuStamp'
import HandleErrorOrders from '@Modules/Order/screens/HandleErrorOrders'
import PickupWaitingList from '@Modules/Order/screens/Pickup/List'
import ProcessPickingUp from '@Modules/Order/screens/Pickup/Process/ProcessPickingUp'
import PurchasingAccountList from '@Modules/PurchasingAccount/screens/List'
import ListPurchasingOrders from '@Modules/PurchasingOrder/screens/ListPurchasingOrders'
import ListPurchasingOrdersByVendor from '@Modules/PurchasingOrder/screens/ListPurchasingOrders/ListPurchasingOrdersByVendor'
import ListPurchasingPackage from '@Modules/PurchasingPackage/screens/List'
import ScanPacking from '@Modules/ScanOrder/screens/Packing'
import MoveProducts from '@Modules/Warehouse/screens/MoveProducts'

import DefaultLayout from './layouts/Default'
import Error403 from './modules/App/Error/403'
import Error404 from './modules/App/Error/404'
import Home from './modules/App/Home'
import loadLanguage from './modules/App/middleware/loadLanguage'
import Login from './modules/Auth/Login'
import LoginCallback from './modules/Auth/LoginCallback'
import authenticate from './modules/Auth/middleware/authenticate'
import { can, canAny } from './modules/Auth/middleware/authorize'
import loadAuthUser from './modules/Auth/middleware/loadAuthUser'
import PERMISSIONS from './modules/Auth/services/permissions'
import SynchronousProducts from './modules/Configuration/screens/SynchronousProducts'
import ExportingDocumentDetail from './modules/Document/screens/Exporting/Detail'
import ForControlExportingDocument from './modules/Document/screens/Exporting/ForControl'
import ExportingDocumentList from './modules/Document/screens/Exporting/List'
import DocumentImportingDetail from './modules/Document/screens/Importing/Detail'
import DocumentImporting from './modules/Document/screens/Importing/List'
import PackingDocumentDetail from './modules/Document/screens/Packing/Detail'
import PackingDocumentList from './modules/Document/screens/Packing/List'
import DetailedBill from './modules/ExportProduct/screens/DetailedBill'
import ListBills from './modules/ExportProduct/screens/ListBills'
import NewExportedBill from './modules/ExportProduct/screens/NewExportedBill'
import ListMerchants from './modules/Merchant/screens/ListMerchants'
import OperationConfig from './modules/OperationConfig/screens'
import CreateOrder from './modules/Order/screens/CreateOrder'
import ListOrder from './modules/Order/screens/ListOrder'
import OrderDetail from './modules/Order/screens/OrderDetail'
import ListPrepareOrder from './modules/Order/screens/PrepareOrder'
import HistoryImport from './modules/Product/screens/HistoryImport'
import HistoryImportDetail from './modules/Product/screens/HistoryImport/HistoryImportDetail'
import ListProducts from './modules/Product/screens/ListProducts'
import OriginalProduct from './modules/Product/screens/OriginalProduct'
import InventoryManagement from './modules/Product/screens/ProductInventory'
import ProductCategories from './modules/ProductCategories/screens/ListCategories'
import ListVariant from './modules/ProductVariants/screens/ListVariant'
import VariantDetail from './modules/ProductVariants/screens/VariantDetail'
import ExportOrder from './modules/ScanOrder/screens/ExportOrder'
import ServicesManager from './modules/ServicesManager/screens/List'
import ListOperation from './modules/Users/screens/Operations'
import WarehousesDetail from './modules/Warehouse/screens/Detail'
import ListWarehouses from './modules/Warehouse/screens/List'
import ListWarehouseArea from './modules/WarehouseArea/screens/ListWarehouseArea'
import ComboDetail from '@Modules/Product/screens/ComboDetail'
import Supplier from '@Modules/Supplier/screens/List'
import PurchasingPackageCreate from '@Modules/PurchasingPackage/screens/Create'
import SupplierDetail from '@Modules/Supplier/screens/Detail'

const router = {
    routes: [
        {
            name: 'login',
            path: '/login',
            component: Login,
        },
        {
            name: 'error-404',
            path: '/error-404',
            component: Error404,
        },
        {
            name: 'error-403',
            path: '/error-403',
            component: Error403,
        },
        {
            name: 'login.callback',
            path: '/login/callback',
            component: LoginCallback,
        },
        {
            group: {
                layout: DefaultLayout,
                middleware: [authenticate, loadAuthUser],
                routes: [
                    {
                        name: 'home',
                        path: '/',
                        component: Home,
                    },
                    {
                        group: {
                            name: 'orders.',
                            prefix: '/orders',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListOrder,
                                    middleware: [can(PERMISSIONS.ORDER_VIEW_LIST)],
                                },
                                {
                                    name: 'detail',
                                    path: '/:id',
                                    component: OrderDetail,
                                    middleware: [can(PERMISSIONS.ORDER_VIEW_DETAIL)],
                                },
                                {
                                    name: 'create',
                                    path: '/create',
                                    component: CreateOrder,
                                    middleware: [can(PERMISSIONS.ORDER_CREATE)],
                                },
                                {
                                    name: 'prepare',
                                    path: '/prepare',
                                    component: ListPrepareOrder,
                                    middleware: [can(PERMISSIONS.OPERATION_PREPARATION)],
                                },
                                {
                                    name: 'export',
                                    path: '/export',
                                    component: ExportOrder,
                                    middleware: [can(PERMISSIONS.OPERATION_EXPORT)],
                                },
                                {
                                    name: 'handle-error',
                                    path: '/handle-error',
                                    component: HandleErrorOrders,
                                    middleware: [can(PERMISSIONS.ORDER_VIEW_FAILED_ORDER)],
                                },
                                {
                                    name: 'pickup-waiting-list',
                                    path: '/pickup-waiting-list',
                                    component: PickupWaitingList,
                                    middleware: [can(PERMISSIONS.OPERATION_PICKING)],
                                },
                                {
                                    name: 'picking-up',
                                    path: '/picking-up/:sessionId',
                                    component: ProcessPickingUp,
                                    middleware: [can(PERMISSIONS.OPERATION_PICKING)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'purchasing_orders.',
                            prefix: '/purchasing-orders',
                            middleware: [canAny([PERMISSIONS.MERCHANT_PURCHASING_ORDER_ALL, PERMISSIONS.MERCHANT_PURCHASING_ORDER_ASSIGNED])],
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListPurchasingOrders,
                                    middleware: [can(PERMISSIONS.MERCHANT_PURCHASING_ORDER_ALL)],
                                },
                                {
                                    name: 'list_by_vendor',
                                    path: '/list-by-vendor',
                                    component: ListPurchasingOrdersByVendor,
                                    middleware: [can(PERMISSIONS.MERCHANT_PURCHASING_ORDER_ASSIGNED)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'purchasing_packages.',
                            prefix: '/purchasing_packages',
                            middleware: [canAny([PERMISSIONS.FINANCE_VIEW_INBOUND_SHIPMENT])],
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListPurchasingPackage,
                                    middleware: [can(PERMISSIONS.FINANCE_VIEW_INBOUND_SHIPMENT)],
                                },
                                {
                                    name: 'create',
                                    path: '/create',
                                    component: PurchasingPackageCreate,
                                    middleware: [can(PERMISSIONS.ADMIN_PACKAGE_CREATE)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'documents.',
                            prefix: '/documents',
                            routes: [
                                {
                                    group: {
                                        name: 'packing.',
                                        prefix: '/packing',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                component: PackingDocumentList,
                                                middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_PREPARATION, PERMISSIONS.OPERATION_PREPARATION])],
                                            },
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                component: PackingDocumentDetail,
                                                middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_PREPARATION, PERMISSIONS.OPERATION_PREPARATION])],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'exporting.',
                                        prefix: '/exporting',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                component: ExportingDocumentList,
                                                middleware: [canAny([PERMISSIONS.OPERATION_EXPORT, PERMISSIONS.OPERATION_HISTORY_EXPORT])],
                                            },
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                component: ExportingDocumentDetail,
                                                middleware: [canAny([PERMISSIONS.OPERATION_EXPORT, PERMISSIONS.OPERATION_HISTORY_EXPORT])],
                                            },
                                            {
                                                name: 'for-control',
                                                path: '/for-control/:id',
                                                component: ForControlExportingDocument,
                                                middleware: [canAny([PERMISSIONS.OPERATION_EXPORT, PERMISSIONS.OPERATION_HISTORY_EXPORT])],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'importing.',
                                        prefix: '/importing',
                                        routes: [
                                            {
                                                name: 'scan',
                                                path: '/scan',
                                                component: DocumentImportingScan,
                                                middleware: [canAny([PERMISSIONS.OPERATION_IMPORT])],
                                            },
                                            {
                                                name: 'list',
                                                path: '/',
                                                component: DocumentImporting,
                                                middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_IMPORT, PERMISSIONS.OPERATION_IMPORT])],
                                            },
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                component: DocumentImportingDetail,
                                                middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_IMPORT, PERMISSIONS.OPERATION_IMPORT])],
                                            },
                                            // {
                                            //     name: 'scan-package-order',
                                            //     path: '/scan-package-order',
                                            //     component: ImportingByPurchaseOrder,
                                            //     middleware: [canAny([PERMISSIONS.OPERATION_IMPORT])]
                                            //
                                            // },
                                            {
                                                name: 'scan-return-order',
                                                path: '/scan-return-order',
                                                component: ImportingByReturnOrder,
                                                middleware: [canAny([PERMISSIONS.OPERATION_IMPORT])],
                                            },
                                            {
                                                name: 'detail-importing-return-order',
                                                path: '/return-order/:id',
                                                component: ImportingReturnOrderDetail,
                                                middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_IMPORT, PERMISSIONS.OPERATION_IMPORT])],
                                            },
                                            {
                                                name: 'scan-purchase-package',
                                                path: '/scan-purchase-package',
                                                component: ImportingByPurchasePackage,
                                                middleware: [canAny([PERMISSIONS.OPERATION_IMPORT])],
                                            },
                                        ],
                                    },
                                },
                                {
                                    group: {
                                        name: 'inventory.',
                                        prefix: 'inventory',
                                        middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_AUDIT_EDIT, PERMISSIONS.OPERATION_HISTORY_AUDIT_VIEW])],
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                middleware: [can(PERMISSIONS.OPERATION_HISTORY_AUDIT_VIEW)],
                                                component: DocumentInventory,
                                            },
                                            {
                                                name: 'create',
                                                path: '/create',
                                                component: CreateInventoryDocument,
                                                middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_AUDIT_EDIT])],
                                            },
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                component: InventoryDocumentDetail,
                                                middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_AUDIT_EDIT])],
                                            },
                                            {
                                                name: 'preview',
                                                path: '/preview/:id',
                                                component: InventoryDocumentPreview,
                                                middleware: [canAny([PERMISSIONS.OPERATION_HISTORY_AUDIT_VIEW])],
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'products.',
                            prefix: '/products',
                            middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_LIST, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListProducts,
                                    middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_LIST, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                                },
                                {
                                    name: 'detail',
                                    path: '/:id',
                                    component: OriginalProduct,
                                    middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_DETAIL, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                                },
                                {
                                    name: 'categories',
                                    path: '/categories',
                                    component: ProductCategories,
                                    middleware: [can(PERMISSIONS.PRODUCT_VIEW_LIST)],
                                },
                                {
                                    name: 'combo-detail',
                                    path: '/combo-detail/:id',
                                    component: ComboDetail,
                                    middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_DETAIL, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'dropshipping.',
                            prefix: '/dropshipping',
                            middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_LIST, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListDropshipping,
                                    middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_LIST, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                                },
                                {
                                    name: 'detail',
                                    path: '/:id',
                                    component: DropshippingDetail,
                                    middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_DETAIL, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'variants.',
                            prefix: '/variants',
                            middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_LIST, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListVariant,
                                    middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_LIST, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                                },
                                {
                                    name: 'detail',
                                    path: '/:id',
                                    component: VariantDetail,
                                    middleware: [canAny([PERMISSIONS.PRODUCT_VIEW_DETAIL, PERMISSIONS.PRODUCT_MANAGE_ALL])],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'warehouses.',
                            prefix: '/warehouses',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: ListWarehouses,
                                    middleware: [can(PERMISSIONS.WAREHOUSE_VIEW)],
                                },
                                {
                                    name: 'detail',
                                    path: '/:id',
                                    component: WarehousesDetail,
                                    middleware: [can(PERMISSIONS.WAREHOUSE_VIEW)],
                                },
                            ],
                        },
                    },
                    {
                        name: 'import-history',
                        path: '/import-history',
                        component: HistoryImport,
                        middleware: [can(PERMISSIONS.WAREHOUSE_IMPORT_HISTORY)],
                    },
                    {
                        name: 'import-history-detail',
                        path: '/import-history/:id',
                        component: HistoryImportDetail,
                        middleware: [can(PERMISSIONS.WAREHOUSE_IMPORT_HISTORY)],
                    },
                    {
                        name: 'warehouse-areas',
                        path: '/warehouse-areas',
                        component: ListWarehouseArea,
                        middleware: [can(PERMISSIONS.WAREHOUSE_VIEW)],
                    },
                    {
                        name: 'inventory-management',
                        path: '/inventory-management',
                        component: InventoryManagement,
                        middleware: [can(PERMISSIONS.STOCK_VIEW)],
                    },
                    {
                        name: 'move_products',
                        path: '/move_products',
                        component: MoveProducts,
                        middleware: [can(PERMISSIONS.OPERATION_ARRANGE)],
                    },
                    {
                        name: 'new-exported-bill',
                        path: '/new-exported-bill',
                        component: NewExportedBill,
                        middleware: [can(PERMISSIONS.DELIVERY_NOTE_CREATE)],
                    },
                    {
                        name: 'detailed-bill',
                        path: '/detailed-bill/:id',
                        component: DetailedBill,
                        middleware: [can(PERMISSIONS.DELIVERY_NOTE_VIEW)],
                    },
                    {
                        name: 'list-exported-bills',
                        path: '/list-exported-bills',
                        component: ListBills,
                        middleware: [can(PERMISSIONS.DELIVERY_NOTE_VIEW)],
                    },
                    {
                        group: {
                            name: 'config.',
                            prefix: '/config',
                            routes: [
                                {
                                    group: {
                                        name: 'merchants.',
                                        prefix: '/merchants',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                component: ListMerchants,
                                                middleware: [can(PERMISSIONS.MERCHANT_VIEW)],
                                            },
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                component: MerchantsDetails,
                                                middleware: [can(PERMISSIONS.FINANCE_VIEW_SELLER_WALLET)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'user-operation',
                                    path: '/user-operation',
                                    middleware: [can(PERMISSIONS.USER_MERCHANT_VIEW, PERMISSIONS.USER_MERCHANT_ADD)],
                                    component: ListOperation,
                                },
                                {
                                    name: 'synchronous',
                                    path: '/synchronous',
                                    component: SynchronousProducts,
                                    middleware: [can(PERMISSIONS.SKU_VIEW_LIST_EXTERNAL_CODE)],
                                },
                                {
                                    name: 'connected-accounts',
                                    path: '/connected-accounts',
                                    component: ConnectedAccountList,
                                    middleware: [can(PERMISSIONS.MERCHANT_MANAGE_STORE)],
                                },
                                {
                                    name: 'purchasing-accounts',
                                    path: '/purchasing-accounts',
                                    component: PurchasingAccountList,
                                    middleware: [can(PERMISSIONS.MERCHANT_CONNECT_PURCHASING)],
                                },
                                {
                                    name: 'manager-services',
                                    path: '/manager-services',
                                    component: ServicesManager,
                                    middleware: [can(PERMISSIONS.SERVICE_VIEW)],
                                },
                                {
                                    name: 'operation-config',
                                    path: '/operation-config',
                                    component: OperationConfig,
                                    middleware: [can(PERMISSIONS.ADMIN_CHANGE_BANNER)],
                                },
                                {
                                    group: {
                                        name: 'supplier.',
                                        prefix: '/supplier',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                component: Supplier,
                                                middleware: [can(PERMISSIONS.OPERATION_VIEW_SUPPLIER)],
                                            },
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                component: SupplierDetail,
                                                middleware: [can(PERMISSIONS.OPERATION_VIEW_SUPPLIER)],
                                            },
                                        ],
                                    },
                                   
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'packing.',
                            prefix: '/packing',
                            routes: [
                                {
                                    name: 'confirm',
                                    path: '/confirm',
                                    component: ScanPacking,
                                    middleware: [can(PERMISSIONS.OPERATION_PREPARATION)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'purchasing-account.',
                            prefix: '/purchasing-account',
                            routes: [
                                {
                                    name: 'list',
                                    path: '/',
                                    component: PurchasingAccountList,
                                    middleware: [can(PERMISSIONS.MERCHANT_CONNECT_PURCHASING)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'stores.',
                            prefix: '/stores',
                            routes: [
                                {
                                    name: 'connect-callback',
                                    path: '/connect-callback',
                                    component: props => <Redirect to={`/config/connected-accounts${props.location.search}`} />,
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'inventories.',
                            prefix: '/inventories',
                            routes: [
                                {
                                    name: 'warehouse-stocks',
                                    path: '/warehouse-stocks',
                                    component: WarehouseStocks,
                                    middleware: [can(PERMISSIONS.STOCK_VIEW)],
                                },
                            ],
                        },
                    },
                    {
                        group: {
                            name: 'finances.',
                            prefix: '/finances',
                            middleware: [canAny([PERMISSIONS.FINANCE_VIEW_SELLER_REPORT])],
                            routes: [
                                {
                                    name: 'statistic',
                                    path: '/statistic',
                                    component: FinanceStatistic,
                                    middleware: [can(PERMISSIONS.FINANCE_VIEW_SELLER_REPORT)],
                                },
                                {
                                    group: {
                                        name: 'control.',
                                        prefix: '/control',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                component: FinanceControl,
                                                middleware: [can(PERMISSIONS.FINANCE_VIEW_STATEMENT)],
                                            },
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                component: FinanceControlDetail,
                                                middleware: [can(PERMISSIONS.FINANCE_VIEW_STATEMENT)],
                                            },
                                        ],
                                    },
                                },
                                {
                                    name: 'debts-shipping',
                                    path: '/debts-shipping',
                                    component: DebtsShipping,
                                    middleware: [can([PERMISSIONS.FINANCE_VIEW_STATEMENT])],
                                },

                                {
                                    group: {
                                        name: 'control-delivery.',
                                        prefix: '/control-delivery',
                                        routes: [
                                            {
                                                name: 'list',
                                                path: '/',
                                                component: ControlDelivery,
                                                middleware: [can(PERMISSIONS.FINANCE_VIEW_DELIVERY_STATEMENT)],
                                            },
                                            {
                                                name: 'detail',
                                                path: '/:id',
                                                component: ControlDeliveryDetail,
                                                middleware: [can(PERMISSIONS.FINANCE_VIEW_DELIVERY_STATEMENT)],
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            group: {
                name: 'stamp-templates',
                prefix: '/stamp-templates',
                routes: [
                    {
                        name: 'shipping',
                        path: '/shipping',
                        component: ShippingStamp,
                    },
                    {
                        name: 'sku',
                        path: '/sku',
                        component: SkuStamp,
                    },
                ],
            },
        },
    ],
    defaultRoute: {
        component: Error404,
    },
    middleware: [loadLanguage],
}

export default router
