import PropTypes from 'prop-types';
import React from 'react';

import StyledTable from '@Modules/App/Table/StyledTable';

const OrderExportingTable = ({ columns, dataSource, pagination, onChangePage }) => (
    <StyledTable
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        scroll={{ x: 576 }}
        onChange={onChangePage}
    />
);

OrderExportingTable.propTypes = {
    dataSource: PropTypes.array,
};

OrderExportingTable.defaultProps = {
    dataSource: [],
};

export default OrderExportingTable;
