import { Card, Table, Typography } from 'antd'
import { get } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import CustomizedPagination from '@Modules/App/CustomizedPagination'
import { auth } from '@Modules/Auth/services'
import permissions from '@Modules/Auth/services/permissions'

import { t } from '@System/i18n'
import { url } from '@System/routing'

import AddSupplier from '../../components/AddSupplier'
import EditSupplier from '../../components/EditSupplier'

const { Title, Paragraph } = Typography

const Body = ({ addFilter, filters, data, loading, pagination, refetch }) => {
    const columns = [
        {
            className: '_supplier-info',
            title: t('config:supplier.label.code'),
            dataIndex: ['supplier', 'code'],
            key: 'code',
            render: (text, record) => {
                const id = get(record, 'supplier.id')
                return (
                    <Link
                        to={url.to('config.supplier.detail', { id })}
                        className="font-weight-500"
                    >
                        {text}
                    </Link>
                )
            },
        },
        {
            className: '_supplier-info',
            title: t('config:supplier.label.name'),
            dataIndex: ['supplier', 'name'],
            key: 'name',
        },
        {
            className: '_supplier-address',
            title: t('address'),
            dataIndex: ['supplier', 'address'],
            width: '20%',
            render: text => {
                return (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: t('btn.expand'),
                        }}
                    >
                        {text ?? ''}
                    </Paragraph>
                )
            },
        },
        {
            className: '_supplier-contact',
            title: t('contact'),
            dataIndex: ['supplier', 'contact'],
            key: 'contact',
            width: '20%',
            render: text => {
                return (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: t('btn.expand'),
                        }}
                    >
                        {text ?? ''}
                    </Paragraph>
                )
            },
        },
        {
            className: '_supplier-note',
            title: t('note'),
            dataIndex: ['supplier', 'note'],
            key: 'note',
            width: '20%',
            render: text => {
                return (
                    <Paragraph
                        ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: t('btn.expand'),
                        }}
                    >
                        {text ?? ''}
                    </Paragraph>
                )
            },
        },
        {
            className: '_supplier-action',
            title: t('label.action'),
            dataIndex: ['supplier'],
            key: 'action',
            render: value => {
                return (
                    auth.can(permissions.ADMIN_UPDATE_SUPPLIER) && (
                        <EditSupplier
                            supplier={value}
                            refetch={refetch}
                        />
                    )
                )
            },
        },
    ]

    return (
        <>
            <Card className="rounded-12">
                <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between mb-4">
                    <Title
                        className="font-weight-bold text-fz-16 mb-0"
                        level={3}
                    >
                        {t('supplier_list')}
                    </Title>
                    {auth.can(permissions.ADMIN_CREATE_SUPPLIER) && <AddSupplier refetch={refetch} />}
                </div>

                <div className="data-box--body">
                    <Table
                        className="order-list-table pb-3"
                        dataSource={data}
                        rowKey={record => record?.supplier?.id}
                        columns={columns}
                        pagination={false}
                        loading={loading}
                        locale={{
                            emptyText: <p>{t(`message.${loading ? 'loading' : 'no_data'}`)}</p>,
                        }}
                        scroll={{ x: 578 }}
                    />
                    <CustomizedPagination
                        pagination={pagination}
                        addFilter={addFilter}
                        filters={filters}
                    />
                </div>
            </Card>
        </>
    )
}

export default Body
