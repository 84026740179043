import { notification } from 'antd';
import has from 'lodash/has';
import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import { t } from '@System/i18n';
import { notifyErrorFromServer } from '@System/support/helpers';
import api from "@Modules/Product/services/api";


const useAddItemPurchasePackageMutation = payload => {
    if (!payload) {
        payload = {};
    }

    if (!has(payload, 'notifyWhenError')) {
        payload.notifyWhenError = true;
    }

    const formattedConfig = omit(
        {
            ...payload,
            onSuccess: ({ data }) => {
                if (isFunction(payload?.onSuccess)) {
                    payload.onSuccess(data);
                }

                if (!data?.errors?.length) {
                    notification.success({
                        message: t('message.create_success', {attribute: t("sku:title.add_sku")}),
                    });
                }
            },
            onError: error => {
                if (isFunction(payload?.onError)) {
                    payload.onError(error);
                }

                if (payload.notifyWhenError) {
                    notifyErrorFromServer(error, t('message.create_failed', {attribute: t("sku:title.add_sku")}), '');
                }
            },
        },
        'notifyWhenError'
    );

    return useMutation(data => {
        return api.addItemOfPurchasePackage(payload.id, data);
    }, formattedConfig);
};

export default useAddItemPurchasePackageMutation;
