import isFunction from 'lodash/isFunction';
import omit from 'lodash/omit';
import { useMutation } from 'react-query';

import api from '@Modules/Finance/services/api';

const useUpdateFinanceControlDocumentMutation = payload => {
    if (!payload) {
        payload = {};
    }

    const formattedConfig = omit(
        {
            ...payload,
            onSuccess: ({ data }) => {
                if (isFunction(payload?.onSuccess)) {
                    payload.onSuccess(data);
                }
            },
            onError: error => {
                if (isFunction(payload?.onError)) {
                    payload.onError(error);
                }
            },
        },
        ['id']
    );

    return useMutation(data => api.updateFinanceControlDocument(payload?.id, data), formattedConfig);
};

export default useUpdateFinanceControlDocumentMutation;
