
import {useQuery} from 'react-query';

import api from "../services/api";
import processResponseError from "@System/api/processResponseError";

const useExportingDocumentListQuery = (params) =>  {
    return useQuery(['ExportingDocumentListQuery', params],
        () => api.getDocumentExportings(params), {
            retry: false,
            onError: (err) => {
                processResponseError(err)
            }
        });
};

export default useExportingDocumentListQuery;
