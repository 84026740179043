import { Col, Form, Input, notification, Row, Select, Spin, Typography } from 'antd'
import { each, filter, find, flatMap, get, head, isEmpty, map, trim, uniq } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import useScanBarcode from '@Modules/App/Hooks/useScanBarcode'
import useScanSounds from '@Modules/App/Hooks/useScanSounds'
import ImportPackingConfirm from '@Modules/ScanOrder/components/ImportPackingConfirm'
import customerError from '@Modules/ScanOrder/components/customerError'
import apiService from '@Modules/Services/services/api'
import { SERVICE_TYPE } from '@Modules/Services/services/constants'

import { addError, deleteErrorByKey } from '@State/scanErrors/actions'

import { t } from '@System/i18n'
import { getVar } from '@System/support/helpers'

import { scanBarcodeTypeStorage, scanWarehouseStorage, startScanServiceConfirmPacking } from '../../App/services'
import SelectWarehouse from '../../Warehouse/components/SelectWarehouse'
import api from '../services/api'
import { BARCODE_TYPE } from '../services/constants'
import ScanBarcode from './ScanBarcode'

const { Option } = Select
const { Title } = Typography

function ScanLadingBill(props) {
    const {
        typeAction,
        getScanInfo,
        countryId,
        disableChange,
        setLoadingData,
        disableBarcode,
        scanOrderListInfo,
        setTypeAction,
        updateListOfLading,
        warehouses,
        reloadData,
        page
    } = props

    const barcodeType = scanBarcodeTypeStorage.value
    const { playSuccessSound, playErrorSound } = useScanSounds()
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const [scanLoading, setScanLoading] = useState(false)
    const [isScanService, setIsScanService] = useState(false)
    const [listBarcodeType, setListBarcodeType] = useState(BARCODE_TYPE)
    const [scanServiceStart, setScanServiceStart] = useState(startScanServiceConfirmPacking.value)

    const [serviceList, setServiceList] = useState([])

    const inputRef = useRef(null)

    const scan = useScanBarcode(getScanInfo, {
        onSuccess: (response, params) => {
            const data = get(response, 'data', {})
            const key = map(params, (value, key) => `${key}:${value ? value : ''}`).join('-')
            if (!isEmpty(data)) {
                let existsCode = !isEmpty(find(scanOrderListInfo, ['id', get(data, 'order_exporting.id')]))
                if (typeAction === 'confirm_packing') {
                    existsCode = !isEmpty(find(scanOrderListInfo, ['id', get(data, 'order_packing.id')]))
                }
                if (existsCode) {
                    playErrorSound()
                    dispatch(
                        addError({
                            type: props.errorKey,
                            key,
                            message: t('order:message.scan_order.code_scanned', {
                                ...params,
                                barcode: `#${params?.barcode}`,
                                barcode_type: t(`order:barcode_type.${BARCODE_TYPE[barcodeType]}`),
                                title: t(`order:title.barcode_type.${BARCODE_TYPE[barcodeType]}`),
                            }),
                        })
                    )
                } else {
                    updateListOfLading([data])
                    playSuccessSound()
                    dispatch(
                        deleteErrorByKey({
                            type: props.errorKey,
                            key,
                        })
                    )
                    notification.success({
                        type: props.errorKey,
                        message: t('order:message.scan_order.success', {
                            ...params,
                            barcode_type: t(`order:barcode_type.${BARCODE_TYPE[barcodeType]}`),
                        }),
                    })
                }
            }
        },
        onError: (error, params) => {
            const key = map(params, (value, key) => `${key}:${value}`).join('-')

            playErrorSound()
            dispatch(
                addError({
                    type: props.errorKey,
                    key,
                    message: { error: error?.response, typeAction, warehouses, params: params },
                })
            )
        },
        onSettled: () => {
            setScanLoading(false)
            setLoadingData(false)
            form.setFieldsValue({
                barcode: undefined,
            })
        },
    })
    useEffect(() => {
        if (
            get(head(scanOrderListInfo), 'order_code') === get(scanServiceStart, 'order_code') &&
            get(scanServiceStart, 'started', false) &&
            !isEmpty(scanOrderListInfo)
        ) {
            form.setFieldsValue({
                barcode_type: 'SERVICE',
            })
            setListBarcodeType({ SERVICE: 'SERVICE' })
            setTypeAction('service_price')
            setIsScanService(true)
        }
    }, [form, scanOrderListInfo, scanServiceStart])

    useEffect(() => {
        if (isEmpty(scanOrderListInfo)) {
            setListBarcodeType(BARCODE_TYPE)
            form.setFieldsValue({
                barcode_type: barcodeType,
                barcode: undefined,
            })

            setIsScanService(false)
            setScanServiceStart({
                order_code: '',
                started: false,
            })
            startScanServiceConfirmPacking.setValue({
                order_code: '',
                started: true,
            })
        }
    }, [scanOrderListInfo])

    useEffect(() => {
        if (isScanService) {
            setScanLoading(true)
            apiService
                .list({ country_id: countryId, hidden_init_service: true, merchant_id: get(head(scanOrderListInfo), 'merchant_id'), type: SERVICE_TYPE.EXPORT })
                .then(res => {
                    let services = getVar(res, 'data.services', [])
                    setServiceList(services)
                })
                .finally(() => setScanLoading(false))
        }
    }, [isScanService])

    function _onChange(name, value) {
        if (typeof value === 'string') {
            value.trim()
        }

        if (name === 'barcode_type') {
            scanBarcodeTypeStorage.setValue(value)
        }

        if (name === 'warehouse_id') {
            scanWarehouseStorage.setValue(value)
        }

        if (name === 'barcode') {
            form.setFieldsValue({
                [name]: value,
            })

            form.submit()
        }
    }

    const onCheckSelectService = data => {
        const isStartSelectService = getVar(data, 'barcode', undefined) === 'CHONDICHVU'
        const isEndSelectService = getVar(data, 'barcode', undefined) === 'KETTHUCCHONDICHVU'

        if (isStartSelectService) {
            if (isScanService) {
                const key = map(data, (value, key) => `${key}:${value}`).join('-')
                dispatch(
                    addError({
                        type: props.errorKey,
                        key,
                        message: { error: { data: { data: { product_service: { invalid: [] } } }, status: 400 }, typeAction, warehouses, params: data },
                    })
                )
                playErrorSound()
                form.setFieldsValue({
                    barcode: undefined,
                })
                return true
            } else {
                form.setFieldsValue({
                    barcode_type: 'SERVICE',
                })

                setListBarcodeType({ SERVICE: 'SERVICE' })
                setIsScanService(true)

                form.setFieldsValue({
                    barcode: undefined,
                })

                setScanServiceStart({
                    order_code: get(head(scanOrderListInfo), 'order_code'),
                    started: false,
                })
                startScanServiceConfirmPacking.setValue({
                    order_code: get(head(scanOrderListInfo), 'order_code'),
                    started: true,
                })

                return true
            }
        }

        if (isEndSelectService) {
            setListBarcodeType(BARCODE_TYPE)
            form.setFieldsValue({
                barcode_type: barcodeType,
                barcode: undefined,
            })
            setIsScanService(false)
            setScanServiceStart({
                order_code: '',
                started: false,
            })
            startScanServiceConfirmPacking.setValue({
                order_code: '',
                started: true,
            })
            return true
        }
        inputRef.current.focus({ cursor: 'start' })
    }

    function handleUpdateService(priceId) {
        return api
            .updateOrderPackingsServices({ order_packing_ids: [get(head(scanOrderListInfo), 'order_packing_id')], service_price_ids: priceId })
            .then(res => {
                setScanServiceStart({
                    order_code: get(res, 'data.0.order.code'),
                    started: true,
                })
                startScanServiceConfirmPacking.setValue({
                    order_code: get(res, 'data.0.order.code'),
                    started: true,
                })

                reloadData()
            })
            .catch(error => {})
    }

    const checkPriceInOrder = values => {
        const barcodeType = get(values, 'barcode_type')

        if (barcodeType === 'SERVICE') {
            let servicePriceData = {}
            const barcode = get(values, 'barcode')
            serviceList.forEach(item => {
                map(item.servicePrices, valuePrice => {
                    if (valuePrice.service_price_code === barcode) {
                        servicePriceData = {
                            idService: get(item, 'service.id'),
                            idPrice: get(valuePrice, 'id'),
                        }
                    }
                })
            })
            if (get(servicePriceData, 'idService')) {
                if (get(scanServiceStart, 'started')) {
                    let listIds = []

                    each(get(head(scanOrderListInfo), 'services'), item => {
                        each(flatMap(serviceList, 'service'), val => {
                            if (item.service_id === val.id) {
                                listIds.push({ idService: item.service_id, idPrice: item.service_price_id })
                            }
                        })
                    })

                    const dataF = map(
                        filter(listIds, item => item.idService !== get(servicePriceData, 'idService')),
                        'service_price_id'
                    )

                    const dataListIds = listIds.filter(item => item.idService !== get(servicePriceData, 'idService'))

                    const newData = uniq([get(servicePriceData, 'idPrice'), ...dataF, ...map(dataListIds, 'idPrice')])

                    handleUpdateService(newData)
                } else {
                    // 'CHONDICHVU'
                    // 'KETTHUCCHONDICHVU'
                    let listIds = []

                    each(get(head(scanOrderListInfo), 'services'), item => {
                        each(flatMap(serviceList, 'service'), val => {
                            if (item.service_id === val.id && val.is_required) {
                                listIds.push({ idService: item.service_id, idPrice: item.service_price_id })
                            }
                        })
                    })
                    const newData = listIds.filter(item => item.idService !== get(servicePriceData, 'idService'))

                    handleUpdateService(uniq([get(servicePriceData, 'idPrice'), ...map(newData, 'idPrice')]))
                }
                playSuccessSound()
            } else {
                const key = map(values, (value, key) => `${key}:${value}`).join('-')
                dispatch(
                    addError({
                        type: props.errorKey,
                        key,
                        message: { error: { data: { data: { product_service: { invalid: [] } } }, status: 400 }, typeAction, warehouses, params: values },
                    })
                )
                playErrorSound()
            }
            form.setFieldsValue({
                barcode: undefined,
            })
        }
    }

    function handleFinish(values) {
        if (!isEmpty(scanOrderListInfo)) {
            if (onCheckSelectService(values)) {
                return
            }
        }

        if (isScanService) {
            checkPriceInOrder(values)

            return
        }

        setScanLoading(true)
        setLoadingData(true)
        scan({
            ...values,
            barcode: trim(get(values, 'barcode', '')),
            playSound: false,
            page
        })
        inputRef.current.focus({ cursor: 'start' })
    }

    function handleImportSuccess(infoScanSuccess, params, error) {
        const successData = []
        if (infoScanSuccess.length > 0) {
            let isSuccess = 0
            let isFail = 0
            const messages = []
            infoScanSuccess.map(data => {
                if (!isEmpty(data)) {
                    const barcode =
                        barcodeType === BARCODE_TYPE.FREIGHT_BILL
                            ? getVar(data, 'order_packing.freight_bill', '')
                            : getVar(data, 'order_packing.order.code', '')
                    const newParams = { ...params, barcode }
                    const key = map(newParams, (value, key) => `${key}:${value}`).join('-')
                    let existsCode = !isEmpty(find(scanOrderListInfo, ['id', get(data, 'order_exporting.id')]))
                    if (typeAction === 'confirm_packing') {
                        existsCode = !isEmpty(find(scanOrderListInfo, ['id', get(data, 'order_packing.id')]))
                    }

                    if (existsCode) {
                        const message = t('order:message.scan_order.code_scanned', {
                            ...newParams,
                            barcode: `#${barcode}`,
                            barcode_type: t(`order:barcode_type.${BARCODE_TYPE[barcodeType]}`),
                            title: t(`order:title.barcode_type.${BARCODE_TYPE[barcodeType]}`),
                        })
                        isFail++
                        messages.push(message)
                        dispatch(
                            addError({
                                type: props.errorKey,
                                key,
                                message: message,
                            })
                        )
                    } else {
                        isSuccess++
                        successData.push(data)
                        dispatch(
                            deleteErrorByKey({
                                type: props.errorKey,
                                key,
                            })
                        )
                    }
                }
            })
            if (isSuccess) {
                updateListOfLading(successData)
                playSuccessSound()
            } else {
                playErrorSound()
            }
            return messages
        } else {
            const key = map(params, (value, key) => `${key}:${value}`).join('-')
            dispatch(
                addError({
                    type: props.errorKey,
                    key,
                    message: { error: error, typeAction, params: params },
                })
            )
            return customerError({ error: error, typeAction, params: params }, warehouses)
        }
    }

    return (
        <Form
            className="bg-white"
            form={form}
            layout="vertical"
            onFinish={handleFinish}
        >
            {typeAction === 'confirm_packing' ? (
                <div className="d-flex justify-content-between align-items-center">
                    <Title
                        className="mb-3"
                        level={3}
                    >
                        {t('title.packing_confirm')}
                    </Title>
                    {!disableBarcode && (
                        <ImportPackingConfirm
                            form={form}
                            onSuccessImport={handleImportSuccess}
                        />
                    )}
                </div>
            ) : (
                <Title level={3}>{t('title.export_order')}</Title>
            )}
            <Row gutter={20}>
                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 6 }}
                >
                    <Form.Item
                        className="mb-0"
                        initialValue={scanWarehouseStorage.value}
                        label={t('order:label.export_warehouse')}
                        name="warehouse_id"
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('order:label.export_warehouse'),
                                }),
                            },
                        ]}
                    >
                        <SelectWarehouse
                            className="_scan-order-warehouse-id"
                            disabled={disableChange && scanWarehouseStorage.value}
                            placeholder={t('order:placeholder.export_warehouse')}
                            onChange={value => _onChange('warehouse_id', value)}
                        />
                    </Form.Item>
                </Col>

                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 8 }}
                >
                    <Form.Item
                        className="mb-0"
                        initialValue={barcodeType}
                        label={t('order:label.barcode_type')}
                        name="barcode_type"
                        rules={[
                            {
                                required: true,
                                message: t('validation:required', {
                                    attribute: t('order:label.barcode_type'),
                                }),
                            },
                        ]}
                    >
                        <Select
                            className="_scan-order-barcode-type"
                            disabled={disableChange}
                            showSearch
                            placeholder={t('order:placeholder.barcode_type')}
                            optionFilterProp="children"
                            onChange={value => _onChange('barcode_type', value)}
                        >
                            {/* {Object.keys(listBarcodeType).map((opt, index) => {
                                return (
                                    <Option key={index} value={BARCODE_TYPE[opt]}>
                                        {t(`order:barcode_type.${BARCODE_TYPE[opt]}`)}
                                    </Option>
                                );
                            })} */}

                            {Object.entries(isEmpty(scanOrderListInfo) ? BARCODE_TYPE : listBarcodeType).map(([key, val]) => {
                                return (
                                    <Option
                                        key={key}
                                        value={val}
                                    >
                                        {t(`order:barcode_type.${val}`)}
                                    </Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Col>

                <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 10 }}
                >
                    <Form.Item
                        className="mb-0"
                        label={t('order:label.barcode')}
                    >
                        <Spin spinning={scanLoading ? scanLoading : false}>
                            <Form.Item
                                className="mb-0"
                                name="barcode"
                            >
                                <Input
                                    ref={inputRef}
                                    className="_scan-order-barcode"
                                    onPressEnter={form.submit}
                                    placeholder={t('order:placeholder.barcode', {
                                        typeAction: t(`order:label.scan_order.${typeAction}`),
                                    })}
                                    suffix={disableBarcode ? false : <ScanBarcode onUpdate={value => _onChange('barcode', value)} />}
                                    disabled={disableBarcode}
                                    autoComplete="off"
                                />
                            </Form.Item>
                        </Spin>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default ScanLadingBill
