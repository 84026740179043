import { Modal, Input, Table, Form, Row, Col, notification, Spin } from 'antd'
import { get, isEmpty } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'

import CurrencyInput from '@Modules/App/Utilities/CurrencyInput'
import TableEmpty from '@Modules/App/Utilities/TableEmpty'
import api from '@Modules/ServicesManager/services/api'

import processResponseError from '@System/api/processResponseError'
import { t } from '@System/i18n'
import { formatCurrency } from '@System/support/numberFormat'

interface IAddServiceCombo {
    servicePackId: number
    hiddenCreateCombo: () => void
}
const ModalAddServiceCombo: React.FC<IAddServiceCombo> = ({ servicePackId, hiddenCreateCombo }) => {
    const [form] = Form.useForm()
    const [currency, setCurrency] = useState({} as any)
    const [servicePackPrices, setServicePackPrices] = useState([])
    const [dataCombo, setDataCombo] = useState({})
    const [loadingData, setLoadingData] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getDetails()
    }, [servicePackId])

    const getDetails = useCallback(async () => {
        setLoadingData(true)
        try {
            const res = await api.getServicesPackDetail(servicePackId)
            const data = get(res, 'data')
            const servicePackPrices = get(data, 'service_pack_prices')
            const servicePriceQuatas = servicePackPrices.map(item => {
                return {
                    service_price_id: get(item, 'service_price.id', 0),
                    quota: '',
                }
            })
            const servicePack = get(data, 'service_pack')
            setCurrency(get(data, 'country.currency'))
            setServicePackPrices(servicePackPrices)
            setDataCombo({
                service_pack_id: get(servicePack, 'id'),
                code: get(servicePack, 'code'),
                name: get(servicePack, 'name'),
                note: '',
                using_days: '',
                using_skus: '',
                suggest_price: '',
                service_price_quotas: servicePriceQuatas,
            })
        } catch (error) {
            processResponseError(error)
        } finally {
            setLoadingData(false)
        }
    }, [])

    const columns = [
        {
            className: ` _manage-service-column-type`,
            title: t('label.service_groups'),
            dataIndex: ['service', 'type'],
            key: 'type',
            render: text => <span>{t(`service_manage_status.${text}`)}</span>,
        },
        {
            className: ` _manage-service-column-name`,
            title: t('label.service_name'),
            dataIndex: ['service', 'name'],
            key: 'name',
        },
        {
            className: ` _manage-service-column-service_code`,
            title: t('label.service_code'),
            dataIndex: ['service', 'code'],
            key: 'code',
        },
        {
            className: `_manage-service-column-price_level`,
            title: t('label.price_level'),
            dataIndex: ['service_price', 'label'],
            key: 'label',
            render: (text, record) => {
                const price = get(record, 'service_price.price')
                return <span>{text ? `${text} - ${isEmpty(currency) ? price : formatCurrency(price || 0, currency)}` : t('noText')}</span>
            },
        },
        {
            className: `_manage-service-column-additional_costs`,
            title: t('label.additional_costs'),
            dataIndex: ['service_price', 'yield_price'],
            key: 'yield_price',
            render: text => {
                return <span>{isEmpty(currency) ? text : formatCurrency(text || 0, currency)}</span>
            },
        },
        {
            className: `_manage-service-quota`,
            title: t('serviceManager:label.service_combo_field.quota'),
            key: 'quota',
            dataIndex: ['service_pack_price', 'id'],
            render: (text, record, index) => {
                const servicePriceLabel = get(record, 'service_price.label', '')
                const servicePriceId = get(record, 'service_price.id', 0)
                const price = get(record, 'service_price.price', 0)
                form.setFields([
                    { name: ['service_price_quotas', index, 'service_price_id'], value: servicePriceId },
                    { name: ['service_price_quotas', index, 'price'], value: price },
                ])
                return (
                    <>
                        <Form.Item
                            name={['service_price_quotas', index, 'quota']}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:required', { attribute: t('serviceManager:label.service_combo_field.quota') }),
                                },
                                { pattern: /^\+?([0-9]\d*)$/, message: t('serviceManager:message.numeric', { min: 0 }) },
                            ]}
                        >
                            <Input
                                onBlur={handleOnBlur}
                                className="text-right"
                                placeholder={t('serviceManager:placeholder.quota')}
                            />
                        </Form.Item>
                        <Form.Item
                            name={['service_price_quotas', index, 'service_price_id']}
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['service_price_quotas', index, 'price']}
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>
                    </>
                )
            },
        },
        {
            className: `_manage-service-column-note`,
            title: t('note'),
            dataIndex: ['service_price', 'note'],
            key: 'note',
            render: text => {
                return <span>{text ? text : t('noText')}</span>
            },
        },
    ]

    function handleOnBlur() {
        const data = form.getFieldsValue()
        const servicePriceQuotas = get(data, 'service_price_quotas', [])
        let suggestPrice = 0
        servicePriceQuotas.map(item => {
            let quota = Number(get(item, 'quota', 0))
            let price = Number(get(item, 'price', 0))
            if (isNaN(quota)) {
                quota = 0
            }
            suggestPrice += quota * price
            return true
        })
        form.setFields([{ name: 'suggest_price', value: suggestPrice, errors: [] }])
    }

    function handleOk() {
        form.validateFields().then(data => {
            setLoading(true)
            api.createServiceCombo(data)
                .then(res => {
                    hiddenCreateCombo()
                    notification.success({ message: t('message.create_success', { attribute: t('serviceManager:label.service_combo') }) })
                })
                .catch(error => {
                    const code = get(error, 'response.data.code', '')
                    if (code === 'INPUT_INVALID') {
                        const data = get(error, 'response.data.data', {})
                        const messages: any[] = []
                        const notificationMessage: any[] = []
                        const formFields = ['service_pack_id', 'code', 'name', 'note', 'using_days', 'using_skus', 'suggest_price', 'service_price_quotas']
                        Object.keys(data).map(item => {
                            const errorKeys = Object.keys(data[item])
                            const messageError: any[] = []
                            errorKeys.map(errorKey => {
                                if (formFields.includes(item)) {
                                    messageError.push(
                                        t(`serviceManager:message.create_combo.${errorKey}`, {
                                            attribute: t(`serviceManager:label.service_combo_field.${item}`),
                                        })
                                    )
                                } else {
                                    notificationMessage.push(
                                        t(`serviceManager:message.create_combo.${errorKey}`, {
                                            attribute: t(`serviceManager:label.service_combo_field.${item}`),
                                        })
                                    )
                                }
                                return true
                            })
                            if (formFields.includes(item) && messageError.length > 0) {
                                messages.push({ name: item, errors: messageError })
                            }
                            return true
                        })

                        if (messages.length > 0) {
                            form.setFields(messages)
                        }

                        if (notificationMessage.length > 0) {
                            notification.error({ message: notificationMessage.join(', ') })
                        }
                    } else {
                        notification.error({ message: t('message.create_failed', { attribute: t('serviceManager:label.service_combo') }) })
                    }
                })
                .finally(() => setLoading(false))
        })
    }
    return (
        <Modal
            title={t('serviceManager:title.create_service_combo')}
            visible={true}
            okText={t('btn.ok')}
            cancelText={t('btn.cancel')}
            onCancel={hiddenCreateCombo}
            onOk={handleOk}
            width={1000}
            maskClosable={false}
            okButtonProps={{ loading: loading }}
        >
            <Spin spinning={loadingData}>
                {!isEmpty(dataCombo) && (
                    <Form
                        initialValues={dataCombo}
                        form={form}
                    >
                        <Form.Item
                            name={'service_pack_id'}
                            hidden={true}
                        >
                            <Input />
                        </Form.Item>
                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item
                                    label={t('serviceManager:label.service_combo_field.name')}
                                    name="name"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validation:required', { attribute: t('serviceManager:label.service_combo_field.name') }),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('placeholder.service_name')} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('serviceManager:label.service_combo_field.using_days') + ` (${t('label.day')})`}
                                    name="using_days"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validation:required', { attribute: t('serviceManager:label.service_combo_field.using_days') }),
                                        },
                                        { pattern: /^\+?([1-9]\d*)$/, message: t('serviceManager:message.numeric', { min: 1 }) },
                                    ]}
                                >
                                    <Input placeholder={t('serviceManager:placeholder.using_days')} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('serviceManager:label.service_combo_field.code')}
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validation:required', { attribute: t('serviceManager:label.service_combo_field.code') }),
                                        },
                                    ]}
                                >
                                    <Input placeholder={t('placeholder.service_code')} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label={t('serviceManager:label.service_combo_field.using_skus')}
                                    name="using_skus"
                                    rules={[
                                        {
                                            required: true,
                                            message: t('validation:required', { attribute: t('serviceManager:label.service_combo_field.using_skus') }),
                                        },
                                        { pattern: /^\+?([1-9]\d*)$/, message: t('serviceManager:message.numeric', { min: 1 }) },
                                    ]}
                                >
                                    <Input placeholder={t('serviceManager:placeholder.using_skus')} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Table
                            rowKey={(record: any) => record?.service_pack_price?.id}
                            rowClassName={'service-list-table'}
                            dataSource={servicePackPrices}
                            scroll={{ x: 768 }}
                            columns={columns}
                            pagination={false}
                            locale={{
                                emptyText: (
                                    <TableEmpty
                                        className="_product-list-empty"
                                        loading={loading}
                                    />
                                ),
                            }}
                            footer={() => (
                                <Row>
                                    <Col span={12}></Col>
                                    <Col span={9}>
                                        <Form.Item
                                            name="suggest_price"
                                            label={<b>{t('serviceManager:label.service_combo_field.suggest_price')}</b>}
                                            rules={[
                                                { pattern: /^\+?([0-9]\d*)$/, message: t('serviceManager:message.numeric', { min: 0 }) },
                                            ]}
                                        >
                                            <CurrencyInput
                                                value={form.getFieldValue('suggest_price')}
                                                currency={currency}
                                                style={{ width: '100%' }}
                                                className="text-right"
                                                placeholder={t('serviceManager:placeholder.suggest_price')}
                                                onBlur={value => {
                                                    let newValue = value || 0
                                                    form.setFields([{ name: 'suggest_price', value: newValue }])
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={3}></Col>
                                </Row>
                            )}
                        />
                    </Form>
                )}
            </Spin>
        </Modal>
    )
}
export default ModalAddServiceCombo
